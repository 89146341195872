// @ts-strict-ignore
import { Injectable } from '@angular/core';

@Injectable()
export class DemographicsAndDoctorFilterService {
  setAllFilters(survey: any, patientInfo: any, doctor: boolean) {
    for (const page of survey.pages) {
      if (page.elements) {
        for (const element of page.elements) {
          element.demographicsOrDoctorHidden = this.demographicsAndDoctorFilter(
            element,
            patientInfo,
            doctor
          );
        }
      }
    }
    return survey;
  }

  // true means hidden
  demographicsAndDoctorFilter(element, patientInfo, doctor: boolean): boolean {
    return (
      this.demographicsFilter(element, patientInfo) ||
      this.doctorFilter(element, doctor)
    );
  }

  doctorFilter(element: any, doctor: boolean): boolean {
    return !!doctor !== !!element.doctor; // coerce to boolean
  }

  demographicsFilter(element: any, patientInfo: any): boolean {
    const filters = element.filter;
    if (!filters) {
      return false;
    }
    if (filters.gender > 0) {
      if (
        (filters.gender === '1' && patientInfo.gender === 'Male') ||
        (filters.gender === '2' && patientInfo.gender === 'Female')
      ) {
        element.demographicsHidden = true;
        return true;
      }
    }
    // if any age filters
    if (filters.ageType > 0) {
      if (
        (filters.ageType === '1' && patientInfo.age >= filters.ageMax) ||
        (filters.ageType === '2' &&
          (patientInfo.age >= filters.ageMax ||
            patientInfo.age < filters.ageMin)) ||
        (filters.ageType === '3' && patientInfo.age < filters.ageMin)
      ) {
        element.demographicsHidden = true;
        return true;
      }
    }
    return false;
  }
} // end service
