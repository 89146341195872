<div class="user-table">
  <div class="row m-0">
    <div class="col-6">
      <button
        mat-mini-fab
        class="float-left basic-button m-3"
        matTooltip="Add User"
        color="primary"
        (click)="addUserBtnClicked.emit()"
      >
        <i class="material-icons">add</i>
      </button>
      <h4>{{ userGroup.title }}</h4>
    </div>
    <div class="col-6">
      <button
        mat-mini-fab
        color="none"
        matTooltip="Delete User Group"
        class="float-right basic-button m-3"
        (click)="deleteUserGroup.emit()"
      >
        <mat-icon class="notranslate">
          <mat-icon class="material-icons">delete</mat-icon>
        </mat-icon>
      </button>
    </div>
  </div>
  <div class="divider divider-grey"></div>

  <table
    class="table table-hover"
    style="table-layout: fixed;word-wrap: break-word;"
    *ngIf="userList && userList.length > 0"
  >
    <thead>
      <tr>
        <th scope="col" style="width:15%">Enabled</th>
        <th scope="col" style="width:35%">Name</th>
        <th scope="col" style="width:35%" class="hidden-md-down">Email</th>
        <th scope="col" style="width:15%" class="hidden-md-down">Delete</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of userList; let i = index">
        <td>
          <mat-checkbox
            [(ngModel)]="user.enabled"
            (click)="toggleClicked.emit(user)"
          ></mat-checkbox>
        </td>
        <td (click)="userClicked.emit(user)">
          <h4 class="service-header full-width">
            {{ user.first }} {{ user.last }}
          </h4>
        </td>
        <td class="hidden-md-down" (click)="userClicked.emit(user)">
          <span class="service-sample-detail">{{ user.email }} </span>
        </td>

        <td>
          <button mat-icon-button (click)="deleteBtnClicked.emit(user)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="row text-center" *ngIf="userList.length == 0">
    <h4 class="col-12">No users in group!</h4>
    <div class="divider divider-grey"></div>

    <div
      class="button-secondary primary-color-full"
      (click)="addUserBtnClicked.emit()"
    >
      <div class="d-flex flex-row justify-content-center align-items-center">
        <mat-icon class="notranslate material-icons">person_add</mat-icon>
        <span>&nbsp;Add User</span>
      </div>
    </div>
  </div>
</div>
