<mat-card>
  <!-- begin toolbar -->
  <mat-toolbar class="d-flex flex-row">
    <span>Question Triggers</span>
    <button
      mat-mini-fab
      color="none"
      class="ml-auto"
      (click)="dialogRef.close(undefined)"
    >
      <mat-icon class="notranslate">clear</mat-icon>
    </button>
  </mat-toolbar>
  <!-- end toolbar -->

  <mat-dialog-content [style.max-height]="'50vh'">
    <mat-checkbox #triggerExistingToggle [hidden]="true"></mat-checkbox>

    <!-- begin main view -->
    <div *ngIf="!triggerExistingToggle.checked" class="d-flex flex-column flex">
      <!-- begin answer select -->
      <div>
        <div class="divider"></div>
        <div class="row no-gutters">
          <div class="col-10 offset-1">
            <mat-form-field
              *ngIf="question.type != 'multiyesno'"
              class="full-width"
            >
              <mat-label>Triggers for Answer</mat-label>
              <mat-select
                [ngModel]="optionId"
                (ngModelChange)="changeOption($event)"
              >
                <mat-option
                  *ngFor="let option of optionList"
                  [value]="option.id"
                >
                  {{ option.value ? option.value : '(option title is blank)' }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <ng-container *ngIf="question.type == 'multiyesno'">
              <mat-form-field class="full-width">
                <mat-label>Triggers for Question</mat-label>
                <mat-select
                  [ngModel]="optionId"
                  (ngModelChange)="changeOption($event)"
                >
                  <mat-option
                    *ngFor="let option of optionList"
                    [value]="option.id"
                  >
                    {{
                      option.text ? option.text : '(question title is blank)'
                    }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="divider"></div>
              <div class="text-center">
                <button
                  (click)="data.yesNoOption = 'No'"
                  *ngIf="data.yesNoOption != 'No'"
                  mat-raised-button
                >
                  No
                </button>
                <button
                  *ngIf="data.yesNoOption == 'No'"
                  mat-raised-button
                  color="primary"
                >
                  No
                </button>
                <span class="space"></span>
                <button
                  (click)="data.yesNoOption = 'Yes'"
                  *ngIf="data.yesNoOption != 'Yes'"
                  mat-raised-button
                >
                  Yes
                </button>
                <button
                  *ngIf="data.yesNoOption == 'Yes'"
                  mat-raised-button
                  color="primary"
                >
                  Yes
                </button>
              </div>
              <hr />
            </ng-container>
          </div>
        </div>
      </div>
      <!-- end answer select -->

      <!-- begin trigger list -->
      <ng-container *ngIf="question.type != 'multiyesno'">
        <div *ngIf="triggerList.length === 0" class="w-100 text-center">
          <span>No subquestions</span>
        </div>
        <div
          *ngFor="let trigger of triggerList; let triggerIndex = index"
          class="d-flex flex-row justify-content-start align-items-center p-2"
        >
          <span class="mx-2">{{
            elementDict[trigger]
              ? elementDict[trigger]
              : '(empty question title)'
          }}</span>
          <button
            mat-raised-button
            class="ml-auto"
            (click)="removeTrigger(triggerIndex)"
          >
            Remove Trigger
          </button>
          <!-- <button mat-raised-button class="mx-1">Delete Subquestion</button> -->
        </div>
      </ng-container>

      <ng-container *ngIf="question.type == 'multiyesno'">
        <div
          *ngFor="
            let trigger of triggerList[data.yesNoOption == 'No' ? 'No' : 'Yes'];
            let triggerIndex = index
          "
          class="d-flex flex-row justify-content-start align-items-center p-2"
        >
          <span calss="mx-2">{{
            elementDict[trigger]
              ? elementDict[trigger]
              : '(empty question title)'
          }}</span>
          <button
            mat-raised-button
            class="ml-auto"
            (click)="removeTrigger(triggerIndex)"
          >
            Remove Trigger
          </button>
          <!-- <button mat-raised-button class="mx-1">Delete Subquestion</button> -->
        </div>
      </ng-container>
      <!-- end trigger list -->
    </div>
    <!-- end main view -->

    <!-- begin existing question view -->
    <div *ngIf="triggerExistingToggle.checked" class="d-flex flex-column flex">
      <!-- begin search bar -->
      <mat-form-field class="full-width">
        <mat-label>Search</mat-label>
        <input
          matInput
          [ngModel]="searchFilter"
          (ngModelChange)="filterList($event)"
        />
      </mat-form-field>
      <!-- end search bar -->

      <!-- begin question list -->
      <mat-list>
        <mat-list-item
          *ngFor="let subquestion of filteredList; let subquestionIndex = index"
          [hidden]="subquestion.id === element.id"
        >
          <mat-grid-list cols="10" rowHeight="40px" class="full-width">
            <mat-grid-tile [colspan]="9">
              {{
                subquestion.text ? subquestion.text : '(empty question title)'
              }}
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1">
              <mat-checkbox
                [ngModel]="
                  !(
                    (question.type === 'multiyesno'
                      ? triggerList[data.yesNoOption]
                      : triggerList
                    ).indexOf(subquestion.id) === -1
                  )
                "
                (ngModelChange)="toggleTrigger($event, subquestion.id)"
              ></mat-checkbox>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-list-item>
      </mat-list>
      <!-- end question list -->
    </div>
    <!-- end existing question view -->
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <ng-container *ngIf="triggerExistingToggle.checked === false">
      <div class="container">
        <div class="row">
          <div class="col-6 text-center">
            <button
              mat-raised-button
              (click)="triggerExistingToggle.checked = true"
            >
              Trigger Existing Question
            </button>
          </div>
          <div class="col-6 text-center">
            <button
              mat-raised-button
              (click)="addNewSubquestion(element.orderNo)"
            >
              Trigger New Question
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="triggerExistingToggle.checked === true">
      <button mat-raised-button (click)="triggerExistingToggle.checked = false">
        Done
      </button>
    </ng-container>
  </mat-dialog-actions>
</mat-card>
