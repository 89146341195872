// @ts-strict-ignore
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'shared-virtual-service',
  templateUrl: './shared-virtual-service.component.html',
  styleUrls: [
    '../../../../../../styles/virtual-care/settings.scss',
    '../../../../../../styles/global/secondary-nav/main.scss',
    './shared-virtual-service.component.scss',
  ],
})
export class SharedVirtualServiceComponent implements OnInit {
  // outputs
  @Output() backBtnClicked = new EventEmitter();
  @Output() deleteBtnClicked = new EventEmitter();
  @Output() saveBtnClicked = new EventEmitter();
  @Output() setMinPrice = new EventEmitter();
  @Output() selectSurvey = new EventEmitter();
  @Output() copiedURLSuccess = new EventEmitter();
  @Output() cancelBtnClicked = new EventEmitter();

  // inputs
  // @Input() selectedService: any;
  @Input() existingServicesArray: any[];
  @Input() existingCategoriesArray: any[];
  @Input() companyPlan = false;
  @Input() companyPlanRestrictions: any = {};
  @Input() selectedServiceURL: any = '';
  @Input() userType = 'doctor';
  @Input() userData: any;
  @Input() newService = false;
  @Input() companyPlanAdmin = false;
  @Input() publicBilling = false;
  @Input() patientSubscriptions = false;
  public itemsCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredItems: Observable<any[]>;

  private _selectedService: any;
  @Output() selectedServiceChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() set selectedService(val: any) {
    this._selectedService = val;
    this.selectedServiceChange.emit(this._selectedService);
  }
  get selectedService(): any {
    return this._selectedService;
  }

  public hoverOver = false;

  ngOnInit() {
    console.log('COMPANY PLANN!!!!');
    console.log(this.companyPlan);
    console.log('public billing: ', this.publicBilling);

    if (!!this.selectedService && !!this.selectedService.data) {
      this.itemsCtrl.setValue(this.selectedService.data.label);
      this.selectedServiceChange.emit(this.selectedService);
    }
    if (
      !this.selectedService.data.category ||
      this.selectedService.data.category === 'null' ||
      this.selectedService.data.category.constructor !== Array
    ) {
      this.selectedService.data.category = [];
    }

    if (!!this.newService && !!this.publicBilling) {
      this.selectedService.data.publicBilling = true;
    }
    console.log(this.selectedService);

    this.filteredItems = this.itemsCtrl.valueChanges.pipe(
      startWith(''),
      map((service) => {
        if (service) {
          this.selectedService.data.label = service;
          this.selectedServiceChange.emit(this.selectedService);
        }
        return service
          ? this.filterItems(service)
          : this.existingServicesArray.slice();
      })
    );
  }

  addCategory(categoryData, newCategory) {
    console.log(categoryData);
    console.log(newCategory);

    // dont add if already in there
    for (const i in categoryData) {
      if (categoryData[i].catID === newCategory.catID) {
        return false;
      }
    }

    categoryData.push({
      catID: newCategory.catID,
      name: newCategory.data.name,
    });
  }

  removeCategory(categoryData, catID) {
    console.log(categoryData);
    console.log(catID);

    for (const i in categoryData) {
      if (categoryData[i].catID === catID) {
        categoryData.splice(i, 1);
        return true;
      }
    }
  }

  filterItems(val: string) {
    return val
      ? this.existingServicesArray.filter(
          (s) => s.data.label.toLowerCase().indexOf(val.toLowerCase()) !== -1
        )
      : this.existingServicesArray;
  }

  setHoverVariable(val: boolean) {
    this.hoverOver = val;
  }

  coverChange(value, type) {
    if (type === 'amount') {
      this.selectedService.data.coverAmount = value;
    } else {
      this.selectedService.data.coverPercent = value;
    }

    if (parseFloat(this.selectedService.data.price) > 0) {
      const price = parseFloat(this.selectedService.data.price);
      console.log(type);
      if (type === 'amount' && this.selectedService.data.coverAmount === 0) {
        this.selectedService.data.coverPercent = 100;
      } else if (
        type === 'percent' &&
        this.selectedService.data.coverPercent === 0
      ) {
        this.selectedService.data.coverAmount = 0;
      } else if (
        type === 'amount' &&
        this.selectedService.data.coverAmount > 0
      ) {
        this.selectedService.data.coverPercent =
          price / this.selectedService.data.coverAmount;
      } else {
        this.selectedService.data.coverAmount =
          price * (this.selectedService.data.coverPercent / 100);
      }
    }
  }

  patientSubscriptionsClicked(val: any) {
    this.selectedService.data.patientSubscriptions = val;
    if (val && this.selectedService.data.publicBilling) {
      this.selectedService.data.publicBilling = false;
    }
  }

  overridePublicBillingClicked(val: any) {
    this.selectedService.data.overridePublicBilling = val;
    if (val) {
      this.selectedService.data.overridePaidAppointment = false;
    }
    // if (val && this.selectedService.data.patientSubscriptions) {
    //   this.selectedService.data.patientSubscriptions = false;
    // }
  }

  overridePaidAppointmentClicked(val: any) {
    this.selectedService.data.overridePaidAppointment = val;
    if (val) {
      this.selectedService.data.overridePublicBilling = false;
    }
    // if (val && this.selectedService.data.overridePaidAppointment) {
    //   this.selectedService.data.overridePaidAppointment = false;
    // }
  }

  publicBillingClicked(val: any) {
    this.selectedService.data.publicBilling = val;
    // if (val && this.selectedService.data.patientSubscriptions) {
    //   this.selectedService.data.patientSubscriptions = false;
    // }
  }
}
