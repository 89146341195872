import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'insig-ui-text-input',
  templateUrl: './insig-text-input.component.html',
  styleUrls: ['./insig-text-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: InsigTextInputComponent,
  }],
})
export class InsigTextInputComponent implements ControlValueAccessor {
  public onChange?: (value: string) => void;
  public onTouched?: () => void;

  public _isReadOnly = false;
  public _isRequired = false;
  public _isDisabled = false;

  @Input() set isReadOnly(isReadOnly: boolean | 'true' | 'false') {
    this._isReadOnly = coerceBooleanProperty(isReadOnly);
  }
  @Input() set isRequired(isRequired: boolean | 'true' | 'false') {
    this._isRequired = coerceBooleanProperty(isRequired);
  }
  @Input() set isDisabled(isDisabled: boolean | 'true' | 'false') {
    this._isDisabled = coerceBooleanProperty(isDisabled);
  }

  @Input() autocomplete = '';
  @Input() label = '';
  @Input() name = '';
  @Input() placeholder = '';
  @Input() type = 'text';
  @Input() value = '';
  @Input() smallMargins = false;

  @ViewChild('inputElement')
  public inputElement: ElementRef | undefined;

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
  }

  handleChange(event: Event): void {
    this.onChange?.((event.target as HTMLInputElement).value);
    this.onTouched?.();
  }

  handleBlur(): void {
    this.onTouched?.();
  }
}
