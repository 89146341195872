<mat-card>
  <div class="divider"></div>

  <div class="row minWidth">
    <div class="col-10 offset-1">
      <mat-form-field class="full-width">
        <mat-label>Type here</mat-label>
        <textarea
          matInput
          autofocus
          #textBox
          rows="3"
          autocomplete="off"
          [(ngModel)]="text"
          (keyup.enter)="dialogRef.close(text)"
        ></textarea>
      </mat-form-field>
    </div>
  </div>

  <div class="divider"></div>
  <div class="divider"></div>
  <button
    [disabled]="loading"
    mat-raised-button
    (click)="dialogRef.close(false)"
  >
    Cancel
  </button>
  <span class="space"></span>
  <span class="space float-right"></span>
  <button
    class="float-right"
    [disabled]="loading"
    mat-raised-button
    color="primary"
    (click)="dialogRef.close(text)"
  >
    Done
  </button>
  <span class="space float-right" *ngIf="signature"></span>
  <button
    class="float-right"
    *ngIf="signature"
    [disabled]="loading"
    mat-raised-button
    (click)="dialogRef.close('signature')"
  >
    Signature
  </button>
</mat-card>
