/**
 * Insig Booking Flow APIs
 * The specification contains all booking flow APIs
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@insighealth.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Province = 'ON' | 'AB' | 'NL' | 'PE' | 'NS' | 'NB' | 'QC' | 'MB' | 'SK' | 'BC' | 'YT' | 'NT' | 'NU';

export const Province = {
    On: 'ON' as Province,
    Ab: 'AB' as Province,
    Nl: 'NL' as Province,
    Pe: 'PE' as Province,
    Ns: 'NS' as Province,
    Nb: 'NB' as Province,
    Qc: 'QC' as Province,
    Mb: 'MB' as Province,
    Sk: 'SK' as Province,
    Bc: 'BC' as Province,
    Yt: 'YT' as Province,
    Nt: 'NT' as Province,
    Nu: 'NU' as Province
};

