<div class="container-fluid">
  <mat-radio-group
    [(ngModel)]="question.response.selectedAnswer"
    (change)="handleOfferedAnswerChanged($event)"
    class="vertical-group"
  >
    <label
      *ngFor="let answer of question.offeredAnswers; let i = index"
      class="d-flex"
      (click)="modifyTriggers(i)"
    >
      <mat-radio-button [value]="answer.id">{{ answer.value }}</mat-radio-button>
    </label>

    <label
      *ngIf="question.otherAnswer"
      class="d-flex align-items-center"
      (click)="removeAllTriggers()"
    >
      <mat-radio-button class="mt-2" [value]="'other'" ngDefaultControl></mat-radio-button>
      <span>Other:&nbsp;</span>
      <mat-form-field class="w-75 ml-2">
        <textarea
          rows="1"
          matInput
          type="text"
          [ngModel]="question.response.other"
          (ngModelChange)="handleOtherResponseChange($event)"
          [disabled]="question.response.selectedAnswer != 'other'"
        ></textarea>
      </mat-form-field>
    </label>
  </mat-radio-group>
</div>
