// @ts-strict-ignore
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'book-appointment-question',
  templateUrl: './book-appointment-question.component.html',
  styleUrls: ['./book-appointment-question.component.scss'],
})
export class BookAppointmentQuestionComponent implements OnChanges {
  @Input() public question: Question;
  @Output() public questionChange = new EventEmitter<Question>();
  @Input() public patientInfo: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }
}
