import {
  Component,
  OnInit,
} from '@angular/core';
import { JAVA_BACKEND_ENDPOINT } from '@insig-health/config/config';

@Component({
  selector: 'my-page-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class PageSignUpComponent implements OnInit {
  ngOnInit() {
    window.location.href = `${JAVA_BACKEND_ENDPOINT}doctor/start`;
  }
}
