// @ts-strict-ignore
/**
 * Question editor switch-case multiplexer.
 * This component is not meant to be used stand-alone. It must be used within
 * the context of an EditElementComponent, and is only abstracted to reduce
 * code complexity.
 */
import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Question } from 'insig-types/surveys/question';

@Component({
  selector: 'edit-question',
  templateUrl: './edit-question.component.html',
})
export class EditQuestionComponent {
  /** The question object this component represents */
  @Input() question: Question; // TODO make this immutable so we can use OnPush change detection
  /** Emits question if any recursive changes to it were made */
  @Output() questionChange = new EventEmitter<Question>();

  /** Whether or not to show the active view */
  @Input() isActive: boolean;

  /** The surveyID to save external files under. Optional but recommended for organization purposes. */
  @Input() surveyID?: string;
  /** The userID to save external files under. Required for read/write permissions. */
  @Input() userID: string;

  /** A function that opens the trigger dialog and performs survey-wide changes. Should be provided by edit-element. */
  @Input() openTriggerDialog: (initialId: string) => void;
  /** A function that opens the multi yes no trigger dialog and performs survey-wide changes. Should be provided by edit-element. */
  @Input() openMultiYesNoTriggerDialog: (
    initialId: string,
    yesNoOption: 'Yes' | 'No'
  ) => void;
}
