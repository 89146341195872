<mat-form-field class="full-width">
  <mat-label>Youtube URL</mat-label>
  <input
    #localInput="ngModel"
    matInput
    validateYoutube
    spellcheck="false"
    type="text"
    [ngModel]="ngModel ? ngModel : emptyString"
  />
  <mat-error>The URL must begin with https://www.youtube.com/</mat-error>
</mat-form-field>

<div class="w-100 my-3 d-flex justify-content-center">
  <button
    mat-raised-button
    [disabled]="!localInput.valid"
    (click)="loadedURL = ngModel"
  >
    Load Youtube video
  </button>
</div>

<youtube-embed-container
  class="w-100 d-flex justify-content-center"
  *ngIf="loadedURL"
  [src]="loadedURL | youtubeEmbedSecurityPipe"
></youtube-embed-container>
