<!-- begin active view -->
<div cdkDropList (cdkDropListDropped)="drop($event)">
  <!-- begin offeredAnswers -->
  <div
    *ngFor="let answer of question.offeredAnswers; let i = index"
    cdkDrag
    [cdkDragData]="answer"
  >
    <div class="w-100 d-flex align-items-center">
      <button
        mat-icon-button
        matTooltip="Drag"
        matTooltipPosition="left"
        cdkDragHandle
      >
        <i class="notranslate fas fa-arrows-alt-v fa-2x"></i>
      </button>
      <div class="w-100 row no-gutters px-2">
        <div class="col-9">
          <mat-form-field class="full-width px-1">
            <input
              id="offered-answer-value-input"
              required
              matInput
              [ngModel]="answer.value"
              (ngModelChange)="onAnswerChange(i, $event)"
            />
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field class="full-width px-1">
            <mat-label>Score</mat-label>
            <input
              id="offered-answer-score-input"
              required
              matInput
              type="number"
              [ngModel]="answer.score"
              (ngModelChange)="onScoreChange(i, $event)"
              spellcheck="false"
            />
          </mat-form-field>
        </div>
      </div>
      <button
        id="offered-answer-flagging-menu-button"
        [ngStyle]="{ background: answer.flag ? answer.flag : 'White' }"
        mat-mini-fab
        color="none"
        [insMenuTriggerFor]="colorMenu"
        matTooltip="Flag Question"
        matTooltipPosition="above"
      >
        <mat-icon class="notranslate">flag</mat-icon>
      </button>
      <ins-menu #colorMenu="insMenu">
        <div mat-menu-item (click)="answer.flag = false">
          <button mat-raised-button>
            <mat-icon class="notranslate">clear</mat-icon>
          </button>
        </div>
        <div
          *ngFor="let color of flagColors"
          mat-menu-item
          (click)="answer.flag = color.color"
        >
          <button
            id="offered-answer-flagging-select-flag-button"
            [ngStyle]="{ background: color.color }"
            mat-raised-button
          >
            <mat-icon class="notranslate">flag</mat-icon>
          </button>
        </div>
      </ins-menu>
      <button
        class="ml-2"
        mat-mini-fab
        [color]="answer.integrationID ? 'accent' : null"
        (click)="openIntegrationsDialog(answer)"
      >
        <mat-icon class="notranslate">cloud_queue</mat-icon>
      </button>
      <button
        class="ml-2"
        mat-mini-fab
        color="none"
        matTooltip="Remove"
        matTooltipPosition="right"
        (click)="question.offeredAnswers.splice(i, 1)"
      >
        <mat-icon class="notranslate">clear</mat-icon>
      </button>
    </div>
  </div>
  <!-- end offeredAnswers -->
  <!-- begin otherAnswer -->
  <div *ngIf="question.otherAnswer" class="w-100 d-flex align-items-center">
    <mat-form-field class="px-2">
      <mat-label>Other:</mat-label>
      <input id="other-answer-input" matInput />
    </mat-form-field>
    <button
      id="remove-other-answer-button"
      mat-mini-fab
      color="none"
      matTooltip="Remove"
      matTooltipPosition="right"
      (click)="toggleOtherAnswer()"
    >
      <mat-icon class="notranslate">clear</mat-icon>
    </button>
  </div>
  <!-- end otherAnswer -->
  <!-- begin config -->
  <div class="d-flex justify-content-center">
    <button
      mat-raised-button
      class="mx-1"
      (click)="addOption(question.offeredAnswers.length)"
    >
      <span>Add Option</span>
    </button>
  </div>
  <!-- end config -->
</div>
<!-- end active view -->
