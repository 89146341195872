<form [formGroup]="yearMonthDayForm">
  <div class="form-group row">
    <div class="col-2"></div>
    <div class="col-8">
      <div class="form-group row">
        <div class="col-sm-4 col-xs-12">
          <mat-form-field class="full-width marginTop05em">
            <mat-label>Year</mat-label>
            <input
              type="number"
              matInput
              autocomplete="off"
              [attr.maxlength]="4"
              formControlName="year"
            />
          </mat-form-field>
        </div>
        <div class="col-sm-4 col-xs-12">
          <mat-form-field class="full-width pt-2">
            <mat-label>Month</mat-label>
            <mat-select formControlName="month">
              <mat-option *ngFor="let month of months" [value]="month">
                {{ month }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-4 col-xs-12">
          <mat-form-field class="full-width marginTop05em">
            <mat-label>Day</mat-label>
            <input
              type="number"
              matInput
              autocomplete="off"
              [attr.maxlength]="2"
              formControlName="day"
            />
            <mat-error>Please enter a valid day</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-2"></div>
  </div>
</form>
