// @ts-strict-ignore
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges, inject } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

import { MatDialog } from '@angular/material/dialog';

import { DeleteFolderDialogComponent } from '../../../dialogs/delete-folder-dialog.component';
import { FoldersService } from '../../../services/folders.service';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

@Component({
  selector: 'show-surveys-sidenav',
  styles: [
    `
      .renamingButtonLabel {
        position: absolute;
        left: 0;
        z-index: 1;
        background-color: white;
      }
      .height10px {
        height: 10px;
      }
      .height300px {
        height: 300px;
      }
      .noBorder {
        border: 0;
      }
    `,
  ],
  templateUrl: './show-surveys-sidenav.component.html',
  providers: [FoldersService],
  animations: [
    trigger('shrinkOut', [
      state('in', style({ height: '*' })),
      transition('* => void', [
        style({ height: 300 }),
        animate(500, style({ height: 0 })),
      ]),
      transition('void => *', [
        style({ height: 0 }),
        animate(250, style({ height: '*' })),
      ]),
    ]),
    trigger('fadeOut', [
      state('in', style({ height: '*' })),
      transition('* => void', [
        style({ opacity: 1 }),
        animate(100, style({ opacity: 0 })),
      ]),
      transition('void => *', [
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ShowSurveysSidenavComponent implements OnChanges {
  private readonly dialog = inject(MatDialog);
  private readonly folderService = inject(FoldersService);
  private readonly firebaseAuthService = inject(FirebaseAuthService);
  private _selectedTab: any;
  public showSpacing = true;

  @Input() companyData: any;

  // selectedTab
  @Output() selectedTabChange = new EventEmitter();
  set selectedTab(val) {
    this._selectedTab = val;
    this.selectedTabChange.emit(this._selectedTab);
  }

  @Input() get selectedTab() {
    return this._selectedTab;
  }

  // newFolderFunction
  @Output() newFolder = new EventEmitter<string>();

  // createnewsurvey function
  @Output() createNewSurvey = new EventEmitter<string>();

  @Output() resetVariables = new EventEmitter<string>();

  @Input() mobile: any;

  @Input() selectedSurvey: any;

  @Input() selectedFolder: any;
  @Output() selectedFolderChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() onEditClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPatientPreviewClicked: EventEmitter<any> = new EventEmitter<
    any
  >();
  @Output() onDeleteFolderConfirmed: EventEmitter<any> = new EventEmitter<
    any
  >();

  ngOnChanges(change: any) {
    if (change.selectedSurvey) {
      if (change.selectedSurvey.currentValue) {
        this.showSpacing = false;
      } else if (!this.selectedFolder) {
        this.showSpacing = true;
      }
    }

    if (change.selectedFolder) {
      this.showSpacing = !change.selectedFolder.currentValue;
    }
  }

  changeTab(item: string) {
    this.selectedTab = item;
    this.resetVariables.emit();
  }

  openDeleteFolderDialog() {
    const dialogRef = this.dialog.open(DeleteFolderDialogComponent);
    dialogRef.afterClosed().subscribe((deleteConfirmed) => {
      if (deleteConfirmed) {
        this.onDeleteFolderConfirmed.emit();
      }
    });
  }

  saveFolder(folder: any): Promise<void> {
    console.log(folder);
    console.log(this.firebaseAuthService.getFirebaseCurrentUser().uid);
    return this.folderService.setUserFolder(
      this.firebaseAuthService.getFirebaseCurrentUser().uid,
      folder
    );
  }
}
