<div *ngIf="!question.optionsSetup" class="text-center">
  <p>Use commas to separate each option you want to offer to autocomplete</p>
  <div class="row">
    <div class="col-10 offset-1">
      <mat-form-field class="full-width">
        <mat-label>Autocomplete Options</mat-label>
        <textarea
          matInput
          type="text"
          rows="3"
          [(ngModel)]="options"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="divider"></div>
  <button mat-raised-button color="primary" (click)="setOptions()">
    Set Options
  </button>
</div>

<div *ngIf="question.optionsSetup">
  <div *ngIf="allowMultiple">
    <div class="form-group row">
      <div class="col-2"></div>
      <div class="col-8">
        <mat-chip-listbox class="text-center">
          <div
            *ngFor="
              let item of previewQuestion.response.selectedAnswers;
              let i = index
            "
          >
            <div *ngIf="!(item === undefined)">
              <span class="space space-md"></span>
              <span color="primary">{{ item }} &nbsp;</span>
              <button
                mat-mini-fab
                color="none"
                (click)="removeItem(item)"
              >
                <mat-icon class="notranslate">clear</mat-icon>
              </button>
            </div>
          </div>
        </mat-chip-listbox>
      </div>
      <div class="col-2"></div>
    </div>

    <div class="form-group row">
      <div class="col-2"></div>
      <div class="col-8">
        <mat-form-field class="full-width">
          <mat-label>Enter answer</mat-label>
          <input
            matInput
            (keyup.enter)="addItem(question.response.selectedAnswers[-1])"
            [(ngModel)]="previewQuestion.response.selectedAnswers[-1]"
            [matAutocomplete]="auto"
            #itemsSelect
            [formControl]="itemsCtrl"
          />
        </mat-form-field>
      </div>
      <div class="col-2"></div>
    </div>

    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        *ngFor="let item of filteredItems | async"
        (click)="addItem(item)"
        [value]="item"
      >
        <mat-icon class="notranslate">add_circle_outline</mat-icon>{{ item }}
      </mat-option>
      <mat-option
        class="notranslate"
        *ngIf="previewQuestion.response.selectedAnswers[-1] != null"
        (click)="addItem(previewQuestion.response.selectedAnswers[-1])"
        [value]="previewQuestion.response.selectedAnswers[-1]"
      >
        <mat-icon class="notranslate">add_circle_outline</mat-icon
        >{{ previewQuestion.response.selectedAnswers[-1] }}
      </mat-option>
    </mat-autocomplete>
  </div>

  <div *ngIf="!allowMultiple">
    <div class="form-group row">
      <div class="col-12"></div>
    </div>
    <!-- empty spacer -->
    <div class="form-group row">
      <div class="col-2"></div>
      <div class="col-8">
        <mat-form-field class="full-width">
          <mat-label>Enter answer</mat-label>
          <input
            matInput
            [(ngModel)]="previewQuestion.response.selectedAnswers[0]"
            [matAutocomplete]="auto"
            #itemsSelect
            [formControl]="itemsCtrl"
          />
        </mat-form-field>
      </div>
      <div class="col-2"></div>
    </div>

    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let item of filteredItems | async" [value]="item">
        {{ item }}
      </mat-option>
    </mat-autocomplete>
  </div>

  <div class="text-center">
    <div class="row">
      <div class="col-6 text-right">
        <mat-checkbox
          [checked]="allowMultiple"
          (change)="this.allowMultiple = $event.checked"
          >Allow Multiple Answers</mat-checkbox
        >
      </div>
      <div class="col-6 text-left">
        <button mat-raised-button color="primary" (click)="modifyOptions()">
          Modify Options
        </button>
      </div>
    </div>
  </div>
</div>

<div class="divider"></div>
