<div [hidden]="filtersToggle === true || notesToggle === true">
  <!-- #region question type element -->
  <ng-container *ngIf="element.type === 'question'">
    <h4 *ngIf="!isActive" class="mt-0 mb-2">
      {{ element.question?.text || '(click to enter question title)' }}
    </h4>
    <div *ngIf="isActive" class="d-flex flex-row">
      <mat-form-field class="full-width">
        <mat-label>Question Title</mat-label>
        <input
          id="question-title-input"
          matInput
          required
          type="text"
          [(ngModel)]="element.question.text"
          (ngModelChange)="
            element.question.text = $event; elementChange.emit(element)
          "
        />
        <mat-error>Required</mat-error>
      </mat-form-field>
      &nbsp;
      <mat-form-field>
        <mat-label>Question Type</mat-label>
        <mat-select
          id="question-type-mat-select"
          [ngModel]="element.question.type"
          (ngModelChange)="
            element = changeQuestionType(element, $event);
            elementChange.emit(element)
          "
        >
          <mat-option
            *ngFor="let questionType of questionTypes"
            [value]="questionType.type"
          >
            {{ questionType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        mat-mini-fab
        color="none"
        disableRipple
        insTooltip="Change Element Type"
        insTooltipPosition="above"
        (click)="openQuestionTypesDialog(element)"
      >
        <mat-icon class="notranslate">grid_on</mat-icon>
      </button>
    </div>

    <edit-question
      [userID]="userID"
      [surveyID]="surveyID"
      [(question)]="element.question"
      [isActive]="isActive"
      [openTriggerDialog]="openTriggerDialog"
      [openMultiYesNoTriggerDialog]="openMultiYesNoTriggerDialog"
    >
    </edit-question>
  </ng-container>
  <!-- #endregion question type element -->

  <!-- #region paragraph type element -->
  <ng-container *ngIf="element.type === 'paragraph'">
    <ng-container *ngIf="isActive">
      <div>
        <button
          mat-raised-button
          disableRipple
          (click)="element = changeToQuestion(element)"
        >
          <mat-icon class="notranslate">autorenew</mat-icon>&nbsp;Switch to
          Question
        </button>

        <mat-checkbox
          class="ml-5"
          [ngModel]="element.paragraph.patient"
          (ngModelChange)="
            element.paragraph.patient = $event; elementChange.emit(element)
          "
        >
          Patient Edit
        </mat-checkbox>
      </div>
      <ckeditor
        [ngModel]="element.paragraph.html"
        (ngModelChange)="
          element.paragraph.html = $event; elementChange.emit(element)
        "
        [readonly]="false"
      ></ckeditor>
    </ng-container>

    <ng-container *ngIf="!isActive">
      <h4 class="my-0">Paragraph</h4>
      <div class="divider"></div>
      <div *ngIf="!element.paragraph.html" class="w-100 text-center">
        (click to enter paragraph text)
      </div>
      <div
        *ngIf="element.paragraph.html"
        [innerHTML]="element.paragraph.html | safeHtml"
      ></div>
      <div class="divider"></div>
    </ng-container>
  </ng-container>
  <!-- #endregion paragraph type element -->
</div>

<!-- #region filter settings -->
<div [hidden]="filtersToggle === false">
  <mat-toolbar>
    <div class="w-100 d-flex flex-row justify-content-start align-items-center">
      <span>Filters</span>
      <button
        mat-raised-button
        disableRipple
        class="ml-auto"
        (click)="filtersToggle = false"
      >
        Back
      </button>
    </div>
  </mat-toolbar>
  <mat-tab-group
    [selectedIndex]="element.filter?.gender"
    (selectedTabChange)="
      elementChange.emit(changeGenderFilter(element, $event))
    "
  >
    <mat-tab label="None"></mat-tab>
    <mat-tab label="Females"></mat-tab>
    <mat-tab label="Males"></mat-tab>
  </mat-tab-group>
  <mat-tab-group
    [selectedIndex]="element.filter?.ageType"
    (selectedTabChange)="elementChange.emit(changeAgeFilter(element, $event))"
  >
    <mat-tab label="None"></mat-tab>
    <mat-tab label="Younger">
      <span>
        Age is less than&nbsp;
        <mat-form-field *ngIf="element.filter">
          <input
            matInput
            type="number"
            [ngModel]="element.filter?.ageMax"
            (ngModelChange)="
              element.filter.ageMax = $event; elementChange.emit(element)
            "
          />
        </mat-form-field>
      </span>
    </mat-tab>
    <mat-tab label="Between">
      <span>
        Age is between&nbsp;
        <mat-form-field *ngIf="element.filter">
          <input
            matInput
            type="number"
            (blur)="elementChange.emit(validateAgeMin(element))"
            [ngModel]="element.filter?.ageMin"
            (ngModelChange)="
              element.filter.ageMin = $event; elementChange.emit(element)
            "
          />
        </mat-form-field>
        &nbsp;and&nbsp;
        <mat-form-field *ngIf="element.filter">
          <input
            matInput
            type="number"
            (blur)="elementChange.emit(validateAgeMax(element))"
            [ngModel]="element.filter?.ageMax"
            (ngModelChange)="
              element.filter.ageMax = $event; elementChange.emit(element)
            "
          />
        </mat-form-field>
      </span>
    </mat-tab>
    <mat-tab label="Older">
      Age is greater than&nbsp;
      <mat-form-field *ngIf="element.filter">
        <input
          matInput
          type="number"
          [ngModel]="element.filter.ageMin"
          (ngModelChange)="
            element.filter.ageMin = $event; elementChange.emit(element)
          "
        />
      </mat-form-field>
    </mat-tab>
  </mat-tab-group>
</div>
<!-- #endregion filter settings -->

<!-- #region note settings -->
<div [hidden]="notesToggle === false">
  <mat-toolbar>
    <div class="w-100 d-flex flex-row justify-content-start align-items-center">
      <span>Medical Note</span>
      <button
        mat-raised-button
        disableRipple
        class="ml-auto"
        (click)="notesToggle = false"
      >
        Back
      </button>
    </div>
  </mat-toolbar>
  <div class="divider"></div>
  <div class="row">
    <div class="col-6">
      <mat-form-field class="full-width">
        <mat-label>Select a note section</mat-label>
        <mat-select
          [ngModel]="element.note?.location"
          (ngModelChange)="
            elementChange.emit(changeNoteLocation(element, $event))
          "
        >
          <mat-option>None</mat-option>
          <mat-option *ngFor="let location of noteLocations" [value]="location">
            {{ location }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field class="full-width">
        <mat-label>or type your own</mat-label>
        <input
          matInput
          [ngModel]="element.note?.location"
          (ngModelChange)="
            elementChange.emit(changeNoteLocation(element, $event))
          "
        />
      </mat-form-field>
    </div>
  </div>

  <ng-container
    *ngIf="
      !!element.note?.location &&
      element.type === 'question' &&
      !!element.question
    "
  >
    <div
      class="mt-2 text-center"
      *ngIf="!element.note.first && !element.note.last"
    >
      <mat-checkbox
        [ngModel]="element.note.qa"
        (ngModelChange)="element.note.qa = $event; elementChange.emit(element)"
        >"Question - <b>Answer</b>" note sentence</mat-checkbox
      >
    </div>

    <ng-container
      *ngIf="
        element.question.type !== 'yesno' &&
        element.question.type !== 'medicationAllergies' &&
        element.question.type !== 'conditions' &&
        element.question.type !== 'surgery' &&
        element.question.type !== 'heightWeight'
      "
    >
      <h5 class="mt-2">Enter a sentence to appear in the note</h5>
      <mat-form-field class="full-width">
        <mat-label>First part of note</mat-label>
        <input
          matInput
          autocomplete="off"
          [ngModel]="element.note.first"
          (ngModelChange)="
            element.note.first = $event; elementChange.emit(element)
          "
        />
      </mat-form-field>
      <div class="text-center">(Patient response appears between)</div>
      <mat-form-field class="full-width">
        <mat-label>Second part of note</mat-label>
        <input
          matInput
          autocomplete="off"
          [ngModel]="element.note.last"
          (ngModelChange)="
            element.note.last = $event; elementChange.emit(element)
          "
        />
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="element.question.type == 'yesno'">
      <div>
        <h5>Enter the sentence to appear in the note</h5>
      </div>
      <mat-form-field class="full-width">
        <mat-label>Note if answered yes</mat-label>
        <input
          matInput
          autocomplete="off"
          [ngModel]="element.note.first"
          (ngModelChange)="
            element.note.first = $event; elementChange.emit(element)
          "
        />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Note if answered no</mat-label>
        <input
          matInput
          autocomplete="off"
          [ngModel]="element.note.last"
          (ngModelChange)="
            element.note.last = $event; elementChange.emit(element)
          "
        />
      </mat-form-field>
    </ng-container>

    <div
      *ngIf="element.question.type == 'medicationAllergies'"
      class="text-center"
    >
      <h5>Note will appear as:</h5>
      <h5>'Patient has no known drug allergies'</h5>
      <h5>OR</h5>
      <h5>'Patient reports having drug allergies including ...'</h5>
    </div>

    <div *ngIf="element.question.type == 'heightWeight'" class="text-center">
      <h5>Note will appear as:</h5>
      <h5>'Patient reports a height of _____ and a weight of _____'</h5>
    </div>

    <div *ngIf="element.question.type == 'surgery'" class="text-center">
      <h5>Note will appear as:</h5>
      <h5>'Patient reports having no past surgeries'</h5>
      <h5>OR</h5>
      <h5>'Patient reports having had surgery including ...'</h5>
    </div>

    <div *ngIf="element.question.type == 'conditions'" class="text-center">
      <h5>Note will appear as:</h5>
      <h5>'Patient reports having no known medical conditions'</h5>
      <h5>OR</h5>
      <h5>'Patient reports having medical conditions including ...'</h5>
    </div>

    <h5 class="mt-3 mb-0">More Options</h5>
    <div class="divider"></div>
    <div class="row">
      <div class="col-md-6">
        <div class="w-100 d-flex flex-column mb-2">
          <mat-checkbox
            class="mr-2"
            [ngModel]="element.note.lineBreakBefore"
            (ngModelChange)="
              element.note.lineBreakBefore = $event; elementChange.emit(element)
            "
            >Add line breaks before sentence</mat-checkbox
          >
          <mat-form-field *ngIf="element.note.lineBreakBefore">
            <mat-label># of line breaks</mat-label>
            <input
              matInput
              [ngModel]="element.note.lineBreakBefore"
              (ngModelChange)="
                element.note.lineBreakBefore = $event;
                elementChange.emit(element)
              "
              type="number"
              min="0"
              max="8"
            />
          </mat-form-field>
        </div>
        <div class="w-100 d-flex flex-column mb-2">
          <mat-checkbox
            class="mr-2"
            [ngModel]="element.note.lineBreakAfter"
            (ngModelChange)="
              element.note.lineBreakAfter = $event; elementChange.emit(element)
            "
            >Add line breaks after sentence</mat-checkbox
          >
          <mat-form-field *ngIf="element.note.lineBreakAfter">
            <mat-label># of line breaks</mat-label>
            <input
              matInput
              [ngModel]="element.note.lineBreakAfter"
              (ngModelChange)="
                element.note.lineBreakAfter = $event;
                elementChange.emit(element)
              "
              type="number"
              min="0"
              max="8"
            />
          </mat-form-field>
        </div>
        <mat-checkbox
          class="mb-2 w-100"
          [ngModel]="element.cpp"
          (ngModelChange)="element.cpp = $event; elementChange.emit(element)"
          >Insig CPP (autofill & update)</mat-checkbox
        >
      </div>
      <div class="col-md-6">
        <div class="mb-2 w-100">
          <mat-checkbox
            [ngModel]="element.note.bullet"
            (ngModelChange)="
              element.note.bullet = $event; elementChange.emit(element)
            "
            >Bullet Point</mat-checkbox
          >
        </div>
        <div class="mb-2 w-100">
          <mat-checkbox
            class="mb-2 w-100"
            [ngModel]="element.note.removePeriod"
            (ngModelChange)="
              element.note.removePeriod = $event; elementChange.emit(element)
            "
            >Don't Include Period</mat-checkbox
          >
        </div>
      </div>
    </div>
  </ng-container>

  <div class="divider"></div>
</div>
<!-- #endregion note settings -->

<!-- #region element footer actions -->
<br *ngIf="!isActive && element.type === 'question'" />
<hr *ngIf="isActive" />

<mat-card-footer class="mb-0 mx-0 pb-0">
  <!-- footer actions are separated into two rows: top and bottom -->
  <!-- #region top row actions -->
  <div class="d-flex flex-row justify-content-start">
    <!-- #region notes section selection -->
    <ng-container
      *ngIf="
        !(element.type === 'question' && element.question?.type === 'survey')
      "
    >
      <button
        mat-mini-fab
        disableRipple
        [color]="!!element.note?.location ? 'accent' : 'none'"
        insTooltip="Edit Note"
        insTooltipPosition="above"
        (click)="notesToggle = !notesToggle; filtersToggle = false"
      >
        <mat-icon class="notranslate">note</mat-icon>
      </button>
      &nbsp;
      <mat-form-field class="full-width">
        <mat-label>Note Section</mat-label>
        <mat-select
          [ngModel]="element.note?.location"
          (ngModelChange)="
            changeNoteLocation(element, $event); elementChange.emit(element)
          "
        >
          <mat-option>None</mat-option>
          <mat-option
            *ngFor="let location of noteLocations"
            [value]="location"
            >{{ location }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </ng-container>
    <!-- #endregion notes section selection -->
    &nbsp;
    <!-- #region enter integration ID -->
    <ng-container *ngIf="isActive && element.type === 'question'">
      <mat-form-field class="ml-auto">
        <mat-label>Integration ID</mat-label>
        <input
          matInput
          [ngModel]="element.integrationID"
          (ngModelChange)="
            element.integrationID = $event; elementChange.emit(element)
          "
        />
      </mat-form-field>
      <div class="d-flex align-items-center" *ngIf="element.integrationID">
        <mat-checkbox [(ngModel)]="element.integrationValues">Data</mat-checkbox>
      </div>
    </ng-container>
    <!-- #region enter integration ID -->
  </div>
  <!-- #endregion top row actions -->

  <br *ngIf="isActive && element.type === 'question'" />

  <!-- #region bottom row actions -->
  <div class="d-flex flex-row gap-2 justify-content-start align-items-center">
    <!-- #region filters -->
    <button
      mat-mini-fab
      disableRipple
      class="mx-0"
      [color]="
        element.filter &&
        (element.filter?.ageType !== '0' || element.filter?.gender !== '0')
          ? 'accent'
          : 'none'
      "
      (click)="filtersToggle = !filtersToggle; notesToggle = false"
      insTooltip="Filters"
      insTooltipPosition="above"
    >
      <mat-icon class="notranslate">filter_list</mat-icon>
    </button>
    <!-- #endregion filters -->

    <!-- #region practitioner question toggle -->
    <button
      mat-mini-fab
      disableRipple
      class="mx-0"
      [color]="element.doctor ? 'accent' : 'none'"
      (click)="togglePractitionerQuestion(element)"
      insTooltip="Practitioner Element"
      insTooltipPosition="above"
    >
      <i class="fa fa-stethoscope" style="font-size:24px;"></i>
    </button>
    <!-- #endregion practitioner question toggle -->

    <!-- #region full question flagging -->
    <ng-container *ngIf="element.type === 'question'">
      <button
        mat-mini-fab
        color="none"
        disableRipple
        class="mx-0"
        [ngStyle]="{
          background: element.question.flag ? element.question.flag : 'White',
          color: element.question.flag ? 'White' : 'Black'
        }"
        [insMenuTriggerFor]="colorMenu"
        insTooltip="Flag Question"
        insTooltipPosition="above"
      >
        <mat-icon class="notranslate">flag</mat-icon>
      </button>
      <ins-menu #colorMenu="insMenu">
        <div
          mat-menu-item
          (click)="element.question.flag = false; surveyChanged = true"
        >
          <button mat-raised-button disableRipple>
            <mat-icon class="notranslate">clear</mat-icon>
          </button>
        </div>
        <div
          *ngFor="let color of flagColorsService.getFlagColors()"
          mat-menu-item
          (click)="element.question.flag = color.color; surveyChanged = true"
        >
          <button
            [ngStyle]="{ background: color.color }"
            mat-raised-button
            disableRipple
          >
            <mat-icon class="notranslate">flag</mat-icon>
          </button>
        </div>
      </ins-menu>
    </ng-container>
    <!-- #endregion full question flagging -->

    <!-- #region parent question scroll-to button -->
    <ng-container
      *ngIf="
        !!getParentQuestionsTooltip &&
        !!getParentQuestionsList &&
        !!setActiveElement &&
        !!questionDict
      "
    >
      <span
        class="mx-0"
        [insTooltip]="'No parent question'"
        insTooltipPosition="above"
        [insTooltipDisabled]="element.pageFlow ? element.pageFlow.child : false"
      >
        <button
          mat-mini-fab
          color="none"
          disableRipple
          [color]="element.pageFlow ? 'accent' : null"
          [style.z-index]="10"
          [insTooltip]="getParentQuestionsTooltip(element)"
          [insMenuTriggerFor]="parentTriggersMenu"
          [disabled]="element.pageFlow ? !element.pageFlow.child : true"
        >
          <mat-icon class="notranslate">call_merge</mat-icon>
        </button>
      </span>
    </ng-container>
    <!-- #endregion parent question scroll-to button -->

    <ng-container
      *ngIf="
        element.type === 'question' &&
        !!element.question &&
        element.question.type !== 'grid' &&
        element.question.type !== 'pdf' &&
        element.question.type !== 'physicalGrid' &&
        element.question.type !== 'survey'
      "
    >
      <mat-slide-toggle
        [ngModel]="element.question.required"
        (ngModelChange)="
          element.question.required = $event; elementChange.emit(element)
        "
      >
        Required
      </mat-slide-toggle>
    </ng-container>

    <ng-container *ngIf="!!page">
      <div class="ml-auto d-flex gap-2">
        <button
          *ngIf="isActive"
          class="mr-5"
          id="copy-element-button"
          mat-mini-fab
          color="none"
          disableRipple
          insTooltip="Copy element"
          insTooltipPosition="above"
          (click)="copyElement(page, page.elements.indexOf(element), element)"
        >
          <mat-icon class="notranslate">content_copy</mat-icon>
        </button>

        <button
          id="move-element-up-button"
          mat-mini-fab
          color="none"
          disableRipple
          insTooltip="Move up"
          insTooltipPosition="left"
          (click)="moveElementUp(page, page.elements.indexOf(element))"
          [disabled]="page.elements.indexOf(element) === 0"
        >
          <mat-icon class="notranslate">arrow_drop_up</mat-icon>
        </button>

        <button
          id="move-element-down-button"
          mat-mini-fab
          color="none"
          disableRipple
          insTooltip="Move down"
          [style.z-index]="10"
          insTooltipPosition="right"
          (click)="moveElementDown(page, page.elements.indexOf(element))"
          [disabled]="
            page.elements.indexOf(element) === page.elements.length - 1
          "
        >
          <mat-icon class="notranslate">arrow_drop_down</mat-icon>
        </button>

        <button
          *ngIf="isActive"
          id="delete-element-button"
          mat-mini-fab
          color="none"
          disableRipple
          insTooltip="Delete element"
          insTooltipPosition="above"
          (click)="deleteElement(page, page.elements.indexOf(element))"
        >
          <mat-icon class="notranslate">delete</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
  <!-- #endregion botton row actions -->
</mat-card-footer>
<!-- #endregion element footer actions -->

<!-- #region element footer menus -->
<ins-menu #parentTriggersMenu="insMenu">
  <ng-container
    *ngIf="
      !!getParentQuestionsTooltip &&
      !!getParentQuestionsList &&
      !!setActiveElement &&
      !!questionDict
    "
  >
    <div class="full-width text-center py-2">Scroll to parent:</div>
    <div
      *ngFor="
        let parent of questionDict | keyPipe: getParentQuestionsList(element)
      "
    >
      <button
        mat-menu-item
        (click)="setActiveElement(parent.element.id)"
        *ngIf="!!parent"
      >
        {{ parent.element.question.text }}
      </button>
    </div>
  </ng-container>
</ins-menu>
<!-- #endregion element footer menus -->
