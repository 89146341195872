<object
  *ngIf="!isSafari"
  [data]="url"
  type="application/pdf"
  class="w-100 h-100 min-h-inherit"
>
  <embed [src]="url" type="application/pdf" />
  <p>
    This browser does not support PDFs. Please download the PDF to view
    it:
    <a [href]="url">Download PDF</a>.
  </p>
</object>

<object
  *ngIf="isSafari"
  [data]="url"
  class="w-100 h-100 min-h-inherit"
>
  <embed [src]="url"/>
  <p>
    This browser does not support PDFs. Please download the PDF to view
    it:
    <a [href]="url">Download PDF</a>.
  </p>
</object>
