// @ts-strict-ignore
import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';
import { AuthService } from '@insig-health/services/auth/auth.service';
import { ReauthenticateDialogComponent } from 'insig-app/dialogs/reauthenticate/reauthenticate-dialog.component';
import { firstValueFrom } from 'rxjs';
import { MILLISECONDS_PER_SECOND } from '../doctorSettings.service.utilities';

@Injectable({
  providedIn: 'root',
})
export class ExternalLoginService {
  private readonly dialog = inject(MatDialog);
  private readonly authService = inject(AuthService);
  private readonly gcpIpAuthService = inject(GcpIpAuthService);
  static readonly EXTERNAL_LOGIN_CHECK_INTERVAL = 30 * MILLISECONDS_PER_SECOND;
  private static externalLoginCheckInterval: number | undefined;

  async start(): Promise<void> {
    if (ExternalLoginService.externalLoginCheckInterval) {
      window.clearInterval(ExternalLoginService.externalLoginCheckInterval);
      ExternalLoginService.externalLoginCheckInterval = undefined;
    }
    ExternalLoginService.externalLoginCheckInterval = window.setInterval(async () => {
      const user = this.gcpIpAuthService.getCurrentUser();
      if (user) {
        const isUserDoctor = await this.gcpIpAuthService.isUserDoctor(user);
        if (isUserDoctor && !(await this.isTokenValid())) {
          await this.gcpIpAuthService.signOut();
          await this.openReauthenticateDialog();
        }
      }
    }, ExternalLoginService.EXTERNAL_LOGIN_CHECK_INTERVAL);
  }

  stop(): void {
    if (ExternalLoginService.externalLoginCheckInterval) {
      window.clearInterval(ExternalLoginService.externalLoginCheckInterval);
      ExternalLoginService.externalLoginCheckInterval = undefined;
    }
  }

  private async isTokenValid(): Promise<boolean> {
    return this.authService.getDoctorLoggedInStatus().then((loginStatus) => loginStatus.isLoggedIn);
  }

  private async openReauthenticateDialog(): Promise<void> {
    const reauthenticateDialog = this.dialog.getDialogById(ReauthenticateDialogComponent.DEFAULT_DIALOG_ID) ??
      this.dialog.open(ReauthenticateDialogComponent, ReauthenticateDialogComponent.DEFAULT_DIALOG_CONFIG);
    await firstValueFrom(reauthenticateDialog.afterClosed());
    window.location.reload();
  }
}
