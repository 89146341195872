<div class="row">
  <div class="col-12 text-center full-width">
    <mat-radio-group
      [(ngModel)]="question.info"
      required
      class="mat-radio-group-spacing"
    >
      <mat-radio-button value="contact">Send contact info</mat-radio-button>
      <mat-radio-button class="ml-4" value="note"
        >Send contact info & questionnaire</mat-radio-button
      >
    </mat-radio-group>
  </div>
</div>

<div class="divider-md divider"></div>

<div class="row">
  <div class="col-8 offset-2">
    <mat-form-field class="full-width">
      <span matPrefix>
        <mat-icon class="notranslate material-icons">email</mat-icon>&nbsp;
      </span>
      <mat-label>Email to Send the Questionnaire Results</mat-label>
      <input
        matInput
        type="email"
        pattern="^.+@.+\..+$"
        autocomplete="off"
        [(ngModel)]="question.email"
        spellcheck="false"
      />
      <mat-error>Please enter a valid email</mat-error>
    </mat-form-field>
  </div>
</div>
<div class="divider"></div>
<h5 class="mt-0">Sharing Information Privacy Statement:</h5>
<ckeditor
  [(ngModel)]="question.termsContent"
  [config]="ckConfig"
  [readonly]="false"
>
</ckeditor>
