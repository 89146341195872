<div #scrollContainer class="table-responsive overflow-x-auto">
  <table class="table table-striped table-bordered">
    <thead>
      <tr>
        <td *ngIf="!question.allowMultiple"></td>
        <td *ngFor="let col of question.grid.cols">
          <label>{{ col.label }}</label>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of question.grid.rows">
        <td *ngIf="!question.allowMultiple">
          {{ row.label }}
        </td>
        <!-- <td *ngIf="question.allowMultiple">
        </td> -->
        <td *ngFor="let col of question.grid.cols">
          <div
            *ngIf="!col.colInputType"
            [ngSwitch]="question.grid.cellInputType"
          >
            <mat-radio-group
              *ngSwitchCase="'radio'"
              [(ngModel)]="question.tempResponse[row.id]"
            >
              <mat-radio-button [value]="col.id"></mat-radio-button>
            </mat-radio-group>

            <div *ngSwitchCase="'checkbox'">
              <mat-checkbox
                [(ngModel)]="question.tempResponse[row.id][col.id]"
              ></mat-checkbox>
            </div>

            <div *ngSwitchCase="'date'">
              <mat-form-field class="full-width" (click)="picker.open()">
                <mat-label>Select a date</mat-label>
                <input
                  matInput
                  readonly
                  autocomplete="off"
                  [(ngModel)]="question.tempResponse[row.id][col.id]"
                  [matDatepicker]="picker"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
              </mat-form-field>
              <mat-datepicker
                #picker
                startView="year"
                [touchUi]="false"
              ></mat-datepicker>
            </div>
            <mat-form-field *ngSwitchDefault class="full-width">
              <textarea
                rows="1"
                matInput
                [(ngModel)]="question.tempResponse[row.id][col.id]"
              ></textarea>
            </mat-form-field>
          </div>

          <div *ngIf="col.colInputType" [ngSwitch]="col.colInputType">
            <mat-radio-group
              *ngSwitchCase="'radio'"
              [(ngModel)]="question.tempResponse[row.id].radio"
            >
              <mat-radio-button [value]="col.id"></mat-radio-button>
            </mat-radio-group>
            <div *ngSwitchCase="'checkbox'">
              <mat-checkbox
                [(ngModel)]="question.tempResponse[row.id][col.id]"
              ></mat-checkbox>
            </div>
            <div *ngSwitchCase="'date'">
              <mat-form-field class="full-width" (click)="picker.open()">
                <mat-label>Select a date</mat-label>
                <input
                  matInput
                  readonly
                  autocomplete="off"
                  [(ngModel)]="question.tempResponse[row.id][col.id]"
                  [matDatepicker]="picker"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
              </mat-form-field>
              <mat-datepicker
                #picker
                startView="year"
                [touchUi]="false"
              ></mat-datepicker>
            </div>
            <mat-form-field *ngSwitchDefault class="full-width">
              <textarea
                rows="1"
                matInput
                [(ngModel)]="question.tempResponse[row.id][col.id]"
              ></textarea>
            </mat-form-field>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="question.allowMultiple">
  <button mat-raised-button color="primary" (click)="addNewRow()">
    Add Row
  </button>
</div>
