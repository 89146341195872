// @ts-strict-ignore
import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';

import { APPCONFIG } from '@insig-health/config/config';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

@Component({
  selector: 'my-app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class AppSidenavComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly firebaseAuthService = inject(FirebaseAuthService);
  AppConfig;
  public specialLogo = '';

  ngOnInit() {
    if (window.location.origin.includes('app.well.company')
      || window.location.origin.includes('app.wellclinics.ca')) {
      this.specialLogo = 'assets/images/global/well/well-icon-logo-white.svg';
    } else if (window.location.origin.includes('app.jacknathanhealth.com')) {
      this.specialLogo = 'assets/images/global/jnh/jnh-logo.png';
    } else if (window.location.origin.includes('app.thevirtualdoctor.org')) {
      this.specialLogo = 'assets/images/global/tvd/tvd.jpeg';
    } else if (window.location.origin.includes('virtual.highmark.tech')) {
      this.specialLogo = 'assets/images/global/eq/eq-logo.png';
    }
    this.AppConfig = APPCONFIG;
  }

  toggleCollapsedNav() {
    this.AppConfig.navCollapsed = !this.AppConfig.navCollapsed;
  }

  openSupportPage() {
    window.open('https://support.insighealth.com/');
  }

  async logout() {
    await this.firebaseAuthService.signOutThenReturnToLoginPage();
  }
} // end class
