import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'new-folder-dialog',
  templateUrl: './new-folder-dialog.component.html',
})
export class NewFolderDialogComponent {
  public readonly dialogRef = inject<MatDialogRef<NewFolderDialogComponent>>(MatDialogRef<NewFolderDialogComponent>);
  public surveyName = null;
  public preview = false;
  public newFolderName = 'Untitled folder';
}
