import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'range-edit',
  templateUrl: './range-edit.component.html',
  styleUrls: ['./range-edit.component.scss'],
})
export class RangeEditComponent {
  @Input() question: any;
  @Input() value: any;
  @Output() questionChange: EventEmitter<any>;
  constructor() {
    this.questionChange = new EventEmitter<any>();
  }

  checkMin() {
    if (this.question.min > this.question.max) {
      this.question.min = this.question.max;
      this.questionChange.emit(this.question);
    }
  }

  checkMax() {
    if (this.question.max < this.question.min) {
      this.question.max = this.question.min;
      this.questionChange.emit(this.question);
    }
  }

  updateMin(min: number) {
    this.question.min = min;
  }

  updateMax(max: number) {
    this.question.max = max;
  }
} // end component
