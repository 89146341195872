import { Component } from '@angular/core';

@Component({
  selector: 'ins-tooltip',
  templateUrl: './insig-tooltip.component.html',
  styleUrls: ['../insig-material.shared.scss'],
})
export class InsigTooltipComponent {
  public message = '';
}
