// @ts-strict-ignore
import { Component, OnInit, ElementRef, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'add-companies-to-plan-dialog',
  templateUrl: './add-companies-to-plan.component.html',
})
export class AddCompaniesToPlanDialogComponent implements OnInit {
  public readonly dialogRef = inject<MatDialogRef<AddCompaniesToPlanDialogComponent>>(MatDialogRef<AddCompaniesToPlanDialogComponent>);
  private readonly formBuilder = inject(UntypedFormBuilder);
  private readonly snackBar = inject(MatSnackBar);
  public readonly data = inject<any>(MAT_DIALOG_DATA);
  private readonly elRef = inject(ElementRef);
  public planTitle = '';
  public form: UntypedFormGroup;
  public companyList: any;
  public type: any;
  public selectedCompanies: any = [];
  public currentCompanyName = '';
  public currentCompanyGroup = '';
  private selectedGroups: any = [];
  private newCompanies: any = [];
  separatorKeysCodes = [ENTER, COMMA];

  companiesControl: UntypedFormControl = new UntypedFormControl();
  public filteredOptions: Observable<any>;

  constructor(
) {
    if (this.data.data) {
      console.log(this.data.data);
      this.companyList = this.data.data.companyList;
      this.type = this.data.data.type;
    }
    // (window.document.querySelector(
    //   '.insig-dialog-panel'
    // ) as HTMLElement).style.minWidth = '60%';
  }

  ngOnInit() {
    this.buildForm();

    this.filteredOptions = this.companiesControl.valueChanges.pipe(
      startWith(''),
      map((val) => this.filter(val))
    );
  }

  filter(searchVal: string): string[] {
    const filterKeys = ['name', 'brandingName'];
    return this.companyList.filter((company) => {
      for (const key of filterKeys) {
        if (company[key] !== null) {
          if (company[key].toLowerCase().includes(searchVal.toLowerCase())) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onEnter(value) {
    const filter = this.filter(value);
    if (filter.length === 1) {
      this.selectedCompanies.push(filter[0]);
    }
    this.currentCompanyName = '';
  }

  buildForm() {
    this.form = this.formBuilder.group({
      companiesSubscribed: this.formBuilder.control(null),
    });
  }

  addNewCompanies() {
    const data = this.selectedCompanies;
    console.log(data);
    this.dialogRef.close(data);
  }

  addItem(val) {
    if (!this.selectedCompanies.includes(val)) {
      this.selectedCompanies.push(val);
    }
    this.currentCompanyName = '';
  }

  removeItem(val) {
    const index = this.selectedCompanies.indexOf(val);
    this.selectedCompanies.splice(index, 1);
  }

  addCompanyGroupItem(val) {
    if (!this.selectedGroups.includes(val)) {
      this.selectedGroups.push(val);
    }
    this.currentCompanyGroup = '';
  }

  removeCompanyGroupItem(val) {
    const index = this.selectedGroups.indexOf(val);
    this.selectedGroups.splice(index, 1);
  }

  addNewCompany(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.newCompanies.push({ email: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeNewCompany(user: any): void {
    const index = this.newCompanies.indexOf(user);

    if (index >= 0) {
      this.newCompanies.splice(index, 1);
    }
  }
}
