<div
  class="referral-details p-0 m-2"
  [ngClass]="{ 'referral-border': !!referralBorder }"
>
  <div class="row m-0 p-0 full-height text-center">
    <div class="referral-details-img col-md-4" *ngIf="!!referralClinic.logo">
      <div class="full-width my-auto">
        <img [src]="referralClinic.logo" class="h-100 py-3 logo-boundary" />
      </div>
    </div>
    <div
      class="p-0"
      [ngClass]="{
        'col-12': !referralClinic.logo,
        'col-md-8': !!referralClinic.logo
      }"
    >
      <div class="row m-0 pb-2 full-height full-width text-left">
        <h5 class="referral-details-title col-12 mx-auto">
          {{ referralClinic.name }}
        </h5>
        <h5 class="referral-details-desc col-12 mx-auto" *ngIf="!doctorMode">
          {{
            !!showAddress
              ? referralClinic.address
              : !!referralClinic.dist
              ? referralClinic.dist + ' km away from your address'
              : ''
          }}
        </h5>
        <h5 class="referral-details-desc col-12 mx-auto" *ngIf="!!doctorMode">
          {{
            !!showAddress
              ? referralClinic.address
              : !!referralClinic.dist
              ? referralClinic.dist + ' km away from your patient'
              : ''
          }}
        </h5>
      </div>
    </div>
  </div>
</div>
