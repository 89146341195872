import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'time-edit',
  templateUrl: './time-edit.component.html',
})
export class TimeEditComponent {
  @Input() question: any;
  @Output() questionChange: EventEmitter<any>;

  constructor() {
    this.questionChange = new EventEmitter<any>();
  }
}
