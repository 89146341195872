<label class="d-block w-100">
  <input
    [autofocus]="autofocus"
    required
    type="text"
    inputmode="numeric"
    [value]="value"
    (input)="handleChange($event)"
    (change)="handleChange($event)"
    (blur)="handleBlur()"
    maxlength="6"
    pattern="\d{6}"
  />
  <div class="outerBackground"></div>
  <div class="innerBackground"></div>
</label>
