<div class="row row-eq-height" cdkDropListGroup>
  <div class="col-6">
    <div class="panel panel-success">
      <h4>Available (drag these)</h4>
      <div class="divider"></div>
      <div class="panel-body" style="border:solid;">
        <ul
          class="list-group"
          cdkDropList
          [cdkDropListData]="AVAILABLE_CONTAINER"
          (cdkDropListDropped)="availableDropped($event)"
        >
          <li
            *ngFor="let item of question.priorityList; let i = index"
            class="list-group-item"
            cdkDrag
            [cdkDragData]="item"
          >
            {{ item.value }}
          </li>
          <li
            *ngIf="question.priorityList.length === 0"
            class="list-group-item"
          >
            Empty
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="panel panel-success">
      <h4>Sorted (drag here & reorder)</h4>
      <div class="divider"></div>
      <div class="panel-body" style="border:solid;">
        <ul
          class="list-group"
          cdkDropList
          [cdkDropListData]="SORTED_CONTAINER"
          (cdkDropListDropped)="sortedDropped($event)"
        >
          <li
            *ngFor="let item of question.response.priorityList; let i = index"
            class="list-group-item"
            cdkDrag
            [cdkDragData]="item"
          >
            {{ i + 1 }}. {{ item.value }}
          </li>
          <li
            *ngIf="question.response.priorityList.length === 0"
            class="list-group-item"
          >
            Empty
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
