// @ts-strict-ignore
export default class MyString {
  static removeCharacterOfEscapeInAllString(stringValue, stringOfEscape) {
    const listOfEscape = this.removeCharacterOfEscapeNotAllowed(stringOfEscape);
    let newstringValue = '';

    if (!listOfEscape) {
      return stringValue;
    }

    Array.from(stringValue).forEach((element) => {
      if (!listOfEscape.includes(element)) {
        newstringValue += element;
      }
    });
    return newstringValue;
  }

  static convertOneCharInHexToDec(value) {
    if (value.length !== 1) {
      return undefined;
    } else if (value >= '0' && value <= '9') {
      return value;
    } else if (value.toUpperCase() >= 'A' && value.toUpperCase() <= 'F') {
      value = value.toUpperCase();
      let num;
      switch (value) {
        case 'A':
          num = '10';
          break;
        case 'B':
          num = '11';
          break;
        case 'C':
          num = '12';
          break;
        case 'D':
          num = '13';
          break;
        case 'E':
          num = '14';
          break;
        case 'F':
          num = '15';
          break;
      }
      return num;
    } else {
      return undefined;
    }
  }

  static removeCharacterOfEscapeNotAllowed(stringOfEscape) {
    const listOfCharacterOfEscape: any = ['\n', '\r', '\t', '\f'];
    const newStringOfEscape = [];

    Array.from(stringOfEscape).forEach((elem) => {
      if (listOfCharacterOfEscape.includes(elem)) {
        newStringOfEscape.push(elem);
      }
    });
    return newStringOfEscape.length > 0 ? newStringOfEscape : undefined;
  }

  static hasOnlyWhiteSpace(content) {
    return content.replace(/\s/g, '').length === 0 ? true : false;
  }
}
