// @ts-strict-ignore
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { Question } from 'insig-types/surveys';

type YesOrNo = 'Yes' | 'No';

@Component({
  selector: 'multiyesno-question',
  templateUrl: './multiyesno.component.html',
})
export class MultiYesNoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();
  @Input() pageElement = null;
  @Input() elementID = null;
  @Input() triggersDictionary = null;
  @Output() checkTriggersChild: EventEmitter<string> = new EventEmitter();

  /**
   * Template shortcut helper. Returns an array specifying which side
   * the Yes or No options are for each offered question based on the
   * switched property.
   */
  public get yesOrNoPositions(): Array<{ left: YesOrNo; right: YesOrNo }> {
    return this.question.offeredQuestions.map((offeredQuestion) => ({
      left: offeredQuestion.switched ? 'Yes' : 'No',
      right: offeredQuestion.switched ? 'No' : 'Yes',
    }));
  }

  ngOnInit() {
    if (!this.question.response.selectedAnswers) {
      this.question.response.selectedAnswers = [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  ngOnDestroy() {
    if (this.pageElement.triggerHidden === true) {
      this.removeAllTriggers();
    }
  }

  changeAnswer(index: number, yesOrNo: 'Yes' | 'No') {
    if (this.question.response.selectedAnswers[index] !== yesOrNo) {
      // Optimize redundant calls
      this.question.response.selectedAnswers[index] = yesOrNo;
      this.modifyTriggers(index, yesOrNo);
    }
  } // end change answer func

  removeAllTriggers(): void {
    let triggersChanged = false; // If no triggers were changed skip trigger checking
    for (const offeredQuestion of this.question.offeredQuestions) {
      if (!!offeredQuestion.pageFlow && !!offeredQuestion.pageFlow.trigger) {
        for (const possibleAnswers of ['Yes', 'No']) {
          if (offeredQuestion.pageFlow.trigger[possibleAnswers]) {
            offeredQuestion.pageFlow.trigger[possibleAnswers].forEach(
              (child) => {
                if (this.triggersDictionary[child]) {
                  if (
                    this.triggersDictionary[child][this.elementID] !== false
                  ) {
                    this.triggersDictionary[child][this.elementID] = false;
                    triggersChanged = true;
                  }
                } else {
                  console.error(
                    'Incorrect JSON for this question. Subquestion array error'
                  );
                }
              }
            );
          }
        }
      }
    }
    if (triggersChanged) {
      this.sendCheckTriggers();
    }
  }

  modifyTriggers(index: number, answer: YesOrNo) {
    let triggersChanged = false; // If no triggers were changed skip trigger checking
    const offeredQuestion = this.question.offeredQuestions[index];
    if (!!offeredQuestion.pageFlow && !!offeredQuestion.pageFlow.trigger) {
      for (const possibleAnswers of ['Yes', 'No']) {
        if (offeredQuestion.pageFlow.trigger[possibleAnswers]) {
          offeredQuestion.pageFlow.trigger[possibleAnswers].forEach((child) => {
            if (this.triggersDictionary[child]) {
              if (
                this.triggersDictionary[child][this.elementID] !==
                (possibleAnswers === answer)
              ) {
                this.triggersDictionary[child][this.elementID] =
                  possibleAnswers === answer;
                triggersChanged = true;
              }
            } else {
              console.error(
                'Incorrect JSON for this question. Subquestion array error'
              );
            }
          });
        }
      }
    }
    if (triggersChanged) {
      this.sendCheckTriggers();
    }
  } // end modify trigs

  sendCheckTriggers() {
    this.checkTriggersChild.emit('true');
  }
} // end component
