<ng-container [ngTemplateOutlet]="inputStyle === 'insigBooking' ? insigBookingInput : matFormFieldInput"></ng-container>

<!-- #region insig-core styling -->
<ng-template #matFormFieldInput>
  <mat-form-field class="w-100" [appearance]="appearance" *ngIf="familyDoctorFullNameControl">
    <mat-label>Family Doctor Full Name</mat-label>
    <span *ngIf="_isRequired" class="required">&nbsp;*</span>
    <input
      matInput
      autocomplete="off"
      placeholder="Family Doctor Full Name"
      name="Family Doctor Full Name"
      [formControl]="familyDoctorFullNameControl"
      [matAutocomplete]="autocomplete"
      [required]="_isRequired"
    />
  </mat-form-field>
</ng-template>
<!-- #endregion insig-core styling -->

<!-- #region insig-booking styling -->
<ng-template #insigBookingInput>
  <insig-ui-autocomplete-input
    *ngIf="familyDoctorFullNameControl"
    label="Family Doctor Full Name"
    [formControl]="familyDoctorFullNameControl"
    [matAutocomplete]="autocomplete"
    [isRequired]="_isRequired"
  ></insig-ui-autocomplete-input>
</ng-template>
<!-- #endregion insig-booking styling -->

<mat-autocomplete #autocomplete
  panelWidth="500px"
>
  <mat-option class="lh-1 text-truncate"
    *ngFor="let familyDoctor of (familyDoctors$ | async) ?? []"
    [value]="familyDoctor.fullName"
    (click)="handleFamilyDoctorOptionClicked(familyDoctor)"
  >
    <span class="fw-bold">{{ familyDoctor.fullName }}</span><br>
    <small class="fw-lighter fst-italic text-muted">{{ familyDoctor.address }}</small>
  </mat-option>
</mat-autocomplete>
