// @ts-strict-ignore
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import * as LZString from 'lz-string';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class MedicationsListService {

  getMedications(): Observable<string[]> {
    return Observable.create((observer) => {
      return firebase.database().ref('global/medicationsList').on('value', (snapshot) => {
        if (snapshot !== undefined) {
          observer.next(JSON.parse(LZString.decompressFromBase64(snapshot.val())));
        }
      });
    });
  }

  getLUMeds(): Observable<any> {
    return Observable.create((observer) => {
      return firebase.database().ref('global/LUMedsList2').on('value', (snapshot) => {
        if (snapshot !== undefined) {
          observer.next(snapshot.val());
        }
      });
    });
  }

  getLUCodeDescriptions(): Observable<any> {
    return Observable.create((observer) => {
      return firebase.database().ref('global/LUCodeDescriptions').on('value', (snapshot) => {
        if (snapshot !== undefined) {
          observer.next(snapshot.val());
        }
      });
    });
  }

}
