import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'insig-ui-autocomplete-input',
  templateUrl: './insig-autocomplete-input.component.html',
  styleUrls: ['./insig-autocomplete-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: InsigAutocompleteInputComponent,
  }],
})
export class InsigAutocompleteInputComponent implements ControlValueAccessor, AfterViewInit {
  public onChange?: (value: string) => void;
  public onTouched?: () => void;
  public _isRequired = false;

  @Input() label = '';
  @Input() name = '';
  @Input() value = '';
  @Input() matAutocomplete: MatAutocomplete | undefined;
  @Input() set isRequired(isRequired: boolean | 'true' | 'false') {
    this._isRequired = coerceBooleanProperty(isRequired);
  }

  ngAfterViewInit(): void {
    if (this.matAutocomplete === undefined) {
      throw new Error('matAutocomplete is required');
    }
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  handleChange(event: Event): void {
    this.onChange?.((event.target as HTMLInputElement).value);
    this.onTouched?.();
  }

  handleBlur(): void {
    this.onTouched?.();
  }
}
