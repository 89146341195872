// @ts-strict-ignore
import { Component, OnInit, OnDestroy, Input, inject } from '@angular/core';

import {
  SchedulerViewDay,
  SchedulerViewHour,
  SchedulerViewHourSegment,
  CalendarSchedulerEvent,
  CalendarSchedulerEventAction,
} from '../../../global/admin/scheduler/calendar-scheduler-view.component';
import {
  CalendarPeriod,
  startOfPeriod,
  endOfPeriod,
  addPeriod,
  subPeriod,
} from '../../../global/admin/scheduler/calendar-utils';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

import { DoctorSettingsService, WeeklySchedule } from 'insig-app/services/doctorSettings.service';
import { VirtualService } from 'insig-app/services/virtual/virtual.service';
import { GeneralService } from 'insig-app/services/general.service';
import { CreateCompanyPlanDialogComponent } from '../../dialogs/create-company-plan/create-company-plan.component';
import { AddCompaniesToPlanDialogComponent } from '../../dialogs/add-companies-to-plan/add-companies-to-plan.component';
import { AddUserGroupsToPlanDialogComponent } from '../../dialogs/add-user-groups-to-plan/add-user-groups-to-plan.component';
import { ConfirmCompanyPlanDeleteDialogComponent } from '../../dialogs/confirm-company-plan-delete/confirm-company-plan-delete.component';
import { SelectAppointmentSurveyDialogComponent } from 'insig-app/global/virtual/settings/dialogs/select-appointment-survey.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { APPCONFIG, SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/config';

import { Apollo, gql } from 'apollo-angular';
import { endOfDay, subDays, addDays, addMonths, startOfWeek } from 'date-fns';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DoctorScheduleReindexService } from '@insig-health/services/doctor-schedule-reindex/doctor-schedule-reindex.service';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

interface User {
  first: string;
  last: string;
  fullName: string;
  phone: string;
  uid: string;
  valid: boolean;
  image: string;
  email: string;
  company: string;
  address: string;
  type: {
    admin: boolean;
  };
  acceptVirtual: boolean;
}

@Component({
  selector: 'admin-scheduler',
  templateUrl: './admin-scheduler.component.html',
  styleUrls: [
    './admin-scheduler.component.scss',
    '../../shared/styles/info-page.style.scss',
  ],
})
export class AdminSchedulerComponent implements OnInit, OnDestroy {
  private readonly firebaseAuthService = inject(FirebaseAuthService);
  private readonly virtualService = inject(VirtualService);
  private readonly doctorSettingsService = inject(DoctorSettingsService);
  private readonly insigDialog = inject(MatDialog);
  private readonly snackBar = inject(MatSnackBar);
  private readonly generalService = inject(GeneralService);
  private readonly apollo = inject(Apollo);
  private readonly doctorScheduleReindexService = inject(DoctorScheduleReindexService);
  private readonly dateAndTimeService = inject(DateAndTimeService);
  // graphql queries
  private userDataQuery = gql`
    query User($userID: ID!) {
      getUserData(uid: $userID) {
        uid
        company
        first
        acceptVirtual
        last
        title
        qualifications
      }
    }
  `;


  //

  private daysOfTheWeek = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ] as const;

  @Input() userData: any;
  @Input() combinedSchedule: any = [];
  public userWeeklySchedules: any;
  @Input() combinedScheduleMap: any;
  @Input() companyData: any;
  @Input() companyServices: any;
  @Input() companyCategories: any;
  @Input() librarySurveys: any;
  private fullUserList: User[] = [];
  public fullUserMap: { [uid: string]: User } = {};
  public companyPlanVirtualServices: any = [];
  public selectedCategoryItem: any;
  public selectedCategory: any;
  public selectedCategoryName: any;
  public selectedGroup: any;
  public selectedService: any;
  private fullCompanyPlanSub: Subscription;
  private companies: any;
  public companyPlans: any = [];
  public newService = false;
  public selectedPlan: any;
  public showBottomSheet = false;
  public subscribedCompanies: any = [];
  public subscribedUserGroups: any = [];
  private showSubscriptionWarning = false;
  public userServices: any;
  private unsubVirtualServices: Subscription;
  public companyPlansDictionary = {};
  public selectedDoctorID: string; // The id of the doctor selected from the user menu
  public selectedDoctorCompanyID: string; // The company ID of the selected doctor
  public selectedDoctorUserData: {
    uid: string;
    company: string;
    first: string;
    last: string;
    title: string;
    acceptVirtual: boolean;
  };
  public daySelected: Date;
  public scheduleSelected: any;
  public showView = 'list';
  public hoverOver = false;
  public selectedDoctorName: string = null;

  private weeklyScheduleSubscription: Subscription;

  private static menuItemTypes: Readonly<{
    [key: string]: number;
  }> = Object.freeze({
    // Menu item types enumeration
    USER: 1,
  });

  public AppConfig: any = APPCONFIG;
  public hideTiaLogo: boolean = false;

  view: CalendarPeriod = 'week';
  viewDate: Date = startOfWeek(new Date());
  refreshSubject: Subject<any> = new Subject();
  locale = 'en';
  weekStartsOn = 0;
  startsWithToday = false;
  activeDayIsOpen = true;
  excludeDays: number[] = [];
  dayStartHour = 8;
  dayEndHour = 22;

  minDate: Date = new Date();
  maxDate: Date = endOfDay(addMonths(new Date(), 1));
  dayModifier: (day: SchedulerViewDay) => void;
  hourModifier: (hours: SchedulerViewHour) => void;
  segmentModifier: (segment: SchedulerViewHourSegment) => void;
  prevBtnDisabled = false;
  nextBtnDisabled = false;

  actions: CalendarSchedulerEventAction[] = [
    {
      when: 'enabled',
      label:
        '<span class="valign-center"><i class="material-icons md-18 md-red-500">cancel</i></span>',
      title: 'Delete',
      onClick: (event: CalendarSchedulerEvent): void => {
        console.log('Pressed action cancel on event ' + event.id);
      },
    },
  ];

  events = [
    {
      start: new Date().setHours(12),
      end: new Date().setHours(16),
      title: '',
      color: colors.blue,
    },
  ];

  public menuItems: any;
  private IDToken: string;

  constructor(
) {
    // dont need to disable past
    // this.dayModifier = ((day: SchedulerViewDay): void => {
    //   if (!this.isDateValid(day.date)) {
    //     day.cssClass = 'cal-disabled';
    //   }
    // }).bind(this);
    // this.hourModifier = ((hour: SchedulerViewHour): void => {
    //   if (!this.isDateValid(hour.date)) {
    //     hour.cssClass = 'cal-disabled';
    //   }
    // }).bind(this);
    // this.segmentModifier = ((segment: SchedulerViewHourSegment): void => {
    //   if (!this.isDateValid(segment.date)) {
    //     segment.isDisabled = true;
    //   }
    // }).bind(this);

    this.dateOrViewChanged();
  }

  async ngOnInit(): Promise<void> {
    if (
      window.location.origin.includes('app.well.company') ||
      window.location.origin.includes('app.wellclinics.ca')
    ) {
      this.hideTiaLogo = true;
    }

    this.weeklyScheduleSubscription = this.doctorSettingsService
      .getWeeklyScheduleByCompany(this.userData.company)
      .subscribe((weeklySchedule) => {
        this.getAllUsers(weeklySchedule).then(() => {
          this.resetMenu();
          this.loadCompanyPlans();
        });
      });

    this.firebaseAuthService
      .onIdTokenChanged()
      .subscribe(async (user) => {
          if (user) {
            this.IDToken = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();
          }
        }
      );
  }

  ngOnDestroy() {
    if (this.fullCompanyPlanSub) {
      this.fullCompanyPlanSub.unsubscribe();
    }
    if (this.unsubVirtualServices) {
      this.unsubVirtualServices.unsubscribe();
    }

    if (!!this.weeklyScheduleSubscription) {
      this.weeklyScheduleSubscription.unsubscribe();
    }
  }

  async getAllUsers(weeklySchedule): Promise<void> {
    const doctorListQuery = gql`
      query DoctorListQuery($companyID: ID!, $idToken: ID!) {
        getCompanyUserList(cid: $companyID, token: $idToken) {
          first
          last
          phone
          uid
          valid
          image
          email
          company
          address
          type {
            admin
          }
          acceptVirtual
        }
      }
    `;
    try {
      const userListQuery = await this.apollo
        .query<{
          getCompanyUserList: User[];
        }>({
          query: doctorListQuery,
          variables: {
            companyID: this.userData.company,
            idToken: await this.firebaseAuthService.idToken$.pipe(take(1)).toPromise(),
          },
        })
        .toPromise();

      this.fullUserList = userListQuery.data.getCompanyUserList
        .map((user) => ({
          fullName: user.first + ' ' + user.last, // Add fullName field
          ...user,
        }))
        .sort((a, b) => {
          return a.last.localeCompare(b.last);
        });

      this.fullUserMap = this.fullUserList.reduce((dictionary, user) => {
        dictionary[user.uid] = user;
        return dictionary;
      }, {});

      const userScheds = this.generateUserScheds(weeklySchedule);
      this.userWeeklySchedules = userScheds;
      console.log({ userScheds });
      this.generateCombinedWeeklySchedule(userScheds);
    } catch (error) {
      console.error(error);
    }
  }

  generateUserScheds(weeklySchedules) {
    const userScheds = {};
    for (const d in weeklySchedules) {
      if (!!weeklySchedules[d] && !!weeklySchedules[d].weeklySchedule) {
        userScheds[d] = this.convertEpochWeeklyScheduleToSchedule(
          weeklySchedules[d].weeklySchedule
        );
      }
    }
    return userScheds;
  }

  // Parses a weekly schedule from epoch time to 24 hour time
  convertEpochWeeklyScheduleToSchedule(
    weeklyEpochSchedule: WeeklySchedule,
  ): {
    defaultAvailability?: string;
    timeIncrement?: string;
  } {
    if (!weeklyEpochSchedule) {
      console.log('returning empty schedule');
      return {};
    }
    const weeklySchedule = {
      defaultAvailability: weeklyEpochSchedule.defaultAvailability,
    };

    // Convert all times to 24 hour time
    for (const dayIndex in this.daysOfTheWeek) {
      if (this.daysOfTheWeek[dayIndex]) {
        const day = weeklyEpochSchedule[this.daysOfTheWeek[dayIndex]];

        if (day === undefined) {
          continue;
        }

        const weeklyDay = (weeklySchedule[this.daysOfTheWeek[dayIndex]] = {
          timeIncrement: day.timeIncrement,
          timeSegments: [],
          operatingTimeZone: day.operatingTimeZone,
        });

        for (const segmentIndex in day.timeSegments) {
          if (day.timeSegments[segmentIndex]) {
            const segment = day.timeSegments[segmentIndex];
            const localTimeZone = this.dateAndTimeService.getLocalTimeZone();
            const localTimeSegment = this.doctorSettingsService.convertTimeSegmentToLocalTime(segment, new Date(), day.operatingTimeZone, localTimeZone);

            const startOfWeek = this.dateAndTimeService.getStartOfWeek(new Date(), localTimeZone);
            const weekday = this.dateAndTimeService.addDaysToDate(startOfWeek, parseInt(dayIndex, 10));
            const weekdayNoon = weekday.setHours(12);
            const date = new Date(weekdayNoon);

            weeklyDay.timeSegments[segmentIndex] = {
              start: this.convertMsPastMidnightToLocalDate(localTimeSegment.localStartTime, date),
              end: this.convertMsPastMidnightToLocalDate(localTimeSegment.localEndTime, date),
              availability: segment.availability,
              location: segment.location || null,
            };

            console.log(weeklyDay.timeSegments[segmentIndex].start);
            console.log(weeklyDay.timeSegments[segmentIndex].end);
            weeklyDay.timeSegments[segmentIndex].startTimeString = `${(weeklyDay
              .timeSegments[segmentIndex]
              .start as Date).getHours()}:${(weeklyDay.timeSegments[
              segmentIndex
            ].start as Date).getMinutes()}`;

            weeklyDay.timeSegments[segmentIndex].endTimeString = `${(weeklyDay
              .timeSegments[segmentIndex].end as Date).getHours()}:${(weeklyDay
              .timeSegments[segmentIndex].end as Date).getMinutes()}`;
          }
        }
      }
    }
    return weeklySchedule;
  }

  convertMsPastMidnightToLocalDate(msPastMidnight: number, date: Date): Date {
    date = new Date(date.getTime()); // treat input as immutable
    return new Date(date.setHours(0, 0, 0, msPastMidnight));
  }

  generateCombinedWeeklySchedule(userScheds) {
    this.userWeeklySchedules = userScheds;
    const finalSched = {};
    for (const dayIndex in this.daysOfTheWeek) {
      if (this.daysOfTheWeek[dayIndex]) {
        for (const u in userScheds) {
          if (userScheds[u]) {
            const defaultAvailability =
              userScheds[u].defaultAvailability === 'both' ||
              userScheds[u].defaultAvailability === 'virtual'
                ? true
                : false;
            if (!finalSched[dayIndex]) {
              if (
                !!this.fullUserMap[u] &&
                !!this.fullUserMap[u].acceptVirtual &&
                userScheds[u][this.daysOfTheWeek[dayIndex]] &&
                userScheds[u][this.daysOfTheWeek[dayIndex]].timeSegments &&
                userScheds[u][this.daysOfTheWeek[dayIndex]].timeSegments
                  .length > 0
              ) {
                finalSched[dayIndex] = this.getTimesObjFromTimeSegments(
                  userScheds[u][this.daysOfTheWeek[dayIndex]].timeSegments,
                  defaultAvailability
                );
                if (finalSched[dayIndex].start === undefined || finalSched[dayIndex].end === undefined) {
                  delete finalSched[dayIndex];
                  continue;
                }
                finalSched[dayIndex].title = '';
                finalSched[dayIndex].users = [u];
                finalSched[dayIndex].userSchedules = {};
                finalSched[dayIndex].userSchedules[
                  u
                ] = this.getTimesObjFromTimeSegments(
                  userScheds[u][this.daysOfTheWeek[dayIndex]].timeSegments,
                  defaultAvailability
                );
                finalSched[dayIndex].color = {
                  primary: '#1e90ff',
                  secondary: '#D1E8FF',
                };
              }
            } else {
              const start = finalSched[dayIndex].start;
              const end = finalSched[dayIndex].end;
              if (
                !!this.fullUserMap[u] &&
                !!this.fullUserMap[u].acceptVirtual &&
                userScheds[u][this.daysOfTheWeek[dayIndex]].timeSegments
                  .length > 0
              ) {
                const userStart = this.getTimesObjFromTimeSegments(
                  userScheds[u][this.daysOfTheWeek[dayIndex]].timeSegments,
                  defaultAvailability
                );
                if (userStart.start !== undefined && userStart.start < start) {
                  finalSched[dayIndex].start = start;
                }
                if (userStart.end !== undefined && userStart.end > end) {
                  finalSched[dayIndex].end = end;
                }
                finalSched[dayIndex].users.push(u);
                finalSched[dayIndex].userSchedules[u] = userStart;
              }
            }
          }
        }
      }
    }
    this.combinedSchedule = [];
    this.combinedScheduleMap = finalSched;
    for (const s in finalSched) {
      if (finalSched[s]) {
        this.combinedSchedule.push(finalSched[s]);
      }
    }
    console.log(this.combinedSchedule);
  }

  getTimesObjFromTimeSegments(timeSegments, defaultAvailability) {
    let end;
    let start;
    for (const segment of timeSegments) {
      if (
        segment.availability === 'virtual' ||
        (defaultAvailability && segment.availability === 'default')
      ) {
        if (!start) {
          start = segment.start;
        }

        if (!end) {
          end = segment.end;
        }

        if (segment.start < start) {
          start = segment.start;
        }

        if (segment.end > end) {
          end = segment.end;
        }
      }
    }

    return {
      start,
      end,
    };
  }

  changeDate(date: Date): void {
    this.viewDate = date;
    this.dateOrViewChanged();
  }

  changeView(view: CalendarPeriod): void {
    this.view = view;
    this.dateOrViewChanged();
  }

  dateOrViewChanged(): void {
    if (this.startsWithToday) {
      this.prevBtnDisabled = !this.isDateValid(
        subPeriod(this.view, this.viewDate, 1)
      );
      this.nextBtnDisabled = !this.isDateValid(
        addPeriod(this.view, this.viewDate, 1)
      );
    } else {
      this.prevBtnDisabled = !this.isDateValid(
        endOfPeriod(this.view, subPeriod(this.view, this.viewDate, 1))
      );
      this.nextBtnDisabled = !this.isDateValid(
        startOfPeriod(this.view, addPeriod(this.view, this.viewDate, 1))
      );
    }

    if (this.viewDate < this.minDate) {
      this.changeDate(this.minDate);
    } else if (this.viewDate > this.maxDate) {
      this.changeDate(this.maxDate);
    }

    if (this.combinedSchedule) {
      for (const event of this.combinedSchedule) {
        if (this.viewDate > event.start) {
          event.start = addDays(event.start, 7);
          event.end = addDays(event.end, 7);
        } else {
          event.start = subDays(event.start, 7);
          event.end = subDays(event.end, 7);
        }
      }
    }
  }

  sortFunc(array) {
    return array.sort((a, b) => {
      return a.last.localeCompare(b.last);
    });
  }

  private isDateValid(date: Date): boolean {
    return /*isToday(date) ||*/ date >= this.minDate && date <= this.maxDate;
  }

  dayClicked({
    date,
    events,
  }: {
    date: Date;
    events: CalendarSchedulerEvent[];
  }): void {
    console.log('dayClicked Date', date);
    console.log('dayClicked Events', events);

    this.daySelected = date;
    this.scheduleSelected = this.combinedScheduleMap[date.getDay()];
    console.log(this.scheduleSelected);
    this.toggleBottomSheet();
  }

  toggleBottomSheet() {
    this.showBottomSheet = !this.showBottomSheet;
  }

  eventClicked(action: string, event: CalendarSchedulerEvent): void {
    console.log('eventClicked Action', action);
    console.log('eventClicked Event', event);
  }

  segmentClicked(action: string, segment: SchedulerViewHourSegment): void {
    console.log('segmentClicked Action', action);
    console.log('segmentClicked Segment', segment);
  }

  resetMenu() {
    this.menuItems = {
      title: 'Admin Scheduler',
      showAdd: false,
      groups: [
        {
          title: 'Schedules',
          items: [
            {
              iden: 'all',
              image: '/assets/images/logo/logo_short.svg',
              title: 'Combined Schedule',
              companyID: this.userData.company,
            },
          ],
          // items: [],
          emptyArrStr: 'You currently have no user groups',
        },
        {
          title: 'Virtual Care Enabled',
          items: [],
          emptyArrStr: 'You currently have no users in your group',
        },
        {
          title: 'Virtual Care Disabled',
          items: [],
          emptyArrStr: 'You currently have no users in your group',
        },
      ],
    };

    // create two lists, one of virtual enabled, one of virtual disabled users
    this.fullUserList.forEach((user) => {
      this.menuItems.groups[user.acceptVirtual ? 1 : 2].items.push({
        type: AdminSchedulerComponent.menuItemTypes.USER,
        iden: user.uid,
        image: user.image ? user.image : '/assets/images/logo/logo_short.svg',
        title: user.first + ' ' + user.last,
      });
    });
  } // end

  async getUserGroups(): Promise<any[]> {
    const userGroups = await this.virtualService
      .getUserGroupsByCompany(this.userData.company)
      .pipe(take(1))
      .toPromise();
    if (userGroups !== null) {
      return userGroups;
    } else {
      return [];
    }
  }

  loadCompanyPlans() {
    if (!!this.fullCompanyPlanSub) {
      this.fullCompanyPlanSub.unsubscribe();
    }
    this.fullCompanyPlanSub = this.virtualService
      .getCompanyPlans(this.userData.company)
      .subscribe((snapshot: any) => {
        if (snapshot !== null) {
          this.companyPlans = snapshot;
          this.companyPlansDictionary = {};
          for (const i in snapshot) {
            if (snapshot[i]) {
              this.companyPlansDictionary[snapshot[i].id] = snapshot[i];
            }
          }
          this.resetMenu();
        }
      });
  } // end func

  async addNewCategory() {
    console.log('adding new category');
    const dialogRef = this.insigDialog.open(CreateCompanyPlanDialogComponent);

    dialogRef.afterClosed().subscribe(async (selection) => {
      if (selection) {
        console.log(selection);
        const newPlan = selection;
        newPlan.companyID = this.userData.company;

        await this.virtualService.addCompanyPlan(newPlan);
      }
    });
    return dialogRef.afterClosed();
  }

  editService(service: any) {
    console.log('editting service');
    console.log(service);

    if (service.planID === 'tiaHealth') {
      this.snackBar.open(
        'Tia Health appointment types can not be edited.',
        undefined,
        { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS },
      );
      return;
    }

    this.newService = false;
    this.selectedService = service;
    if (service.planID) {
      this.selectedPlan = this.companyPlansDictionary[service.planID];
    }

    this.showView = 'edit';
  }

  sortServicesFunc(array) {
    return array.sort((a, b) => {
      if (!a.data || !a.data.label) {
        return 1;
      }
      if (!b.data || !b.data.label) {
        return -1;
      }
      return a.data.label.localeCompare(b.data.label);
    });
  }

  async menuItemSelected(item: any) {
    console.log(item);

    delete this.selectedDoctorID;
    delete this.selectedDoctorCompanyID;
    delete this.selectedDoctorName;

    delete this.companyPlanVirtualServices;
    delete this.selectedCategory;

    if (item.type === AdminSchedulerComponent.menuItemTypes.USER) {
      this.selectedDoctorID = item.iden;
      // Get selected doctor company info
      const userDataQuery = await this.apollo
        .query<{
          getUserData: {
            title: string;
            first: string;
            last: string;
            uid: string;
            company: string;
            acceptVirtual: boolean;
          };
        }>({
          query: this.userDataQuery,
          variables: {
            userID: item.iden,
          },
        })
        .toPromise();

      this.selectedDoctorUserData = userDataQuery.data.getUserData;

      this.selectedDoctorCompanyID = this.selectedDoctorUserData.company;

      this.selectedDoctorName =
        (this.selectedDoctorUserData.title ?? '') +
        ' ' +
        (this.selectedDoctorUserData.first ?? '') +
        ' ' +
        (this.selectedDoctorUserData.last ?? '');

      console.log(this.selectedDoctorCompanyID);

      if (!!this.unsubVirtualServices) {
        this.unsubVirtualServices.unsubscribe();
      }
      this.unsubVirtualServices = this.virtualService
        .getUserVirtualServicesByDoctor(item.iden)
        .subscribe((snapshot) => {
          if (snapshot) {
            console.log(snapshot);
            this.userServices = this.sortServicesFunc(snapshot);
          }
        });
    } else {
      delete this.selectedDoctorName;
      delete this.selectedDoctorID;
      delete this.selectedCategory;
    }
  }

  selectDoctor(uid) {
    if (uid) {
      this.toggleBottomSheet();
      this.menuItemSelected({
        type: AdminSchedulerComponent.menuItemTypes.USER,
        iden: uid,
        image: '/assets/images/logo/logo_short.svg',
      });
    }
  }

  async getSelectedPlanCompanySubscriptions(item: any): Promise<any> {
    const companyPlans = await this.virtualService
      .getSubscribedCompanyPlansByPlanIDByType(item.iden, 'company')
      .pipe(take(1))
      .toPromise();
    if (!!companyPlans && companyPlans.length > 0) {
      return companyPlans;
    } else {
      return [];
    }
  }

  async getSelectedPlanUserGroupSubscriptions(item: any): Promise<any> {
    const companyPlans = await this.virtualService
      .getSubscribedCompanyPlansByPlanIDByType(item.iden, 'userGroup')
      .pipe(take(1))
      .toPromise();
    if (!!companyPlans && companyPlans.length > 0) {
      return companyPlans;
    } else {
      return [];
    }
  }

  addNewService() {
    this.selectedService = {
      apptID: '',
      data: {
        duration: 5,
        buffer: 0,
        coverAmount: 40,
        coverPercent: 100,
        category: null,
        desc: '',
        label: '',
        private: false,
        phone: true,
        price: '40.00',
        video: true,
        inPerson: true,
        messaging: true,
        serviceCode: 'A007',
        diagnosticCode: '799',
        doctorID: this.selectedDoctorID,
        companyID: this.selectedDoctorCompanyID,
        survey: {
          name: 'Default Questionnaire',
          surveyID: 'default',
          userID: 'default',
        },
      },
    };

    this.showView = 'edit';
    this.newService = true;
  }

  async delete() {
    this.showView = 'list';
    this.virtualService.deleteUserVirtualService(this.selectedService.id);

    await this.doctorScheduleReindexService.reindexSchedule(this.selectedDoctorUserData.uid, this.selectedDoctorUserData.company);
  }

  setHoverVariable(val: boolean) {
    this.hoverOver = val;
  }

  selectSurvey() {
    const dialogRef: any = this.insigDialog.open(
      SelectAppointmentSurveyDialogComponent
    );
    dialogRef.componentInstance.userList = this.fullUserList;
    dialogRef.componentInstance.librarySurveys = this.librarySurveys;
    dialogRef.componentInstance.appointment = true;
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
        if (result === 'none') {
          this.selectedService.data.survey = null;
        } else if (result === 'default') {
          this.selectedService.data.survey = {
            name: 'Default Questionnaire',
            surveyID: 'default',
            userID: 'default',
          };
        } else {
          this.selectedService.data.survey = result.survey;
        }
      }
    }); // end after closed
  } // end func

  doesServiceIdExist(service): boolean {
    for (const serv of this.companyServices) {
      if (service.id === serv.id) {
        return true;
      }
    }
    return false;
  }

  doesServiceNameExistInCompanyServices(service): boolean {
    for (const serv of this.companyServices) {
      if (service.data.label.toLowerCase() === serv.data.label.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  doesServiceNameExistInUserServices(service): boolean {
    for (const serv of this.userServices) {
      if (service.data.label.toLowerCase() === serv.data.label.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  back() {
    this.save();
    this.showView = 'list';
  }

  saveNewService() {
    if (!this.selectedService.data.label) {
      this.snackBar.open('Please enter an appointment type title!', null, {
        duration: 4000,
      });
      return false;
    }

    this.selectedService.companyID = this.selectedDoctorCompanyID;

    console.log('NEW SERVICE HERE', this.selectedService);
    // check if doctor already has user survey
    if (this.doesServiceNameExistInUserServices(this.selectedService)) {
      console.log('already exists in user Cases');
      this.snackBar.open('You are already offering this service!', null, {
        duration: 4000,
      });
    } else {
      let saveToCompany = false;
      // check if exists in company data
      if (!this.doesServiceNameExistInCompanyServices(this.selectedService)) {
        saveToCompany = true;
      }
      // if not in company data save to company services otherwise get apptID from company data
      if (saveToCompany) {
        this.selectedService.apptID = this.generalService.generateRandomID(32);
        console.log('does not exist in company services or userServices');
        const companyServ = this.selectedService;
        companyServ.companyID = this.selectedDoctorCompanyID;
        this.virtualService.addCompanyVirtualService(companyServ);
      } else {
        this.selectedService.apptID = this.getCompanyVirtualServiceID(
          this.selectedService
        );
      }

      // save to user Services
      const userServ = this.selectedService;
      userServ.doctorID = this.selectedDoctorID;
      console.log(userServ);
      this.virtualService.addUserVirtualService(userServ);

      // go back to list view

      this.showView = 'list';
      this.snackBar.open(
        "The virtual service '" +
          this.selectedService.data.label +
          "' has been created successfully!",
        null,
        { duration: 5000 }
      );
    }
  }

  getCompanyVirtualServiceID(service) {
    for (const serv of this.companyServices) {
      if (service.data.label.toLowerCase() === serv.data.label.toLowerCase()) {
        return serv.apptID;
      }
    }
  }

  async save(): Promise<void> {
    console.log(
      'saving service',
      this.selectedService,
      this.userData.uid,
      this.selectedService.apptID
    );
    if (this.newService) {
      this.saveNewService();
    } else {
      await this.virtualService.updateUserVirtualService(
        this.selectedService,
        this.selectedService.doctorID,
        this.selectedService.apptID
      );
      this.snackBar.open('Service saved!', null, { duration: 4000 });
    }

    await this.doctorScheduleReindexService.reindexSchedule(this.selectedDoctorUserData.uid, this.selectedDoctorUserData.company);
  }

  copiedURLSuccess() {
    this.snackBar.open('Appointment URL copied successfully!', null, {
      duration: 4000,
    });
  }

  setMinPrice(data) {
    const price = data.price;
    if (!price || parseFloat(price) < 5) {
      data.price = '5.00';
    } else {
      data.price = (Math.round(price * 100) / 100).toFixed(2);
    }
  }

  cancel() {
    console.log('cancelling service');
    delete this.selectedService;
    this.showView = 'list';
  }

  async companyPlanSettingsToggle(save = false) {
    console.log(save);
    this.showBottomSheet = !this.showBottomSheet;
    if (save) {
      console.log(this.selectedPlan);
      const iden = this.selectedPlan.id;
      const savedPlan = this.selectedPlan;
      delete savedPlan.id;
      await this.virtualService.updateCompanyPlans(iden, savedPlan);

      this.snackBar.open('Company Plan Updated', null, { duration: 4000 });
    }
  }

  saveCompanyPlanSettings() {
    this.companyPlanSettingsToggle(true);
    this.snackBar.open('Company Plan settings saved!', null, {
      duration: 4000,
    });
  }

  async getAllCompanies(): Promise<any[]> {
    try {

      let allCompanies: any[] = [];
      console.log('all companies: ', allCompanies);
      // Only display companies that have an id and a name
      allCompanies = allCompanies.filter(
        (company) => !!company.id && !!company.name
      );

      // Sort companies by alphabetical order
      allCompanies = allCompanies.sort((a, b) => a.name.localeCompare(b.name));

      console.log(allCompanies);
      return allCompanies;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  checkIfCompaniesAreSubscribed(companyID) {
    for (const sub of this.subscribedCompanies) {
      if (sub.companyID === companyID) {
        return true;
      }
    }
    return false;
  }

  checkIfGroupsAreSubscribed(userGroupID) {
    for (const sub of this.subscribedUserGroups) {
      if (sub.userGroupID === userGroupID) {
        return true;
      }
    }
    return false;
  }
  async subscribeToPlan(obj: any, type: string): Promise<any> {
    let data;
    if (type === 'company') {
      data = {
        companyID: this.userData.company,
        subscribedCompanyID: obj.id,
        title: obj.name,
        planID: this.selectedCategory.iden,
        type,
      };
    } else {
      data = {
        companyID: this.userData.company,
        subscribedUserGroupID: obj.id,
        title: obj.title,
        planID: this.selectedCategory.iden,
        type,
        groupType: obj.type,
      };
    }

    await this.virtualService.addSubscribedCompanyPlan(data);
  }

  async addSubscribedCompany() {
    console.log(this.selectedCategory);

    const companyList = await this.getAllCompanies();
    console.log(companyList);

    const dialogRef = this.insigDialog.open(AddCompaniesToPlanDialogComponent, {
      data: {
        companyList,
      },
    });

    dialogRef.afterClosed().subscribe(async (selection) => {
      if (selection) {
        if (selection.length > 0) {
          for (const company of selection) {
            if (!this.checkIfCompaniesAreSubscribed(company.id)) {
              console.log('adding company');
              await this.subscribeToPlan(company, 'company');
            }
          }
          this.subscribedCompanies = await this.getSelectedPlanCompanySubscriptions(
            this.selectedCategory
          );
          this.subscribedUserGroups = await this.getSelectedPlanUserGroupSubscriptions(
            this.selectedCategory
          );
        }
      }
    });
    return dialogRef.afterClosed();
  }

  async addSubscribedUserGroup(_type: string) {
    console.log(this.selectedCategory);

    const userGroupList = await this.getUserGroups();
    console.log(userGroupList);

    const dialogRef = this.insigDialog.open(
      AddUserGroupsToPlanDialogComponent,
      {
        data: {
          userGroupList,
        },
      }
    );

    dialogRef.afterClosed().subscribe(async (selection) => {
      if (selection) {
        if (selection.length > 0) {
          for (const group of selection) {
            if (!this.checkIfGroupsAreSubscribed(group.id)) {
              console.log('adding user group');
              await this.subscribeToPlan(group, 'userGroup');
            }
          }
          this.subscribedCompanies = await this.getSelectedPlanCompanySubscriptions(
            this.selectedCategory
          );
          this.subscribedUserGroups = await this.getSelectedPlanUserGroupSubscriptions(
            this.selectedCategory
          );
        }
      }
    });
    return dialogRef.afterClosed();
  }

  async deleteCompanyPlan() {
    const dialogRef = this.insigDialog.open(
      ConfirmCompanyPlanDeleteDialogComponent
    );

    dialogRef.afterClosed().subscribe(async (selection) => {
      if (selection) {
        for (const comp of this.subscribedCompanies) {
          await this.virtualService.removeSubscribedCompanyPlan(comp.id);
        }

        for (const group of this.subscribedUserGroups) {
          await this.virtualService.removeSubscribedCompanyPlan(group.id);
        }

        for (const service of this.companyPlanVirtualServices) {
          await this.virtualService.removeCompanyPlanVirtualService(service.id);
        }

        // NOTE: need to add deletion of reference to VC appointments by doctor

        await this.virtualService.removeCompanyPlan(this.selectedCategory.iden);
        delete this.selectedCategory;
        this.snackBar.open('Company Plan Deleted', null, { duration: 4000 });
      }
    });
    return dialogRef.afterClosed();
  }

  async removeSubscribedElement(obj: any) {
    console.log(obj);
    await this.virtualService.removeSubscribedCompanyPlan(obj.id);
    this.subscribedCompanies = await this.getSelectedPlanCompanySubscriptions(
      this.selectedCategory
    );
    this.subscribedUserGroups = await this.getSelectedPlanUserGroupSubscriptions(
      this.selectedCategory
    );
  }

  async setDoctorAcceptVirtual(acceptVirtual: boolean): Promise<void> {
    if (this.selectedDoctorUserData.acceptVirtual !== acceptVirtual) {
      this.selectedDoctorUserData = {
        ...this.selectedDoctorUserData,
        acceptVirtual,
      };
      try {
        const userDataAcceptVirtualMutation = gql`
          mutation UserDataAcceptVirtualMutation(
            $userId: ID!
            $token: ID!
            $data: UserInput!
          ) {
            setUserData(uid: $userId, token: $token, data: $data) {
              acceptVirtual
            }
          }
        `;

        await this.apollo
          .mutate({
            mutation: userDataAcceptVirtualMutation,
            variables: {
              userId: this.selectedDoctorUserData.uid,
              token: this.IDToken,
              data: {
                acceptVirtual,
              },
            },
          })
          .toPromise();

        await this.doctorScheduleReindexService.reindexSchedule(this.selectedDoctorUserData.uid, this.selectedDoctorUserData.company);
      } catch (error) {
        console.error(error);
      }
    }
  }
}
