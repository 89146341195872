// @ts-strict-ignore
import {
  Directive,
  ElementRef,
  Input,
  ViewContainerRef,
  OnInit,
  OnDestroy,
  HostListener, inject } from '@angular/core';
import {
  FlexibleConnectedPositionStrategy,
  HorizontalConnectionPos,
  Overlay,
  OverlayConfig,
  OverlayRef,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Direction, Directionality } from '@angular/cdk/bidi';

import { InsigMenuComponent } from './insig-menu.component';
import { MenuPositionX, MenuPositionY } from './insig-menu.position';

@Directive({
  selector: '[insMenuTriggerFor]',
  exportAs: 'insMenuTriger',
})
export class InsigMenuTriggerDirective implements OnInit, OnDestroy {
      private _elementRef = inject(ElementRef);
      private _overlay = inject(Overlay);
      private _viewContainerRef = inject(ViewContainerRef);
      private _dir = inject(Directionality, { optional: true });
  private _portal: TemplatePortal;
  private _overlayRef: OverlayRef | null = null;

  @Input('insMenuTriggerFor') menu: InsigMenuComponent;

  private _menuOpen = false;

  ngOnInit() {
    window.addEventListener('click', (event: MouseEvent) => {
      if (this._elementRef.nativeElement.contains(event.target)) {
        // Clicked in box
      } else {
        this.closeMenu();
      }
    });
  }

  ngOnDestroy() {
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = null;
    }
  }

  get dir(): Direction {
    return this._dir && this._dir.value === 'rtl' ? 'rtl' : 'ltr';
  }

  @HostListener('click', ['$event']) handleClick(_event: MouseEvent): void {
    this.toggleMenu();
  }

  toggleMenu(): void {
    return this._menuOpen ? this.closeMenu() : this.openMenu();
  }

  closeMenu(): void {
    this._menuOpen = false;
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = null;
    }
  }

  openMenu(): void {
    if (!this._menuOpen) {
      this._menuOpen = true;
      this._createOverlay().attach(this._portal);
    }
  }

  private _createOverlay(): OverlayRef {
    if (!this._overlayRef) {
      this._portal = new TemplatePortal(
        this.menu.templateRef,
        this._viewContainerRef,
      );
      const config = this._getOverlayConfig();
      this._subscribeToPositions(
        config.positionStrategy as FlexibleConnectedPositionStrategy,
      );
      this._overlayRef = this._overlay.create(config);
    }

    return this._overlayRef;
  }

  private _getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this._getPosition(),
      hasBackdrop: false,
      direction: this.dir,
      scrollStrategy: this._overlay.scrollStrategies.block(),
    });
  }

  private _getPosition(): FlexibleConnectedPositionStrategy {
    const [originX, originFallbackX]: HorizontalConnectionPos[] =
      this.menu.xPosition === 'before' ? ['end', 'start'] : ['start', 'end'];

    const [overlayY, overlayFallbackY]: VerticalConnectionPos[] =
      this.menu.yPosition === 'above' ? ['bottom', 'top'] : ['top', 'bottom'];

    let [originY, originFallbackY] = [overlayY, overlayFallbackY];
    const [overlayX, overlayFallbackX] = [originX, originFallbackX];
    const offsetY = 0;

    if (!this.menu.overlapTrigger) {
      originY = overlayY === 'top' ? 'bottom' : 'top';
      originFallbackY = overlayFallbackY === 'top' ? 'bottom' : 'top';
    }

    return this._overlay
      .position()
      .flexibleConnectedTo(
        this._elementRef,
      )
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }])
      .withPush(true)
      .withDefaultOffsetY(offsetY);
  }

  private _subscribeToPositions(position: FlexibleConnectedPositionStrategy): void {
    position.positionChanges.subscribe((change) => {
      const posX: MenuPositionX =
        change.connectionPair.overlayX === 'start' ? 'after' : 'before';
      const posY: MenuPositionY =
        change.connectionPair.overlayY === 'top' ? 'below' : 'above';

      this.menu.setPositionClasses(posX, posY);
    });
  }
}
