import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'collect-payment-edit',
  templateUrl: './collect-payment-edit.component.html',
  styleUrls: ['./collect-payment-edit.component.scss'],
  providers: [
  ]
})
export class CollectPaymentEditComponent implements OnInit {
  @Input() question: any;
  @Input() companyID: any;
  @Output() questionChange = new EventEmitter<any>();

  ngOnInit(){}

}
