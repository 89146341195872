// @ts-strict-ignore
import { Injectable, inject } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { FirestoreService } from '@insig-health/services/firestore/firestore.service';

@Injectable()
export class PublicBillingService {
  private readonly firestoreService = inject(FirestoreService);

  getAllPublicBillings() {
    return this.firestoreService.getCollectionWithIdsAsObservable(
      firebase.firestore().collection('publicBilling')
    );
  }

  async addPublicBillingSubscribed(data) {
    await firebase
      .firestore()
      .collection('publicBillingSubscribed/')
      .add(data);
  }

  getPublicBillingSubscribedByCompany(companyID) {
    return this.firestoreService.getQueryWithIdAsObservable(
      firebase.firestore().collection('publicBillingSubscribed')
        .where('companyID', '==', companyID)
        .limit(1)
    );
  }

  removePublicBillingSubscribed(iden: string): Promise<boolean> {
    return firebase
      .firestore()
      .collection('publicBillingSubscribed')
      .doc(iden)
      .delete()
      .then(() => {
        console.log('Document successfully deleted!');
        return true;
      })
      .catch((error) => {
        console.error('Error removing document: ', error);
        return false;
      });
  }
}
