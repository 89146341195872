export const MILLISECONDS_PER_SECOND = 1000;

export const SECONDS_PER_MINUTE = 60;
export const MILLISECONDS_PER_MINUTE = SECONDS_PER_MINUTE * MILLISECONDS_PER_SECOND;

export const MINUTES_PER_HOUR = 60;
export const MILLISECONDS_PER_HOUR = MINUTES_PER_HOUR * MILLISECONDS_PER_MINUTE;

export const HOURS_PER_DAY = 24;
export const MILLISECONDS_PER_DAY = HOURS_PER_DAY * MILLISECONDS_PER_HOUR;

export const DAYS_PER_YEAR = 365;
export const DAYS_PER_WEEK = 7;
