import { NgModule } from '@angular/core';
import { DevEnvironmentLabelComponent } from './dev-environment-label.component';

@NgModule({
  declarations: [
    DevEnvironmentLabelComponent,
  ],
  exports: [
    DevEnvironmentLabelComponent,
  ],
})
export class DevEnvironmentLabelModule {}
