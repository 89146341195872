<div
  *ngFor="let offeredQuestion of question.offeredQuestions; let i = index"
  class="w-100"
>
  <h5>{{ offeredQuestion.text }}</h5>
  <div class="flex justify-content-center">
    <button
      *ngFor="let leftOrRight of ['left', 'right']"
      mat-raised-button
      class="btn-w-md mx-3"
      [color]="
        question.response.selectedAnswers[i] ===
        yesOrNoPositions[i][leftOrRight]
          ? 'primary'
          : 'none'
      "
      (click)="changeAnswer(i, yesOrNoPositions[i][leftOrRight])"
    >
      {{ yesOrNoPositions[i][leftOrRight] }}
    </button>
  </div>
  <hr *ngIf="i !== question.offeredQuestions.length - 1" />
</div>
