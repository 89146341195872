<div
  id="settingsSideNav"
  [ngClass]="{
    'span1 primary-navbar h-100': !mobile,
    'w-100 text-center fixed-bottom mobile-background': mobile
  }"
>
  <ul
    class="primary-color-text"
    [ngClass]="{ 'd-flex flex-row w-100 justify-content-around': mobile }"
  >
    <li
      *ngFor="let item of menuItems"
      [ngClass]="{
        'nav-item-selected': selectedTab == item.iden,
        flex: mobile
      }"
    >
      <a
        (click)="changeTab(item.iden)"
        [ngClass]="{
          'nav-item-selected': selectedTab == item.iden,
          'nav-item-not-selected': selectedTab != item.iden
        }"
      >
        <mat-icon class="notranslate">{{ item.icon }}</mat-icon>
        <div [ngClass]="{ 'mobile-nav-text': mobile }">{{ item.title }}</div>
      </a>
    </li>
  </ul>
</div>
