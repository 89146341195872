<!-- begin active view -->
<div *ngIf="isActive" cdkDropList (cdkDropListDropped)="drop($event)">
  <!-- begin offeredAnswers -->
  <div
    *ngFor="let answer of question.offeredAnswers; let i = index"
    cdkDrag
    [cdkDragData]="answer"
  >
    <div class="w-100 d-flex align-items-center">
      <button
        mat-icon-button
        matTooltip="Drag"
        matTooltipPosition="left"
        cdkDragHandle
      >
        <i class="notranslate fas fa-arrows-alt-v fa-2x"></i>
      </button>
      <button
        *ngIf="!!openTriggerDialog"
        [color]="
          answer.pageFlow && answer.pageFlow.trigger.length > 0
            ? 'accent'
            : null
        "
        mat-mini-fab
        color="none"
        matTooltip="Edit Triggers"
        matTooltipPosition="above"
        (click)="openTriggerDialog(answer.id)"
      >
        <mat-icon class="notranslate">call_split</mat-icon>
      </button>
      <mat-form-field class="px-2">
        <input
          required
          matInput
          [ngModel]="answer.value"
          (ngModelChange)="onAnswerChange(i, $event)"
        />
      </mat-form-field>
      <button
        id="offered-answer-flagging-menu-button"
        [ngStyle]="{ background: answer.flag ? answer.flag : 'White' }"
        mat-mini-fab
        color="none"
        [insMenuTriggerFor]="colorMenu"
        matTooltip="Flag Question"
        matTooltipPosition="above"
      >
        <mat-icon class="notranslate">flag</mat-icon>
      </button>
      <ins-menu #colorMenu="insMenu">
        <div mat-menu-item (click)="answer.flag = false">
          <button mat-raised-button>
            <mat-icon class="notranslate">clear</mat-icon>
          </button>
        </div>
        <div
          *ngFor="let color of flagColors"
          mat-menu-item
          (click)="answer.flag = color.color"
        >
          <button
            id="offered-answer-flagging-select-flag-button"
            [ngStyle]="{ background: color.color }"
            mat-raised-button
          >
            <mat-icon class="notranslate">flag</mat-icon>
          </button>
        </div>
      </ins-menu>
      <button
        class="ml-2"
        mat-mini-fab
        [color]="answer.integrationID ? 'accent' : 'none'"
        (click)="openIntegrationsDialog(answer)"
      >
        <mat-icon class="notranslate">cloud_queue</mat-icon>
      </button>
      <button
        class="ml-2"
        mat-mini-fab
        color="none"
        matTooltip="Remove"
        matTooltipPosition="right"
        (click)="question.offeredAnswers.splice(i, 1)"
      >
        <mat-icon class="notranslate">clear</mat-icon>
      </button>
    </div>
  </div>
  <!-- end offeredAnswers -->
  <!-- begin otherAnswer -->
  <div *ngIf="question.otherAnswer" class="w-100 d-flex align-items-center">
    <mat-form-field class="px-2">
      <mat-label>Other:</mat-label>
      <input id="other-answer-input" matInput />
    </mat-form-field>
    <button
      id="remove-other-answer-button"
      mat-mini-fab
      color="none"
      matTooltip="Remove"
      matTooltipPosition="right"
      (click)="toggleOtherAnswer()"
    >
      <mat-icon class="notranslate">clear</mat-icon>
    </button>
  </div>
  <!-- end otherAnswer -->
  <!-- begin config -->
  <div class="d-flex justify-content-center">
    <div class="order-1">
      <button
        mat-raised-button
        class="mx-1"
        (click)="addOption(question.offeredAnswers.length)"
      >
        <span>Add Option</span>
      </button>
      <button
        *ngIf="!question.otherAnswer && !(question.type === 'select')"
        id="add-other-answer-button"
        class="mx-1"
        mat-raised-button
        (click)="toggleOtherAnswer()"
      >
        <span>Add "Other"</span>
      </button>
    </div>
    <ng-container *ngIf="question.type === 'checkbox'">
      <span class="flex order-0 text-center">
        <span>Note Line</span>
        <div class="mt-1"></div>
        <div class="d-flex justify-content-center align-items-center">
          <span>Single&nbsp;</span>
          <mat-slide-toggle [(ngModel)]="question.multiLine"></mat-slide-toggle>
          <span>&nbsp;Multi</span>
        </div>
      </span>
      <span class="flex order-2 text-center">
        <span>Response</span>
        <div class="mt-1"></div>
        <div class="d-flex justify-content-center align-items-center">
          <span>And&nbsp;</span>
          <mat-slide-toggle [(ngModel)]="question.Or"></mat-slide-toggle>
          <span>&nbsp;Or</span>
        </div>
      </span>
    </ng-container>
  </div>
  <!-- end config -->
</div>
<!-- end active view -->

<!-- begin inactive view -->
<div *ngIf="!isActive">
  <!-- begin radio view -->
  <table *ngIf="question.type === 'radio'" class="text-left mx-auto">
    <mat-radio-group class="vertical-group">
      <tr *ngFor="let answer of question.offeredAnswers; let i = index">
        <td>
          <mat-radio-button [value]="answer.id"> </mat-radio-button>
        </td>
        <td class="text-left">
          {{ answer.value }}
        </td>
      </tr>

      <tr *ngIf="question.otherAnswer">
        <td>
          <mat-radio-button [value]="'other'" ngDefaultControl>
          </mat-radio-button>
        </td>
        <td class="text-left">
          <span>Other: </span>
          <mat-form-field>
            <input matInput type="text" />
          </mat-form-field>
        </td>
      </tr>
    </mat-radio-group>
  </table>
  <!-- end radio view -->
  <!-- begin checkbox view -->
  <table *ngIf="question.type === 'checkbox'" class="text-left mx-auto">
    <tr *ngFor="let answer of question.offeredAnswers; let i = index">
      <td>
        <mat-checkbox></mat-checkbox>
      </td>
      <td>
        <span class="space space-md"></span>
      </td>
      <td>
        <span>{{ answer.value }}</span>
      </td>
    </tr>

    <tr *ngIf="question.otherAnswer">
      <td>
        <mat-checkbox></mat-checkbox>
      </td>
      <td>
        <span class="space space-md"></span>
      </td>
      <td>
        <span>Other: </span>
        <mat-form-field>
          <input matInput type="text" />
        </mat-form-field>
      </td>
    </tr>
  </table>

  <!-- end checkbox view -->
  <!-- begin select view -->
  <div *ngIf="question.type === 'select'" class="form-group row">
    <div class="col-2"></div>
    <div class="col-8">
      <mat-form-field class="full-width">
        <mat-label>Select an option</mat-label>
        <mat-select>
          <mat-option
            *ngFor="let answer of question.offeredAnswers"
            [value]="answer.id"
          >
            {{ answer.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-2"></div>
  </div>
  <!-- end select view -->
</div>
<!-- end inactive view -->
