import { Injectable, inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';
import { PatientProfileService } from '@insig-health/services/patient-profile/patient-profile.service';

@Injectable({
  providedIn: 'root',
})
export class IsPatientProfileCompleteGuard {
  private readonly gcpIpAuthService = inject(GcpIpAuthService);
  private readonly patientProfileService = inject(PatientProfileService);
  private readonly router = inject(Router);
  private loginUrlTree = this.router.createUrlTree(['/auth/login']);

  async canActivate(): Promise<boolean | UrlTree> {
    const currentUser = this.gcpIpAuthService.getCurrentUser();
    if (currentUser === null) {
      return this.loginUrlTree;
    }
    if (await this.gcpIpAuthService.isUserDoctor(currentUser)) {
      return true;
    }
    const isPatientProfileComplete = await this.patientProfileService.isPatientProfileComplete(currentUser.uid);
    if (!isPatientProfileComplete) {
      return this.loginUrlTree;
    } else {
      return true;
    }
  }
}
