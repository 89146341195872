import { Component, OnInit } from '@angular/core';
import { APPCONFIG } from '@insig-health/config/config';

@Component({
  selector: 'my-page-maintenance',

  templateUrl: './maintenance.component.html',
})
export class PageMaintenanceComponent implements OnInit {
  public AppConfig: any;

  ngOnInit() {
    this.AppConfig = APPCONFIG;
  }
}
