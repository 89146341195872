<div *ngIf="(!question.pdfURL || error) && question.pdfType != 'patient'">
  <div class="row">
    <div class="col-10 offset-1">
      <p>Error loading PDF! Please ignore this question.</p>
    </div>
  </div>
</div>

<div *ngIf="question.pdfURL && !error && question.pdfType != 'patient'">
  <div *ngIf="loading">
    <mat-progress-spinner
      class="mx-auto"
      mode="indeterminate"
    ></mat-progress-spinner>
    <div class="divider-md divider"></div>
  </div>
  <div *ngIf="question.readOnly">
    <pdf-viewer
      class="d-block w-100"
      style="height: 500px;"
      [src]="question.pdfURL"
      [render-text]="false"
      (error)="onError($event)"
      [show-all]="true"
      [original-size]="false"
      (after-load-complete)="callBackFn($event)"
    >
    </pdf-viewer>
  </div>
  <div *ngIf="!question.readOnly" class="text-center" [hidden]="loading">
    <button mat-button class="w-100" (click)="openPDF()">
      <h5>Click/tap to open document</h5>
      <pdf-viewer
        *ngIf="!question.response[0]"
        class="d-block w-100"
        style="height: 500px;"
        [src]="question.pdfURL"
        [render-text]="false"
        [page]="1"
        (error)="onError($event)"
        [show-all]="false"
        [original-size]="true"
        (after-load-complete)="callBackFn($event)"
      >
      </pdf-viewer>
    </button>
    <div *ngIf="question.response && question.response[0]">
      <img
        class="maxWidth75"
        [src]="question.response[page - 1]"
        (click)="openPDF()"
      />
      <div class="row" *ngIf="question.numPages > 1">
        <div class="col-6">
          <button
            class="float-right"
            mat-raised-button
            [disabled]="page <= 1"
            (click)="page = page - 1"
          >
            <mat-icon class="notranslate material-icons"
              >keyboard_arrow_left</mat-icon
            >Previous Page
          </button>
        </div>
        <div class="col-6">
          <button
            class="float-left"
            mat-raised-button
            [disabled]="page >= question.numPages"
            (click)="page = page + 1"
          >
            Next Page<mat-icon class="notranslate material-icons"
              >keyboard_arrow_right</mat-icon
            >
          </button>
          <span class="float-right smallMarginTop"
            >Page {{ page }} / {{ question.response.length }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="question.pdfType == 'patient'">
  <div class="container">
    <div class="row">
      <div class="col-10 offset-1 text-center">
        <p [hidden]="loading">Upload PDF below</p>
        <ngx-file-drop
          (onFileDrop)="onAdded($event)"
          multiple="false"
          accept=".pdf"
          dropZoneClassName="ngxFileDropZone"
          contentClassName="ngxFileDropContent"
        >
          <ng-template
            ngx-file-drop-content-tmp
            let-openFileSelector="openFileSelector"
          >
            <span>Drop file here</span>
            <span class="px-3"><!-- divider --></span>
            <button
              mat-raised-button
              color="primary"
              type="button"
              (click)="openFileSelector()"
            >
              Browse
            </button>
          </ng-template>
        </ngx-file-drop>
        <div class="divider"></div>
        <mat-progress-spinner
          class="mx-auto"
          *ngIf="loading"
          mode="indeterminate"
        ></mat-progress-spinner>
      </div>
    </div>
  </div>

  <div *ngIf="question.patientPDFs && question.patientPDFs.length > 0">
    <mat-tab-group>
      <mat-tab
        label="{{ question.patientPDFs[i].name }}"
        *ngFor="let doc of question.patientPDFs; let i = index"
      >
        <button mat-raised-button class="mb-1 mt-4 pr-2" (click)="removePDF(i)">
          Remove PDF
          <mat-icon class="notranslate material-icons">clear</mat-icon>
        </button>
        <insig-pdf-embed
          class="w-100"
          style="height: 50vh;"
          [url]="uploadedPDFIDs[question.patientPDFs[i].pdfDictID]"
        >
        </insig-pdf-embed>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
