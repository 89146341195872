/// <reference types="@types/googlemaps" />

import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

export interface Address {
  streetAddress: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
  latitude?: number;
  longitude?: number;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export const TORONTO_COORDINATES: Coordinates = {
  latitude: 43.65107,
  longitude: -79.347015,
};

@Injectable({
  providedIn: 'root',
})
export class GooglePlaceService {
  getPlaceResultObservable(input: HTMLInputElement): Observable<google.maps.places.PlaceResult> {
    return new Observable((subscriber) => {
      const autoComplete = new google.maps.places.Autocomplete(input, {
        types: ['address'],
        fields: ['address_components', 'geometry', 'formatted_address'],
        componentRestrictions: {
          country: ['ca', 'us'],
        },
      });
      const autoCompleteListener = autoComplete.addListener('place_changed', () => {
        subscriber.next(autoComplete.getPlace());
      });

      return function unsubscribe() {
        google.maps.event.removeListener(autoCompleteListener);
      };
    });
  }

  getPlaceChangedObservable(input: HTMLInputElement): Observable<Address> {
    return this.getPlaceResultObservable(input).pipe(
      map((placeResult) => this.getAddressFromPlaceResult(placeResult)),
    );
  }

  getAddressFromPlaceResult(placeResult: google.maps.places.PlaceResult): Address {
    let streetNumber = '';
    let streetName = '';
    let city = '';
    let province = '';
    let country = '';
    let postalCode = '';
    placeResult.address_components?.forEach((component) => {
      if (component.types.includes('street_number')) {
        streetNumber = component.long_name;
      }
      if (component.types.includes('route')) {
        streetName = component.long_name;
      }
      if (component.types.includes('locality')) {
        city = component.long_name;
      }
      if (component.types.includes('administrative_area_level_1')) {
        province = component.short_name;
      }
      if (component.types.includes('country')) {
        country = component.long_name;
      }
      if (component.types.includes('postal_code')) {
        postalCode = component.long_name;
      }
    });

    const streetAddress = `${streetNumber} ${streetName}`.trim();
    const latitude = placeResult.geometry?.location?.lat();
    const longitude = placeResult.geometry?.location?.lng();

    return {
      streetAddress,
      city,
      province,
      country,
      postalCode,
      latitude,
      longitude,
    };
  }
}
