import { NgModule } from '@angular/core';
import { SearchPipe } from './search.pipe';
import { DictToArrayPipe } from './dict-to-array.pipe';
import { DayAbbreviationPipe } from './dayAbbreviation.pipe';
import { UrlToBase64Pipe } from './url-to-base64.pipe';
import { UrlToBlobPipe } from './url-to-blob.pipe';
import { PhonePipe } from './phone.pipe';
import { MultiSearchPipe } from './multi-search.pipe';
import { TruncatePipe } from './truncate.pipe';
import { SortByPipe } from './sortBy.pipe';
import { SafeUrlPipe } from './safeUrl.pipe';
import { SafeHtmlPipe } from './safeHtml.pipe';
import { DoctorIdToFullNamePipe } from './doctor-id-to-full-name.pipe';
import { MfaAuthAppLinksPipe } from './mfa-auth-app-links.pipe';

@NgModule({
  exports: [
    SearchPipe,
    DictToArrayPipe,
    DayAbbreviationPipe,
    UrlToBase64Pipe,
    UrlToBlobPipe,
    PhonePipe,
    MultiSearchPipe,
    TruncatePipe,
    SafeUrlPipe,
    SortByPipe,
    SafeHtmlPipe,
    DoctorIdToFullNamePipe,
    MfaAuthAppLinksPipe,
  ],
  declarations: [
    SearchPipe,
    DictToArrayPipe,
    DayAbbreviationPipe,
    UrlToBase64Pipe,
    UrlToBlobPipe,
    PhonePipe,
    MultiSearchPipe,
    TruncatePipe,
    SafeUrlPipe,
    SortByPipe,
    SafeHtmlPipe,
    DoctorIdToFullNamePipe,
    MfaAuthAppLinksPipe,
  ],
})
export class PipesModule {}
