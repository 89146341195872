<div class="text-center">
  <p>*Remember to include "https://" at the start of the URL</p>
</div>
<div class="row">
  <div class="col-10 offset-1">
    <mat-form-field class="full-width">
      <mat-label>Button Label</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="question.label"
      />
    </mat-form-field>
    
    <mat-form-field class="full-width">
      <mat-label>Link / URL</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="question.link"
      />
    </mat-form-field>
  </div>

  <div class="col-12 col-md-6 mx-auto">
    <div
      class="my-4 action-button primary-color-full"
    >
      <div class="justify-content-center align-items-center">
        <div class="row mx-0 mb-2 mt-0">
          <span class="title mx-auto"
            >&nbsp;
            {{ !!question.label ? question.label : question.link }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
