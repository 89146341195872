<insig-ui-card *ngIf="loginState !== LoginState.LOGGING_IN" class="w-100 d-flex flex-column mw-inherit" [ngClass]="{ 'disableCardBorder': disableCardBorder }">
  <ng-container *ngIf="loginState === LoginState.NOT_LOGGED_IN">
    <ng-container *ngTemplateOutlet="loginFormTemplate"></ng-container>
  </ng-container>

  <ng-container *ngIf="loginState === LoginState.MFA_VERIFICATION">
    <ng-container *ngTemplateOutlet="mfaFormTemplate"></ng-container>
  </ng-container>
</insig-ui-card>

<ng-container *ngIf="loginState === LoginState.LOGGING_IN">
  <div class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-container>

<ng-template #loginFormTemplate>
  <div *ngIf="isLogoVisible" class="d-flex align-items-center justify-content-center border-bottom w-100 py-4">
    <img src="/assets/images/yourcare-logo.png" height="40" alt="Your Care Logo" aria-label="Your Care Logo">
  </div>

  <div class="d-flex flex-column w-100 px-4">
    <div class="h2 align-self-center my-3 font-size-26px font-weight-500 line-height-1-point-1">Sign In</div>

    <ng-container *ngTemplateOutlet="errorMessageTemplate"></ng-container>

    <form [formGroup]="loginForm" class="d-flex flex-column">
      <insig-ui-text-input
        class="mb-3"
        isRequired="true"
        label="Email"
        type="email"
        [smallMargins]="true"
        [isReadOnly]="readOnlyEmail !== undefined"
        [hidden]="readOnlyEmail !== undefined"
        [formControl]="loginForm.controls.email"
      ></insig-ui-text-input>
      <insig-ui-text-input
        class="mb-4 font-size-16px font-size-md-1rem"
        label="Password"
        type="password"
        isRequired="true"
        [smallMargins]="true"
        [formControl]="loginForm.controls.password"
      ></insig-ui-text-input>
      <insig-ui-button
        class="w-100"
        stylePreset="secondary"
        type="submit"
        [disabled]="!loginForm.valid"
        (click)="handleSignInButtonClicked()"
      >
        Sign In
      </insig-ui-button>
    </form>

    <ng-container *ngIf="!disableSignUp">
      <div class="d-flex align-items-center justify-content-center w-100 py-2 gap-3">
        <div class="flex-fill horizontal-line"></div>
        <span class="textSecondary">OR</span>
        <div class="flex-fill horizontal-line"></div>
      </div>
      <insig-ui-button
        class="w-100"
        stylePreset="secondary"
        (click)="handlePatientSignUpButtonClicked()">{{ disableClinicianAuthentication ? 'Sign Up' : 'Patient Sign Up' }}
      </insig-ui-button>
      <insig-ui-button
        *ngIf="!disableClinicianAuthentication"
        class="w-100 mt-3"
        stylePreset="secondary"
        (click)="handleClinicianSignUpButtonClicked()">Clinician Sign Up
      </insig-ui-button>
    </ng-container>

    <a [href]="PASSWORD_RESET_LINK" class="text-decoration-none mt-4">Forgot Password?</a>
    <a href="https://tiahealth.com/contact/" class="text-decoration-none pt-2">Help</a>
  </div>
</ng-template>

<ng-template #mfaFormTemplate>
  <ng-container *ngIf="mfaResolver && selectedMultiFactorInfo">
    <div class="d-flex flex-column gap-2">
      <div>
        Enter your MFA verification code.
      </div>
  
      <ng-container *ngTemplateOutlet="errorMessageTemplate"></ng-container>
  
      <form [formGroup]="mfaForm" class="d-flex flex-column gap-3">
        <insig-ui-one-time-password-input
          autofocus
          [formControl]="mfaForm.controls.verificationCode"
          (paste)="handleMfaVerificationCodePasted($event, mfaForm, mfaResolver, selectedMultiFactorInfo)"
        ></insig-ui-one-time-password-input>
        <div class="d-flex align-items-center justify-content-between">
          <insig-ui-button
            stylePreset="tertiary"
            (click)="handleMfaBackButtonClicked()"
          >
            Back
          </insig-ui-button>
          <insig-ui-button
            stylePreset="secondary"
            type="submit"
            [disabled]="!mfaForm.valid"
            (click)="handleMfaSignInButtonClicked(mfaForm, mfaResolver, selectedMultiFactorInfo)"
          >
            Sign In
          </insig-ui-button>
        </div>
      </form>
    </div>
  </ng-container>
</ng-template>

<ng-template #errorMessageTemplate>
  <div *ngIf="errorMessage !== undefined" class="d-flex align-items-center mb-3">
    <div class="bg-danger p-icon rounded-circle">
      <div class="icon text-danger text-center bg-white rounded-circle">!</div>
    </div>
    <div class="flex-fill bold p-2">{{ errorMessage }}</div>
  </div>
</ng-template>
