import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PdfEmbedComponent } from './pdf-embed.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    PdfEmbedComponent,
  ],
  exports: [
    PdfEmbedComponent,
  ]
})
export class PdfEmbedModule { }
