// @ts-strict-ignore
import { Component, ElementRef, QueryList, ViewChildren, inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import firebase from 'firebase/compat/app';
import qrCode from 'qrcode';
import type { TotpSecret } from 'firebase/auth';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';
import { Observable, filter, firstValueFrom, map } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/config';
import { MatDialogRef } from '@angular/material/dialog';

enum MfaEnrollmentState {
  LOADING,
  GENERAL_MFA_INFO,
  VERIFY_EMAIL,
  LOGIN,
  SELECT_DEVICE_TYPE,
  VERIFY_MFA_CODE,
  EXPIRED_MFA_CODE,
  SUCCESS,
  REMOVE_MFA,
  REMOVE_MFA_SUCCESS,
}

enum ErrorMessage {
  GENERATE_QR_CODE_FAILURE = 'Error generating QR code. Please try again later.',
  TOO_MANY_VERIFICATION_CODE_ATTEMPTS = 'Too many verification code attempts. Please scan a new QR code.',
  COULD_NOT_SEND_VERIFICATION_EMAIL = 'Could not send verification email.',
}

enum InfoMessage {
  SENT_VERIFICATION_EMAIL = 'Verification email sent.',
}

export enum MfaDeviceTypeOptions {
  ANDROID = 'ANDROID',
  IPHONE = 'IPHONE',
}

export interface MfaAuthApp {
  name: string;
  logo: string;
  logoAltText: string;
  url: string;
}

@Component({
  selector: 'insig-health-mfa-enrollment-dialog',
  templateUrl: './mfa-enrollment-dialog.component.html',
  styleUrls: ['./mfa-enrollment-dialog.component.scss'],
})
export class MfaEnrollmentDialogComponent {
  private readonly gcpIpAuthService = inject(GcpIpAuthService);
  private readonly snackBar = inject(MatSnackBar);
  private readonly matDialogRef = inject<MatDialogRef<MfaEnrollmentDialogComponent>>(MatDialogRef<MfaEnrollmentDialogComponent>);
  public static readonly ANDROID_AUTHENTICATOR_APP_LINKS: MfaAuthApp[] = [
    {
      name: 'Duo Mobile',
      logo: 'assets/images/icons/duo-mobile-app-logo.png',
      logoAltText: 'Duo Mobile App Logo',
      url: 'https://play.google.com/store/apps/details?id=com.duosecurity.duomobile&pcampaignid=web_share',
    },
    {
      name: 'Google Authenticator',
      logo: 'assets/images/icons/google-authenticator-app-logo.png',
      logoAltText: 'Google Authenticator App Logo',
      url: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share',
    },
    {
      name: 'Microsoft Authenticator',
      logo: 'assets/images/icons/microsoft-authenticator-app-logo.svg',
      logoAltText: 'Microsoft Authenticator App Logo',
      url: 'https://play.google.com/store/apps/details?id=com.azure.authenticator&pcampaignid=web_share',
    },
    {
      name: 'Twilio Authy',
      logo: 'assets/images/icons/twilio-authy-app-logo.png',
      logoAltText: 'Twilio Authy App Logo',
      url: 'https://play.google.com/store/apps/details?id=com.authy.authy&pcampaignid=web_share',
    },
  ];
  public static readonly IOS_AUTHENTICATOR_APP_LINKS: MfaAuthApp[] = [
    {
      name: 'Duo Mobile',
      logo: 'assets/images/icons/duo-mobile-app-logo.png',
      logoAltText: 'Duo Mobile App Logo',
      url: 'https://apps.apple.com/us/app/duo-mobile/id422663827',
    },
    {
      name: 'Google Authenticator',
      logo: 'assets/images/icons/google-authenticator-app-logo.png',
      logoAltText: 'Google Authenticator App Logo',
      url: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
    },
    {
      name: 'Microsoft Authenticator',
      logo: 'assets/images/icons/microsoft-authenticator-app-logo.svg',
      logoAltText: 'Microsoft Authenticator App Logo',
      url: 'https://apps.apple.com/us/app/microsoft-authenticator/id983156458',
    },
    {
      name: 'Twilio Authy',
      logo: 'assets/images/icons/twilio-authy-app-logo.png',
      logoAltText: 'Twilio Authy App Logo',
      url: 'https://apps.apple.com/us/app/twilio-authy/id494168017',
    },
  ];

  public static readonly ErrorMessage = ErrorMessage;
  public static readonly InfoMessage = InfoMessage;
  public static readonly TOTP_DISPLAY_NAME = 'Insig Health';

  public readonly MfaEnrollmentState = MfaEnrollmentState;
  public mfaEnrollmentState = MfaEnrollmentState.GENERAL_MFA_INFO;
  public verificationCodeForm = new FormGroup({
    verificationCode: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
  });
  public passwordForm = new FormGroup({
    password: new FormControl('', [Validators.required]),
  });

  public email = this.gcpIpAuthService.getCurrentUser()?.email;
  public totpSecret: TotpSecret | undefined;
  public errorMessage: string | undefined;

  public readonly DeviceTypeOptions = MfaDeviceTypeOptions;
  public selectedDeviceType: MfaDeviceTypeOptions.ANDROID | MfaDeviceTypeOptions.IPHONE | undefined;

  @ViewChildren('qrCodeCanvas') private _canvasQuery: QueryList<ElementRef<HTMLCanvasElement>>;

  constructor(
) {
    firstValueFrom(this.matDialogRef.beforeClosed()).then(async () => {
      const customToken = await this.gcpIpAuthService.getFirebaseCustomToken();
      await this.gcpIpAuthService.signInWithCustomToken(customToken);
    });
  }

  async renderQrCode(): Promise<void> {
    try {
      const totpSecret = await this.getTotpSecret();
      if (totpSecret) {
        const qrCodeUrl = await this.getQrCodeUrl(totpSecret);
        this.totpSecret = totpSecret;
        this.mfaEnrollmentState = MfaEnrollmentState.VERIFY_MFA_CODE;
        const canvas = await this.getQrCodeCanvas();
        qrCode.toCanvas(canvas, qrCodeUrl);
      }
    } catch (error) {
      console.error(error);
      this.snackBar.open(
        ErrorMessage.GENERATE_QR_CODE_FAILURE,
        undefined,
        { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS },
      );
    }
  }

  async getQrCodeCanvas(): Promise<HTMLCanvasElement> {
    const canvasQuery = this._canvasQuery;
    const canvas = await firstValueFrom((canvasQuery.changes as Observable<typeof canvasQuery>).pipe(
      map((changes) => changes.first),
      filter((canvas) => canvas !== undefined),
      map((canvas) => canvas.nativeElement),
    ));
    return canvas;
  }

  async handleGeneralMfaInfoNextClicked(): Promise<void> {
    const isSignedInWithCustomToken = await this.gcpIpAuthService.isSignedInWithCustomToken();
    if (!isSignedInWithCustomToken) {
      this.renderQrCode();
    } else {
      this.mfaEnrollmentState = MfaEnrollmentState.LOGIN;
    }
  }

  async handleResendVerificationEmailClicked(): Promise<void> {
    try {
      await this.gcpIpAuthService.sendEmailVerification();
      this.snackBar.open(InfoMessage.SENT_VERIFICATION_EMAIL, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
    } catch (error) {
      console.error(error);
      this.snackBar.open(ErrorMessage.COULD_NOT_SEND_VERIFICATION_EMAIL, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS});
    }
  }

  async handleLoginSuccessOrVerifyEmailNextClicked(): Promise<void> {
    this.mfaEnrollmentState = MfaEnrollmentState.LOADING;
    if (await this.gcpIpAuthService.isMfaEnrolled()) {
      this.renderQrCode();
    } else {
      this.showDeviceSelection();
    }
  }

  handleDeviceTypeSelected(): void {
    this.mfaEnrollmentState = MfaEnrollmentState.LOADING;
    this.renderQrCode();
  }

  showDeviceSelection(): void {
    this.mfaEnrollmentState = MfaEnrollmentState.SELECT_DEVICE_TYPE;
  }

  async handleVerificationCodeFormSubmitted(verificationCodeForm: typeof this.verificationCodeForm, totpSecret: TotpSecret): Promise<void> {
    this.mfaEnrollmentState = MfaEnrollmentState.LOADING;
    const { verificationCode } = verificationCodeForm.value;
    this.errorMessage = undefined;
    try {
      await this.gcpIpAuthService.enrollMfa(totpSecret, verificationCode, MfaEnrollmentDialogComponent.TOTP_DISPLAY_NAME);
      this.mfaEnrollmentState = MfaEnrollmentState.SUCCESS;
    } catch (error) {
      verificationCodeForm.reset();
      this.handleMultiFactorEnrollmentError(error);
    }
  }

  async handleUnenrollClicked(): Promise<void> {
    this.mfaEnrollmentState = MfaEnrollmentState.LOADING;
    await this.gcpIpAuthService.unenrollMfa();
    this.mfaEnrollmentState = MfaEnrollmentState.REMOVE_MFA_SUCCESS;
  }

  private handleTotpSecretGenerationError(error: firebase.auth.Error): void {
    switch (error.code) {
      case 'auth/unverified-email': {
        this.handleUnverifiedEmailError();
        break;
      }
      case 'auth/unsupported-first-factor':
      case 'auth/requires-recent-login': {
        this.handleUnsupportedFirstFactorError();
        break;
      }
      case 'auth/maximum-second-factor-count-exceeded': {
        this.handleMaximumSecondFactorCountExceededError();
        break;
      }
      default: {
        console.error(error);
        this.handleUnexpectedError();
        throw error;
      }
    }
  }

  private handleMultiFactorEnrollmentError(error: firebase.auth.Error): void {
    switch (error.code) {
      case 'auth/code-expired':
      case 'auth/invalid-verification-code': {
        this.mfaEnrollmentState = MfaEnrollmentState.EXPIRED_MFA_CODE;
        break;
      }
      case 'auth/unsupported-first-factor': {
        this.handleUnsupportedFirstFactorError();
        break;
      }
      case 'auth/too-many-enrollment-attempts': {
        this.errorMessage = ErrorMessage.TOO_MANY_VERIFICATION_CODE_ATTEMPTS;
        this.renderQrCode();
        break;
      }
      default: {
        console.error(error);
        this.handleUnexpectedError();
        break;
      }
    }
  }

  private async getTotpSecret(): Promise<TotpSecret> {
    try {
      return await this.gcpIpAuthService.getTotpSecret();
    } catch (error) {
      this.handleTotpSecretGenerationError(error);
    }
  }

  private async getQrCodeUrl(totpSecret: TotpSecret): Promise<string> {
    const user = this.gcpIpAuthService.getCurrentUser();
    const qrCodeUrl = totpSecret.generateQrCodeUrl(user.email, 'Insig Health');
    return qrCodeUrl;
  }

  private handleUnverifiedEmailError(): void {
    this.mfaEnrollmentState = MfaEnrollmentState.VERIFY_EMAIL;
    this.gcpIpAuthService.sendEmailVerification();
  }

  private handleUnsupportedFirstFactorError(): void {
    this.mfaEnrollmentState = MfaEnrollmentState.LOGIN;
  }

  private handleMaximumSecondFactorCountExceededError(): void {
    this.mfaEnrollmentState = MfaEnrollmentState.REMOVE_MFA;
  }

  private handleUnexpectedError(): void {
    this.snackBar.open('An unexpected error occurred. Please try again later.', undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
    this.matDialogRef.close();
  }
}
