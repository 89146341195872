import { Component, Input } from '@angular/core';

@Component({
  selector: 'insig-ui-image-container',
  templateUrl: './insig-image-container.component.html',
})
export class InsigImageContainerComponent {
  @Input() imageUrl: string | undefined;
  @Input() fallbackImageUrl: string | undefined; // Should be a locally hosted file or base64 encoded image data
  @Input() alt = '';
  @Input() width: string | undefined;
  @Input() height: string | undefined;

  ngOnInit(): void {
    if (this.fallbackImageUrl === undefined) {
      throw new Error('No fallbackImageUrl provided');
    }
  }

  handleImageLoadError(): void {
    if (this.imageUrl !== this.fallbackImageUrl) {
      this.imageUrl = this.fallbackImageUrl;
    }
  }
}
