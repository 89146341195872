<mat-card class="p-1">
  <mat-dialog-content class="d-flex flex-column">
    <div class="w-100vw">
      <!-- spacer so that canvases are correct width after view init -->
    </div>
    <div class="w-100">
      <button class="float-left" mat-raised-button (click)="renderWholePdf()">
        Restart
        <i class="notranslate material-icons close_button">restore</i>
      </button>
      <button class="float-left ml-3" mat-raised-button (click)="undo()">
        Undo
        <i class="notranslate material-icons close_button">undo</i>
      </button>

      <button
        mat-raised-button
        class="ml-3 float-right"
        color="primary"
        (click)="dialogRef.close(true)"
      >
        Done
      </button>

      <div class="text-center hidden-sm-down">
        <button
          [ngStyle]="{ background: lineColor, color: 'white' }"
          mat-raised-button
          [insMenuTriggerFor]="colorMenu"
          *ngIf="!drawingOrText"
        >
          Color
        </button>
        <button
          mat-raised-button
          [insMenuTriggerFor]="fontMenu"
          *ngIf="drawingOrText"
        >
          <mat-icon class="notranslate">font_download</mat-icon>Font Size
        </button>

        <ins-menu #colorMenu="insMenu">
          <div
            *ngFor="let color of colors"
            mat-menu-item
            (click)="setDrawingColor(color)"
          >
            <button [ngStyle]="{ background: color }" mat-raised-button>
              &nbsp;
            </button>
          </div>
        </ins-menu>
        <span class="space"></span>

        <button
          mat-raised-button
          *ngIf="drawingOrText"
          (click)="changeDrawingOrText(false)"
        >
          Switch to Drawing<mat-icon class="notranslate">mode_edit</mat-icon>
        </button>
        <button
          mat-raised-button
          *ngIf="!drawingOrText"
          (click)="changeDrawingOrText(true)"
        >
          Switch to Text<mat-icon class="notranslate">text_fields</mat-icon>
        </button>

        <!-- <span class="space"></span> -->
        <ins-menu #fontMenu="insMenu">
          <div
            class="notranslate"
            *ngFor="let font of fontSizes"
            mat-menu-item
            (click)="setFont(font)"
          >
            <button [ngStyle]="{ font: font }" mat-button>Aa Bb Cc</button>
          </div>
        </ins-menu>
      </div>

      <div class="hidden-md-up">
        <button mat-raised-button class="ml-3" [insMenuTriggerFor]="apptMenu">
          <mat-icon class="notranslate">more_vert</mat-icon>
          <span>Tools</span>
        </button>
        <ins-menu #apptMenu="insMenu">
          <button
            mat-menu-item
            *ngIf="drawingOrText"
            (click)="changeDrawingOrText(false)"
          >
            Switch to Drawing <mat-icon class="notranslate">mode_edit</mat-icon>
          </button>
          <button
            mat-menu-item
            *ngIf="!drawingOrText"
            (click)="changeDrawingOrText(true)"
          >
            Switch to Text <mat-icon class="notranslate">text_fields</mat-icon>
          </button>
          <button
            mat-menu-item
            [ngStyle]="{ background: lineColor, color: 'white' }"
            [insMenuTriggerFor]="colorMenu"
          >
            Color
          </button>
          <button mat-menu-item [insMenuTriggerFor]="fontMenu">
            Font Size <mat-icon class="notranslate">font_download</mat-icon>
          </button>
        </ins-menu>

        <ins-menu #fontMenu="insMenu">
          <div
            class="notranslate"
            *ngFor="let font of fontSizes"
            mat-menu-item
            (click)="setFont(font)"
          >
            <button [ngStyle]="{ font: font }" mat-button>Aa Bb Cc</button>
          </div>
        </ins-menu>

        <ins-menu #colorMenu="insMenu">
          <div
            *ngFor="let color of colors"
            mat-menu-item
            (click)="setDrawingColor(color)"
          >
            <button [ngStyle]="{ background: color }" mat-raised-button>
              &nbsp;
            </button>
          </div>
        </ins-menu>
      </div>
    </div>

    <div *ngIf="!question.pdfURL || error" class="row no-gutters">
      <div class="col-10 offset-1">
        <p>Error, loading PDF! Please ignore this question.</p>
      </div>
    </div>

    <div
      #pdfContainer
      class="overflow-scroll"
      style="min-height: 40vh;"
      (click)="clickCanvas($event)"
    ></div>
  </mat-dialog-content>
</mat-card>

<div class="text-center w-100"></div>
