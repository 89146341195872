// @ts-strict-ignore
import { Component, Input, ViewChild, ElementRef, Output, EventEmitter, OnChanges, SimpleChanges, OnInit, AfterViewInit, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'video-question',
  templateUrl: './video.component.html',
})
export class VideoComponent implements OnInit, AfterViewInit, OnChanges {
  private readonly domSanitizer = inject(DomSanitizer);
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();

  safeURL = null;
  @ViewChild('container') public container: ElementRef;
  width = 616;
  height = 347;

  ngOnInit(): void {
    if (this.question.url) {
      const url = this.getYoutubeID(this.question.url);
      this.safeURL = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

  ngAfterViewInit(): void {
    if (window.screen.width < 700) {
      this.width = this.container.nativeElement.offsetWidth * 0.8;
      this.height = (this.width * 347) / 616;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  getYoutubeID(url) {
    const baseURL = 'https://www.youtube.com/embed/';
    return baseURL + url.split('=')[1];
  }
} // end component
