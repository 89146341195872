// @ts-strict-ignore
import { Component, OnInit, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'download-survey-data-dialog',
  templateUrl: './download-survey-data-dialog.component.html',
  styleUrls: ['./download-survey-data-dialog.component.scss'],
})
export class DownloadSurveyDataDialogComponent implements OnInit {
  public readonly dialogRef = inject<MatDialogRef<DownloadSurveyDataDialogComponent>>(MatDialogRef<DownloadSurveyDataDialogComponent>);
  private readonly formBuilder = inject(UntypedFormBuilder);
  private readonly snackBar = inject(MatSnackBar);
  public readonly data = inject<any>(MAT_DIALOG_DATA);
  public form: UntypedFormGroup;

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      withPatientData: this.formBuilder.control(null, [Validators.required]),
    });
  }

  downloadSurveyData() {
    if (!this.form.controls['withPatientData'].value) {
      this.snackBar.open(
        'Please select if you want to include patient data!',
        null,
        { duration: 3000 }
      );
      this.dialogRef.close(false);
    }
    this.dialogRef.close(this.form.controls['withPatientData'].value);
  }
}
