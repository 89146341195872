<div class="text-center">
  <div [hidden]="activeCamera">
    <button mat-mini-fab color="none" (click)="activateCamera()">
      <mat-icon class="notranslate">add_a_photo</mat-icon>
    </button>
    <div class="divider"></div>
  </div>

  <div [hidden]="!activeCamera">
    <div [hidden]="testPhoto">
      <video
        #video
        width="{{ videoWidth }}"
        height="{{ videoHeight }}"
        autoplay
      ></video>
      <div class="divider"></div>
      <div
        class="row d-flex flex-row justify-content-center align-items-center"
      >
        <ngx-file-drop
          (onFileDrop)="onAdded($event)"
          multiple="false"
          accept=".png,.jpg,.jpeg"
          dropZoneClassName="ngxFileDropZone"
          contentClassName="ngxFileDropContent"
        >
          <ng-template
            ngx-file-drop-content-tmp
            let-openFileSelector="openFileSelector"
          >
            <span>Upload Image</span>
            <span class="px-3"><!-- divider --></span>
            <button
              mat-raised-button
              color="primary"
              type="button"
              (click)="openFileSelector()"
            >
              Browse
            </button>
          </ng-template>
        </ngx-file-drop>
        <span class="space"></span><span class="space"></span>
        <span class="space"></span><span class="space"></span>
        <button
          mat-raised-button
          color="primary"
          [disabled]="permissionDenied"
          (click)="takePhoto()"
        >
          Take Photo
        </button>
      </div>
    </div>

    <div *ngIf="testPhoto">
      <img [src]="testPhoto" style="max-width:100%;" />
      <div class="divider"></div>
      <div
        class="row d-flex flex-row justify-content-center align-items-center"
      >
        <ngx-file-drop
          (onFileDrop)="onAdded($event)"
          multiple="false"
          accept=".png,.jpg,.jpeg"
          dropZoneClassName="ngxFileDropZone"
          contentClassName="ngxFileDropContent"
        >
          <ng-template
            ngx-file-drop-content-tmp
            let-openFileSelector="openFileSelector"
          >
            <span>Upload Image</span>
            <span class="px-3"><!-- divider --></span>
            <button
              mat-raised-button
              color="primary"
              type="button"
              (click)="openFileSelector()"
            >
              Browse
            </button>
          </ng-template>
        </ngx-file-drop>
        <span class="space"></span><span class="space"></span>
        <span class="space"></span><span class="space"></span>
        <button
          mat-raised-button
          color="primary"
          [disabled]="permissionDenied"
          (click)="takePhoto()"
        >
          Take Photo
        </button>
      </div>
    </div>
    <canvas
      [hidden]="true"
      #canvas
      width="{{ videoWidth }}"
      height="{{ videoHeight }}"
    ></canvas>
  </div>
</div>

<p *ngIf="permissionDenied">
  <br /><br />You have blocked Insig from accessing your camera
</p>
