import { NgModule, LOCALE_ID } from '@angular/core';
import { MaterialComponentsModule } from '../material-components.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { InsigNavigationModule } from '../global/navigation/insig-navigation.module';
import { SharedModule } from './shared/shared.module';
import { ClipboardModule } from 'ngx-clipboard';
import { GlobalVirtualSettingsModule } from '../global/virtual/settings/global-virtual-settings.module';
import { AvailabilityCalendarModule } from '../global/availability-calendar/availability-calendar.module';

import { MainDashboardComponent } from './components/main/main-dashboard.component';
import { CompanyPlansComponent } from './components/company-plans/company-plans.component';
import { UserGroupsComponent } from './components/user-groups/user-groups.component';
import { CompanyPlanListHeaderComponent } from './components/company-plan-list-header/company-plan-list-header.component';
import { CompanyPlanSettingsComponent } from './components/company-plan-settings/company-plan-settings.component';
import { AdminSchedulerComponent } from './components/admin-scheduler/admin-scheduler.component';
import { AdminSchedulerDailySettingsComponent } from './components/admin-scheduler-daily-settings/admin-scheduler-daily-settings.component';
import { PatientAdminHistoryComponent } from './components/patient-admin-history/patient-admin-history.component';
import { SurveyDataDashboardComponent } from './components/survey-data/survey-data-dashboard.component';
// dialogs
import { CreateCompanyPlanDialogComponent } from './dialogs/create-company-plan/create-company-plan.component';
import { CreateUserGroupDialogComponent } from './dialogs/create-user-group/create-user-group.component';
import { AddUsersToGroupDialogComponent } from './dialogs/add-users-to-group/add-users-to-group.component';
import { ConfirmUserGroupSubscribedDeleteDialogComponent } from './dialogs/confirm-user-group-subscribed-delete/confirm-user-group-subscribed-delete.component';
import { ConfirmUserGroupDeleteDialogComponent } from './dialogs/confirm-user-group-delete/confirm-user-group-delete.component';
import { ConfirmCompanyPlanServiceDeleteDialogComponent } from './dialogs/confirm-company-plan-service-delete/confirm-company-plan-service-delete.component';
import { AddCompaniesToPlanDialogComponent } from './dialogs/add-companies-to-plan/add-companies-to-plan.component';
import { AddUserGroupsToPlanDialogComponent } from './dialogs/add-user-groups-to-plan/add-user-groups-to-plan.component';
import { ConfirmCompanyPlanDeleteDialogComponent } from './dialogs/confirm-company-plan-delete/confirm-company-plan-delete.component';
import { SelectNoteDialogComponent } from 'insig-app/global/dialogs/select-note-dialog/select-note-dialog.component';
import { ViewNoteDialogComponent } from 'insig-app/global/dialogs/view-note-dialog/view-note-dialog.component';
import { DownloadSurveyDataDialogComponent } from './dialogs/download-survey-data/download-survey-data-dialog.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { SchedulerModule } from '../global/admin/scheduler/scheduler.module';
// customizer
import { ThemeCustomizerComponent } from './components/theme-customizer/theme-customizer.component';

import { PipesModule } from '../pipes/pipes.module';
import { InsigColorPickerModule } from 'insig-app/global/insig-color-picker/insig-color-picker.module';
import { InsigAppLoaderModule } from '../global/loader/loader.module';
import { PdfEmbedModule } from 'insig-app/global/pdf-embed/pdf-embed.module';

@NgModule({
  imports: [
    MaterialComponentsModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DashboardRoutingModule,
    InsigNavigationModule,
    SharedModule,
    GlobalVirtualSettingsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    SchedulerModule.forRoot({ locale: 'en', headerDateFormat: 'weekNumber' }),
    AvailabilityCalendarModule,
    PipesModule,
    InsigColorPickerModule,
    InsigAppLoaderModule,
    ClipboardModule,
    PdfEmbedModule,
  ],
  exports: [],
  providers: [{ provide: LOCALE_ID, useValue: 'en-US' }],
  declarations: [
    DashboardComponent,
    MainDashboardComponent,
    CompanyPlansComponent,
    UserGroupsComponent,
    CompanyPlanListHeaderComponent,
    CompanyPlanSettingsComponent,
    CreateCompanyPlanDialogComponent,
    CreateUserGroupDialogComponent,
    AddUsersToGroupDialogComponent,
    ConfirmUserGroupSubscribedDeleteDialogComponent,
    ConfirmUserGroupDeleteDialogComponent,
    ConfirmCompanyPlanServiceDeleteDialogComponent,
    AddCompaniesToPlanDialogComponent,
    AddUserGroupsToPlanDialogComponent,
    ConfirmCompanyPlanDeleteDialogComponent,
    AdminSchedulerComponent,
    AdminSchedulerDailySettingsComponent,
    PatientAdminHistoryComponent,
    ThemeCustomizerComponent,
    SelectNoteDialogComponent,
    ViewNoteDialogComponent,
    SurveyDataDashboardComponent,
    DownloadSurveyDataDialogComponent,
  ]
})
export class DashboardModule { }
