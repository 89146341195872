// @ts-strict-ignore
import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'autocomplete-edit',
  templateUrl: './autocomplete-edit.component.html',
})
export class AutocompleteEditComponent implements OnInit {
  @Input() question: any;
  @Output() questionChange = new EventEmitter<any>();

  get allowMultiple(): boolean {
    return !!this.question.allowMultiple;
  }
  set allowMultiple(value: boolean) {
    if (this.question.allowMultiple !== value) {
      this.question.allowMultiple = value;
      this.questionChange.emit(this.question);
    }
  }

  @ViewChild('itemsSelect') itemsSelect;
  private options = null;
  itemsCtrl: UntypedFormControl;
  filteredItems: any;
  private items = [];
  private previewQuestion = {
    response: {
      selectedAnswers: [],
    },
  };

  constructor() {
    this.itemsCtrl = new UntypedFormControl();
    this.filteredItems = this.itemsCtrl.valueChanges.pipe(
      startWith(null),
      map((name) => this.filterItems(name))
    );
  }

  ngOnInit() {
    if (this.question.arrayOptions) {
      this.options = this.question.arrayOptions.join();
      this.items = this.question.arrayOptions;
    }
    this.previewQuestion.response.selectedAnswers = [];
  }

  setOptions() {
    if (this.options) {
      this.items = this.question.arrayOptions = this.options.split(',');
      this.question.optionsSetup = true;
    }
  }

  modifyOptions() {
    this.question.optionsSetup = false;
  }

  filterItems(val: string) {
    return val
      ? this.items
          .filter(
            (s) =>
              s[0].toLowerCase().indexOf(val.toLowerCase()) === 0 ||
              s[1].toLowerCase().indexOf(val.toLowerCase()) === 0
          )
          .slice(0, 50)
      : this.items.slice(0, 50);
  }

  removeItem(val) {
    const index = this.previewQuestion.response.selectedAnswers.indexOf(val);
    this.previewQuestion.response.selectedAnswers.splice(index, 1);
  }

  addItem(val) {
    if (this.allowMultiple) {
      // if its from the list its an array of first and last names else its a string
      if (val.constructor === Array) {
        val = val[0] + ' ' + val[1];
      }
      if (this.previewQuestion.response.selectedAnswers.indexOf(val) === -1) {
        this.previewQuestion.response.selectedAnswers.push(val);
      }
      this.itemsCtrl.setValue(null);
    }
    // this.itemsSelect.nativeElement.blur();
  } // end add item
} // end component
