<div #container>
  <iframe
    *ngIf="safeURL"
    [src]="safeURL"
    width="{{ width }}"
    height="{{ height }}"
    frameborder="0"
    allowfullscreen
  ></iframe>
</div>

<p *ngIf="!question.url">
  *Error, no video link provided! Please ignore this question.
</p>
