<insig-sidenav-menu
  [menuItems]="menuItems"
  (menuItemSelected)="menuItemSelected($event)"
  [selectedGroup]="selectedGroup"
  [selectedCategoryItem]="selectedCategoryItem"
  [containerSpacing]="false"
>
  <div *ngIf="!!loading" class="full-width full-height">
    <insig-app-loader class="mx-auto my-auto"></insig-app-loader>
  </div>
  <div
    *ngIf="!!selectedCategory && !!surveyDataInstances && !loading"
    class="full-height full-width d-flex flex-column && !loading"
  >
    <div class="flex-shrink-1 margin-2">
      <button mat-raised-button (click)="exportSurveyData()">
        <mat-icon class="notranslate">cloud_download</mat-icon>&nbsp;Export
        Survey Data
      </button>
    </div>

    <div
      *ngIf="!!selectedCategory && !!surveyDataInstances && !loading"
      class="insig-container mat-elevation-z8 d-flex flex flex-column"
      #tableContainer
    >
      <div class="flex-grow-1 d-flex flex flex-column">
        <table
          mat-table
          [dataSource]="dataSource"
          multiTemplateDataRows
          class="insig-table"
          #scrollTable
          (scroll)="onScrollTable($event)"
        >
          <ng-container
            matColumnDef="{{ question }}"
            *ngFor="let question of surveyDataInstances.questionsArray"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="min-box-width mat-header-row "
            >
              {{ surveyDataInstances.questionsObj[question].name }}
            </th>
            <td mat-cell *matCellDef="let element" class="min-box-width">
              {{ element.responses[question] }}
            </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <div matColumnDef="expandedDetail">
            <td
              mat-cell
              *matCellDef="let element"
              sticky
              [attr.colspan]="surveyDataInstances.questionsArray.length"
            >
              <!-- [attr.colspan]="math.round(tableContainer.offsetWidth/200)" -->
              <div
                class="insig-element-detail"
                [ngStyle]="{
                  'width.px': tableContainer.offsetWidth,
                  'left.px':
                    leftScrollTable + tableContainer.offsetWidth <=
                    surveyDataInstances.questionsArray.length * 200 - 10
                      ? leftScrollTable
                      : surveyDataInstances.questionsArray.length * 200 -
                        tableContainer.offsetWidth -
                        10
                }"
                [@detailExpand]="
                  element == expandedElement ? 'expanded' : 'collapsed'
                "
              >
                <div class="row flex">
                  <div class="col-3">
                    <mat-icon class="notranslate">info</mat-icon>
                    <span>
                      {{ tableContainer.offsetWidth }}
                      &nbsp;{{ element?.metadata?.surveyName }}
                    </span>
                  </div>
                  <div class="col-3">
                    <mat-icon class="notranslate">phone</mat-icon>
                    &nbsp;<a class="d-inline initialColor p-0">
                      {{ element?.metadata?.patientData?.phone | phone }}
                    </a>
                  </div>
                  <div class="col-2">
                    <mat-icon class="notranslate">cake</mat-icon>
                    <span>
                      &nbsp;{{ element?.metadata?.patientData?.day }}/{{
                        element?.metadata?.patientData?.month
                      }}/{{ element?.metadata?.patientData?.year }}
                    </span>
                  </div>
                  <div class="col-4">
                    <mat-icon class="notranslate">home</mat-icon>
                    <span>
                      &nbsp;{{ element?.metadata?.patientData?.address
                      }}{{
                        element?.metadata?.patientData?.city
                          ? ', ' + element?.metadata?.patientData?.city
                          : ''
                      }}
                      <span *ngIf="element?.metadata?.patientData?.province"
                        >, {{ element?.metadata?.patientData?.province }}</span
                      >
                    </span>
                  </div>

                  <div class="col-4 mb-4 text-center h-100 ">
                    <div class="my-auto">
                      <button
                        mat-raised-button
                        (click)="openNotesPatient(element)"
                        color="primary"
                        class="mt-3"
                      >
                        View Notes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </div>

          <tr
            mat-header-row
            *matHeaderRowDef="surveyDataInstances.questionsArray; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="
              let element;
              columns: surveyDataInstances.questionsArray
            "
            class="insig-element-row"
            [class.insig-expanded-row]="expandedElement === element"
            (click)="
              expandedElement = expandedElement === element ? null : element
            "
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            class="insig-detail-row"
          ></tr>
        </table>
      </div>

      <mat-paginator
        class="flex-shrink-1"
        [pageSize]="15"
        [pageSizeOptions]="[15, 30, 60]"
        showFirstLastButtons
        (page)="paginatorChange($event)"
      ></mat-paginator>
    </div>

    <main
      *ngIf="!selectedCategory && !loading"
      class="ml-auto mr-auto full-height"
    >
      <div>
        <div>
          <h5>You have no survey selected!</h5>
          <h5>Please select a survey to view data</h5>
        </div>
        <svg viewBox="0 0 200 600">
          <polygon
            points="118.302698 8 59.5369448 66.7657528 186.487016 193.715824 14 366.202839 153.491505 505.694344 68.1413353 591.044514 200 591.044514 200 8"
          ></polygon>
        </svg>
      </div>
      <svg class="crack" viewBox="0 0 200 600">
        <polyline
          points="118.302698 8 59.5369448 66.7657528 186.487016 193.715824 14 366.202839 153.491505 505.694344 68.1413353 591.044514"
        ></polyline>
      </svg>
      <div>
        <svg viewBox="0 0 200 600">
          <polygon
            points="118.302698 8 59.5369448 66.7657528 186.487016 193.715824 14 366.202839 153.491505 505.694344 68.1413353 591.044514 0 591.044514 0 8"
          ></polygon>
        </svg>
        <div></div>
      </div>
    </main>
  </div>
</insig-sidenav-menu>
