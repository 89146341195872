// @ts-strict-ignore
import { Injectable, inject } from '@angular/core';
import {
  OldBookingFlowService as OldBookingFlowApiService,
  PublicAggregateDoctor as ApiPublicAggregateDoctor,
  PublicAggregateService as ApiPublicAggregateService,
  PublicClinicNetwork as ApiPublicClinicNetwork,
  PublicClinicNetworkSubscribed as ApiPublicClinicNetworkSubscription,
} from '@insig-health/api/old-booking-flow-api';
import { Province } from '@insig-health/services/province/province.service';
import { firstValueFrom } from 'rxjs';

export interface PublicAggregateDoctor {
  uid: string;
  title: string;
  first: string;
  last: string;
  company: string;
  image: string;
  publicBilling: boolean;
  overrideTime: boolean;
  province: string;
  otherProvinces: ProvinceToggles;
  blockedProvinces: ProvinceToggles;
  city: string;
  languages: string;
  specialty: string;
  qualifications: string;
  bookingRestrictions?: {
    enabled: boolean;
    ageMin?: number;
    ageMax?: number;
    ageType?: number;
    gender?: number;
    message?: string;
  },
  referralRequired: boolean;
  defaultVirtualSurvey?: {
    userID?: string;
    surveyID?: string;
  },
}

export interface PublicAggregateService {
  apptID: string;
  doctorIDs: string[];
  specialties: string[];
  video: boolean;
  phone: boolean;
  inPerson: boolean;
  messaging: boolean;
  price: string;
  duration: number;
  buffer: number;
  label: string;
  category: string[];
  freeInClinic: boolean;
  survey: {
    userID: string;
    surveyID: string;
  };
  serviceCode: string;
  diagnosticCode: string;
  overridePublicBilling: boolean;
  overridePaidAppointment: boolean;
  changes: {
    [doctorId: string]: {
      survey: {
        userID: string;
        surveyID: string;
      };
      overridePaidAppointment: boolean;
      overridePublicBilling: boolean;
      video: boolean;
      phone: boolean;
      inPerson: boolean;
      messaging: boolean;
      price: string;
      duration: number;
      buffer: number;
    }
  }
}

type ProvinceToggles = { [key in keyof typeof Province]: boolean };

export interface PublicClinicNetwork {
  planId: string;
}

export interface PublicClinicNetworkSubscription {
  companyId: string;
  networkId: string;
}

@Injectable({
  providedIn: 'root',
})
export class OldBookingFlowService {
  private readonly oldBookingFlowApiService = inject(OldBookingFlowApiService);

  public async getDoctorsByDoctorCompanyId(doctorCompanyId: string): Promise<PublicAggregateDoctor[]> {
    return firstValueFrom(this.oldBookingFlowApiService.getDoctorsByDoctorCompanyId(doctorCompanyId))
      .then((apiDoctors) => apiDoctors.map(this.getPublicAggregateDoctorFromApi.bind(this)));
  }

  public async getDoctorsByNetworkCompanyId(networkCompanyId: string): Promise<PublicAggregateDoctor[]> {
    return firstValueFrom(this.oldBookingFlowApiService.getDoctorsByNetworkCompanyId(networkCompanyId))
      .then((apiDoctors) => apiDoctors.map(this.getPublicAggregateDoctorFromApi.bind(this)));
  }

  public async getDoctorsByPlanId(planId: string): Promise<PublicAggregateDoctor[]> {
    return firstValueFrom(this.oldBookingFlowApiService.getDoctorsByPlanId(planId))
      .then((apiDoctors) => apiDoctors.map(this.getPublicAggregateDoctorFromApi.bind(this)));
  }

  public async getPublicClinicNetworkById(networkId: string): Promise<PublicClinicNetwork> {
    return firstValueFrom(this.oldBookingFlowApiService.getPublicClinicNetworkById(networkId))
      .then(this.getPublicClinicNetworkFromApi.bind(this));
  }

  public async getPublicClinicNetworkSubscriptionById(networkSubscriptionId: string): Promise<PublicClinicNetworkSubscription> {
    return firstValueFrom(this.oldBookingFlowApiService.getPublicClinicNetworkSubscriptionById(networkSubscriptionId))
      .then(this.getPublicClinicNetworkSubscriptionFromApi.bind(this));
  }

  public async getServicesByCompanyId(companyId: string): Promise<PublicAggregateService[]> {
    return firstValueFrom(this.oldBookingFlowApiService.getServicesByDoctorCompanyId(companyId))
      .then((apiServices) => apiServices.map(this.getPublicAggregateServiceFromApi.bind(this)));
  }

  public async getServicesByPlanId(planId: string): Promise<PublicAggregateService[]> {
    return firstValueFrom(this.oldBookingFlowApiService.getServicesByPlanId(planId))
      .then((apiServices) => apiServices.map(this.getPublicAggregateServiceFromApi.bind(this)));
  }

  private getPublicAggregateDoctorFromApi(apiDoctor: ApiPublicAggregateDoctor): PublicAggregateDoctor {
    return {
      uid: apiDoctor.uid,
      title: apiDoctor.title,
      first: apiDoctor.first,
      last: apiDoctor.last,
      company: apiDoctor.company,
      image: apiDoctor.image,
      publicBilling: apiDoctor.publicBilling,
      overrideTime: apiDoctor.overrideTime,
      province: apiDoctor.province,
      otherProvinces: apiDoctor.otherProvinces as ProvinceToggles,
      blockedProvinces: apiDoctor.blockedProvinces as ProvinceToggles,
      city: apiDoctor.city,
      languages: apiDoctor.languages,
      specialty: apiDoctor.specialty,
      qualifications: apiDoctor.qualifications,
      bookingRestrictions: {
        ...apiDoctor.bookingRestrictions,
        enabled: apiDoctor.bookingRestrictions?.enabled ?? false,

      },
      referralRequired: apiDoctor.referralRequired,
      defaultVirtualSurvey: apiDoctor.defaultVirtualSurvey,
    };
  }

  private getPublicAggregateServiceFromApi(apiService: ApiPublicAggregateService): PublicAggregateService {
    const service = {
      apptID: apiService.apptID,
      doctorIDs: apiService.doctorIDs,
      specialties: apiService.specialties,
      video: apiService.video,
      phone: apiService.phone,
      inPerson: apiService.inPerson,
      messaging: apiService.messaging,
      price: apiService.price,
      duration: apiService.duration,
      buffer: apiService.buffer,
      label: apiService.label,
      category: apiService.category,
      freeInClinic: apiService.freeInClinic,
      survey: apiService.survey === null ? null : {
        userID: apiService.survey.userID,
        surveyID: apiService.survey.surveyID,
      },
      serviceCode: apiService.serviceCode,
      diagnosticCode: apiService.diagnosticCode,
      overridePublicBilling: apiService.overridePublicBilling,
      overridePaidAppointment: apiService.overridePaidAppointment,
      changes: apiService.changes === null ? null : Object.entries(apiService.changes).reduce((acc, [doctorId, change]) => {
        acc[doctorId] = {
          survey: change.survey === null ? null : {
            userID: change.survey.userID,
            surveyID: change.survey.surveyID,
          },
          overridePaidAppointment: change.overridePaidAppointment,
          overridePublicBilling: change.overridePublicBilling,
          video: change.video,
          phone: change.phone,
          inPerson: change.inPerson,
          messaging: change.messaging,
          price: change.price,
          duration: change.duration,
          buffer: change.buffer,
        };
        return acc;
      }, {}),
    };
    return this.removeNestedNulls(service);
  }

  private removeNestedNulls<T>(object: T): T {
    if (typeof object === 'object') {
      Object.entries(object).forEach(([key, value]) => {
        if (value === null) {
          delete object[key];
        } else if (typeof value === 'object') {
          this.removeNestedNulls(value);
        }
      });
    }
    return object; 
  }

  private getPublicClinicNetworkFromApi(apiClinicNetwork: ApiPublicClinicNetwork): PublicClinicNetwork {
    return {
      planId: apiClinicNetwork.planId,
    };
  }

  private getPublicClinicNetworkSubscriptionFromApi(apiClinicNetworkSubscription: ApiPublicClinicNetworkSubscription): PublicClinicNetworkSubscription {
    return {
      companyId: apiClinicNetworkSubscription.companyId,
      networkId: apiClinicNetworkSubscription.networkId,
    };
  }
}
