import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialComponentsModule } from 'insig-app/material-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { InsigReferralSearchComponent } from './insig-referral-search/insig-referral-search.component';
import { InsigReferralMapComponent } from './insig-referral-map/insig-referral-map.component';
import { InsigReferralClinicComponent } from './insig-referral-clinic/insig-referral-clinic.component';
import { InsigReferralPatientComponent } from './insig-referral-patient/insig-referral-patient.component';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialComponentsModule,
    GoogleMapsModule,
  ],
  exports: [
    InsigReferralClinicComponent,
    InsigReferralSearchComponent,
    InsigReferralMapComponent,
    InsigReferralPatientComponent,
  ],
  declarations: [
    InsigReferralSearchComponent,
    InsigReferralMapComponent,
    InsigReferralClinicComponent,
    InsigReferralPatientComponent,
  ]
})
export class InsigReferralModule { }
