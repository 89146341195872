import { Injectable } from '@angular/core';

export const darkTheme = {
  'primary-color': '#009fdf',
  'secondary-color': 'var(--secondary-color)',
  'text-color': '#000105',
  'text-selected': '#fff',
  'text-secondary': 'a1a1a1',
};

export const lightTheme = {
  'primary-color': '#009fdf',
  'secondary-color': '#FFFFFF',
  'text-color': '#000105',
  'text-selected': '#fff',
  'text-secondary': 'a1a1a1',
};

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  toggleDark(): void {
    this.setTheme(darkTheme);
  }

  toggleLight(): void {
    this.setTheme(lightTheme);
  }

  setTheme(theme: Record<string, string>): void {
    Object.keys(theme).forEach((k) =>
      document.documentElement.style.setProperty(`--${k}`, theme[k]),
    );
  }
}
