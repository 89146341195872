<div class="page-maintenance">
  <header class="top-header text-center">
    <a [routerLink]="['/']" class="logo">{{ AppConfig.brand }}</a>
  </header>

  <div class="content">
    <div class="main-content text-center">
      <h1>Site is Under Maintenance</h1>
      <p class="text-muted">It will take about 10-15 minutes</p>
    </div>

    <div class="row">
      <div class="col-xl-4">
        <div class="icon-box ibox-center ibox-lg ibox-light ibox-plain">
          <div class="ibox-icon">
            <mat-icon class="notranslate">info_outline</mat-icon>
          </div>
          <h3>Why is the Site Down?</h3>
          <p>
            The site is under maintenance because we are integrating new
            features to it.
          </p>
        </div>
      </div>
      <div class="col-xl-4">
        <div class="icon-box ibox-center ibox-lg ibox-light ibox-plain">
          <div class="ibox-icon">
            <mat-icon class="notranslate">access_time</mat-icon>
          </div>
          <h3>What is the Downtime?</h3>
          <p>
            Normally, it takes about 10-15 minutes, however sometimes it may
            need a few hours or more.
          </p>
        </div>
      </div>
      <div class="col-xl-4">
        <div class="icon-box ibox-center ibox-lg ibox-light ibox-plain">
          <div class="ibox-icon">
            <mat-icon class="notranslate">mail_outline</mat-icon>
          </div>
          <h3>Do you need Support?</h3>
          <p>
            You may send us an Email at
            <a href="mailto:mail@site.com">mail@site.com</a> if you need urgent
            support.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
