<div class="form-group row">
  <div class="col-2"></div>
  <div class="col-8">
    <mat-form-field (click)="fromDate.open()">
      <mat-label>From date</mat-label>
      <input
        matInput
        readonly
        [max]="question.response.answer.to"
        autocomplete="off"
        [(ngModel)]="question.response.answer.from"
        [matDatepicker]="fromDate"
      />
      <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker
      #fromDate
      startView="year"
      [touchUi]="false"
    ></mat-datepicker>
    <span class="space space-md"></span>
    <mat-form-field (click)="toDate.open()">
      <mat-label>To date</mat-label>
      <input
        matInput
        readonly
        autocomplete="off"
        [min]="question.response.answer.from"
        [(ngModel)]="question.response.answer.to"
        [matDatepicker]="toDate"
      />
      <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #toDate startView="year" [touchUi]="false"></mat-datepicker>
  </div>
  <div class="col-2"></div>
</div>
