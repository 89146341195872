<br />
<div class="row">
  <div class="col-6">
    <mat-form-field>
      <mat-label>Cell Input Type</mat-label>
      <mat-select
        [(ngModel)]="question.grid.cellInputType"
      >
        <mat-option [value]="'radio'">Radio</mat-option>
        <mat-option [value]="'checkbox'">Checkbox</mat-option>
        <mat-option [value]="'text'">Text</mat-option>
        <mat-option [value]="'date'">Date</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-6">
    <mat-form-field>
      <mat-label>Grid Type</mat-label>
      <mat-select
        [(ngModel)]="question.gridType"
        (change)="changeGridType($event.value)"
      >
        <mat-option [value]="'Manual'">Manual</mat-option>
        <mat-option [value]="'Yes / No'">Yes / No</mat-option>
        <mat-option [value]="'Normal / Abnormal'">Normal / Abnormal</mat-option>
        <mat-option [value]="'Vaccination'">Vaccination</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="grid-container">
  <div class="grid-wrapper">
    <mat-list>
      <!-- begin header row -->
      <mat-list-item [style.width]="dynamicTableWidth">
        <mat-grid-list
          [cols]="question.grid.cols.length + 2"
          rowHeight="60px"
          class="full-width"
        >
          <mat-grid-tile [colspan]="1">
            <!-- spacer -->
          </mat-grid-tile>
          <mat-grid-tile
            [colspan]="1"
            *ngFor="let col of question.grid.cols; let colIndex = index"
          >
            <mat-form-field class="full-width">
              <input
                id="grid-column-label"
                matInput
                type="text"
                [(ngModel)]="question.grid.cols[colIndex].label"
                [style.text-overflow]="'ellipsis'"
              />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              id="grid-add-column-button"
              mat-button
              matTooltip="Add Column"
              matTooltipPosition="right"
              (click)="addColumn()"
            >
              <mat-icon class="material">add_circle</mat-icon>
              <mat-icon class="material">view_column</mat-icon>
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-list-item>
      <!-- end header row -->
      <!-- begin body rows -->
      <mat-list-item
        *ngFor="let row of question.grid.rows; let rowIndex = index"
        [style.width]="dynamicTableWidth"
      >
        <mat-grid-list
          [cols]="question.grid.cols.length + 2"
          rowHeight="60px"
          class="full-width"
        >
          <mat-grid-tile [colspan]="1">
            <mat-form-field
              class="full-width"
              [hidden]="question.allowMultiple"
            >
              <input
                matInput
                type="text"
                [(ngModel)]="question.grid.rows[rowIndex].label"
                [style.text-overflow]="'ellipsis'"
              />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile
            [colspan]="1"
            *ngFor="let col of question.grid.cols; let colIndex = index"
          >
            <mat-radio-button
              *ngIf="
                col.colInputType
                  ? col.colInputType === 'radio'
                  : question.grid.cellInputType === 'radio'
              "
              radioGroup="'rowRadioGroup'+colIndex"
              [name]="'radioGroupRow' + rowIndex"
            >
            </mat-radio-button>
            <mat-checkbox
              *ngIf="
                col.colInputType
                  ? col.colInputType === 'checkbox'
                  : question.grid.cellInputType === 'checkbox'
              "
            ></mat-checkbox>
            <mat-form-field
              class="full-width"
              *ngIf="
                col.colInputType
                  ? col.colInputType === 'text'
                  : question.grid.cellInputType === 'text'
              "
            >
              <input matInput type="text" [style.text-overflow]="'ellipsis'" />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-mini-fab
              color="none"
              matTooltip="Delete Row"
              matTooltipPosition="right"
              (click)="removeRow(rowIndex)"
            >
              <mat-icon class="notranslate">clear</mat-icon>
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-list-item>
      <!-- end body rows -->
      <!-- begin footer row -->
      <mat-list-item [style.width]="dynamicTableWidth">
        <mat-grid-list
          [cols]="question.grid.cols.length + 2"
          rowHeight="60px"
          class="full-width"
        >
          <mat-grid-tile [colspan]="1">
            <button
              id="grid-add-row-button"
              mat-button
              matTooltip="Add Row"
              matTooltipPosition="below"
              (click)="addRow()"
            >
              <mat-icon class="notranslate">add_circle</mat-icon>
              <mat-icon class="notranslate">toc</mat-icon>
            </button>
          </mat-grid-tile>
          <mat-grid-tile
            [colspan]="1"
            *ngFor="let col of question.grid.cols; let colIndex = index"
          >
            <button
              id="grid-more-column-options-button"
              mat-mini-fab
              color="none"
              matTooltip="More Column Options"
              matTooltipPosition="above"
              [insMenuTriggerFor]="colMenu"
            >
              <mat-icon class="notranslate">more_vert</mat-icon>
            </button>
            <ins-menu #colMenu="insMenu">
              <button
                id="grid-change-column-type-button"
                mat-menu-item
                [insMenuTriggerFor]="colTypeMenu"
              >
                <mat-icon>loop</mat-icon>
                <span>Change Column Type</span>
              </button>
              <button
                id="grid-move-column-right-button"
                *ngIf="question.grid.cols[colIndex + 1]"
                mat-menu-item
                (click)="moveColumnRight(colIndex)"
              >
                <mat-icon>keyboard_arrow_right</mat-icon>
                <span>Move Column Right</span>
              </button>
              <button
                id="grid-move-column-left-button"
                *ngIf="question.grid.cols[colIndex - 1]"
                mat-menu-item
                (click)="moveColumnLeft(colIndex)"
              >
                <mat-icon>keyboard_arrow_left</mat-icon>
                <span>Move Column Left</span>
              </button>
              <button
                id="grid-remove-column-button"
                mat-menu-item
                (click)="removeColumn(colIndex)"
              >
                <mat-icon>clear</mat-icon>
                <span>Delete Column</span>
              </button>
            </ins-menu>
            <ins-menu #colTypeMenu="insMenu">
              <button
                id="grid-change-column-type-radio-button"
                mat-menu-item
                (click)="question.grid.cols[colIndex].colInputType = 'radio'"
              >
                Radio
              </button>
              <button
                id="grid-change-column-type-checkbox-button"
                mat-menu-item
                (click)="question.grid.cols[colIndex].colInputType = 'checkbox'"
              >
                Checkbox
              </button>
              <button
                id="grid-change-column-type-text-button"
                mat-menu-item
                (click)="question.grid.cols[colIndex].colInputType = 'text'"
              >
                Text
              </button>
              <button
                id="grid-change-column-type-date-button"
                mat-menu-item
                (click)="question.grid.cols[colIndex].colInputType = 'date'"
              >
                Date
              </button>
            </ins-menu>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <!-- spacer -->
          </mat-grid-tile>
        </mat-grid-list>
      </mat-list-item>
      <!-- end footer row -->
    </mat-list>
  </div>
</div>

<div class="divider"></div>

<mat-checkbox [(ngModel)]="question.allowMultiple"
  >Allow user to add rows</mat-checkbox
>
