// @ts-strict-ignore
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'daterange-question',
  templateUrl: './daterange.component.html',
})
export class DateRangeComponent implements OnInit, OnChanges {
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();

  ngOnInit() {
    if (!this.question.response.answer) {
      this.question.response.answer = {};
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }
}
