// @ts-strict-ignore
﻿import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  HostBinding,
  ChangeDetectorRef, inject } from '@angular/core';
import {
  SchedulerViewDay,
  SchedulerViewHour,
  SchedulerViewHourSegment,
  CalendarSchedulerEvent,
} from './calendar-scheduler-view.component';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';

@Component({
  // [class.no-border]': '!day.hasBorder
  selector: 'calendar-scheduler-cell',
  template: `
    <ng-template #defaultTemplate>
      <div
        class="cal-scheduler-segments"
        *ngIf="hour.segments.length > 0"
        [ngClass]="hour?.cssClass"
        [class.no-border]="!hour.hasBorder"
      >
        <div
          class="cal-scheduler-segment"
          *ngFor="let segment of hour.segments; let si = index"
          [title]="title"
          [ngClass]="segment?.cssClass"
          [class.has-events]="segment.events.length > 0"
          [class.cal-disabled]="segment.isDisabled"
          [style.backgroundColor]="segment.backgroundColor"
          [class.no-border]="!segment.hasBorder"
          (mwlClick)="onSegmentClick($event, segment)"
        >
          <div class="cal-scheduler-events" *ngIf="segment.events.length > 0">
            <calendar-scheduler-event
              *ngFor="let event of segment.events"
              [day]="day"
              [hour]="hour"
              [segment]="segment"
              [event]="event"
              (mouseenter)="onMouseEnter($event, segment, event)"
              (mouseleave)="onMouseLeave($event, segment, event)"
              [tooltipPlacement]="tooltipPlacement"
              [showActions]="showActions"
              [customTemplate]="eventTemplate"
              (eventClicked)="onEventClick($event, event)"
            >
            </calendar-scheduler-event>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template
      [ngTemplateOutlet]="customTemplate || defaultTemplate"
      [ngTemplateOutletContext]="{
        day: day,
        hour: hour,
        locale: locale,
        tooltipPlacement: tooltipPlacement,
        showActions: showActions,
        eventTemplate: eventTemplate,
        highlightSegment: highlightSegment,
        unhighlightSegment: unhighlightSegment,
        segmentClicked: segmentClicked,
        eventClicked: eventClicked
      }"
    >
    </ng-template>
  `,
})
export class CalendarSchedulerCellComponent implements OnInit {
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly dateAndTimeService = inject(DateAndTimeService);
  @HostBinding('class') class = 'cal-scheduler-cell';
  @HostBinding('class.cal-past') get isPast() {
    return this.day.isPast;
  }
  @HostBinding('class.cal-today') get isToday() {
    return this.day.isToday;
  }
  @HostBinding('class.cal-future') get isFuture() {
    return this.day.isFuture;
  }
  @HostBinding('class.cal-weekend') get isWeekend() {
    return this.day.isWeekend;
  }
  @HostBinding('class.cal-in-month') get inMonth() {
    return this.day.inMonth;
  }
  @HostBinding('class.cal-out-month') get outMonth() {
    return !this.day.inMonth;
  }
  @HostBinding('style.backgroundColor') get backgroundColor() {
    return this.day.backgroundColor;
  }

  @Input() title: string;

  @Input() day: SchedulerViewDay;

  @Input() hour: SchedulerViewHour;

  @Input() locale: string;

  @Input() tooltipPlacement: string;

  @Input() showActions = true;

  @Input() customTemplate: TemplateRef<any>;

  @Input() eventTemplate: TemplateRef<any>;

  @Output() highlightSegment: EventEmitter<any> = new EventEmitter();

  @Output() unhighlightSegment: EventEmitter<any> = new EventEmitter();

  @Output() segmentClicked: EventEmitter<{
    segment: SchedulerViewHourSegment;
  }> = new EventEmitter<{ segment: SchedulerViewHourSegment }>();

  @Output() eventClicked: EventEmitter<{
    event: CalendarSchedulerEvent;
  }> = new EventEmitter<{ event: CalendarSchedulerEvent }>();

  ngOnInit(): void {
    const timeZone = this.dateAndTimeService.getLocalTimeZone();
    const dayOfTheWeek = this.dateAndTimeService.getDayOfTheWeek(this.day.date, timeZone);
    const localizedDate = this.dateAndTimeService.getLocalizedDate(this.day.date, timeZone);

    this.title = `${dayOfTheWeek} ${localizedDate}`;

    this.changeDetectorRef.detectChanges();
  }

  onMouseEnter(
    _mouseEvent: MouseEvent,
    segment: SchedulerViewHourSegment,
    event: CalendarSchedulerEvent
  ): void {
    if (!event.isDisabled && !segment.isDisabled) {
      this.highlightSegment.emit({ event });
    }
  }

  onMouseLeave(
    _mouseEvent: MouseEvent,
    segment: SchedulerViewHourSegment,
    event: CalendarSchedulerEvent
  ): void {
    if (!event.isDisabled && !segment.isDisabled) {
      this.unhighlightSegment.emit({ event });
    }
  }

  /**
   * @hidden
   */
  onSegmentClick(
    mouseEvent: MouseEvent,
    segment: SchedulerViewHourSegment
  ): void {
    if (mouseEvent.stopPropagation) {
      mouseEvent.stopPropagation();
    }

    if (segment.events.length === 0) {
      this.segmentClicked.emit({ segment });
    }
  }

  /**
   * @hidden
   */
  onEventClick(mouseEvent: MouseEvent, event: CalendarSchedulerEvent): void {
    if (mouseEvent.stopPropagation) {
      mouseEvent.stopPropagation();
    }
    if (event.isClickable) {
      this.eventClicked.emit({ event });
    }
  }
}
