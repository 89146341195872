import { Component, OnInit } from '@angular/core';

import { APPCONFIG } from '@insig-health/config/config';

import { LayoutService } from './layout.service';

@Component({
  selector: 'my-app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [LayoutService],
})
export class LayoutComponent implements OnInit {
  public AppConfig: any;

  ngOnInit(): void {
    this.AppConfig = APPCONFIG;
  }
} // end component
