<div class="row m-0">
  <div class="col-12 col-md-6 p-0 full-width map">

    <google-map
      [center]="mapSettings"
      [options]="mapSettings"
    >
      <map-marker
        *ngIf="!!patientAddress"
        [options]="homeMarkerOptions"
      >
      </map-marker>

      <map-marker #marker="mapMarker"
        *ngFor="let referralOption of referralOptions"
        [options]="referralOption.markerOptions"
        (mapClick)="openInfoWindow(marker, referralOption)"
      >
      </map-marker>
      <map-info-window>
        <insig-referral-clinic
          (click)="referralClicked(infoWindowReferralOption)"
          [referralClinic]="infoWindowReferralOption"
          [referralBorder]="false"
          [showAddress]="true"
        >
        </insig-referral-clinic>
      </map-info-window>
    </google-map>

  </div>

  <div
    class="justify-content-center d-flex flex-column
      justify-content-between full-width col-12 col-md-6 p-0 overflow-hidden clinic-list"
  >
    <div class="d-flex">
      <h5 class="px-4 roboto-title my-3">
        <button mat-icon-button matTooltipPosition="above"
        matTooltip="Change Map Location" (click)="clearPatientAddress.emit()">
          <mat-icon class="notranslate">arrow_back</mat-icon>
        </button>
        <span class="pt-2">{{
          !!doctorMode ? 'Locations near your patient:' : 'Locations near you:'
        }}</span>
      </h5>
    </div>

    <div
      class="col-12 action-row"
      *ngIf="!!doctorMode && referralOptions.length === 0"
    >
      <div class="row m-0 p-4">
        <div
          class="col-12 p-0 fit-content"
          *ngIf="!!doctorMode && referralOptions.length === 0"
          (click)="referralClicked(referral)"
        >
          <insig-referral-clinic
            [referralClinic]="blankReferral"
            [referralBorder]="true"
            [showAddress]="true"
            [doctorMode]="doctorMode"
          ></insig-referral-clinic>
        </div>
      </div>

      <div class="row m-0 full-width" *ngIf="referralType !== 'pharmacy'">
        <div class="col-9 mx-auto">
          <div
            class="my-4 back-button primary-color-text"
            (click)="blankReferralClicked.emit()"
          >
            <div class="justify-content-center align-items-center">
              <div class="row mx-0 mb-2 mt-0">
                <span class="title mx-auto">
                  &nbsp;Create Referral
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex flex flex-grow-1 full-width overflow-y-auto"
      *ngIf="!doctorMode || referralOptions.length > 0"
    >
      <div
        class="referral-details-container row p-4
          m-0 full-width align-content-start"
      >
        <!-- if there are no referral options -->

        <div
          class="col-12 p-0 fit-content"
          *ngFor="let referral of referralOptions"
          (mouseenter)="bounceReferral(referral)"
          (mouseleave)="noBounceReferral(referral)"
          (click)="referralClicked(referral)"
        >
          <insig-referral-clinic
            [referralClinic]="referral"
            [referralBorder]="true"
            [showAddress]="false"
            [doctorMode]="doctorMode"
          ></insig-referral-clinic>
        </div>
      </div>
    </div>
  </div>
</div>
