<div class="row">
  <div class="col-sm-5 offset-sm-1">
    <b>Height:</b> inches
    <mat-slide-toggle class="mb-3" [(ngModel)]="metricHeight"
      >cm
    </mat-slide-toggle>
    <!-- <span class="bold">Height</span> -->
    <div class="row" [hidden]="metricHeight">
      <div class="col-6">
        <mat-form-field class="full-width">
          <mat-label>feet</mat-label>
          <input matInput spellcheck="false" type="number" />
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="full-width">
          <mat-label>inches</mat-label>
          <input
            matInput
            spellcheck="false"
            type="number"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row" [hidden]="!metricHeight">
      <div class="col-6 offset-3">
        <mat-form-field class="full-width">
          <mat-label>cm</mat-label>
          <input matInput spellcheck="false" type="number" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="col-sm-4 offset-sm-1 col-md-3 offset-md-2 col-12">
    <b>Weight:</b> lbs
    <mat-slide-toggle class="mb-3" [(ngModel)]="metricWeight"
      >kg</mat-slide-toggle
    >
    <!-- <span class="bold">Weight</span> -->
    <mat-form-field class="full-width">
      <mat-label>{{ metricWeight ? 'kg' : 'lbs' }}</mat-label>
      <input
        matInput
        spellcheck="false"
        type="number"
      />
    </mat-form-field>
  </div>
</div>
