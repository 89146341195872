// @ts-strict-ignore
import { Component, Input, Output, EventEmitter, OnInit, inject } from '@angular/core';
import { CkConfigService } from 'insig-app/services/ckConfig.service';
@Component({
  selector: 'send-communication-edit',
  templateUrl: './send-communication-edit.component.html',
  providers: [CkConfigService],
})
export class SendCommunicationEditComponent implements OnInit {
  private readonly ckConfigService = inject(CkConfigService);
  @Input() question: any;
  @Output() questionChange = new EventEmitter<any>();
  public ckConfig: any;
  constructor() {
    this.ckConfig = this.ckConfigService.getConfig();
    this.ckConfig.height = 400;
  }

  ngOnInit() {
    console.log(this.question);
    if (!this.question.emailSettings) {
      const firstColumnID = this.generateRandomID(32);
      const secondColumnID = this.generateRandomID(32);
      const firstRowID = this.generateRandomID(32);
      this.question.emailSettings = {
        substitutionGrid: {
          cols: [
            {
              id: firstColumnID,
              label: 'Template Key',
              orderNo: 0,
            },
            {
              id: secondColumnID,
              label: 'Value',
              orderNo: 1,
            },
          ],
          rows: [
            {
              id: firstRowID,
              label: '',
              orderNo: 0,
            },
          ],
        },
        substitutions: {},
      };
      this.question.emailSettings.substitutions[`${firstRowID}`] = {};
    }
  }

  addNewRow() {
    const row = {
      id: this.generateRandomID(32),
      orderNo: this.question.emailSettings.substitutionGrid.rows.length + 1,
      label: null,
    };
    this.question.emailSettings.substitutions[row.id] = {};
    this.question.emailSettings.substitutionGrid.rows.push(row);
  } // end add row

  generateRandomID(length): string {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
}
