// @ts-strict-ignore
const alignmentReferenceList = [
  { name: 'center', reference: '\\qc' },
  { name: 'left', reference: '\\ql' },
  { name: 'right', reference: '\\qr' },
  { name: 'justify', reference: '\\qj' },
];

export default class Alignment {
  static getRtfAlignmentReference(propertyName) {
    let alignmentReference;
    alignmentReferenceList.forEach((value) => {
      if (value.name === propertyName.trim()) {
        alignmentReference = value.reference;
      }
    });
    return alignmentReference;
  }
}
