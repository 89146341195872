// @ts-strict-ignore
import { Directive, ElementRef, Input, AfterViewInit, inject } from '@angular/core';
import 'jquery-slimscroll/jquery.slimscroll.min.js';

@Directive({ selector: '[mySlimScroll]' })
export class SlimScrollDirective implements AfterViewInit {
  el: ElementRef;
  constructor() {
        const el = inject(ElementRef);

    this.el = el;
  }

  @Input() scrollHeight: string;

  ngAfterViewInit() {
    const $el = $(this.el.nativeElement);

    ($el as any).slimScroll({
      height: this.scrollHeight || '100%',
    });
  }
}
