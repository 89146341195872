// @ts-strict-ignore
import { Injectable } from '@angular/core';

@Injectable()
export class NewSurveyService {
  createNewSurvey(): any {
    const date = new Date().toDateString();
    const newSurvey = {
      name: 'New - ' + date,
      id: this.generateRandomID(32),
      pages: [
        {
          id: this.generateRandomID(32),
          elements: [
            {
              id: this.generateRandomID(32),
              note: {
                location: 'HPI',
                qa: true,
              },
              orderNo: 1,
              type: 'question',
              question: {
                id: this.generateRandomID(32),
                text: '',
                type: 'text',
                required: false,
              },
            },
          ],
        },
      ],
    }; // end newSurvey variable
    return newSurvey;
  } // end duplicate survey function

  generateRandomID(length) {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
} // end service
