// @ts-strict-ignore
import Rtf from './rtf/rtf.class';

export function htmlToRTF(html) {
  const rtf = new Rtf();
  let finalRTF = rtf.convertHtmlToRtf(html);
  // remove undefined
  finalRTF = finalRTF.replace(new RegExp('undefined', 'g'), '');
  return finalRTF;
}
