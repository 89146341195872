// @ts-strict-ignore
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { orderBy, sortBy } from 'lodash';

@Pipe({
  name: 'sortBy',
})
@Injectable()
export class SortByPipe implements PipeTransform {
  transform(
    value: any[],
    order: boolean | '' | 'asc' | 'desc',
    column = ''
  ): any[] {
    if (!value || order === '' || !order) {
      return value;
    } // no array
    if (!column || column === '') {
      return sortBy(value);
    } // sort 1d array
    if (value.length <= 1) {
      return value;
    } // array with only one item
    return orderBy(value, [column], [order]);
  }
}
