<div class="page-auth page-confirm-email">
  <div class="main-body">
    <div class="card card-white">
      <div class="card-content">
        <div class="logo text-center">
          <a [routerLink]="['/']">Confirm Email</a>
        </div>

        <div>
          <p class="confirm-mail-icon">
            <mat-icon class="notranslate">mail_outline</mat-icon>
          </p>
          <p class="text-center text-small no-margin">
            An email has been send to <strong>username@mail.com</strong>. Please
            check for an email from us and click on the included link to reset
            your password.
          </p>
        </div>

        <div class="additional-info">
          <span>Return to <a [routerLink]="['/auth/login']">Login</a></span>
        </div>
      </div>
    </div>
  </div>
</div>
