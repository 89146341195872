import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef, inject } from '@angular/core';

// Services
import { CookieService } from 'ngx-cookie-service';
import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';
import { ThemeService } from 'insig-app/services/theme/theme.service';
import { LoginService } from '@insig-health/services/login/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { take } from 'rxjs/operators';
import { UserData } from 'insig-types/user-data';
import { Apollo, gql } from 'apollo-angular';
import { JAVA_BACKEND_ENDPOINT, SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/config';
import { ActivatedRoute } from '@angular/router';
import * as Sentry from '@sentry/browser';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';
import { PatientTermsOfUseComponent } from '@insig-health/patient-terms-of-use/patient-terms-of-use.component';
import { UpdateDoctorTermsDialogComponent } from '../update-doctor-terms-dialog/update-doctor-terms-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { DoctorTermsOfUseComponent } from 'insig-app/doctor-terms-of-use/doctor-terms-of-use.component';
import { DoctorService } from '@insig-health/services/doctor/doctor.service';
import { UpdatePatientTermsDialogComponent } from '../update-patient-terms-dialog/update-patient-terms-dialog.component';
import { PatientProfile, PatientProfileService } from '@insig-health/services/patient-profile/patient-profile.service';

interface queryUserData {
  getUserData: UserData
}

enum LoginState {
  TRIAGE,
  PATIENT_REGISTRATION,
  PATIENT_COMPLETE_PROFILE,
}

@Component({
  selector: 'insig-health-login',
  templateUrl: './login.component.html',
  providers: [
    CookieService,
    PatientUserDataService,
    ThemeService,
    LoginService,
  ],
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private readonly cookieService = inject(CookieService);
  private readonly gcpIpAuthService = inject(GcpIpAuthService);
  private readonly snackbar = inject(MatSnackBar);
  private readonly patientUserDataService = inject(PatientUserDataService);
  private readonly apollo = inject(Apollo);
  private readonly route = inject(ActivatedRoute);
  private readonly changeDetector = inject(ChangeDetectorRef);
  private readonly dialog = inject(MatDialog);
  private readonly doctorService = inject(DoctorService);
  private readonly patientProfileService = inject(PatientProfileService);
  public languageList: string[] = [];
  public clientPortal = false;
  public physioAccount: string | undefined; // ID of the physio salesperson who referred us
  public specialLogo = '';
  public partialPatient: Partial<PatientProfile> | undefined;

  @Input() loggingInForVirtualVisit = false;
  @Input() patientId: string | undefined;

  @Input() disablePatientRegistration = false;
  @Input() disableClinicianRegistrationAndLogin = false;

  @Output() loginOutput = new EventEmitter();

  public showClinicSignUp = false;
  public LoginState = LoginState;
  public state: LoginState = LoginState.TRIAGE;

  private userDataQuery = gql`
    query UserDataQuery($userID: ID!, $token: ID!) {
      getUserData(uid: $userID, token: $token) {
        uid
        company
        valid
        email
        type {
          admin
          broker
        }
        lastAcceptedTermsOfUseVersion
        resetPassword
      }
    }
  `;

  private clinicianRegistrationUrl =
    window.location.hostname === 'app.tiahealth.com' || window.location.hostname === 'app.wellclinics.ca' ?
      'https://form.jotform.com/220684024718253' :
      `${JAVA_BACKEND_ENDPOINT}doctor/start`;

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe((params) => {
      if (params.phy) {
        this.physioAccount = params.phy;
      }
    });

    this.route.params.subscribe((params) => {
      if (params.phy) {
        this.physioAccount = params.phy;
      }
    });
  }

  setLoginState(loginState: LoginState): void {
    this.state = loginState;
    this.changeDetector.detectChanges();
  }

  async getUserData(uid: string): Promise<UserData | undefined> {
    return (await firstValueFrom(this.apollo
      .query<queryUserData>({
        query: this.userDataQuery,
        variables: {
          userID: uid,
          token: await this.gcpIpAuthService.getCurrentUser()?.getIdToken(),
        },
      }))).data.getUserData;
  }

  async handleOauthComplete(): Promise<void> {
    const firebaseUser = this.gcpIpAuthService.getCurrentUser();
    if (firebaseUser === null) {
      return;
    }

    if (
      !this.patientId ||
      firebaseUser.uid !== this.patientId ||
      !this.loggingInForVirtualVisit
    ) {
      const redirectLink = await this.checkIfUserValid(firebaseUser.uid);
      if (redirectLink !== null) {
        this.loginOutput.emit(redirectLink);
      }
    }

    this.checkUserTermsOfUseVersion(firebaseUser.uid);

    // reset tabletmode, turning it off
    this.cookieService.set(
      'tabletMode',
      'false',
      undefined,
      undefined,
      undefined,
      true,
      'None',
    );
  }

  async checkUserTermsOfUseVersion(userId: string): Promise<void> {
    const doctorData = await this.getUserData(userId);

    if (doctorData) {
      if (doctorData.lastAcceptedTermsOfUseVersion === null ||
        doctorData.lastAcceptedTermsOfUseVersion === undefined ||
        doctorData.lastAcceptedTermsOfUseVersion < DoctorTermsOfUseComponent.VERSION) {
        this.openUpdateDoctorTermsDialog(userId);
      }
    } else {
      const patientData = await this.getPatientData(userId);
      if (
        !this.isPathnameSurveyPathname(window.location.pathname) &&
        (patientData.lastAcceptedTermsOfUseVersion === undefined || patientData.lastAcceptedTermsOfUseVersion < PatientTermsOfUseComponent.VERSION)
      ) {
        this.openUpdatePatientTermsDialog();
      }
    }
  }

  async openUpdateDoctorTermsDialog(doctorId: string): Promise<void> {
    const dialogRef = this.dialog.open<UpdateDoctorTermsDialogComponent>(UpdateDoctorTermsDialogComponent);
    const userAcceptedTerms = await firstValueFrom(dialogRef.afterClosed());

    if (userAcceptedTerms) {
      this.doctorService.updateDoctor(doctorId, { lastAcceptedTermsOfUseVersion: DoctorTermsOfUseComponent.VERSION });
    }
  }

  async openUpdatePatientTermsDialog(): Promise<void> {
    const dialogRef = this.dialog.open<UpdatePatientTermsDialogComponent>(UpdatePatientTermsDialogComponent);
    const userAcceptedTerms = await firstValueFrom(dialogRef.afterClosed());

    if (userAcceptedTerms) {
      this.patientProfileService.setCurrentUserPatientProfile({
        lastAcceptedTermsOfUseVersion: PatientTermsOfUseComponent.VERSION,
      });
    }
  }

  async checkIfDoctorAccount(uid: string): Promise<boolean> {
    const doctorData = await this.getUserData(uid);

    if (doctorData) {
      return true;
    } else {
      return false;
    }
  }

  async checkIfPatientAccount(uid: string): Promise<boolean> {
    try {
      const userData = await this.getPatientData(uid);
      if (userData && userData.uid) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getPatientData(uid: string): Promise<{
    uid: string,
    lastAcceptedTermsOfUseVersion?: string,
  }> {
    return firstValueFrom(this.patientUserDataService
      .getPatientData(uid))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  async checkIfUserValid(uid: string): Promise<string | null> {
    try {
      const isUserADoctor = await this.checkIfDoctorAccount(uid);
      const isUserAPatient = await this.checkIfPatientAccount(uid);

      if (!isUserADoctor && !isUserAPatient) {
        this.snackbar.open('Error loading user data!', undefined, {
          duration: 4000,
        });

        throw new Error('Patient is neither a doctor or a patient');
      }

      if (isUserAPatient && !isUserADoctor) {
        const isPatientProfileComplete = await this.patientProfileService.isPatientProfileComplete(uid);
        if (isPatientProfileComplete) {
          return this.getPatientLoginLink();
        } else {
          this.partialPatient = await this.patientProfileService.getPartialPatientProfile(uid);
          this.snackbar.open('Please complete your profile to continue', undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
          this.setLoginState(LoginState.PATIENT_COMPLETE_PROFILE);
          return null;
        }
      } else {
        const userData = await this.getUserData(uid);

        if (userData && !userData.valid) {
          // if user is not validated
          await this.gcpIpAuthService.signOut();
          return '/auth/not-validated';
        } else if (userData) {
          return this.getDoctorLoginLink(userData);
        } else {
          return null;
        }
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  getDoctorLoginLink(userData: UserData): string | null {
    if (!this.loggingInForVirtualVisit) {
      const loginLink = this.cookieService.get('loginLink');
      if (loginLink) {
        this.cookieService.delete('loginLink');
        return loginLink;
      } else if (userData.type && userData.type.broker) {
        return '/employee-manager';
      } else if (userData.resetPassword) {
        this.snackbar.open('Password Reset Required!', undefined, {
          duration: 4000,
        });
        return '/auth/reset-password';
      } else {
        return '/app/virtual/dashboard';
      }
    }
    return null;
  }

  getPatientLoginLink(): string {
    const loginLink = this.cookieService.get('loginLink');
    if (loginLink) {
      this.cookieService.delete('loginLink');
      return loginLink;
    } else {
      return '/app/virtual/dashboard';
    }
  }

  handleRegisterClinician(): void {
    window.location.href = this.clinicianRegistrationUrl;
  }

  handlePatientRegistrationComplete(): void {
    this.state = LoginState.TRIAGE;
  }

  handleOauthCancelled(): void {
    this.state = LoginState.TRIAGE;
  }

  isPathnameSurveyPathname(pathname: string): boolean {
    return pathname.startsWith('/surveys') || pathname.startsWith('/scheduler');
  }

  handlePatientProfileComplete(): void {
    this.loginOutput.emit(this.getPatientLoginLink());
  }
}
