// @ts-strict-ignore
import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy, inject } from '@angular/core';

import { FileUploadService } from 'insig-app/services/fileUpload.service';

import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

@Component({
  selector: 'integrations-dialog',
  templateUrl: './integrations-dialog.component.html',
  providers: [FileUploadService],
})
export class IntegrationsDialogComponent implements OnInit, OnDestroy {
  public readonly dialogRef = inject<MatDialogRef<Component>>(MatDialogRef<Component>);
  public readonly snackBar = inject(MatSnackBar);
  private readonly firebaseAuthService = inject(FirebaseAuthService);
  public answer = null;
  public multiYesNoAnswers = null;
  public multiYesNoOption = null;
  public multiYesNo = false;
  private userID: string = null;
  @ViewChild('container') public container: ElementRef;
  private maxWidth = 450;
  private loading = false;
  public startingText = '';

  async ngOnInit() {
    // handle multiyesno
    if (this.multiYesNo) {
      if (!this.multiYesNoAnswers.integrations) {
        this.multiYesNoAnswers.integrations = [{}, {}];
      }
      if (!this.multiYesNoAnswers.integrations[0]) {
        this.multiYesNoAnswers.integrations[0] = {};
      }
      if (!this.multiYesNoAnswers.integrations[1]) {
        this.multiYesNoAnswers.integrations[1] = {};
      }
      if (this.multiYesNoOption) {
        this.answer = this.multiYesNoAnswers.integrations[1];
      } else {
        this.answer = this.multiYesNoAnswers.integrations[0];
      }
    }
    this.loading = true;
    this.userID = await this.firebaseAuthService.getFirebaseCurrentUser().uid;
    this.loading = false;
  }

  ngOnDestroy() {
    if (!this.answer.integrationID || this.answer.integrationID.length === 0) {
      delete this.answer.integrationID;
      delete this.answer.integrationType;
      delete this.answer.integrationText;
      delete this.answer.integrationImage;
    }
  }

  changeIntegrationType(event) {
    delete this.answer.integrationText;
    delete this.answer.integrationImage;
    this.answer.integrationType = event;
    if (event === 'text') {
      this.answer.integrationText = this.startingText;
    }
  }

  removeImage(answer) {
    delete answer.integrationImage;
  }

  async onAdded(fileDropEntries: NgxFileDropEntry[]): Promise<void> {
    const fileDrop = fileDropEntries[0];
    const fileData = await new Promise<File>((resolve) => {
      const fileEntry = fileDrop.fileEntry as FileSystemFileEntry;
      fileEntry.file((file) => {
        resolve(file);
      });
    });

    this.loading = true;
    const reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = (() => {
      // slice to remove png,base64 etc. at beginnninng of string
      this.answer.integrationImage = reader.result;
      this.loading = false;
    }).bind(this);
    reader.onerror = ((error) => {
      console.log('Error: ', error);
      this.loading = false;
      this.snackBar.open(
        'Error uploading image! Please try again.',
        null,
        { duration: 2000 }
      );
    }).bind(this);
  } // end func
} // end class
