// @ts-strict-ignore
import {
  Component,
  Input,
  ViewChild,
  EventEmitter,
  Output,
  OnInit,
  OnChanges,
  SimpleChanges, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { NotesService } from 'insig-app/services/notes.service';
import { SelectNoteDialogComponent } from 'insig-app/global/dialogs/select-note-dialog/select-note-dialog.component';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import asyncPool from 'tiny-async-pool';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';


@Component({
  selector: 'patient-admin-history',
  templateUrl: './patient-admin-history.component.html',
  styleUrls: ['./patient-admin-history.component.scss'],
  providers: [NotesService],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', display: 'none' })
      ),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class PatientAdminHistoryComponent implements OnInit, OnChanges {
  private readonly snackBar = inject(MatSnackBar);
  private readonly dialog = inject(MatDialog);
  private readonly notesService = inject(NotesService);
  private readonly firebaseAuthService = inject(FirebaseAuthService);
  public menuItems: any = [
    {
      iden: 'history',
      icon: 'history',
      title: 'HISTORY',
    },
    {
      iden: 'help',
      icon: 'help',
      title: 'CONTACT US',
    },
  ];
  public loading = false;
  public searchMode = false;

  public selectedTab = 'history';
  columnsToDisplay = [
    'patientName',
    'date',
    'practitioner',
    'approvedStatus',
    'status',
    'documentSent',
  ];
  columnTitles = {
    patientName: { title: 'Patient Name', bold: true },
    date: { title: 'Date' },
    practitioner: { title: 'Practitioner' },
    approvedStatus: { title: 'Approved (Y/N)' },
    status: { title: 'Status' },
    documentSent: { title: 'Documents' },
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() patientAppointments: any;
  @Input() patientAppointmentsSearched: any;

  @Input() userData: any;
  @Output() getMorePatientAppointments = new EventEmitter();
  @Output() getSearchedPatientAppointments = new EventEmitter();
  @Output() getPatientAppointments = new EventEmitter();
  @Input() patientAdminLoading = false;

  dataSource: MatTableDataSource<any>;
  dataSourceSearched: MatTableDataSource<any>;
  // patient loading their notes for an appt
  async openNotesPatient(appointment: { linkID: string, patientID: string, companyID: string, appointmentType: string, patient: any }) {
    try {
      this.snackBar.open('Loading notes, please wait ...');
      const [companyId, apptID] = appointment.linkID.split('-');

      const patientNotes = await this.notesService.getNoteIdsFromAppointmentIds([{ companyId, appointmentId: apptID }], appointment.patientID, false)
        .then((noteIdsList) =>
          asyncPool(2, noteIdsList, (noteIds) =>
            this.notesService.loadNoteFromSpring(noteIds, { admittedPatient: true })
              .then((springNote) => {
                // Convert to legacy format
                const note = {
                  ...springNote,
                  patientInfo: springNote.admittedPatient,
                  companyID: companyId,
                  nid: springNote.noteId,
                };
                return note;
              })
              .catch((error) => {
                console.error('Failed to load note: ', noteIds, error);
                console.error(error);
              })
          )
        )
        .catch((error) => console.error(error));

      console.log(patientNotes);

      this.openNotes(
        patientNotes,
        appointment.patient,
        appointment.linkID.split('-')[1],
        true,
        appointment.linkID,
        appointment.appointmentType
      );
      this.snackBar.dismiss();
    } catch (error) {
      console.error(error);
      this.snackBar.open('Loading notes failed. Try again.', undefined, {
        duration: 3000,
      });
    }
  }

  openNotes(
    patientNotes,
    patient,
    apptID,
    patientView?,
    linkID?,
    appointmentType?
  ) {
    const dialogRef = this.dialog.open(SelectNoteDialogComponent);
    dialogRef.componentInstance.notes = patientNotes;
    dialogRef.componentInstance.patient = patient;
    dialogRef.componentInstance.apptID = apptID;
    dialogRef.componentInstance.patientView = patientView;
    dialogRef.componentInstance.linkID = linkID;
    dialogRef.componentInstance.appointmentType = appointmentType;
  }

  loadPatients(): void {
    this.searchMode = false;
    this.dataSource.paginator = this.paginator;
    // this.getPatientAppointments.emit();
  }

  searchPatients(patientSearch): void {
    this.loading = true;
    this.searchMode = true;

    // Convert queries to lowercase
    // firstNameSearch = firstNameSearch.toLowerCase();
    // lastNameSearch = lastNameSearch.toLowerCase();
    this.getSearchedPatientAppointments.emit(patientSearch.toLowerCase());
  }

  paginatorChange(ev) {
    console.log(ev);
    if (ev.length <= (ev.pageIndex + 2) * ev.pageSize) {
      console.log('load more data');
      this.getMorePatientAppointments.emit();
    }
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<any>(this.patientAppointments);
    this.dataSourceSearched = new MatTableDataSource<any>(
      this.patientAppointmentsSearched
    );
    
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.patientAppointments) {
      console.log(changes.patientAppointments);
      this.dataSource = new MatTableDataSource<any>(changes.patientAppointments.currentValue);
      this.dataSource.paginator = this.paginator;
    }

    if (changes.patientAppointmentsSearched) {
      this.dataSourceSearched = new MatTableDataSource<any>(changes.patientAppointmentsSearched.currentValue);
      this.dataSourceSearched.paginator = this.paginator;
    }
  }

  getBirthday(userData) {
    return new Date(
      `${userData.day}/${userData.month}/${userData.year}`
    ).toLocaleDateString();
  }

  async approvalStatusChange(element) {
    console.log(element);
    const IDToken = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();

    if (element.approved) {
      try {
        await this.notesService.setAppointmentIsApproved(
          IDToken,
          element.linkID,
          false
        );
      } catch (err) {
        console.log(err);
      }

    } else {
      this.notesService.setAppointmentIsApproved(IDToken, element.linkID, true);
    }
    element.approved = !element.approved;
  }
}
