<div id="patient-login">
  <div *ngIf="loading || stillLoading">
    <div class="divider "></div>
    <div class="divider "></div>
    <insig-app-loader class="mx-auto"></insig-app-loader>
  </div>

  <div class="auth-page h-100" [hidden]="!showPatientDemographics && !creatingNote">
    <div class="top-nav-right">
      <button mat-raised-button *ngIf="preview"
        color="primary"
        class="m-2"
        (click)="resetVariables(); showQuickPreview = true"
      >
        Quick Preview
      </button>
    </div>

    <div class="pt-5">
      <div class="l-banner container banner-content pt-0">
        <div>
          <div class="col-12 text-center">
            <h2 *ngIf="!loggingIn && !creatingNote" class="article-title mt-0">
              Patient Check-In
            </h2>
            <h2 *ngIf="creatingNote" class="article-title mt-0">
              Patient Info
            </h2>
            <h2 *ngIf="loggingIn" class="article-title paddingTop05em">
              Patient Login
            </h2>
            <h6 *ngIf="!loggingIn && !creatingNote" class="placeholder pb-3 mt-0 text-left">
              You will now be asked a series of questions that will be reviewed
              by your medical provider as part of your appointment. Your
              responses will be entered into your medical chart; please answer
              as accurately and completely as possible. Your information is
              secure and is only shared with your medical provider.
              <hr />
              Please enter your information as per government ID.
            </h6>
            <h6 *ngIf="creatingNote" class="placeholder pb-3 mt-0 text-left">
              <hr />
              <br />
              Enter patient info to match your EMR records.
              <br /><br />
            </h6>
          </div>
        </div>

        <div class="form-wrapper form-wrapper-larger clearfix-md" [hidden]="loading">
          <form *ngIf="loggingIn" [hidden]="stillLoading">
            <insig-health-login [disableClinicianRegistrationAndLogin]="true"
              (loginOutput)="handleLoggedIn()"></insig-health-login>
          </form>

          <form *ngIf="!!validationForm" class="webkit-hardware-acceleration" [formGroup]="validationForm"
            (ngSubmit)="onSubmit()" [hidden]="stillLoading || loggingIn"
            onkeypress="return event.keyCode != 13;">
            <fieldset>
              <div class="row">
                <div class="col-md-6 col-xs-12">
                  <div class="form-group form-field">
                    <mat-form-field class="full-width">
                      <span matPrefix>
                        <mat-icon class="notranslate material-icons">person</mat-icon>&nbsp;
                      </span>
                      <mat-label>First Name</mat-label>
                      <input id="first-name-launch-survey-signingup" matInput required formControlName="first" />
                      <mat-error>Required</mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-6 col-xs-12">
                  <div class="form-group form-field">
                    <mat-form-field class="full-width">
                      <span matPrefix>
                        <mat-icon class="notranslate material-icons">person_outline</mat-icon>&nbsp;
                      </span>
                      <mat-label>Last Name</mat-label>
                      <input id="last-name-launch-survey-signingup" matInput required formControlName="last" />
                      <mat-error>Required</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="divider "></div>

              <div class="row">
                <div class="col-md-6 col-xs-12">
                  <div class="form-group form-field">
                    <mat-form-field class="full-width">
                      <span matPrefix>
                        <mat-icon class="notranslate material-icons">phone</mat-icon>&nbsp;
                      </span>
                      <mat-label>Cell Phone Number</mat-label>
                      <input matInput insig-phone-mask required minlength="10" maxlength="14" type="tel" formControlName="phone" />
                      <mat-error>Please enter a 10-digit phone number</mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-6 col-xs-12">
                  <div class="form-group form-field">
                    <mat-form-field class="full-width">
                      <span matPrefix>
                        <mat-icon class="notranslate material-icons">email</mat-icon>&nbsp;
                      </span>
                      <mat-label>Email</mat-label>
                      <input matInput pattern="^.+@.+\..+$" type="email"
                        formControlName="email" />
                      <mat-error>Please enter a valid email (joe@example.com)</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <!-- <div class="divider "></div> -->

              <div class="row">
                <div class="col-12">
                  <label class="placeholder pl-1">Birthday *</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 col-xs-12">
                  <div class="row">
                    <div class="col-5 pr-0">
                      <div class="form-group form-field-no-padding">
                        <mat-form-field class="full-width">
                          <mat-label>YYYY</mat-label>
                          <input id="birthday-year-launch-survey-signingup" class="notranslate" type="tel"
                            pattern="[0-9]*" inputmode="numeric" required matInput maxlength="4" formControlName="year" />
                          <mat-error>Invalid year</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-7 pl-0 d-flex">
                      <div class="form-group form-field-no-padding w-50">
                        <mat-form-field class="full-width">
                          <mat-label>MM</mat-label>
                          <input id="birthday-month-launch-survey-signingup" class="notranslate" type="tel"
                            pattern="[0-9]*" inputmode="numeric" matInput required maxlength="2" formControlName="month" />
                          <mat-error>Invalid</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="form-group form-field-no-padding w-50">
                        <mat-form-field class="full-width">
                          <mat-label>DD</mat-label>
                          <input id="birthday-day-launch-survey-signingup" class="notranslate" type="tel"
                            pattern="[0-9]*" inputmode="numeric" matInput required maxlength="2" formControlName="day" />
                          <mat-error>Invalid</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-xs-12">
                  <div class="divider  hidden-sm-up"></div>
                  <div class="form-group form-field">
                    <mat-form-field class="full-width">
                      <span matPrefix>
                        <mat-icon class="notranslate material-icons">credit_card</mat-icon>&nbsp;
                      </span>
                      <mat-label>Health Card Number</mat-label>
                      <input matInput formControlName="healthCardNumber" />
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="divider "></div>

              <div class="row">
                <div class="col-md-6 col-xs-12 text-center">
                  <label class="placeholder">Designated Gender At Birth *</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 col-xs-12">
                  <div class="text-center ">
                    <mat-radio-group required class="mat-radio-group-spacing" formControlName="gender">
                      <mat-radio-button class="marginRight1em" id="male-gender-select-launch-survey-signingup"
                        value="Male">Male</mat-radio-button>
                      <mat-radio-button id="female-gender-select-launch-survey-signingup" value="Female">Female
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>

                <div class="col-md-6 col-xs-12">
                  <div class="hidden-md-up text-center">
                    <div class="divider "></div>
                    <mat-checkbox required color="primary" formControlName="terms"></mat-checkbox>
                    <span class="space"></span>I read and accept the
                    <p>
                      <a href="javascript:;" (click)="openTerms()"><i>Terms & Conditions</i></a>
                    </p>
                  </div>
                  <div class="hidden-sm-down">
                    <mat-checkbox id="terms-select-launch-survey-signingup" required color="primary"
                      formControlName="terms"></mat-checkbox>
                    <span class="space"></span>I read and accept the
                    <a href="javascript:;" class="d-inline" (click)="openTerms()"><i>Terms & Conditions</i></a>
                  </div>
                </div>
              </div>

              <div class="mt-4 text-center">
                <div class="divider "></div>
                <button id="start-button-launch-survey" mat-raised-button type="submit" class="btn-w-md"
                  color="primary">
                  START!
                </button>
                <div class="divider "></div>
                <div class="divider "></div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>

    </div>
  </div>

  <div class="auth-page h-100" *ngIf="showQuickPreview">
    <div class="top-nav-right">
      <button mat-raised-button color="primary" class="m-2" (click)="resetVariables(); showPatientDemographics = true">
        Patient Login
      </button>
    </div>

    <div class="top-nav-right" id="googleTranslateHeader">
      <div id="google_translate_element_main"></div>
    </div>

    <div class="full-height">
      <div class="l-banner container banner-content pt-5">
        <h1 class="m-0 h2 text-center" [hidden]="loading || stillLoading">
          Quick Preview
        </h1>

        <div class="form-wrapper form-wrapper-larger clearfix-md" [hidden]="loading">
          <div class="card" [hidden]="stillLoading">
            <div class="card-content">
              <div class="row">
                <div class="col-12">
                  <mat-icon class="notranslate">person</mat-icon> <b>Name:</b>
                  {{ quickPreviewVals.first }} {{ quickPreviewVals.last }}
                </div>
              </div>
              <div class="mt-2 row">
                <div class="col-12">
                  <mat-icon class="notranslate">person</mat-icon>
                  <b>Gender:</b> &nbsp;
                  <mat-radio-group required class="mat-radio-group-spacing" [(ngModel)]="quickPreviewVals.gender">
                    <mat-radio-button value="Male">Male</mat-radio-button>
                    <mat-radio-button class="ml-2" value="Female">Female</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <div class="mt-2 row">
                <div class="col-12">
                  <mat-icon class="notranslate">cake</mat-icon>
                  <b>Age:</b> &nbsp;
                  <mat-radio-group required class="mat-radio-group-spacing" [(ngModel)]="quickPreviewVals.year">
                    <mat-radio-button class="marginRight1em" *ngFor="let year of yearValues" [value]="year">{{
                      today.getFullYear() - year }}</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>

              <hr />
              <button id="quick-preview-start-launch-survey" mat-raised-button class="btn-w-md full-width"
                color="primary" (click)="quickPreview = true; onSubmit()">
                START!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
