<mat-card>
  <mat-card-title
    >Are you sure to remove this individual from this user
    group?</mat-card-title
  >
  <mat-card-content></mat-card-content>
  <div class="divider"></div>
  <div class="divider"></div>
  <mat-card-actions>
    <button mat-raised-button (click)="dialogRef.close(false)">No</button>
    <button
      mat-raised-button
      color="primary"
      class="float-right"
      (click)="dialogRef.close(true)"
    >
      Yes
    </button>
  </mat-card-actions>
</mat-card>
