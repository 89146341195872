// @ts-strict-ignore
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'surgery-edit',
  templateUrl: './surgery-edit.component.html',
})
export class SurgeryEditComponent implements OnInit {
  question = {
    response: {
      selectedAnswers: [],
      selectedCheckboxes: [],
    },
  };

  private commonSurgeries = ['Tonsils', 'Wisdom Teeth'];

  ngOnInit() {
    this.commonSurgeries.forEach((surgery) => {
      this.question.response.selectedCheckboxes.push({
        surgery,
        selected: false,
      });
    });
  }

  removeItem(val) {
    const index = this.question.response.selectedAnswers.indexOf(val);
    this.question.response.selectedAnswers.splice(index, 1);
  }

  addItem(val) {
    if (this.question.response.selectedAnswers.indexOf(val) === -1) {
      this.question.response.selectedAnswers.push(val);
    }
    this.question.response.selectedAnswers[-1] = null;
  } // end add item
}
