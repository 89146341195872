// @ts-strict-ignore
import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'video-edit',
  templateUrl: './video-edit.component.html',
  styleUrls: ['./video-edit.component.scss'],
})
export class VideoEditComponent implements AfterViewInit {
  @Input() ngModel: NgModel;
  @ViewChild(NgModel) innerModel: NgModel;
  public emptyString: any = '';
  public loadedURL: any;

  ngAfterViewInit() {
    this.innerModel.model = this.ngModel;
    setTimeout(() => {
      this.loadedURL = this.innerModel.model;
    }, 0);
  }
}
