import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  getRandomString(length: number): string {
    const characterBank = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let returnValue = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characterBank.length);
      returnValue += characterBank.slice(randomIndex, randomIndex + 1);
    }
    return returnValue;
  }

  /**
   * Checks if a string is empty or only contains whitespace
   * We need to handle null or undefined values for legacy code with no strict null checks
   */
  isEmptyString(value: string | null | undefined): boolean {
    return !value || value.trim().length === 0;
  }
}
