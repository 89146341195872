// @ts-strict-ignore
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { IDatePickerConfig } from 'ng2-date-picker';
import dayjs, { Dayjs } from 'dayjs';

@Component({
  selector: 'date-edit',
  templateUrl: './date-edit.component.html',
})
export class DateEditComponent implements OnInit {
  @Input() question: any;
  @Output() questionChange = new EventEmitter<any>();
  private thisDayjs: Dayjs;
  private config: IDatePickerConfig = {};
  constructor() {
    this.thisDayjs = dayjs(new Date());
  }

  ngOnInit() {
    this.config.max = this.question.noFuture ? this.thisDayjs : null;
    this.config.min = this.question.noPast ? this.thisDayjs : null;
  }

  toggleFutureDates(event) {
    this.question.noFuture = event;
    this.config.max = event ? this.thisDayjs : null;
    this.questionChange.emit(this.question);
  }

  togglePastDates(event) {
    this.question.noPast = event;
    this.config.min = event ? this.thisDayjs : null;
    this.questionChange.emit(this.question);
  }
}
