// @ts-strict-ignore
import { Injectable, inject } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/storage';
import { EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FileUploadService {
  private readonly http = inject(HttpClient);

  uploadFile(
    path: string,
    file: Blob | ArrayBuffer | Uint8Array,
    metadata?: firebase.storage.UploadMetadata
  ): firebase.storage.UploadTask {
    return firebase
      .storage()
      .ref()
      .child(path)
      .put(file, metadata);
  }

  deleteFile(path: string): Promise<any> {
    return firebase
      .storage()
      .ref()
      .child(path)
      .delete();
  }

  generateRandomID(length) {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  /**
   * Transforms a resource url into an Observable containing a template embeddable base 64 string
   * Resolve this Observable using Angular's built-in AsyncPipe
   * @param  {string | SafeResourceUrlImpl} url          The resource location as a URL, SafeResourceUrlImpl is not yet added to @types
   * @param  {string}                       [authScheme] The scheme to authenticate with, if any. If unsure, use 'Bearer'
   * @param  {string}                       authToken    The token to use for authentication, if any. If unsure, use firebase User ID Token
   * @return {Observable<SafeResourceUrl>}  An Observable containing a base 64 template embeddable string: "data:<MIME type>;base64,<data string>"
   */
  urlToBase64Service(
    url: string | any,
    authScheme?: string,
    authToken?: string
  ): Promise<any> {
    if (!url) {
      console.warn('url is void');
      return Promise.resolve(false);
    }
    const urlString: string =
      typeof url !== 'string' ? url.changingThisBreaksApplicationSecurity : url; // Convert SafeResourceUrlImpl to string, if necessary
    if (!urlString) {
      console.warn('url is void');
      Promise.resolve(false);
    }
    const headers =
      !!authScheme && !!authToken
        ? { Authorization: `${authScheme} ${authToken}` }
        : undefined; // Construct Authorization headers
    return this.http
      .get(urlString, {
        headers,
        observe: 'response',
        responseType: 'arraybuffer',
      })
      .pipe(
        map((res) => {
          return `data:${res.headers.get(
            'Content-Type'
          )};base64,${this.arrayBufferToBase64(res.body)}`;
        }),
        catchError((error) => {
          const enc = new TextDecoder('utf-8');
          if (error.error) {
            error.error = enc.decode(error.error);
          }
          console.error(error);
          return EMPTY;
        })
      )
      .toPromise();
  }

  /**
   * Converts Uint8Array to base64 string
   * @param  buffer ArrayBuffer of Uint8
   * @return        Raw base64 string
   */
  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    const bytes = new Uint8Array(buffer);
    let binary = '';
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
} // end service

// service firebase.storage {
//   match /b/insig-ca.appspot.com/o {
//
//
//     match /2016/{allPaths=**} {
//       allow read: if false;
//     }
//     match /2017/{allPaths=**} {
//       allow read: if false;
//     }
//     match /Tutorials/{allPaths=**} {
//       allow read: if true;
//     }
//
//     match /company/{allPaths=**} {
//       allow read, write: if true;
//     }
//
//     match /user/surveys/{folder}/{allPaths=**}  {
//       allow read: if (folder != 'patientUploaded');
//       allow write: if true;
//     }
//
//     //user uploaded files
//     match /user/decompressed/{allPaths=**} {
//       allow read, write: if true;
//     }
//     match /user/data/{allPaths=**} {
//       allow read, write: if true;
//     }
//
//   	//patients uploading their pdfs for pdf question
//   	//put read true b/c wouldnt else it let patient upload
//     match /patientUploadedPDFs/{cid}/{allPaths=**} {
//       allow read, write: if true;
//     }
//     //pdf images drawn or typed on by patient
//     match /pdfImage/{cid}/{allPaths=**} {
//     	allow read, write: if true;
//     }
//    //more pfds uploaded to note
//    match /notePDFs/{cid}/{allPaths=**} {
//        allow read, write: if (request.auth.token.cid == cid
//                         || (cid == 'tiaHealth' && request.auth.token.tia));
//    }
//
//
//   }
// }
