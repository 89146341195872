<div>
  <h2 mat-dialog-title>Update Your Email Address</h2>

  <mat-dialog-content>
    <ng-container *ngIf="emailUpdateState === EmailUpdateState.LOADING">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>

    <ng-container *ngIf="emailUpdateState === EmailUpdateState.LOGIN">
      <div class="d-flex flex-column align-items-start gap-2">
        <div>
          Please reauthenticate to continue.
        </div>
        <insig-health-gcp-ip-login-widget
          class="mx-auto"
          [disableSignUp]="true"
          [readOnlyEmail]="email"
          [isLogoVisible]="false"
          [disableAutomaticLogin]="true"
          [disableCustomToken]="true"
          [disableCardBorder]="true"
          (onLoggedIn)="handleLoginSuccess($event)"
        >
        </insig-health-gcp-ip-login-widget>
      </div>
    </ng-container>

    <ng-container *ngIf="emailUpdateState === EmailUpdateState.ENTER_NEW_EMAIL">
      <ng-container *ngTemplateOutlet="changeEmailFormTemplate"></ng-container>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions class="d-flex justify-content-between">
    <insig-ui-button
      class="mr-auto"
      stylePreset="tertiary"
      [mat-dialog-close]="false"
    >
      Cancel
    </insig-ui-button>
    <insig-ui-button *ngIf="emailUpdateState === EmailUpdateState.ENTER_NEW_EMAIL"
      stylePreset="secondary"
      (click)="handleSaveButtonClick()"
      [disabled]="changeEmailForm.invalid"  
    >
      Save
    </insig-ui-button>
  </mat-dialog-actions>
</div>

<ng-template #changeEmailFormTemplate>
  <form [formGroup]="changeEmailForm">
    <insig-ui-text-input label="New Email Address" [formControlName]="NEW_EMAIL_ADDRESS_FORM_CONTROL_NAME" isRequired="true"></insig-ui-text-input>
    <insig-ui-form-error
      [formControlForErrorMessage]="changeEmailForm.controls[NEW_EMAIL_ADDRESS_FORM_CONTROL_NAME]"
      errorText="Email is required."
      errorType="required"
    ></insig-ui-form-error>
    <insig-ui-form-error
      [formControlForErrorMessage]="changeEmailForm.controls[NEW_EMAIL_ADDRESS_FORM_CONTROL_NAME]"
      errorText="Email must be a valid email."
      errorType="email"
    ></insig-ui-form-error>

    <insig-ui-text-input label="Confirm New Email Address" [formControlName]="CONFIRM_NEW_EMAIL_ADDRESS_FORM_CONTROL_NAME" isRequired="true"></insig-ui-text-input>
    <insig-ui-form-error
      [formControlForErrorMessage]="changeEmailForm.controls[CONFIRM_NEW_EMAIL_ADDRESS_FORM_CONTROL_NAME]"
      errorText="Confirm email is required."
      errorType="required"
    ></insig-ui-form-error>
    <insig-ui-form-error
      [formControlForErrorMessage]="changeEmailForm.controls[CONFIRM_NEW_EMAIL_ADDRESS_FORM_CONTROL_NAME]"
      errorText="Confirm email must be a valid email."
      errorType="email"
    ></insig-ui-form-error>

    <insig-ui-form-error
      [formControlForErrorMessage]="changeEmailForm"
      errorText="Emails must match."
      errorType="controlValuesNotMatching"
    ></insig-ui-form-error>
  </form>
</ng-template>
