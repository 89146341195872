<mat-card>
  <mat-card-title>Add Companies To Plan</mat-card-title>
  <mat-card-content>
    <div class="row m-0">
      <form [formGroup]="form" class="full-width">
        <!-- Company auto select -->
        <div class="form-group">
          <mat-chip-listbox class="text-center">
            <mat-chip-option
              *ngFor="let company of selectedCompanies"
              [selectable]="true"
              [removable]="true"
              (remove)="removeItem(company)"
            >
              {{ company.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
        <div class="form-group">
          <mat-form-field class="full-width">
            <mat-label>Add Existing Insig Companies</mat-label>
            <input
              #companySearchBox
              matInput
              aria-label="companies"
              [matAutocomplete]="auto"
              [(ngModel)]="currentCompanyName"
              [formControl]="companiesControl"
              formControlName="companiesSubscribed"
              (keyup.enter)="onEnter(companySearchBox.value)"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let company of filteredOptions | async"
                (click)="addItem(company)"
                [value]="company.name"
              >
                <img
                  style="vertical-align:middle;"
                  aria-hidden
                  src="{{
                    company.branding
                      ? company.branding
                      : '/assets/images/logo/logo_short.svg'
                  }}"
                  height="25"
                />
                <span
                  >{{ company.name }}
                  {{
                    company.brandingName ? '(' + company.brandingName + ')' : ''
                  }}</span
                >
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </form>
    </div>
  </mat-card-content>
  <div class="divider"></div>
  <div class="divider"></div>
  <mat-card-actions>
    <button mat-raised-button (click)="dialogRef.close(false)">Cancel</button>
    <button
      mat-raised-button
      color="primary"
      class="float-right"
      (click)="addNewCompanies()"
    >
      Add Companies
    </button>
  </mat-card-actions>
</mat-card>
