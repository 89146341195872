<mat-card>
  <h1 mat-dialog-title>Move To Folder</h1>

  <mat-dialog-content>
    <!-- begin folder selection tool -->
    <div class="row w-100 p-0 w-0">
      <div class="col-4 p-0">
        <ng-container
          *ngIf="leftMenuFolders === null || leftMenuFolders === undefined"
        >
          <button
            mat-menu-item
            (click)="openFolder('root', 'left')"
            [ngClass]="isSelected('root')"
          >
            <mat-icon matListItemIcon class="notranslate">home</mat-icon>
            <div class="d-flex justify-content-start align-items-center">
              <span class="mr-auto">Root</span>
              <i matListItemIcon class="fa fa-chevron-right text-material" aria-hidden="true"></i>
            </div>
          </button>
        </ng-container>
        <ng-container
          *ngIf="leftMenuFolders !== null && leftMenuFolders !== undefined"
        >
          <button
            mat-menu-item
            *ngFor="let folder of leftMenuFolders"
            (click)="openFolder(folder.id, 'left')"
            [ngClass]="isSelected(folder.id)"
          >
            <mat-icon class="notranslate">folder</mat-icon>
            <div class="d-flex justify-content-between align-items-center">
              <span class="mr-auto">{{ folder.name }}</span>
              <i class="fa fa-chevron-right text-material" aria-hidden="true"></i>
            </div>
          </button>
        </ng-container>
      </div>

      <div class="col-4 p-0 border-x-1">
        <ng-container
          *ngIf="centerMenuFolders !== null && centerMenuFolders !== undefined"
        >
          <button mat-menu-item disabled *ngIf="centerMenuFolders.length === 0">
            No nested folders
          </button>

          <button
            mat-menu-item
            *ngFor="let folder of centerMenuFolders"
            (click)="openFolder(folder.id, 'center')"
            [ngClass]="isSelected(folder.id)"
          >
            <mat-icon class="notranslate">folder</mat-icon>
            <div class="d-flex justify-content-between align-items-center">
              <span class="mr-auto">{{ folder.name }}</span>
              <i class="fa fa-chevron-right text-material" aria-hidden="true"></i>
            </div>
          </button>
        </ng-container>
      </div>

      <div class="col-4 p-0">
        <ng-container
          *ngIf="rightMenuFolders !== null && rightMenuFolders !== undefined"
        >
          <button mat-menu-item disabled *ngIf="rightMenuFolders.length === 0">
            No nested folders
          </button>

          <button
            mat-menu-item
            *ngFor="let folder of rightMenuFolders"
            (click)="openFolder(folder.id, 'right')"
            [ngClass]="isSelected(folder.id)"
            class="d-flex justify-content-between align-items-center"
          >
            <mat-icon class="notranslate">folder</mat-icon>
            <div class="d-flex justify-content-between align-items-center">
              <span class="mr-auto">{{ folder.name }}</span>
              <i class="fa fa-chevron-right text-material" aria-hidden="true"></i>
            </div>
          </button>
        </ng-container>
      </div>
    </div>
    <!-- end folder selection tool -->
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="selectedFolderId">
      Confirm
    </button>
  </mat-dialog-actions>
</mat-card>
