import { Injectable } from '@angular/core';

@Injectable()
export class SubscriptionsService {
  public subscriptions = {
    surveysMonthly: {
      price: 120,
      subscriptionID: 'insig-virtual-monthly',
      name: 'Insig Monthly',
    },
    surveysYearly: {
      price: 1200,
      subscriptionID: 'insig-virtual-yearly',
      name: 'Insig Yearly',
    },
    insigDev: {
      price: 0,
      subscriptionID: 'insig-dev',
      name: 'Dev Subscription',
    },

    tiaHealthOptIn: {
      price: 0,
      subscriptionID: 'tia-health-opt-in',
      name: 'Tia Health Subscription',
    },
  };
} // end service
