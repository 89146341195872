// @ts-strict-ignore
import { Component, OnInit, Input, EventEmitter, Output, inject } from '@angular/core';
import { DemographicsAndDoctorFilterService } from '../../services/demographicsAndDoctorFilter.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpecialResponseService } from 'insig-app/services/specialResponse.service';
import { CkConfigService } from 'insig-app/services/ckConfig.service';
import { InitNoteService } from 'insig-app/services/initNote.service';

import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';
import { take } from 'rxjs/operators';

// import 'insig-app/styles/surveys/surveys.scss';

@Component({
  selector: 'survey-pages',
  templateUrl: './survey-pages.component.html',
  styleUrls: ['./survey-pages.component.scss'],
  providers: [
    DemographicsAndDoctorFilterService,
    CkConfigService,
    SpecialResponseService,
    InitNoteService,
  ]
})
export class SurveyPagesComponent implements OnInit {
  private readonly demographicsAndDoctorFilterService = inject(DemographicsAndDoctorFilterService);
  private readonly snackBar = inject(MatSnackBar);
  private readonly ckConfigService = inject(CkConfigService);
  private readonly specialResponseService = inject(SpecialResponseService);
  private readonly initNoteService = inject(InitNoteService);
  private readonly patientUserDataService = inject(PatientUserDataService);
  @Input() preview = false;
  @Input() schedulerLogin = false;
  @Input() tabletMode: any;
  @Input() quickView: any;
  @Input() showAds: any = false;
  /** Whether or not the template should reduce as much white space as possible */
  @Input() compactView = false;

  @Input() survey: any;
  @Input() companyID: string;
  @Input() doctorCompleting = false;
  @Input() patientInfo: any;
  @Input() quickPreview: any;
  @Input() surveyError = false;
  @Input() familyId: string;

  @Output() submitSurvey = new EventEmitter<string>();
  @Output() updateCurrentPage = new EventEmitter<number>();
  public patientCkConfig: any;

  private _uploadedPDFIDs = {};
  @Input() set uploadedPDFIDs(value: any) {
    this._uploadedPDFIDs = value;
    this.uploadedPDFIDsChange.emit(this._uploadedPDFIDs);
  }
  get uploadedPDFIDs(): any {
    return this._uploadedPDFIDs;
  }
  @Output() uploadedPDFIDsChange = new EventEmitter<any>();

  public loading = false;
  @Input() currentPage = 0;
  public adIndexes: number[] = [];
  public get progressBarValue(): number {
    return ((this.currentPage + 1) * 100.0) / this.survey.pages.length;
  }

  public get isFirstPage(): boolean {
    return !this.previousPageExists(this.currentPage);
  }
  public previousPageExists(pageNumber: number): boolean {
    if (!this.survey.pages[pageNumber - 1]) {
      return false;
    }
    if (
      !this.checkIfAllQuestionsHiddenOnPage(
        this.survey.pages[pageNumber - 1].elements
      )
    ) {
      return true;
    } else {
      return this.previousPageExists(pageNumber - 1);
    }
  }

  public get isLastPage(): boolean {
    return !this.nextPageExists(this.currentPage);
  }
  public nextPageExists(pageNumber: number): boolean {
    if (!this.survey.pages[pageNumber + 1]) {
      return false;
    }
    if (
      !this.checkIfAllQuestionsHiddenOnPage(
        this.survey.pages[pageNumber + 1].elements
      )
    ) {
      return true;
    } else {
      return this.nextPageExists(pageNumber + 1);
    }
  }

  constructor(
) {
    this.patientCkConfig = this.ckConfigService.getPatientConfig();
  }

  async ngOnInit() {
    if (!this.survey) {
      console.log('BROKEN SURVEY');
      this.surveyError = true;
      return;
    }

    if (!this.survey.morePDFs) {
      this.survey.morePDFs = [];
    }

    // set patient age for age filters
    if (!this.quickPreview) {
      if (this.patientInfo === undefined) {
        try {
          if (this.familyId) {
            this.patientInfo = await this.patientUserDataService.getOneFamilyMember(this.familyId).then((documentSnapshot) => documentSnapshot.data()?.data);
          } else {
            this.patientInfo = await this.patientUserDataService.getPatientData(this.survey.patientUid).pipe(take(1)).toPromise();
          }
        } catch(error) {
          console.error(error);
          throw error;
        }
      }
      this.patientInfo.age = this.getAge(this.patientInfo.year + '/' + this.patientInfo.month + '/' + this.patientInfo.day);
      this.survey.patientInfo = this.patientInfo;
      this.setTriggersPage(this.survey.pages[this.currentPage].elements);
      this.survey = this.demographicsAndDoctorFilterService.setAllFilters(
        this.survey,
        this.patientInfo,
        this.doctorCompleting
      );
    }

    // Goto first page with visible elements
    if (
      this.checkIfAllQuestionsHiddenOnPage(
        this.survey.pages[this.currentPage].elements
      )
    ) {
      this.nextPage();
    }

    // dont fill CPP if doctor doing it
    if (!this.doctorCompleting) {
      this.fillCPPAnswers(this.patientInfo);
    }
      // only show roster question to patients in Ontario
    if (!this.doctorCompleting) {
      try {
        this.hideRosterQuestionOutsideOntario(this.patientInfo);
        this.hideFamilyDoctorQuestionOutsideBC(this.patientInfo);
      } catch(err) {
        console.error(err);
      }
    }
    this.getShownElements(this.survey.pages[this.currentPage].elements, true);
  }

  hideFamilyDoctorQuestionOutsideBC(patientInfo) {
    this.survey.pages.forEach((page) => {
      page.elements.forEach((element) => {
        if (element.integrationID === 'bcFamilyDoctorStatus' &&
          patientInfo.province !== 'BC' &&
          patientInfo.province !== 'British Columbia' &&
          patientInfo.province !== 'britishcolumbia' &&
          patientInfo.province !== 'bc' &&
          patientInfo.province !== 'british columbia') {
            element.demographicsOrDoctorHidden = true;
          }
      })
    })
  } // end func

  // roster question will only show if we know the patient's province and
  // its in ontario
  hideRosterQuestionOutsideOntario(patientInfo) {
    this.survey.pages.forEach((page) => {
      page.elements.forEach((element) => {
        if (element.integrationID === 'rosterStatus' &&
          patientInfo.province !== 'ON' &&
          patientInfo.province !== 'Ontario' &&
          patientInfo.province !== 'on' &&
          patientInfo.province !== 'ontario') {
            element.demographicsOrDoctorHidden = true;
          }
      })
    })
  } // end func

  fillCPPAnswers(patientInfo) {
    console.log('filling cpp answers: ', patientInfo);
    console.log(this.survey);
    if (patientInfo.cpp) {
      this.survey.pages.forEach((page) => {
        page.elements.forEach((element) => {
          if (
            element.cpp &&
            element.question &&
            patientInfo.cpp[element.question.type]
          ) {
            // cleanup arrays that turned into dictionary
            if (patientInfo.cpp[element.question.type].selectedAnswers) {
              const tempArray = [];
              for (const i of Object.keys(
                patientInfo.cpp[element.question.type].selectedAnswers || {}
              )) {
                tempArray.push(
                  patientInfo.cpp[element.question.type].selectedAnswers[i]
                );
              }
              patientInfo.cpp[
                element.question.type
              ].selectedAnswers = tempArray;
            }
            element.question.response = patientInfo.cpp[element.question.type];
          } else if (
            element.cpp &&
            element.question &&
            patientInfo.cpp[element.integrationID]
          ) {
            // cleanup arrays that turned into dictionary
            if (patientInfo.cpp[element.integrationID].selectedAnswers) {
              const tempArray = [];
              for (const i of Object.keys(
                patientInfo.cpp[element.integrationID].selectedAnswers || {}
              )) {
                tempArray.push(
                  patientInfo.cpp[element.integrationID].selectedAnswers[i]
                );
              }
              patientInfo.cpp[
                element.integrationID
              ].selectedAnswers = tempArray;
            }
            if (element.question.type === 'yesno' && patientInfo.cpp[element.integrationID]) {
              element.question.response = {answer: patientInfo.cpp[element.integrationID]};
            } else {
              element.question.response = patientInfo.cpp[element.integrationID];
            }
          } else if (
            !!element.cpp &&
            !!element.question &&
            !!patientInfo.cpp.custom &&
            !!patientInfo.cpp.custom[this.survey.id] &&
            !!patientInfo.cpp.custom[this.survey.id][element.question.id]
          ) {
            console.log(
              element,
              patientInfo.cpp.custom[this.survey.id][element.question.id]
            );
            if (element.question.type === 'grid') {
              element.question.tempResponse = this.specialResponseService.extractTempResponseForGridQuestion(
                patientInfo.cpp.custom[this.survey.id][element.question.id]
              );
              element.question.response =
                patientInfo.cpp.custom[this.survey.id][
                  element.question.id
                ].response;
              element.question.grid =
                patientInfo.cpp.custom[this.survey.id][
                  element.question.id
                ].grid;
            } else {
              element.question.response =
                patientInfo.cpp.custom[this.survey.id][
                  element.question.id
                ].response;
            }
            console.log(element.question);
          }
        });
      });
    }
  }

  /**
   * Computes the age in years given a date of birth
   * @param  birthday date of birth
   * @return          age in years
   */
  getAge(birthday) {
    return this.initNoteService.getAge(birthday);
  }

  checkLoadedParent(loading: boolean): void {
    setTimeout(() => {
      this.loading = loading;
    }, 100);
  }

  // ***Not sure why it needs to be done twice, but else messes up triggers
  // that trigger surveys
  checkTriggersParent(_event) {
    this.setTriggersPage(this.survey.pages[this.currentPage].elements);
    this.setTriggersPage(this.survey.pages[this.currentPage].elements);
    try {
      this.removeUntriggeredSurvey();
    } catch (error) {
      console.log('Error removing untriggered surveys');
      console.log(error);
    }
    this.getShownElements(this.survey.pages[this.currentPage].elements, false);
  }

  // dave
  removeUntriggeredSurvey() {
    if (!this.survey.pages || this.quickPreview) {
      return false;
    }
    this.survey.pages.forEach(((page) => {
      this.setTriggersPage(page.elements);
      page.elements.forEach((element) => {
        if (
          element.question &&
          element.question.type === 'survey' &&
          element.triggerHidden &&
          element.question.parentRemovalID
        ) {
          console.log(
            'Removing previously triggered survey: ',
            element.question.text
          );
          this.surveyUntriggered(element.question);
        }
      });
    }).bind(this));
  }

  // if survey un-triggered, hide its children, since children at
  // top level are linked to this variable
  surveyUntriggered(question) {
    if (!question.parentRemovalID) {
      return false;
    }
    this.removeSurvey(question);
    question.surveyQuestionTriggered = false;
    question.alreadyLoaded = false;
    question.parentRemovalID = null;
    delete question.liveName;
  }

  // iterate from back so splice works properly on indices
  removeSurvey(question) {
    const removalID = question.parentRemovalID;
    const triggersDictionary = this.survey.triggersDictionary;
    const mainSurveyPages = this.survey.pages;
    for (
      let pageIndex = mainSurveyPages.length - 1;
      pageIndex >= 0;
      --pageIndex
    ) {
      const pageElements = mainSurveyPages[pageIndex].elements;
      for (
        let elementIndex = pageElements.length - 1;
        elementIndex >= 0;
        --elementIndex
      ) {
        if (pageElements[elementIndex].removalID === removalID) {
          if (
            pageElements[elementIndex].question &&
            pageElements[elementIndex].question.type === 'survey'
          ) {
            this.surveyUntriggered(pageElements[elementIndex].question);
          }
          delete triggersDictionary[pageElements[elementIndex].id];
          pageElements.splice(elementIndex, 1);
        } // end if
      } // end loop elements
      if (pageElements.length === 0) {
        mainSurveyPages.splice(pageIndex, 1);
      }
    } // end loop pages
  } // end remove surveys

  checkRequired(pageElements) {
    if (!pageElements || this.quickPreview) {
      return true;
    }
    const unanswered = [];
    pageElements.forEach((element) => {
      if (
        element.question &&
        element.type === 'question' &&
        element.question.required &&
        !element.demographicsOrDoctorHidden &&
        !element.triggerHidden
      ) {
        const questionType = element.question.type;
        const response = element.question.response;
        if (
          (questionType === 'text' ||
            questionType === 'textarea' ||
            questionType === 'photograph' ||
            questionType === 'yesno' ||
            questionType === 'date' ||
            questionType === 'time' ||
            questionType === 'email' ||
            questionType === 'range' ||
            questionType === 'fiveStar' ||
            questionType === 'drawing' ||
            questionType === 'phone' ||
            questionType === 'medicationAllergies' ||
            questionType === 'address' ||
            questionType === 'conditions') &&
          (!response || !response.answer)
        ) {
          element.unanswered = true;
          unanswered.push(element.id);
          return false;
        }
        if (questionType === 'number') {
          if (response?.answer === null || response?.answer === undefined) {
            element.unanswered = true;
            unanswered.push(element.id);
            return false;
          }
        }
        if (
          (questionType === 'scoring' || questionType === 'select') &&
          (!response || !response.selectedAnswer)
        ) {
          element.unanswered = true;
          unanswered.push(element.id);
          return false;
        }
        if (
          questionType === 'yearMonthDay' &&
          (!response ||
            !response.answer ||
            (!response.answer.day &&
              !response.answer.month &&
              !response.answer.year))
        ) {
          element.unanswered = true;
          unanswered.push(element.id);
          return false;
        }
        if (
          questionType === 'radio' &&
          (!response ||
            !response.selectedAnswer ||
            (response.selectedAnswer === 'other' && !response.other))
        ) {
          element.unanswered = true;
          unanswered.push(element.id);
          return false;
        }
        if (
          questionType === 'multiyesno' &&
          (!element.question.response ||
            !response.selectedAnswers ||
            response.selectedAnswers.length !==
              element.question.offeredQuestions.length)
        ) {
          element.unanswered = true;
          unanswered.push(element.id);
          return false;
        }
        if (
          questionType === 'checkbox' &&
          (!response ||
            ((!response.selectedAnswers ||
              response.selectedAnswers[0] === undefined) &&
              !response.other))
        ) {
          element.unanswered = true;
          unanswered.push(element.id);
          return false;
        }

        if (
          questionType === 'medications' &&
          (!response ||
            ((!response.selectedAnswers ||
              response.selectedAnswers[0] === undefined) &&
              !response.noMeds))
        ) {
          element.unanswered = true;
          unanswered.push(element.id);
          return false;
        }

        if (
          (questionType === 'doctors' || questionType === 'autocomplete') &&
          (!response ||
            !response.selectedAnswers ||
            response.selectedAnswers[0] === undefined)
        ) {
          element.unanswered = true;
          unanswered.push(element.id);
          return false;
        }
        if (
          questionType === 'dateRange' &&
          (!response ||
            !response.answer ||
            !response.answer.from ||
            !response.answer.to)
        ) {
          element.unanswered = true;
          unanswered.push(element.id);
          return false;
        }
        if (
          questionType === 'priority' &&
          (!response ||
            !response.priorityList ||
            response.priorityList.length === 0)
        ) {
          element.unanswered = true;
          unanswered.push(element.id);
          return false;
        }
        if (
          questionType === 'heightWeight' &&
          (!response || Object.keys(response).length === 0)
        ) {
          element.unanswered = true;
          unanswered.push(element.id);
          return false;
        }
        if (
          questionType === 'signature' &&
          (!response ||
            !response.answer ||
            (element.question.terms && !response.responseTerms))
        ) {
          element.unanswered = true;
          unanswered.push(element.id);
          return false;
        }
        element.unanswered = false;
      }
    });
    if (unanswered.length > 0) {
      this.snackBar.open(
        'Please enter a valid answer for each required question!',
        null,
        { duration: 4000 }
      );
      const unansweredQuestion = document.getElementById(unanswered[0]);
      if (unansweredQuestion) {
        window.scrollTo(0, unansweredQuestion.offsetTop);
        return false;
      } else {
        console.log('Error, can\'t find unanswered question');
      }
    }
    return true;
  }

  /**
   * Setup triggers for the given elements (shown, hidden, etc.)
   * @param elements Elements to setup triggers for
   */
  setTriggersPage(elements: any[]): void {
    if (this.quickPreview) {
      return;
    }
    for (const element of Object.keys(elements || {})) {
      this.triggersFilter(elements[element]);
    }
  }

  triggersFilter(pageElement) {
    // check if question has no triggers or is a top level parent
    if (!pageElement.pageFlow || !pageElement.pageFlow.parents) {
      return false;
    }
    const wholeTriggerDictionary = this.survey.triggersDictionary;
    const individualTriggerDictionary = wholeTriggerDictionary[pageElement.id];
    // reset if question is being shown to false
    individualTriggerDictionary['shown'] = false;
    // check if any of the triggers are active (parents)
    for (const key in individualTriggerDictionary) {
      // make sure parent is triggered or top level as well
      if (
        individualTriggerDictionary[key] === true &&
        (!wholeTriggerDictionary[key] || wholeTriggerDictionary[key]['shown'])
      ) {
        individualTriggerDictionary['shown'] = true;
        pageElement.triggerHidden = false;
        return false;
      }
    }
    pageElement.triggerHidden = true;
    return true;
  } // end triggersFilter

  // true if all hidden
  checkIfAllQuestionsHiddenOnPage(elements: any[]): boolean {
    if (!elements) {
      return true;
    }
    for (const element of elements) {
      if (!element.demographicsOrDoctorHidden && !element.triggerHidden) {
        return false;
      }
    }
    return true;
  }

  nextPage(): void {
    // Disable progression if a required element was unfulfilled
    if (!this.checkRequired(this.survey.pages[this.currentPage].elements)) {
      return;
    }

    if (this.isLastPage) {
      return;
    }

    this.currentPage += 1;
    this.updateCurrentPage.emit(this.currentPage);

    // If this page is empty keep paginating
    this.setTriggersPage(this.survey.pages[this.currentPage].elements);

    // check if empty doctor question page
    if (
      this.checkIfAllQuestionsHiddenOnPage(
        this.survey.pages[this.currentPage].elements
      )
    ) {
      this.nextPage();
    }

    this.scrollTop();
    // this stops the ads from resetting or changing before next page is loaded
    this.adIndexes = [-1, -1, -1];
    setTimeout(() => {
      // TODO use a promise instead of a timeout
      this.getShownElements(this.survey.pages[this.currentPage].elements, true);
    }, 1000);
  }

  previousPage(): void {
    this.currentPage -= 1;
    this.updateCurrentPage.emit(this.currentPage);

    // Set triggers for this page
    this.setTriggersPage(this.survey.pages[this.currentPage].elements);

    // If this page is empty keep paginating
    if (
      this.checkIfAllQuestionsHiddenOnPage(
        this.survey.pages[this.currentPage].elements
      ) &&
      !this.isFirstPage
    ) {
      this.previousPage();
    }

    this.scrollTop();
    this.getShownElements(this.survey.pages[this.currentPage].elements, true);
  }

  scrollTop(): void {
    if (document.getElementById('surveyTitle')) {
      document.getElementById('surveyTitle').scrollIntoView(true);
    }
    if (document.getElementById('topOfLaunch')) {
      document.getElementById('topOfLaunch').scrollIntoView(true);
    }
    // use for doctor Qs
    if (document.getElementById('topOfNotes')) {
      document.getElementById('topOfNotes').scrollIntoView(true);
    }
    // use for questionnaire after booking appt
    if (document.getElementById('topOfBookedApptQ')) {
      document.getElementById('topOfBookedApptQ').scrollIntoView(true);
    }
    window.scrollTo(0, 0);
  }

  childSubmitSurvey(append = false): void {
    // Disable progression if a required element was unfulfilled
    if (!this.checkRequired(this.survey.pages[this.currentPage].elements)) {
      return;
    }

    if (append) {
      this.submitSurvey.emit('append');
    } else {
      this.submitSurvey.emit('complete');
    }
  }

  // reset recreated the adindexes array, dont want to always reset
  // because it reloads the ads, so ads that past may reload and never be seen
  getShownElements(elements: any[], reset = false): void {
    // dont show ads if any of these true
    if (
      this.quickView ||
      !this.showAds ||
      this.preview ||
      this.schedulerLogin ||
      this.doctorCompleting ||
      this.tabletMode === 'true'
    ) {
      return;
    }

    const shownIndices = elements
      .filter(
        (element) =>
          /* not hidden */ !element.demographicsOrDoctorHidden &&
          !element.triggerHidden &&
          /* not survey */ !(
            !!element.question && element.question.type === 'survey'
          )
      )
      .map((element) => elements.indexOf(element));

    // reset allows overwriting an ad index & recreates the array
    // no reset makes sure previous ad indexes don't change so it won't refresh ads
    // but you can still append a new ad if the space comes available
    if (reset) {
      // first index is after second question shown
      if (shownIndices[1]) {
        this.adIndexes = [shownIndices[1]];
      }
      if (shownIndices.length >= 6) {
        this.adIndexes.push(
          shownIndices[Math.floor((shownIndices.length * 4) / 5)]
        );
      }
      if (shownIndices.length >= 11) {
        this.adIndexes.push(
          shownIndices[Math.floor((shownIndices.length * 2) / 5)]
        );
      }
    } else {
      if (this.adIndexes.length === 0 && shownIndices[1]) {
        this.adIndexes.push(shownIndices[1]);
      }
      // add 2nd and 3rd ads
      if (this.adIndexes.length === 1 && shownIndices.length >= 11) {
        this.adIndexes.push(
          shownIndices[Math.floor((shownIndices.length * 4) / 5)]
        );
        this.adIndexes.push(
          shownIndices[Math.floor((shownIndices.length * 3) / 5)]
        );
        // just add second ad, second last spot so doesn't go behind where pt is filling out
      } else if (this.adIndexes.length === 1 && shownIndices.length >= 6) {
        this.adIndexes.push(shownIndices[shownIndices.length - 2]);
        // just add third ad, second last spot so doesn't go behind where pt is filling out
      } else if (this.adIndexes.length === 2 && shownIndices.length >= 11) {
        this.adIndexes.push(shownIndices[shownIndices.length - 2]);
      }
      // clear third spot if placed same as second spot
      if (this.adIndexes[1] === this.adIndexes[2]) {
        this.adIndexes.splice(2, 1);
      }
    } // end if-else
  } // end func
} // end component
