<insig-health-login-container *ngIf="showLoginForm; else survey">
  <insig-health-login
    [disableClinicianRegistrationAndLogin]="true"
    (loginOutput)="handleLogin()"
  ></insig-health-login>
</insig-health-login-container>

<ng-template #survey>
  <router-outlet></router-outlet>
</ng-template>
