import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit, inject } from '@angular/core';
import { GooglePlaceService } from '@insig-health/services/google-place/google-place.service';

interface MapSettings {
  lat?: number;
  lng?: number;
}

interface PatientAddress {
  address?: string;
  lat?: number;
  lng?: number;
  locationObj?: google.maps.places.PlaceResult;
}

@Component({
  selector: 'insig-referral-search',
  templateUrl: './insig-referral-search.component.html',
})
export class InsigReferralSearchComponent implements AfterViewInit {
  private readonly googlePlaceService = inject(GooglePlaceService);
  @Input() title: string | undefined;
  @Input() doctorMode = false;

  @ViewChild('searchAddress')
  public addressElementRef: ElementRef | undefined;
  @Output() updateAddresses = new EventEmitter();
  public searchValue: string | undefined;

  private _mapSettings: MapSettings = {};
  @Output() mapSettingsChange = new EventEmitter<MapSettings>();
  set mapSettings(val: MapSettings) {
    this._mapSettings = val;
    this.mapSettingsChange.emit(this._mapSettings);
  }

  @Input() get mapSettings() {
    return this._mapSettings;
  }

  private _patientAddress: PatientAddress | undefined;
  @Output() patientAddressChange = new EventEmitter<PatientAddress | undefined>();
  set patientAddress(val: PatientAddress | undefined) {
    this._patientAddress = val;
    this.patientAddressChange.emit(this._patientAddress);
  }

  @Input() get patientAddress() {
    return this._patientAddress;
  }

  ngAfterViewInit(): void {
    const addressInput = this.addressElementRef?.nativeElement;
    if (addressInput) {
      this.googlePlaceService.getPlaceResultObservable(addressInput).subscribe((placeResult) => {
        this.patientAddress = {};
        this.patientAddress.address = placeResult['formatted_address'];
  
        if (placeResult.geometry) {
          this.patientAddress.lat = placeResult.geometry.location.lat();
          this.patientAddress.lng = placeResult.geometry.location.lng();
        } else if (placeResult.name) {
          this.patientAddress.address = placeResult.name;
        }
  
        // delete unnecessary data
        delete placeResult.geometry;
        delete placeResult.html_attributions;
        delete placeResult.adr_address;
        delete placeResult.types;
        delete placeResult.address_components;
  
        this.patientAddress.locationObj = placeResult;
        this.mapSettings.lat = this.patientAddress.lat;
        this.mapSettings.lng = this.patientAddress.lng;
  
        this.updateAddresses.emit();
      });
    }
  }
}
