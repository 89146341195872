import { Injectable, NgZone, inject } from '@angular/core';
import $ from 'jquery';

import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocationService, Region } from '../location/location.service';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  private readonly cookieService = inject(CookieService);
      private _ngZone = inject(NgZone);
  private readonly locationService = inject(LocationService);
  private region: { countryAbbreviation: string; } | undefined;
  private _languageList = [
    'English',
    'French',
    'Spanish',
    'Afrikaans',
    'Albanian',
    'Amharic',
    'Arabic',
    'Armenian',
    'Azerbaijani',
    'Bengali',
    'Belarusian',
    'Bosnian',
    'Bulgarian',
    'Cebuano',
    'Chinese (Simplified)',
    'Croatian',
    'Czech',
    'Danish',
    'Estonian',
    'Finnish',
    'Filipino',
    'Georgian',
    'German',
    'Greek',
    'Gujarati',
    'Haitian Creole',
    'Hausa',
    'Hawaiian',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Indonesian',
    'Irish',
    'Italian',
    'Japanese',
    'Kannada',
    'Kazakh',
    'Khmer',
    'Kyrgyz',
    'Latvian',
    'Lithuanian',
    'Luxembourgish',
    'Macedonian',
    'Malay',
    'Malayalam',
    'Marathi',
    'Maltese',
    'Maori',
    'Mongolian',
    'Nepali',
    'Norwegian',
    'Pashto',
    'Persian',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Romanian',
    'Russian',
    'Samoan',
    'Scots Gaelic',
    'Serbian',
    'Sesotho',
    'Shona',
    'Sindhi',
    'Sinhala',
    'Slovak',
    'Slovenian',
    'Somali',
    'Swahili',
    'Swedish',
    'Tajik',
    'Tamil',
    'Telugu',
    'Thai',
    'Turkish',
    'Ukrainian',
    'Urdu',
    'Uzbek',
    'Vietnamese',
    'Yoruba',
  ];

  private _currentLanguageSubject = new BehaviorSubject(this.cookieService.get('selectedLanguage') || 'English');

  async getFlagURL(): Promise<string> {
    // get right flag
    const language = this.language;
    if (!this.region) {
      try {
        this.region = await this.locationService.getRegion();
      } catch (error) {
        console.error(error);
        this.region = { countryAbbreviation: 'US' }; // default to US
      }
    }

    let flagSource = 'assets/images/language-flags/' + language + '.png';
    if (
      !!this.region.countryAbbreviation &&
      this.region.countryAbbreviation === 'US' &&
      language === 'English'
    ) {
      flagSource = 'assets/images/language-flags/English-US.png';
    }

    if (
      language === 'Hindi' ||
      language === 'Gujarati' ||
      language === 'Kannada' ||
      language === 'Malayalam' ||
      language === 'Marathi' ||
      language === 'Telugu'
    ) {
      flagSource = 'assets/images/language-flags/India.png';
    } else if (language === 'Tamil' || language === 'Sinhala') {
      flagSource = 'assets/images/language-flags/Srilanka.png';
    } else if (
      language === 'Pashto' ||
      language === 'Urdu' ||
      language === 'Punjabi' ||
      language === 'Sindhi'
    ) {
      flagSource = 'assets/images/language-flags/Pakistan.png';
    } else if (language === 'Filipino' || language === 'Cebuano') {
      flagSource = 'assets/images/language-flags/Philippines.png';
    }
    return flagSource;
  }

  async getLanguageList(): Promise<string[]> {
    const languageList = this._languageList;
    if (!this.region) {
      try {
        this.region = await this.locationService.getRegion();
      } catch (error) {
        this.region = { countryAbbreviation: 'US' }; // default to US
      }
    }

    if (
      !!this.region &&
      !!this.region.countryAbbreviation &&
      this.region.countryAbbreviation === 'US'
    ) {
      languageList[0] = 'English-US';
    }
    return languageList;
  }

  getRawLanguageList(): string[] {
    return this._languageList;
  }

  public get language(): string {
    const frame = $('.goog-te-menu-frame:first');
    const languageSpan = frame
      .contents()
      .find('.goog-te-menu2-item-selected span.text')
      .get(0);
    if (!!languageSpan && languageSpan.innerText !== 'Select Language') {
      return languageSpan.innerText;
    } else {
      return this.cookieService.get('selectedLanguage') || 'English';
    }
  }
  public set language(value: string) {
    this.changeLanguage(value);
  }

  /**
   * Change the language of the page via translation from English; automatically reloads to apply changes
   * @param {string} language The name of the language to change to; see this.languageList()
   */
  changeLanguage(language: string): void {
    if (language === 'English-US') {
      language = 'English';
    }
    this._ngZone.runOutsideAngular(() => {
      if (language.toLowerCase() !== 'english') {
        const legacyFrame = $('.goog-te-menu-frame:eq(0)');
        const isLegacyGoogleTranslate = !!legacyFrame.get(0);

        const newFrame = $('iframe.skiptranslate');
        const isNewGoogleTranslate = !!newFrame.get(0);

        let languageButton;
        if (isLegacyGoogleTranslate) {
          languageButton = legacyFrame
            .contents()
            .find(`.goog-te-menu2-item span.text:contains(${language})`)
            .get(0);
        } else if (isNewGoogleTranslate) {
          languageButton = newFrame
            .contents()
            .find(`span.text:contains(${language})`)
            .get(0);
        } else {
          console.warn('No google translate iframe found');
          return;
        }

        if (languageButton === undefined) {
          console.warn(
            'Error changing language, probably changing to language already being used',
          );
          return;
        }

        this.cookieService.set(
          'selectedLanguage',
          language,
          undefined,
          undefined,
          undefined,
          false,
          'Lax',
        );
        languageButton.click();

        this._currentLanguageSubject.next(language);
      } else {
        const legacyFrame = $('.goog-te-banner-frame:eq(0)');
        const isLegacyGoogleTranslate = !!legacyFrame.get(0);

        const newFrame = $('iframe.skiptranslate');
        const isNewGoogleTranslate = !!newFrame.get(0);

        let showOriginalButton;
        if (isLegacyGoogleTranslate) {
          showOriginalButton = legacyFrame
            .contents()
            .find('.goog-te-button button:contains("Show original")')
            .get(0);
        } else if (isNewGoogleTranslate) {
          showOriginalButton = newFrame
            .contents()
            .find('button:contains("Show original")')
            .get(0);
        } else {
          console.warn('No google translate iframe found');
          return;
        }

        if (!showOriginalButton) {
          console.warn(
            'Error changing language, probably changing to language already being used',
          );
          return;
        }

        this.cookieService.set(
          'selectedLanguage',
          language,
          undefined,
          undefined,
          undefined,
          false,
          'Lax',
        );
        showOriginalButton.click();

        this._currentLanguageSubject.next(language);
      }
    });
  }

  getCurrentLanguage(): Observable<string> {
    return this._currentLanguageSubject.asObservable();
  }
}
