<div class="box box-default">
  <div class="d-flex align-items-center justify-content-center">
    <button mat-icon-button (click)="decreaseMonth()">
      <i class="fa fa-caret-left" aria-hidden="true"></i>
    </button>
    <h3 class="mx-3">{{ viewDate | calendarDate: 'monthViewTitle':'en' }}</h3>
    <button mat-icon-button (click)="increaseMonth()">
      <i class="fa fa-caret-right" aria-hidden="true"></i>
    </button>
  </div>
  <div class="box-body">
    <div myECharts [EChartsOptions]="chartData" style="height: 450px;"></div>
  </div>
</div>

<div class="row text-center">
  <h3 class="mb-1 ml-auto mr-auto">Monthly Totals</h3>
</div>

<div class="divider divider-grey"></div>

<div class="row">
  <!-- Stats -->

  <div class="col-xl-3 col-sm-6" *ngIf="!isPatientAdmin">
    <div class="box box-default">
      <div class="box-top">
        <i class="material-icons color-info">attach_money</i>
      </div>
      <div class="box-info">
        <span>Net Revenue</span>
      </div>
      <div class="box-bottom">
        <span>${{ this.netRevenue | number }}</span>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-sm-6">
    <div class="box box-default">
      <div class="box-top">
        <i class="material-icons color-success">date_range</i>
      </div>
      <div class="box-info">
        <span>Bookings</span>
      </div>
      <div class="box-bottom">
        <span>{{ totalBookings }}</span>
        <!-- <span>{{ !!isPatientAdmin ? totalBookings : (surveysStats.length > 0 ? surveysStats[0].count : 'None Yet') }}</span> -->
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-sm-6">
    <div class="box box-default">
      <div class="box-top">
        <i class="material-icons color-info">supervisor_account</i>
      </div>
      <div class="box-info">
        <span>Appointments</span>
      </div>
      <div class="box-bottom">
        <span>{{ totalAppointments }}</span>
        <!-- <span>{{ !!isPatientAdmin ? totalBookings : (surveysStats.length > 0 ? surveysStats[0].count : 'None Yet') }}</span> -->
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-sm-6" *ngIf="!isPatientAdmin">
    <div class="box box-default">
      <div class="box-top">
        <i class="material-icons color-success">attach_money</i>
      </div>
      <div class="box-info">
        <span>Total Revenue</span>
      </div>
      <div class="box-bottom">
        <span>${{ this.totalRevenue | number }}</span>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-sm-6" *ngIf="!isPatientAdmin">
    <div class="box box-default">
      <div class="box-top">
        <i class="material-icons color-info">video_call</i>
      </div>
      <div class="box-info">
        <span>Average Video Call</span>
      </div>
      <div class="box-bottom">
        <span
          >{{
            averageVideoCallLength
              ? (averageVideoCallLength / 1000 | number: '1.0-0')
              : 0
          }}s</span
        >
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-sm-6" *ngIf="!isPatientAdmin">
    <div class="box box-default">
      <div class="box-top">
        <i class="material-icons color-danger">phone_in_talk</i>
      </div>
      <div class="box-info">
        <span>Average Phone Call</span>
      </div>
      <div class="box-bottom">
        <span
          >{{
            averagePhoneCallLength
              ? (averagePhoneCallLength / 1000 | number: '1.0-0')
              : 0
          }}s</span
        >
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-sm-6" *ngIf="!isPatientAdmin">
    <div class="box box-default">
      <div class="box-top">
        <i class="material-icons color-danger">money_off</i>
      </div>
      <div class="box-info">
        <span>Cancelled</span>
      </div>
      <div class="box-bottom">
        <span>{{ totalCancelled }}</span>
        <!-- <span>{{(!!isPatientAdmin ? totalPayments :  (bookingsStats.length > 0 && paymentsStats[0]?.meta) ? (paymentsStats[0].meta.price | currency) : 'None Yet')}}</span> -->
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-sm-6" *ngIf="!isPatientAdmin">
    <div class="box box-default">
      <div class="box-top">
        <i class="material-icons color-warning">hourglass_empty</i>
      </div>
      <div class="box-info">
        <span>No Shows</span>
      </div>
      <div class="box-bottom">
        <span>{{ totalNoShows }}</span>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-sm-6" *ngIf="!isPatientAdmin">
    <div class="box box-default">
      <div class="box-top">
        <i class="material-icons color-info">videocam</i>
      </div>
      <div class="box-info">
        <span>Video Appointments</span>
      </div>
      <div class="box-bottom">
        <span>{{ totalVideoAppts }}</span>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-sm-6" *ngIf="!isPatientAdmin">
    <div class="box box-default">
      <div class="box-top">
        <i class="material-icons color-danger">phone</i>
      </div>
      <div class="box-info">
        <span>Phone Appointments</span>
      </div>
      <div class="box-bottom">
        <span>{{ totalPhoneAppts }}</span>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-sm-6" *ngIf="!isPatientAdmin">
    <div class="box box-default">
      <div class="box-top">
        <i class="material-icons color-warning">business</i>
      </div>
      <div class="box-info">
        <span>In-Clinic Appointments</span>
      </div>
      <div class="box-bottom">
        <span>{{ totalClinicAppts }}</span>
      </div>
    </div>
  </div>

  <!-- end stats -->
</div>
