<h2 class="text-center">{{ status }}</h2>

{{ loginError }}

<ng-container *ngIf="shouldShowLoginScreen">
  <insig-health-login
    [loggingInForVirtualVisit]="false"
    (loginOutput)="redirect($event)"
  >
  </insig-health-login>
</ng-container>
