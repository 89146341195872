import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { JAVA_BACKEND_ENDPOINT } from '@insig-health/config/config';
import { from, lastValueFrom, Observable } from 'rxjs';
import { AwsWafCaptchaService } from './aws-waf-captcha.service';

@Injectable({
  providedIn: 'root'
})
export class AwsWafCaptchaInterceptor implements HttpInterceptor {
  private readonly awsWafCaptchaService = inject(AwsWafCaptchaService);
  private static readonly PATIENT_REGISTRATION_PATH_REGEX = /^(?:\/patient-registration|\/v2\/patient-registration)$/;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isPatientRegistrationEndpoint(request)) {
      if (this.awsWafCaptchaService.getIntegration().hasToken()) {
        return from(this.handle(request, next));
      } else {
        return next.handle(request);
      }
    } else {
      return next.handle(request);
    }
  }

  private async handle<T>(request: HttpRequest<T>, next: HttpHandler): Promise<HttpEvent<T>> {
    const token = await this.awsWafCaptchaService.getIntegration().getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          'x-aws-waf-token': token
        }
      });
    }
    return lastValueFrom(next.handle(request));
  }
  
  private isPatientRegistrationEndpoint(request: HttpRequest<any>): boolean {
    try {
      const url = new URL(request.url);
      const protocol = url.protocol;
      const hostname = url.hostname;
      const path = url.pathname;

      return `${protocol}//${hostname}/` === JAVA_BACKEND_ENDPOINT &&
        AwsWafCaptchaInterceptor.PATIENT_REGISTRATION_PATH_REGEX.test(path);
    } catch (_error) {
      return false;
    }
  }
}
