import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'insig-tali-login',
  templateUrl: './tali-login.component.html',
})
export class TaliLoginComponent implements OnInit {
  private readonly router = inject(Router);
  private static readonly REDIRECT_DURATION_MS = 1000;
  private static readonly REDIRECT_PATH = '/auth/login';

  ngOnInit(): void {
    setTimeout(() => {      
      this.router.navigate([TaliLoginComponent.REDIRECT_PATH]);
    }, TaliLoginComponent.REDIRECT_DURATION_MS);
  }
}
