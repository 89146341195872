import { take } from 'rxjs/operators';
import { Injectable, inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService, DoctorLoginStatusResponse } from '@insig-health/api/auth-api';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';

export const DELETE_PROXIMITY_COOKIES_QUERY_PARAM = 'deleteProximityCookiesRedirectUrl';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly authenticationService = inject(AuthenticationService);
  public static readonly BEARER_PAYLOAD_REGEX = new RegExp(/.*\.(?<payload>..*)\..*/);

  private _isLoggedIntoSpring$ = new BehaviorSubject<boolean>(false);

  async getFirebaseCustomToken(): Promise<string> {
    const response = await firstValueFrom(this.authenticationService.getFirebaseCustomToken());
    const customToken = response.custom_token;
    if (!customToken) {
      throw new Error('Missing custom token from response');
    } else {
      return customToken;
    }
  }

  async isLoggedOutOfSpring(): Promise<boolean> {
    try {
      await this.authenticationService.getFirebaseCustomToken().pipe(take(1)).toPromise();
      return false;
    } catch (error) {
      if ((error as HttpErrorResponse).status === 401) {
        return true;
      } else {
        throw error;
      }
    }
  }

  isLoggedIntoSpring(): Observable<boolean> {
    return this._isLoggedIntoSpring$.asObservable();
  }

  getDoctorLoggedInStatus(): Promise<DoctorLoginStatusResponse> {
    return firstValueFrom(this.authenticationService.getDoctorLoginStatus());
  }
}
