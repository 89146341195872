import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PendingChangesDialogComponent } from 'insig-app/dialogs/pending-changes-dialog/pending-changes-dialog.component';
import { ProfileComponent, UserDataFormState } from 'insig-app/profile/profile.component';
import { firstValueFrom } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class IsProfileSavedGuard {
  private readonly dialog = inject(MatDialog);

  async canDeactivate(): Promise<boolean> {
    if (ProfileComponent.userDataFormState === UserDataFormState.DIRTY) {
      const dialogRef = this.dialog.open(PendingChangesDialogComponent, {
        disableClose: true,
      });
      return firstValueFrom(dialogRef.afterClosed());
    } else {
      return true;
    }
  }
}
