// @ts-strict-ignore
import * as cheerio from 'cheerio';
import Style from '../style/style.class';
import AllowedHtmlTags from '../allowed-html-tags/allowed-html-tags.class';
import Table from '../table/table.class';
import MyString from '../string/my-string.class';
import juice from 'juice';
import { Buffer } from 'buffer';

export default class Rtf {
  public rtfHeaderOpening: any;
  public rtfHeaderContent: any;
  public rtfClosing: any;
  public rtfContentReferences: any;
  public Table: any;
  constructor() {
    this.rtfHeaderOpening =
      '{\\rtf1\\ansi\\deff0{\\fonttbl {\\f0\\fnil\\fcharset0 Calibri;}{\\f1\\fnil\\fcharset2 Symbol;}}-----\\line';
    this.rtfHeaderContent = '';
    this.rtfClosing = '}';
    this.rtfContentReferences = [];
    this.Table = new Table();
  }

  convertHtmlToRtf(html) {
    html = '<html>' + html + '</html>';
    const $ = cheerio.load(juice(html));
    const treeOfTags = $('html').children();

    Array.from(treeOfTags).forEach((tag) => this.readAllChildsInTag(tag));
    return this.buildRtf();
  }

  buildRtf() {
    this.rtfHeaderContent += Style.getRtfColorTable();
    return (
      this.rtfHeaderOpening +
      this.rtfHeaderContent +
      this.getRtfContentReferences() +
      this.rtfClosing
    );
  }

  getRtfContentReferences() {
    let rtfReference = '';
    this.rtfContentReferences.forEach(
      (value) => (rtfReference += value.content)
    );
    return rtfReference;
  }

  // Don't has a test
  readAllChildsInTag(fatherTag) {
    if (fatherTag.children) {
      this.addOpeningTagInRtfCode(fatherTag);
      this.ifExistsAttributesAddAllReferencesInRtfCode(fatherTag.attribs);

      if (fatherTag.name.toLowerCase() === 'table') {
        this.Table.setAmountOfColumns(
          this.getAmountOfColumnThroughOfFirstChildOfTbodyTag(
            fatherTag.children
          )
        );
      }

      if (fatherTag.name.toLowerCase() === 'tr') {
        this.addReferenceTagInRtfCode(
          this.Table.buildCellsLengthOfEachColumn()
        );
      }

      fatherTag.children.forEach((child) => {
        if (child.type !== 'text') {
          this.readAllChildsInTag(child);
        } else {
          this.addContentOfTagInRtfCode(child.data);
        }
      });
    }
    this.addClosingFatherTagInRtfCode(fatherTag.name);
  }

  getAmountOfColumnThroughOfFirstChildOfTbodyTag(tableChildren) {
    let count = 0;
    const tbodyIndex = tableChildren.findIndex(
      (value) => value.name === 'tbody'
    );

    if (tbodyIndex < 0) {
      return 0;
    }

    for (const child of tableChildren[tbodyIndex].children) {
      if (child.type !== 'text') {
        child.children.forEach((child) => {
          if (child.type !== 'text') {
            count++;
          }
        });
        break;
      }
    }
    return count;
  }

  ifExistsAttributesAddAllReferencesInRtfCode(attributes) {
    if (attributes.style) {
      this.addReferenceTagInRtfCode(
        Style.getRtfReferencesInStyleProperty(attributes.style)
      );
    }
    if (attributes.align) {
      this.addReferenceTagInRtfCode(
        Style.getRtfAlignmentReference(attributes.align)
      );
    }
  }

  addReferenceTagInRtfCode(referenceTag) {
    if (referenceTag) {
      this.rtfContentReferences.push({ content: referenceTag, tag: true });
    }
  }

  addOpeningTagInRtfCode(tag) {
    if (tag.name === 'img') {
      try {
        let tempString: string;
        let hexString: string;
        if (tag.attribs.src.indexOf('data:image/png;base64,') !== -1) {
          tempString = tag.attribs.src.split('data:image/png;base64,')[1];
          hexString =
            '\\pngblip\\picw6000\\pich3000 ' +
            Buffer.from(tempString, 'base64').toString('hex');
          this.addReferenceTagInRtfCode(
            AllowedHtmlTags.getRtfReferenceTag(tag.name) + hexString
          );
        } else if (tag.attribs.src.indexOf('data:image/jpeg;base64,') !== -1) {
          tempString = tag.attribs.src.split('data:image/jpeg;base64,')[1];
          hexString =
            '\\jpegblip\\picw6000\\pich3000 ' +
            Buffer.from(tempString, 'base64').toString('hex');
          this.addReferenceTagInRtfCode(
            AllowedHtmlTags.getRtfReferenceTag(tag.name) + hexString
          );
        } else {
          // no base64 png or jpg, probably a download url SRC
          // console.log("*** IMAGE NOT PNG OR JPG (probably URL):")
          // console.log(tag);
          console.log(AllowedHtmlTags.getRtfReferenceTag(tag.name));
          this.addReferenceTagInRtfCode(
            AllowedHtmlTags.getRtfReferenceTag(tag.name)
          );
        }
      } catch (error) {
        console.log(error);
        console.log(tag);
        this.addReferenceTagInRtfCode(
          AllowedHtmlTags.getRtfReferenceTag(tag.name)
        );
      }
    } else {
      this.addReferenceTagInRtfCode(
        AllowedHtmlTags.getRtfReferenceTag(tag.name)
      );
    }
  }

  addClosingFatherTagInRtfCode(closingFatherTag) {
    this.addReferenceTagInRtfCode(
      AllowedHtmlTags.getRtfReferenceTag(`/${closingFatherTag}`)
    );
  }

  addContentOfTagInRtfCode(contentOfTag) {
    contentOfTag = MyString.removeCharacterOfEscapeInAllString(
      contentOfTag,
      '\n\t'
    );

    if (!!contentOfTag && !MyString.hasOnlyWhiteSpace(contentOfTag)) {
      this.rtfContentReferences.push({
        content: this.addSpaceAroundString(contentOfTag.trim()),
        tag: false,
      });
    }
  }

  addSpaceAroundString(contentOfTag) {
    return ` ${contentOfTag} `;
  }
}
