<div class="text-center">
  <!-- <rating [(ngModel)]="fakeModel"></rating> -->
  <mat-slider
    discrete
    showTickMarks
    class="full-width"
    [max]="5"
    [min]="0"
  >
    <input
      matSliderThumb
      [(ngModel)]="fakeModel"
    />
  </mat-slider>
</div>
