<mat-card>
  <h1 mat-dialog-title>Confirm Folder Deletion</h1>

  <mat-dialog-content>
    <div class="text-center">
      <h4>
        Are you sure you want to delete this folder and all of its contents?
      </h4>
      <h6>This action is irreversible.</h6>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="d-block">
    <button mat-button (click)="dialogRef.close(false)">No</button>
    <button mat-raised-button color="primary" (click)="dialogRef.close(true)">
      Yes
    </button>
  </mat-dialog-actions>
</mat-card>
