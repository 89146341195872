import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'insig-ui-line-clamped-text',
  templateUrl: './insig-line-clamped-text.component.html',
  styleUrls: ['./insig-line-clamped-text.component.scss'],
})
export class InsigLineClampedTextComponent {
  @Input() lineClamp = 0;
  @Output() readMoreClicked = new EventEmitter<void>();

  isElementTextClamped(element: HTMLElement): boolean {
    const { scrollHeight, clientHeight } = element;
    return scrollHeight > clientHeight;
  }

  handleReadMoreClicked(event: Event): void {
    event.stopPropagation();
    this.readMoreClicked.emit();
  }
}
