import { Injectable } from '@angular/core';
import { Province as ServerProvince } from '@insig-health/api/doctor-booking-flow-api-v1';

export enum Province {
  AB = 'AB',
  BC = 'BC',
  MB = 'MB',
  NB = 'NB',
  NL = 'NL',
  NT = 'NT',
  NS = 'NS',
  NU = 'NU',
  ON = 'ON',
  PE = 'PE',
  QC = 'QC',
  SK = 'SK',
  YT = 'YT',
}

@Injectable({
  providedIn: 'root',
})
export class ProvinceService {

  private PUBLIC_INSURED_PROVINCES = [
    Province.AB,
    Province.BC,
    Province.ON,
    Province.QC,
  ];

  isProvinceAvailableForPublicAppointments(province: Province): boolean {
    return this.PUBLIC_INSURED_PROVINCES.includes(province);
  }

  getProvinceName(province: Province): string {
    switch (province) {
      case Province.BC: return 'British Columbia';
      case Province.AB: return 'Alberta';
      case Province.SK: return 'Saskatchewan';
      case Province.MB: return 'Manitoba';
      case Province.ON: return 'Ontario';
      case Province.QC: return 'Quebec';
      case Province.NL: return 'Newfoundland and Labrador';
      case Province.NB: return 'New Brunswick';
      case Province.NS: return 'Nova Scotia';
      case Province.PE: return 'Prince Edward Island';
      case Province.YT: return 'Yukon';
      case Province.NT: return 'Northwest Territories';
      case Province.NU: return 'Nunavut';
      default: throw new Error(`Unrecognized province: ${province}`);
    }
  }

  convertFromServerProvince(serverProvince: ServerProvince): Province {
    switch (serverProvince) {
      case ServerProvince.Bc: return Province.BC;
      case ServerProvince.Ab: return Province.AB;
      case ServerProvince.Sk: return Province.SK;
      case ServerProvince.Mb: return Province.MB;
      case ServerProvince.On: return Province.ON;
      case ServerProvince.Qc: return Province.QC;
      case ServerProvince.Nl: return Province.NL;
      case ServerProvince.Nb: return Province.NB;
      case ServerProvince.Ns: return Province.NS;
      case ServerProvince.Pe: return Province.PE;
      case ServerProvince.Yt: return Province.YT;
      case ServerProvince.Nt: return Province.NT;
      case ServerProvince.Nu: return Province.NU;
      default: throw new Error(`Unrecognized province: ${serverProvince}`);
    }
  }

  convertToServerProvince(province: Province): ServerProvince {
    switch (province) {
      case Province.BC: return ServerProvince.Bc;
      case Province.AB: return ServerProvince.Ab;
      case Province.SK: return ServerProvince.Sk;
      case Province.MB: return ServerProvince.Mb;
      case Province.ON: return ServerProvince.On;
      case Province.QC: return ServerProvince.Qc;
      case Province.NL: return ServerProvince.Nl;
      case Province.NB: return ServerProvince.Nb;
      case Province.NS: return ServerProvince.Ns;
      case Province.PE: return ServerProvince.Pe;
      case Province.YT: return ServerProvince.Yt;
      case Province.NT: return ServerProvince.Nt;
      case Province.NU: return ServerProvince.Nu;
      default: throw new Error(`Unrecognized province: ${province}`);
    }
  }

  parseQueryParamProvince(provinceString: string): Province {
    provinceString = provinceString.toUpperCase();
    switch (provinceString) {
      case 'BC': return Province.BC;
      case 'AB': return Province.AB;
      case 'SK': return Province.SK;
      case 'MB': return Province.MB;
      case 'ON': return Province.ON;
      case 'QC': return Province.QC;
      case 'NL': return Province.NL;
      case 'NB': return Province.NB;
      case 'NS': return Province.NS;
      case 'PE': return Province.PE;
      case 'YT': return Province.YT;
      case 'NT': return Province.NT;
      case 'NU': return Province.NU;
      default: throw new Error(`Unrecognized province: ${provinceString}`);
    }
  }
}
