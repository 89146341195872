<mat-card>
  <h1 matDialogTitle>Select a Practitioner</h1>
  <div mat-dialog-content>
    <div class="col-12">
      <article class="article">
        <div class="box box-transparent">
          <div class="box-body">
            <div class="row">
              <div class="col-12">
                <mat-form-field class="full-width">
                  <span matPrefix>
                    <mat-icon class="notranslate material-icons"
                      >search</mat-icon
                    >&nbsp;
                  </span>
                  <mat-label>search by surname</mat-label>
                  <input
                    matInput
                    #searchDoctors
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </article>
      <div
        *ngFor="
          let doctor of data.doctorList
            | searchpipe: 'fullName':searchDoctors.value
        "
      >
        <div class="card bg-color-white" (click)="dialogRef.close(doctor)">
          <button mat-button>
            <div class="card-content">
              <span class="card-title">{{ doctor.fullName }}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-card>
