import { Component, inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { DoctorService } from '@insig-health/services/doctor/doctor.service';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/config';

@Component({
  selector: 'change-profile-image-dialog',
  templateUrl: 'change-profile-image-dialog.component.html',
})
export class ChangeProfileImageDialogComponent {
  public static readonly PROFILE_IMAGE_UPDATE_SUCCESS_MESSAGE = 'Profile image updated successfully.';
  public static readonly PROFILE_IMAGE_UPDATE_ERROR_MESSAGE = 'Error updating profile image. Please try again.';

  public readonly data = inject<{ uid: string; imageUrl?: string; }>(MAT_DIALOG_DATA);
  private readonly dialogRef = inject(MatDialogRef);
  private readonly snackBar = inject(MatSnackBar);
  private readonly doctorService = inject(DoctorService);
  public userData: any;
  public loading = false;

  async onAdded(fileDropEntries: NgxFileDropEntry[]): Promise<void> {
    const fileDrop = fileDropEntries[0];
    const fileData = await new Promise<File>((resolve) => {
      const fileEntry = fileDrop.fileEntry as FileSystemFileEntry;
      fileEntry.file((file) => {
        resolve(file);
      });
    });

    const fileBlob = new Blob([fileData], { type: fileData.type });
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);

    try {
      this.loading = true;
      const imageUrl = await new Promise<string>((resolve) => {
        reader.onload = () => {
          resolve(reader.result as string);
        };
      });
      await this.doctorService.updateDoctor(this.data.uid, { image: imageUrl });
      this.data.imageUrl = imageUrl;
      this.snackBar.open(ChangeProfileImageDialogComponent.PROFILE_IMAGE_UPDATE_SUCCESS_MESSAGE, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      this.dialogRef.close();
    } catch (error) {
      console.error(error);
      this.snackBar.open(ChangeProfileImageDialogComponent.PROFILE_IMAGE_UPDATE_ERROR_MESSAGE, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
    }

    this.loading = false;
  }

  async removeUserImage(): Promise<void> {
    try {
      await this.doctorService.updateDoctor(this.data.uid, { image: '' });
      this.snackBar.open(ChangeProfileImageDialogComponent.PROFILE_IMAGE_UPDATE_SUCCESS_MESSAGE, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      this.dialogRef.close();
    } catch (error) {
      console.error(error);
      this.snackBar.open(ChangeProfileImageDialogComponent.PROFILE_IMAGE_UPDATE_ERROR_MESSAGE, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
    }
  }
}
