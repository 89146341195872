// @ts-strict-ignore
import {
  Injectable,
  Pipe,
  PipeTransform, inject } from '@angular/core';
import {
  DomSanitizer
} from '@angular/platform-browser'

@Pipe({
  name: 'safeHtml',
})
@Injectable()
export class SafeHtmlPipe implements PipeTransform {
  private readonly sanitized = inject(DomSanitizer);

  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
