import { Component, Output, EventEmitter, OnInit, Input, OnDestroy, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { JAVA_BACKEND_ENDPOINT } from '@insig-health/config/config';
import { GcpIpAuthService } from './gcp-ip-auth.service';
import { firstValueFrom, Subscription } from 'rxjs';
import {
  MultiFactorInfo,
  MultiFactorResolver,
  TotpMultiFactorGenerator,
  UserCredential,
} from 'firebase/auth';
import {
  FirebaseError,
} from '@firebase/util';

enum LoginState {
  NOT_LOGGED_IN,
  LOGGING_IN,
  MFA_VERIFICATION,
}

enum ErrorMessage {
  INVALID_EMAIL_OR_PASSWORD = 'The email or password provided was invalid.',
  INVALID_EMAIL = 'The email provided is not a valid email address.',
  USER_DISABLED = 'Your account has been disabled. Please contact support or a system administrator to regain access.',
  TOO_MANY_REQUESTS = 'Too many failed login attempts. Please try again later.',
  UNSUPPORTED_MFA_FACTOR = 'Unsupported MFA factor. Please contact support.',
  INCORRECT_MFA_VERIFICATION_CODE = 'Incorrect verification code.',
  TOO_MANY_MFA_ATTEMPTS = 'Too many attempts. Please try again later.',
  MUST_LOGIN_AS_PATIENT = 'Please create or sign in with a patient account.',
}

/**
 * Error codes for GCP IP authentication. Some error codes have been left out for security purposes.
 *
 * @link https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithemailandpassword
 * @link https://firebase.google.com/docs/auth/admin/errors
 */
export enum GcpIpErrorCode {
  INVALID_EMAIL = 'auth/invalid-email',
  USER_DISABLED = 'auth/user-disabled',
  TOO_MANY_REQUESTS = 'auth/too-many-requests',
}

@Component({
  selector: 'insig-health-gcp-ip-login-widget',
  templateUrl: './gcp-ip-login-widget.component.html',
  styleUrls: ['./gcp-ip-login-widget.component.scss'],
})
export class GcpIpLoginWidgetComponent implements OnInit, OnDestroy {
  private readonly gcpIpAuthService = inject(GcpIpAuthService);
  @Input() disableSignUp = false;
  @Input() disableClinicianAuthentication = false;
  @Input() disableAutomaticLogin = false;
  @Input() disableCustomToken = false;
  @Input() disableCardBorder = false;
  @Input() isLogoVisible = true;
  @Input() readOnlyEmail: string | undefined = undefined;

  @Output() onLoggedIn = new EventEmitter<void>();
  @Output() onPatientSignUpButtonClicked = new EventEmitter<void>();
  @Output() onClinicianSignUpButtonClicked = new EventEmitter<void>();

  public readonly LoginState = LoginState;
  public readonly PASSWORD_RESET_LINK = `${JAVA_BACKEND_ENDPOINT}password-reset`;
  public static readonly ErrorMessage = ErrorMessage;

  public loginState = LoginState.LOGGING_IN;
  public errorMessage: string | undefined;

  public loginForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });
  public mfaForm = new FormGroup({
    verificationCode: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
  });
  public mfaResolver: MultiFactorResolver | undefined;
  public selectedMultiFactorInfo: MultiFactorInfo | undefined;
  private isLoggedInSubscription: Subscription | undefined;

  async ngOnInit(): Promise<void> {
    let automaticallyRetrievedUserCredential: UserCredential | undefined = undefined;
    if (!this.disableAutomaticLogin && !this.disableCustomToken) {
      try {
        automaticallyRetrievedUserCredential = await this.reauthenticateWithCustomToken();
      } catch (error) {
        // Failed to retrieve the custom token, the user might not have a session on the backend.
      }
    }

    const isLoggedIn = await firstValueFrom(this.gcpIpAuthService.isLoggedIn());
    if (isLoggedIn && !this.disableAutomaticLogin) {
      if (!this.disableCustomToken) {
        const userCredential = automaticallyRetrievedUserCredential ?? await this.reauthenticateWithCustomToken();
        const isCredentialValid = await this.isCustomTokenCredentialValid(userCredential, !this.disableClinicianAuthentication);
        if (!isCredentialValid) {
          await this.handleInvalidDoctorLogin();
          return;
        }
      }
      this.onLoggedIn.emit();
    } else {
      this.loginState = LoginState.NOT_LOGGED_IN;
    }

    if (this.readOnlyEmail) {
      this.loginForm.controls.email.setValue(this.readOnlyEmail);
    }

    this.isLoggedInSubscription = this.gcpIpAuthService.isLoggedIn().subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.loginState = LoginState.NOT_LOGGED_IN;
      } else if (!this.disableAutomaticLogin) {
        this.loginState = LoginState.LOGGING_IN;
      }
    });
  }

  ngOnDestroy(): void {
    this.isLoggedInSubscription?.unsubscribe();
  }

  async handleSignInButtonClicked(): Promise<void> {
    const { email, password } = this.loginForm.value;
    if (!email || !password) {
      return;
    }
    this.errorMessage = undefined;
    await this.login(email, password);
  }

  async handleMfaSignInButtonClicked(
    mfaForm: typeof this.mfaForm,
    mfaResolver: MultiFactorResolver,
    selectedMultiFactorInfo: MultiFactorInfo,
  ): Promise<void> {
    if (!mfaForm.valid) {
      return;
    }
    const { verificationCode } = mfaForm.value;
    if (!verificationCode) {
      return;
    }
    this.loginState = LoginState.LOGGING_IN;

    try {
      this.errorMessage = undefined;
      await this.gcpIpAuthService.signInWithMfa(mfaResolver, selectedMultiFactorInfo.uid, verificationCode);
      if (!this.disableCustomToken) {
        const userCredential = await this.reauthenticateWithCustomToken();
        const isCredentialValid = await this.isCustomTokenCredentialValid(userCredential, !this.disableClinicianAuthentication);
        if (!isCredentialValid) {
          await this.handleInvalidDoctorLogin();
          return;
        }
      }

      this.onLoggedIn.emit();
    } catch (error) {
      if ((error as FirebaseError)?.code === 'auth/quota-exceeded') {
        this.errorMessage = ErrorMessage.TOO_MANY_MFA_ATTEMPTS;
        this.loginState = LoginState.NOT_LOGGED_IN;
        this.mfaForm.reset();
      } else {
        this.errorMessage = ErrorMessage.INCORRECT_MFA_VERIFICATION_CODE;
        this.loginState = LoginState.MFA_VERIFICATION;
        this.mfaForm.reset();
      }
    }
  }

  handleMfaVerificationCodePasted(
    clipboardEvent: ClipboardEvent,
    mfaForm: typeof this.mfaForm,
    mfaResolver: MultiFactorResolver,
    selectedMultiFactorInfo: MultiFactorInfo,
  ): void {
    const target = clipboardEvent.target as HTMLInputElement;
    setTimeout(() => {
      if (target.value.length >= 6) {
        this.handleMfaSignInButtonClicked(mfaForm, mfaResolver, selectedMultiFactorInfo);
      }
    });
  }

  async handleMfaBackButtonClicked(): Promise<void> {
    this.loginForm.reset();
    this.mfaForm.reset();
    this.errorMessage = undefined;
    this.loginState = LoginState.NOT_LOGGED_IN;
  }

  handleClinicianSignUpButtonClicked(): void {
    this.onClinicianSignUpButtonClicked.emit();
  }

  handlePatientSignUpButtonClicked(): void {
    this.onPatientSignUpButtonClicked.emit();
  }

  private async login(email: string, password: string): Promise<void> {
    this.loginState = LoginState.LOGGING_IN;
    try {
      const { mfaResolver } = await this.gcpIpAuthService.signIn(email, password);
      if (mfaResolver) {
        this.handleMfaRequired(mfaResolver);
        return;
      } else {
        if (!this.disableCustomToken) {
          const userCredential = await this.reauthenticateWithCustomToken();
          const isCredentialValid = await this.isCustomTokenCredentialValid(userCredential, !this.disableClinicianAuthentication);
          if (!isCredentialValid) {
            await this.handleInvalidDoctorLogin();
            return;
          }
        }
        this.onLoggedIn.emit();
      }
    } catch (error) {
      this.errorMessage = this.getErrorMessageByGcpIpErrorCode((error as FirebaseError).code);
      this.loginState = LoginState.NOT_LOGGED_IN;
    }
  }

  private handleMfaRequired(resolver: MultiFactorResolver): void {
    this.mfaResolver = resolver;
    const totpFactors = resolver.hints.filter((hint) => {
      return hint.factorId === TotpMultiFactorGenerator.FACTOR_ID;
    });
    if (totpFactors.length !== 0) {
      this.handleSingleMfaFactor(resolver);
    } else {
      this.handleUnsupportedMfaFactor();
    }
  }

  private handleSingleMfaFactor(resolver: MultiFactorResolver): void {
    this.selectedMultiFactorInfo = resolver.hints[0];
    this.loginState = LoginState.MFA_VERIFICATION;
  }

  private handleUnsupportedMfaFactor(): void {
    this.errorMessage = ErrorMessage.UNSUPPORTED_MFA_FACTOR;
    this.loginState = LoginState.NOT_LOGGED_IN;
  }

  private async reauthenticateWithCustomToken(): Promise<UserCredential> {
    const customToken = await this.gcpIpAuthService.getFirebaseCustomToken();
    return this.gcpIpAuthService.signInWithCustomToken(customToken);
  }

  private async isCustomTokenCredentialValid(userCredential: UserCredential, isClinicianLoginAllowed: boolean): Promise<boolean> {
    if (!isClinicianLoginAllowed) {
      const isDoctor = await this.gcpIpAuthService.isUserDoctor(userCredential.user);
      return !isDoctor;
    } else {
      return true;
    }
  }

  private async handleInvalidDoctorLogin(): Promise<void> {
    await this.gcpIpAuthService.signOut();
    this.loginState = LoginState.NOT_LOGGED_IN;
    this.errorMessage = ErrorMessage.MUST_LOGIN_AS_PATIENT;
    this.loginForm.reset();
    this.mfaForm.reset();
  }

  private getErrorMessageByGcpIpErrorCode(errorCode: string): ErrorMessage {
    switch (errorCode) {
      case GcpIpErrorCode.INVALID_EMAIL: {
        return ErrorMessage.INVALID_EMAIL;
      }

      case GcpIpErrorCode.TOO_MANY_REQUESTS: {
        return ErrorMessage.TOO_MANY_REQUESTS;
      }

      case GcpIpErrorCode.USER_DISABLED: {
        return ErrorMessage.USER_DISABLED;
      }

      default: {
        return ErrorMessage.INVALID_EMAIL_OR_PASSWORD;
      }
    }
  }
}
