// @ts-strict-ignore
import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AWSCLOUDFUNCTIONSENDPOINT } from '@insig-health/config/config';

@Injectable()
export class GoogleMapService {
  private readonly http = inject(HttpClient);
  private geocodeAddressURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'google/geocodeAddress';

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  geocodeAddress(address) {
    return this.http
      .post(
        this.geocodeAddressURL,
        JSON.stringify({
          address,
        }),
        { headers: this.headers }
      )
      .toPromise()
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }
} // end service
