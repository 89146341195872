// @ts-strict-ignore
import { Component, Input, ViewChild, ElementRef, OnInit, AfterViewInit, EventEmitter, OnChanges, SimpleChanges, Output } from '@angular/core';

import { Question } from 'insig-types/surveys';

const DEFAULT_MAX_WIDTH = 500;

@Component({
  selector: 'diagram-question',
  templateUrl: './diagram.component.html',
})
export class DiagramComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();

  @ViewChild('container') public container: ElementRef;
  maxWidth: number;
  
  ngOnInit(): void {
    this.question.response = true;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.maxWidth = this.container?.nativeElement.offsetWidth ?? DEFAULT_MAX_WIDTH;
    }, 0);
  }// end func

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

}// end component
