<mat-card>
  <mat-card-title
    >Add
    {{
      type == 'patient' ? 'Patients' : 'Medical Practitioners'
    }}</mat-card-title
  >
  <mat-card-content>
    <div class="row m-0">
      <div class="col-12">
        <form [formGroup]="addEmailsForm">
          <div class="form-group">
            <mat-chip-listbox class="text-center">
              <mat-chip-option
                *ngFor="let user of selectedUsers; let i = index"
                [selectable]="true"
                [removable]="true"
                (remove)="removeItem(i)"
              >
                {{ user }}
                <mat-icon matChipRemove (click)="removeItem(i)"
                  >cancel</mat-icon
                >
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
          <div class="form-group">
            <mat-form-field class="full-width">
              <mat-label>Enter Emails</mat-label>
              <input
                matInput
                required
                pattern="^.+@.+\..+$"
                formControlName="email"
                (keyup.enter)="onEnter()"
              />
              <mat-error
                >Please enter a valid email (joe@example.com)</mat-error
              >
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </mat-card-content>
  <div class="divider"></div>
  <div class="divider"></div>
  <mat-card-actions>
    <button mat-raised-button (click)="dialogRef.close(false)">Cancel</button>
    <button
      mat-raised-button
      color="primary"
      class="float-right"
      (click)="addNewUsers()"
    >
      Add Users
    </button>
  </mat-card-actions>
</mat-card>
