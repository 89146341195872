<div class="text-center" cdkDropList (cdkDropListDropped)="drop($event)">
  <!-- begin priorityList -->
  <div
    *ngFor="let answer of question.priorityList; let i = index"
    cdkDrag
    [cdkDragData]="answer"
  >
    <div class="w-100 d-flex align-items-center">
      <button
        mat-icon-button
        matTooltip="Drag"
        matTooltipPosition="left"
        cdkDragHandle
      >
        <i class="notranslate fas fa-arrows-alt-v fa-2x"></i>
      </button>
      <mat-form-field class="px-2">
        <input
          required
          matInput
          [ngModel]="answer.value"
          (ngModelChange)="onAnswerChange(i, $event)"
        />
      </mat-form-field>
      <button
        mat-fab
        color="none"
        matTooltip="Remove"
        matTooltipPosition="right"
        (click)="question.priorityList.splice(i, 1)"
      >
        <mat-icon class="notranslate">clear</mat-icon>
      </button>
    </div>
  </div>
  <!-- end priorityList -->
  <button mat-raised-button (click)="addOption(question.priorityList.length)">
    <span>Add Option</span>
  </button>
</div>
