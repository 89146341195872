<div>
  <h2 mat-dialog-title>Update Your Password</h2>

  <mat-dialog-content>
    <ng-container *ngIf="passwordUpdateState === PasswordUpdateState.LOGIN">
      <div class="d-flex flex-column align-items-start gap-2">
        <div>
          Please reauthenticate to continue.
        </div>
        <insig-health-gcp-ip-login-widget
          class="mx-auto"
          [disableSignUp]="true"
          [readOnlyEmail]="email"
          [isLogoVisible]="false"
          [disableAutomaticLogin]="true"
          [disableCustomToken]="true"
          [disableCardBorder]="true"
          (onLoggedIn)="handleLoginSuccess($event)"
        >
        </insig-health-gcp-ip-login-widget>
      </div>
    </ng-container>

    <ng-container *ngIf="passwordUpdateState === PasswordUpdateState.ENTER_NEW_PASSWORD">
      <ng-container *ngTemplateOutlet="changePasswordFormTemplate"></ng-container>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions class="d-flex justify-content-between mt-2">
    <insig-ui-button
      class="mr-auto"
      stylePreset="tertiary"
      [mat-dialog-close]="false"
    >
      Cancel
    </insig-ui-button>
    <insig-ui-button *ngIf="passwordUpdateState === PasswordUpdateState.ENTER_NEW_PASSWORD"
      stylePreset="secondary"
      (click)="handleSaveButtonClick(changePasswordForm)"
      [disabled]="changePasswordForm.invalid"  
    >
      Save
    </insig-ui-button>
  </mat-dialog-actions>
</div>

<ng-template #changePasswordFormTemplate>
  <div>Passwords must have:
    <ul>
      <li>a length of 8 or more characters</li>
      <li>at least 1 upper case letter</li>
      <li>at least 1 lower case letter</li>
      <li>at least 1 number</li>
      <li>at least 1 special character</li>
    </ul>
  </div>
  <form [formGroup]="changePasswordForm">
    <insig-ui-text-input label="New Password" [formControlName]="NEW_PASSWORD_FORM_CONTROL_NAME" isRequired="true" type="password"></insig-ui-text-input>
    <insig-ui-form-error
      [formControlForErrorMessage]="changePasswordForm.controls[NEW_PASSWORD_FORM_CONTROL_NAME]"
      errorText="Password is required."
      errorType="required"
    ></insig-ui-form-error>
    <insig-ui-form-error
      [formControlForErrorMessage]="changePasswordForm.controls[NEW_PASSWORD_FORM_CONTROL_NAME]"
      errorText="Password does not meet requirements."
      errorType="passwordNotValid"
    ></insig-ui-form-error>

    <insig-ui-text-input label="Confirm New Password" [formControlName]="CONFIRM_NEW_PASSWORD_FORM_CONTROL_NAME" isRequired="true" type="password"></insig-ui-text-input>
    <insig-ui-form-error
      [formControlForErrorMessage]="changePasswordForm.controls[CONFIRM_NEW_PASSWORD_FORM_CONTROL_NAME]"
      errorText="Confirm password is required."
      errorType="required"
    ></insig-ui-form-error>

    <insig-ui-form-error
      [formControlForErrorMessage]="changePasswordForm"
      errorText="Passwords must match."
      errorType="controlValuesNotMatching"
    ></insig-ui-form-error>

    <button type="submit" class="d-none" (click)="handleSaveButtonClick(changePasswordForm)"></button>
  </form>
</ng-template>
