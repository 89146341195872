// @ts-strict-ignore
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Question } from 'insig-types/surveys';

@Component({
  selector: 'question-types',
  templateUrl: './question-types.component.html',
  styleUrls: ['question-types.style.css'],
})
export class QuestionTypesComponent implements OnInit, OnDestroy {
  // Import elementID to get top level for triggering
  @Input() companyID = null;
  @Input() elementID = null;
  @Input() pageElement = null;
  @Input() uploadedPDFIDs: any;
  @Input() doctorCompleting = null;
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();
  @Input() triggersDictionary = null;
  @Output() checkTriggersChild = new EventEmitter<string>();
  @Input() morePDFs = null;
  @Input() patientInfo: any;

  checkTriggersParent(event): void {
    this.checkTriggersChild.emit(event);
  }

  ngOnInit(): void {
    if (typeof this.question === 'object' && !this.question.response) {
      this.question = {
        ...this.question,
        response: {},
      };
    }
  }

  ngOnDestroy(): void {
    if (this.pageElement.triggerHidden === true) {
      this.question.response = null;
    }
  }

  handleQuestionResponseChange(question: Question, fieldName: string, fieldValue: string): void {
    const newResponse = {
      ...question.response,
      [fieldName]: fieldValue,
    };

    const newQuestion = {
      ...question,
      response: newResponse,
    };

    this.handleQuestionChange(newQuestion);
  }

  handleQuestionChange(newQuestion: Question): void {
    this.questionChange.emit(newQuestion);
  }

  setQuestionSelectedAnswerId(question: Question, answerId: string): void {
    question.response.selectedAnswer = answerId;
    this.handleQuestionChange(question);
  }
}
