<div class="form-group row">
  <div class="col-2"></div>
  <div class="col-8">
    <p *ngIf="question.offeredAnswers.length == 0">
      *No options added, please ignore this question.
    </p>
    <mat-form-field class="full-width">
      <mat-label>Select an option</mat-label>
      <mat-select
        *ngIf="question.offeredAnswers.length > 0"
        [(ngModel)]="question.response.selectedAnswer"
      >
        <mat-option
          *ngFor="let answer of question.offeredAnswers; let i = index"
          [value]="answer.id"
          (click)="modifyTriggers(i)"
        >
          {{ answer.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-2"></div>
</div>
