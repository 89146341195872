// @ts-strict-ignore
import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as htmlToRTF from './rtf/rtf-gen';

import { AWSCLOUDFUNCTIONSENDPOINT } from '@insig-health/config/config';
import firebase from 'firebase/compat/app';
import 'firebase/storage';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

const OPTIONALPHRASES = ['healthCardNumber', 'address', 'email'];

@Injectable({
  providedIn: 'root',
})
export class InitNoteService {
  private readonly http = inject(HttpClient);
  private readonly firebaseAuthService = inject(FirebaseAuthService);
  private fillPDFURL = AWSCLOUDFUNCTIONSENDPOINT + 'fillPDF/fillPdf';
  private noteLocationsDictionary = {};
  private storageRef: any;
  private specialNoteSections = {
    header: '%%-Header',
    footer: '%%-Footer',
    scoring: 'Scoring Tests',
  };
  private noteLocations = [
    'Triage',
    'Patient Profile',
    'Employment History',
    'Chief Complaint',
    'HPI',
    'Medications',
    'Medication Allergies',
    'Medical Conditions',
    'Review of Systems',
    'Lab Tests',
    'Diagnostic Imaging',
    'Mental Health History',
    'Sexual Health History',
    'Past Medical History',
    'Past Psychiatric History',
    'Past Surgical History',
    'Obstetric History',
    'Family Medical History',
    'Family Psychiatric History',
    'Social History / Risk Factors',
    'Past Personal History',
    'Preventive Health/Screening Activities',
    'Vaccination History',
    'Mental Status Examination',
    'Physical Exam',
    'Impression',
    'Assessment',
    'Recommendations',
    'Plan',
    'Assessment & Plan',
    'Miscellaneous',
    this.specialNoteSections['scoring'],
    this.specialNoteSections['header'],
    this.specialNoteSections['footer'],
  ];

  private cppQuestions = {
    doctors: true,
    conditions: true,
    heightWeight: true,
    medicationAllergies: true,
    surgery: true,
    medications: true,
    yesno: 'custom'
  };

  private cppElements = [
    { type: 'heightWeight', title: 'Height / Weight' },
    { type: 'doctors', title: 'Family Doctor' },
    { type: 'medications', title: 'Medications' },
    { type: 'conditions', title: 'Medical Conditions' },
    { type: 'medicationAllergies', title: 'Allergies' },
    { type: 'surgery', title: 'Past Surgeries' },
    { type: 'textarea', title: 'Additional Information' },
  ];

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
) {
    this.noteLocations.forEach(((noteLocation) => {
      this.noteLocationsDictionary[noteLocation] = true;
    }).bind(this));
    this.storageRef = firebase.storage().ref();
  }

  getNoteLocations() {
    return this.noteLocations.slice();
  }

  getCPPResponses(survey) {
    const cppResponses: any = {};
    survey.pages.forEach((page) => {
      if (!page.elements || !page.elements.length || page.elements.length < 1) {
        return false;
      }
      page.elements.forEach(((element) => {
        if (
          element.question &&
          element.question.type &&
          element.question.response &&
          this.cppQuestions[element.question.type] &&
          element.cpp
        ) {
          if (this.cppQuestions[element.question.type] === 'custom' && element.integrationID) {
            if (element.question.type === 'yesno' && element.question.response && element.question.response.answer) {
              cppResponses[element.integrationID] = element.question.response.answer;
            } else if (Object.keys(element.question.response).length > 0) {
              cppResponses[element.integrationID] = element.question.response;
            }
          } else {
            cppResponses[element.question.type] = element.question.response;
          }
        } else if (
          !!element.question &&
          !!element.question.type &&
          !!element.cpp
        ) {
          if (!cppResponses.custom) {
            cppResponses.custom = {};
          }
          if (!cppResponses.custom[survey.id]) {
            cppResponses.custom[survey.id] = {};
          }

          cppResponses.custom[survey.id][element.question.id] =
            element.question;
        }
      }).bind(this));
    });
    return cppResponses;
  }

  setNote(survey, companyID, patients?: string[], append = false): any {
    survey.integrationResponses = {};
    const formattedResults = this.initNote(survey, append);
    if (append) {
      if (!survey.note) {
        survey.note = '';
      }
      survey.note += formattedResults['note'];
    } else {
      survey.note = formattedResults['note'];
    }
    survey.summary = formattedResults['summary'];
    if (formattedResults['scoring']) {
      survey.scoring = formattedResults['scoring'];
    }
    survey.rtf = formattedResults['rtf'];
    // check if theres are integration answers
    if (Object.keys(survey.integrationResponses).length > 0) {
      this.cleanInvalidIntegrationResponseKeys(survey);
      // if survey pdfID, send PDF create cloud function
      if (survey.pdfID) {
        const pdfPath = this.generateRandomID(32) + '.pdf';
        this.generatePDF(survey, pdfPath, companyID, patients);
        survey.pdfPath = pdfPath;
      }
    } else {
      delete survey.integrationResponses;
    }

    if (!survey.feedback) {
      this.generateFeedback(survey);
    }

    console.log(survey);
    return survey;
  }

  cleanInvalidIntegrationResponseKeys(survey) {
    const cleanIntegrationResponses = {};
    for (const key in survey.integrationResponses) {
      if (survey.integrationResponses[key]) {
        try {
          // remove trailing spaces . # , $ [ ]
          const tempKey = key
            .trim()
            .replace(/\s+/g, '-')
            .replace(/[\.\#\,\$\/\[\]]/g, '');
          cleanIntegrationResponses[tempKey] = survey.integrationResponses[key];
        } catch (err) {
          console.error(err);
        } // end try catch
      }
    } // end for loop
    survey.integrationResponses = cleanIntegrationResponses;
  } // end func

  // add feedback responses to survey object
  generateFeedback(survey) {
    survey.pages.forEach((page) => {
      if (!page.elements || !page.elements.length || page.elements.length < 1) {
        return false;
      }
      page.elements.forEach((element) => {
        if (
          element.id.includes('feedback') &&
          element.question.response &&
          element.question.response.selectedAnswers &&
          element.question.response.selectedAnswers.length > 0
        ) {
          if (!survey.feedback) {
            survey.feedback = [];
          }
          element.question.response.selectedAnswers.forEach((item) => {
            survey.feedback.push({
              title: item,
              selected: false,
            });
          }); // end for each
        } // end if
      }); // end for each element
    }); // end for each page
  } // end func

  htmlToRTF(html, compressed?) {
    let rtf = htmlToRTF.htmlToRTF(this.prepareNoteForRTF(html));
    if (compressed) {
      rtf = rtf
        .split('fs20')
        .join('fs16')
        .split('fs22')
        .join('fs20');
    }
    return rtf;
  }

  initNote(survey, append?) {
    const formmattedResults = {};
    const responses = this.generateResponses(survey);
    const summary = this.generateSummary(responses, survey);
    const scoring = this.generateScoring(survey);

    let note;
    if (append) {
      const docResponses = this.generateResponses(survey, append);
      note = this.generateNote(docResponses, survey, scoring);
    } else {
      note = this.generateNote(responses, survey, scoring);
    }

    formmattedResults['summary'] = summary;
    formmattedResults['note'] = note;
    formmattedResults['scoring'] = scoring;

    const rtf = this.htmlToRTF(note, survey.compressed);
    if (rtf) {
      formmattedResults['rtf'] = rtf;
    } else {
      formmattedResults['rtf'] = null;
    }
    return formmattedResults;
  }

  prepareNoteForRTF(note) {
    if (!note) {
      note = '';
    }

    // *** removed this because it caused formatting issue in EMR - CHS
    // if (compressed) {
    // return note.split('&nbsp;').join(' ').split('✓').join('\\uc0\\u10004');
    // } else {
    return note
      .split('&nbsp;')
      .join(' ')
      .split('<h3>')
      .join('<br><h3>')
      .split('✓')
      .join('\\uc0\\u10004');
    // }
  }

  generateScoring(survey) {
    const QuestionsAndTotalScore = this.getScoringQuestionsAndTotalScore(
      survey
    );
    // if no scoring questions return nothing
    if (!QuestionsAndTotalScore) {
      return false;
    }
    let scoringCharts = '';
    for (const scoredSurvey of Object.keys(QuestionsAndTotalScore)) {
      scoringCharts += this.buildEachScoringChart(
        QuestionsAndTotalScore[scoredSurvey],
        scoredSurvey
      );
    }
    return scoringCharts;
  }

  buildEachScoringChart(QuestionsAndTotalScore, title) {
    if (QuestionsAndTotalScore['questions'].length === 0) {
      return '';
    }
    let totalScore = 0;
    const maxScore = QuestionsAndTotalScore['maxScore'];
    if (!title || title === 'undefined') {
      title = '';
    }

    let chart =
      "<table border='1' bordercolor='#ccc' cellpadding='5' cellspacing='0' style='text-align:center;border-collapse:collapse;width:100%' class='note-table'><tbody><tr><td><b><h4>" +
      title +
      '</h4></b></td><td><b><h4>Answer</h4></b></td><td><b><h4>Score</h4></b></td></tr>';
    QuestionsAndTotalScore['questions'].forEach((question) => {
      let answered = false;
      for (const a in question.offeredAnswers) {
        if (!question.response) {
          continue;
        }
        if (
          question.offeredAnswers[a].id === question.response.selectedAnswer
        ) {
          totalScore += parseInt(question.offeredAnswers[a].score, 10);
          if (question.offeredAnswers[a].flag) {
            chart +=
              "<tr><td style='text-align:left;color:" +
              question.offeredAnswers[a].flag +
              "'><span style='font-family:Georgia,serif;'>" +
              question.text +
              "</span></td><td style='color:" +
              question.offeredAnswers[a].flag +
              "'><span style='font-family:Georgia,serif;'>" +
              question.offeredAnswers[a].value +
              "</span></td><td style='color:" +
              question.offeredAnswers[a].flag +
              "'><span style='font-family:Georgia,serif;'>" +
              question.offeredAnswers[a].score +
              '</span></td></tr>';
          } else {
            chart +=
              "<tr><td style='text-align:left;'><span style='font-family:Georgia,serif'>" +
              question.text +
              '</span></td><td>' +
              question.offeredAnswers[a].value +
              '</td><td>' +
              question.offeredAnswers[a].score +
              '</td></tr>';
          }
          answered = true;
        }
      }
      if (!answered) {
        chart +=
          "<tr><td style='text-align:left;'><span style='font-family:Georgia,serif'>" +
          question.text +
          '</span></td><td></td><td>0</td></tr>';
      }
    });
    chart +=
      "<tr><td style='text-align:right;color:#000000;'><b><h4>Total</h4></b></td><td style='color:#000000;'></td><td style='color:#000000;'><b><h4>" +
      totalScore +
      ' / ' +
      maxScore +
      '</h4></b></td></tr>';
    chart += '</tbody></table>';
    return chart;
  }

  getScoringQuestionsAndTotalScore(survey) {
    const scoringDictionary = {};
    const getQuestionMaxScore = this.getQuestionMaxScore;
    survey.pages.forEach((page) => {
      if (!page.elements || !page.elements.length || page.elements.length < 1) {
        return false;
      }
      page.elements.forEach((element) => {
        if (element.question && element.question.type === 'scoring') {
          if (!scoringDictionary[element.originalSurvey]) {
            scoringDictionary[element.originalSurvey] = {
              questions: [],
              maxScore: 0,
            };
          }
          scoringDictionary[element.originalSurvey].questions.push(
            element.question
          );
          scoringDictionary[
            element.originalSurvey
          ].maxScore += getQuestionMaxScore(element.question);
        }
      });
    });

    // if no scoring questions
    if (Object.keys(scoringDictionary).length === 0) {
      return false;
    }

    return scoringDictionary;
  } // end func

  getQuestionMaxScore(question) {
    let maxScore = 0;
    if (!question.offeredAnswers) {
      return 0;
    }
    question.offeredAnswers.forEach((option) => {
      if (option.score > maxScore) {
        maxScore = parseInt(option.score, 10);
      }
    });
    return maxScore;
  }

  replaceOptionalPhrases(note, patientInfo) {
    for (const item in OPTIONALPHRASES) {
      if (patientInfo[OPTIONALPHRASES[item]]) {
        note = note.replace(
          new RegExp('%%-' + OPTIONALPHRASES[item], 'g'),
          patientInfo[OPTIONALPHRASES[item]]
        );
      } else {
        note = note.replace(
          new RegExp('%%-' + OPTIONALPHRASES[item], 'g'),
          'N/A'
        );
      }
    }
    return note;
  }

  replaceSpecialPhrases(note, survey) {
    const patientInfo = survey.patientInfo;
    const birthday = new Date(
      patientInfo['year'],
      patientInfo['month'] - 1,
      patientInfo['day']
    );
    const birthdayString =
      birthday.toLocaleString('en-us', { month: 'long' }) +
      ' ' +
      birthday.getDate() +
      ', ' +
      birthday.getFullYear();
    const age = this.getAge(birthday);
    note = note.replace(new RegExp('%%-age', 'g'), age);
    note = note.replace(new RegExp('%%-dob', 'g'), birthdayString);
    note = note.replace(new RegExp('%%-day', 'g'), patientInfo['day']);
    note = note.replace(new RegExp('%%-month', 'g'), patientInfo['month']);
    note = note.replace(new RegExp('%%-year', 'g'), patientInfo['year']);
    note = note.replace(new RegExp('%%-first-name', 'g'), patientInfo['first']);
    note = note.replace(new RegExp('%%-last-name', 'g'), patientInfo['last']);
    note = note.replace(new RegExp('%%-address', 'g'), patientInfo['address']);
    note = note.replace(
      new RegExp('%%-healthCardNumber', 'g'),
      patientInfo['healthCardNumber']
    );

    note = note.replace(
      new RegExp('%%-fullAddress', 'g'),
      patientInfo['address'] +
        ', ' +
        patientInfo['city'] +
        ' ' +
        patientInfo['province'] +
        ', ' +
        patientInfo['postalCode']
    );

    note = note.replace(new RegExp('%%-city', 'g'), patientInfo['city']);
    note = note.replace(
      new RegExp('%%-postalCode', 'g'),
      patientInfo['postalCode']
    );
    note = note.replace(
      new RegExp('%%-province', 'g'),
      patientInfo['province']
    );
    note = note.replace(
      new RegExp('%%-phone', 'g'),
      this.formatPhoneNumber(patientInfo['phone'])
    );
    note = note.replace(
      new RegExp('%%-date-today', 'g'),
      new Date().toDateString()
    );
    note = note.replace(
      new RegExp('%%-date-completed', 'g'),
      new Date(survey.time ?? new Date()).toDateString()
    );
    // these phrases may not always have a value because they are optional for patient sign in
    note = this.replaceOptionalPhrases(note, patientInfo);

    if (patientInfo.gender === 'Male') {
      note = note.replace(new RegExp('%%-gender', 'g'), 'male');
      note = note.replace(new RegExp('%%-mr-ms', 'g'), 'Mr.');
      note = note.replace(new RegExp('%%-his-her-c', 'g'), 'His');
      note = note.replace(new RegExp('%%-his-her', 'g'), 'his');
      note = note.replace(new RegExp('%%-he-she-c', 'g'), 'He');
      note = note.replace(new RegExp('%%-he-she', 'g'), 'he');
      note = note.replace(new RegExp('%%-him-her-c', 'g'), 'Him');
      note = note.replace(new RegExp('%%-him-her', 'g'), 'him');
    } else {
      note = note.replace(new RegExp('%%-gender', 'g'), 'female');
      note = note.replace(new RegExp('%%-mr-ms', 'g'), 'Ms.');
      note = note.replace(new RegExp('%%-his-her-c', 'g'), 'Her');
      note = note.replace(new RegExp('%%-his-her', 'g'), 'her');
      note = note.replace(new RegExp('%%-he-she-c', 'g'), 'She');
      note = note.replace(new RegExp('%%-he-she', 'g'), 'she');
      note = note.replace(new RegExp('%%-him-her-c', 'g'), 'Her');
      note = note.replace(new RegExp('%%-him-her', 'g'), 'her');
    }
    return note;
  }

  getAge(dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

  generateNote(responses, survey, scoring) {
    const noteSectionsArray = this.buildNoteArray(responses, scoring);
    let formattedNote = '';

    if (survey.grid) {
      formattedNote +=
        "<div><table border='0' bordercolor='#ccc' cellpadding='0' cellspacing='0' style='border-collapse:collapse;width:100%' class='note-table'><tbody>";
      noteSectionsArray.forEach((noteSection) => {
        // if no sentences for note section, dont include
        if (noteSection[1].length === 0) {
          return;
        }
        formattedNote +=
          '<tr><td><h3><b>' + noteSection[0] + '</b></h3></td><td>';
        noteSection[1].forEach((sentence) => {
          formattedNote += '<span>' + sentence + '</span>';
        });
        formattedNote += '</td></tr>';
      });
      formattedNote += '</tbody></table></div>';
    } else {
      noteSectionsArray.forEach((noteSection) => {
        // if no sentences for note section, dont include
        if (noteSection[1].length === 0) {
          return;
        }
        formattedNote += '<h3><b>' + noteSection[0] + '</b></h3>';
        noteSection[1].forEach((sentence) => {
          formattedNote += '<span>' + sentence + '</span>';
        });
      });
    }

    // remove header and footer h3s
    formattedNote = formattedNote.replace(
      new RegExp('<h3><b></b></h3>', 'g'),
      ''
    );
    // add extra spacing to bottom of the note
    formattedNote += '<br><br>';
    // replace %% stuff
    formattedNote = this.replaceSpecialPhrases(formattedNote, survey);

    // remove double periods, space period, and period after table
    formattedNote = formattedNote.replace(new RegExp(/ \.\./, 'g'), '.');
    formattedNote = formattedNote.replace(new RegExp(/ \./, 'g'), '.');
    formattedNote = formattedNote.replace(new RegExp(/br\/>\./, 'g'), 'br>');
    formattedNote = formattedNote.replace(new RegExp(/ul\/>\./, 'g'), 'ul>');
    formattedNote = formattedNote.replace(new RegExp(/ul>\.'/, 'g'), 'ul>');
    formattedNote = formattedNote.replace(new RegExp(/<p>\./, 'g'), '<p>');
    // removed because used as line break inn paragraph question? maybe?
    // formattedNote = formattedNote.replace(new RegExp('<p>&nbsp;</p>', 'g'), '');
    formattedNote = formattedNote.replace(
      new RegExp('<h3>&nbsp;</h3>', 'g'),
      ''
    );
    formattedNote = formattedNote.replace(
      new RegExp('<p>. &nbsp;</p>', 'g'),
      ''
    );
    formattedNote = formattedNote.replace(new RegExp(/>\./, 'g'), '>');
    formattedNote = formattedNote.replace(
      new RegExp('<p>.&nbsp;</p>', 'g'),
      ''
    );
    formattedNote = formattedNote.replace(new RegExp('<p>.</p>', 'g'), '');
    formattedNote = formattedNote.replace(new RegExp(/<p>\.<\/p>/, 'g'), '');
    formattedNote = formattedNote.replace(new RegExp(/\/p>\./, 'g'), '/p>');
    formattedNote = formattedNote.replace(new RegExp(/\n\./, 'g'), '');
    formattedNote = formattedNote.replace(
      new RegExp(/table>\./, 'g'),
      'table>'
    );
    formattedNote = formattedNote.replace(new RegExp(/div>\./, 'g'), 'div>');
    formattedNote = formattedNote.replace(new RegExp(/\.\./, 'g'), '.');

    return formattedNote;
  }

  buildNoteArray(responses, scoring) {
    const noteSections = {};
    let footer;
    responses.forEach(((response) => {
      if (response.note && response.note.location) {
        if (!noteSections[response.note.location]) {
          noteSections[response.note.location] = [];
        }
        const noteSentence = this.getNoteSentence(response);
        if (noteSentence) {
          noteSections[response.note.location].push(noteSentence);
        }
        // add custom note location to array
        if (!this.noteLocationsDictionary[response.note.location]) {
          // put custom location near end
          const index = this.noteLocations.length / 2;
          this.noteLocations.splice(index, 0, response.note.location);
          this.noteLocationsDictionary[response.note.location] = true;
        }
      }
    }).bind(this));
    const noteSectionsArray = [];
    // setting up header and footer properly
    if (noteSections[this.specialNoteSections['header']]) {
      noteSectionsArray[0] = [
        '',
        noteSections[this.specialNoteSections['header']],
      ];
      delete noteSections[this.specialNoteSections['header']];
    }
    if (noteSections[this.specialNoteSections['footer']]) {
      footer = ['<br/><br/>'].concat(
        noteSections[this.specialNoteSections['footer']]
      );
      delete noteSections[this.specialNoteSections['footer']];
    }
    // add scoring in
    if (scoring) {
      if (!noteSections[this.specialNoteSections['scoring']]) {
        noteSections[this.specialNoteSections['scoring']] = [];
      }
      noteSections[this.specialNoteSections['scoring']].push(scoring);
    }
    // get proper note ordering based on noteLocations
    this.noteLocations.forEach((noteLocation) => {
      if (noteSections[noteLocation]) {
        noteSectionsArray.push([noteLocation, noteSections[noteLocation]]);
      }
    });
    // add in footer at bottom
    if (footer) {
      noteSectionsArray.push(['', footer]);
    }
    return noteSectionsArray;
  }

  getNoteSentence(response) {
    let sentence = '';
    if (response.paragraph) {
      let cleanLineBreaks = response.answer;
      // change linne breaks to br
      cleanLineBreaks = cleanLineBreaks.replace(
        new RegExp('<p>&nbsp;</p>', 'g'),
        '<br/>'
      );
      // remove first p tag
      cleanLineBreaks = cleanLineBreaks.replace('<p>', '').replace('</p>', '');
      // remove last p tag
      cleanLineBreaks = cleanLineBreaks
        .split(' ')
        .reverse()
        .join(' ')
        .replace(new RegExp('<p>'), '')
        .replace(new RegExp(/<\/p>/), '')
        .split(' ')
        .reverse()
        .join(' ');
      return cleanLineBreaks;

      // if no note sentences, use question
    } else if (
      response.note.qa &&
      !response.note.first &&
      !response.note.last
    ) {
      sentence =
        (response.question.text ? '<p>' + response.question.text + ': ' : '') +
        '<b>' +
        response.answer +
        '</b></p>';
    } else if (response.question && response.question.type === 'yesno') {
      sentence = this.getYesNoNoteSentence(response);
    } else {
      sentence =
        (response.note.first ? response.note.first + ' ' : '') +
        response.answer +
        (response.note.last ? ' ' + response.note.last : '');
    }
    // incase returned false above
    if (!sentence) {
      return false;
    }
    sentence = this.addExtraNoteOptions(response, sentence);
    if (response.question.flag) {
      sentence = sentence.replace(
        '<table',
        "<table style='width:100%;color:" + response.question.flag + "'"
      );
      sentence = this.addFlagColor(sentence, response.question.flag);
    }
    if (response.note.removePeriod) {
      console.log(sentence);
      return sentence;
    } else {
      return sentence + '. ';
    }
  }

  addExtraNoteOptions(response, sentence) {
    if (response.note.bullet) {
      sentence = '<ul><li>' + sentence + '</li></ul>';
      // because bullet already removes one before and adds one after
      if (response.note.lineBreakBefore) {
        response.note.lineBreakBefore += 1;
      }
      if (response.note.lineBreakAfter) {
        response.note.lineBreakAfter -= 1;
      }
    }
    if (response.note.lineBreakBefore > 0) {
      let lineBreaks = '';
      for (let i = 0; i < response.note.lineBreakBefore; i++) {
        lineBreaks += '<br/>';
      }
      sentence = lineBreaks + sentence;
    }
    if (response.note.lineBreakAfter > 0) {
      let lineBreaks = '';
      for (let i = 0; i < response.note.lineBreakAfter; i++) {
        lineBreaks += '<br/>';
      }
      sentence = sentence + lineBreaks;
    }
    return sentence;
  }

  getYesNoNoteSentence(response) {
    if (response.question.response.answer === 'Yes' && response.note.first) {
      if (response.question.yesFlag) {
        return this.addFlagColor(
          response.note.first,
          response.question.yesFlag
        );
      } else {
        return response.note.first;
      }
    } else if (
      response.question.response.answer === 'No' &&
      response.note.last
    ) {
      if (response.question.noFlag) {
        return this.addFlagColor(response.note.last, response.question.noFlag);
      } else {
        return response.note.last;
      }
    }
    return false;
  }

  getResponse(question, survey) {
    if (!question.response) {
      return false;
    }
    const questionType = question.type;
    if (
      questionType === 'text' ||
      questionType === 'textarea' ||
      questionType === 'phone' ||
      questionType === 'number' ||
      questionType === 'email' ||
      questionType === 'fiveStar' ||
      questionType === 'range' ||
      questionType === 'address'
    ) {
      return question.response.answer;
    } else if (questionType === 'yesno') {
      return this.getYesNoResponse(question, survey);
    } else if (questionType === 'multiyesno') {
      return this.getmultiYesNoResponse(question, survey);
    } else if (
      questionType === 'radio' ||
      questionType === 'scoring' ||
      questionType === 'select'
    ) {
      return this.getRadioResponse(question, survey);
    } else if (questionType === 'checkbox') {
      return this.getCheckboxResponse(question, survey);
    } else if (questionType === 'yearMonthDay') {
      return this.getYearMonthDayResponse(question.response.answer);
    } else if (questionType === 'medications') {
      return this.getMedicationsResponse(question);
    } else if (questionType === 'doctors') {
      return this.getDoctorResponse(question);
    } else if (questionType === 'autocomplete') {
      return this.getAutocompleteResponse(question);
    } else if (questionType === 'pdf') {
      return this.getPDFResponse(question);
    } else if (questionType === 'medicationAllergies') {
      return this.getMedicationAllergiesResponse(question);
    } else if (questionType === 'conditions') {
      return this.getConditionsResponse(question);
    } else if (questionType === 'bloodPressure') {
      return this.getBloodPressureResponse(question);
    } else if (questionType === 'surgery') {
      return this.getSurgeriesResponse(question);
    } else if (questionType === 'heightWeight') {
      return this.getheightWeightResponse(question);
    } else if (questionType === 'grid') {
      return this.getGridResponse(question);
    } else if (questionType === 'physicalGrid') {
      return this.getPhysicalGridResponse(question);
    } else if (questionType === 'drawing' || questionType === 'photograph') {
      return this.getImageResponse(question);
    } else if (questionType === 'signature') {
      return this.getSignatureResponse(question);
    } else if (questionType === 'shareNote') {
      return this.getShareNoteResponse(question);
    } else if (questionType === 'dateRange') {
      return this.getDateRangeResponse(question);
    } else if (questionType === 'date') {
      return this.getDateResponse(question);
    } else if (questionType === 'priority') {
      return this.getPriorityResponse(question);
    } else if (questionType === 'diagram') {
      return this.getDiagramResponse(question);
    } else if (questionType === 'time') {
      return this.getTimeResponse(question);
    } else {
      return false; // question.type + " question not setup yet";
    }
  } // end func

  getTimeResponse(question) {
    if (!question.response.answer) {
      return false;
    }
    if (question.timeType === 'dateTime') {
      return (
        new Date(question.response.answer).toLocaleDateString() +
        ' ' +
        new Date(question.response.answer).toLocaleTimeString()
      );
    }
    return question.response.answer;
  }

  getDiagramResponse(question) {
    if (question.response) {
      return "<p><img class='maxWidth100%' src='" + question.images + "'></p>";
    } else {
      return false;
    }
  }

  getBloodPressureResponse(question) {
    if (!question.response.rows || question.response.rows.length === 0) {
      return false;
    }
    let filledInRows = 0;
    let chart =
      "<table border='1' bordercolor='#ccc' cellpadding='5' cellspacing='0' style='text-align:center;border-collapse:collapse;width:100%' class='note-table'><tbody><tr><td><b><h4>Systolic</h4></b></td><td><b><h4>Diastolic</h4></b></td><td><b><h4>BPM</h4></b></td><td><b><h4>SO2</h4></b></td><td><b><h4>Time</h4></b></td></tr>";
    for (const index of Object.keys(question.response.rows)) {
      if (
        question.response.rows[index].diastolic ||
        question.response.rows[index].systolic ||
        question.response.rows[index].bpm ||
        question.response.rows[index].so2
      ) {
        filledInRows += 1;
      } else {
        continue;
      }
      chart +=
        '<tr>' +
        "<td style='text-align:left;'>" +
        (question.response.rows[index].systolic
          ? question.response.rows[index].systolic
          : '') +
        '</td>' +
        "<td style='text-align:left;'>" +
        (question.response.rows[index].diastolic
          ? question.response.rows[index].diastolic
          : '') +
        '</td>' +
        "<td style='text-align:left;'>" +
        (question.response.rows[index].bpm
          ? question.response.rows[index].bpm
          : '') +
        '</td>' +
        "<td style='text-align:left;'>" +
        (question.response.rows[index].so2
          ? question.response.rows[index].so2
          : '') +
        '</td>' +
        "<td style='text-align:left;'>" +
        (question.response.rows[index].time
          ? question.response.rows[index].time
          : '') +
        '</td>' +
        '</tr>';
    }
    chart += '	</tbody></table>';
    if (filledInRows < 1) {
      return false;
    }
    return chart;
  }

  getAutocompleteResponse(question) {
    if (!question.response) {
      return false;
    }
    // check at least one entered med
    let atLeastOne = false;
    for (const item in question.response.selectedAnswers) {
      if (question.response.selectedAnswers[item]) {
        atLeastOne = true;
      }
    }
    if (!atLeastOne) {
      return false;
    }
    if (question.response.selectedAnswers[-1]) {
      question.response.selectedAnswers.push(
        question.response.selectedAnswers[-1]
      );
      question.response.selectedAnswers[-1] = null;
    }
    return question.response.selectedAnswers.join(', ');
  }

  getDoctorResponse(question) {
    if (!question.response) {
      return false;
    }
    // check at least one entered med
    let atLeastOne = false;
    for (const item in question.response.selectedAnswers) {
      if (question.response.selectedAnswers[item]) {
        atLeastOne = true;
      }
    }
    if (!atLeastOne) {
      return false;
    }
    if (question.response.selectedAnswers[-1]) {
      question.response.selectedAnswers.push(
        question.response.selectedAnswers[-1]
      );
      question.response.selectedAnswers[-1] = null;
    }
    return 'Dr. ' + question.response.selectedAnswers.join(', Dr. ');
  }

  getheightWeightResponse(question) {
    if (!question.response) {
      return false;
    }
    const response = question.response;
    let responseString = '';
    if (
      ((response.feet || response.height) && !response.metricHeight) ||
      (response.height && response.metricHeight)
    ) {
      responseString +=
        'Height of ' +
        this.heightConversion(
          response.height,
          response.feet,
          response.metricHeight
        );
      if (response.weight) {
        responseString +=
          ' and a weight of ' +
          this.weightConversion(response.weight, response.metricWeight);
      }
      return responseString;
    } else if (response.weight) {
      return (responseString +=
        'Weight of ' +
        this.weightConversion(response.weight, response.metricWeight));
    }
    return false;
  }

  weightConversion(weight, metricWeight) {
    if (metricWeight) {
      return Math.round(weight * 2.2) + ' lbs (' + weight + ' ' + 'kg)';
    }
    return weight + ' lbs (' + Math.round(weight / 2.2) + ' ' + 'kg)';
  }
  heightConversion(height, feet, metricHeight) {
    if (metricHeight) {
      const cmToInches = Math.round(height / 2.54);
      return (
        Math.floor(cmToInches / 12) +
        "' " +
        (cmToInches % 12) +
        '" ' +
        '(' +
        height +
        ' cm)'
      );
    }
    return (
      feet +
      "' " +
      height +
      '" ' +
      '(' +
      Math.round((height + feet * 12) * 2.54) +
      ' cm)'
    );
  }

  getMedicationsResponse(question) {
    if (!question.response) {
      return false;
    }
    // check at least one entered med
    let atLeastOne = false;
    for (const item in question.response.selectedAnswers) {
      if (question.response.selectedAnswers[item]) {
        atLeastOne = true;
      }
    }
    if (!atLeastOne && !question.response.noMeds) {
      return false;
    }
    if (question.response.noMeds && question.allowMultiple) {
      return 'no medications';
    } else if (question.response.noMeds) {
      return 'no medication';
    } else {
      if (
        !!question.response.selectedAnswers &&
        !!question.response.selectedAnswers[-1]
      ) {
        question.response.selectedAnswers.push(
          question.response.selectedAnswers[-1]
        );
        question.response.selectedAnswers[-1] = null;
      }
      return question.response.selectedAnswers.join(', ');
    }
  }

  getDateResponse(question) {
    if (
      question.response.answer &&
      question.response.answer !== 'Invalid Date'
    ) {
      let answer = new Date(question.response.answer).toDateString();
      if (answer === 'Invalid Date') {
        return false;
      }

      if (question.flagDays && question.flagNumDays) {
        const answerDateMS = new Date(question.response.answer).getTime();
        // current time - number of days = flag line
        const flagDayMS =
          new Date().getTime() - 24 * 60 * 60 * 1000 * question.flagNumDays;
        if (answerDateMS < flagDayMS) {
          if (question.flagDaysReason) {
            answer += ' (' + question.flagDaysReason + ')';
          }
          answer = '<span style="color:red;">' + answer + '</span>';
        }
      }
      question.response.answer = answer;
      return answer;
    } else {
      return false;
    }
  }

  getConditionsResponse(question) {
    let response = '';
    if (!question.response || !question.response.answer) {
      return false;
    }
    if (question.response.answer === 'No') {
      return 'Patient reports having no known medical conditions';
    } else {
      response +=
        '<span style="color:rgb(207, 55, 89)">Patient reports having medical conditions';
    }
    const combinedArray = [];
    if (question.response.selectedCheckboxes) {
      question.response.selectedCheckboxes.forEach((item) => {
        if (item.selected === true) {
          combinedArray.push(item.condition);
        }
      });
    }

    if (question.response.selectedAnswers) {
      if (question.response.selectedAnswers[-1]) {
        question.response.selectedAnswers.push(
          question.response.selectedAnswers[-1]
        );
        question.response.selectedAnswers[-1] = null;
      }
      for (const item in question.response.selectedAnswers) {
        if (question.response.selectedAnswers[item]) {
          combinedArray.push(question.response.selectedAnswers[item]);
        }
      }
    }
    // check if empty, sometimes leaves [undefined]
    let empty = true;
    combinedArray.forEach((item) => {
      if (item) {
        empty = false;
      }
    });
    if (!empty) {
      response += ' including: ';
      if (combinedArray.length > 1 && combinedArray[combinedArray.length - 1]) {
        combinedArray[combinedArray.length - 1] =
          'and ' + combinedArray[combinedArray.length - 1];
      }
      response += combinedArray.join(', ');
    }
    return response + '</span><span style="color:black;">&nbsp;</span>';
  }

  getSurgeriesResponse(question) {
    let response = '';
    if (!question.response || !question.response.answer) {
      return false;
    }
    if (question.response.answer === 'No') {
      return 'Patient reports having no past surgeries';
    } else {
      response += 'Patient reports having had surgery';
    }
    const combinedArray = [];
    if (question.response.selectedCheckboxes) {
      question.response.selectedCheckboxes.forEach((item) => {
        if (item.selected === true) {
          combinedArray.push(item.surgery);
        }
      });
    }
    if (question.response.selectedAnswers) {
      if (question.response.selectedAnswers[-1]) {
        question.response.selectedAnswers.push(
          question.response.selectedAnswers[-1]
        );
        question.response.selectedAnswers[-1] = null;
      }
      for (const item in question.response.selectedAnswers) {
        if (question.response.selectedAnswers[item]) {
          combinedArray.push(question.response.selectedAnswers[item]);
        }
      }
    }
    // check if empty, sometimes leaves [undefined]
    let empty = true;
    combinedArray.forEach((item) => {
      if (item) {
        empty = false;
      }
    });
    if (!empty) {
      response += ' including: ';
      if (combinedArray.length > 1 && combinedArray[combinedArray.length - 1]) {
        combinedArray[combinedArray.length - 1] =
          'and ' + combinedArray[combinedArray.length - 1];
      }
      response += combinedArray.join(', ');
    }
    // return response + ' &nbsp;</span>';
    return response;
  }

  getMedicationAllergiesResponse(question) {
    let response = '';
    if (!question.response || !question.response.answer) {
      return false;
    }
    if (question.response.answer === 'No') {
      return 'Patient reports having no known drug allergies';
    } else {
      response +=
        '<span style="color:rgb(207, 55, 89)">Patient reports having drug allergies';
    }
    const combinedArray = [];
    if (question.response.selectedCheckboxes) {
      question.response.selectedCheckboxes.forEach((item) => {
        if (item.selected === true) {
          combinedArray.push(item.medication);
        }
      });
    }
    if (question.response.selectedAnswers) {
      if (question.response.selectedAnswers[-1]) {
        question.response.selectedAnswers.push(
          question.response.selectedAnswers[-1]
        );
        question.response.selectedAnswers[-1] = null;
      }
      for (const item in question.response.selectedAnswers) {
        if (question.response.selectedAnswers[item]) {
          combinedArray.push(question.response.selectedAnswers[item]);
        }
      }
    }
    // check if empty, sometimes leaves [undefined]
    let empty = true;
    combinedArray.forEach((item) => {
      if (item) {
        empty = false;
      }
    });
    if (!empty) {
      response += ' including: ';
      if (combinedArray.length > 1 && combinedArray[combinedArray.length - 1]) {
        combinedArray[combinedArray.length - 1] =
          'and ' + combinedArray[combinedArray.length - 1];
      }
      response += combinedArray.join(', ');
    }
    return response + '</span><span style="color:black;">&nbsp;</span>';
  }

  getDateRangeResponse(question) {
    if (!question.response.answer.from && !question.response.answer.to) {
      return false;
    }
    const response =
      new Date(question.response.answer.from).toDateString() +
      ' to ' +
      new Date(question.response.answer.to).toDateString();
    return response.replace('Invalid Date', '_____');
  }

  getYesNoResponse(question, survey) {
    const answer = question.response.answer;
    if (answer === 'Yes') {
      this.addIntegrationResponse(question.offeredAnswers[1], survey);
      if (question.yesFlag) {
        return this.addFlagColor(answer, question.yesFlag);
      }
    } else if (answer === 'No') {
      this.addIntegrationResponse(question.offeredAnswers[0], survey);
      if (question.noFlag) {
        return this.addFlagColor(answer, question.noFlag);
      }
    }
    return answer;
  }

  getYearMonthDayResponse(answer) {
    if (!answer.day && !answer.year && !answer.month) {
      return false;
    }
    let response = '';
    // year and month
    if (!answer.day && answer.year && answer.month) {
      response += answer.month + ' / ' + answer.year;
      // only year
    } else if (!answer.day && answer.year && !answer.month) {
      response += answer.year;
      // only month
    } else if (!answer.day && !answer.year && answer.month) {
      response += answer.month;
      // month and day
    } else if (answer.day && !answer.year && answer.month) {
      response += answer.day + ' / ' + answer.month;
    } else {
      response += answer.day + ' / ' + answer.month + ' / ' + answer.year;
    }
    return response.replace(new RegExp('null|undefined', 'g'), '___');
  }

  getShareNoteResponse(question) {
    if (question.response.responseTerms || question.response.answer) {
      let consent = '';
      if (question.response.responseTerms) {
        consent = 'Terms & Conditions Accepted';
      } else if (!question.response.responseTerms) {
        consent =
          "<span style='color:orange;'>Terms & Conditions Not Accepted</span>";
      }
      if (question.response.answer) {
        consent +=
          "<p><img class='maxWidth100%' src='" +
          question.response.answer +
          "'></p>";
      }
      return consent;
    } else {
      return false;
    }
  }

  getSignatureResponse(question) {
    if (question.response.responseTerms || question.response.answer) {
      let consent = '';
      if (question.response.responseTerms) {
        consent = 'Terms & Conditions Accepted';
        if (!question.hideTerms) {
          consent += '<br/>' + question.termsContent;
        }
      } else if (!question.response.responseTerms && question.terms) {
        consent =
          "<span style='color:orange;'>Terms & Conditions Not Accepted</span>";
      }
      if (question.response.answer) {
        consent +=
          "<p><img style='height: 50px;' class='maxWidth100%' src='" +
          question.response.answer +
          "'></p>";
      }
      return consent;
    } else {
      return false;
    }
  }

  getImageResponse(question) {
    if (question.response && question.response.answer) {
      return (
        "<p><img class='maxWidth100%' src='" +
        question.response.answer +
        "'></p>"
      );
    } else {
      return false;
    }
  }

  getPDFResponse(question) {
    if (!question.response || !question.response[0]) {
      return false;
    }
    let images = '';
    question.response.forEach((image) => {
      console.log(image);
      images += "<p><img class='maxWidth100%' src='" + image + "'></p>";
    });
    return images;
  }

  // dave
  getPhysicalGridResponse(question) {
    let atLeastOneAnswer = false;

    const checkboxesArray = [];
    let checkboxesString = '';
    for (const item in question.response.checkboxes) {
      if (question.response.checkboxes[item]) {
        checkboxesArray.push(item);
      }
    }
    if (question.response.otherCheckbox) {
      checkboxesArray.push(question.response.otherCheckbox);
    }
    if (checkboxesArray.length !== 0) {
      checkboxesString =
        '<b>General findings:</b> ' + checkboxesArray.join(', ');
    }

    let bloodPressureString = this.getBloodPressureResponse(question) || '';
    if (bloodPressureString) {
      bloodPressureString =
        '<p><b>Blood Pressure</b></p>' + bloodPressureString;
    }

    let finalPhys =
      '<table border="1" cellpadding="5" cellspacing="0" style="border-collapse:collapse; width:100%;" class="note-table">';
    finalPhys +=
      '<thead><tr><td><b>Physical Grid</b></td><td style="text-align:center;"><label><b>Normal</b></label></td><td style="text-align:center;"><label><b>Abnormal</b></label></td><td style="text-align:center;" colspan="3"><label><b>Description</b></label></td></tr></thead><tbody>';
    for (const key of Object.keys(question.response.physicalGrid)) {
      let temp =
        '<tr><td style="text-align:center;"><label><b>' +
        key +
        '</b></label></td><td></td><td></td><td></td></tr>';
      let atLeastOneRow = false;
      for (const part of Object.keys(question.response.physicalGrid[key])) {
        if (question.response.physicalGrid[key][part].options) {
          question.response.physicalGrid[key][part].selectedOptions = [];
          question.response.physicalGrid[key][part].options.forEach((item) => {
            if (item.selected) {
              question.response.physicalGrid[key][part].selectedOptions.push(
                item.title
              );
            }
          });
        }
        if (
          !question.response.physicalGrid[key][part].value &&
          !question.response.physicalGrid[key][part].desc &&
          (!question.response.physicalGrid[key][part].selectedOptions ||
            !question.response.physicalGrid[key][part].selectedOptions.length ||
            question.response.physicalGrid[key][part].selectedOptions.length <
              1)
        ) {
          continue;
        }
        temp +=
          '<tr><td style="padding-left:1em;"><label>' + part + '</label></td>';
        if (question.response.physicalGrid[key][part].value === 'normal') {
          temp +=
            '<td style="text-align:center;">✓</td><td style="text-align:center;"></td><td style="text-align:left;" colspan="3">';
          atLeastOneRow = true;
        } else if (
          question.response.physicalGrid[key][part].value === 'abnormal'
        ) {
          temp +=
            '<td></td><td style="text-align:center;">✓</td><td style="text-align:left;" colspan="3">';
          atLeastOneRow = true;
        } else {
          temp +=
            '<td></td><td style="text-align:center;"></td><td style="text-align:left;" colspan="3">';
        }
        if (
          question.response.physicalGrid[key][part].selectedOptions &&
          question.response.physicalGrid[key][part].selectedOptions.length &&
          question.response.physicalGrid[key][part].selectedOptions.length > 0
        ) {
          temp +=
            '<span>' +
            question.response.physicalGrid[key][part].selectedOptions.join(
              ', '
            ) +
            '</span><br>';
        }
        if (question.response.physicalGrid[key][part].desc) {
          temp += question.response.physicalGrid[key][part].desc + '</td></tr>';
          atLeastOneRow = true;
        } else {
          temp += '</td></tr>';
        }
      }
      if (atLeastOneRow) {
        finalPhys += temp;
        atLeastOneAnswer = true;
      }
    } // end for
    if (atLeastOneAnswer) {
      finalPhys += '</tbody></table>';
      return checkboxesString + bloodPressureString + finalPhys;
    } else if (checkboxesString || bloodPressureString) {
      return checkboxesString + bloodPressureString;
    } else {
      return false;
    }
  } // end func

  getGridResponse(question) {
    if (!question.grid?.rows) {
      return false;
    }

    const inputType = question.grid.cellInputType;
    let colInputType: any;
    let atLeastOneAnswerRow: any;
    let colInputIndep = false;
    let atLeastOneAnswer = false;

    for (const row in question.grid.rows) {
      if (question.response[row] && question.response[row].col) {
        atLeastOneAnswer = true;
        break;
      }
    }
    if (!atLeastOneAnswer) {
      return false;
    }
    atLeastOneAnswer = false;
    // header
    let finalChart =
      "<table border='1' bordercolor='#ccc' cellpadding='5' cellspacing='0' width='100%;' style='border-collapse:collapse;width:100%;margin:0' class='note-table'><thead><tr>";
    if (!question.allowMultiple) {
      finalChart += "<th style='width:100px;'><b><h4></h4></b></th>";
    }
    for (const col1 of Object.keys(question.grid.cols)) {
      const tempCol1 = question.grid.cols[col1].label;
      const col1Str =
        "<th scope='col' style='text-align:center;'><span style='font-family:Georgia,serif;width:100px;'>" +
        tempCol1 +
        '</span></th>';
      finalChart += col1Str;
    }
    finalChart += '</tr></thead><tbody>';
    for (const c in question.grid.cols) {
      if (question.grid.cols[c].colInputType) {
        colInputIndep = true;
        break;
      }
    }
    for (const row in question.grid.rows) {
      if (!question.response[row].col) {
        continue;
      }
      atLeastOneAnswerRow = 0;
      const tempRow = question.grid.rows[row].label;
      const rowId = question.grid.rows[row].id;
      let rowStr = '<tr>';
      if (!question.allowMultiple) {
        rowStr +=
          "<td style='width:100px;'><span style='font-family:Georgia,serif'>" +
          tempRow +
          '</span></td>';
      }
      for (const col of Object.keys(question.grid.cols)) {
        const colId = question.grid.cols[col].id;
        if (question.grid.cols[col].colInputType) {
          colInputType = question.grid.cols[col].colInputType;
        } else {
          colInputType = false;
        }
        let tempCol =
          "<td style='text-align:center;width:100px;'><span style='font-family:Georgia,serif'>";
        if (colInputIndep) {
          for (const r in question.response) {
            if (
              question.response[r].col.id === colId &&
              question.response[r].row.id === rowId &&
              question.response[r].value
            ) {
              atLeastOneAnswerRow = true;
              if (colInputType === 'checkbox' || colInputType === 'radio') {
                tempCol += '✓';
              } else if (colInputType === 'date') {
                tempCol += new Date(question.response[r].value).toDateString();
              } else {
                tempCol += question.response[r].value;
              }
            }
          }
        } else if (inputType === 'radio' || inputType === 'checkbox') {
          for (const r in question.response) {
            if (
              question.response[r].value &&
              question.response[r].col &&
              question.response[r].row &&
              question.response[r].col.id === colId &&
              question.response[r].row.id === rowId
            ) {
              atLeastOneAnswerRow = true;
              tempCol += '✓';
            }
          }
        } else {
          for (const r in question.response) {
            if (
              question.response[r].col.id === colId &&
              question.response[r].row.id === rowId &&
              question.response[r].value
            ) {
              atLeastOneAnswerRow = true;
              if (inputType === 'date') {
                tempCol += new Date(question.response[r].value).toDateString();
              } else {
                tempCol += question.response[r].value;
              }
            }
          }
        }
        tempCol += '</span></td>';
        rowStr += tempCol;
      }
      if (atLeastOneAnswerRow) {
        atLeastOneAnswer = true;
        finalChart += rowStr + '</tr>';
      }
    } // end row loop
    if (!atLeastOneAnswer) {
      return false;
    }
    finalChart += '</tbody></table>';
    return finalChart.replace('null', '');
  }

  getPriorityResponse(question) {
    if (!question.response.priorityList) {
      return false;
    }
    let chart =
      "<table border='1' bordercolor='#ccc' cellpadding='5' cellspacing='0' style='text-align:center;border-collapse:collapse;min-width:50%;' class='note-table'><tbody><tr><td><b><h4>" +
      question.text +
      '</h4></b></td></tr>';
    for (const index of Object.keys(question.response.priorityList)) {
      chart +=
        "<tr><td style='text-align:left;'><span style='font-family:Georgia,serif'>" +
        (parseInt(index, 10) + 1) +
        '. ' +
        question.response.priorityList[index].value +
        '</span></td></tr>';
    }
    chart += '	</tbody></table>';
    return chart;
  }

  addFlagColor(sentence, color) {
    return (
      "<span style='color:" +
      color +
      "'>" +
      sentence +
      '</span><span style="color:black;">&nbsp;</span>'
    );
  }

  getRadioResponse(question, survey) {
    if (!question.response.selectedAnswer) {
      return false;
    }
    if (question.response.selectedAnswer === 'other') {
      return question.response.other;
    } else {
      for (const a in question.offeredAnswers) {
        if (
          question.offeredAnswers[a].id === question.response.selectedAnswer
        ) {
          this.addIntegrationResponse(question.offeredAnswers[a], survey);
          if (question.offeredAnswers[a].flag) {
            return this.addFlagColor(
              question.offeredAnswers[a].value,
              question.offeredAnswers[a].flag
            );
          } else {
            return question.offeredAnswers[a].value;
          }
        }
      }
    }
    return false;
  } // end func

  getCheckboxResponse(question, survey) {
    if (
      (!question.response.selectedAnswers ||
        question.response.selectedAnswers.length === 0) &&
      !question.isOtherAnswer
    ) {
      return false;
    }
    const answer = [];

    for (const a in question.response.selectedAnswers) {
      if (question.response.selectedAnswers[a]) {
        if (question.response.selectedAnswers[a].flag) {
          answer.push(
            this.addFlagColor(
              question.response.selectedAnswers[a].value,
              question.response.selectedAnswers[a].flag
            )
          );
        } else {
          answer.push(question.response.selectedAnswers[a].value);
        }
        // add integration stuff
        this.addIntegrationResponse(
          question.response.selectedAnswers[a],
          survey
        );
      }
    }
    // checking if otherAnswer
    if (question.isOtherAnswer && question.response.other) {
      answer.push(question.response.other);
    }

    // if multiLine, change output format
    if (question.multiLine && answer.length > 1) {
      for (const item in answer) {
        if (answer[item]) {
          answer[item] = '<p>' + answer[item] + '</p>';
        }
      }
      return answer.join('');
    }

    // adding and to end of lists
    if (answer.length > 1 && answer[answer.length - 1]) {
      if (question.Or) {
        answer[answer.length - 1] = 'or ' + answer[answer.length - 1];
      } else {
        answer[answer.length - 1] = 'and ' + answer[answer.length - 1];
      }
    }
    // no comma if only one answer
    if (answer.length < 2) {
      if (answer[0]) {
        return answer[0];
      }
      if (answer[1]) {
        return answer[1];
      }
    }
    return answer.join(', ');
  } // end func

  getmultiYesNoResponse(question, survey) {
    if (
      !question.response.selectedAnswers ||
      question.response.selectedAnswers.length === 0 ||
      !question.offeredQuestions
    ) {
      return false;
    }

    if (question.sentence) {
      const yesLine = [];
      const noLine = [];
      for (const index in question.response.selectedAnswers) {
        if (question.response.selectedAnswers[index] === 'Yes') {
          if (
            question.offeredQuestions[index].integrations &&
            question.offeredQuestions[index].integrations[1]
          ) {
            this.addIntegrationResponse(
              question.offeredQuestions[index].integrations[1],
              survey
            );
          }
          if (question.offeredQuestions[index].yesFlag) {
            yesLine.push(
              "<span style='color:" +
                question.offeredQuestions[index].yesFlag +
                "'>" +
                (question.offeredQuestions[index].text
                  ? question.offeredQuestions[index].text
                  : '') +
                '</span>'
            );
          } else {
            yesLine.push(
              question.offeredQuestions[index].text
                ? question.offeredQuestions[index].text
                : ''
            );
          }
        } else if (question.response.selectedAnswers[index] === 'No') {
          if (
            question.offeredQuestions[index].integrations &&
            question.offeredQuestions[index].integrations[0]
          ) {
            this.addIntegrationResponse(
              question.offeredQuestions[index].integrations[0],
              survey
            );
          }
          if (question.offeredQuestions[index].noFlag) {
            noLine.push(
              "<span style='color:" +
                question.offeredQuestions[index].noFlag +
                "'>" +
                (question.offeredQuestions[index].text
                  ? question.offeredQuestions[index].text
                  : '') +
                '</span>'
            );
          } else {
            noLine.push(
              question.offeredQuestions[index].text
                ? question.offeredQuestions[index].text
                : ''
            );
          }
        }
      }
      let sentence = '';
      if (yesLine.length > 0) {
        sentence += '<br>&nbsp;&nbsp;<b>Yes</b>: ' + yesLine.join(', ');
      }
      if (noLine.length > 0) {
        sentence += '<br>&nbsp;&nbsp;&nbsp;<b>No</b>: ' + noLine.join(', ');
      }
      if (yesLine.length > 0 || noLine.length > 0) {
        sentence += '<br>';
      }
      return sentence;
    } else {
      let chart =
        "<table border='1' bordercolor='#ccc' cellpadding='5' cellspacing='0' style='text-align:center;border-collapse:collapse;width:100%' class='note-table'><tbody><tr><td><b><h4>" +
        question.text +
        '</h4></b></td><td><b><h4>No</h4></b></td><td><b><h4>Yes</h4></b></td></tr>';
      for (const index in question.response.selectedAnswers) {
        if (question.response.selectedAnswers[index] === 'Yes') {
          if (
            question.offeredQuestions[index].integrations &&
            question.offeredQuestions[index].integrations[1]
          ) {
            this.addIntegrationResponse(
              question.offeredQuestions[index].integrations[1],
              survey
            );
          }
          if (question.offeredQuestions[index].yesFlag) {
            chart +=
              "<tr> <td style='font-family:Georgia,serif;text-align:left;'><span style='font-family:Georgia,serif;text-align:left;color:" +
              question.offeredQuestions[index].yesFlag +
              "'>" +
              (question.offeredQuestions[index].text
                ? question.offeredQuestions[index].text
                : '') +
              '</span></td> <td></td> <td>✓</td> </tr>';
          } else {
            chart +=
              "<tr> <td style='font-family:Georgia,serif;text-align:left;'><span style='font-family:Georgia,serif;text-align:left;'>" +
              (question.offeredQuestions[index].text
                ? question.offeredQuestions[index].text
                : '') +
              '</span></td> <td></td> <td>✓</td> </tr>';
          }
        } else if (question.response.selectedAnswers[index] === 'No') {
          if (
            question.offeredQuestions[index].integrations &&
            question.offeredQuestions[index].integrations[0]
          ) {
            this.addIntegrationResponse(
              question.offeredQuestions[index].integrations[0],
              survey
            );
          }
          if (question.offeredQuestions[index].noFlag) {
            chart +=
              "<tr> <td style='text-align:left;'><span style='font-family:Georgia,serif;text-align:left;color:" +
              question.offeredQuestions[index].noFlag +
              "'>" +
              (question.offeredQuestions[index].text
                ? question.offeredQuestions[index].text
                : '') +
              '</span></td> <td>✓</td> <td></td> </tr>';
          } else {
            chart +=
              "<tr> <td style='text-align:left;'><span style='font-family:Georgia,serif;text-align:left;'>" +
              (question.offeredQuestions[index].text
                ? question.offeredQuestions[index].text
                : '') +
              '</span></td> <td>✓</td> <td></td> </tr>';
          }
        }
      }
      chart += '	</tbody></table>';
      return chart;
    }
  } // end func

  // get responses for every question
  generateResponses(survey, append?) {
    const responses = [];

    if (survey.surveyNames === undefined) {
      survey.surveyNames = [];
    }

    if (survey.patientInfo.pharmaFax && !append) {
      responses.push({
        question: { text: 'Preferred Pharmacy' },
        note: { location: 'Patient Profile', removePeriod: true },
        answer: this.addPharmacyInfoToResponses(responses, survey.patientInfo),
      });
    }

    survey.pages.forEach((page) => {
      if (!page.elements || !page.elements.length || page.elements.length < 1) {
        return false;
      }
      page.elements.forEach((element) => {
        let response;

        if (append && !element.doctor) {
          return false;
        }

        if (element.question) {
          response = this.getResponse(element.question, survey);
          // check if patient answered it
          if (
            response != null &&
            response !== false &&
            response !== undefined
          ) {
            responses.push({
              question: element.question,
              note: element.note,
              answer: response,
            });
          }

          if (element.question.liveName) {
            survey.surveyNames.push({
              name: element.question.liveName,
              id: element.question.survId,
              survUID: element.question.survUID,
              type: element.question.triggeredLibrary ? 'library' : 'user',
              triage: element.question.triage,
            });
          }
        } else if (element.paragraph) {
          if (
            element.note &&
            element.note.location &&
            element.paragraph.html &&
            !element.triggerHidden &&
            !element.demographicsHidden
          ) {
            response = element.paragraph.html;
            responses.push({
              paragraph: true,
              note: element.note,
              answer: response,
            });
          }
        }
        // add to pdfResponses
        this.addToPDFResponses(response, element, survey);
      });
    });
    return responses;
  } // end func

  addPharmacyInfoToResponses(_responses, patientInfo) {
    if (patientInfo.pharmaName && patientInfo.pharmaFax) {
      return (
        '<b>Pharmacy</b>: ' +
        patientInfo.pharmaName +
        ' <b>FAX:</b> ' +
        this.formatPhoneNumber(patientInfo.pharmaFax)
      );
    } else {
      return (
        '<b>Pharmacy FAX:</b> ' + this.formatPhoneNumber(patientInfo.pharmaFax)
      );
    }
  }

  addToPDFResponses(response, element, survey) {
    if (response && element.integrationID) {
      // handle images differently
      if (
        element.question &&
        (element.question.type === 'drawing' ||
          element.question.type === 'signature' ||
          element.question.type === 'photograph')
      ) {
        if (element.question.response && element.question.response.answer) {
          survey.integrationResponses[
            'image:' + element.integrationID
          ] = element.question.response.answer.split(',')[1];
        }
      } else if (
        element.question.type === 'diagram' &&
        element.question.response
      ) {
        survey.integrationResponses[
          'image:' + element.integrationID
        ] = element.question.images.split(',')[1];
      } else if (
        element.question.type === 'heightWeight' &&
        element.question.response
      ) {
        if (
          (element.question.response.feet &&
            element.question.response.height) ||
          (element.question.response.height &&
            element.question.response.metricHeight)
        ) {
          if (element.question.response.metricHeight) {
            survey.integrationResponses[element.integrationID + '-cm'] =
              element.question.response.height;
            const cmToInches = Math.round(
              element.question.response.height / 2.54
            );
            survey.integrationResponses[
              element.integrationID + '-feet'
            ] = Math.floor(cmToInches / 12);
            survey.integrationResponses[element.integrationID + '-inches'] =
              cmToInches % 12;
          } else {
            survey.integrationResponses[
              element.integrationID + '-cm'
            ] = Math.round(
              (element.question.response.height +
                element.question.response.feet * 12) *
                2.54
            );
            survey.integrationResponses[element.integrationID + '-feet'] =
              element.question.response.feet;
            survey.integrationResponses[element.integrationID + '-inches'] =
              element.question.response.height;
          }
        }
        if (element.question.response.weight) {
          if (element.question.response.metricWeight) {
            survey.integrationResponses[
              element.integrationID + '-lbs'
            ] = Math.round(element.question.response.weight * 2.2);
            survey.integrationResponses[element.integrationID + '-kg'] =
              element.question.response.weight;
          } else {
            survey.integrationResponses[
              element.integrationID + '-kg'
            ] = Math.round(element.question.response.weight / 2.2);
            survey.integrationResponses[element.integrationID + '-lbs'] =
              element.question.response.weight;
          }
        }
        survey.integrationResponses[element.integrationID] = response;
      } else {
        // TODO remove any html tags???? Does this ever happen???
        survey.integrationResponses[element.integrationID] = response;
      }

      if (element.integrationValues) {
        survey.integrationResponses[element.integrationID + '-r'] =
          element.question.response;
      }
    }
  }

  // get whole summary
  generateSummary(responses, survey) {
    let summary = this.getSummaryHeader(survey);
    summary += this.formatSummaryResponses(responses);
    summary = this.replaceSpecialPhrases(summary, survey);
    return summary;
  } // end func

  getSummaryHeader(survey) {
    const patientInfo = survey.patientInfo;
    let summaryHeader = '<h2 style="text-align:center;">';
    if (patientInfo.first) {
      summaryHeader += patientInfo.first;
    }
    if (patientInfo.last) {
      summaryHeader += ' ' + patientInfo.last;
    }
    if (patientInfo.gender) {
      summaryHeader += ' - ' + patientInfo.gender;
    }
    if (patientInfo.age) {
      summaryHeader += ' - ' + patientInfo.age;
    }
    summaryHeader += '</h2>';
    summaryHeader +=
      '<table cellpadding="5" cellspacing="0" style="width:100%" class="note-table"><tbody><tr>';
    if (survey.surveyNames) {
      summaryHeader += '<td style="width:50%">';
      if (survey.surveyNames.length > 1) {
        summaryHeader += '<p><b>Questionnaires:</b> ';
      } else {
        summaryHeader += '<p><b>Questionnaire:</b> ';
      }
      summaryHeader +=
        survey.surveyNames.map((e) => e.name).join(', ') + '</p>';
      summaryHeader += '</td>';
    }
    summaryHeader +=
      '<td style="width:50%"><p><b>DOB:</b> ' +
      patientInfo.day +
      ' / ' +
      patientInfo.month +
      ' / ' +
      patientInfo.year +
      '</p></td>';
    summaryHeader += '</tr><tr>';
    if (patientInfo.phone) {
      summaryHeader +=
        '<td style="width:50%"><p><b>Phone:</b> ' +
        this.formatPhoneNumber(patientInfo.phone) +
        '</p></td>';
    }
    if (patientInfo.email) {
      summaryHeader +=
        '<td style="width:50%"><p><b>Email:</b> ' +
        patientInfo.email +
        '</p></td>';
    }
    summaryHeader += '</tr><tr>';
    if (patientInfo.healthCardNumber) {
      summaryHeader +=
        '<td style="width:50%"><p><b>Health Card Number:</b> ' +
        patientInfo.healthCardNumber +
        '</p></td>';
    }
    if (patientInfo.address) {
      summaryHeader +=
        '<td style="width:50%"><p><b>Patient Address:</b> ' +
        patientInfo.address +
        (patientInfo.city ? ', ' + patientInfo.city : '') +
        (patientInfo.postalCode ? ', ' + patientInfo.postalCode : '') +
        (patientInfo.province ? ', ' + patientInfo.province : '') +
        '</p></td>';
    }
    summaryHeader += '</tr>';
    summaryHeader += '</tbody></table>';
    return summaryHeader;
  } // end func

  formatPhoneNumber(s) {
    try {
      const s2 = ('' + s).replace(/\D/g, '');
      const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
      return !m ? s : '(' + m[1] + ') ' + m[2] + '-' + m[3];
    } catch (error) {
      console.log(error);
      return s;
    }
  }

  // format responses for summary
  formatSummaryResponses(responses) {
    let summaryResponses =
      '<table cellpadding="5" cellspacing="0" style="width:100%" class="note-table"><tbody>';
    responses.forEach((response) => {
      if (response.question && response.question.flag) {
        summaryResponses +=
          '<tr><td style="color:' +
          response.question.flag +
          '"><span><b>' +
          response.question.text +
          '</b><br/>' +
          response.answer +
          '</span></td></tr>';
      } else if (response.paragraph) {
        summaryResponses +=
          '<tr><td><span>' + response.answer + '</span></td></tr>';
      } else {
        summaryResponses +=
          '<tr><td><span><b>' +
          response.question.text +
          '</b><br/>' +
          response.answer +
          '</span></td></tr>';
      }
    });
    summaryResponses += '</tbody></table>';
    return summaryResponses;
  } // end func

  addIntegrationResponse(answer, survey) {
    if (!answer.integrationID) {
      return false;
    }
    if (!answer.integrationType || answer.integrationType === 'checkmark') {
      survey.integrationResponses[answer.integrationID] = '✔';
    }
    if (answer.integrationType === 'text' && answer.integrationText) {
      survey.integrationResponses[answer.integrationID] =
        answer.integrationText;
    }
    if (answer.integrationType === 'image' && answer.integrationImage) {
      // split to remove first part --- base64/png etc.
      survey.integrationResponses[
        'image:' + answer.integrationID
      ] = answer.integrationImage.split(',')[1];
    }
  } // end func

  async generatePDF(
    survey: any,
    path: string,
    companyID: string,
    patients: string[]
  ): Promise<any> {
    const integrationResponses = survey.integrationResponses;
    const patientInfo = survey.patientInfo;
    // add the patientInfo to the integrationResponses
    for (const item in patientInfo) {
      if (patientInfo[item]) {
        integrationResponses['patientInfo-' + item] = patientInfo[item];
      }
    }
    // format phone number nicely
    if (patientInfo['phone']) {
      integrationResponses['patientInfo-phone'] = this.formatPhoneNumber(
        patientInfo['phone']
      );
    }
    integrationResponses['patientInfo-fullName'] =
      patientInfo['first'] + ' ' + patientInfo['last'];
    // add doctor data
    if (survey.doctorData) {
      const doctorData = survey.doctorData;
      for (const item in doctorData) {
        if (doctorData[item]) {
          integrationResponses['doctorData-' + item] = doctorData[item];
        }
      }
      // format phone number nicely
      if (doctorData['phone']) {
        integrationResponses['doctorData-phone'] = this.formatPhoneNumber(
          doctorData['phone']
        );
      }
      if (doctorData['title']) {
        integrationResponses['doctorData-fullName'] =
          doctorData['title'] +
          ' ' +
          integrationResponses['doctorData-first'] +
          ' ' +
          integrationResponses['doctorData-last'];
      } else {
        integrationResponses['doctorData-fullName'] =
          integrationResponses['doctorData-first'] +
          ' ' +
          integrationResponses['doctorData-last'];
      }
    }
    // add company data
    if (survey.companyData) {
      const companyData = survey.companyData;
      for (const item in companyData) {
        if (companyData[item]) {
          integrationResponses['companyData-' + item] = companyData[item];
        }
      }
      if (companyData.branding) {
        integrationResponses['image:companyData-branding'] = (
          await this.toDataUrl(companyData.branding)
        ).split(',')[1];
      }
    }

    const birthday = new Date(
      patientInfo['year'],
      patientInfo['month'] - 1,
      patientInfo['day']
    );
    const birthdayString =
      birthday.toLocaleString('en-us', { month: 'long' }) +
      ' ' +
      birthday.getDate() +
      ', ' +
      birthday.getFullYear();
    integrationResponses['patientInfo-birthday'] = birthdayString;
    const age = this.getAge(birthday);
    integrationResponses['patientInfo-age'] = age;

    // add date completed
    integrationResponses['surveyInfo-date'] = new Date().toDateString();
    integrationResponses['surveyInfo-year'] = new Date().getFullYear();
    integrationResponses['surveyInfo-month'] = new Date().getMonth() + 1;
    integrationResponses['surveyInfo-day'] = new Date().getDate();
    return this.http
      .post(
        this.fillPDFURL,
        JSON.stringify({
          pdfData: {
            data: integrationResponses,
          },
          template: survey.pdfID,
          path,
          token: this.firebaseAuthService.getFirebaseCurrentUser()
            ? await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken()
            : null,
          company: companyID || null,
          patients: patients || null,
        }),
        { headers: this.headers }
      )
      .toPromise()
      .then((response: any) => {
        console.log('PDF created!');
        survey.pdfResponse = true;
        return response;
      })
      .catch((error) => {
        survey.pdfResponse = true;
        console.error(error);
      });
  }

  /**
   * Converts Uint8Array to base64 string
   * @param  buffer ArrayBuffer of Uint8
   * @return        Raw base64 string
   */
  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    const bytes = new Uint8Array(buffer);
    let binary = '';
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  async toDataUrl(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = (error) => {
        reject(error);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  buildCPPResult(item) {
    let resultArray = [];
    // console.log(item);
    try {
      switch (item.type) {
        case 'heightWeight':
          const tempVar = this.getheightWeightResponse(item);
          return tempVar ? tempVar : '';
        case 'doctors':
          console.log(item.response);
          return item.response &&
            item.response.selectedAnswers &&
            item.response.selectedAnswers[0]
            ? 'Dr. ' + item.response.selectedAnswers
            : '';
        case 'medications':
          if (item.response.noMeds) {
            return '';
          }
          if (
            item.response.selectedAnswers &&
            item.response.selectedAnswers[-1]
          ) {
            item.response.selectedAnswers.push(
              item.response.selectedAnswers[-1]
            );
          }
          if (item.response.selectedAnswers) {
            item.response.selectedAnswers[-1] = null;
          }
          return item.response.selectedAnswers.join(', ');
        case 'conditions':
          if (item.response.answer !== 'Yes') {
            return '';
          }
          item.response.selectedCheckboxes.forEach((issue) => {
            if (issue.selected) {
              resultArray.push(issue.condition);
            }
          });
          if (
            item.response.selectedAnswers &&
            item.response.selectedAnswers[-1]
          ) {
            item.response.selectedAnswers.push(
              item.response.selectedAnswers[-1]
            );
          }
          if (item.response.selectedAnswers) {
            item.response.selectedAnswers[-1] = null;
          }
          resultArray = resultArray.concat(item.response.selectedAnswers);
          return resultArray.join(', ');
        case 'medicationAllergies':
          if (item.response.answer !== 'Yes') {
            return '';
          }
          item.response.selectedCheckboxes.forEach((issue) => {
            if (issue.selected) {
              resultArray.push(issue.medication);
            }
          });
          if (
            item.response.selectedAnswers &&
            item.response.selectedAnswers[-1]
          ) {
            item.response.selectedAnswers.push(
              item.response.selectedAnswers[-1]
            );
          }
          if (item.response.selectedAnswers) {
            item.response.selectedAnswers[-1] = null;
          }
          resultArray = resultArray.concat(item.response.selectedAnswers);
          return resultArray.join(', ');
        case 'surgery':
          if (item.response.answer !== 'Yes') {
            return '';
          }
          item.response.selectedCheckboxes.forEach((issue) => {
            if (issue.selected) {
              resultArray.push(issue.surgery);
            }
          });
          if (
            item.response.selectedAnswers &&
            item.response.selectedAnswers[-1]
          ) {
            item.response.selectedAnswers.push(
              item.response.selectedAnswers[-1]
            );
          }
          if (item.response.selectedAnswers) {
            item.response.selectedAnswers[-1] = null;
          }
          resultArray = resultArray.concat(item.response.selectedAnswers);
          return resultArray.join(', ');
        case 'textarea':
          return item.response ? item.response.answer : '';
        case 'grid':
          console.log('grid cpp element');
          return this.getGridResponse(item);
      }
    } catch (error) {
      // catch weird dictionary array, make back into array
      if (item.response && item.response.selectedAnswers) {
        const tempArray = [];
        for (const i in item.response.selectedAnswers) {
          if (item.response.selectedAnswers[i]) {
            tempArray.push(item.response.selectedAnswers[i]);
          }
        }
        item.response.selectedAnswers = tempArray;
      }

      console.log(error);
    }
    return '';
  }

  generateRandomID(length) {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  formatCPPData(patientCPPData) {
    const cppData = {};
    for (const item in patientCPPData) {
      if (patientCPPData[item]) {
        if (item === 'custom' || item === 'rosterStatus' || item === 'rosterDate' || item === 'rosterOrigin') {
          cppData[item] = patientCPPData[item];
          continue;
        }
        cppData[item] = {
          type: item,
          response: patientCPPData[item],
        };
        // cleanup arrays that turned into dictionary
        if (cppData[item].response && cppData[item].response.selectedAnswers) {
          const tempArray = [];
          for (const i in cppData[item].response.selectedAnswers) {
            if (cppData[item].response.selectedAnswers[i]) {
              tempArray.push(cppData[item].response.selectedAnswers[i]);
            }
          }
          cppData[item].response.selectedAnswers = tempArray;
        }
        // set result string
        cppData[item].result = this.buildCPPResult(cppData[item]);
      }
    }
    return cppData;
  }

  formatCustomCPPData(patientCPPData) {
    console.log(patientCPPData);
    const customCppElements = [];
    const customCppData = {};
    for (const s in patientCPPData) {
      if (patientCPPData[s]) {
        const survey = patientCPPData[s];
        for (const q in survey) {
          if (survey[q]) {
            const question = survey[q];
            customCppElements.push({
              id: question.id,
              title: question.text,
            });

            customCppData[question.id] = question;

            if (
              customCppData[question.id].response &&
              customCppData[question.id].response.selectedAnswers
            ) {
              const tempArray = [];
              for (const i in customCppData[question.id].response
                .selectedAnswers) {
                if (customCppData[question.id].response.selectedAnswers[i]) {
                  tempArray.push(
                    customCppData[question.id].response.selectedAnswers[i]
                  );
                }
              }
              customCppData[question.id].response.selectedAnswers = tempArray;
            }
            customCppData[question.id].result = this.buildCPPResult(question);
          }
        }
      }
    }
    return { customCppElements, customCppData };
  }

  getCPPElements() {
    return this.cppElements;
  }
} // end service
