import { Component, OnInit, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NoteManagementService } from 'insig-app/services/note-management/note-management.service';
import { SpringNote } from 'insig-types/spring-api/notes';

@Component({
  selector: 'view-note-dialog',
  templateUrl: './view-note-dialog.component.html',
  styleUrls: ['./view-note-dialog.component.scss'],
})
export class ViewNoteDialogComponent implements OnInit {
  public readonly dialogRef = inject<MatDialogRef<ViewNoteDialogComponent>>(MatDialogRef<ViewNoteDialogComponent>);
  public readonly data = inject<{ note: SpringNote & { note: string } }>(MAT_DIALOG_DATA);
  private readonly noteManagementService = inject(NoteManagementService);
  private readonly domSanitizer = inject(DomSanitizer);
  public static readonly DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
    width: '100%',
  };

  public pdfUrl: SafeResourceUrl | undefined;

  async ngOnInit() {
    const note = this.data.note;
    const pdfBlob = await this.noteManagementService.getNotePdf(note.companyId, note.noteId);
    this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(pdfBlob));
  }
}
