<mat-toolbar>
  <div class="d-flex align-items-center w-100">
    <!-- begin back button -->
    <button
      mat-mini-fab
      color="none"
      class="m-2"
      *ngIf="selectedFolder && selectedFolder.id"
      (click)="previousFolder.emit()"
    >
      <mat-icon class="notranslate">keyboard_backspace</mat-icon>
    </button>
    <!-- end back button -->

    <!-- begin NEW button -->
    <button
      mat-raised-button
      class="primary-color-text"
      [insMenuTriggerFor]="newButtonMenu"
    >
      NEW
    </button>
    <ins-menu #newButtonMenu="insMenu">
      <button mat-menu-item (click)="createNewSurvey.emit()">
        <mat-icon class="notranslate">note_add</mat-icon>
        <span>New Questionnaire</span>
      </button>
      <button mat-menu-item (click)="newFolder.emit()">
        <mat-icon class="notranslate">create_new_folder</mat-icon>
        <span>New Folder</span>
      </button>
    </ins-menu>
    <!-- end NEW button -->

    <!-- begin search bar -->
    <ins-searchbar
      class="px-2 flex align-self-stretch"
      placeholder="Search"
      [(ngModel)]="searchValue"
    ></ins-searchbar>
    <!-- end search bar -->
  </div>
</mat-toolbar>
