// @ts-strict-ignore
import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'insig-sidenav-menu',
  templateUrl: './insig-sidenav-menu.component.html',
  styleUrls: ['./insig-sidenav-menu.component.scss'],
})
export class InsigSidenavMenuComponent implements OnChanges {
  private _selectedTab: any;
  public mobile = false;
  public showToolBar = false;
  public showDataScreenMinWidth = true;
  public hideSecondMenuSelect = false;
  @Input() selectedGroup: string;
  @Input() selectedCategoryItem = 'all';
  @Input() containerSpacing = true;

  @Output() addNewCategory = new EventEmitter();

  @Output() menuItemSelected = new EventEmitter();

  @Output() selectedTabChange = new EventEmitter();
  set selectedTab(val) {
    this._selectedTab = val;
    this.selectedTabChange.emit(this._selectedTab);
  }

  @Input() get selectedTab() {
    return this._selectedTab;
  }

  @Input() menuItems: any;

  constructor() {
    if (window.innerWidth < 768) {
      this.mobile = true;
    }
  }

  changeTab(item: string) {
    this.selectedTab = item;
  }

  ngOnChanges(_changes: any) {
    // console.log(changes)
    // this.selectedCategory = obj;
    if (window.innerWidth < 991) {
      this.showDataScreenMinWidth = false;
      if (this.selectedCategoryItem) {
        this.hideSecondMenuSelect = true;
        this.showDataScreenMinWidth = true;
        this.showToolBar = true;
      }
    } else if (window.innerWidth >= 991) {
      this.showToolBar = false;
      this.showDataScreenMinWidth = true;
      this.hideSecondMenuSelect = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // console.log(event.target.innerWidth)
    // console.log(event.target.innerWidth >= 1200)
    if (event.target.innerWidth < 991) {
      this.showDataScreenMinWidth = false;
      if (this.selectedCategoryItem) {
        this.hideSecondMenuSelect = true;
        this.showDataScreenMinWidth = true;
        this.showToolBar = true;
      }
    } else if (event.target.innerWidth >= 991) {
      this.showToolBar = false;
      this.showDataScreenMinWidth = true;
      this.hideSecondMenuSelect = false;
    }
  }

  backToMenu() {
    this.hideSecondMenuSelect = false;
    this.showDataScreenMinWidth = false;
    this.showToolBar = false;
  }
}
