import {
  Directive,
  Output,
  EventEmitter,
  Optional,
  HostListener,
  OnInit,
} from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import { RegexService } from '../services/src/regex/regex.service';

@Directive({
  selector: '[insig-phone-mask]',
  exportAs: 'insig-phone-mask',
})
export class PhoneMaskDirective implements OnInit {
  static readonly PHONE_NUMBER_REGEX = /^\((\d{3})\)\s\d{3}-\d{4}$/;
  static readonly THREE_DIGITS_OR_LESS_PHONE_NUMBER_REGEX = /^(\d{0,3})/;
  static readonly SIX_DIGITS_OR_LESS_PHONE_NUMBER_REGEX = /^(\d{0,3})(\d{0,3})/;
  static readonly SEVEN_DIGITS_OR_MORE_PHONE_NUMBER_REGEX = /^(\d{0,3})(\d{0,3})(.*)/;

  static readonly PHONE_NUMBER_MAX_LENGTH = 10;

  constructor(
    private regexService: RegexService,
    @Optional() public ngControl: NgControl,
  ) {}

  @Output() rawChange: EventEmitter<string> = new EventEmitter<string>();
  ngOnInit(): void {
    this.formatControl(this.ngControl);
  }

  @HostListener('ngModelChange')
  onNgModelChange(): void {
    this.formatControl(this.ngControl);
  }

  private formatControl(ngControl: NgControl | NgModel | undefined): void {
    if (ngControl) {
      setTimeout(() => {
        const formattedPhoneNumber = this.getFormattedPhoneNumberFromControl(ngControl);
        ngControl.control?.setValue(formattedPhoneNumber);
      }, 0);
    }
  }

  getFormattedPhoneNumberFromControl(ngControl: NgControl | NgModel): string {
    const inputValue = this.isNgModel(ngControl) ? ngControl.model : ngControl.control?.value;
    if (inputValue === undefined || inputValue === null) {
      return '';
    } else {
      return this.formatPhoneNumber(inputValue);
    }
  }

  isNgModel(ngControl: NgControl | NgModel): ngControl is NgModel {
    return "model" in ngControl;
  }

  @HostListener('input', ['$event'])
  onInputChanged(event: InputEvent): void {

    const target = event.target as HTMLInputElement;
    let phoneNumber = target.value;

    phoneNumber = phoneNumber.replace(this.regexService.getNonDigitGlobalRegex(), '');
    phoneNumber = this.formatPhoneNumber(phoneNumber);

    target.value = phoneNumber;

    if (!!this.ngControl && !!this.ngControl.control) {
      this.ngControl.control.setValue(phoneNumber); // Necessary to validate chrome autofill
    }
    this.rawChange.emit(phoneNumber.replace(this.regexService.getNonDigitGlobalRegex(), ''));
    target.selectionStart = phoneNumber.length;
    target.selectionEnd = phoneNumber.length;
  }

  @HostListener('keydown.backspace', ['$event']) onBackspace(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    if (
      target.selectionStart === target.selectionEnd &&
      target.value.length <= 5
    ) {
      target.value = target.value.substring(
        0,
        target.value.length - 1
      );
    }
  }

  formatPhoneNumber(phoneNumber: string): string {
    let formattedPhoneNumber: string;
    formattedPhoneNumber = phoneNumber.replace(this.regexService.getNonDigitGlobalRegex(), '');

    if (formattedPhoneNumber.length === PhoneMaskDirective.PHONE_NUMBER_MAX_LENGTH + 1 && formattedPhoneNumber[0] === "1") {
      formattedPhoneNumber = formattedPhoneNumber.substring(1);
    }

    if (formattedPhoneNumber.length > PhoneMaskDirective.PHONE_NUMBER_MAX_LENGTH) {
      formattedPhoneNumber = formattedPhoneNumber.slice(0, PhoneMaskDirective.PHONE_NUMBER_MAX_LENGTH);
    }

    // don't show braces for empty value
    if (formattedPhoneNumber.length === 0) {
      formattedPhoneNumber = '';
    } else if (formattedPhoneNumber.length <= 3) {
      formattedPhoneNumber = formattedPhoneNumber.replace(PhoneMaskDirective.THREE_DIGITS_OR_LESS_PHONE_NUMBER_REGEX, '($1)');
    } else if (formattedPhoneNumber.length <= 6) {
      formattedPhoneNumber = formattedPhoneNumber.replace(PhoneMaskDirective.SIX_DIGITS_OR_LESS_PHONE_NUMBER_REGEX, '($1) $2');
    } else {
      formattedPhoneNumber = formattedPhoneNumber.replace(PhoneMaskDirective.SEVEN_DIGITS_OR_MORE_PHONE_NUMBER_REGEX, '($1) $2-$3');
    }

    return formattedPhoneNumber;
  }
}
