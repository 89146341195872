import { Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'insig-health-page-login',
  templateUrl: './login.component.html',
})
export class PageLoginComponent {
  public readonly router = inject(Router);
  @Input() companyBranding: string | undefined;
  @Input() virtualCareLogin = false;
  @Input() doctorID: string | undefined;
  @Input() patientID: string | undefined;
  @Input() patientLogin = false;

  redirect(event: string | null): void {
    this.router.navigate([event]);
  }
}
