<label class="w-100">
  <span class="bold textSecondary">{{ label }}</span>
  <span *ngIf="_isRequired" class="required">&nbsp;*</span>
  <input [type]="type"
    #inputElement
    [name]="name"
    class="bold fs-6"
    [ngClass]="{
      'mt-1': smallMargins === true,
      'mt-3': smallMargins === false
    }"
    [value]="value"
    [attr.autocomplete]="autocomplete"
    [placeholder]="placeholder"
    [readonly]="_isReadOnly"
    [disabled]="_isDisabled"
    (input)="handleChange($event)"
    (change)="handleChange($event)"
    (blur)="handleBlur()"
  />
</label>
