<!-- #region allowMultiple is true -->
<ng-container *ngIf="question.allowMultiple">
  <!-- #region chip container -->
  <div class="row ml-1">
    <div class="col-8 offset-2">
      <mat-chip-listbox class="text-center">
        <div *ngFor="let item of question.response.selectedAnswers">
          <span class="space space-md"></span>
          <span color="primary">{{ item }} &nbsp;</span>
          <button mat-mini-fab color="none" (click)="removeItem(item)">
            <mat-icon class="notranslate">clear</mat-icon>
          </button>
        </div>
      </mat-chip-listbox>
    </div>
  </div>
  <!-- #endregion chip container -->

  <!-- #region input field -->
  <div class="row ml-1">
    <div class="col-8 offset-2">
      <mat-form-field class="full-width">
        <mat-label>Enter answer</mat-label>
        <input
          matInput
          (keyup.enter)="addItem(question.response.selectedAnswers[-1])"
          [(ngModel)]="question.response.selectedAnswers[-1]"
          [matAutocomplete]="auto"
        />
      </mat-form-field>
    </div>
  </div>
  <!-- #endregion input field -->

  <!-- #region autocomplete component -->
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      *ngFor="let item of filteredItems"
      (click)="addItem(item)"
      [value]="item"
    >
      <mat-icon class="notranslate">add_circle_outline</mat-icon> {{ item }}
    </mat-option>
    <mat-option
      *ngIf="!!question.response.selectedAnswers[-1]"
      (click)="addItem(question.response.selectedAnswers[-1])"
      [value]="question.response.selectedAnswers[-1]"
    >
      <mat-icon class="notranslate">add_circle_outline</mat-icon>
      {{ question.response.selectedAnswers[-1] }}
    </mat-option>
  </mat-autocomplete>
  <!-- #endregion autocomplete component -->
</ng-container>
<!-- #endregion allowMultiple is true -->

<!-- #region allowMultiple is false -->
<ng-container *ngIf="!question.allowMultiple">
  <div class="form-group row">
    <div class="col-8 offset-2">
      <mat-form-field class="full-width">
        <mat-label>Enter answer</mat-label>
        <input
          matInput
          [(ngModel)]="question.response.selectedAnswers[0]"
          [matAutocomplete]="auto"
        />
      </mat-form-field>
    </div>
  </div>

  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let item of filteredItems" [value]="item">
      {{ item }}
    </mat-option>
  </mat-autocomplete>
</ng-container>
<!-- #endregion allowMultiple is false -->
