<div #lineClampedText class="lineClampedText text-break"
  [style.-webkit-line-clamp]="lineClamp"
>
  <ng-content></ng-content>
</div>
<div *ngIf="isElementTextClamped(lineClampedText)"
  role="button"
  class="bold font-size-xs textGray readMoreButton d-inline"
  (click)="handleReadMoreClicked($event)"
>
  Read more
</div>
