<section class="insig-customizer ml-auto mr-auto" id="insig-customizer">
  <a class="customizer-close" href="javascript:;">
    <mat-icon class="notranslate">close</mat-icon>
  </a>
  <!-- <a class="customizer-toggle" href="javascript:;" myToggleQuickview="customizer"><span class="material-icons"><div class="notranslate">settings</div></span></a> -->

  <div class="quickview-inner" mySlimScroll>
    <div>
      <p class="customizer-header">Customizer</p>
      <p class="small no-margin">Customize and preview in real time.</p>

      <div class="divider divider-lg divider-solid"></div>

      <h4 class="section-header">Custom Colors</h4>
      <!-- <p class="small no-margin">Tip: Additionally, you can active "Full Width Header" above</p> -->
      <div class="divider"></div>
      <div class="row my-4">
        <insig-color-picker
          class="col-6"
          [color]="primaryColor"
          [heading]="'Primary'"
          (event)="setColor('primary', $event)"
        ></insig-color-picker>
        <insig-color-picker
          class="col-6"
          [color]="secondaryColor"
          [heading]="'Secondary'"
          (event)="setColor('secondary', $event)"
        ></insig-color-picker>
      </div>
      <div class="row my-4">
        <insig-color-picker
          class="col-4"
          [color]="fontColor"
          [heading]="'Text'"
          (event)="setColor('font', $event)"
        ></insig-color-picker>
        <insig-color-picker
          class="col-4"
          [color]="fontSecondary"
          [heading]="'Text Secondary'"
          (event)="setColor('fontSecondary', $event)"
        ></insig-color-picker>
        <insig-color-picker
          class="col-4"
          [color]="fontSelected"
          [heading]="'Text Selected'"
          (event)="setColor('fontSelected', $event)"
        ></insig-color-picker>
      </div>

      <div class="divider divider-lg divider-solid"></div>

      <button
        mat-raised-button
        class="primary-color-full"
        (click)="saveCompanyData()"
      >
        Save Company Theme
      </button>
    </div>
  </div>
</section>
