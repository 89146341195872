<mat-toolbar
  class="primary-color-full company-plan-toolbar"
  style="position:fixed;"
>
  <mat-toolbar-row>
    <span>
      Practitioners Scheduled on {{ daySelected | date: 'longDate' }}</span
    >
    <span class="specific-spacer"></span>
    <mat-icon
      class="notranslate speicfic-icon"
      (click)="toggleBottomSheet.emit()"
      >close</mat-icon
    >
  </mat-toolbar-row>
</mat-toolbar>
<div class="container-fluid no-breadcrumbs overflow-auto">
  <div class="row ">
    <div>
      <table
        class="table table-hover"
        style="table-layout: fixed;word-wrap: break-word;"
        *ngIf="scheduleSelected?.users.length > 0"
      >
        <thead>
          <tr>
            <th scope="col" style="width:40%">Practitioner</th>
            <th scope="col" style="width:30%">Schedule</th>
            <th scope="col" style="width:30%"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of scheduleSelected.users; let i = index">
            <td>
              <h4 class="service-header full-width">
                {{ fullUserMap[user].first }} {{ fullUserMap[user].last }}
              </h4>
            </td>
            <td>
              <h4 class="service-header full-width">
                {{ getDoctorRange(user) }}
              </h4>
            </td>

            <td (click)="selectDoctor.emit(service)">
              <button mat-raised-button (click)="selectDoctor.emit(user)">
                <mat-icon class="notranslate">mode_edit</mat-icon>Edit
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row text-center" *ngIf="scheduleSelected?.users.length == 0">
        <h4 class="col-12">No practitioners scheduled!</h4>
        <div class="divider divider-grey"></div>

        <div
          class="button-secondary primary-color-full"
          (click)="addServicesBtnClicked.emit()"
        >
          <div
            class="d-flex flex-row justify-content-center align-items-center"
          >
            <mat-icon class="notranslate">note_add</mat-icon>
            <span>&nbsp;Add Service</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row p-5">
    <div class="col-6">
      <button
        mat-raised-button
        class="float-right primary-color-full"
        (click)="toggleBottomSheet.emit()"
      >
        Cancel
      </button>
    </div>

    <!-- <div class="col-6">
        <button mat-raised-button class="primary-color-full float-right" (click)="companyPlanSettingsToggle.emit(true)">Save</button>
      </div> -->
  </div>
</div>
