import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';

import { APPCONFIG } from '@insig-health/config/config';
// services
import { TranslateService } from '@insig-health/services/translate/translate.service';
import { VirtualVisitService, UnreadMessageNotification } from 'insig-app/services/virtual-visit.service';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';
import { BehaviorSubject, combineLatest, filter, firstValueFrom, map, shareReplay, switchMap, tap } from 'rxjs';

interface UnreadMessageNotificationWithName extends UnreadMessageNotification {
  name: string;
}

@Component({
  selector: 'my-app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [TranslateService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly translateService = inject(TranslateService);
  private readonly virtualVisitService = inject(VirtualVisitService);
  private readonly firebaseAuthService = inject(FirebaseAuthService);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  public readonly INITIAL_UNREAD_MESSAGES_LIMIT = 100;
  public readonly DEFAULT_MESSAGE_NAME = 'New Message!';
  private readonly UNREAD_MESSAGES_PAGINATION_INCREMENT = 10;
  private _unreadMessagesLimit$ = new BehaviorSubject(this.UNREAD_MESSAGES_PAGINATION_INCREMENT);

  // the conversation lambda endpoint does not have proper caching, so we need to cache the names of the conversations
  private unreadConversationNameCache = new Map<string, string>();

  private user$ = this.firebaseAuthService.onIdTokenChanged().pipe(filter((user) => !!user));
  private userId$ = this.user$.pipe(map((user) => user?.uid), filter((uid) => !!uid));
  private idToken$ = this.user$.pipe(switchMap((user) => user.getIdToken()));
  public allUnreadMessageNotifications$ = this.userId$.pipe(
    switchMap((userId) => this.virtualVisitService.getUnreadMessageNotifications(userId)),
    map((unreadMessageNotifications) => this.sortUnreadMessageNotificationsByMostRecent(unreadMessageNotifications)),
    tap(() => this.changeDetectorRef.markForCheck()),
    shareReplay(1),
  );

  public paginatedUnreadMessageNotifications$ = combineLatest([this.allUnreadMessageNotifications$, this._unreadMessagesLimit$]).pipe(
    map(([unreadMessageNotifications, limit]) => {
      return unreadMessageNotifications.slice(0, limit);
    }),
    switchMap(async (unreadMessageNotifications) => {
      const names = await Promise.all(unreadMessageNotifications.map(async (unreadMessageNotification) => {
        if (this.unreadConversationNameCache.has(unreadMessageNotification.messageId)) {
          return this.unreadConversationNameCache.get(unreadMessageNotification.messageId);
        } else {
          const name = await this.getNameOfUnreadMessageNotification(unreadMessageNotification);
          this.unreadConversationNameCache.set(unreadMessageNotification.messageId, name);
          return name;
        }
      }));
      return unreadMessageNotifications.map((unreadMessageNotification, index) => ({ ...unreadMessageNotification, name: names[index] } as UnreadMessageNotificationWithName));
    }),
    tap(() => this.changeDetectorRef.markForCheck()),
    shareReplay(1),
  );

  public AppConfig: any = APPCONFIG;
  public flag: string | undefined;
  public languageList: string[] = [];
  public loadingMessages = false;

  async ngOnInit(): Promise<void> {
    this.languageList = await this.translateService.getLanguageList();
  }

  sortUnreadMessageNotificationsByMostRecent(unreadMessageNotifications: UnreadMessageNotification[]): UnreadMessageNotification[] {
    return unreadMessageNotifications.sort((a, b) =>
      b.latestMessageEpochMilliseconds - a.latestMessageEpochMilliseconds,
    );
  }

  trackByMessageId(_index: number, unreadMessageNotification: UnreadMessageNotification): string {
    return unreadMessageNotification.messageId;
  }

  async getNameOfUnreadMessageNotification(unreadMessageNotification: UnreadMessageNotification): Promise<string> {
    let name: string | undefined;
    try {
      const conversationAdditionalDetails = await this.virtualVisitService.loadConversationAdditionalDetails(
        await firstValueFrom(this.idToken$),
        unreadMessageNotification.messageId,
        unreadMessageNotification.fromUserId,
      );
      if (conversationAdditionalDetails?.statusCode === 200) {
        name = conversationAdditionalDetails?.body?.name;
      }
    } catch (error) {
      console.error(error);
    }
    return name ?? this.DEFAULT_MESSAGE_NAME;
  }

  handleUnreadMessageNotificationClicked(unreadMessageNotification: UnreadMessageNotification): void {
    this.router.navigate(['app/virtual/dashboard']);
    // time out so can properly move between appointments and reload everything
    setTimeout(async () => {
      this.router.navigate([`app/virtual/messaging/${unreadMessageNotification.messageId}`]);
    }, 1000);
  }

  capitalize(value: any) {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  }

  async switchLanguage(language: any) {
    this.translateService.changeLanguage(language);
  }

  async logout() {
    await this.firebaseAuthService.signOutThenReturnToLoginPage();
  }

  async loadMoreUnreadMessages(): Promise<void> {
    const allUnreadMessageNotificationsCount = await firstValueFrom(this.allUnreadMessageNotifications$.pipe(map((notifications) => notifications.length)));
    if (this._unreadMessagesLimit$.value < allUnreadMessageNotificationsCount) {
      this.loadingMessages = true;
      this._unreadMessagesLimit$.next(this._unreadMessagesLimit$.value + this.UNREAD_MESSAGES_PAGINATION_INCREMENT);
    } else {
      this.loadingMessages = false;
      this.changeDetectorRef.markForCheck();
    }
  }
}
