import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ins-searchbar',
  templateUrl: './insig-searchbar.component.html',
  styleUrls: ['../insig-material.shared.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InsigSearchbarComponent),
      multi: true,
    },
  ],
})
export class InsigSearchbarComponent implements ControlValueAccessor {
  @Input() placeholder = '';

  public searchValue = '';
  writeValue(searchValue: string): void {
    this.searchValue = searchValue;
  }

  public onChange = (_searchValue: string) => {
    /** Do nothing */
  };
  registerOnChange(fn: (searchValue: string) => void): void {
    this.onChange = fn;
  }

  public onTouched = () => {
    /** Do nothing */
  };
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public disabled = false;
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
