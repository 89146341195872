// @ts-strict-ignore
import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AWSCLOUDFUNCTIONSENDPOINT } from '@insig-health/config/config';

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  private readonly http = inject(HttpClient);
  private getAllAppointmentsToCSV =
    AWSCLOUDFUNCTIONSENDPOINT + 'reporting/getAllAppointmentsToCSV';
  private getSurveyDataCSVURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'reporting/getSurveyDataCSV';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  getAppointmentsToCSV(IDToken:string, selectedMonth:number): any {
    let startOfMonth = new Date(new Date(selectedMonth).getFullYear(), new Date(selectedMonth).getMonth(), 1).getTime();
    let endOfMonth = new Date(new Date(selectedMonth).getFullYear(), new Date(selectedMonth).getMonth() + 1, 1).getTime();
    return this.http
      .post(
        this.getAllAppointmentsToCSV,
        { IDToken, startOfMonth, endOfMonth },
        { headers: this.headers }
      )
      .toPromise();
  }

  getSurveyDataToCSV(includePatient, IDToken, surveyData): any {
    return this.http
      .post(
        this.getSurveyDataCSVURL,
        { includePatient, IDToken, surveyData },
        { headers: this.headers }
      )
      .toPromise();
  }
}
