<mat-card>
  <mat-card-title>Create a Company Plan</mat-card-title>
  <mat-card-content>
    <div class="row">Company Plan Title:</div>
    <div class="row m-0">
      <form [formGroup]="form" class="full-width">
        <div class="form-group">
          <mat-form-field class="full-width">
            <mat-label>Plan Title</mat-label>
            <input
              matInput
              required
              type="text"
              formControlName="planTitle"
            />
            <mat-error>Required</mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
  </mat-card-content>
  <div class="divider"></div>
  <div class="divider"></div>
  <mat-card-actions>
    <button mat-raised-button (click)="dialogRef.close(false)">Cancel</button>
    <button
      mat-raised-button
      color="primary"
      class="float-right"
      (click)="addCompanyPlan()"
    >
      Create Plan
    </button>
  </mat-card-actions>
</mat-card>
