import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'link-edit',
  templateUrl: './link-edit.component.html',
  styleUrls: [
    './link-edit.component.scss'
  ]
})
export class LinkEditComponent {
  @Input() question: any;
  @Output() questionChange = new EventEmitter<any>();
}
