// @ts-strict-ignore
import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { fromEvent as observableFromEvent } from 'rxjs';
import { takeUntil, switchMap, pairwise } from 'rxjs/operators';

@Component({
  selector: 'signature-dialog-question',
  templateUrl: './signature-dialog.component.html',
  styleUrls: ['./signature.style.css'],
})
export class SignatureDialogComponent implements AfterViewInit {
  private readonly dialogRef = inject<MatDialogRef<SignatureDialogComponent>>(MatDialogRef<SignatureDialogComponent>);
  @Input() question = null;

  @ViewChild('canvas') public canvas: ElementRef;
  lineWidth = 3;
  canvasEl: HTMLCanvasElement = null;
  public cx: CanvasRenderingContext2D;
  dimensions = { width: 600, height: 250 };

  public ngAfterViewInit() {
    this.canvasEl = this.canvas.nativeElement;
    this.question.canvas = this.canvasEl;
    this.cx = this.canvasEl.getContext('2d');
    this.setupCanvas();
  } // end after view init

  doneDrawing() {
    this.dialogRef.close(true);
  }

  setDimensions() {
    if (window.screen.width < 500) {
      this.dimensions.width = window.screen.width * 0.85;
      this.dimensions.height = window.screen.height * 0.2;
    } else {
      this.dimensions.width = window.screen.width * 0.5;
      this.dimensions.height = window.screen.height * 0.2;
    }
  }

  setupCanvas() {
    const canvasEl = this.canvasEl;
    // set the width and height
    this.setDimensions();
    canvasEl.width = this.dimensions.width;
    canvasEl.height = this.dimensions.height;
    this.setupCanvasDefaults(canvasEl);
  }

  setupCanvasDefaults(canvasEl) {
    // set some default properties about the line
    this.cx.lineWidth = this.lineWidth;
    this.cx.lineCap = 'round';
    // This chooses the color
    this.cx.strokeStyle = '#000';
    // capturing mouse events
    this.captureEvents(canvasEl);
  }

  public clear() {
    this.cx.clearRect(0, 0, this.dimensions.width, this.dimensions.height);
  }

  private captureEvents(canvasEl: HTMLCanvasElement) {
    // mouse
    observableFromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap((_e) => {
          return observableFromEvent(canvasEl, 'mousemove').pipe(
            takeUntil(observableFromEvent(canvasEl, 'mouseup')),
            pairwise()
          );
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top,
        };
        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top,
        };
        this.drawOnCanvas(prevPos, currentPos);
      });
    // touch display
    observableFromEvent(canvasEl, 'touchstart')
      .pipe(
        switchMap((_e) => {
          return observableFromEvent(canvasEl, 'touchmove').pipe(
            takeUntil(observableFromEvent(canvasEl, 'touchend')),
            pairwise()
          );
        })
      )
      .subscribe((res: [TouchEvent, TouchEvent]) => {
        const rect = canvasEl.getBoundingClientRect();
        const prevPos = {
          x: res[0].touches[0].clientX - rect.left,
          y: res[0].touches[0].clientY - rect.top,
        };
        const currentPos = {
          x: res[1].touches[0].clientX - rect.left,
          y: res[1].touches[0].clientY - rect.top,
        };
        this.drawOnCanvas(prevPos, currentPos);
      });
    // dont move screen
  } // end capture events

  private drawOnCanvas(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ) {
    // incase the context is not set
    if (!this.cx) {
      return;
    }
    // start our drawing path
    this.cx.beginPath();
    // we're drawing lines so we need a previous position
    if (prevPos) {
      // sets the start point
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      // draws a line from the start pos until the current position
      this.cx.lineTo(currentPos.x, currentPos.y);
      // strokes the current path with the styles we set earlier
      this.cx.stroke();
    }
  } // end draw on canvas
} // end component
