<mat-card>
  <button mat-icon-button class="float-right" (click)="dialogRef.close()">
    <mat-icon class="notranslate">clear</mat-icon>
  </button>
  <h1 matDialogTitle class="mt-1">Terms & Conditions</h1>

  <div mat-dialog-content [innerHTML]="termsContent"></div>

  <mat-dialog-actions align="end">
    <button mat-button (click)="dialogRef.close(false)">Decline</button>
    <button mat-raised-button color="primary" (click)="dialogRef.close(true)">
      Accept
    </button>
  </mat-dialog-actions>
</mat-card>
