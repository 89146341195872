// @ts-strict-ignore
import {
  Component,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'select-question',
  templateUrl: './select.component.html',
  styleUrls: ['../question-types.style.css'],
})
export class SelectComponent implements OnChanges, OnDestroy {
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();
  @Input() pageElement = null;
  @Input() elementID = null;
  @Input() triggersDictionary = null;
  @Output() checkTriggersChild: EventEmitter<string> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  sendCheckTriggers() {
    this.checkTriggersChild.emit('true');
  }

  ngOnDestroy() {
    if (this.pageElement.triggerHidden === true) {
      this.removeAllTriggers();
    }
  }

  removeAllTriggers() {
    // go over each answers triggers
    for (const offeredAnswer in this.question.offeredAnswers) {
      if (
        this.question.offeredAnswers[offeredAnswer].pageFlow &&
        this.question.offeredAnswers[offeredAnswer].pageFlow.trigger
      ) {
        const triggersDictionary = this.triggersDictionary;
        const parentID = this.elementID;
        // change each childs trigger property for this parent
        this.question.offeredAnswers[offeredAnswer].pageFlow.trigger.forEach(
          (child) => {
            if (triggersDictionary[child]) {
              triggersDictionary[child][parentID] = false;
            } else {
              console.log(
                'Incorrect JSON for this question. Subquestion array error'
              );
            }
          }
        );
      } // end if
    }
    this.sendCheckTriggers();
  }

  // update triggers for each answer, index is answer selected
  modifyTriggers(index: string | number) {
    index = index.toString(); // coerce to string
    // go over each answers triggers
    for (const offeredAnswer in this.question.offeredAnswers) {
      if (
        this.question.offeredAnswers[offeredAnswer].pageFlow &&
        this.question.offeredAnswers[offeredAnswer].pageFlow.trigger
      ) {
        const changeTo = index === offeredAnswer;
        const triggersDictionary = this.triggersDictionary;
        const parentID = this.elementID;
        // change each childs trigger property for this parent
        this.question.offeredAnswers[offeredAnswer].pageFlow.trigger.forEach(
          (child) => {
            if (triggersDictionary[child]) {
              triggersDictionary[child][parentID] = changeTo;
            } else {
              console.log(
                'Incorrect JSON for this question. Subquestion array error'
              );
            }
          }
        );
      } // end if
    }
    this.sendCheckTriggers();
  } // end modify trigs
} // end component
