
<div class="row">
  <p *ngIf="!question.link">
    *Error, no video link provided! Please ignore this question.
  </p>
  
  <div *ngIf="!!question.link" class="col-12 col-md-6 mx-auto">
    <div
      class="my-4 action-button primary-color-full"
      (click)="openLink()"
    >
      <div class="justify-content-center align-items-center">
        <div class="row mx-0 mb-2 mt-0">
          <span class="title mx-auto"
            >&nbsp;
            {{ !!question.label ? question.label : question.link }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
