<mat-card>
  <h1 mat-dialog-title>{{ title }}</h1>
  <mat-dialog-content>
    <div class="pb-3">{{ message }}</div>
    <insig-health-gcp-ip-login-widget
      [disableSignUp]="true"
      [disableAutomaticLogin]="true"
      [disableCardBorder]="true"
    ></insig-health-gcp-ip-login-widget>
  </mat-dialog-content>
</mat-card>
