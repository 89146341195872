// @ts-strict-ignore
import { Component, Input, OnInit, inject } from '@angular/core';
import { PhysicalGridService } from 'insig-app/services/physicalGrid.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'physicalgrid-edit',
  templateUrl: './physicalgrid-edit.component.html',
  providers: [PhysicalGridService],
})
export class PhysicalGridEditComponent implements OnInit {
  private readonly physicalGridService = inject(PhysicalGridService);
  private readonly snackbar = inject(MatSnackBar);
  @Input() question: any;
  public questionExample: any = {
    physical: {},
    response: {
      physicalGrid: {},
      checkboxes: {},
    },
  };

  public defaultStructure = this.physicalGridService.getDefaultStructure();
  public checkboxes = this.physicalGridService.getCheckboxes();
  public hideGeneral = false;
  public showBP = false;

  ngOnInit() {
    if (!this.question.standard) {
      this.question.standard = {};
    }
    // setting up response dictionary
    const tempQuestion = {};
    const tempResponse = {};
    this.defaultStructure.forEach(((value) => {
      tempQuestion[value.id] = false;
      tempResponse[value.id] = {};
      this.question.standard[value.id] = {};
      value.sections.forEach((value2) => {
        tempResponse[value.id][value2.id] = value2;
      });
    }).bind(this));
    this.questionExample.physical = tempQuestion;
    this.questionExample.response.physicalGrid = tempResponse;
  } // end init

  explainIcon() {
    setTimeout(() => {
      this.snackbar.open(
        'This icon will autofill the text you enter under the description column.'
      );
    }, 4000);
  }
} // end component
