import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'show-surveys-toolbar',
  templateUrl: './show-surveys-toolbar.component.html',
  providers: [],
  styleUrls: [
    '../../../../../styles/surveys/show-surveys.scss',
    '../../../../../styles/global/secondary-nav/main.scss',
    './show-surveys-toolbar.component.scss'
  ],
})
export class ShowSurveysToolbarComponent {
  private _searchValue: any = '';
  private _selectedTab: any;

  // searchValue
  @Output() searchValueChange = new EventEmitter();
  set searchValue(val) {
    this._searchValue = val;
    this.searchValueChange.emit(this._searchValue);
  }

  @Input() get searchValue() {
    // this.parseVirtualArray()
    return this._searchValue;
  }

  // selectedTab
  @Output() selectedTabChange = new EventEmitter();
  set selectedTab(val) {
    this._selectedTab = val;
    this.selectedTabChange.emit(this._selectedTab);
  }

  @Input() get selectedTab() {
    // this.parseVirtualArray()
    return this._selectedTab;
  }

  // newFolderFunction
  @Output() newFolder = new EventEmitter<string>();

  // createnewsurvey function
  @Output() createNewSurvey = new EventEmitter<string>();

  @Output() previousFolder = new EventEmitter<string>();

  @Input() mobile: any;

  @Input() selectedFolder: any;
}
