<div class="form-group row" *ngIf="!question.inlineDate">
  <div class="col-2"></div>
  <div class="col-8">
    <mat-form-field (click)="picker.open()">
      <mat-label>Select a date</mat-label>
      <input
        matInput
        readonly
        autocomplete="off"
        [(ngModel)]="question.response.answer"
        [matDatepicker]="picker"
        [min]="question.noPast ? today : null"
        [max]="question.noFuture ? today : null"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #picker startView="year" [touchUi]="false"></mat-datepicker>
  </div>
  <div class="col-2"></div>
</div>

<div class="form-group row" *ngIf="question.inlineDate">
  <div class="col-12">
    <dp-day-calendar
      [ngModel]="dayjsDate"
      (ngModelChange)="updateResponseDate($event)"
      [theme]="'dp-material'"
      [config]="config"
    ></dp-day-calendar>
  </div>
</div>
