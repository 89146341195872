<section class="overflow-x-auto">
  <div class="container">
    <h3>Medical History</h3>

    <div class="row">
      <div class="col-md-6">
        <ng-container *ngIf="!!cppData">
          <h4 class="text-center">Patient Profile</h4>
          <div *ngFor="let item of cppElements">
            <mat-card class="example-card">
              <div class="px-4 py-3 d-flex align-items-start justify-content-between">
                <div>
                  <div class="h3 bold">{{ item.title }}</div>
                  <p class="mb-0" *ngIf="!editing[item.type]">
                    {{
                      cppData[item.type] && cppData[item.type].result
                        ? cppData[item.type].result
                        : '-'
                    }}
                  </p>
                </div>
                <button mat-button *ngIf="!editing[item.type]"
                  color="none"
                  (click)="toggleEditing(item)"
                >
                  Edit
                </button>
                <button mat-raised-button *ngIf="editing[item.type]"
                  color="primary"
                  (click)="toggleEditing(item)"
                >
                  Done
                </button>
              </div>
              <mat-card-content *ngIf="editing[item.type]">
                <question-types
                  [question]="cppData[item.type]"
                  (questionChange)="handleCppResponseChange($event)"
                  [pageElement]="cppData[item.type]"
                ></question-types>
              </mat-card-content>
            </mat-card>
          </div>
        </ng-container>

        <ng-container *ngIf="!cppData">
          <h4 class="text-center">Loading...</h4>
        </ng-container>
      </div>

      <div class="col-md-6">
        <h4 class="text-center">
          Medical Notes
          <!-- <span *ngIf="!!notePromises && notePromises.length !== notes.length">&nbsp;({{notes.length}}/{{notePromises.length}})</span> -->
        </h4>

        <h5 class="text-center mt-5" *ngIf="loadingNotes">
          Loading Notes... {{ notes.length }}
          {{ !!numberOfNotesAvailable ? '/ ' + numberOfNotesAvailable : '' }}
        </h5>
        <h5
          class="text-center mt-5"
          *ngIf="!loadingNotes && notes.length === 0"
        >
          You currently have no medical notes.
        </h5>

        <div class="container">
          <div class="row">
            <div
              class="card col-12 mt-0"
              *ngFor="let note of sortByTime(notes)"
              [hidden]="note.locked"
            >
              <div class="card-content text-center">
                <p
                  class="card-subtitle d-flex align-items-center text-right"
                  *ngIf="note && note.companyData"
                >
                  <img
                    *ngIf="note.companyData?.branding"
                    [src]="note.companyData?.branding"
                    style="height:50px;max-width:150px;"
                  />
                  <!-- <span class="float-right bold w-100">{{
                    note.companyData?.name
                  }}</span> -->
                </p>
                <p class="card-subtitle" *ngIf="note && note.doctorData">
                  <b
                    >{{ note.doctorData?.title }} {{ note.doctorData?.first }}
                    {{ note.doctorData?.last }}</b
                  >
                </p>
                <p class="card-subtitle" *ngIf="note && note.patientInfo">
                  {{ note.patientInfo?.first }} {{ note.patientInfo?.last }}
                </p>
                <span>{{ (note ? note.time : '') | date: 'fullDate' }}</span>
              </div>
              <div class="card-action">
                <button
                  mat-raised-button
                  class="float-right"
                  (click)="openNotePatient(note)"
                >
                  Open
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="py-3"><!--spacer--></div>
