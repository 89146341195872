<section
  class="quickview-wrapper customizer hidden-lg-down"
  id="quickview-customizer"
>
  <a class="customizer-close" href="javascript:;" myToggleQuickview="customizer"
    ><span class="material-icons">
      <div class="notranslate">close</div>
    </span></a
  >
  <!-- <a class="customizer-toggle" href="javascript:;" myToggleQuickview="customizer"><span class="material-icons"><div class="notranslate">settings</div></span></a> -->

  <div class="quickview-inner" mySlimScroll>
    <div>
      <p class="customizer-header">Customizer</p>
      <p class="small no-margin">Customize and preview in real time.</p>
      <!-- <h4 class="section-header">Layout Options</h4>
      <div class="divider divider-sm"></div>
      <mat-list>
        <mat-list-item>
          <p>Fixed Header</p>
          <mat-slide-toggle class="md-secondary" [(ngModel)]="AppConfig.fixedHeader"></mat-slide-toggle>
        </mat-list-item>
        <mat-list-item>
          <p>Collapsed Sidebar</p>
          <mat-slide-toggle class="md-secondary" [(ngModel)]="AppConfig.navCollapsed" (change)="onLayoutChange()"></mat-slide-toggle>
        </mat-list-item>
        <mat-list-item>
          <p>Full Width Header</p>
          <mat-slide-toggle class="md-secondary" [(ngModel)]="AppConfig.navBehind"></mat-slide-toggle>
        </mat-list-item>
        <mat-list-item>
          <p>Boxed Layout</p>
          <mat-slide-toggle class="md-secondary" [(ngModel)]="AppConfig.layoutBoxed" (change)="onLayoutChange()"></mat-slide-toggle>
        </mat-list-item>
        <mat-list-item>
          <p>Sidebar Width</p>
          <mat-form-field>
          <mat-select class="no-margin" [(ngModel)]="AppConfig.sidebarWidth" (change)="onLayoutChange()">
            <mat-option value="small">small</mat-option>
            <mat-option value="middle" >middle</mat-option>
            <mat-option value="large" >large</mat-option>
          </mat-select>
        </mat-form-field>
        </mat-list-item>
      </mat-list> -->

      <div class="divider divider-lg divider-solid"></div>
      <h4 class="section-header">Color Options</h4>
      <!-- <p class="small no-margin">Tip: Additionally, you can active "Full Width Header" above</p> -->
      <div class="divider"></div>
      <div class="row">
        <div class="col-4">
          <label class="color-option-check">
            <input
              type="radio"
              name="color"
              value="11"
              [(ngModel)]="AppConfig.colorOption"
            />
            <span class="color-option-item bg-color-page">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span class="bg-color-dark item-header"></span>
              <span class="bg-color-light item-header"></span>
              <span class="bg-color-dark"></span>
            </span>
          </label>
          <label class="color-option-check">
            <input
              type="radio"
              name="color"
              value="12"
              [(ngModel)]="AppConfig.colorOption"
            />
            <span class="color-option-item bg-color-page">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span class="bg-color-primary item-header"></span>
              <span class="bg-color-light item-header"></span>
              <span class="bg-color-dark"></span>
            </span>
          </label>
          <label class="color-option-check">
            <input
              type="radio"
              name="color"
              value="13"
              [(ngModel)]="AppConfig.colorOption"
            />
            <span class="color-option-item bg-color-page">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span class="bg-color-success item-header"></span>
              <span class="bg-color-light item-header"></span>
              <span class="bg-color-dark"></span>
            </span>
          </label>
          <label class="color-option-check">
            <input
              type="radio"
              name="color"
              value="14"
              [(ngModel)]="AppConfig.colorOption"
            />
            <span class="color-option-item bg-color-page">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span class="bg-color-info item-header"></span>
              <span class="bg-color-light item-header"></span>
              <span class="bg-color-dark"></span>
            </span>
          </label>
          <label class="color-option-check">
            <input
              type="radio"
              name="color"
              value="15"
              [(ngModel)]="AppConfig.colorOption"
            />
            <span class="color-option-item bg-color-page">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span class="bg-color-warning item-header"></span>
              <span class="bg-color-light item-header"></span>
              <span class="bg-color-dark"></span>
            </span>
          </label>
          <label class="color-option-check">
            <input
              type="radio"
              name="color"
              value="16"
              [(ngModel)]="AppConfig.colorOption"
            />
            <span class="color-option-item bg-color-page">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span class="bg-color-danger item-header"></span>
              <span class="bg-color-light item-header"></span>
              <span class="bg-color-dark"></span>
            </span>
          </label>
        </div>

        <div class="col-4">
          <label class="color-option-check">
            <input
              type="radio"
              name="color"
              value="21"
              [(ngModel)]="AppConfig.colorOption"
            />
            <span class="color-option-item bg-color-page">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span class="bg-color-light item-header"></span>
              <span class="bg-color-light item-header"></span>
              <span class="bg-color-dark"></span>
            </span>
          </label>
          <label class="color-option-check">
            <input
              type="radio"
              name="color"
              value="22"
              [(ngModel)]="AppConfig.colorOption"
            />
            <span class="color-option-item bg-color-page">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span class="bg-color-primary item-header"></span>
              <span class="bg-color-primary item-header"></span>
              <span class="bg-color-dark"></span>
            </span>
          </label>
          <label class="color-option-check">
            <input
              type="radio"
              name="color"
              value="23"
              [(ngModel)]="AppConfig.colorOption"
            />
            <span class="color-option-item bg-color-page">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span class="bg-color-success item-header"></span>
              <span class="bg-color-success item-header"></span>
              <span class="bg-color-dark"></span>
            </span>
          </label>
          <label class="color-option-check">
            <input
              type="radio"
              name="color"
              value="24"
              [(ngModel)]="AppConfig.colorOption"
            />
            <span class="color-option-item bg-color-page">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span class="bg-color-info item-header"></span>
              <span class="bg-color-info item-header"></span>
              <span class="bg-color-dark"></span>
            </span>
          </label>
          <label class="color-option-check">
            <input
              type="radio"
              name="color"
              value="25"
              [(ngModel)]="AppConfig.colorOption"
            />
            <span class="color-option-item bg-color-page">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span class="bg-color-warning item-header"></span>
              <span class="bg-color-warning item-header"></span>
              <span class="bg-color-dark"></span>
            </span>
          </label>
          <label class="color-option-check">
            <input
              type="radio"
              name="color"
              value="26"
              [(ngModel)]="AppConfig.colorOption"
            />
            <span class="color-option-item bg-color-page">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span class="bg-color-danger item-header"></span>
              <span class="bg-color-danger item-header"></span>
              <span class="bg-color-dark"></span>
            </span>
          </label>
        </div>
        <div class="col-4">
          <label class="color-option-check">
            <input
              type="radio"
              name="color"
              value="31"
              [(ngModel)]="AppConfig.colorOption"
            />
            <span class="color-option-item bg-color-page">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span class="bg-color-dark item-header"></span>
              <span class="bg-color-dark item-header"></span>
              <span class="bg-color-light"></span>
            </span>
          </label>
          <label class="color-option-check">
            <input
              type="radio"
              name="color"
              value="32"
              [(ngModel)]="AppConfig.colorOption"
            />
            <span class="color-option-item bg-color-page">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span class="bg-color-primary item-header"></span>
              <span class="bg-color-primary item-header"></span>
              <span class="bg-color-light"></span>
            </span>
          </label>
          <label class="color-option-check">
            <input
              type="radio"
              name="color"
              value="33"
              [(ngModel)]="AppConfig.colorOption"
            />
            <span class="color-option-item bg-color-page">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span class="bg-color-success item-header"></span>
              <span class="bg-color-success item-header"></span>
              <span class="bg-color-light"></span>
            </span>
          </label>
          <label class="color-option-check">
            <input
              type="radio"
              name="color"
              value="34"
              [(ngModel)]="AppConfig.colorOption"
            />
            <span class="color-option-item bg-color-page">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span class="bg-color-info item-header"></span>
              <span class="bg-color-info item-header"></span>
              <span class="bg-color-light"></span>
            </span>
          </label>
          <label class="color-option-check">
            <input
              type="radio"
              name="color"
              value="35"
              [(ngModel)]="AppConfig.colorOption"
            />
            <span class="color-option-item bg-color-page">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span class="bg-color-warning item-header"></span>
              <span class="bg-color-warning item-header"></span>
              <span class="bg-color-light"></span>
            </span>
          </label>
          <label class="color-option-check">
            <input
              type="radio"
              name="color"
              value="36"
              [(ngModel)]="AppConfig.colorOption"
            />
            <span class="color-option-item bg-color-page">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span class="bg-color-danger item-header"></span>
              <span class="bg-color-danger item-header"></span>
              <span class="bg-color-light"></span>
            </span>
          </label>
        </div>
      </div>

      <div class="divider divider-lg divider-solid"></div>
      <h4 class="section-header">Theme Options</h4>
      <div class="divider"></div>

      <div class="row no-margin theme-options clearfix">
        <div class="col-4">
          <label class="theme-option-check">
            <input
              type="radio"
              name="theme"
              value="dark"
              [(ngModel)]="AppConfig.theme"
            />
            <span class="theme-option-item bg-color-dark">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span>Dark</span>
            </span>
          </label>
        </div>
        <div class="col-4">
          <label class="theme-option-check">
            <input
              type="radio"
              name="theme"
              value="gray"
              [(ngModel)]="AppConfig.theme"
            />
            <span class="theme-option-item bg-color-gray">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span>Gray</span>
            </span>
          </label>
        </div>
        <div class="col-4" style="border: 1px solid black;">
          <label class="theme-option-check">
            <input
              type="radio"
              name="theme"
              value="light"
              [(ngModel)]="AppConfig.theme"
            />
            <span class="theme-option-item bg-color-page">
              <span class="overlay"
                ><span class="material-icons">
                  <div class="notranslate">check</div>
                </span></span
              >
              <span>
                <div class="notranslate" style="color:black;">Light</div>
              </span>
            </span>
          </label>
        </div>
      </div>

      <div class="divider divider-lg divider-solid"></div>

      <button
        mat-raised-button
        class="primary-color-full"
        (click)="saveCompanyData()"
      >
        Save Company Theme
      </button>

      <!-- <div class="divider divider-lg divider-solid"></div>
      <div class="text-right">
        <a target="_blank" [href]="AppConfig.productLink">Download it Now</a>
      </div> -->
    </div>
  </div>
</section>
