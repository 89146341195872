import { Component } from '@angular/core';
import { APPURL } from '@insig-health/config/config';

@Component({
  selector: 'my-page-not-validated',
  templateUrl: './not-validated.component.html',
})
export class NotValidatedComponent {
  public APPURL = APPURL;
}
