import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { MaterialComponentsModule } from '../../material-components.module';
import { CalendarModule } from 'angular-calendar';

import { AppointmentTypePipe } from './appointment-type.pipe';
import { EditAvailabilityComponent } from './edit-availability.component';
import { DailyScheduleComponent } from './daily-schedule.component';
import { InsigAppLoaderModule } from '../loader/loader.module';
import { TimeSegmentDurationWarningDialogComponent } from './time-segment-duration-warning-dialog/time-segment-duration-warning-dialog.component';
import { OverlappingTimeSegmentsWarningDialogComponent } from './overlapping-time-slots-warning-dialog/overlapping-time-segments-warning-dialog.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    MaterialComponentsModule,
    CalendarModule,
    InsigAppLoaderModule,
  ],
  exports: [
    AppointmentTypePipe,
    EditAvailabilityComponent,
    DailyScheduleComponent,
  ],
  declarations: [
    AppointmentTypePipe,
    EditAvailabilityComponent,
    DailyScheduleComponent,
    TimeSegmentDurationWarningDialogComponent,
    OverlappingTimeSegmentsWarningDialogComponent,
  ],
})
export class AvailabilityCalendarModule {}
