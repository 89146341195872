// @ts-strict-ignore
import { Injectable } from '@angular/core';
import * as LZString from 'lz-string';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
@Injectable()
export class CanadaDoctorsListService {

  /**
   * Returns a list of doctors names
   * @return {Observable<Array<[string, string]>>} Observable with doctors names in the form [firstName, lastName]
   */
  getDoctors(): Observable<Array<[string, string]>> {
    return Observable.create((observer) => {
      return firebase.database().ref('doctorListNames').on('value', (snapshot) => {
        if (snapshot !== undefined) {
          observer.next(LZString.decompressFromEncodedURIComponent(snapshot.val()));
        }
      });
    });
  }

}
