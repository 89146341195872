import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchpipe',
})
@Injectable()
export class SearchPipe implements PipeTransform {
  transform(items: any[], field: string, value: string): any[] {
    if (!items) {
      return [];
    }
    if (!value || value === '') {
      return items;
    }
    return items.filter(
      (item: any) =>
        item[field].toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
}
