// @ts-strict-ignore
import { Injectable } from '@angular/core';

@Injectable()
export class SpecialResponseService {
  getSpecialResponses(_companyID: string, survey: any) {
    if (survey.pages) {
      for (const page of survey.pages) {
        if (
          !page.elements ||
          !page.elements.length ||
          page.elements.length < 1
        ) {
          console.log(page);
          console.log('skip');
          continue;
        }
        for (const element of page.elements) {
          if (element.type === 'question') {
            if (
              element.question.type === 'grid' &&
              element.question.tempResponse
            ) {
              element.question.response = this.extractResponseForGridQuestion(
                element.question,
                element.question.tempResponse
              );
            }
          }
        }
      }
      return survey;
    } else {
      throw new Error('survey has no pages');
    }
  }

  extractTempResponseForGridQuestion(question) {
    let result;
    if (
      !question.grid ||
      !question.grid.rows ||
      !question.response ||
      question.response.length === 0
    ) {
      return result;
    }
    // if (question.grid.cellInputType === 'radio' && !colInputIndep) {
    //   return this.extractTempResponseForRadioGridQuestion(question, questionResponse);
    // }
    result = {};
    question.response.forEach((obj) => {
      if (!result[obj.row.id]) {
        result[obj.row.id] = {};
      }
      result[obj.row.id][obj.col.id] = obj.value;
    });

    return result;
  }

  extractResponseForGridQuestion(question, questionResponse) {
    let result;
    let colInputIndep = false;
    for (const c in question.grid.cols) {
      if (question.grid.cols[c].colInputType) {
        colInputIndep = true;
        break;
      }
    }
    if (!question.grid || !question.grid.rows) {
      return result;
    }
    if (question.grid.cellInputType === 'radio' && !colInputIndep) {
      return this.extractResponseForRadioGridQuestion(
        question,
        questionResponse
      );
    }
    result = [];
    question.grid.rows.forEach((row) => {
      question.grid.cols.forEach((col) => {
        const res = {
          row: {
            id: row.id,
            label: row.label,
          },
          col: {
            id: col.id,
            label: col.label,
          },
          value: null,
        };
        if (
          questionResponse.hasOwnProperty(row.id) &&
          questionResponse[row.id].hasOwnProperty(col.id)
        ) {
          res.value = questionResponse[row.id][col.id];
        }
        if (questionResponse[row.id]) {
          if (questionResponse[row.id].radio === col.id) {
            res.value = true;
          }
        }
        result.push(res);
      });
    });
    return result;
  }

  extractResponseForRadioGridQuestion(question, questionResponse) {
    const result = [];
    const colById = {};
    question.grid.cols.forEach((obj) => {
      const val = obj;
      colById[obj.id] = val;
    });
    question.grid.rows.forEach((row) => {
      const selectedColId = questionResponse[row.id];
      let selectedCol = null;
      if (selectedColId) {
        selectedCol = colById[selectedColId];
      }
      const rowResponse = {
        row: {
          id: row.id,
          label: row.label,
        },
        col: null,
      };
      if (selectedCol) {
        rowResponse.col = {
          id: selectedCol.id,
          label: selectedCol.label,
        };
        rowResponse['value'] = true;
      }
      result.push(rowResponse);
    });
    return result;
  }
} // end service
