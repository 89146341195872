<div class="row m-0 full-width p-2">
  <form class="m-0 p-0 form-insig-payment-form2 full-width" #insigPaymentForm
    *ngIf="!loadingPayment && !completePayment">
    <div class="cell insig-payment-form insig-payment-form2" id="insig-payment-form-2">
      <h5 class="mb-3 mx-2">Enter your payment information</h5>
      <div class="row">
        <div class="col-12 my-2 p-0">
          <mat-form-field appearance="outline" class="full-width height-70">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="name" [ngModelOptions]="{standalone:true}">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 my-2 p-0">
          <mat-form-field appearance="outline" class="full-width height-70">
            <mat-label>Email</mat-label>
            <input matInput [(ngModel)]="email" [ngModelOptions]="{standalone:true}">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 my-2 p-0">
          <div class="field">
            <div class="baseline"></div>
            <div id="insig-payment-form2-card-number" class="input empty mx-3"></div>
            <label for="insig-payment-form2-card-number" data-tid="elements_insig-payment-forms.form.card_number_label"
              class="stripe-label">Card number</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 my-2 p-0 pr-2">
          <div class="field">
            <div class="baseline"></div>
            <div id="insig-payment-form2-card-expiry" class="input empty mx-3"></div>
            <label for="insig-payment-form2-card-expiry" data-tid="elements_insig-payment-forms.form.card_expiry_label"
              class="stripe-label">Expiration</label>
          </div>
        </div>
        <div class="col-6 my-2 p-0 pl-2">
          <div class="field">
            <div class="baseline"></div>
            <div id="insig-payment-form2-card-cvc" class="input empty mx-3"></div>
            <label for="insig-payment-form2-card-cvc" data-tid="elements_insig-payment-forms.form.card_cvc_label"
              class="stripe-label">CVC</label>
          </div>
        </div>
      </div>
      <div class="error text-center hide" role="alert">
        <svg xmlns="https://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
          <path class="base" fill="#000"
            d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z">
          </path>
          <path class="glyph" fill="#FFF"
            d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z">
          </path>
        </svg>
        <span class="message"></span>
      </div>
    </div>

    <div class="row m-0 p-0">
      <div id="submit-payment-btn" class="filled-btn col-12 d-flex flex-column p-0 mx-auto highlight-border"
        (click)="submitPaymentForm()">
        <div class="mt-auto mb-auto">Click to Confirm</div>
      </div>
    </div>
  </form>

  <div class="col-12 align-self-center justify-content-center kiosk-title" *ngIf="!!completePayment && !loadingPayment">
    <div class="py-5 p-sm-5 text-center">
      <h4 class="m-0 px-4 mx-auto roboto-title">

        {{!!questionnaire ? 'Thank you, you can continue with the questionnaire!' : 'Thank you!'}}
        
      </h4>
    </div>
  </div>

  <div class="col-12 align-self-center justify-content-center kiosk-title" *ngIf="!!loadingPayment">
    <div class="py-5 p-sm-5 text-center">
      <h4 class="m-0 px-4 mx-auto roboto-title">
        <insig-app-loader class="mx-auto my-auto"></insig-app-loader>
      </h4>
    </div>
  </div>
  <!-- *ngIf="!cardAdded" -->
</div>
