<div class="row">
  <div class="col-12">
    <div class="text-center mb-3">
      <p>PDF Type</p>
      <mat-radio-group
        required
        class="mat-radio-group-spacing mb-3"
        [(ngModel)]="question.pdfType"
      >
        <mat-radio-button value="practitioner"
          >Practitioner Upload</mat-radio-button
        >
        <span class="space"></span>
        <mat-radio-button value="patient">Patient Upload</mat-radio-button>
      </mat-radio-group>
      <hr />
    </div>

    <p *ngIf="question.pdfType == 'patient'" class="text-center my-3">
      * The patient can upload a PDF here while completing this questionnaire *
    </p>

    <div *ngIf="!question.pdfType || question.pdfType == 'practitioner'">
      <div *ngIf="!question.pdfURL">
        <div class="row">
          <div class="col-10 offset-1 text-center">
            <p [hidden]="loading">Upload PDF below</p>
            <ngx-file-drop
              (onFileDrop)="onAdded($event)"
              multiple="false"
              accept=".pdf"
              dropZoneClassName="ngxFileDropZone"
              contentClassName="ngxFileDropContent"
            >
              <ng-template
                ngx-file-drop-content-tmp
                let-openFileSelector="openFileSelector"
              >
                <span>Drop file here</span>
                <span class="px-3"><!-- divider --></span>
                <button
                  mat-raised-button
                  color="primary"
                  type="button"
                  (click)="openFileSelector()"
                >
                  Browse
                </button>
              </ng-template>
            </ngx-file-drop>
            <div class="divider"></div>
            <mat-progress-spinner
              class="mx-auto"
              *ngIf="loading"
              mode="indeterminate"
            ></mat-progress-spinner>
          </div>
        </div>
      </div>

      <div *ngIf="question.pdfURL">
        <pdf-viewer
          class="d-block w-100"
          style="height: 500px;"
          [src]="question.pdfURL"
          (error)="onError($event)"
          [show-all]="false"
          [page]="page"
          [original-size]="false"
          (after-load-complete)="callBackFn($event)"
        >
        </pdf-viewer>
        <div *ngIf="error">
          <div class="divider"></div>
          <h5 class="text-center">Error loading PDF!</h5>
          <div class="divider"></div>
          <div class="divider"></div>
        </div>
        <span class="float-right smallMarginTop"
          >Page {{ page }} / {{ question.numPages }}</span
        >
        <button
          class="float-left"
          color="primary"
          mat-raised-button
          (click)="question.pdfURL = false"
        >
          Change PDF
        </button>
        <div class="row marginRight75em">
          <div class="col-6">
            <button
              class="float-right"
              mat-raised-button
              [disabled]="page <= 1"
              (click)="page = page - 1"
            >
              <mat-icon class="notranslate material-icons"
                >keyboard_arrow_left</mat-icon
              >Previous Page
            </button>
          </div>
          <div class="col-6">
            <button
              class="float-left"
              mat-raised-button
              [disabled]="page >= question.numPages"
              (click)="page = page + 1"
            >
              Next Page<mat-icon class="notranslate material-icons"
                >keyboard_arrow_right</mat-icon
              >
            </button>
          </div>
        </div>
        <div class="divider"></div>
        <div class="divider"></div>
        <div class="text-center">
          <mat-radio-group
            class="mat-radio-group-spacing"
            [(ngModel)]="question.readOnly"
          >
            <mat-radio-button class="marginRight3em" [value]="false"
              >View and Edit</mat-radio-button
            >
            <mat-radio-button [value]="true">Read Only</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>
</div>
