// @ts-strict-ignore
﻿import { Component, Input, HostBinding } from '@angular/core';
import { CalendarSchedulerEvent } from './calendar-scheduler-view.component';

@Component({
  selector: 'calendar-scheduler-event-content',
  template: `
    <div
      *ngIf="event.content"
      class="cal-scheduler-event-content"
      [innerHTML]="event.content"
    ></div>
  `,
})
export class CalendarSchedulerEventContentComponent {
  @HostBinding('class') class = 'cal-scheduler-event-content-container';

  @Input() event: CalendarSchedulerEvent;
}
