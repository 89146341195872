<div class="row">
  <div class="col-10 offset-1">
    <div class="text-center" #container>
      <p *ngIf="question.images">(click/tap on the image below to begin)</p>
      <p *ngIf="!question.images">(click/tap on space below to begin)</p>

      <canvas
        *ngIf="!test && !question.images"
        (click)="startDrawing()"
        class="drawingBorder"
      ></canvas>

      <img
        [ngStyle]="{ 'max-width.px': maxWidth }"
        *ngIf="!test && question.images"
        (click)="startDrawing()"
        src="{{ question.images }}"
      />

      <img
        [ngStyle]="{ 'max-width.px': maxWidth }"
        *ngIf="test"
        (click)="startDrawing()"
        src="{{ test }}"
        class="{{ question.images ? '' : 'drawingBorder' }}"
      />

      <div class="divider"></div>

      <div class="row" [hidden]="loading">
        <div
          class="text-center"
          [ngClass]="{ 'col-8': question.images, 'col-12': !question.images }"
        >
          <p *ngIf="!question.images">Upload image below</p>
          <ngx-file-drop
            (onFileDrop)="onAdded($event)"
            multiple="false"
            accept=".png,.jpg,.jpeg"
            dropZoneClassName="ngxFileDropZone"
            contentClassName="ngxFileDropContent"
          >
            <ng-template
              ngx-file-drop-content-tmp
              let-openFileSelector="openFileSelector"
            >
              <span>Drop file here</span>
              <span class="px-3"><!-- divider --></span>
              <button
                mat-raised-button
                color="primary"
                type="button"
                (click)="openFileSelector()"
              >
                Browse
              </button>
            </ng-template>
          </ngx-file-drop>
        </div>
        <div class="col-4" *ngIf="question.images">
          <button
            class="marginTop"
            color="primary"
            mat-raised-button
            (click)="question.images = false"
          >
            Change Image
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="divider"></div>

<mat-progress-spinner
  class="mx-auto"
  *ngIf="loading"
  mode="indeterminate"
></mat-progress-spinner>
