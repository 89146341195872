// @ts-strict-ignore
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'conditions-edit',
  templateUrl: './conditions-edit.component.html',
})
export class ConditionsEditComponent implements OnInit {
  question = {
    response: {
      selectedAnswers: [],
      selectedCheckboxes: [],
    },
  };

  private conditions = [
    'Anxiety',
    'Arthritis',
    'Asthma',
    'Cancer',
    'Depression',
    'Diabetes',
    'Heart Condition',
    'High Blood Pressure',
    'High Cholesterol',
    'Kidney Disease',
    'Stroke',
    'Thyroid Disease',
  ];

  ngOnInit() {
    this.conditions.forEach((condition) => {
      this.question.response.selectedCheckboxes.push({
        condition,
        selected: false,
      });
    });
  }

  removeItem(val) {
    const index = this.question.response.selectedAnswers.indexOf(val);
    this.question.response.selectedAnswers.splice(index, 1);
  }

  addItem(val) {
    if (this.question.response.selectedAnswers.indexOf(val) === -1) {
      this.question.response.selectedAnswers.push(val);
    }
    this.question.response.selectedAnswers[-1] = null;
    console.log(this.question.response.selectedAnswers);
  } // end add item
}
