<div [ngSwitch]="question.type">
  <div *ngSwitchCase="'text'">
    <div class="row">
      <div class="col-10 offset-1">
        <mat-form-field class="full-width">
          <mat-label>Single Line Text</mat-label>
          <input
            matInput
            [disabled]="true"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'textarea'">
    <div class="row">
      <div class="col-10 offset-1">
        <mat-form-field class="full-width">
          <mat-label>Multi Line Text</mat-label>
          <textarea
            matInput
            [disabled]="true"
            type="text"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'scoring'">
    <scoring-edit [(question)]="question"></scoring-edit>
  </div>

  <div *ngSwitchCase="'sendCommunication'">
    <send-communication-edit [(question)]="question"></send-communication-edit>
  </div>

  <div *ngSwitchCase="'bookAppointment'">
    <book-appointment-edit [(question)]="question"></book-appointment-edit>
    <!-- <send-communication-edit [(question)]="question"></send-communication-edit> -->
  </div>

  <div *ngSwitchCase="'collectPayment'">
    <collect-payment-edit [(question)]="question"></collect-payment-edit>
    <!-- <send-communication-edit [(question)]="question"></send-communication-edit> -->
  </div>

  <div *ngSwitchCase="'radio'">
    <radio-checkbox-select-edit
      [(question)]="question"
      [openTriggerDialog]="openTriggerDialog"
      [isActive]="isActive"
    ></radio-checkbox-select-edit>
  </div>

  <div *ngSwitchCase="'checkbox'">
    <radio-checkbox-select-edit
      [(question)]="question"
      [openTriggerDialog]="openTriggerDialog"
      [isActive]="isActive"
    ></radio-checkbox-select-edit>
  </div>

  <div *ngSwitchCase="'select'">
    <radio-checkbox-select-edit
      [(question)]="question"
      [openTriggerDialog]="openTriggerDialog"
      [isActive]="isActive"
    ></radio-checkbox-select-edit>
  </div>

  <div *ngSwitchCase="'priority'">
    <priority-edit [(question)]="question"></priority-edit>
  </div>

  <div *ngSwitchCase="'photograph'">
    <photograph-edit></photograph-edit>
  </div>

  <div *ngSwitchCase="'grid'" class="grid-edit">
    <grid-edit [(question)]="question"></grid-edit>
  </div>

  <div *ngSwitchCase="'physicalGrid'">
    <physicalgrid-edit [(question)]="question"></physicalgrid-edit>
  </div>

  <div *ngSwitchCase="'yesno'">
    <yesno-edit
      [(question)]="question"
      [openTriggerDialog]="openTriggerDialog"
    ></yesno-edit>
  </div>

  <div *ngSwitchCase="'multiyesno'">
    <multiyesno-edit
      [(question)]="question"
      [openTriggerDialog]="openMultiYesNoTriggerDialog"
      [isActive]="isActive"
    ></multiyesno-edit>
  </div>

  <div *ngSwitchCase="'number'">
    <div class="row">
      <div class="col-10 offset-1">
        <number-edit [(question)]="question"></number-edit>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'date'">
    <date-edit [(question)]="question"></date-edit>
  </div>

  <div *ngSwitchCase="'time'">
    <time-edit [(question)]="question"></time-edit>
  </div>

  <div *ngSwitchCase="'yearMonthDay'">
    <yearmonthday-edit></yearmonthday-edit>
  </div>

  <div *ngSwitchCase="'dateRange'">
    <div class="row">
      <div class="col-1">
        <!-- left spacer -->
      </div>
      <div class="col-5">
        <mat-form-field class="full-width">
          <mat-label>From</mat-label>
          <input
            matInput
            [disabled]="true"
            [matDatepicker]="picker"
          />
          <mat-datepicker-toggle
            [disabled]="true"
            [for]="picker"
          ></mat-datepicker-toggle>
        </mat-form-field>
      </div>
      <div class="col-5">
        <mat-form-field class="full-width">
          <mat-label>To</mat-label>
          <input
            matInput
            [disabled]="true"
            [matDatepicker]="picker"
          />
          <mat-datepicker-toggle
            [disabled]="true"
            [for]="picker"
          ></mat-datepicker-toggle>
        </mat-form-field>
      </div>
      <div class="col-1">
        <!-- right spacer -->
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'email'">
    <div class="row">
      <div class="col-8 offset-2">
        <mat-form-field class="full-width">
          <span matPrefix>
            <mat-icon class="notranslate material-icons">email</mat-icon>&nbsp;
          </span>
          <mat-label>Email</mat-label>
          <input matInput type="text" spellcheck="false"/>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'range'">
    <div class="row">
      <div class="col-10 offset-1">
        <range-edit [(question)]="question"></range-edit>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'link'">
    <link-edit [(question)]="question"></link-edit>
  </div>

  <div *ngSwitchCase="'fiveStar'">
    <fivestar-edit></fivestar-edit>
  </div>

  <div *ngSwitchCase="'drawing'">
    <drawing-edit
      [(question)]="question"
      [surveyID]="surveyID"
      [userID]="userID"
    ></drawing-edit>
  </div>

  <div *ngSwitchCase="'diagram'">
    <diagram-edit
      [(question)]="question"
      [surveyID]="surveyID"
      [userID]="userID"
    ></diagram-edit>
  </div>

  <div *ngSwitchCase="'signature'">
    <signature-edit [(question)]="question"></signature-edit>
  </div>

  <div *ngSwitchCase="'shareNote'">
    <share-note-edit [(question)]="question"></share-note-edit>
  </div>

  <div *ngSwitchCase="'address'">
    <address-edit></address-edit>
  </div>

  <div *ngSwitchCase="'phone'">
    <div class="row">
      <div class="col-8 offset-2">
        <mat-form-field class="full-width">
          <span matPrefix>
            <mat-icon class="notranslate material-icons">phone</mat-icon>&nbsp;
          </span>
          <mat-label>Phone Number</mat-label>
          <input
            matInput
            spellcheck="false"
            minlength="10"
            maxlength="14"
            insig-phone-mask
            type="tel"
          />
        </mat-form-field>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'video'">
    <div class="row">
      <div class="col-10 offset-1">
        <video-edit
          [ngModel]="question.url"
          (ngModelChange)="question.url = $event; questionChange.emit(question)"
          ngDefaultControl
        ></video-edit>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'medicationAllergies'">
    <medication-allergies-edit></medication-allergies-edit>
  </div>

  <div *ngSwitchCase="'conditions'">
    <conditions-edit></conditions-edit>
  </div>

  <div *ngSwitchCase="'bloodPressure'">
    <blood-pressure-edit></blood-pressure-edit>
  </div>

  <div *ngSwitchCase="'heightWeight'">
    <height-weight-edit></height-weight-edit>
  </div>

  <div *ngSwitchCase="'surgery'">
    <surgery-edit></surgery-edit>
  </div>

  <div *ngSwitchCase="'medications'">
    <medications-edit [(question)]="question"></medications-edit>
  </div>

  <div *ngSwitchCase="'doctors'">
    <doctors-edit [(question)]="question"></doctors-edit>
  </div>

  <div *ngSwitchCase="'autocomplete'">
    <autocomplete-edit [(question)]="question"></autocomplete-edit>
  </div>

  <div *ngSwitchCase="'survey'">
    <survey-edit [(question)]="question" [userID]="userID"></survey-edit>
  </div>

  <div *ngSwitchCase="'pdf'">
    <pdf-edit
      [(question)]="question"
      [surveyID]="surveyID"
      [userID]="userID"
    ></pdf-edit>
  </div>
</div>
