<!-- //images from https://png.icons8.com/grid-filled/ios7/25 -->
<mat-card>
  <mat-toolbar>
    <button
      mat-mini-fab
      color="none"
      class="mr-4"
      (click)="dialogRef.close()"
    >
      <mat-icon class="notranslate">clear</mat-icon>
    </button>
    <span>Select Element Type</span>
  </mat-toolbar>

  <mat-dialog-content class="d-inline">
    <div class="divider"></div>

    <mat-list>
      <mat-list-item>
        <mat-grid-list cols="5" rowHeight="60px" class="full-width">
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="A short, single line typed answer"
              (click)="confirmSelection('text')"
            >
              <img src="../../../assets/images/question-types/text/text.png" />
              <div>Single Line</div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="A text box with room for a detailed, typed answer"
              (click)="confirmSelection('textarea')"
            >
              <img
                src="../../../assets/images/question-types/textarea/textarea.png"
              />
              <div>Multi Line</div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Select a single answer from a list of multiple choices"
              (click)="confirmSelection('radio')"
            >
              <img
                src="../../../assets/images/question-types/radio/radio.png"
              />
              <div>Radio</div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Answer determines a score"
              (click)="confirmSelection('scoring')"
            >
              <!-- <img src="https://png.icons8.com/report-card/ios7/25" title="Report Card" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/scoring/scoring.png"
              />
              <div>Scoring</div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Check as many answers as desired from the options"
              (click)="confirmSelection('checkbox')"
            >
              <img
                src="../../../assets/images/question-types/checkbox/checkbox.png"
              />
              <div>Checkbox</div>
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-list-item>

      <div class="divider"></div>

      <mat-list-item>
        <mat-grid-list cols="5" rowHeight="60px" class="full-width">
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="A binary yes or no answer"
              (click)="confirmSelection('yesno')"
            >
              <img
                src="../../../assets/images/question-types/yesno/yesno.png"
              />
              <div>Yes/No</div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Select as many binary yes or no answers as desired"
              (click)="confirmSelection('multiyesno')"
            >
              <img
                src="../../../assets/images/question-types/multiyesno/multiyesno.png"
              />
              <div>Multi&nbsp;Yes/No</div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Select one item from a dropdown menu"
              (click)="confirmSelection('select')"
            >
              <img
                src="../../../assets/images/question-types/select/select.png"
              />
              <div>Select</div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Answer is limited to a numerical value"
              (click)="confirmSelection('number')"
            >
              <img
                src="../../../assets/images/question-types/number/number.png"
              />
              <div>Number</div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Answer is limited to numerical values"
              (click)="confirmSelection('range')"
            >
              <!-- <img src="https://png.icons8.com/width-filled/ios7/25" title="Width Filled" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/range/range.png"
              />
              <div>Range</div>
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-list-item>

      <div class="divider"></div>

      <mat-list-item>
        <mat-grid-list cols="5" rowHeight="60px" class="full-width">
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Rank answers by priority or importance"
              (click)="confirmSelection('priority')"
            >
              <img
                src="../../../assets/images/question-types/priority/priority.png"
              />
              <div>Priority</div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Answer is limited to a date format"
              (click)="confirmSelection('date')"
            >
              <!-- <img src="https://png.icons8.com/date-to/ios7/25" title="Date To" width="25" height="25"> -->
              <img src="../../../assets/images/question-types/date/date.png" />
              <div>Date</div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Answer is limited to a Year/Month/Day format"
              (click)="confirmSelection('yearMonthDay')"
            >
              <!-- <img src="https://png.icons8.com/calendar/ios7/25" title="Calendar" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/yearMonthDay/yearMonthDay.png"
              />
              <div>
                YYYY/MM/DD
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Answer is limited to a start and end date"
              (click)="confirmSelection('dateRange')"
            >
              <!-- <img src="https://png.icons8.com/date-span/ios7/25" title="Date Span" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/dateRange/dateRange.png"
              />
              <div>Date Range</div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Answer is limited to a time format"
              (click)="confirmSelection('time')"
            >
              <!-- <img src="https://png.icons8.com/time/ios7/25" title="Time" width="25" height="25"> -->
              <img src="../../../assets/images/question-types/time/time.png" />
              <div>Time</div>
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-list-item>

      <div class="divider"></div>

      <mat-list-item>
        <mat-grid-list cols="5" rowHeight="60px" class="full-width">
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Patients take a photograph or upload an image"
              (click)="confirmSelection('photograph')"
            >
              <img
                src="../../../assets/images/question-types/photograph/photograph.png"
              />
              <div>
                Photograph / Image
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Question in the form of a table"
              (click)="confirmSelection('grid')"
            >
              <img src="../../../assets/images/question-types/grid/grid.png" />
              <div>
                Grid
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Answer is in format of an email address"
              (click)="confirmSelection('email')"
            >
              <!-- <img src="https://png.icons8.com/send-mass-email/ios7/25" title="Send Mass Email" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/email/email.png"
              />
              <div>
                Email
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Insert a link in the format of a URL"
              (click)="confirmSelection('link')"
            >
              <!-- <img src="https://png.icons8.com/link-filled/ios7/25" title="Link Filled" width="25" height="25"> -->
              <img src="../../../assets/images/question-types/link/link.png" />
              <div>
                Link
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Select a number of stars to represent your rating"
              (click)="confirmSelection('fiveStar')"
            >
              <!-- <img src="https://png.icons8.com/star/ios7/25" title="Star" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/fiveStar/fiveStar.png"
              />
              <div>
                Five Star
              </div>
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-list-item>

      <div class="divider"></div>

      <mat-list-item>
        <mat-grid-list cols="5" rowHeight="60px" class="full-width">
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Answer represented in form of a drawing"
              (click)="confirmSelection('drawing')"
            >
              <!-- <img src="https://png.icons8.com/edit/ios7/25" title="Edit" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/drawing/drawing.png"
              />
              <div>
                Drawing
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Insert a diagram or picture"
              (click)="confirmSelection('diagram')"
            >
              <!-- <img src="https://png.icons8.com/venn-diagram/ios7/25" title="Venn Diagram" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/diagram/diagram.png"
              />
              <div>
                Diagram
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Answer is in form of a signature"
              (click)="confirmSelection('signature')"
            >
              <!-- <img src="https://png.icons8.com/signature/ios7/25" title="Signature" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/signature/signature.png"
              />
              <div>
                Signature
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Answer represents a phone number"
              (click)="confirmSelection('phone')"
            >
              <!-- <img src="https://png.icons8.com/phone/ios7/25" title="Phone" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/phone/phone.png"
              />
              <div>
                Phone
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Google Autocomplete Address"
              (click)="confirmSelection('address')"
            >
              <!-- <img src="https://png.icons8.com/video-call/ios7/25" title="Video Call" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/address/address.png"
              />
              <div>
                Address
              </div>
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-list-item>

      <div class="divider"></div>

      <mat-list-item>
        <mat-grid-list cols="5" rowHeight="60px" class="full-width">
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Enter physician name"
              (click)="confirmSelection('doctors')"
            >
              <!-- <img src="https://png.icons8.com/doctors-bag/ios7/25" title="Doctors Bag" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/doctors/doctors.png"
              />
              <div>
                Doctors
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Enter medication name"
              (click)="confirmSelection('medications')"
            >
              <!-- <img src="https://png.icons8.com/pill/ios7/25" title="Pill" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/medications/medications.png"
              />
              <div>
                Medications
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Common and custom medical conditions"
              (click)="confirmSelection('conditions')"
            >
              <!-- <img src="https://png.icons8.com/pdf/Dusk_Wired/64" title="PDF" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/conditions/conditions.png"
              />
              <div>
                Medical Conditions
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Medication Allergies"
              (click)="confirmSelection('medicationAllergies')"
            >
              <img
                src="../../../assets/images/question-types/medicationAllergies/medicationAllergies.png"
              />
              <div>
                Drug Allergies
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Past Surgical History"
              (click)="confirmSelection('surgery')"
            >
              <!-- <img src="https://png.icons8.com/idea/Dusk_Wired/64" title="Autocomplete" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/surgery/surgery.png"
              />
              <div>
                Surgical History
              </div>
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-list-item>

      <div class="divider"></div>

      <mat-list-item>
        <mat-grid-list cols="5" rowHeight="60px" class="full-width">
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Autocomplete answers"
              (click)="confirmSelection('autocomplete')"
            >
              <!-- <img src="https://png.icons8.com/idea/Dusk_Wired/64" title="Autocomplete" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/autocomplete/autocomplete.png"
              />
              <div>
                Autocomplete
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Complete PDF Documents"
              (click)="confirmSelection('pdf')"
            >
              <!-- <img src="https://png.icons8.com/pdf/Dusk_Wired/64" title="PDF" width="25" height="25"> -->
              <img src="../../../assets/images/question-types/pdf/pdf.png" />
              <div>
                PDF
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Collect height & weight"
              (click)="confirmSelection('heightWeight')"
            >
              <img
                src="../../../assets/images/question-types/heightWeight/heightWeight.png"
              />
              <div>
                Height & Weight
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Full body description of abnormalities"
              (click)="confirmSelection('physicalGrid')"
            >
              <img
                src="../../../assets/images/question-types/physicalGrid/physicalGrid.png"
              />
              <div>
                Physical Grid
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Insert another questionnaire"
              (click)="confirmSelection('survey')"
            >
              <!-- <img src="https://png.icons8.com/survey/ios7/25" title="Survey" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/survey/survey.png"
              />
              <div>
                Questionnaire
              </div>
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-list-item>

      <div class="divider"></div>

      <mat-list-item>
        <mat-grid-list cols="5" rowHeight="60px" class="full-width">
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Display a paragraph"
              (click)="confirmSelection('paragraph')"
            >
              <img
                src="../../../assets/images/question-types/paragraph/paragraph.png"
              />
              <div>
                Paragraph
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Display a YouTube video"
              (click)="confirmSelection('video')"
            >
              <!-- <img src="https://png.icons8.com/video-call/ios7/25" title="Video Call" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/video/video.png"
              />
              <div>
                Video
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Collect blood pressure information"
              (click)="confirmSelection('bloodPressure')"
            >
              <!-- <img src="https://png.icons8.com/video-call/ios7/25" title="Video Call" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/blood-pressure/blood-pressure.png"
              />
              <div>
                Blood Pressure
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Send Communication (email, SMS)"
              (click)="confirmSelection('sendCommunication')"
            >
              <!-- <img src="https://png.icons8.com/video-call/ios7/25" title="Video Call" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/send-communication/send-communication.png"
              />
              <div>
                Send Communication
              </div>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Referral Network"
              (click)="confirmSelection('bookAppointment')"
            >
              <!-- <img src="https://png.icons8.com/video-call/ios7/25" title="Video Call" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/book-appointment/book-appointment.png"
              />
              <div>
                Referral Network
              </div>
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-list-item>

      <div class="divider"></div>
      
      <mat-list-item>
        <mat-grid-list cols="5" rowHeight="60px" class="full-width">
          <mat-grid-tile [colspan]="1">
            <button
              mat-button
              class="button"
              matTooltip="Send Communication (email, SMS)"
              (click)="confirmSelection('collectPayment')"
            >
              <!-- <img src="https://png.icons8.com/video-call/ios7/25" title="Video Call" width="25" height="25"> -->
              <img
                src="../../../assets/images/question-types/collect-payment/collect-payment.png"
              />
              <div>
                Collect Payment
              </div>
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-list-item>
      
    </mat-list>
  </mat-dialog-content>
</mat-card>
