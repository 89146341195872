// @ts-strict-ignore
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'admin-scheduler-daily-settings',
  templateUrl: './admin-scheduler-daily-settings.component.html',
  providers: [],
  styleUrls: ['./admin-scheduler-daily-settings.component.scss'],
})
export class AdminSchedulerDailySettingsComponent {
  @Input() daySelected: Date;
  @Input() fullUserMap: any;
  @Input() scheduleSelected: any;
  @Output() toggleBottomSheet = new EventEmitter();
  @Output() selectDoctor = new EventEmitter();

  getDoctorRange(uid: string): string {
    if (
      !!this.scheduleSelected &&
      this.scheduleSelected.userSchedules[uid] &&
      this.scheduleSelected.userSchedules[uid].start &&
      this.scheduleSelected.userSchedules[uid].end
    ) {
      const start = this.scheduleSelected.userSchedules[uid].start;
      const end = this.scheduleSelected.userSchedules[uid].end;
      return this.formatAMPM(start) + '-' + this.formatAMPM(end);
    }
    return '';
  }

  formatAMPM(date: Date): string {
    let hours = date.getHours();
    let minutes: any = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
}
