// @ts-strict-ignore
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  Output, inject } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { FlagColorsService } from '../../../services/flagColors.service';
import { IntegrationsDialogComponent } from '../../../dialogs/integrations-dialog.component';

@Component({
  selector: 'multiyesno-edit',
  templateUrl: './multiyesno-edit.component.html',
  providers: [FlagColorsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiYesNoEditComponent implements OnInit {
  private readonly dialog = inject(MatDialog);
  private readonly flagColorsService = inject(FlagColorsService);
  @Input() question: any;
  @Output() questionChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() isActive: boolean;

  @Input() openTriggerDialog: (
    initialId: string,
    yesNoOption: 'Yes' | 'No'
  ) => void;

  public flagColors = null;

  testChangeDetection(): string {
    console.log('Change Detection Run!');
    return '';
  }

  ngOnInit() {
    this.flagColors = this.flagColorsService.getFlagColors();
    if (!this.question.offeredQuestions) {
      this.question.offeredQuestions = [];
      this.addOption(0);
      this.addOption(0);
      this.questionChange.emit(this.question);
    }
  }

  openIntegrationsDialog(answers, yesOption) {
    const dialogRef = this.dialog.open(IntegrationsDialogComponent);
    dialogRef.componentInstance.multiYesNoAnswers = answers;
    dialogRef.componentInstance.multiYesNoOption = yesOption;
    dialogRef.componentInstance.multiYesNo = true;
    dialogRef.componentInstance.startingText = answers.text;
    dialogRef.afterClosed().subscribe(() => {
      this.questionChange.emit(this.question); // Trigger onPush change detection
    });
  }

  addOption(index: number) {
    const id = this.generateRandomID(32);
    this.question.offeredQuestions.splice(index, 0, {
      id,
      orderNumber: index + 1,
      value: '',
    });
    this.questionChange.emit(this.question);
  }

  generateRandomID(length: number): string {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  moveDown(index: number): void {
    // Swaps object at index with object at index+1
    const temp = this.question.offeredQuestions[index + 1];
    this.question.offeredQuestions[index + 1] = this.question.offeredQuestions[
      index
    ];
    this.question.offeredQuestions[index] = temp;
    this.questionChange.emit(this.question);
  }

  moveUp(index: number): void {
    // Swaps object at index with object at index-1
    const temp = this.question.offeredQuestions[index - 1];
    this.question.offeredQuestions[index - 1] = this.question.offeredQuestions[
      index
    ];
    this.question.offeredQuestions[index] = temp;
    this.questionChange.emit(this.question);
  }

  onAnswerChange(index: number, newValue: string): void {
    this.question.offeredQuestions[index].text = newValue;
    this.questionChange.emit(this.question);
  }
} // end component
