// @ts-strict-ignore
import { Component, Input } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'insig-bottom-sheet',

  templateUrl: './insig-bottom-sheet.component.html',
  providers: [],
  styleUrls: ['./insig-bottom-sheet.component.scss'],
  animations: [
    trigger('bottomSheetAnimation', [
      state('in', style({ transform: 'translateY(0)' })),
      transition('void => *', [
        style({ transform: 'translateY(100%)' }),
        animate(200),
      ]),
      transition('* => void', [
        animate(200, style({ transform: 'translateY(100%)' })),
      ]),
    ]),
  ],
})
export class InsigBottomSheetComponent {
  @Input() show: boolean;
}
