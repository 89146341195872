<div *ngIf="question.terms" class="text-center">
  <mat-checkbox
    color="primary"
    [(ngModel)]="question.response.responseTerms"
  ></mat-checkbox>
  <span class="space"></span> I have read and accept the
  <a href="javascript:;" class="d-inline" (click)="openTerms()"
    ><i> Terms & Conditions</i></a
  ><a></a>
</div>

<div class="divider"></div>

<div (click)="startSignature()">
  <h5>Click/tap to begin signature</h5>
  <div #container>
    <canvas
      [ngStyle]="{ 'max-width.px': maxWidth }"
      *ngIf="!question.response.answer"
      class="signatureStyle"
    ></canvas>
  </div>
  <img
    class="signatureStyle"
    *ngIf="question.response.answer"
    src="{{ question.response.answer }}"
  />
</div>

<div *ngIf="doctorCompleting" class="text-center">
  <div class="divider"></div>

  <p *ngIf="noSavedSignatureMessage" style="color: red;">
    {{ noSavedSignatureMessage }}
  </p>

  <button
    mat-raised-button
    [disabled]="noSavedSignatureMessage"
    (click)="addSavedSignature()"
  >
    Add Your Saved Signature
  </button>
</div>
