<insig-sidenav-menu
  [menuItems]="menuItems"
  (addNewCategory)="addNewCategory()"
  (menuItemSelected)="menuItemSelected($event)"
  [selectedGroup]="selectedGroup"
  [selectedCategoryItem]="selectedCategoryItem"
>
  <company-user-group-list
    *ngIf="selectedCategory"
    class="full-width"
    [userList]="userGroupList"
    [userGroup]="selectedCategory"
    (addUserBtnClicked)="addUsersToGroup()"
    (toggleClicked)="toggleUserEnabled($event)"
    (deleteUserGroup)="deleteUserGroup()"
    (deleteBtnClicked)="deleteUserFromGroup($event)"
  ></company-user-group-list>

  <main *ngIf="!selectedCategory" class="ml-auto mr-auto full-height">
    <div>
      <div>
        <h5>You have no user group selected</h5>
        <h5>Please select one to edit</h5>
      </div>
      <svg viewBox="0 0 200 600">
        <polygon
          points="118.302698 8 59.5369448 66.7657528 186.487016 193.715824 14 366.202839 153.491505 505.694344 68.1413353 591.044514 200 591.044514 200 8"
        ></polygon>
      </svg>
    </div>
    <svg class="crack" viewBox="0 0 200 600">
      <polyline
        points="118.302698 8 59.5369448 66.7657528 186.487016 193.715824 14 366.202839 153.491505 505.694344 68.1413353 591.044514"
      ></polyline>
    </svg>
    <div>
      <svg viewBox="0 0 200 600">
        <polygon
          points="118.302698 8 59.5369448 66.7657528 186.487016 193.715824 14 366.202839 153.491505 505.694344 68.1413353 591.044514 0 591.044514 0 8"
        ></polygon>
      </svg>
      <div>
        <h5>Or</h5>
        <h5>
          <a (click)="addNewCategory()">
            <b>Create One?</b>
          </a>
        </h5>
      </div>
    </div>
  </main>
</insig-sidenav-menu>
