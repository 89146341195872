import { Injectable } from '@angular/core';

@Injectable()
export class PhysicalGridService {
  getDefaultStructure() {
    return this.defaultStructure;
  }

  getCheckboxes() {
    return this.checkboxes;
  }

  private checkboxes = [
    'NAD',
    'Afebrile',
    'Hydrated',
    'Supple neck',
    'No scleral icterus',
    'Apparent distress',
    'Febrile',
    'Signs of dehydration',
    'Stiff neck',
    'Icterus present',
  ];

  private defaultStructure: any = [
    {
      id: 'Head, Eye, Ear, Nose, Throat',
      open: true,
      sections: [
        {
          id: 'Head',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Scalp',
          parent: 'Head',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Eyes',
          parent: true,
          sentence:
            'Normal Bilaterally. PERL, conjuctivae--no erythema, discharge or lesions, eyelids no erythema, swelling or lesions.',
        },
        {
          id: 'Left Eye',
          parent: 'Eyes',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Right Eye',
          parent: 'Eyes',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Visual Aquity Left',
          parent: 'Eyes',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Visual Aquity Right',
          parent: 'Eyes',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Ears',
          parent: true,
          normal: false,
          abnormal: false,
          sentence:
            'Normal Bilaterally. External ear, no erythema, no swelling, no lesions. Canal; no erythema, no discharge. TM\'s: No bulging, no erythema, no air fluid levels.',
        },
        {
          id: 'Left Ear',
          parent: 'Ears',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Right Ear',
          parent: 'Ears',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Hearing',
          parent: 'Ears',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Nose',
          parent: true,
          normal: false,
          abnormal: false,
          sentence: 'Nasal mucosa normal, no discharge, blood or polyps seen.',
        },
        {
          id: 'Throat',
          parent: true,
          normal: false,
          abnormal: false,
          sentence:
            'Oropharynx normal, no redness or discharge, no mucosal lesions.',
        },
        {
          id: 'Oropharynx',
          parent: 'Throat',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Tonsils',
          parent: 'Throat',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Uvula',
          parent: 'Throat',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Palate',
          parent: 'Throat',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Oral Cavity',
          parent: true,
          normal: false,
          abnormal: false,
          sentence: 'Normal oral cavity, no mucosal lesions seen.',
        },
        {
          id: 'Neck',
          parent: true,
          normal: false,
          abnormal: false,
          sentence:
            'Normal. Trachea midline, no submental, submandibular or anterior cervical lymphadenopathy. No Goiter.',
        },
        {
          id: 'Back',
          parent: 'Neck',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Front',
          parent: 'Neck',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Dental',
          parent: true,
          normal: false,
          abnormal: false,
          sentence: 'Normal oral cavity',
        },
        {
          id: 'Dentures Upper',
          parent: 'Dental',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Dentures Lower',
          parent: 'Dental',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Teeth Missing',
          parent: 'Dental',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Decay',
          parent: 'Dental',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Face',
          parent: true,
          normal: false,
          abnormal: false,
        },
      ],
      images: [
        'https://firebasestorage.googleapis.com/v0/b/insig-ca.appspot.com/o/general%2FphysicalGrid%2FHEENNT%2Fear.png?alt=media&token=3e0e97d3-a338-4234-85a3-4d7294349b6d',
        'https://firebasestorage.googleapis.com/v0/b/insig-ca.appspot.com/o/general%2FphysicalGrid%2FHEENNT%2Feye.png?alt=media&token=d7ef2981-0e2c-4a65-9a5b-6bd7cf5a8cd1',
        'https://firebasestorage.googleapis.com/v0/b/insig-ca.appspot.com/o/general%2FphysicalGrid%2FHEENNT%2Feye2.jpeg?alt=media&token=49c8b0d0-d3fc-436a-8d13-fab11af8bac5',
        'https://firebasestorage.googleapis.com/v0/b/insig-ca.appspot.com/o/general%2FphysicalGrid%2FHEENNT%2Fface.jpg?alt=media&token=8479fbe5-1d41-478c-9611-badce8281c11',
        'https://firebasestorage.googleapis.com/v0/b/insig-ca.appspot.com/o/general%2FphysicalGrid%2FHEENNT%2Fhead.jpg?alt=media&token=0a74937e-4850-4498-829e-17614ab65cbb',
        'https://firebasestorage.googleapis.com/v0/b/insig-ca.appspot.com/o/general%2FphysicalGrid%2FHEENNT%2Fmouth.jpg?alt=media&token=29de5468-e9d6-474d-b7fe-0686af007589',
        'https://firebasestorage.googleapis.com/v0/b/insig-ca.appspot.com/o/general%2FphysicalGrid%2FHEENNT%2Fneck.jpg?alt=media&token=5c91051a-3dd1-4a04-8fd2-d9ba8f061910',
      ],
    },
    {
      id: 'Respiratory',
      open: true,
      sections: [
        {
          id: 'Breath Sounds',
          parent: true,
          normal: false,
          options: [
            { title: 'Improvement post-bronchodilator' },
            { title: 'No improvement post-bronchodilator' },
          ],
          abnormal: false,
          sentence: 'Clear Bilateral Air Entry, no Crackles or Wheezes heard.',
        },
        {
          id: 'Scoliosis',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Left',
          parent: 'Scoliosis',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Right',
          parent: 'Scoliosis',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Spirometry',
          parent: true,
          normal: false,
          options: [
            { title: 'Obstructive pattern' },
            { title: 'Restrictive pattern' },
            { title: 'Improvement post-bronchodilator' },
            { title: 'No improvement post-bronchodilator' },
          ],
          abnormal: false,
          sentence:
            'Spirometry today showed nether obstruction nor restriction.',
        },
        {
          id: 'Flow',
          parent: 'Spirometry',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Volume',
          parent: 'Spirometry',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Good Reversal',
          parent: 'Spirometry',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Palpation',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Inspection',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Percussion',
          parent: true,
          normal: false,
          abnormal: false,
        },
      ],
      images: [
        'https://firebasestorage.googleapis.com/v0/b/insig-ca.appspot.com/o/general%2FphysicalGrid%2FRespitory%2Fbody.jpg?alt=media&token=5d424546-359a-4288-96f6-5452acdcf895',
        'https://firebasestorage.googleapis.com/v0/b/insig-ca.appspot.com/o/general%2FphysicalGrid%2FRespitory%2Fchest.jpg?alt=media&token=4a14e870-7425-42e5-b565-8ea6c095873f',
      ],
    },
    {
      id: 'Cardiovascular',
      open: false,
      sections: [
        {
          id: 'Heart Sounds',
          parent: true,
          normal: false,
          abnormal: false,
          sentence: 'Normal S-1 and S-2, No murmurs or extra heart sounds.',
        },
        {
          id: 'Bruits',
          parent: true,
          normal: false,
          abnormal: false,
          sentence: 'No bruits heard in neck or abdomen',
        },
        {
          id: 'Carotid',
          parent: 'Bruits',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Abdominal',
          parent: 'Bruits',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Femoral',
          parent: 'Bruits',
          normal: false,
          abnormal: false,
        },
        {
          id: 'S3',
          parent: 'Heart Sounds',
          normal: false,
          abnormal: false,
        },
        {
          id: 'S4',
          parent: 'Heart Sounds',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Murmurs',
          parent: 'Heart Sounds',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Rub',
          parent: 'Heart Sounds',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Peripheral Pulses',
          parent: true,
          normal: false,
          abnormal: false,
          sentence:
            'Peripheral pulses present and equal at Posterior Tibial and Dorsalis Pedis.',
        },
        {
          id: 'Posterior Tibial',
          parent: 'Peripheral Pulses',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Dorsalis Pedis',
          parent: 'Peripheral Pulses',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Edema',
          parent: true,
          normal: false,
          abnormal: false,
          sentence: 'No Peripheral edema noted.',
        },
        {
          id: 'Left',
          parent: 'Edema',
          normal: false,
          options: [
            { title: 'Grade 0' },
            { title: 'Grade 1' },
            { title: 'Grade 2' },
            { title: 'Grade 3' },
            { title: 'Grade 4' },
            { title: 'Pitting' },
            { title: 'Non-Pitting' },
          ],
          abnormal: false,
        },
        {
          id: 'Right',
          parent: 'Edema',
          normal: false,
          options: [
            { title: 'Grade 0' },
            { title: 'Grade 1' },
            { title: 'Grade 2' },
            { title: 'Grade 3' },
            { title: 'Grade 4' },
            { title: 'Pitting' },
            { title: 'Non-Pitting' },
          ],
          abnormal: false,
        },
        {
          id: 'Bilateral',
          parent: 'Edema',
          normal: false,
          options: [
            { title: 'Grade 0' },
            { title: 'Grade 1' },
            { title: 'Grade 2' },
            { title: 'Grade 3' },
            { title: 'Grade 4' },
            { title: 'Pitting' },
            { title: 'Non-Pitting' },
          ],
          abnormal: false,
        },
        {
          id: 'Sacral',
          parent: 'Edema',
          normal: false,
          abnormal: false,
        },
      ],
      images: [],
    },
    {
      id: 'Abdominal-Inguinal',
      open: false,
      sections: [
        {
          id: 'Inspection & Palpation',
          parent: true,
          normal: false,
          abnormal: false,
          sentece: 'No Tenderness, mass or guarding.',
        },
        {
          id: 'Guarding',
          parent: 'Inspection & Palpation',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Rebound',
          parent: 'Inspection & Palpation',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Upper Quadrant',
          parent: 'Inspection & Palpation',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Lower Quadrant',
          parent: 'Inspection & Palpation',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Mass',
          parent: 'Inspection & Palpation',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Tenderness',
          parent: 'Inspection & Palpation',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Auscultation',
          parent: true,
          normal: false,
          abnormal: false,
          sentence: 'Normal bowel sounds heard.',
        },
        {
          id: 'Percussion',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Inguinal',
          parent: true,
          normal: false,
          abnormal: false,
          sentence: 'No hernias detected bilaterally.',
        },
        {
          id: 'Left',
          parent: 'Inguinal',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Right',
          parent: 'Inguinal',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Lymph Nodes',
          parent: 'Inguinal',
          normal: false,
          abnormal: false,
        },
      ],
      images: [
        'https://firebasestorage.googleapis.com/v0/b/insig-ca.appspot.com/o/general%2FphysicalGrid%2FInguinal%2Fabdomen2.gif?alt=media&token=6441b9d7-3d1b-40da-939a-02394edcfe71',
        'https://firebasestorage.googleapis.com/v0/b/insig-ca.appspot.com/o/general%2FphysicalGrid%2FInguinal%2Fabdomen.jpg?alt=media&token=42e1011f-58e0-45fc-af4f-469906e1159e',
      ],
    },
    {
      id: 'Musculoskeletal',
      open: false,
      sections: [
        {
          id: 'Spine',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'C Spine',
          parent: 'Spine',
          normal: false,
          abnormal: false,
        },
        {
          id: 'T Spine',
          parent: 'Spine',
          normal: false,
          abnormal: false,
        },
        {
          id: 'L Spine',
          parent: 'Spine',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Sacrum',
          parent: 'Spine',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Rib Cage',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Pelvis',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Coccyx',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Upper Extremity',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Shoulder',
          parent: 'Upper Extremity',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Elbow',
          parent: 'Upper Extremity',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Hands',
          parent: 'Upper Extremity',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Fingers',
          parent: 'Upper Extremity',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Lower Extremity',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Hip',
          parent: 'Lower Extremity',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Knee',
          parent: 'Lower Extremity',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Ankle',
          parent: 'Lower Extremity',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Foot',
          parent: 'Lower Extremity',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Toes',
          parent: 'Lower Extremity',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Head and Neck',
          parent: true,
          normal: false,
          abnormal: false,
        },
      ],
      images: [
        'https://firebasestorage.googleapis.com/v0/b/insig-ca.appspot.com/o/general%2FphysicalGrid%2FMSK%2Fmsk.gif?alt=media&token=588a3298-ca34-463e-95a1-8876bfca3de6',
      ],
    },
    {
      id: 'Derm Exam',
      open: false,
      sections: [
        {
          id: 'Derm Exam',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Head',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Neck',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Hair',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Nails',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Subcutaneous Abcess',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Eczema',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Psoriasis',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Rosacea',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Vitiligo',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Seborrhea',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Tenia Corporus',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Tenia Pedis',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Tenia Cruris',
          parent: true,
          normal: false,
          abnormal: false,
        },
      ],
      images: [
        'https://firebasestorage.googleapis.com/v0/b/insig-ca.appspot.com/o/general%2FphysicalGrid%2FSkin%2Fdermatome.jpg?alt=media&token=a4e7cfae-01c4-4e44-9c25-a0fb258ad675',
      ],
    },
    {
      id: 'Neurological',
      open: false,
      sections: [
        {
          id: 'Cranial Nerves',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Left',
          parent: 'Cranial Nerves',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Right',
          parent: 'Cranial Nerves',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Reflexes',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Upper Arm',
          parent: 'Reflexes',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Knees',
          parent: 'Reflexes',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Achilles',
          parent: 'Reflexes',
          normal: false,
          abnormal: false,
        },
        {
          id: 'Sensation',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Power',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Balance',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Temor',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Tone',
          parent: true,
          normal: false,
          abnormal: false,
        },
      ],
      images: [],
    },
    {
      id: 'Psychological',
      open: false,
      sections: [
        {
          id: 'Attention',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Dress and Grooming',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Orientation',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Affect',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Mood',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Memory Short Term',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Memory Long Term',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'Abstraction',
          parent: true,
          normal: false,
          abnormal: false,
        },
        {
          id: 'General Intelligence',
          parent: true,
          normal: false,
          abnormal: false,
        },
      ],
      images: [],
    },
  ];
} // end service
