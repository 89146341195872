import { Component, Input, Output, EventEmitter } from '@angular/core';

import { APPCONFIG } from '@insig-health/config/config';

@Component({
  selector: 'company-plan-list-header',
  templateUrl: './company-plan-list-header.component.html',
  providers: [],
  styleUrls: ['./company-plan-list-header.component.scss'],
})
export class CompanyPlanListHeaderComponent {
  @Input() selectedCategoryName: any;
  @Output() addNewService = new EventEmitter();
  @Output() companyPlanSettings = new EventEmitter();
  @Output() deleteCompanyPlan = new EventEmitter();
  @Output() copyPlanURL = new EventEmitter();
  
  // @ViewChild('collapseCompanyPlanSettings', {static: false }) settingsCollapseEl:ElementRef;
  public selectPlanImage: any;

  public elOpened = false;
  public AppConfig = APPCONFIG;

  private _selectedPlan: any;
  @Output() selectedPlanChange = new EventEmitter();
  @Input() get selectedPlan() {
    return this._selectedPlan;
  }
  set selectedPlan(val) {
    this._selectedPlan = val;
    this.selectedPlanChange.emit(this._selectedPlan);
  }
}
