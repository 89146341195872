// @ts-strict-ignore
import { Injectable } from '@angular/core';

@Injectable()
export class GoogleAnalyticsService {

  gtag(_one, _two, _three?) {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push(arguments);
  }


  googleConversionTracking(price, transactionID, label) {
    if (!price) {
      // price = 0;

      // not a conversion if 0 price
      // return false;
      price = 40;
    }
    // tia cut is 40%
    // price = price * 0.4;
    this.gtag('event', 'purchase', {
      value: price,
      currency: 'CAD',
      transaction_id: transactionID,
      items: [
        {
          id: label.replace(/\s/g, '-'),
          name: label,
          price,
          quantity: 1,
        },
      ],
    });

  } // end func

  updatePage(
    page,
    customGoogleTracking: any = false,
    customTrackingURLID: any = false
  ) {
    this.gtag(
      'config',
      `${customGoogleTracking ? customGoogleTracking : 'UA-79506801-4'}`,
      {
        page_path:
          `/virtual/book-appointment/${
            customTrackingURLID ? customTrackingURLID : 'tiaHealth'
          }/` + page,
      }
    );
    if (!customGoogleTracking) {
      this.gtag(
        'config', 'G-LMZ5V5CTF7',
        { page_path: `/virtual/book-appointment/tiaHealth}/` + page }
      );
    }

    try {
      this.wellAnalytics(page, customTrackingURLID);
    } catch(error) {
      console.log(error);
    }
  } //end func

  wellAnalytics(page, customTrackingURLID) {
    let customGoogleTracking;
    // tracking for WELL
    if ( window.location.origin.includes('app.well.company') || window.location.origin.includes('app.wellclinics.ca') ) {
      customGoogleTracking = 'AW-750407818';
      this.gtag(
        'config',
        `${customGoogleTracking}`,
        {
          page_path:
            `/virtual/book-appointment/${
              customTrackingURLID ? customTrackingURLID : 'tiaHealth'
            }/` + page,
        }
      );
      customGoogleTracking = 'AW-750407818';
      this.gtag(
        'config',
        `${customGoogleTracking}`,
        {
          page_path:
            `/virtual/book-appointment/${
              customTrackingURLID ? customTrackingURLID : 'tiaHealth'
            }/` + page,
        }
      );
      customGoogleTracking = 'UA-93943557-10';
      this.gtag(
        'config',
        `${customGoogleTracking}`,
        {
          page_path:
            `/virtual/book-appointment/${
              customTrackingURLID ? customTrackingURLID : 'tiaHealth'
            }/` + page,
        }
      );
    }
  } // end func

  googleEvent(eventName, data) {
    this.gtag('event', eventName, data);
  }

  startAnalytics(customGoogleTracking: any = false) {
    this.gtag('js', new Date());
    this.gtag(
      'config',
      `${customGoogleTracking ? customGoogleTracking : 'UA-79506801-4'}`
    );
    // google ads
    if (!customGoogleTracking) {
      this.gtag('config', 'AW-756172870');
      this.gtag('config', 'G-LMZ5V5CTF7');
    }
  }

  startWELLAnalytics() {
    this.gtag('js', new Date());
    this.gtag('config','UA-93943557-10',  {
      'linker': {
        'domains': ['virtualclinics.ca'],
        'accept_incoming': true
      }
    });
    // WELL ads and analytics tags
    this.startAnalytics('AW-666461147');
    this.startAnalytics('AW-750407818');
  } // end func

} // end service
