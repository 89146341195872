<div id="topOfLaunch" class="h-100 overflow-y-auto backgroundBlue">
  <ng-container *ngIf="!surveyError && !questionnaireAlreadyCompleted">
    <div style="position: sticky; top: 0; z-index: 1000; background-color: #E5E5E5;">
      <ng-container *ngIf="!schedulerLogin && !preview && !quickView">
        <div class="top-nav-left pl-2 pb-2 pt-3 ">
          <ng-container *ngIf="companyData?.id">
            <img
              class="mr-3 ml-1 mb-2"
              src="{{
                companyData?.branding
                  ? companyData.branding
                  : 'assets/images/global/logofullnormal.svg'
              }}"
              style="max-height:50px;max-width:250px;height:50px;"
            />
          </ng-container>
        </div>

        <div class="top-nav-right" id="googleTranslateHeader">
          <div id="google_translate_element_main"></div>
        </div>
        <div class="top-nav-right mt-2" *ngIf="!preview">
          <li class="list-inline-item">
            <button
              mat-button
              class="mat-button header-btn"
              [insMenuTriggerFor]="flagMenu"
            >
              <div class="notranslate">
                Language
              </div>
            </button>
            <mat-menu
              #flagMenu="matMenu"
              x-position="after"
              y-position="below"
              overlapTrigger="false"
            >
              <button
                mat-menu-item
                *ngFor="let language of languageList"
                (click)="switchLanguage(language)"
              >
                <div class="notranslate">
                  <span>&nbsp;{{ language }}</span>
                </div>
              </button>
            </mat-menu>
          </li>
        </div>
      </ng-container>

      <div class="w-100 py-2 m-0 text-center h4">
        Your healthcare provider needs more information to help you better. Please complete your medical questionnaire.
      </div>
    </div>

    <patient-login
      *ngIf="
        paramsLoaded && !quickView && !bypassPatientInfoForm && !formSubmitted && !schedulerLogin
      "
      [(formSubmitted)]="formSubmitted"
      [(surveyLoaded)]="surveyLoaded"
      [(preview)]="preview"
      [patientInfo]="patientInfo"
      (patientInfoChange)="handlePatientInfoChanged($event)"
    ></patient-login>

    <section class="container-fluid with-maxwidth chapter">
      <article class="article" style="padding-bottom:0;">
        <survey-pages
          *ngIf="
            surveyLoaded && ((formSubmitted && !surveySubmitted) || quickView || (bypassPatientInfoForm && !surveySubmitted))
          "
          [(currentPage)]="currentPage"
          (updateCurrentPage)="updateCurrentPage($event)"
          [doctorCompleting]="false"
          [familyId]="familyId"
          [surveyError]="surveyError"
          (submitSurvey)="submitSurvey($event)"
          [patientInfo]="patientInfo"
          [(quickView)]="quickView"
          [(schedulerLogin)]="schedulerLogin"
          [(preview)]="preview"
          [(showAds)]="showAds"
          [(survey)]="survey"
          [(tabletMode)]="tabletMode"
          [companyID]="companyID"
          [(quickPreview)]="quickView"
          [(uploadedPDFIDs)]="uploadedPDFIDs"
        >
        </survey-pages>

        <div *ngIf="surveySubmitted">
          <div *ngIf="!submittingSurvey && !errorSubmitting">
            <h2 class="article-title pl-3 pt-4 mb-4">Thank You!</h2>
            <div class="box box-default mb-0">
              <div class="box-body">
                <h5 class="my-2">
                  The results have been sent to your healthcare provider.
                </h5>
                <div *ngIf="!hideNavigationButton" class="d-flex justify-content-center">
                  <button
                    mat-raised-button
                    (click)="navigateToDashboardPage()"
                  >
                    Go to your portal
                  </button>
                </div>
                <div *ngIf="tabletMode == 'true'">
                  <div class="divider"></div>
                  <div class="divider"></div>
                  <h5>Please return the tablet to the staff.</h5>
                </div>
              </div>
            </div>
            <div *ngIf="survey.print" class="text-center">
              <div class="divider"></div>
              <div class="divider"></div>
              <button mat-raised-button (click)="printResults()">
                Print Results
              </button>
            </div>
          </div>

          <div *ngIf="!submittingSurvey && errorSubmitting">
            <h2 class="article-title pl-3">Error!</h2>
            <div class="box box-default">
              <div class="box-body">
                <h5 class="my-2">
                  There has been an error submitting the results to your
                  healthcare provider.
                </h5>
                <div *ngIf="tabletMode == 'true'">
                  <div class="divider"></div>
                  <div class="divider"></div>
                  <h5>Please inform your healthcare provider of this error.</h5>
                </div>
                <div class="divider"></div>
                <div>
                  <button
                    mat-raised-button
                    [disabled]="resubmitOnCooldown"
                    (click)="resubmitSurvey()"
                  >
                    Resubmit
                  </button>
                </div>
                <div class="divider"></div>
              </div>
            </div>
          </div>
          <div *ngIf="submittingSurvey">
            <insig-app-loader class="mx-auto"></insig-app-loader>
            <h2 class="article-title pl-3">Please wait!</h2>
            <div class="box box-default">
              <div class="box-body text-center">
                <h5 class="my-2">
                  The results are being sent to your healthcare provider.
                </h5>
                <h5>Please do not leave this page!</h5>
                <div class="divider"></div>
                <div>
                  <button
                    mat-raised-button
                    [disabled]="resubmitOnCooldown"
                    (click)="resubmitSurvey()"
                  >
                    Resubmit
                  </button>
                </div>
                <div class="divider"></div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  </ng-container>

  <ng-container *ngIf="questionnaireAlreadyCompleted">
    <h4 class="text-center mt-5 px-4">Intake Form Already Completed</h4>
    <h5 class="text-center px-4">
      Please note this intake form has already been completed.
      <br /><br />
      To edit your responses, please login at
      <a class="d-inline" href="/auth/login">{{ baseURL }}</a>
      and select your appointment on the 'Appointments' tab.
    </h5>
  </ng-container>
  <ng-container *ngIf="surveyError">
    <h5 class="text-center mt-5 px-4">Error loading questionnaire!</h5>
    <h5 class="text-center px-4">
      The questionnaire could not be found, it may have been deleted.
    </h5>
  </ng-container>
</div>
