import { Injectable } from '@angular/core';

export const NON_ALPHABETICAL_CHARACTERS_REGEX = /[^a-zA-Z]/g;

@Injectable({
  providedIn: 'root',
})
export class RegexService {

  static readonly FORBIDDEN_NAME_CHARACTERS_REGEX = /[|$^*+{}[\]!@#%&=_`~]/;
  static readonly LEADING_ZERO_REGEX = /^0+(?!$)/;
  static readonly MANUAL_APPOINTMENT_LINK_REGEX = /^.*\*m$/;
  static readonly NON_DIGIT_GLOBAL_REGEX = /\D/g;
  static readonly NON_E164_PHONE_NUMBER_CHARACTER_REGEX = /[^+\d]/g;
  static readonly PHONE_NUMBER_REGEX = /^\((\d{3})\)\s\d{3}-\d{4}$/;
  static readonly TIA_HEALTH_REGEX = /^tiaHealth$/;
  static readonly WHITESPACE_REGEX = /\s/g;
  static readonly DASH_REGEX = /-/g;

  getNonDigitGlobalRegex(): RegExp {
    return RegexService.NON_DIGIT_GLOBAL_REGEX;
  }

  getManualAppointmentLinkRegex(): RegExp {
    return RegexService.MANUAL_APPOINTMENT_LINK_REGEX;
  }

  getTiaHealthRegex(): RegExp {
    return RegexService.TIA_HEALTH_REGEX;
  }
}
