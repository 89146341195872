// @ts-strict-ignore
const StyleProperties = [
  { propertyName: 'color', allowed: true },
  { propertyName: 'font-size', allowed: true },
  { propertyName: 'text-align', allowed: true },
  { propertyName: 'background', allowed: true },
  { propertyName: 'background-color', allowed: true },
];

export default class AllowedStyleProperties {
  static isTagAllowed(propertyName) {
    const isAllowed = false;
    for (const property of StyleProperties) {
      if (property.propertyName === propertyName) {
        return !!property.allowed;
      }
    }
    return isAllowed;
  }

  static getAllowedTags() {
    const allowedTags = [];
    StyleProperties.forEach((value) => {
      if (value.allowed) {
        allowedTags.push(value);
      }
    });
    return allowedTags;
  }
}
