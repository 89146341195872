<label *ngIf="matAutocomplete" class="w-100">
  <span class="bold textSecondary">{{ label }}</span>
  <span *ngIf="_isRequired" class="required">&nbsp;*</span>
  <input
    class="bold fs-6 mt-3"
    type="text"
    autocomplete="off"
    [name]="name"
    [value]="value"
    [matAutocomplete]="matAutocomplete"
    (input)="handleChange($event)"
    (change)="handleChange($event)"
    (blur)="handleBlur()"
    [required]="_isRequired"
  />
</label>
