// @ts-strict-ignore
import { Component, OnInit, OnDestroy, inject } from '@angular/core';

import { Apollo, gql } from 'apollo-angular';

import { MatDialogRef } from '@angular/material/dialog';
import { LoadSurveyService } from 'insig-app/services/loadSurvey.service';

import { Subscription } from 'rxjs';

import { Survey } from 'insig-types/surveys';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';

@Component({
  selector: 'select-appointment-survey',
  templateUrl: './select-appointment-survey.component.html',
  providers: [LoadSurveyService],
})
export class SelectAppointmentSurveyDialogComponent
  implements OnInit, OnDestroy {
  public readonly dialogRef = inject<MatDialogRef<SelectAppointmentSurveyDialogComponent>>(MatDialogRef<SelectAppointmentSurveyDialogComponent>);
  private readonly loadSurveyService = inject(LoadSurveyService);
  private readonly gcpIpAuthService = inject(GcpIpAuthService);
  private readonly apollo = inject(Apollo);
  // graphql queries
  private getAllUserSurveyNamesQuery = gql`
    query GetAllUserSurveyNamesQuery($userId: ID!, $idToken: ID!) {
      getAllUserSurveyNames(uid: $userId, token: $idToken) {
        id
        name
      }
    }
  `;
  //

  public userList = null;
  public librarySurveys: Survey[] = null;
  public appointment = null;
  public doctorSurveys = null;
  public loading = false;
  public doctorID = null;
  public locations = [];
  private librarySurveysSubscription: Subscription = null;
  public selectedLocation: any;
  public currentScreen = 'locationSelection';

  // endpoints

  ngOnInit() {
    this.loadLibrarySurveys();
    if (!this.locations || this.locations.length === 0) {
      this.currentScreen = 'surveySelection';
    }
  }

  ngOnDestroy() {
    this.librarySurveysSubscription?.unsubscribe();
  }

  loadLibrarySurveys() {
    this.librarySurveysSubscription = this.loadSurveyService
      .watchLibrarySurveysFromFirestore()
      .subscribe((surveys) => {
        this.librarySurveys = this.sortLibraryFunc(surveys);
      });
  }

  sortLibraryFunc(array: Survey[]): Survey[] {
    return array.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  // get surveys for any userId
  async getSurveysForDoctor(userId: string): Promise<void> {
    this.loading = true;
    try {
      const getAllUserSurveyNamesQuery = await this.apollo
        .query<{ getAllUserSurveyNames?: Array<{ name: string }> }>({
          query: this.getAllUserSurveyNamesQuery,
          variables: {
            userId,
            idToken: await this.gcpIpAuthService.getCurrentUser().getIdToken(),
          },
        })
        .toPromise();
      this.doctorSurveys =
        getAllUserSurveyNamesQuery.data.getAllUserSurveyNames?.slice().sort((a, b) => {
          return a.name.localeCompare(b.name);
        }) || [];
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.doctorSurveys = [];
      this.loading = false;
    }
  }

  setLocation(location) {
    this.currentScreen = 'surveySelection';
    this.selectedLocation = location;
  }

  setSurvey(survey, type) {
    let surveyDetails = {};
    if (type === 'library') {
      surveyDetails = {
        name: survey.name,
        surveyID: survey.id,
        userID: 'library',
      };
    } else {
      surveyDetails = {
        name: survey.name,
        surveyID: survey.id,
        userID: this.doctorID,
      };
    }
    this.dialogRef.close({
      survey: surveyDetails,
      location: this.selectedLocation,
    });
  }
}
