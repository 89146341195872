<div [ngClass]="{ 'd-none': state !== LoginState.TRIAGE, 'd-flex': state === LoginState.TRIAGE }" class="h-100 flex-column">
  <div class="my-auto"></div>
  <div class="w-100 d-flex justify-content-center">
    <div class="mx-auto flex-grow-5"></div>
    <insig-health-gcp-ip-login-widget
      class="min-width-fit-content flex-grow-1 max-width-350px"
      [disableClinicianAuthentication]="disableClinicianRegistrationAndLogin"
      [disableCardBorder]="true"
      (onLoggedIn)="handleOauthComplete()"
      (onPatientSignUpButtonClicked)="state = LoginState.PATIENT_REGISTRATION"
      (onClinicianSignUpButtonClicked)="handleRegisterClinician()"
    ></insig-health-gcp-ip-login-widget>
    <div class="mx-auto flex-grow-5"></div>
  </div>
  <div class="my-auto"></div>
</div>

<patient-login-sign-up
  *ngIf="state === LoginState.PATIENT_REGISTRATION"
  (backToLoginClicked)="state = LoginState.TRIAGE"
  (registrationComplete)="handlePatientRegistrationComplete()"
>
</patient-login-sign-up>

<patient-login-sign-up
  *ngIf="state === LoginState.PATIENT_COMPLETE_PROFILE && partialPatient"
  [partialPatient]="partialPatient"
  (backToLoginClicked)="state = LoginState.TRIAGE"
  (registrationComplete)="handlePatientProfileComplete()"
>
</patient-login-sign-up>
