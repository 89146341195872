import { Injectable, inject } from '@angular/core';
import { CpsEsDoctorSearchResponse, DoctorManagementService, Province } from '@insig-health/api/doctor-api';
import { ProvinceService } from '../province/province.service';
import { firstValueFrom } from 'rxjs';

export interface CpsEsDoctor {
  fullName: string;
  address: string;
  faxNumber: string;
  province: Province;
}

@Injectable({
  providedIn: 'root',
})
export class CpsEsService {
  private readonly doctorManagementService = inject(DoctorManagementService);
  private readonly provinceService = inject(ProvinceService);

  async searchCpsEsDoctors(nameQuery: string, province: string, limit: number): Promise<CpsEsDoctor[]> {
    try {
      const cpsEsProvince = this.provinceService.convertToServerProvince(this.provinceService.parseQueryParamProvince(province));
      const cpsEsDoctorSearchResponses = await firstValueFrom(this.doctorManagementService.getDoctorsFromCpsEs(nameQuery, cpsEsProvince, limit));
      return this.convertCpsEsDoctorSearchResponses(cpsEsDoctorSearchResponses);
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  private convertCpsEsDoctorSearchResponses(cpsEsDoctorSearchResponses: CpsEsDoctorSearchResponse[]): CpsEsDoctor[] {
    return cpsEsDoctorSearchResponses
      .filter ((cpsEsDoctorSearchResponse: CpsEsDoctorSearchResponse): cpsEsDoctorSearchResponse is CpsEsDoctorSearchResponse & { province: string } =>
        cpsEsDoctorSearchResponse.province !== undefined)
      .map((cpsEsDoctorSearchResponse: CpsEsDoctorSearchResponse & { province: string }) => {
        return {
          fullName: cpsEsDoctorSearchResponse.fullName ?? '',
          address: cpsEsDoctorSearchResponse.address ?? '',
          faxNumber: cpsEsDoctorSearchResponse.faxNumber ?? '',
          province: this.provinceService.parseQueryParamProvince(cpsEsDoctorSearchResponse.province),
        };
      });
  }
}
