<h1 mat-dialog-title>Create a User Group</h1>
<mat-dialog-content>
  <form [formGroup]="form" class="full-width">
    <div class="form-group">
      <mat-form-field class="full-width">
        <mat-label>User Group Name</mat-label>
        <input
          matInput
          required
          type="text"
          formControlName="userGroupName"
        />
        <mat-error>Required</mat-error>
      </mat-form-field>
    </div>

    <div>Create a user group of:</div>
    <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="userGroupType" class="w-100">
      <div class="w-100 d-flex">
        <mat-button-toggle
          value="patient"
          class="flex-even"
          [ngClass]="{
            'button-selected': userGroupType == 'patient',
            'button-not-selected': userGroupType != 'patient'
          }"
          (click)="setUserGroupType('patient')"
        >
          Patients
        </mat-button-toggle>
        <mat-button-toggle
          value="practitioner"
          class="flex-even"
          [ngClass]="{
            'button-selected': userGroupType == 'practitioner',
            'button-not-selected': userGroupType != 'practitioner'
          }"
          (click)="setUserGroupType('practitioner')"
        >
          Practioners
        </mat-button-toggle>
      </div>
    </mat-button-toggle-group>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button (click)="dialogRef.close(false)">Cancel</button>
    <button
      mat-raised-button
      color="primary"
      class="float-right"
      (click)="addUserGroup()"
    >
      Create Plan
    </button>
</mat-dialog-actions>
