<mat-card>
  <mat-card-title>Add {{ type }} groups to plan</mat-card-title>
  <mat-card-content>
    <div class="mt-5 text-center">
      <mat-chip-listbox
        class="full-width text-center"
        *ngFor="let group of userGroupList"
      >
        <mat-chip-option
          class="mx-auto my-2"
          [selected]="!!group.selected"
          (click)="group.selected = !group.selected"
          >{{ group.title }}</mat-chip-option
        >
      </mat-chip-listbox>
    </div>
  </mat-card-content>
  <div class="divider"></div>
  <div class="divider"></div>
  <mat-card-actions>
    <button mat-raised-button (click)="dialogRef.close(false)">Cancel</button>
    <button
      mat-raised-button
      color="primary"
      class="float-right"
      (click)="addGroups()"
    >
      Add Groups
    </button>
  </mat-card-actions>
</mat-card>
