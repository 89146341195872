import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialComponentsModule } from '../../../material-components.module';
import { ClipboardModule } from 'ngx-clipboard';

import { CompanyPlanServicesListComponent } from './company/plan-services-list/company-plan-services-list.component';
import { CompanyUserGroupListComponent } from './company/user-group-list/company-user-group-list.component';
import { SharedVirtualServiceComponent } from './shared/virtual-service/shared-virtual-service.component';
import { GlobalPaymentFormComponent } from './company/payment-form/global-payment-form.component';
import { TableFilterDirective } from './company/user-group-list/directives/table-filter.directive';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MaterialComponentsModule,
    ClipboardModule,
  ],
  exports: [
    CompanyPlanServicesListComponent,
    SharedVirtualServiceComponent,
    CompanyUserGroupListComponent,
    GlobalPaymentFormComponent,
    TableFilterDirective,
  ],
  declarations: [
    CompanyPlanServicesListComponent,
    SharedVirtualServiceComponent,
    CompanyUserGroupListComponent,
    TableFilterDirective,
    GlobalPaymentFormComponent,
  ],
})
export class GlobalVirtualSettingsModule {}
