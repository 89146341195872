<div class="color-picker">
  <div class="summary-info mx-auto" (click)="toggleColors()">
    <div class="info">
      <p class="style">{{ heading }}</p>
      <p class="style-value">{{ color }}</p>
    </div>

    <div class="circle mx-auto" [ngStyle]="{ background: color }"></div>
  </div>

  <div *ngIf="show" class="opened">
    <div class="colors">
      <div
        (click)="changeColor(paint)"
        *ngFor="let paint of defaultColors"
        class="circle mx-auto"
        [ngStyle]="{ background: paint }"
      ></div>
    </div>

    <div class="hex-code">
      <p>Hex Code</p>
      <div class="g-input">
        <input
          type="text"
          maxlength="7"
          [value]="color"
          (keyup)="changeColorManual(paintInput.value)"
          (keydown.enter)="toggleColors()"
          #paintInput
        />
      </div>
    </div>
  </div>
</div>
