<div>
  <br />
  <div class="text-center">
    <mat-form-field>
      <mat-label>Time Type</mat-label>
      <mat-select
        required
        [(ngModel)]="question.timeType"
      >
        <mat-option [value]="'time'">Time</mat-option>
        <mat-option [value]="'dateTime'">Date Time</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="divider"></div>
  <div *ngIf="question.timeType == 'time' || !question.timeType">
    <div class="form-group row">
      <div class="col-2"></div>
      <div class="col-8">
        <mat-form-field class="full-width">
          <mat-label>Answer here</mat-label>
          <input
            type="time"
            matInput
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
  <div *ngIf="question.timeType == 'dateTime'">
    <div class="form-group row">
      <div class="col-2"></div>
      <div class="col-8">
        <mat-form-field class="full-width">
          <mat-label>Answer here</mat-label>
          <input
            type="datetime-local"
            matInput
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</div>
