<span class="float-right requiredQuestion" *ngIf="question?.required">
  Required
</span>

<h4 [innerHTML]="question?.text"></h4>

<div class="divider hidden-xs-down"></div>
<div class="divider"></div>

<div [ngSwitch]="question?.type" class="text-center">
  <div *ngSwitchCase="'text'" class="form-group">
    <div class="form-group row">
      <div class="col-2"></div>
      <div class="col-8">
        <mat-form-field class="full-width">
          <mat-label>Answer here</mat-label>
          <input
            matInput
            autocomplete="off"
            [ngModel]="question?.response.answer"
            (ngModelChange)="handleQuestionResponseChange(question, 'answer', $event)"
          />
        </mat-form-field>
      </div>
      <div class="col-2"></div>
    </div>
  </div>

  <div *ngSwitchCase="'textarea'" class="form-group">
    <div class="form-group row">
      <div class="col-2"></div>
      <div class="col-8">
        <mat-form-field class="full-width">
          <mat-label>Answer here</mat-label>
          <textarea
            matInput
            rows="3"
            autocomplete="off"
            [ngModel]="question?.response.answer"
            (ngModelChange)="handleQuestionResponseChange(question, 'answer', $event)"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="col-2"></div>
    </div>
  </div>

  <div *ngSwitchCase="'number'">
    <number-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></number-question>
  </div>

  <div *ngSwitchCase="'email'">
    <div class="form-group row">
      <div class="col-2"></div>
      <div class="col-8">
        <mat-form-field>
          <span matPrefix>
            <mat-icon class="notranslate material-icons">email</mat-icon>&nbsp;
          </span>
          <mat-label>Email here</mat-label>
          <input
            matInput
            type="email"
            pattern="^.+@.+\..+$"
            autocomplete="off"
            [ngModel]="question?.response.answer"
            (ngModelChange)="handleQuestionResponseChange(question, 'answer', $event)"
          />
          <mat-error>Please enter a valid email</mat-error>
        </mat-form-field>
      </div>
      <div class="col-2"></div>
    </div>
  </div>

  <div *ngSwitchCase="'address'">
    <address
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></address>
  </div>

  <div *ngSwitchCase="'phone'">
    <div class="form-group row">
      <div class="col-2"></div>
      <div class="col-8">
        <mat-form-field>
          <span matPrefix>
            <mat-icon class="notranslate material-icons">phone</mat-icon>&nbsp;
          </span>
          <mat-label>Phone number here</mat-label>
          <input
            matInput
            type="tel"
            autocomplete="off"
            minlength="10"
            maxlength="14"
            insig-phone-mask
            [ngModel]="question?.response.answer"
            (ngModelChange)="handleQuestionResponseChange(question, 'answer', $event)"
          />
          <mat-error>Please enter a valid phone number</mat-error>
        </mat-form-field>
      </div>
      <div class="col-2"></div>
    </div>
  </div>

  <div *ngSwitchCase="'radio'">
    <radio-question
      [(pageElement)]="pageElement"
      (checkTriggersChild)="checkTriggersParent($event)"
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
      [elementID]="elementID"
      [(triggersDictionary)]="triggersDictionary"
    ></radio-question>
  </div>

  <div *ngSwitchCase="'scoring'">
    <mat-radio-group
      [ngModel]="question?.response.selectedAnswer"
      (ngModelChange)="handleQuestionResponseChange(question, 'selectedAnswer', $event)"
      class="vertical-group"
    >
      <tr *ngFor="let answer of question?.offeredAnswers; let i = index">
        <td>
          <mat-radio-button [value]="answer.id"> </mat-radio-button>
        </td>
        <td
          class="text-left"
          (click)="setQuestionSelectedAnswerId(question, answer.id)"
        >
          {{ answer.value }}
        </td>
      </tr>
    </mat-radio-group>
  </div>

  <div *ngSwitchCase="'checkbox'">
    <checkbox-question
      class="vertical-group"
      (checkTriggersChild)="checkTriggersParent($event)"
      [(triggersDictionary)]="triggersDictionary"
      [elementID]="elementID"
      [(pageElement)]="pageElement"
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></checkbox-question>
  </div>

  <div *ngSwitchCase="'yesno'">
    <yesno-question
      [(pageElement)]="pageElement"
      (checkTriggersChild)="checkTriggersParent($event)"
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
      [elementID]="elementID"
      [(triggersDictionary)]="triggersDictionary"
    ></yesno-question>
  </div>

  <div *ngSwitchCase="'multiyesno'">
    <multiyesno-question
      [(pageElement)]="pageElement"
      (checkTriggersChild)="checkTriggersParent($event)"
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
      [elementID]="elementID"
      [(triggersDictionary)]="triggersDictionary"
    ></multiyesno-question>
  </div>

  <div *ngSwitchCase="'yearMonthDay'">
    <yearmonthday-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></yearmonthday-question>
  </div>

  <div *ngSwitchCase="'select'">
    <select-question
      [(pageElement)]="pageElement"
      (checkTriggersChild)="checkTriggersParent($event)"
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
      [elementID]="elementID"
      [(triggersDictionary)]="triggersDictionary"
    ></select-question>
  </div>

  <div *ngSwitchCase="'date'">
    <date-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></date-question>
  </div>

  <div *ngSwitchCase="'time'">
    <div *ngIf="question?.timeType == 'time' || !question?.timeType">
      <div class="form-group row">
        <div class="col-2"></div>
        <div class="col-8">
          <mat-form-field>
            <mat-label>Enter time</mat-label>
            <input
              type="time"
              matInput
              autocomplete="off"
              [ngModel]="question?.response.answer"
              (ngModelChange)="handleQuestionResponseChange(question, 'answer', $event)"
            />
          </mat-form-field>
        </div>
        <div class="col-2"></div>
      </div>
    </div>
    <div *ngIf="question?.timeType == 'dateTime'">
      <div class="form-group row">
        <div class="col-md-4 col-sm-2"></div>
        <div class="col-md-4 col-sm-8">
          <mat-form-field class="full-width">
            <mat-label>Enter date-time</mat-label>
            <input
              type="datetime-local"
              matInput
              autocomplete="off"
              [ngModel]="question?.response.answer"
              (ngModelChange)="handleQuestionResponseChange(question, 'answer', $event)"
            />
          </mat-form-field>
        </div>
        <div class="col-md-4 col-sm-2"></div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'dateRange'">
    <daterange-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></daterange-question>
  </div>

  <div *ngSwitchCase="'photograph'">
    <photograph-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></photograph-question>
  </div>

  <div *ngSwitchCase="'grid'">
    <grid-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></grid-question>
  </div>

  <div *ngSwitchCase="'fiveStar'">
    <mat-slider
      discrete
      showTickMarks
      class="full-width"
      [max]="5"
      [min]="0"
    >
      <input
        matSliderThumb
        [ngModel]="question?.response.answer"
        (ngModelChange)="handleQuestionResponseChange(question, 'answer', $event)"  
      />
    </mat-slider>
  </div>

  <div *ngSwitchCase="'signature'">
    <signature-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
      [(doctorCompleting)]="doctorCompleting"
    ></signature-question>
  </div>

  <div *ngSwitchCase="'shareNote'">
    <shareNote-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></shareNote-question>
  </div>

  <div *ngSwitchCase="'drawing'">
    <drawing-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></drawing-question>
  </div>

  <div *ngSwitchCase="'diagram'">
    <diagram-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></diagram-question>
  </div>

  <div *ngSwitchCase="'pdf'">
    <pdf-question
      [(uploadedPDFIDs)]="uploadedPDFIDs"
      [(morePDFs)]="morePDFs"
      [companyID]="companyID"
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></pdf-question>
  </div>

  <div *ngSwitchCase="'range'">
    <div class="form-group row">
      <div class="col-2"></div>
      <div class="col-8">
        <span class="float-left">{{
          question?.min ? question?.min : 1
        }}</span>
        <span class="float-right">{{
          question?.max ? question?.max : 10
        }}</span>
        <mat-slider
          discrete
          showTickMarks
          class="full-width"
          [max]="question?.max ? question?.max : 10"
          [min]="question?.min ? question?.min : 1"
        >
          <input
            matSliderThumb
            [ngModel]="question?.response.answer"
            (ngModelChange)="handleQuestionResponseChange(question, 'answer', $event)"
            [value]="value"
          />
        </mat-slider>
      </div>
      <div class="col-2"></div>
    </div>
  </div>

  <div *ngSwitchCase="'link'">
    <link-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    > </link-question>
  </div>

  <div *ngSwitchCase="'physicalGrid'">
    <physical-grid-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></physical-grid-question>
  </div>

  <div *ngSwitchCase="'priority'">
    <priority-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></priority-question>
  </div>

  <div *ngSwitchCase="'doctors'">
    <doctors-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></doctors-question>
  </div>

  <div *ngSwitchCase="'medicationAllergies'">
    <medication-allergies-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></medication-allergies-question>
  </div>

  <div *ngSwitchCase="'heightWeight'">
    <div class="row">
      <div class="col-sm-5 offset-sm-1">
        <b>Height:</b> inches
        <mat-slide-toggle
          class="mb-4"
          [ngModel]="question?.response.metricHeight"
          (ngModelChange)="handleQuestionResponseChange(question, 'metricHeight', $event)"
          >cm
        </mat-slide-toggle>
        <!-- <span class="bold">Height</span> -->
        <div class="row" [hidden]="question?.response.metricHeight">
          <div class="col-6">
            <mat-form-field class="full-width">
              <mat-label>feet</mat-label>
              <input
                matInput
                spellcheck="false"
                [ngModel]="question?.response.feet"
                (ngModelChange)="handleQuestionResponseChange(question, 'feet', $event)"
                type="number"
              />
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="full-width">
              <mat-label>inches</mat-label>
              <input
                matInput
                spellcheck="false"
                [ngModel]="question?.response.height"
                (ngModelChange)="handleQuestionResponseChange(question, 'height', $event)"
                type="number"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row" [hidden]="!question?.response.metricHeight">
          <div class="col-6 offset-3">
            <mat-form-field class="full-width">
              <mat-label>cm</mat-label>
              <input
                matInput
                spellcheck="false"
                [ngModel]="question?.response.height"
                (ngModelChange)="handleQuestionResponseChange(question, 'height', $event)"
                type="number"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
      <!-- //dave -->
      <div class="col-sm-4 offset-sm-1 col-md-3 offset-md-2 col-12">
        <b>Weight:</b> lbs
        <mat-slide-toggle
          class="mb-4"
          [ngModel]="question?.response.metricWeight"
          (ngModelChange)="handleQuestionResponseChange(question, 'metricWeight', $event)"
          >kg</mat-slide-toggle
        >
        <mat-form-field class="full-width">
          <mat-label>{{ question?.response.metricWeight ? 'kg' : 'lbs' }}</mat-label>
          <input
            matInput
            spellcheck="false"
            [ngModel]="question?.response.weight"
            (ngModelChange)="handleQuestionResponseChange(question, 'weight', $event)"
            type="number"
          />
        </mat-form-field>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'medications'">
    <medications-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></medications-question>
  </div>

  <div *ngSwitchCase="'conditions'">
    <conditions-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></conditions-question>
  </div>

  <div *ngSwitchCase="'bloodPressure'">
    <blood-pressure
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></blood-pressure>
  </div>

  <div *ngSwitchCase="'surgery'">
    <surgery-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></surgery-question>
  </div>

  <div *ngSwitchCase="'autocomplete'">
    <autocomplete-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></autocomplete-question>
  </div>

  <div *ngSwitchCase="'video'">
    <video-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
    ></video-question>
  </div>

  <div *ngSwitchCase="'bookAppointment'">
    <book-appointment-question
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
      [patientInfo]="patientInfo"
    ></book-appointment-question>
  </div>

  <div *ngSwitchCase="'collectPayment'">
    <collect-payment
      [question]="question"
      (questionChange)="handleQuestionChange($event)"
      [patientInfo]="patientInfo"
      [companyID]="companyID"
    ></collect-payment>
  </div>

  <div *ngSwitchDefault>
    <h4>
      * {{ question?.type }} question type not available. Please ignore this
      question.
    </h4>
  </div>
</div>
<div class="divider"></div>
<div class="divider"></div>
<hr />
