// @ts-strict-ignore
import { Component, ViewChild, OnDestroy } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';

@Component({
  selector: 'photograph-edit',
  templateUrl: './photograph-edit.component.html',
})
export class PhotographEditComponent implements OnDestroy {
  @ViewChild('video') video: any;
  @ViewChild('canvas') canvas: any;
  private context = null;
  public videoWidth = 640;
  public videoHeight = 480;
  public testPhoto = null;
  private localStream = null;
  public permissionDenied = false;
  public activeCamera = false;

  activateCamera() {
    this.setupCamera();
    this.activeCamera = true;
  }

  async onAdded(fileDropEntries: NgxFileDropEntry[]): Promise<void> {
    const fileDrop = fileDropEntries[0];
    const fileData = await new Promise<File>((resolve) => {
      const fileEntry = fileDrop.fileEntry as FileSystemFileEntry;
      fileEntry.file((file) => {
        resolve(file);
      });
    });

    const reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = () => {
      this.testPhoto = reader.result;
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  ngOnDestroy() {
    if (this.activeCamera && this.video) {
      this.video.nativeElement.pause();
      this.video.nativeElement.src = '';
      if (this.localStream) {
        this.localStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    }
  }

  takePhoto() {
    this.context = this.canvas.nativeElement.getContext('2d');
    this.context.drawImage(this.video.nativeElement, 0, 0, 640, 480);
    this.testPhoto = this.canvas.nativeElement.toDataURL('image/png');
  }

  retakePhoto() {
    this.testPhoto = null;
  }

  async setupCamera() {
    try {
      const _video = this.video.nativeElement;
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        this.localStream = stream;
        _video.srcObject = stream;
        _video.play();
      }
    } catch (error) {
      if (error.name === 'PermissionDeniedError' || error.name === 'NotAllowedError') {
        this.permissionDenied = true;
      } else {
        console.error(error);
        throw error;
      }
    }
  }
}
