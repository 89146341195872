<form [formGroup]="numberForm">
  <div class="form-group row">
    <div class="col-2"></div>
    <div class="col-8">
      <mat-form-field>
        <mat-label>Answer here</mat-label>
        <input
          matInput
          type="number"
          min="{{ question.min }}"
          max="{{ question.max }}"
          formControlName="number"
          autocomplete="off"
        />
        <mat-error *ngIf="numberForm.value.number > question.max"
          >Please enter a value less than {{ question.max }}</mat-error
        >
        <mat-error *ngIf="numberForm.value.number < !question.max"
          >Please enter a value greater than {{ question.min }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-2"></div>
  </div>
</form>
