import { Component, Input, Output, EventEmitter, inject } from '@angular/core';
import { CkConfigService } from 'insig-app/services/ckConfig.service';
@Component({
  selector: 'share-note-edit',
  templateUrl: './share-note-edit.component.html',
})
export class ShareNoteEditComponent {
  private readonly ckConfigService = inject(CkConfigService);
  @Input() question: any;
  @Output() questionChange = new EventEmitter<any>();
  public ckConfig: any;
  constructor() {
    this.ckConfig = this.ckConfigService.getConfig();
  }
}
