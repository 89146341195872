// @ts-strict-ignore
import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit, inject } from '@angular/core';
import { PaymentService } from 'insig-app/services/payment.service';
import { NetworkService } from 'insig-app/services/virtual/network.service';

import { MatSnackBar } from '@angular/material/snack-bar';

import firebase from 'firebase/compat/app';
import 'firebase/auth';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';


@Component({
  selector: 'collect-creditcard',
  templateUrl: './collect-creditcard.component.html',
  styleUrls: ['./collect-creditcard.component.scss'],
  providers: [PaymentService, NetworkService],
})
export class CollectCreditcardComponent implements AfterViewInit {
  private readonly paymentService = inject(PaymentService);
  private readonly networkService = inject(NetworkService);
  private readonly snackBar = inject(MatSnackBar);
  private readonly firebaseAuthService = inject(FirebaseAuthService);
  @Input() companyID: any;
  @Input() questionnaire: boolean = true;
  private _customerRef: any;
  @Output() customerRefChange = new EventEmitter();
  set customerRef(val) {
    this._customerRef = val;
    this.customerRefChange.emit(this._customerRef);
  }

  @Input() get customerRef() {
    // this.parseVirtualArray()
    return this._customerRef;
  }

  @Output() paymentAdded = new EventEmitter();

  public cardAdded = false;
  submitPaymentForm: any;
  private stripeKey = null;
  private stripe: any;
  public loadingPayment = false;
  public completePayment = false;

  public card = null;
  public cardNumber = null;
  public cardExpiry = null;
  public cardCvc = null;
  public submitPaymentButton: any;
  public name = null;
  public email = null;

  ngAfterViewInit() {
    this.stripeKey = this.paymentService.getStripeKey();
    // await this.loadCompanyData(this.cid);

    setTimeout(() => {
      this.setupStripe();
    }, 1000);
  }

  setupStripe() {
    this.stripe = (window as any).Stripe(this.stripeKey);
    const elements = this.stripe.elements();

    // let currency = 'cad';
    // let countryCode = 'CA';
    const elementStyles = {
      base: {
        color: 'rgba(0, 0, 0, 0.6)',
        fontWeight: 500,
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontSmoothing: 'antialiased',

        '::placeholder': {
          color: '#CFD7DF',
        },
        ':-webkit-autofill': {
          color: '#e39f48',
        },
      },
      invalid: {
        color: '#E25950',

        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    };

    const elementClasses = {
      focus: 'focused',
      empty: 'empty',
      invalid: 'invalid',
    };

    this.cardNumber = elements.create('cardNumber', {
      style: elementStyles,
      classes: elementClasses,
    });
    this.cardNumber.mount('#insig-payment-form2-card-number');

    this.cardExpiry = elements.create('cardExpiry', {
      style: elementStyles,
      classes: elementClasses,
    });
    this.cardExpiry.mount('#insig-payment-form2-card-expiry');

    this.cardCvc = elements.create('cardCvc', {
      style: elementStyles,
      classes: elementClasses,
    });
    this.cardCvc.mount('#insig-payment-form2-card-cvc');

    this.registerElements(
      [this.cardNumber, this.cardExpiry, this.cardCvc],
      'form-insig-payment-form2'
    );
  } // end func

  changeCard() {
    // NOTE: need to check card when updating card
    console.log('changing card');
    this.cardAdded = false;
  }

  cardConfirmed() {
    this.cardAdded = true;
  }

  async afterStripeToken(tokenObject) {
    this.loadingPayment = true;
    console.log('creating customer');
    this.snackBar.open('Validating ...', null, { duration: 4000 });
    let createCustomerResponse;
    try {
      if (!!this.questionnaire) {
        createCustomerResponse = await this.paymentService.createCustomerQuestionnaire(
          this.companyID || 'WMjUjSCa1veZHwng0mwQ87BNEhMquBY8',
          tokenObject,
          this.name,
          this.email
        );
      } else {
        let IDToken = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();
        createCustomerResponse = await this.networkService.createCustomerNetwork(
          IDToken,
          tokenObject,
          this.name,
          this.email
        );
      }
    } catch (err) {
      console.log('error: ', err);
      if (!this.questionnaire) {
        this.paymentAdded.emit();
      }
    }

    console.log(createCustomerResponse);
    if (
      createCustomerResponse &&
      createCustomerResponse.body &&
      createCustomerResponse.statusCode == 200
    ) {
      // this.createVirtualCareCharge();
      this.loadingPayment = false;
      this.completePayment = true;
      this.customerRef = createCustomerResponse.body;
      this.paymentAdded.emit();

      this.snackBar.open('Thank you!', null, { duration: 4000 });
    } else {
      this.loadingPayment = false;
      setTimeout(() => {
        this.setupStripe();
      }, 1000);
      if (!!this.questionnaire) {
        this.snackBar.open(
          'Card error! Please verify your card details and try again.',
          null,
          { duration: 4000 }
        );
      }
    }
  }

  registerElements(elements, exampleName) {
    const formClass = '.' + exampleName;
    const form: any = document.querySelector(formClass);
    // let form = example.querySelector('form');
    // let error = form.querySelector('.error');
    // let errorMessage: any = error.querySelector('.message');

    function triggerBrowserValidation() {
      // The only way to trigger HTML5 form validation UI is to fake a user submit
      // event.
      const submit = document.createElement('input');
      submit.type = 'submit';
      submit.style.display = 'none';
      form.appendChild(submit);
      submit.click();
      submit.remove();
    }

    // Listen for errors from each Element, and show error messages in the UI.
    const savedErrors = {};
    elements.forEach((element, idx) => {
      element.on('change', (event) => {
        if (event.error) {
          // error.classList.remove('hide');
          savedErrors[idx] = event.error.message;
          // errorMessage.innerText = event.error.message;
        } else {
          savedErrors[idx] = null;

          // Loop over the saved errors and find the first one, if any.
          const nextError = Object.keys(savedErrors)
            .sort()
            .reduce((maybeFoundError, key) => {
              return maybeFoundError || savedErrors[key];
            }, null);

          if (nextError) {
            // Now that they've fixed the current error, show another one.
            // errorMessage.innerText = nextError;
          } else {
            // The user fixed the last error; no more errors.
            // error.classList.add('hide');
          }
        }
      });
    });

    // Listen on the form's 'submit' handler...
    this.submitPaymentForm = (() => {
      console.log('in here');
      // e.preventDefault();
      // e.preventDefault();
      // Trigger HTML5 validation UI on the form if any of the inputs fail
      // validation.
      let plainInputsValid = true;
      Array.prototype.forEach.call(form.querySelectorAll('input'), (input) => {
        if (input.checkValidity && !input.checkValidity()) {
          plainInputsValid = false;
          return;
        }
      });
      if (!plainInputsValid) {
        triggerBrowserValidation();
        return;
      }

      // Gather additional customer data we may have collected in our form.
      const zip: any = form.querySelector('#' + exampleName + '-zip');
      const additionalData = {
        name: this.name,
        address_zip: zip ? zip.value : undefined,
        email: this.email,
      };

      // already a customer
      this.stripe
        .createToken(this.cardNumber, additionalData)
        .then(async (response) => {
          try {
            console.log(response);
            if (response.error) {
              this.snackBar.open(response.error.message, null, {
                duration: 4000,
              });
              this.loadingPayment = false;

              return false;
            } else if (!response.token) {
              this.snackBar.open(
                'Error! Please check your card number and try again.',
                null,
                { duration: 4000 }
              );
              this.loadingPayment = false;

              return false;
            }

            this.afterStripeToken(response.token);
          } catch (error) {
            console.log(error);
            this.snackBar.open(
              'Error! Please check your card number and try again.',
              null,
              { duration: 4000 }
            );
            this.loadingPayment = false;
          }
        });
    }).bind(this);
  }
}
