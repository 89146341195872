<div #container class="text-center">
  <canvas #canvas class="signatureStyle"></canvas>
</div>

<div class="divider"></div>

<div class="row">
  <div class="col-3 offset-3">
    <button mat-raised-button (click)="clear()">Clear</button>
  </div>
  <div class="col-6">
    <mat-checkbox
      [checked]="question.terms"
      (change)="question.terms = !question.terms"
      >Show Terms and Conditions</mat-checkbox
    >
    <div *ngIf="question.terms">
      <br />
      <mat-checkbox
        [checked]="!question.hideTerms"
        (change)="question.hideTerms = !question.hideTerms"
        >Add Terms and Conditions to Note</mat-checkbox
      >
    </div>
  </div>
</div>

<div *ngIf="question.terms">
  <div class="divider"></div>
  <ckeditor
    [(ngModel)]="question.termsContent"
    [config]="ckConfig"
    [readonly]="false"
  >
  </ckeditor>
</div>
