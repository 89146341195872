import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'page-403',
  templateUrl: './403.component.html',
  styleUrls: ['./403.component.scss'],
})
export class Page403Component {

}
