<mat-card>
  <mat-card-title>Are you sure you want to leave this page?</mat-card-title>
  <mat-card-content
    >Your changes will be lost if you do not save them.</mat-card-content
  >
  <div class="divider"></div>
  <div class="divider"></div>
  <mat-card-actions class="d-flex">
    <button mat-raised-button (click)="dialogRef.close(true)">Leave</button>
    <button mat-raised-button class="ml-auto" (click)="dialogRef.close(false)">
      Stay
    </button>
    <button mat-raised-button color="primary" (click)="saveAndLeave()">
      Save & Leave
    </button>
  </mat-card-actions>
</mat-card>
