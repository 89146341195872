import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';
import { MatDialogModule } from '@angular/material/dialog';

import { InputMasksModule } from '@insig-health/input-masks/input-masks.module';

import { InsigTooltipDirective } from './tooltip/insig-tooltip.directive';
import { InsigTooltipComponent } from './tooltip/insig-tooltip.component';

import { InsigSearchbarComponent } from './searchbar/insig-searchbar.component';

import { InsigSearchSortComponent } from './searchsort/insig-searchsort.component';

import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from '@angular/material/snack-bar';

import { InsigStripeFormComponent } from './stripe-form/insig-stripe-form.component';
import { InsigPatientSignUpFormComponent } from './patient-sign-up-form/insig-patient-sign-up-form.component';

import { InsigDragbarComponent } from './dragbar/insig-dragbar.component';

@NgModule({
  imports: [
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PortalModule,
    InputMasksModule,
    MatDialogModule,
    MatSnackBarModule,
  ],
  exports: [
    InsigTooltipDirective,
    InsigSearchbarComponent,
    InsigSearchSortComponent,
    InsigStripeFormComponent,
    InsigPatientSignUpFormComponent,
    InsigDragbarComponent,
  ],
  declarations: [
    InsigTooltipDirective,
    InsigTooltipComponent,
    InsigSearchbarComponent,
    InsigSearchSortComponent,
    InsigStripeFormComponent,
    InsigPatientSignUpFormComponent,
    InsigDragbarComponent,
  ],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        verticalPosition: 'top',
      },
    },
  ]
})
export class InsigMaterialModule { }
