<div class="h5 text-center">
  {{
    !!title
      ? title
      : !!doctorMode
      ? "Please enter your patient's address"
      : 'Please enter your address'
  }}
</div>

<div class="row mx-0 mb-5">
  <div class="col-12 col-6-lg col-8-md mx-auto">
    <mat-form-field class="w-100">
      <mat-icon matPrefix class="notranslate">search</mat-icon>
      <mat-label>
        {{
          !!doctorMode
            ? "Please enter your patient's address"
            : 'Please enter your address'
        }}
      </mat-label>
      <input
        matInput
        #searchAddress
        [(ngModel)]="searchValue"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
  </div>
</div>
