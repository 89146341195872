// @ts-strict-ignore
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Input, inject } from '@angular/core';
import { Router } from '@angular/router';

import { PaymentService } from 'insig-app/services/payment.service';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

import { UserManagerService } from 'insig-app/services/userManager.service';
import { APPURL, STRIPE_CLIENT_ID } from '@insig-health/config/config';

@Component({
  selector: 'bank-info',
  templateUrl: './bank-info.component.html',
  styleUrls: ['./bank-info.component.css'],
  providers: [PaymentService, UserManagerService],
})
export class BankInfoComponent implements OnInit, OnDestroy {
  private readonly paymentService = inject(PaymentService);
  private readonly firebaseAuthService = inject(FirebaseAuthService);
  private readonly router = inject(Router);
  public readonly stripeConnectUrl = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID}&scope=read_write`;

  @ViewChild('searchAddress')
  public addressElementRef: ElementRef;
  @Input() companyBank = false;
  @Input() quickStart = false;
  @Input() companyManagedBankAccount = false;
  public currentURL = this.router.url;
  public currentHref = window.location.href;
  public originURL = APPURL;
  private unsubscribe = null;
  private userID = null;
  private connectActive = false;
  private IDToken = null;
  private loading = false;

  ngOnInit() {
    console.log(this.currentURL);
    try {
      this.originURL = window.location.origin;
    } catch (err) {
      console.log(err);
    }

    this.unsubscribe = this.firebaseAuthService
      .onIdTokenChanged()
      .subscribe(async (user) => {
        if (user) {
          this.userID = user.uid;
          this.IDToken = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();
          this.loading = true;
          console.log(this.companyBank);
          await this.getStripeConnectAccountDetails(
            this.IDToken,
            this.companyBank
          );
          this.loading = false;
        }
      });
    console.log(this.companyManagedBankAccount);
    console.log(this.connectActive);
  }

  ngOnDestroy() {
    this.unsubscribe.unsubscribe();
  }

  async getStripeConnectAccountDetails(IDToken, companyBank) {
    if (!companyBank) {
      this.checkActiveCompanyConnectID(IDToken, true);
    }
    try {
      console.log(companyBank);
      const response = await this.paymentService.getStripeConnectAccountDetails(
        IDToken,
        companyBank
      );
      console.log(response);
      if (response && response.body && response.body.charges_enabled) {
        this.connectActive = true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async checkActiveCompanyConnectID(IDToken, companyBank) {
    try {
      const response = await this.paymentService.getStripeConnectAccountDetails(
        IDToken,
        companyBank
      );
      console.log('Checking company connect ID:');
      console.log(response);
      if (response && response.body && response.body.charges_enabled) {
        console.log('Company managed Bank!');
        this.companyManagedBankAccount = true;
      }
    } catch (error) {
      console.log(error);
    }
  }
}
