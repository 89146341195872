<div *ngIf="schedule">
  <div *ngIf="selectedWeekday" class="primary-color-full">
    <h3 class="text-center text-white p-3">
      {{ selectedWeekday.charAt(0).toUpperCase() + selectedWeekday.slice(1) }}
    </h3>
  </div>
  <ng-container *ngIf="exceptionDate">
    <div class="divider"></div>
    <div class="text-center bg-warning">
      <div class="divider"></div>
      <div
        class="d-flex flex-row align-items-center justify-content-start px-1"
      >
        <h3 class="mx-auto my-0 text-truncate">
          {{ exceptionDate.toDateString() }}
        </h3>
      </div>
      <div class="divider"></div>
    </div>
  </ng-container>
  <div class="divider"></div>
  <div class="d-flex flex-row flex-wrap align-items-center">
    <button
      mat-raised-button
      class="flex-static primary-color-full"
      (click)="backToCalendar($event)"
    >
      <mat-icon class="notranslate">keyboard_backspace</mat-icon>
      <span>&nbsp;Back</span>
    </button>
    <mat-slide-toggle
      *ngIf="exceptionDate"
      class="pl-4"
      [ngModel]="schedule.exception"
      (ngModelChange)="toggleException($event)"
      color="primary"
      >Enable Exception</mat-slide-toggle
    >
    <div class="pl-4">
      <!-- spacer -->
    </div>
    <mat-form-field class="pt-4 flex">
      <mat-label>Appointment Time Increment</mat-label>
      <mat-select
        [ngModel]="schedule.timeIncrement"
        (ngModelChange)="changeTimeIncrement($event)"
      >
        <mat-option [value]="'4'">4 minutes</mat-option>
        <mat-option [value]="'5'">5 minutes</mat-option>
        <mat-option [value]="'6'">6 minutes</mat-option>
        <mat-option [value]="'7'">7 minutes</mat-option>
        <mat-option [value]="'8'">8 minutes</mat-option>
        <mat-option [value]="'9'">9 minutes</mat-option>
        <mat-option [value]="'10'">10 minutes</mat-option>
        <mat-option [value]="'15'">15 minutes</mat-option>
        <mat-option [value]="'20'">20 minutes</mat-option>
        <mat-option [value]="'30'">30 minutes</mat-option>
        <mat-option [value]="'45'">45 minutes</mat-option>
        <mat-option [value]="'60'">1 hour</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <hr />

  <div class="row">
    <div class="col-6">
      <span>Schedule time zone:</span>
      <mat-select
        [ngModel]="schedule.operatingTimeZone"
        (ngModelChange)="
          schedule.operatingTimeZone = $event; scheduleChange.emit(schedule)
        "
      >
        <mat-option
          *ngFor="let timeZone of getIanaTimeZones()"
          [value]="timeZone"
          >{{ timeZone }}</mat-option
        >
      </mat-select>
    </div>
    <div class="col-6">
      <span>Device time zone:<br /></span>
      <span>{{ dateAndTimeService.getLocalTimeZone() }}</span>
    </div>
  </div>

  <hr />

  <div *ngIf="schedule.timeSegments.length <= 0" class="text-center py-3">
    No time segments
  </div>

  <div *ngIf="schedule.timeSegments.length > 0" class="timeSegmentsGrid p-3 gap-2">
    <div class="text-center bold">From<br>(24-Hour)</div>
    <div class="text-center bold">To<br>(24-Hour)</div>
    <div class="text-center bold">Virtual / In-Clinic Availability</div>
    <div class="text-center bold">Location</div>
    <div><!-- spacer --></div>

    <ng-container *ngFor="let segment of schedule.timeSegments; let segmentIndex = index">
      <mat-form-field>
        <mat-select
          [value]="segment.operatingStartTime"
          (selectionChange)="changeSegmentStartTime(segment, $event)"
        >
          <mat-option
            *ngFor="
              let time of filterOut(
                generateTimeSlots(MINIMUM_START_TIME, segment.endTime, schedule.timeIncrement),
                segment.operatingEndTime
              )
            "
            [value]="time"
          >
            {{ doctorSettingsService.convertMsPastMidnightToTimeString(time) }}
          </mat-option>
          <mat-option
            *ngIf="
              isTimeMissingFromGeneratedTimeSlots(
                segment.operatingStartTime,
                MINIMUM_START_TIME,
                segment.endTime,
                schedule.timeIncrement
              )
            "
            [value]="segment.operatingStartTime"
          >
            {{ doctorSettingsService.convertMsPastMidnightToTimeString(segment.operatingStartTime) }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select
          [value]="segment.operatingEndTime"
          (selectionChange)="changeSegmentEndTime(segment, $event)"
        >
          <mat-option
            *ngFor="
              let time of filterOut(
                generateTimeSlots(segment.operatingStartTime, MAXIMUM_END_TIME, schedule.timeIncrement),
                segment.operatingStartTime
              )
            "
            [value]="time"
            >{{ doctorSettingsService.convertMsPastMidnightToTimeString(time) }}</mat-option
          >
          <mat-option
            *ngIf="
              isTimeMissingFromGeneratedTimeSlots(
                segment.operatingEndTime,
                segment.operatingStartTime,
                MAXIMUM_END_TIME,
                schedule.timeIncrement
              )
            "
            [value]="segment.operatingEndTime"
            >{{ doctorSettingsService.convertMsPastMidnightToTimeString(segment.operatingEndTime) }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select
          [ngModel]="segment.availability"
          (ngModelChange)="changeSegmentAvailability(segment, $event)"
        >
          <mat-option [value]="'virtual'">Virtual Care</mat-option>
          <mat-option [value]="'clinic'">In Clinic</mat-option>
          <mat-option [value]="'both'">Virtual and Clinic</mat-option>
          <mat-option [value]="'default'">Default</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select
          [ngModel]="segment.location"
          (ngModelChange)="changeSegmentLocation(segment, $event)"
          [disabled]="
            segment.availability === 'virtual' ||
            (segment.availability === 'default' &&
              defaultAvailability === 'virtual') ||
            locations.length === 0
          "
          [placeholder]="
            segment.availability === 'virtual'
              ? ''
              : segment.availability === 'default' &&
                defaultAvailability === 'virtual'
              ? 'Online'
              : locations.length === 0
              ? 'No locations setup'
              : !!segment.location
              ? ''
              : 'Select a location'
          "
        >
          <ng-container
            *ngIf="
              segment.availability !== 'virtual' &&
              !(
                segment.availability === 'default' &&
                defaultAvailability === 'virtual'
              )
            "
          >
            <ng-container *ngFor="let location of locations">
              <mat-option [value]="location.id">
                {{ location.name }}
              </mat-option>
            </ng-container>
          </ng-container>

          <mat-option
            *ngIf="segment.availability === 'virtual'"
            [value]="'virtual'"
          >
            Online
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div>
        <button
          mat-mini-fab
          class="mb-4"
          color="none"
          (click)="removeTimeSegment(segmentIndex)"
        >
          <mat-icon class="notranslate">clear</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>

  <div class="text-center full-width">
    <button
      mat-raised-button
      class="primary-color-full"
      (click)="addTimeSegment()"
    >
      Add Time Segment
    </button>
  </div>
</div>
