<button
  [attr.type]="type"
  class="h-100 d-flex align-items-center justify-content-center border-box cursor-pointer"
  [ngClass]="{
    'disabled': disabled,
    'primary-button': stylePreset === 'primary',
    'secondary-button': stylePreset === 'secondary',
    'tertiary-button': stylePreset === 'tertiary',
    'pink-button': stylePreset === 'pink',
    'white-button': stylePreset === 'white',
    'white-button bg-white': stylePreset === 'fullWhite',
    'gray-button': stylePreset === 'gray',
    'flat-button': stylePreset === 'flat'
  }"
  (click)="handleClick()"
  [disabled]="disabled"
>
  <img
    *ngIf="iconSrc"
    [src]="iconSrc"
    class="pe-2 pr-2 button-icon"
    [ngStyle]="{'height.px': iconHeight ?? null }" 
    alt=""
  >
  <ng-content></ng-content>
</button>
