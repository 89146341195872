<div>
  <table
    class="table table-hover"
    style="table-layout: fixed;word-wrap: break-word;"
    *ngIf="companyPlanVirtualServices?.length > 0"
  >
    <thead>
      <tr>
        <th scope="col" style="width:15%" *ngIf="!adminView">Opt-In</th>
        <th scope="col" style="width:45%">Label</th>
        <!-- <th scope="col" style="width:15%" class="hidden-md-down">Dur.</th> -->
        <!-- <th scope="col" style="width:15%" class="hidden-md-down">Price</th> -->
        <th scope="col" style="width:15%" class="hidden-md-down">&nbsp;</th>
        <th scope="col" style="width:15%" class="hidden-md-down">&nbsp;</th>
        <th scope="col" style="width:15%" *ngIf="adminView">% Covered</th>
        <th scope="col" style="width:10%"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let service of companyPlanVirtualServices; let i = index">
        <td *ngIf="!adminView">
          <mat-checkbox
            [(ngModel)]="userCompanyPlanMapping[service.apptID]"
            (click)="toggleService.emit(service)"
          ></mat-checkbox>
        </td>
        <td (click)="serviceClicked.emit(service)">
          <h4 class="service-header full-width">{{ service.data.label }}</h4>
        </td>
        <td class="hidden-md-down" (click)="serviceClicked.emit(service)">
          <!-- <span class="service-sample-detail">{{service.data.duration}} min.</span> -->
        </td>

        <td class="hidden-md-down" (click)="serviceClicked.emit(service)">
          <!-- <span class="service-sample-detail">${{service.data.price}}</span> -->
        </td>
        <td
          *ngIf="adminView"
          class="hidden-md-down"
          (click)="serviceClicked.emit(service)"
        >
          <span class="service-sample-detail"
            >{{
              service.data.coverPercent ? service.data.coverPercent : '0'
            }}
            %</span
          >
        </td>
        <td (click)="serviceClicked.emit(service)">
          <mat-icon
            class="float-right notranslate pull-right material-icons"
            style="font-size:36px;margin-top:auto;margin-bottom:auto;"
            >chevron_right</mat-icon
          >
        </td>
      </tr>
    </tbody>
  </table>

  <div
    class="row text-center"
    *ngIf="companyPlanVirtualServices?.length == 0 && adminView"
  >
    <h4 class="col-12">No services in plan!</h4>
    <div class="divider divider-grey"></div>

    <div
      class="button-secondary primary-color-full"
      (click)="addServicesBtnClicked.emit()"
    >
      <div class="d-flex flex-row justify-content-center align-items-center">
        <mat-icon class="notranslate material-icons">note_add</mat-icon>
        <span>&nbsp;Add Service</span>
      </div>
    </div>
  </div>
</div>
