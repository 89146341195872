// @ts-strict-ignore
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { PhysicalGridService } from 'insig-app/services/physicalGrid.service';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'physical-grid-question',
  templateUrl: './physicalgrid.component.html',
  providers: [PhysicalGridService],
})
export class PhysicalGridComponent implements OnInit, OnChanges {
  private readonly physicalGridService = inject(PhysicalGridService);
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();

  public defaultStructure = this.physicalGridService.getDefaultStructure();
  public checkboxes = this.physicalGridService.getCheckboxes();
  public dictatingDictionary = {};

  ngOnInit() {
    if (!this.question.standard) {
      this.question.standard = {};
    }
    // setting up response dictionary
    if (!this.question.physical || !this.question.response.physicalGrid) {
      const tempQuestion = {};
      const tempResponse = {};
      this.defaultStructure.forEach((value) => {
        tempQuestion[value.id] = value.open;
        tempResponse[value.id] = {};
        value.sections.forEach((value2) => {
          tempResponse[value.id][value2.id] = value2;
        });
      });
      this.question.physical = tempQuestion;
      this.question.response.physicalGrid = tempResponse;
    }
    if (!this.question.response.checkboxes) {
      this.question.response.checkboxes = {};
    }
  } // end init

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  autofillDescription(physID, partID, partSentence) {
    if (
      this.question.standard[physID] &&
      this.question.standard[physID][partID]
    ) {
      this.question.response.physicalGrid[physID][
        partID
      ].desc = this.question.standard[physID][partID];
    } else {
      this.question.response.physicalGrid[physID][partID].desc = partSentence;
    }
    this.question.response.physicalGrid[physID][partID].value = 'normal';
  }
} // end component
