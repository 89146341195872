import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  getQueryWithIdAsObservable<T>(query: firebase.firestore.Query): Observable<Array<T & { id: string }>> {
    return Observable.create((observer: Observer<firebase.firestore.DocumentData>) => {
      return query.onSnapshot({
        next: (querySnapshot) => {
          observer.next(querySnapshot.docs.map((document) => {
            const data = document.data();
            const id = document.id;
            return { ...data, id };
          }));
        },
        error: (error) => {
          console.error(error);
          observer.error(error);
        },
      });
    });
  }

  getQueryAsObservable<T>(query: firebase.firestore.Query): Observable<T[]> {
    return Observable.create((observer: Observer<firebase.firestore.DocumentData>) => {
      return query.onSnapshot({
        next: (querySnapshot) => {
          observer.next(querySnapshot.docs.map((document) => document.data()));
        },
        error: (error) => {
          console.error(error);
          observer.error(error);
        },
      });
    });
  }

  getCollectionAsObservable<T>(collectionReference: firebase.firestore.CollectionReference): Observable<T[]> {
    return Observable.create((observer: Observer<firebase.firestore.DocumentData>) => {
      return collectionReference.onSnapshot({
        next: (snapshot) => {
          observer.next(snapshot.docs.map((document) => document.data()));
        },
        error: (error) => {
          console.error(error);
          observer.error(error);
        },
      });
    });
  }

  getCollectionWithIdsAsObservable<T>(collectionReference: firebase.firestore.CollectionReference): Observable<Array<T & { id: string }>> {
    return Observable.create((observer: Observer<firebase.firestore.DocumentData>) => {
      return collectionReference.onSnapshot({
        next: (snapshot) => {
          observer.next(snapshot.docs.map((document) => {
            const data = document.data();
            const id = document.id;
            return { ...data, id };
          }));
        },
        error: (error) => {
          console.error(error);
          observer.error(error);
        },
      });
    });
  }

  getDocumentAsObservable<T>(documentReference: firebase.firestore.DocumentReference): Observable<T> {
    return Observable.create((observer: Observer<firebase.firestore.DocumentData | undefined>) => {
      return documentReference.onSnapshot({
        next: (snapshot) => {
          observer.next(snapshot.data());
        },
        error: (error) => {
          console.error(error);
          observer.error(error);
        },
      });
    });
  }

  // Safe and intentional use of the any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeNestedUndefined<T extends { [index: string]: any }>(documentData: T): T {
    Object.entries(documentData).forEach(([property, value]) => {
      if (value === undefined) {
        delete documentData[property];
      }

      // recursively remove undefined values from nested properties
      if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        documentData = {
          ...documentData,
          [property]: this.removeNestedUndefined(value),
        };
      }
    });

    return documentData;
  }

}
