// @ts-strict-ignore
import { Component } from '@angular/core';

@Component({
  selector: 'height-weight-edit',
  templateUrl: './height-weight-edit.component.html',
})
export class HeightWeightEditComponent {
  public metricHeight;
  public metricWeight;
} // end component
