// @ts-strict-ignore
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

@Component({
  selector: 'insig-referral-map',
  templateUrl: './insig-referral-map.component.html',
  styleUrls: ['./insig-referral-map.component.scss'],
})
export class InsigReferralMapComponent implements OnInit {
  public blankReferral = {
    address: '',
    name: 'There are no locations near your patient',
  };

  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  @Output() clearPatientAddress = new EventEmitter();

  @Output() blankReferralClicked = new EventEmitter();
  @Input() referralType: string = null;
  @Input() doctorMode: any = false;
  // referralOptions
  private _referralOptions: any = [];
  @Output() referralOptionsChange = new EventEmitter();
  @Input() set referralOptions(val) {
    console.log({ referralOptions: val });
    this._referralOptions = val;
    val.forEach((referralOption) => {
      const markerOptions: google.maps.MarkerOptions = {
        position: { lat: referralOption.lat, lng: referralOption.lng },
        draggable: false,
        clickable: true,
        animation: referralOption.animate ? google.maps.Animation.BOUNCE : undefined,
      };
      referralOption.markerOptions = markerOptions;
    });
    this.referralOptionsChange.emit(val);
  }

  get referralOptions() {
    // this.parseVirtualArray()
    return this._referralOptions;
  }

  // referralSelected
  private _referralSelected: any;
  @Output() referralSelectedChange = new EventEmitter();
  set referralSelected(val) {
    this._referralSelected = val;
    this.referralSelectedChange.emit(this._referralSelected);
  }

  @Input() get referralSelected() {
    // this.parseVirtualArray()
    return this._referralSelected;
  }

  @Input() mapSettings;
  @Input() patientAddress;

  public homeMarkerOptions: google.maps.MarkerOptions = {};
  public infoWindowReferralOption = {};

  ngOnInit() {
    console.log(this.patientAddress);
    console.log(this.mapSettings);
    this.homeMarkerOptions = {
      position: { lat: this.mapSettings.lat, lng: this.mapSettings.lng },
      icon: '/assets/images/question-types/book-appointment/home.png',
      draggable: false,
    };
  }

  // ngOnChanges(changes) {
  //   console.log(changes);
  //   if (changes.patientAddress) {
  //     console.log('updated patient address:', this.patientAddress);
  //   }
  // }

  bounceReferral(referral) {
    referral.animate = true;
  }

  noBounceReferral(referral) {
    referral.animate = false;
  }

  referralClicked(referral) {
    this.referralSelected = referral;
  }

  openInfoWindow(marker: MapMarker, referralOption) {
    this.infoWindowReferralOption = referralOption;
    this.infoWindow.open(marker);
  }
}
