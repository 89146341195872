// @ts-strict-ignore
import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnChanges, SimpleChanges, inject } from '@angular/core';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'grid-question',
  templateUrl: './grid.component.html',
})
export class GridComponent implements OnInit, OnChanges {
  private readonly elementRef = inject(ElementRef);
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();

  @ViewChild('scrollContainer', { read: ElementRef })
  private scrollContainer: ElementRef;

  ngOnInit() {
    if (!this.question.tempResponse) {
      this.question.tempResponse = {};
      for (const row in this.question.grid.rows) {
        if (this.question.grid.rows[row]) {
          this.question.tempResponse[this.question.grid.rows[row].id] = {};
        }
      }
    }
    this.elementRef.nativeElement.addEventListener(
      'touchmove',
      this.handleTouchMove,
      false
    );
    this.elementRef.nativeElement.addEventListener(
      'touchend',
      this.handleTouchEnd,
      false
    );
  } // end ngOnInit

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  addNewRow() {
    const row = {
      id: this.generateRandomID(32),
      orderNo: this.question.grid.rows.length + 1,
      label: null,
    };
    this.question.tempResponse[row.id] = {};
    this.question.grid.rows.push(row);
  } // end add row

  private previousTouch: Touch;
  private handleTouchMove = (touchEvent: TouchEvent) => {
    touchEvent.preventDefault();
    const touches = touchEvent.changedTouches;
    if (touches.length === 1) {
      // Emulate scrolling only if one finger used
      const touch = touches[0];
      if (this.previousTouch) {
        const xDiff = this.previousTouch.clientX - touch.clientX;
        const yDiff = this.previousTouch.clientY - touch.clientY;
        this.getScrollParent(
          this.elementRef.nativeElement.parentNode as HTMLElement
        ).scrollBy(0, yDiff);
        this.scrollContainer.nativeElement.scrollLeft += xDiff;
      }
      this.previousTouch = touch;
    }
  };

  private handleTouchEnd = (_touchEvent: TouchEvent) => {
    this.previousTouch = undefined;
  };

  getScrollParent(node: HTMLElement): HTMLElement {
    // Recursively find the first scrollable parent
    const overflowY = window.getComputedStyle(node).overflowY;
    const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';

    if (!node) {
      return null;
    } else if (isScrollable && node.scrollHeight >= node.clientHeight) {
      return node;
    }

    return (
      this.getScrollParent(node.parentNode as HTMLElement) || document.body
    );
  }

  generateRandomID(length): string {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
}
