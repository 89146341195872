// @ts-strict-ignore
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnInit, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'company-user-group-list',
  templateUrl: './company-user-group-list.component.html',
  styleUrls: ['./company-user-group-list.component.scss'],
})
export class CompanyUserGroupListComponent implements OnInit {
  public readonly changeDetector = inject(ChangeDetectorRef);
  @Input() userList: any = [];
  @Input() userGroup: any;

  @Output() addUserBtnClicked = new EventEmitter();
  @Output() toggleClicked = new EventEmitter();
  @Output() deleteBtnClicked = new EventEmitter();
  @Output() deleteUserGroup = new EventEmitter();
  @Output() userClicked = new EventEmitter();

  usersControl: UntypedFormControl = new UntypedFormControl();
  private filteredOptions: Observable<any>;

  ngOnInit() {
    this.filteredOptions = this.usersControl.valueChanges.pipe(
      startWith(''),
      map((val) => this.filter(val))
    );
  }

  filter(searchVal: string): string[] {
    const filterKeys = ['first', 'last', 'email'];
    return this.userList.filter((user) => {
      for (const key of filterKeys) {
        if (user[key].toLowerCase().includes(searchVal.toLowerCase())) {
          return true;
        }
      }
      return false;

      // return option.toLowerCase().indexOf(val.toLowerCase()) === 0
    });
  }

  updateFilters(nameFilter, emailFilter) {
    for (const user of this.userList) {
      if (
        !(
          (user.first + ' ' + user.last)
            .toLowerCase()
            .indexOf(nameFilter.toLowerCase()) === -1
        ) && // if name contains nameFilter and;
        !(user.email.toLowerCase().indexOf(emailFilter.toLowerCase()) === -1)
      ) {
        // email contains emailFilter
        this.userList.push(user);
      }
    }
    this.userList.forEach((_item, index) => {
      const htmlItem = document.getElementById('User' + index);
      if (!(htmlItem === null || htmlItem === undefined)) {
        if (htmlItem.classList.contains('selected')) {
          htmlItem.classList.remove('selected');
        }
      }
    });
    // this.updateSnackBar();
  }

  updateSorts(sortNames, sortEmails) {
    if (sortNames) {
      this.userList.sort((a, b) =>
        (a.first + a.last).localeCompare(b.first + b.last)
      );
    }
    if (sortEmails) {
      this.userList.sort((a, b) => a.email.localeCompare(b.email));
    }
    this.changeDetector.detectChanges(); // Necessary to refesh checkbox css classes
    // this.updateSnackBar();
  }
} // end class
