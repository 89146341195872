// @ts-strict-ignore
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef, inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Apollo, gql } from 'apollo-angular';

import { APPCONFIG } from '@insig-health/config/config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaymentService } from '../services/payment.service';
import { SubscriptionsService } from '../services/subscriptions.service';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

@Component({
  selector: 'insig-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: [
    './subscriptions.component.style.css',
    '../../styles/payment/payment.scss',
  ],
  providers: [
    PaymentService,
    SubscriptionsService,
  ],
})
export class SubscriptionsComponent implements OnInit, OnDestroy {
  private readonly firebaseAuthService = inject(FirebaseAuthService);
  private readonly subscriptionsService = inject(SubscriptionsService);
  private readonly snackBar = inject(MatSnackBar);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly paymentService = inject(PaymentService);
  private readonly apollo = inject(Apollo);
  // graphql queries
  private userDataQuery = gql`
    query UserDataQuery($userID: ID!, $token: ID!) {
      getUserData(uid: $userID, token: $token) {
        uid
        first
        last
        phone
      }
    }
  `;

  //

  public AppConfig: any;
  private stripeKey = null;
  public subscriptions = null;
  ///////////////////////////////////////
  ///////////////////////////////////////
  // turn this on to allow free dev account
  public devMode = false;
  ///////////////////////////////////////
  ///////////////////////////////////////
  public subscription = null;
  private stripe: any;
  private card = null;
  @ViewChild('paymentName') public paymentName: ElementRef;
  public loading = false;
  private subscriptionType = null;
  private IDToken = null;
  public userData = null;
  private authSub = null;
  // endpoints

  ngOnInit() {
    this.subscriptions = this.subscriptionsService.subscriptions;
    this.route.params.subscribe((params) => {
      this.subscriptionType = params.type;
    });
    this.stripeKey = this.paymentService.getStripeKey();
    this.setupStripe();
    this.AppConfig = APPCONFIG;

    this.authSub = this.firebaseAuthService
      .onIdTokenChanged()
      .subscribe(async (user) => {
        if (user) {
          try {
            this.IDToken = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();
            try {
              const userDataQuery: any = await this.apollo
                .query({
                  query: this.userDataQuery,
                  variables: {
                    userID: user.uid,
                    token: this.IDToken,
                  },
                })
                .toPromise();
              const userData = userDataQuery.data.getUserData;
              this.userData = userData;
              this.userData.displayPhone = this.formatPhoneNumber(
                this.userData.phone
              );
            } catch (error) {
              switch (error.code) {
                default:
                  console.log(error);
                  break;
                case 'PERMISSION_DENIED':
                  console.log('Wrong uid. User may have logged out.');
                  break;
              }
            }
          } catch (error) {
            console.log(error);
            this.router.navigate(['/auth/login']);
          }
        } else {
          this.router.navigate(['/auth/login']);
        }
      });
  } // end init

  formatPhoneNumber(s) {
    const s2 = ('' + s).replace(/\D/g, '');
    const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
    return !m ? null : '(' + m[1] + ') ' + m[2] + '-' + m[3];
  }

  ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  setupStripe() {
    // strip stuff
    this.stripe = (window as any).Stripe(this.stripeKey);
    const elements = this.stripe.elements();
    this.card = elements.create('card', {
      style: {
        base: {
          iconColor: '#666EE8',
          color: '#31325F',
          lineHeight: '40px',
          fontWeight: 300,
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSize: '15px',
          '::placeholder': {
            color: '#CFD7E0',
          },
        },
      },
    });
    this.card.mount('#card-element');
    this.card.on('change', ((event) => {
      this.setOutcome(event);
    }).bind(this));
  }

  setOutcome(result) {
    const successElement = document.querySelector('.success');
    const errorElement = document.querySelector('.error');
    successElement.classList.remove('visible');
    errorElement.classList.remove('visible');
    if (result.token) {
      successElement.querySelector('.token').textContent = result.token.id;
      successElement.classList.add('visible');
    } else if (result.error) {
      errorElement.textContent = result.error.message;
      errorElement.classList.add('visible');
    }
  }

  async submitPayment(e) {
    e.preventDefault();
    const extraDetails = {
      name: this.paymentName.nativeElement.value,
    };
    this.loading = true;
    try {
      const tokenRes = await this.stripe.createToken(this.card, extraDetails);
      if (tokenRes.token) {
        console.log('Creating customer.');
        // TODO add error checking to createCustomer function
        const response = await this.paymentService.createCustomer(
          this.IDToken,
          tokenRes.token,
          'user'
        );
        console.log(response);
        if (
          response &&
          response.statusCode === 200 &&
          response.body === 'success'
        ) {
          console.log('Customer created');
          await this.createSubscription(
            this.IDToken,
            this.subscription.subscriptionID
          );
        } else if (response.body.type === 'StripeCardError') {
          this.loading = false;
          this.snackBar.open(response.body.message, null, { duration: 5000 });
        } else {
          this.snackBar.open('Error! Please contact us.', null, {
            duration: 5000,
          });
          this.loading = false;
          return false;
        }
      } else {
        throw tokenRes.error;
      }
    } catch (error) {
      console.log(error);
      this.loading = false;
      this.snackBar.open(error.message, null, { duration: 2000 });
    }
  } // end submit payment func

  createSubscription(IDToken, subscriptionID) {
    console.log('Creating new subscription');
    this.paymentService
      .createSubscription(IDToken, subscriptionID, false)
      .then((response) => {
        console.log(response);
        if (
          response &&
          response.statusCode === 200 &&
          response.body === 'success'
        ) {
          this.snackBar.open('Subscription added!', null, { duration: 4000 });
          this.router.navigate(['/app/surveys/show-surveys']);
        } else {
          this.snackBar.open(
            'Error adding subscription, please contact us!',
            null,
            { duration: 4000 }
          );
        }
        this.loading = false;
      });
  }

  selectSubscription(subscription) {
    this.subscription = this.subscriptions[subscription];
  }
} // end class
