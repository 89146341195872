// @ts-strict-ignore
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'checkbox-question',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnChanges, OnInit, OnDestroy {
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();
  @Input() pageElement = null;
  @Input() elementID = null;
  @Input() triggersDictionary = null;
  @Output() checkTriggersChild: EventEmitter<string> = new EventEmitter();

  ngOnInit() {
    if (!this.question.response.selectedAnswers) {
      this.question.response.selectedAnswers = [];
      this.question.selectedAnswers = [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  ngOnDestroy() {
    if (this.pageElement.triggerHidden === true) {
      this.removeAllTriggers();
    }
  }

  sendCheckTriggers() {
    this.checkTriggersChild.emit('true');
  }

  removeAllTriggers() {
    for (const index in this.question.offeredAnswers) {
      if (this.question.offeredAnswers[index]) {
        this.modifyTriggers(false, index);
      }
    }
  }

  // changeTo means hide or show child, index is which answer of the checkboxes
  modifyTriggers(changeTo, index) {
    if (
      this.question.offeredAnswers[index].pageFlow &&
      this.question.offeredAnswers[index].pageFlow.trigger
    ) {
      const triggersDictionary = this.triggersDictionary;
      const answerID = this.question.offeredAnswers[index].id;
      // change each childs trigger property for this answer
      this.question.offeredAnswers[index].pageFlow.trigger.forEach((child) => {
        if (triggersDictionary[child]) {
          triggersDictionary[child][answerID] = changeTo;
        } else {
          console.log(
            'Incorrect JSON for this question. Subquestion array error'
          );
        }
      });
    } // end if
    this.sendCheckTriggers();
  } // end mod triggers

  setSelectedAnswers(event, answer, answerIndex) {
    // update triggers
    this.modifyTriggers(event, answerIndex);
    // add answer to response
    if (event) {
      this.question.response.selectedAnswers.push(answer);
    } else {
      // remove answer from response
      const index = this.question.response.selectedAnswers.indexOf(answer);
      this.question.response.selectedAnswers.splice(index, 1);
    }
  } // end setSelectedAnswers
} // end component
