// @ts-strict-ignore
import { Component, OnInit, OnDestroy, inject } from '@angular/core';

import { Apollo, gql } from 'apollo-angular';

import { APPCONFIG } from '@insig-health/config/config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

import { UserManagerService } from 'insig-app/services/userManager.service';

import { LayoutService } from '../layout.service';

import firebase from 'firebase/compat/app';
import 'firebase/auth';

@Component({
  selector: 'my-app-customizer',
  templateUrl: './customizer.component.html',
  providers: [LayoutService, UserManagerService],
})
export class AppCustomizerComponent implements OnInit, OnDestroy {
  private readonly layoutService = inject(LayoutService);
  private readonly snackBar = inject(MatSnackBar);
  private readonly firebaseAuthService = inject(FirebaseAuthService);
  private readonly apollo = inject(Apollo);
  // graphql queries
  private userDataQuery = gql`
    query UserDataQuery($userID: ID!) {
      getUserData(uid: $userID) {
        uid
        company
      }
    }
  `;

  private companyDataMutation = gql`
    mutation CompanyDataMutation(
      $companyID: ID!
      $token: ID!
      $data: CompanyInput!
    ) {
      setCompanyData(cid: $companyID, token: $token, data: $data) {
        AppConfig {
          colorOption
          theme
        }
        ThemeConfig {
          primary
          secondary
          text
          textSecondary
          textSelected
        }
      }
    }
  `;

  //

  // endpoints
  private IDToken = null;
  private unsubscribe = null;
  public AppConfig: any;
  private companyID = null;

  ngOnInit() {
    this.AppConfig = APPCONFIG;
    this.unsubscribe = this.firebaseAuthService
      .onIdTokenChanged()
      .subscribe(async (user) => {
        if (user) {
          this.IDToken = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();

          const userDataQuery: any = await this.apollo
            .query({
              query: this.userDataQuery,
              variables: {
                userID: user.uid,
              },
            })
            .toPromise();
          const userData = userDataQuery.data.getUserData;
          if (userData) {
            this.companyID = userData.company;
          }
        }
      });
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
  }

  onLayoutChange = () => {
    this.layoutService.updateEChartsState(true);
  };

  async saveCompanyData(): Promise<void> {
    console.log('Saving App Config');
    if (!this.companyID) {
      console.log('CID not loaded');
      return;
    }
    const newData = {
      AppConfig: {
        colorOption: this.AppConfig.colorOption,
        theme: this.AppConfig.theme,
      },
    };
    try {
      await this.apollo
        .mutate({
          mutation: this.companyDataMutation,
          variables: {
            companyID: this.companyID,
            token: this.IDToken,
            data: newData,
          },
        })
        .toPromise();
      this.snackBar.open('App Configuration Saved!', null, { duration: 2000 });
    } catch (error) {
      console.log(error);
      this.snackBar.open('Error saving App Configuration!', null, {
        duration: 2000,
      });
    }
  }
}
