<mat-card>
  <div #container class="{{ drawingBorder }}">
    <canvas #canvas></canvas>
  </div>

  <div class="divider"></div>

  <div class="w-100 text-center">
    <span class="mx-2">Width</span>
    <span *ngFor="let width of lineWidths">
      <button
        color="{{ lineWidth == width ? 'primary' : '' }}"
        mat-raised-button
        (click)="changeLineWidth(width)"
      >
        {{ width / 2 }}
      </button>
    </span>
  </div>

  <div class="divider"></div>

  <div class="w-100 text-center">
    <button
      *ngFor="let color of colors"
      mat-raised-button
      [ngStyle]="{ 'background-color': color }"
      (click)="changeColor(color)"
    >
      &nbsp;
    </button>
  </div>

  <div class="divider"></div>

  <button mat-raised-button (click)="clear()">Clear</button>

  <button
    class="float-right"
    mat-raised-button
    color="primary"
    (click)="doneDrawing()"
  >
    Done
  </button>
</mat-card>
