// @ts-strict-ignore
import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  ViewChild,
  Input,
  SimpleChanges, inject } from '@angular/core';
import { CHARTCONFIG } from '../../charts/charts.config';
import { EChartsDirective } from '../../shared/echarts.directive';

import { SchedulerService } from 'insig-app/services/scheduler.service';

import { eachDay, subDays, startOfDay } from 'date-fns';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'main-dashboard',
  templateUrl: './main-dashboard.component.html',
})
export class MainDashboardComponent implements OnInit, OnDestroy, OnChanges {
  private readonly schedulerService = inject(SchedulerService);
  @ViewChild(EChartsDirective) chartsDir;
  config = CHARTCONFIG;
  public menuItems: any = [
    {
      iden: 'dashboard',
      icon: 'dashboard',
      title: 'DASHBOARD',
    },
    {
      iden: 'companyPlans',
      icon: 'assignment',
      title: 'COMPANY PLANS',
    },
    {
      iden: 'patientPlans',
      icon: 'account_box',
      title: 'PATIENT PLANS',
    },
  ];

  public selectedTab = 'dashboard';
  private days = [
    'Sun. ',
    'Mon. ',
    'Tue. ',
    'Wed. ',
    'Thu. ',
    'Fri. ',
    'Sat. ',
  ] as const;

  @Input() userId: string;
  @Input() companyId: string;
  @Input() isPatientAdmin: boolean;

  public timeSlotsPerDayOfTheWeek: any = [];
  public totalVideoAppts = 0;
  public totalPhoneAppts = 0;
  public totalClinicAppts = 0;
  public averagePhoneCallLength = 0;
  public averageVideoCallLength = 0;

  totalAppointments: number;
  totalBookings: number;
  totalNoShows: number;
  totalCancelled: number;
  totalRevenue: number;
  netRevenue: number;
  totalApptSlots: number;

  viewDate = new Date();

  public chartData: any;

  public dailyAppointmentsStats: any[] = [];
  public dailyBookingsStats: any[] = [];

  public dailyAppointmentsSubscription: Subscription;
  public dailyBookingsSubscription: Subscription;

  ngOnInit(): void {
    this.getDashboardData(this.viewDate);
  }

  ngOnDestroy(): void {
    if (!!this.dailyAppointmentsSubscription) {
      this.dailyAppointmentsSubscription.unsubscribe();
    }
    if (!!this.dailyBookingsSubscription) {
      this.dailyBookingsSubscription.unsubscribe();
    }
  }

  ngOnChanges(_changes: SimpleChanges): void {
    console.log({ _changes });
    this.getDashboardData(this.viewDate);
  }

  async getDashboardData(date: Date): Promise<void> {
    if (this.isPatientAdmin) {
      this.getAppointmentsAndBookingsPatient(date);
    } else {
      await this.getAppointmentsAndBookingsProvider(date);
    }
  }

  // patient stats are net of no-shows and cancellations to not show inflated numbers
  getAppointmentsAndBookingsPatient(date: Date): void {
    if (!!this.dailyAppointmentsSubscription) {
      this.dailyAppointmentsSubscription.unsubscribe();
    }
    this.dailyAppointmentsSubscription = this.schedulerService
      .getPatientAppointmentsByMonthDashboard(this.userId, date)
      .subscribe((snapshot) => {
        if (snapshot !== null && snapshot.length !== 0) {
          const dailyAppointmentsStats = {};
          snapshot.forEach((appt) => {
            if (!appt.status || appt.status === 'completed') {
              if (
                !dailyAppointmentsStats[new Date(appt.event.start).getDate()]
              ) {
                dailyAppointmentsStats[new Date(appt.event.start).getDate()] = {
                  count: 0,
                  noShow: 0,
                  cancelled: 0,
                  totalRevenue: 0,
                  netRevenue: 0,
                  start: this.getUTCTimeStamp(startOfDay(appt.event.start)),
                };
              }
              dailyAppointmentsStats[
                new Date(appt.event.start).getDate()
              ].count += 1;
              // if (appt.price) dailyAppointmentsStats[ new Date(appt.event.start).getDate() ].netRevenue += parseInt(appt.price);
            }
          });
          this.dailyAppointmentsStats = Object.keys(dailyAppointmentsStats).map(
            (key) => {
              return dailyAppointmentsStats[key];
            }
          );
        } else {
          this.dailyAppointmentsStats = [];
        }
        this.resetChartData();
      });

    if (!!this.dailyBookingsSubscription) {
      this.dailyBookingsSubscription.unsubscribe();
    }
    this.dailyBookingsSubscription = this.schedulerService
      .getPatientBookingsByMonthDashboard(this.userId, date)
      .subscribe((snapshot) => {
        if (snapshot !== null && snapshot.length !== 0) {
          const dailyBookingsStats = {};
          snapshot.forEach((appt) => {
            if (!appt.status || appt.status === 'completed') {
              if (!dailyBookingsStats[new Date(appt.bookingDate).getDate()]) {
                dailyBookingsStats[new Date(appt.bookingDate).getDate()] = {
                  count: 0,
                  start: this.getUTCTimeStamp(startOfDay(appt.bookingDate)),
                };
              }
              dailyBookingsStats[
                new Date(appt.bookingDate).getDate()
              ].count += 1;
            }
          });
          this.dailyBookingsStats = Object.keys(dailyBookingsStats).map(
            (key) => {
              return dailyBookingsStats[key];
            }
          );
        } else {
          this.dailyBookingsStats = [];
        }
        this.resetChartData();
      });
  }

  async getAppointmentsAndBookingsProvider(date: Date): Promise<[void, void]> {
    return Promise.all([
      this.schedulerService
        .getCompanyAppointmentsMonth(this.companyId, date)
        .pipe(take(1))
        .toPromise()
        .then((snapshot) => {
          if (snapshot !== null && snapshot.length !== 0) {
            const dailyAppointmentsStats = {};
            let totalVideoCallLength = 0;
            let timedVideoSessions = 0;
            let totalPhoneCallLength = 0;
            let timedPhoneSessions = 0;
            this.totalVideoAppts = 0;
            this.totalPhoneAppts = 0;
            this.totalClinicAppts = 0;

            snapshot.forEach((appt) => {
              if (
                appt.lengthOfCall &&
                (!appt.status || appt.status === 'completed')
              ) {
                if (appt.medium === 'video') {
                  totalVideoCallLength += appt.lengthOfCall;
                }
                timedVideoSessions += 1;
                if (appt.medium === 'phone') {
                  totalPhoneCallLength += appt.lengthOfCall;
                }
                timedPhoneSessions += 1;
              }

              if (appt.medium === 'video') {
                this.totalVideoAppts += 1;
              } else if (appt.medium === 'phone') {
                this.totalPhoneAppts += 1;
              } else {
                this.totalClinicAppts += 1;
              }

              const appointmentDate = new Date(appt.event.start).getDate();
              if (!dailyAppointmentsStats[appointmentDate]) {
                dailyAppointmentsStats[appointmentDate] = {
                  count: 1,
                  noShow: 0,
                  cancelled: 0,
                  totalRevenue: 0,
                  netRevenue: 0,
                  start: this.getUTCTimeStamp(startOfDay(appt.event.start)),
                };
              } else {
                dailyAppointmentsStats[appointmentDate].count++;
              }

              if (appt.price) {
                dailyAppointmentsStats[
                  appointmentDate
                ].totalRevenue += parseInt(appt.price, 10);
              }

              if (appt.status === 'cancelled') {
                dailyAppointmentsStats[appointmentDate].cancelled += 1;
              } else if (appt.status === 'no-show') {
                dailyAppointmentsStats[appointmentDate].noShow += 1;
                dailyAppointmentsStats[appointmentDate].netRevenue += 25;
              } else if (appt.price) {
                dailyAppointmentsStats[appointmentDate].netRevenue += parseInt(
                  appt.price,
                  10
                );
              }
            });

            this.averagePhoneCallLength =
              totalPhoneCallLength / timedPhoneSessions;
            this.averageVideoCallLength =
              totalVideoCallLength / timedVideoSessions;

            this.dailyAppointmentsStats = Object.keys(
              dailyAppointmentsStats
            ).map((key) => {
              return dailyAppointmentsStats[key];
            });
          } else {
            this.dailyAppointmentsStats = [];
          }
          this.resetChartData();
        }),

      this.schedulerService
        .getCompanyBookingsMonth(this.companyId, date)
        .pipe(take(1))
        .toPromise()
        .then((snapshot) => {
          if (snapshot !== null && snapshot.length !== 0) {
            const dailyBookingsStats = {};
            snapshot.forEach((appt) => {
              if (dailyBookingsStats[new Date(appt.bookingDate).getDate()]) {
                dailyBookingsStats[
                  new Date(appt.bookingDate).getDate()
                ].count += 1;
              } else {
                dailyBookingsStats[new Date(appt.bookingDate).getDate()] = {
                  count: 1,
                  start: this.getUTCTimeStamp(startOfDay(appt.bookingDate)),
                };
              }
            });
            this.dailyBookingsStats = Object.keys(dailyBookingsStats).map(
              (key) => {
                return dailyBookingsStats[key];
              }
            );
          } else {
            this.dailyBookingsStats = [];
          }
          this.resetChartData();
        }),
    ]);
  }

  resizeChart(ev: boolean) {
    this.chartsDir.resizeChart(ev);
  }

  decreaseMonth() {
    this.viewDate = new Date(
      this.viewDate.getFullYear(),
      this.viewDate.getMonth() - 1
    );
    this.getDashboardData(this.viewDate);
    this.trafficChart.xAxis[0].data = this.viewMonthDays = this.getMonthDays();
  }
  increaseMonth() {
    this.viewDate = new Date(
      this.viewDate.getFullYear(),
      this.viewDate.getMonth() + 1
    );
    this.getDashboardData(this.viewDate);
    this.trafficChart.xAxis[0].data = this.viewMonthDays = this.getMonthDays();
  }

  getPastTwoWeeks = () => {
    const result = eachDay(
      subDays(new Date(), 12),
      new Date(),
    );
    const data = [];
    for (const d of result) {
      data.push(this.days[d.getDay()] + d.getDate());
    }
    return data;
  };
  twoWeeks = this.getPastTwoWeeks();

  getMonthDays() {
    const result = eachDay(
      new Date(
        this.viewDate.getFullYear(),
        this.viewDate.getMonth(),
        1,
        0,
        0,
        0
      ),
      new Date(
        this.viewDate.getFullYear(),
        this.viewDate.getMonth() + 1,
        0,
        23,
        59,
        59
      ),
    );
    const data = [];
    for (const d of result) {
      data.push(this.days[d.getDay()] + d.getDate());
    }
    return data;
  }
  viewMonthDays = this.getMonthDays();

  trafficChart = {
    legend: {
      show: true,
      x: 'right',
      y: 'top',
      textStyle: {
        color: this.config.textColor,
      },
      data: ['Bookings', 'Appointments'],
    },
    grid: {
      x: 40,
      y: 60,
      x2: 40,
      y2: 30,
      borderWidth: 0,
    },
    tooltip: {
      show: true,
      trigger: 'axis',
      axisPointer: {
        lineStyle: {
          color: this.config.gray,
        },
      },
    },
    xAxis: [
      {
        type: 'category',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: this.config.textColor,
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: this.config.splitLineColor,
          },
        },
        data: this.viewMonthDays,
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: this.config.textColor,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: this.config.splitLineColor,
          },
        },
      },
    ],
    series: [
      {
        name: 'Bookings',
        type: 'bar',
        // stack: 'traffic',
        clickable: false,
        itemStyle: {
          color: this.config.success, // '#8BC34A', // Light Green 500
          borderRadius: 0,
        },
        barGap: '0%',
        barCategoryGap: '0%',
        data: [],
        legendHoverLink: false,
        z: 2,
      },
      {
        name: 'Appointments',
        type: 'bar',
        // stack: 'traffic',
        smooth: true,
        itemStyle: {
          color: this.config.primary, // '#03A9F4', // Light Blue 500
          borderRadius: 0,
        },
        barGap: '0%',
        barCategoryGap: '0%',
        data: [],
        symbol: 'none',
        legendHoverLink: false,
        z: 2,
      },
    ],
  };

  extraCategoriesForProviderOnly = [
    {
      name: 'No-Show',
      type: 'bar',
      // stack: 'traffic',
      smooth: true,
      itemStyle: {
        color: '#FFCA28', // '#4FC3F7', // Light Blue 300
        borderRadius: 0,
      },
      barGap: '0%',
      barCategoryGap: '0%',
      data: [],
      symbol: 'none',
      legendHoverLink: false,
      z: 2,
    },
    {
      name: 'Cancelled',
      type: 'bar',
      smooth: true,
      itemStyle: {
        color: 'red', // '#4FC3F7', // Light Blue 300
        borderRadius: 0,
      },
      barGap: '0%',
      barCategoryGap: '0%',
      data: [],
      symbol: 'none',
      legendHoverLink: false,
      z: 2,
    },
    {
      name: 'Appointment Slots',
      type: 'bar',
      selected: false,
      smooth: true,
      itemStyle: {
        color: '#485b67',
        borderRadius: 0,
      },
      barGap: '0%',
      barCategoryGap: '0%',
      data: [],
      symbol: 'none',
      legendHoverLink: false,
      z: 2,
    },
  ];

  resetChartData() {
    if (this.isPatientAdmin) {
      this.trafficChart.legend.data = ['Bookings', 'Appointments'];
      this.trafficChart.series = [
        this.trafficChart.series[0],
        this.trafficChart.series[1],
      ];
    } else {
      this.trafficChart.series = [
        this.trafficChart.series[0],
        this.trafficChart.series[1],
        this.extraCategoriesForProviderOnly[0],
        this.extraCategoriesForProviderOnly[1],
        this.extraCategoriesForProviderOnly[2],
      ];
      this.trafficChart.legend.data = [
        'Bookings',
        'Appointments',
        'No-Show',
        'Cancelled',
        'Appointment Slots',
      ];
      this.trafficChart.legend['selected'] = {
        Bookings: true,
        Appointments: true,
        'No-Show': true,
        Cancelled: true,
        'Appointment Slots': false,
      };
    }
    // delete this.chartData;
    this.chartData = this.trafficChart;
    const result = eachDay(
      new Date(
        this.viewDate.getFullYear(),
        this.viewDate.getMonth(),
        1,
        0,
        0,
        0
      ),
      new Date(
        this.viewDate.getFullYear(),
        this.viewDate.getMonth() + 1,
        0,
        23,
        59,
        59
      ),
    );
    // const bookingData = [];
    // const appointmentsData = [];
    // const viewsData = [];
    // const totalData = [];
    this.chartData.series[0].data = [];
    this.chartData.series[1].data = [];
    if (!this.isPatientAdmin) {
      if (this.chartData.series[2]) {
        this.chartData.series[2].data = [];
      }
      if (this.chartData.series[3]) {
        this.chartData.series[3].data = [];
      }
      if (this.chartData.series[4]) {
        this.chartData.series[4].data = [];
      }
    }
    let totalBookings = 0;
    let totalAppointments = 0;
    let totalNoShows = 0;
    let totalCancelled = 0;
    let totalRevenue = 0;
    let netRevenue = 0;
    let totalApptSlots = 0;
    for (const d of result) {
      const data = this.getRelevantDataByDate(d);
      // let trend = data[0] + data[1] + data[2];
      // this.chartData.series[2].data.push(trend);
      this.chartData.series[0].data.push(data[0]);
      this.chartData.series[1].data.push(data[1]);
      if (!this.isPatientAdmin) {
        if (this.chartData.series[2]) {
          this.chartData.series[2].data.push(data[2]);
        }
        if (this.chartData.series[3]) {
          this.chartData.series[3].data.push(data[3]);
        }
        if (this.chartData.series[4]) {
          this.chartData.series[4].data.push(data[6]);
        }
      }
      totalBookings += data[0];
      totalAppointments += data[1];
      totalNoShows += data[2];
      totalCancelled += data[3];
      totalRevenue += data[4];
      netRevenue += data[5];
      totalApptSlots += data[6];
    } // end loop

    this.totalBookings = totalBookings;
    this.totalAppointments = totalAppointments;
    this.totalNoShows = totalNoShows;
    this.totalCancelled = totalCancelled;
    this.totalRevenue = totalRevenue;
    this.netRevenue = netRevenue;
    this.totalApptSlots = totalApptSlots;

    if (this.chartsDir) {
      this.chartsDir.resetChart();
    }

    console.log(this.chartData);
  } // edn func

  getRelevantDataByDate(date: Date) {
    const day = this.getUTCTimeStamp(startOfDay(date));

    const bookings = this.dailyBookingsStats.filter((el) => {
      return el.start === day;
    });

    const appointments = this.dailyAppointmentsStats.filter((el) => {
      return el.start === day;
    });

    const timeSlotsForDay = this.timeSlotsPerDayOfTheWeek[
      new Date(date).getDay()
    ];

    return [
      bookings.length === 0 ? 0 : bookings[0].count,
      appointments.length === 0 ? 0 : appointments[0].count,
      appointments.length === 0 ? 0 : appointments[0].noShow,
      appointments.length === 0 ? 0 : appointments[0].cancelled,
      appointments.length === 0 ? 0 : appointments[0].totalRevenue,
      appointments.length === 0 ? 0 : appointments[0].netRevenue,
      timeSlotsForDay,
    ];
  } // end func

  getUTCTimeStamp(date) {
    return date.getTime() - date.getTimezoneOffset() * 60 * 1000;
  }
} // end component
