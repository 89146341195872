import { Component, inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'select-doctor-dialog',
  templateUrl: './select-doctor-dialog.component.html',
})
export class SelectDoctorDialogComponent {
  public readonly dialogRef = inject<MatDialogRef<SelectDoctorDialogComponent>>(MatDialogRef<SelectDoctorDialogComponent>);
  public readonly data = inject<{ doctorList: Array<{ fullName: string }> }>(MAT_DIALOG_DATA);
}
