<div class="text-center">
  <div class="center">
    <button
      mat-mini-fab
      (click)="openIntegrationsDialog(question.offeredAnswers[0])"
      [color]="question.offeredAnswers[0].integrationID ? 'accent' : 'none'"
    >
      <mat-icon class="notranslate">cloud_queue</mat-icon>
    </button>

    <span class="space"></span>

    <button
      [ngStyle]="{ background: question.noFlag ? question.noFlag : 'White' }"
      mat-mini-fab
      color="none"
      [insMenuTriggerFor]="colorMenuNo"
      matTooltip="Flag Answer"
      matTooltipPosition="above"
    >
      <mat-icon class="notranslate">flag</mat-icon>
    </button>

    <ins-menu #colorMenuNo="insMenu">
      <div mat-menu-item (click)="question.noFlag = false">
        <button mat-raised-button>
          <mat-icon class="notranslate">clear</mat-icon>
        </button>
      </div>
      <div
        *ngFor="let color of flagColors"
        mat-menu-item
        (click)="question.noFlag = color.color"
      >
        <button [ngStyle]="{ background: color.color }" mat-raised-button>
          <mat-icon class="notranslate">flag</mat-icon>
        </button>
      </div>
    </ins-menu>

    <span class="space"></span>

    <button
      *ngIf="!!openTriggerDialog"
      [color]="
        question.offeredAnswers[0].pageFlow &&
        question.offeredAnswers[0].pageFlow.trigger &&
        question.offeredAnswers[0].pageFlow.trigger.length > 0
          ? 'accent'
          : 'none'
      "
      mat-mini-fab
      (click)="openTriggerDialog(question.offeredAnswers[0].id)"
      matTooltip="Edit Triggers"
      matTooltipPosition="above"
    >
      <mat-icon class="notranslate">call_split</mat-icon>
    </button>

    <span class="space"></span>
    <span class="space"></span>

    <button mat-raised-button>No</button>
    <button mat-raised-button>Yes</button>

    <span class="space"></span>
    <span class="space"></span>

    <button
      *ngIf="!!openTriggerDialog"
      mat-mini-fab
      [color]="
        question.offeredAnswers[1].pageFlow &&
        question.offeredAnswers[1].pageFlow.trigger &&
        question.offeredAnswers[1].pageFlow.trigger.length > 0
          ? 'accent'
          : 'none'
      "
      (click)="openTriggerDialog(question.offeredAnswers[1].id)"
      matTooltip="Edit Triggers"
      matTooltipPosition="above"
    >
      <mat-icon class="notranslate">call_split</mat-icon>
    </button>

    <span class="space"></span>

    <button
      [ngStyle]="{ background: question.yesFlag ? question.yesFlag : 'White' }"
      mat-mini-fab
      color="none"
      [insMenuTriggerFor]="colorMenuYes"
      matTooltip="Flag Answer"
      matTooltipPosition="above"
    >
      <mat-icon>
        <div class="notranslate">flag</div>
      </mat-icon>
    </button>

    <ins-menu #colorMenuYes="insMenu">
      <div mat-menu-item (click)="question.yesFlag = false">
        <button mat-raised-button>
          <mat-icon class="notranslate">clear</mat-icon>
        </button>
      </div>
      <div
        *ngFor="let color of flagColors"
        mat-menu-item
        (click)="question.yesFlag = color.color"
      >
        <button [ngStyle]="{ background: color.color }" mat-raised-button>
          <mat-icon class="notranslate">flag</mat-icon>
        </button>
      </div>
    </ins-menu>

    <span class="space"></span>

    <button
      mat-mini-fab
      (click)="openIntegrationsDialog(question.offeredAnswers[1])"
      [color]="question.offeredAnswers[1].integrationID ? 'accent' : 'none'"
    >
      <mat-icon class="notranslate">cloud_queue</mat-icon>
    </button>
  </div>
</div>
