<div class="row m-0">
  <div class="col-12 full-width">
    <h5>Email Type:</h5>
    <mat-radio-group
      [(ngModel)]="question.emailSettings.type"
      required
      class="mat-radio-group-spacing"
    >
      <mat-radio-button value="template">Use Template Email</mat-radio-button>
      <mat-radio-button class="ml-4" value="custom"
        >Write Custom Email</mat-radio-button
      >
    </mat-radio-group>
  </div>

  <div class="col-12 full-width" *ngIf="!!question.emailSettings.type">
    <h5>Email Options:</h5>
    <mat-radio-group
      [(ngModel)]="question.emailSettings.emailTo"
      required
      class="mat-radio-group-spacing"
    >
      <mat-radio-button value="custom">Use Custom Email</mat-radio-button>
      <mat-radio-button class="mx-4" value="patient"
        >Use Patient's Email</mat-radio-button
      >
      <mat-radio-button class="ml-4" value="clinic"
        >Use Clinic Email</mat-radio-button
      >
    </mat-radio-group>
  </div>
  
  <div class="col-12 full-width" *ngIf="!!question.emailSettings.type">
    <h5>Other Options:</h5>

    <div class="mat-radio-group-spacing">
      <mat-checkbox [(ngModel)]="question.emailSettings.sendQuestionnaireData">
        Send Questionnaire Data
      </mat-checkbox>

    </div>
  </div>
</div>

<div class="divider-md divider"></div>

<div class="row m-0" *ngIf="question.emailSettings.type === 'template'">
  <div class="col-12" *ngIf="question.emailSettings.emailTo === 'custom'">
    <h5>Email To:</h5>
    <mat-form-field class="full-width">
      <span matPrefix>
        <mat-icon class="notranslate material-icons">email</mat-icon>&nbsp;
      </span>
      <mat-label>Email to Send the Questionnaire Results</mat-label>
      <input
        matInput
        type="email"
        pattern="^.+@.+\..+$"
        autocomplete="off"
        [(ngModel)]="question.emailSettings.to"
        spellcheck="false"
      />
      <mat-error>Please enter a valid email</mat-error>
    </mat-form-field>
  </div>

  <div class="divider"></div>

  <div class="col-12">
    <h5 class="mt-0">Email Subject:</h5>
    <mat-form-field class="full-width">
      <span matPrefix>
        <mat-icon class="notranslate material-icons">subject</mat-icon>&nbsp;
      </span>
      <mat-label>Email Subject</mat-label>
      <input
        matInput
        type="text"
        autocomplete="off"
        [(ngModel)]="question.emailSettings.subject"
        spellcheck="false"
      />
    </mat-form-field>
  </div>

  <div class="divider"></div>

  <div class="col-12">
    <h5 class="mt-0">Template ID:</h5>
    <mat-form-field class="full-width">
      <span matPrefix>
        <mat-icon class="notranslate material-icons">assignment</mat-icon>&nbsp;
      </span>
      <mat-label>Template ID</mat-label>
      <input
        matInput
        type="text"
        autocomplete="off"
        [(ngModel)]="question.emailSettings.templateID"
        spellcheck="false"
      />
    </mat-form-field>
  </div>

  <div class="divider"></div>
  <div class="col-12">
    <h5 class="mt-0">Template Substitutions:</h5>
    <div #scrollContainer class="table-responsive overflow-x-auto">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <td
              *ngFor="let col of question.emailSettings.substitutionGrid.cols"
            >
              <label>{{ col.label }}</label>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of question.emailSettings.substitutionGrid.rows">
            <td
              *ngFor="let col of question.emailSettings.substitutionGrid.cols"
            >
              <div>
                <mat-form-field class="full-width">
                  <textarea
                    rows="1"
                    matInput
                    [(ngModel)]="
                      question.emailSettings.substitutions[row.id][col.id]
                    "
                  ></textarea>
                </mat-form-field>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="text-center">
      <button
        mat-raised-button
        class="primary-color-border"
        (click)="addNewRow()"
      >
        Add Row
      </button>
    </div>
  </div>
</div>

<div class="row m-0" *ngIf="question.emailSettings.type === 'custom'">
  <div class="col-12" *ngIf="question.emailSettings.emailTo === 'custom'">
    <h5>Email To:</h5>
    <mat-form-field class="full-width">
      <span matPrefix>
        <mat-icon class="notranslate material-icons">email</mat-icon>&nbsp;
      </span>
      <mat-label>Email to Send the Questionnaire Results</mat-label>
      <input
        matInput
        type="email"
        pattern="^.+@.+\..+$"
        autocomplete="off"
        [(ngModel)]="question.emailSettings.to"
        spellcheck="false"
      />
      <mat-error>Please enter a valid email</mat-error>
    </mat-form-field>
  </div>

  <div class="divider"></div>

  <div class="col-12">
    <h5 class="mt-0">Email Subject:</h5>
    <mat-form-field class="full-width">
      <span matPrefix>
        <mat-icon class="notranslate material-icons">subject</mat-icon>&nbsp;
      </span>
      <mat-label>Email Subject</mat-label>
      <input
        matInput
        type="text"
        autocomplete="off"
        [(ngModel)]="question.emailSettings.subject"
        spellcheck="false"
      />
    </mat-form-field>
  </div>

  <div class="divider"></div>

  <div class="col-12">
    <h5 class="mt-0">Email Body:</h5>
    <ckeditor
      [(ngModel)]="question.emailSettings.body"
      [config]="ckConfig"
      [readonly]="false"
    >
    </ckeditor>
  </div>
</div>
