import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialComponentsModule } from '../../material-components.module';

import { PatientSignUpComponent } from './sign-up/patient-sign-up.component';
import { PatientTermsDialogComponent } from 'insig-app/global/patient/dialogs/terms/patient-terms-dialog.component';

import { InputMasksModule } from '@insig-health/input-masks/input-masks.module';
import { PatientValidateInfoComponent } from './patient-validate-info/patient-validate-info.component';
import { InsigReferralModule } from 'insig-app/global/insig-referral/insig-referral.module';
import { PatientTermsOfUseModule } from '@insig-health/patient-terms-of-use/patient-terms-of-use.module';
import { FamilyDoctorSearchModule } from '@insig-health/family-doctor-search/family-doctor-search.module';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MaterialComponentsModule,
    InputMasksModule,
    InsigReferralModule,
    PatientTermsOfUseModule,
    FamilyDoctorSearchModule,
  ],
  exports: [
    PatientSignUpComponent,
    PatientValidateInfoComponent,
  ],
  declarations: [
    PatientSignUpComponent,
    PatientTermsDialogComponent,
    PatientValidateInfoComponent,
  ],
})
export class GlobalPatientModule { }
