import { Component } from '@angular/core';

@Component({
  selector: 'insig-app-loader',

  templateUrl: './loader.component.html',
  providers: [],
  styleUrls: ['../../../styles/global/loader/loader.scss'],
})
export class InsigAppLoaderComponent {}
