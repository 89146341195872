<div class="list-inline-item">
  <button
    mat-button
    [insMenuTriggerFor]="flagMenu"
    class="bg-transparent notranslate"
    id="language-button"
  >
    {{ selectedLanguage !== undefined ? selectedLanguage : 'English' }}

    <i class="material-icons notranslate" aria-hidden="true">
      arrow_drop_down
    </i>
  </button>
  <ins-menu #flagMenu="insMenu">
    <button
      mat-menu-item
      *ngFor="let language of languageList"
      (click)="switchLanguage(language)"
    >
      <span>&nbsp;{{ language }}</span>
    </button>
  </ins-menu>
</div>
