<div class="h-100 d-flex flex-column">
  <h1 mat-dialog-title>{{ data.note.time | date: 'fullDate' }} - {{ data.note.name }}</h1>
  <mat-dialog-content class="h-100">
    <insig-pdf-embed
      [url]="pdfUrl"
      class="w-100 h-100"
    >
    </insig-pdf-embed>
  </mat-dialog-content>
  
  <mat-dialog-actions class="mt-2" align="end">
    <button mat-raised-button mat-dialog-close color="primary">
      Close
    </button>
  </mat-dialog-actions>
</div>
