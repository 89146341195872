<mat-card>
  <mat-card-title>Are you sure to delete this company plan?</mat-card-title>
  <mat-card-content
    >All poctors and patients will be removed from this plan as
    well</mat-card-content
  >
  <div class="divider"></div>
  <div class="divider"></div>
  <mat-card-actions>
    <button mat-raised-button (click)="dialogRef.close(false)">No</button>
    <button
      mat-raised-button
      color="primary"
      class="float-right"
      (click)="dialogRef.close(true)"
    >
      Yes
    </button>
  </mat-card-actions>
</mat-card>
