// @ts-strict-ignore
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IDatePickerConfig } from 'ng2-date-picker';

import { Question } from 'insig-types/surveys';
import dayjs, { Dayjs } from 'dayjs';

@Component({
  selector: 'date-question',
  templateUrl: './date.component.html',
})
export class DateQuestionComponent implements OnInit, OnChanges {
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();
  
  public today: any;
  private thisDayjs: Dayjs;
  private config: IDatePickerConfig = {};
  public dayjsDate: Dayjs | null = null;

  ngOnInit() {
    if (this.question.inlineDate) {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth();
      const date = today.getDate();
      this.today = new Date(year, month, date);
      this.thisDayjs = dayjs(new Date());
      this.config.max = this.question.noFuture ? this.thisDayjs : null;
      this.config.min = this.question.noPast ? this.thisDayjs : null;
    } else {
      this.today = new Date();
    }
    // need to select answer for inline date
    if (
      this.question.inlineDate &&
      this.question.response &&
      this.question.response.answer &&
      this.question.response.answer !== 'Invalid Date'
    ) {
      this.dayjsDate = new Dayjs(this.question.response.answer);
    }
  } // end init

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  updateResponseDate(dayjs: Dayjs): void {
    if (dayjs !== undefined && dayjs.toDate().getTime() !== new Date(this.question.response.answer).getTime()) {
      this.dayjsDate = dayjs;
      this.question.response.answer = dayjs.toDate().getTime();
    }
  }
} // end component
