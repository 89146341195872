// @ts-strict-ignore
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter, inject } from '@angular/core';
import { PublicBillingService } from 'insig-app/services/virtual/publicBilling.service';

import { APPCONFIG } from '@insig-health/config/config';

@Component({
  selector: 'company-plan-settings',
  templateUrl: './company-plan-settings.component.html',
  providers: [PublicBillingService],
  styleUrls: ['./company-plan-settings.component.scss'],
})
export class CompanyPlanSettingsComponent implements OnInit, OnDestroy {
  private readonly publicBillingService = inject(PublicBillingService);
  @Input() subscribedCompanies: any = [];
  @Input() subscribedUserGroups: any = [];
  @Output() companyPlanSettingsToggle = new EventEmitter();
  @Output() addSubscribedCompany = new EventEmitter();
  @Output() addSubscribedUserGroup = new EventEmitter();
  @Output() removeSubscribedCompany = new EventEmitter();
  @Input() userData: any;
  public publicPlans = [];
  public selectPlanImage: any;

  public elOpened = false;
  public AppConfig = APPCONFIG;
  public allPublicBillingsSub: any;

  public disabledOptions = [
    {
      iden: 'price',
      label: 'Price',
    },
    {
      iden: 'media',
      label: 'Appointment Medium',
    },
    {
      iden: 'serviceCode',
      label: 'Service Code',
    },
    {
      iden: 'diagnosticCode',
      label: 'Diagnostic Code',
    },
    {
      iden: 'questionnaire',
      label: 'Questionnaire',
    },
  ];

  private _selectedPlan: any;
  @Output() selectedPlanChange = new EventEmitter();
  @Input() get selectedPlan() {
    return this._selectedPlan;
  }
  set selectedPlan(val) {
    this._selectedPlan = val;
    this.selectedPlanChange.emit(this._selectedPlan);
  }

  ngOnInit() {
    this.allPublicBillingsSub = this.publicBillingService
      .getAllPublicBillings()
      .subscribe((snapshot) => {
        console.log(snapshot);
        this.publicPlans = snapshot;
      });
  }

  ngOnDestroy() {
    if (this.allPublicBillingsSub) {
      this.allPublicBillingsSub.unsubscribe();
    }
  }

  async planChange(plan) {
    this.selectedPlan.subscribedPublicPlan = plan;
  }

  async removePublicPlan() {
    delete this.selectedPlan.subscribedPublicPlan;
  }
}
