<div class="overflow-hidden">
  <h1 mat-dialog-title>Manage Multifactor Authentication</h1>

  <mat-dialog-content>
    <ng-container *ngIf="mfaEnrollmentState === MfaEnrollmentState.LOADING">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>

    <ng-container *ngIf="mfaEnrollmentState === MfaEnrollmentState.GENERAL_MFA_INFO">
      <div class="pb-2">You can add multifactor authentication to your account through several authenticator apps on your phone. This will add an additional layer of security to your account, by prompting you for a 6-digit code each time you sign in.</div>
    </ng-container>

    <ng-container *ngIf="mfaEnrollmentState === MfaEnrollmentState.VERIFY_EMAIL">
      <div class="pb-2">To proceed, you will have to confirm your email address.  We've just sent you an email, please click the link in that email to verify your address.  If you can't find the email, check your Junk folder as well.</div>
    </ng-container>

    <ng-container *ngIf="mfaEnrollmentState === MfaEnrollmentState.LOGIN">
      <div class="d-flex flex-column align-items-start gap-2">
        <div>
          Please reauthenticate to continue.
        </div>
        <insig-health-gcp-ip-login-widget
          class="mx-auto"
          [disableSignUp]="true"
          [readOnlyEmail]="email"
          [isLogoVisible]="false"
          [disableAutomaticLogin]="true"
          [disableCustomToken]="true"
          [disableCardBorder]="true"
          (onLoggedIn)="handleLoginSuccessOrVerifyEmailNextClicked($event)"
        >
        </insig-health-gcp-ip-login-widget>
      </div>
    </ng-container>

    <ng-container *ngIf="mfaEnrollmentState === MfaEnrollmentState.SELECT_DEVICE_TYPE">
      <div class="d-flex flex-column align-items-start gap-2 overflow-hidden">
        <ng-container *ngTemplateOutlet="errorMessageTemplate"></ng-container>
        <div>
          <label id="select-device-type-radio-group-label">Choose your mobile device type</label>
          <mat-radio-group
            aria-labelledby="select-device-type-radio-group-label"
            class="d-flex flex-column"
            [(ngModel)]="selectedDeviceType"
          >
            <mat-radio-button class="mx-3" value="{{ DeviceTypeOptions.ANDROID }}">Android</mat-radio-button>
            <mat-radio-button class="mx-3" value="{{ DeviceTypeOptions.IPHONE }}">iPhone</mat-radio-button>
          </mat-radio-group>
        </div>

        <div *ngIf="selectedDeviceType">
          <div>Here are some authenticator apps we recommend.  If you have one of these already downloaded on your mobile device, you can continue to the next step</div>

          <div class="d-grid grid-cols-2 mb-2">
            <ng-container *ngFor="let authAppLink of selectedDeviceType | mfaAuthAppLinks">
              <insig-ui-button
                class="m-1"
                target="_blank"
                [externalLink]="authAppLink.url"
                [iconSrc]="authAppLink.logo"
                [iconHeight]="authAppLink.name === 'Duo Mobile' ? '12' : undefined"
                [stylePreset]="'fullWhite'"
              >
                {{ authAppLink.name }}
              </insig-ui-button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="mfaEnrollmentState === MfaEnrollmentState.VERIFY_MFA_CODE && totpSecret">
      <div class="d-flex flex-column align-items-start gap-2">
        <div class="d-flex">
          <div>
            Launch the authentication app you just downloaded on your phone, and choose to add an account by scanning a QR code. <br/><br/> Scan the QR code to the right. This will generate a 6-digit code, please enter that below and then proceed.
          </div>
          <canvas class="border rounded mx-auto max-height-150px" #qrCodeCanvas></canvas>
        </div>
        <ng-container *ngTemplateOutlet="mfaFormTemplate"></ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="mfaEnrollmentState === MfaEnrollmentState.EXPIRED_MFA_CODE && totpSecret">
      <div class="d-flex flex-column align-items-start gap-2">
        <div>
          The verification code you entered has expired or was invalid. Please enter a new verification code.
        </div>
        <ng-container *ngTemplateOutlet="mfaFormTemplate"></ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="mfaEnrollmentState === MfaEnrollmentState.SUCCESS">
      <div class="pb-2">
        Multifactor authentication has been successfully added to your account!
      </div>
    </ng-container>

    <ng-container *ngIf="mfaEnrollmentState === MfaEnrollmentState.REMOVE_MFA">
      <div class="pb-2">
        You are currently enrolled in multifactor authentication. Would you like to unenroll?
      </div>
    </ng-container>

    <ng-container *ngIf="mfaEnrollmentState === MfaEnrollmentState.REMOVE_MFA_SUCCESS">
      <div class="pb-2">
        Multifactor authentication has been successfully disabled.
      </div>
    </ng-container>
  </mat-dialog-content>
  
  <mat-dialog-actions class="d-flex justify-content-between">
    <button mat-button mat-dialog-close>Cancel</button>
    <button *ngIf="mfaEnrollmentState === MfaEnrollmentState.GENERAL_MFA_INFO"
      class="primary-color-full"
      mat-raised-button
      (click)="handleGeneralMfaInfoNextClicked()"
    >
      Next
    </button>
    <div *ngIf="mfaEnrollmentState === MfaEnrollmentState.VERIFY_EMAIL" class="d-flex">
      <button
        class="primary-color-full"
        mat-raised-button
        (click)="handleResendVerificationEmailClicked()"
      >
        Resend Verification Email
      </button>
      <button
        class="primary-color-full"
        mat-raised-button
        (click)="handleLoginSuccessOrVerifyEmailNextClicked()"
      >
        Next
      </button>
    </div>
    <button *ngIf="mfaEnrollmentState === MfaEnrollmentState.LOGIN"
      class="primary-color-full"
      mat-raised-button
      [disabled]="!passwordForm.valid"
      (click)="handlePasswordFormSubmitted(passwordForm)"
    >
      Next
    </button>
    <button *ngIf="mfaEnrollmentState === MfaEnrollmentState.SELECT_DEVICE_TYPE"
      class="primary-color-full"
      mat-raised-button
      [disabled]="!selectedDeviceType"
      (click)="handleDeviceTypeSelected()"
    >
      Next
    </button>
    <button *ngIf="(mfaEnrollmentState === MfaEnrollmentState.VERIFY_MFA_CODE || mfaEnrollmentState === MfaEnrollmentState.EXPIRED_MFA_CODE) && totpSecret"
      class="primary-color-full"
      mat-raised-button
      [disabled]="!verificationCodeForm.valid"
      (click)="handleVerificationCodeFormSubmitted(verificationCodeForm, totpSecret)"
    >
      Complete
    </button>
    <button *ngIf="mfaEnrollmentState === MfaEnrollmentState.SUCCESS || mfaEnrollmentState === MfaEnrollmentState.REMOVE_MFA_SUCCESS"
      class="primary-color-full"
      mat-raised-button
      mat-button mat-dialog-close
    >
      Done
    </button>
    <button *ngIf="mfaEnrollmentState === MfaEnrollmentState.REMOVE_MFA"
      class="primary-color-full"
      mat-raised-button
      (click)="handleUnenrollClicked()"
    >
      Unenroll
    </button>
  </mat-dialog-actions>
</div>

<ng-template #mfaFormTemplate>
  <div class="w-100 d-flex justify-content-center">
    <form class="d-flex gap-2"
      [formGroup]="verificationCodeForm"
      (submit)="handleVerificationCodeFormSubmitted(verificationCodeForm, totpSecret)"
    >
      <insig-ui-one-time-password-input
          [formControl]="verificationCodeForm.controls.verificationCode"
        ></insig-ui-one-time-password-input>
      <input type="submit" hidden />
    </form>
  </div>
</ng-template>

<ng-template #errorMessageTemplate>
  <div *ngIf="errorMessage !== undefined" class="d-flex align-items-center">
    <div class="bg-danger p-icon rounded-circle">
      <div class="icon text-danger text-center bg-white rounded-circle">!</div>
    </div>
    <div class="flex-fill bold p-2">{{ errorMessage }}</div>
  </div>
</ng-template>
