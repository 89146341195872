import { Pipe, PipeTransform } from '@angular/core';
/*
 * Picks out requested elements by key
 */
@Pipe({ name: 'keyPipe' })
export class KeyPipe implements PipeTransform {
  transform(fullDict: any, keyList: Array<string | number>) {
    return keyList ? keyList.map((key) => fullDict[key]) : [];
  }
}
