import { Component } from '@angular/core';

@Component({
  selector: 'auth',
  template: `
    <router-outlet></router-outlet>
  `,
  host: {
    class: 'd-block h-100 overflow-y-auto',
  },
})
export class AuthComponent {}
