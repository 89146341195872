import { Page } from '../../surveys/page';

export type FullSpringNote =
  SpringNote &
  { [Key in keyof SpringNoteLargeStringKey]: Key extends 'pages' ? Page[] : string } &
  { admittedPatient: AdmittedPatient };

export type SpringNote = NoteListResponse & {
  addedDocs: Array<{
    url: string;
    name: string;
    type: string;
    custom: boolean;
    diagnosis: string;
    html?: string;
    htmlId?: string;
    drugs?: Array<{
      name: string;
      dose: string;
      qty: number;
      reps: number;
    }>;
  }>;
  morePDFs: Array<{
    url: string;
    name: string;
    type: string;
  }>;
  pages?: Page[];
  pdfPath?: string;
  surveyNames?: Array<{
    id: string;
    type: 'user' | 'library';
    survUID: string;
    triage: boolean;
    name: string;
  }>;
  triggersDictionary?: {
    [parentId: string]: {
      shown: boolean;
      [childId: string]: boolean;
    }
  };
  coordinates?: {
    lat: number;
    lng: number;
  };
  note?: string;
  patientInfo?: PatientInfo;
  _links: SpringNoteLargeStrings & {
    self: {
      href: string;
      templated: boolean;
    };
    company: {
      href: string;
      templated: boolean;
    };
  }
}

type SpringNoteLargeStrings = {
  [LargeStringKey in SpringNoteLargeStringKey]: {
    href: string;
  };
}

export enum SpringNoteLargeStringKey {
  NOTE = 'note',
  SUMMARY = 'summary',
  RTF = 'rtf',
  PAGES = 'pages',
}

export interface NoteListResponse {
  /* to be deprecated by noteId */
  nid?: string;
  noteId: string;
  companyId: string;
  surveyId: string;
  apptID?: string;
  type: 'user' | 'library';
  /** Date in ISO string format */
  time: string;
  name: string;
  patientUid: string;
  patientSid: string;
  startTime: string;
  locked: boolean;
  familyId?: string;
}

export type NotePutBody = Omit<SpringNote, '_links'> & {
  pages?: Page[];
} & {
  [LargeStringKeys in Exclude<SpringNoteLargeStringKey, 'pages'>]?: string;
};

export type NotePostBody = Omit<NotePutBody, 'noteId' | 'locked' | 'time'> & {
  locked?: boolean;
};

export type NotePostBodyWithPatientInfo = NotePostBody & {
  patientInfo: PatientInfo;
};

// TODO: update once the spring admitted patients API is available
export interface AdmittedPatient {
  first: string;
  firstName: string;
  last: string;
  lastName: string;
  gender: string;
  birthday?: string;
  email?: string;
  phone?: string;
  day?: string | number;
  month?: string | number;
  year?: string | number;
}

// TODO: this is a legacy data type from RTDB. It is expected that a
// format with better field names and data types will be used in future
// iterations of the database
export interface PatientInfo {
  /** Spring API name for the patientSid */
  pid: string;
  first: string;
  firstName?: string;
  last: string;
  lastName?: string;
  day: string;
  month: string;
  year: string;
  gender: string;
  phone?: string;
  address?: string;
  healthCardNumber?: string;
  email?: string;
  terms?: boolean;
  uid?: string;
  pharmaFax?: string;
  pharmaName?: string;
}

export interface NoteAndPatientResponse {
  noteResponse: SpringNote;
  patientResponse: PatientInfo;
}
