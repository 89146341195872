// @ts-strict-ignore
﻿import { Component, Input, HostBinding } from '@angular/core';
import { CalendarSchedulerEvent } from './calendar-scheduler-view.component';

@Component({
  selector: 'calendar-scheduler-event-title',
  template: `
    <div
      class="cal-scheduler-event-title"
      [innerHTML]="event.title | calendarEventTitle: view:event"
    ></div>
    <div
      *ngIf="event.status"
      class="cal-scheduler-event-status"
      [class.ok]="event.status === 'ok'"
      [class.warning]="event.status === 'warning'"
      [class.danger]="event.status === 'danger'"
    ></div>
  `,
})
export class CalendarSchedulerEventTitleComponent {
  @HostBinding('class') class = 'cal-scheduler-event-title-container';

  @Input() event: CalendarSchedulerEvent;

  @Input() view: string;
}
