import { Component } from '@angular/core';

@Component({
  selector: 'insig-health-patient-terms-of-use',
  templateUrl: './patient-terms-of-use.component.html',
  styleUrls: ['./patient-terms-of-use.component.scss'],
})
export class PatientTermsOfUseComponent {
  public static readonly VERSION = '2024-03-04';
}
