// @ts-strict-ignore
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'surgery-question',
  templateUrl: './surgery.component.html',
})
export class SurgeryComponent implements OnChanges, OnInit {
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();

  private commonSurgeries = ['Tonsils', 'Wisdom Teeth'];

  ngOnInit() {
    if (!this.question.response.selectedCheckboxes) {
      this.question.response.selectedCheckboxes = [];
      this.commonSurgeries.forEach((surgery) => {
        this.question.response.selectedCheckboxes.push({
          surgery,
          selected: false,
        });
      });
    }
    if (!this.question.response.selectedAnswers) {
      this.question.response.selectedAnswers = [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  removeItem(val) {
    const index = this.question.response.selectedAnswers.indexOf(val);
    this.question.response.selectedAnswers.splice(index, 1);
  }

  addItem(val) {
    if (this.question.response.selectedAnswers.indexOf(val) === -1) {
      this.question.response.selectedAnswers.push(val);
    }
    this.question.response.selectedAnswers[-1] = null;
    console.log(this.question.response.selectedAnswers);
  } // end add item
}
