<div class="h-100 overflow-x-auto">
  <div class="call-to-action cta-full-width">
    <div class="page-title text-center mt-4 mb-3">
      <div [hidden]="subscription">
        <h3 class="my-4">You need a subscription to continue</h3>
        <h4>Please select an option below</h4>
        <br />
      </div>
      <div [hidden]="!subscription">
        <h3 class="mb-4">Enter your credit card details below</h3>
        <h4>Your payment information is securely processed using Stripe</h4>
        <br />
        <!-- <h4>You will only be charged after the trial period is over</h4> -->
      </div>
    </div>
  </div>

  <!-- <div class="text-center bg-primary white py-1">
    <div class="divider"></div>
    <h3 class="mb-3">60-DAY FREE TRIAL!</h3>
    <h5>Try Insig FREE for the first 60 days, cancel anytime.</h5>
    <div class="divider "></div>
  </div> -->

  <div class="container mt-4">
    <div class="row" [hidden]="subscription">
      <div class="col-6 offset-3">
        <mat-card>
          <mat-card-title
            ><span class="paymentTitle"
              >Insig Subscription</span
            ></mat-card-title
          >
          <mat-divider></mat-divider>

          <p class="mt-4 pt-2">Features</p>

          <div
            class="my-4 d-flex flex-row justify-content-start align-items-center"
          >
            <mat-icon mat-list-icon class="mr-2 notranslate"
              >description</mat-icon
            >
            <span
              >Automate your medical notes, documentation, intake & more!</span
            >
          </div>

          <div
            class="my-4 d-flex flex-row justify-content-start align-items-center"
          >
            <mat-icon mat-list-avatar class="mr-2 notranslate"
              >account_box</mat-icon
            >
            <span mat-line
              >Send questionnaires to patients at home or in your waiting
              room</span
            >
          </div>

          <div
            class="my-4 d-flex flex-row justify-content-start align-items-center"
          >
            <mat-icon mat-list-avatar class="mr-2 notranslate"
              >settings_voice</mat-icon
            >
            <span matLine>Finish notes with voice dictation</span>
          </div>

          <div
            class="my-4 d-flex flex-row justify-content-start align-items-center"
          >
            <mat-icon mat-list-avatar class="mr-2 notranslate"
              >library_books</mat-icon
            >
            <span matLine
              >Access our growing Medical Questionnaire Library</span
            >
          </div>

          <div
            class="my-4 d-flex flex-row justify-content-start align-items-center"
          >
            <mat-icon mat-list-avatar class="mr-2 notranslate">create</mat-icon>
            <span matLine>Build custom questionnaires</span>
          </div>

          <div
            class="my-4 d-flex flex-row justify-content-start align-items-center"
          >
            <mat-icon mat-list-avatar class="mr-2 notranslate"
              >cast_connected</mat-icon
            >
            <span matLine
              >Integrates with your EMR/EHR medical record software</span
            >
          </div>

          <div class="divider"></div>
          <div class="row">
            <div class="col-6">
              <button
                mat-raised-button
                color="primary"
                class="full-width p-3"
                (click)="selectSubscription('surveysMonthly')"
              >
                <h4 matLine>$120/month</h4>
                <p matLine>Paid Monthly</p>
              </button>
            </div>
            <div class="col-6">
              <button
                mat-raised-button
                color="accent"
                class="white float-right full-width p-3"
                (click)="selectSubscription('surveysYearly')"
              >
                <h4 matLine>$100/month</h4>
                <p matLine>Paid Yearly</p>
              </button>
            </div>
          </div>
        </mat-card>
      </div>
      <div *ngIf="devMode" class="col-1 offset-2">
        <button
          mat-button
          style="position:absolute;bottom:0;"
          class="full-width"
          (click)="subscription = subscriptions['insigDev']"
        >
          &nbsp;
        </button>
      </div>
    </div>
  </div>

  <div class="container mt-4">
    <div class="row" [hidden]="!subscription || loading">
      <div class="col-2 offset-1">
        <button
          mat-raised-button
          class="py-1 px-3 bg-white"
          (click)="subscription = null"
        >
          <mat-icon>arrow_back</mat-icon>&nbsp;Change Plan
        </button>
      </div>

      <div class="col-6" id="payment-plan-page">
        <form class="mt-0">
          <div class="group">
            <label>
              <span>Name</span>
              <input
                #paymentName
                name="cardholder-name"
                value="{{ userData ? userData.first : '' }} {{
                  userData ? userData.last : ''
                }}"
                class="field"
                placeholder="Jane Doe"
              />
            </label>
            <label>
              <span>Phone</span>
              <input
                class="field"
                placeholder="(123) 456-7890"
                value="{{ userData ? userData.displayPhone : '' }}"
                type="tel"
              />
            </label>
          </div>
          <div class="group">
            <label>
              <span>Card</span>
              <div id="card-element" class="field"></div>
            </label>
          </div>
          <button type="submit" (click)="submitPayment($event)">
            Subscribe {{ subscription?.name ? 'to ' + subscription?.name : '' }}
          </button>
          <div class="outcome">
            <div class="error" role="alert"></div>
            <div class="success">
              Success! Your Stripe token is <span class="token"></span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <br />
  <br />

  <div class="w-100 text-center" *ngIf="loading">
    <h3>Please wait...</h3>
    <mat-progress-spinner
      class="mx-auto"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>
</div>
