/**
 * Throw an error with a standardized message format across browsers, given any array.
 * If any values of the array are an Error, then throw the first of such values. Otherwise
 * to the best ability possible, general a cross browser string so that the error can be
 * aggregated by instrumentation tools.
 * @param values - An array of anything to be reported.
 */
export function throwAsyncError(...values: any[]): void {
  if (values.length === 0) {
    throw new Error();
  } else if (values.some((value) => value instanceof Error)) {
    const firstError = values.find((value) => value instanceof Error);
    setTimeout(() => { throw firstError; });
  } else {
    const parsedError = values.reduce<string>((errorString, value) => {
      if (typeof value === 'string') {
        return errorString + ' ' + value;
      } else if (value.toString && typeof value.toString === 'function') {
        return errorString + ' ' + `${value.toString()}`;
      } else {
        return errorString + ' ' + `${value}`;
      }
    }, '');
    setTimeout(() => { throw new Error(parsedError); });
  }
}
