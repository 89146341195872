/**
 * Return all dates in the same month of the provided reference date.
 * @param date - The date to derive the month from.
 * @returns An array of Date objects that share the same month as the reference date.
 */
export function getAllDatesOfMonth(date: Date): Date[] {
  date = new Date(date);
  date.setDate(1);
  date.setHours(0, 0, 0, 0);

  const month = date.getMonth();
  const dates: Date[] = [];
  let currDate = new Date(date);
  while (currDate.getMonth() === month) {
    dates.push(currDate);
    currDate = new Date(currDate);
    currDate.setDate(currDate.getDate() + 1);
  }
  return dates;
}
