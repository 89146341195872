import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[insOnScrollToBottom]',
})
export class OnScrollToBottomDirective {
  static readonly ACTIVE_AREA_PX = 800;
  static readonly DEBOUNCE_TIME_MS = 500;

  private isDebouncing = false;
  private previousScrollHeight = 0;

  @Output() scrolledToBottom = new EventEmitter<void>();

  @HostListener('scroll', ['$event']) onScroll(event: Event) {
    const div = event.target as HTMLDivElement;

    const isScrolledToBottom = div.scrollTop >= div.scrollHeight - div.offsetHeight - OnScrollToBottomDirective.ACTIVE_AREA_PX;

    if (div.scrollHeight > this.previousScrollHeight) {
      div.scrollTop -= div.scrollHeight - this.previousScrollHeight;
    }

    this.previousScrollHeight = div.scrollHeight;

    if (isScrolledToBottom && !this.isDebouncing) {
      this.scrolledToBottom.emit();
      this.isDebouncing = true;
      setTimeout(() => this.isDebouncing = false, OnScrollToBottomDirective.DEBOUNCE_TIME_MS);
    }
  }
}
