import { Component, Input, inject } from '@angular/core';
import { UserAgent, UserAgentService } from 'insig-app/services/user-agent/user-agent.service';

@Component({
  selector: 'insig-pdf-embed',
  templateUrl: './pdf-embed.component.html',
  styleUrls: ['./pdf-embed.component.scss'],
})
export class PdfEmbedComponent {
  private readonly userAgentService = inject(UserAgentService);
  @Input() url: string | undefined;

  public isSafari = this.userAgentService.getUserAgent() === UserAgent.SAFARI;
}
