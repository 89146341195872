// @ts-strict-ignore
import { Injectable } from '@angular/core';

import { Survey } from 'insig-types/surveys/survey';

import firebase from 'firebase/compat/app';
import 'firebase/database';
import 'firebase/firestore';

@Injectable()
export class SaveSurveyService {
  /**
   * Saves the user survey to Firestore. See the admin bot for the library equivalent.
   * @param survey The survey to save
   * @param userId The id of the user to save the survey under
   */
  async saveUserSurveyToFirestore(
    survey: Survey,
    userId: string
  ): Promise<void> {
    const baseReference = firebase
      .firestore()
      .collection('userSurveys')
      .doc('surveyData')
      .collection(userId)
      .doc(survey.id);
    const clone = Object.assign({}, survey);
    delete clone.pages; // Save everything except pages
    await baseReference.set(clone);

    // Delete the old pages
    await Promise.all(
      // Query for pages
      await baseReference
        .collection('pages')
        .get()
        .then((querySnapshot) => {
          return querySnapshot.docs
            .map((doc) => doc.id)
            .map((pageNumber) => {
              const pageReference = baseReference
                .collection('pages')
                .doc(pageNumber);

              // Query for elements within a page
              return pageReference
                .collection('elements')
                .get()
                .then((elementQuerySnapshot) => {
                  return Promise.all(
                    elementQuerySnapshot.docs
                      .map((doc) => doc.id)
                      .map((elementNumber) => {
                        // Delete each element
                        pageReference
                          .collection('elements')
                          .doc(elementNumber)
                          .delete();
                      })
                    // Delete the page
                  ).then(() => pageReference.delete());
                });
            });
        })
    );

    // Set the new pages
    for (const pageNumber in survey.pages || []) {
      if (survey.pages[pageNumber]) {
        await baseReference
          .collection('pages')
          .doc(pageNumber)
          .set({ id: survey.pages[pageNumber].id }); // Save only page id
        for (const elementNumber in survey.pages[pageNumber].elements || []) {
          if (survey.pages[pageNumber].elements[elementNumber]) {
            await baseReference
              .collection('pages')
              .doc(pageNumber)
              .collection('elements')
              .doc(elementNumber)
              .set(survey.pages[pageNumber].elements[elementNumber]);
          }
        }
      }
    }
  }

  updateSurveyFolder(
    userId: string,
    surveyId: string,
    folderId: string
  ): Promise<any> {
    return Promise.all([
      firebase
        .firestore()
        .collection('userSurveys')
        .doc('surveyData')
        .collection(userId)
        .doc(surveyId)
        .set({ folder: folderId }, { merge: true }),
      firebase
        .database()
        .ref('user/surveys/' + userId + '/' + surveyId + '/folder')
        .set(folderId),
    ]);
  }

  cleanUndefinedResponses(survey) {
    for (const p in survey.pages) {
      if (!survey.pages[p].elements) {
        continue;
      }
      for (const q in survey.pages[p].elements) {
        if (
          survey.pages[p].elements[q] &&
          survey.pages[p].elements[q].question &&
          survey.pages[p].elements[q].question.response
        ) {
          try {
            // selected answers questions, removing undefined
            if (survey.pages[p].elements[q].question.response.selectedAnswers) {
              for (const i in survey.pages[p].elements[q].question.response
                .selectedAnswers) {
                if (
                  survey.pages[p].elements[q].question.response.selectedAnswers[
                    i
                  ] === undefined
                ) {
                  survey.pages[p].elements[q].question.response.selectedAnswers[
                    i
                  ] = null;
                }
              }
              // other responses of undefined
            } else if (
              survey.pages[p].elements[q].question.response.answer === undefined
            ) {
              survey.pages[p].elements[q].question.response.answer = null;
            }
          } catch (err) {
            console.log(err);
            console.log(survey.pages[p].elements[q].question);
          }
        } // end if-else
      } // end for loop
    } // end for loop
    return survey;
  }
} // end service
