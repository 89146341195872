<mat-card class="p-3">
  <h1 matDialogTitle>
    Confirm Time Segment Change
  </h1>
  <div mat-dialog-content>
    <span>
      Are you sure you make yourself available from
      {{ getOperatingTimeString(operatingStartTime) }} to
      {{ getOperatingTimeString(operatingEndTime) }}
      ({{ getOperatingTimeDurationString(operatingEndTime - operatingStartTime) }})
    </span>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-between">
    <button mat-raised-button [mat-dialog-close]="false">No</button>
    <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
  </div>
</mat-card>
