<section id="topOfEditSurvey" class="edit-survey full-height">
  <!-- begin loading spinner -->
  <div
    id="loading-spinner"
    *ngIf="!surveyLoaded"
    class="h-100 py-4 overflow-x-auto"
  >
    <div class="divider"></div>
    <div class="divider"></div>
    <div class="text-center w-100">
      <h2 class="article-title">Loading Questionnaire ...</h2>
    </div>
    <insig-app-loader></insig-app-loader>
  </div>
  <!-- end loading spinner -->

  <div *ngIf="surveyLoaded" class="h-100 py-4 overflow-x-auto">
    <div class="px-4 d-flex flex-row align-items-center justify-content-end">
      <button mat-raised-button (click)="launchSurvey()">
        Patient Preview
      </button>
      <span class="space"></span>
      <button color="primary" mat-raised-button (click)="saveSurvey()">
        Save
      </button>
    </div>

    <article class="article col-md-10 offset-md-1 col-xs-12">
      <h2 class="article-title">{{ survey.name }}</h2>

      <mat-card class="p-4" style="overflow: hidden">
        <mat-tab-group class="main-tab-group">
          <!-- begin survey builder tab -->
          <mat-tab label="Builder">
            <div class="divider"></div>

            <!-- begin survey header -->
            <label class="placeholder">Questionnaire Name</label>
            <mat-form-field class="full-width">
              <input
                class="text-center survey-title"
                matInput
                required
                type="text"
                [ngModel]="survey.name"
                (ngModelChange)="survey.name = $event; surveyChanged = true"
                touranchor="route.tour"
              />
              <mat-error>Required</mat-error>
            </mat-form-field>

            <label class="placeholder">Questionnaire Tags</label>
            <mat-chip-listbox class="text-center">
              <mat-chip-option
                *ngFor="let tag of survey.searchTags; let i = index"
                class="tagStyle"
              >
                {{ tag }}
                <mat-icon class="notranslate" (click)="removeTag(i)"
                  >delete</mat-icon
                >
              </mat-chip-option>
            </mat-chip-listbox>
            <mat-form-field class="full-width">
              <mat-label>Enter a Tag (press enter to add)</mat-label>
              <input
                matInput
                type="text"
                (keyup.enter)="
                  addTag($event.target.value); addTagField.value = null
                "
                #addTagField
              />
            </mat-form-field>
            <!-- end survey header -->

            <div class="divider"></div>

            <!-- begin pages -->
            <div
              class="d-flex flex-row justify-content-between align-items-center flex-wrap"
            >
              <span id="page-number"
                >Page {{ currentPageIndex + 1 }} of
                {{ survey.pages.length }}</span
              >
              <mat-form-field *ngIf="survey.pages && survey.pages.length > 1">
                <mat-label>Jump To Page</mat-label>
                <mat-select
                  id="jump-to-page-mat-select"
                >
                  <mat-option
                    *ngFor="let page of survey.pages; let pageIndex = index"
                    [value]="pageIndex"
                    (click)="currentPageIndex = pageIndex"
                  >
                    {{ pageIndex + 1 }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-checkbox [(ngModel)]="survey.print"
                >Allow Patient Printing</mat-checkbox
              >
              <mat-checkbox [(ngModel)]="survey.feedbackEnabled"
                >Feedback</mat-checkbox
              >
              <mat-checkbox [(ngModel)]="survey.saveQuestionnaireData"
                >Save Questionnaire Data</mat-checkbox
              >
            </div>

            <div class="divider"></div>

            <ng-container
              *ngFor="let page of survey.pages; let pageIndex = index"
            >
              <mat-card
                id="page"
                [hidden]="pageIndex !== currentPageIndex"
                class="p-4"
              >
                <div *ngIf="page.elements.length === 0" class="text-center">
                  <div class="divider"></div>
                  <h4>This page is empty, Add an element below!</h4>
                  <div class="divider"></div>
                </div>

                <!-- begin page elements -->
                <div
                  id="element"
                  *ngFor="
                    let element of page.elements;
                    let elementIndex = index
                  "
                >
                  <button
                    id="insert-element-button"
                    mat-mini-fab
                    color="none"
                    disableRipple
                    class="insert-button"
                    insTooltip="Insert element"
                    insTooltipPosition="left"
                    [insMenuTriggerFor]="insertElementMenu"
                  >
                    <mat-icon class="notranslate">keyboard_return</mat-icon>
                  </button>

                  <ins-menu #insertElementMenu="insMenu">
                    <button
                      id="insert-element-question-button"
                      mat-menu-item
                      (click)="addElement(page, elementIndex, 'text')"
                    >
                      <mat-icon class="notranslate">help</mat-icon>Question
                    </button>
                    <button
                      id="insert-element-paragraph-button"
                      mat-menu-item
                      (click)="addElement(page, elementIndex, 'paragraph')"
                    >
                      <mat-icon class="notranslate">subject</mat-icon>Paragraph
                    </button>
                    <button
                      id="insert-element-image-button"
                      mat-menu-item
                      (click)="addElement(page, elementIndex, 'diagram')"
                    >
                      <mat-icon class="notranslate">insert_photo</mat-icon>Image
                    </button>
                    <button
                      id="insert-element-video-button"
                      mat-menu-item
                      (click)="addElement(page, elementIndex, 'video')"
                    >
                      <mat-icon class="notranslate">local_movies</mat-icon>Video
                    </button>
                    <button
                      id="insert-element-questionnaire-button"
                      mat-menu-item
                      (click)="addElement(page, elementIndex, 'survey')"
                    >
                      <mat-icon class="notranslate">description</mat-icon
                      >Questionnaire
                    </button>
                    <button
                      id="insert-element-pdf-button"
                      mat-menu-item
                      (click)="addElement(page, elementIndex, 'pdf')"
                    >
                      <mat-icon class="notranslate">picture_as_pdf</mat-icon>PDF
                    </button>
                  </ins-menu>

                  <mat-card
                    [id]="element.id"
                    class="p-4"
                    [ngClass]="{ activeElement: activeElement == element.id }"
                    (click)="setActiveElement(element.id)"
                  >
                    <edit-element
                      [element]="element"
                      (elementChange)="
                        page.elements[elementIndex] = $event;
                        surveyChanged = true
                      "
                      [isActive]="activeElement === element.id"
                      [surveyID]="surveyID"
                      [userID]="userID"
                      [(survey)]="survey"
                      [page]="page"
                      [questionDict]="questionDict"
                      [getParentQuestionsTooltip]="getParentQuestionsTooltip"
                      [getParentQuestionsList]="getParentQuestionsList"
                      [setActiveElement]="setActiveElement"
                      [togglePractitionerQuestion]="togglePractitionerQuestion"
                      [moveElementUp]="moveElementUp"
                      [moveElementDown]="moveElementDown"
                      [deleteElement]="deleteElement"
                      [copyElement]="copyElement"
                    ></edit-element>
                  </mat-card>
                </div>
                <!-- end page elements -->

                <div class="divider"></div>

                <!-- begin page footer actions -->
                <mat-card-actions>
                  <div class="w-100 d-flex flex-row">
                    <span class="flex">
                      <button
                        id="add-page-button"
                        mat-mini-fab
                        color="none"
                        disableRipple
                        insTooltip="Add page"
                        insTooltipPosition="above"
                        (click)="addPage(pageIndex + 1)"
                      >
                        <mat-icon class="notranslate">note_add</mat-icon>
                      </button>
                    </span>

                    <button
                      id="page-footer-add-element-button"
                      mat-raised-button
                      disableRipple
                      [insMenuTriggerFor]="addElementMenu"
                    >
                      Add Element
                    </button>
                    <ins-menu #addElementMenu="insMenu">
                      <button
                        id="page-footer-add-element-question-button"
                        mat-menu-item
                        (click)="addElement(page, page.elements.length, 'text')"
                      >
                        <mat-icon class="notranslate">help</mat-icon>Question
                      </button>
                      <button
                        id="page-footer-add-element-paragraph-button"
                        mat-menu-item
                        (click)="
                          addElement(page, page.elements.length, 'paragraph')
                        "
                      >
                        <mat-icon class="notranslate">subject</mat-icon
                        >Paragraph
                      </button>
                      <button
                        id="page-footer-add-element-image-button"
                        mat-menu-item
                        (click)="
                          addElement(page, page.elements.length, 'diagram')
                        "
                      >
                        <mat-icon class="notranslate">insert_photo</mat-icon
                        >Image
                      </button>
                      <button
                        id="page-footer-add-element-video-button"
                        mat-menu-item
                        (click)="
                          addElement(page, page.elements.length, 'video')
                        "
                      >
                        <mat-icon class="notranslate">local_movies</mat-icon
                        >Video
                      </button>
                      <button
                        id="page-footer-add-element-questionnaire-button"
                        mat-menu-item
                        (click)="
                          addElement(page, page.elements.length, 'survey')
                        "
                      >
                        <mat-icon class="notranslate">description</mat-icon
                        >Questionnaire
                      </button>
                      <button
                        id="page-footer-add-element-pdf-button"
                        mat-menu-item
                        (click)="addElement(page, page.elements.length, 'pdf')"
                      >
                        <mat-icon class="notranslate">picture_as_pdf</mat-icon
                        >PDF
                      </button>
                    </ins-menu>

                    <span class="flex d-flex flex-row justify-content-end">
                      <button
                        id="page-footer-move-page-up-button"
                        mat-mini-fab
                        color="none"
                        disableRipple
                        insTooltip="Move Up"
                        insTooltipPosition="left"
                        (click)="movePageUp(pageIndex)"
                        [disabled]="pageIndex === 0"
                      >
                        <mat-icon class="notranslate">arrow_drop_up</mat-icon>
                      </button>
                      <button
                        id="page-footer-move-page-down-button"
                        mat-mini-fab
                        color="none"
                        disableRipple
                        insTooltip="Move Down"
                        insTooltipPosition="right"
                        [style.z-index]="10"
                        (click)="movePageDown(pageIndex)"
                        [disabled]="pageIndex === survey.pages.length - 1"
                      >
                        <mat-icon class="notranslate">arrow_drop_down</mat-icon>
                      </button>
                      <span class="space"></span>
                      <button
                        id="page-footer-delete-element-button"
                        mat-mini-fab
                        color="none"
                        disableRipple
                        insTooltip="Delete Page"
                        insTooltipPosition="above"
                        (click)="deletePage(pageIndex)"
                        [disabled]="survey.pages.length === 1"
                      >
                        <mat-icon class="notranslate">delete</mat-icon>
                      </button>
                    </span>
                  </div>
                </mat-card-actions>

                <!-- end page footer actions -->

                <div class="divider"></div>

                <!-- begin change page -->
                <div class="w-100 d-flex flex-row justify-content-center">
                  <button
                    id="page-footer-previous-page-button"
                    *ngIf="surveyLoaded"
                    mat-raised-button
                    disableRipple
                    class="btn-w-md float-right"
                    [disabled]="currentPageIndex === 0"
                    (click)="previousPage()"
                    color="primary"
                  >
                    <mat-icon class="notranslate">
                      keyboard_arrow_left
                    </mat-icon>
                    <span>Previous Page</span>
                  </button>

                  <div class="space"></div>

                  <button
                    id="page-footer-next-page-button"
                    *ngIf="surveyLoaded"
                    mat-raised-button
                    disableRipple
                    class="btn-w-md float-left"
                    [disabled]="currentPageIndex === survey.pages.length - 1"
                    (click)="nextPage()"
                    color="primary"
                  >
                    <span>Next Page</span>
                    <mat-icon class="notranslate">
                      keyboard_arrow_right
                    </mat-icon>
                  </button>
                </div>
                <!-- end change page -->
              </mat-card>
            </ng-container>
            <!-- end pages -->
          </mat-tab>
          <!-- end survey builder tab -->

          <!-- begin pdfs integration tab -->
          <mat-tab label="PDFs">
            <!-- <div class="w-100 text-center">
              <h5>
                Insig questionnaires can also auto-populate standard forms or
                PDFs, like WSIB Form 8. To populate forms with Insig
                questionnaire results please contact us.
              </h5>
              <h4>
                <a href="mailto:info@insighealth.com">info@insighealth.com</a>
              </h4>
            </div> -->

            <hr />
            <div class="divider"></div>
            <div class="divider"></div>

            <div class="w-100 d-flex flex-row justify-content-center row">
              <mat-form-field class="col-12">
                <mat-label>PDF ID</mat-label>
                <input
                  matInput
                  type="text"
                  [ngModel]="survey.pdfID"
                  (ngModelChange)="survey.pdfID = $event; surveyChanged = true"
                />
              </mat-form-field>
              <span class="space"></span>
              <mat-form-field class="col-12">
                <mat-label>Default PDF Email</mat-label>
                <input
                  matInput
                  pattern="^.+@.+\..+$"
                  type="email"
                  [ngModel]="survey.pdfEmail"
                  (ngModelChange)="
                    survey.pdfEmail = $event; surveyChanged = true
                  "
                />
              </mat-form-field>
              <span class="space"></span>
              <mat-form-field class="col-12">
                <mat-label>Default PDF Fax Number</mat-label>
                <input
                  matInput
                  minlength="10"
                  maxlength="14"
                  insig-phone-mask
                  type="tel"
                  [ngModel]="survey.pdfFax"
                  (ngModelChange)="survey.pdfFax = $event; surveyChanged = true"
                />
              </mat-form-field>

              <span class="space"></span>
              <span class="space"></span>
              <span class="space"></span>
              <span class="space"></span>
              <span class="space"></span>
              <!-- <mat-checkbox class="pt-3" [(ngModel)]="survey.pdfInNote">Add to Note</mat-checkbox> -->
            </div>

            <div class="divider"></div>
          </mat-tab>

          <mat-tab label="Personalization">
            <div class="mt-4">
              <mat-checkbox [(ngModel)]="survey.compressed"
                >Compress line spacing & font size</mat-checkbox
              >
            </div>

            <div class="mt-2">
              <mat-checkbox [(ngModel)]="survey.grid"
                >Organize note in grid format</mat-checkbox
              >
            </div>

            <h5 class="mt-2">
              Use these codes in your note sentences to personalize the note:
            </h5>
            <span class="notranslate">%%-first-name&nbsp;=></span
            ><span>&nbsp;patient first name</span><br />
            <span class="notranslate">%%-last-name&nbsp;=></span
            ><span>&nbsp;patient last name</span><br />
            <span class="notranslate">%%-healthCardNumber&nbsp;=></span
            ><span>&nbsp;patient health card number</span><br />
            <span class="notranslate">%%-address&nbsp;=></span
            ><span>&nbsp;address</span><br />
            <span class="notranslate">%%-fullAddress&nbsp;=></span
            ><span>&nbsp;address, city, province & postal code</span><br />
            <span class="notranslate">%%-city&nbsp;=></span
            ><span>&nbsp;city</span><br />
            <span class="notranslate">%%-province&nbsp;=></span
            ><span>&nbsp;province</span><br />
            <span class="notranslate">%%-postalCode&nbsp;=></span
            ><span>&nbsp;postalCode</span><br />
            <span class="notranslate">%%-email&nbsp;=></span
            ><span>&nbsp;email</span><br />
            <span class="notranslate">%%-phone&nbsp;=></span
            ><span>&nbsp;phone number</span><br />
            <span class="notranslate">%%-dob&nbsp;=></span
            ><span>&nbsp;date of birth</span><br />
            <span class="notranslate">%%-day&nbsp;=></span
            ><span>&nbsp;day of birth</span><br />
            <span class="notranslate">%%-month&nbsp;=></span
            ><span>&nbsp;month of birth</span><br />
            <span class="notranslate">%%-year&nbsp;=></span
            ><span>&nbsp;year of birth</span><br />
            <span class="notranslate">%%-age&nbsp;=></span
            ><span>&nbsp;patient age</span><br />
            <br />
            <span class="notranslate">%%-gender&nbsp;=></span
            ><span>&nbsp;male</span><span class="notranslate">/</span
            ><span>female</span><br />
            <span class="notranslate">%%-mr-ms&nbsp;=></span
            ><span>&nbsp;Mr.</span><span class="notranslate">/</span
            ><span>Ms.</span><br />
            <span class="notranslate">%%-his-her-c&nbsp;=></span
            ><span>&nbsp;His</span><span class="notranslate">/</span
            ><span>Her</span><br />
            <span class="notranslate">%%-his-her&nbsp;=></span
            ><span>&nbsp;his</span><span class="notranslate">/</span
            ><span>her</span><br />
            <span class="notranslate">%%-he-she-c&nbsp;=></span
            ><span>&nbsp;He</span><span class="notranslate">/</span
            ><span>She</span><br />
            <span class="notranslate">%%-he-she&nbsp;=></span
            ><span>&nbsp;he</span><span class="notranslate">/</span
            ><span>she</span><br />
            <span class="notranslate">%%-him-her-c&nbsp;=></span
            ><span>&nbsp;Him</span><span class="notranslate">/</span
            ><span>Her</span><br />
            <span class="notranslate">%%-him-her&nbsp;=></span
            ><span>&nbsp;him</span><span class="notranslate">/</span
            ><span>her</span><br />
            <br />
            <!-- <p>%%-date-today => date you're viewing a rendered note</p> -->
            <span class="notranslate">%%-date-completed&nbsp;=></span
            ><span>&nbsp;date patient completed a questionnaire</span><br />
          </mat-tab>
          <!-- end pdfs integration tab -->
        </mat-tab-group>
      </mat-card>

      <!-- begin fab buttons -->
      <div class="page-change-buttons">
        <div insTooltip="Next Page" insTooltipPosition="left">
          <button
            id="page-change-next-page-button"
            mat-mini-fab
            color="primary"
            [disabled]="currentPageIndex === survey.pages.length - 1"
            (click)="nextPage()"
            *ngIf="surveyLoaded"
          >
            <mat-icon class="notranslate">navigate_next</mat-icon>
          </button>
        </div>

        <div class="divider"></div>

        <div insTooltip="Previous Page" insTooltipPosition="left">
          <button
            id="page-change-previous-page-button"
            mat-mini-fab
            color="primary"
            [disabled]="currentPageIndex === 0"
            (click)="previousPage()"
            *ngIf="surveyLoaded"
          >
            <mat-icon class="notranslate">navigate_before</mat-icon>
          </button>
        </div>

        <div class="divider"></div>

        <button
          mat-mini-fab
          insTooltip="Save Questionnaire"
          insTooltipPosition="left"
          color="primary"
          (click)="saveSurvey()"
        >
          <mat-icon class="notranslate">save</mat-icon>
        </button>
      </div>
      <!-- end fab buttons -->
    </article>
  </div>
</section>
