// @ts-strict-ignore
import {
  Component,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  NgZone, inject } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationExtras } from '@angular/router';

import { Apollo, gql } from 'apollo-angular';

import { firstValueFrom, Subscription } from 'rxjs';

import firebase from 'firebase/compat/app';

import { CompanyDataService } from '@insig-health/services/company/company-data.service';
import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';
import { PatientUserData } from '@insig-health/insig-types/user-data';
import { SchedulerService } from 'insig-app/services/scheduler.service';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';
import { PatientAppointment } from 'insig-app/services/appointment/appointment.service';


@Component({
  selector: 'insig-health-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styles: [
    `
      .selectedSideNavOption {
        background-color: var(--primary-color) !important;
        color: white !important;
      }
      .referralMenuItem {
        color: #66bb6a !important;
      }
      .nav-text {
        text-wrap: wrap;
      }

      li > a {
        height: unset;
      }
    `,
  ],
  providers: [PatientUserDataService],
})
export class AppSidenavMenuComponent implements OnInit, OnDestroy {
  private readonly apollo = inject(Apollo);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly firebaseAuthService = inject(FirebaseAuthService);
  private readonly changeDetector = inject(ChangeDetectorRef);
  private readonly ngZone = inject(NgZone);
  private readonly patientUserDataService = inject(PatientUserDataService);
  private readonly router = inject(Router);
  private readonly schedulerService = inject(SchedulerService);
  public readonly location = inject(Location);
  // graphql queries
  private userDataQuery = gql`
    query UserDataQuery($userID: ID!, $token: ID!) {
      getUserData(uid: $userID, token: $token) {
        uid
        company
        type {
          admin
        }
      }
    }
  `;
  //

  public admin = false;
  public userData: any = {};
  private authSubscription: Subscription;
  public loading = true;

  public readonly BOOK_APPOINTMENT_PATH = '/virtual/book-appointment/';
  private readonly INTERNAL_APP_PATH = '/app';
  public patient = false;
  public bookingUrl: string | undefined;

  ngOnInit(): void {
    this.authSubscription = this.firebaseAuthService
      .onIdTokenChanged()
      .subscribe(async (user) => {
        if (user) {
          this.userData = await this.tryGetUserData(user);
          if (!this.userData) {
            const patientUserData = await this.tryGetPatientData(user);
            if (patientUserData === undefined) {
              // user logged out or is not logged in
              return;
            }
            this.userData = patientUserData;
            this.bookingUrl = await this.getBookingUrl(patientUserData);
            this.patient = true;
            this.admin = this.userData.admin;
          }
        }
        this.loading = false;
        this.changeDetector.detectChanges();
      });
  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  public navigate(commands: any[], extras?: NavigationExtras): void {
    this.ngZone.run(() => this.router.navigate(commands, extras)).then();
  }

  async tryGetPatientData(user: firebase.User): Promise<PatientUserData> {
    return firstValueFrom(this.patientUserDataService.getPatientData(user.uid));
  }

  async tryGetUserData(user: firebase.User): Promise<any> {
    try {
      const userDataQuery: any = await firstValueFrom(this.apollo
        .query({
          query: this.userDataQuery,
          variables: {
            userID: user.uid,
            token: await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken(),
          },
        }));
      const userData = userDataQuery.data.getUserData;
      if (userData) {
        if (userData.type?.admin) {
          this.admin = true;
        }
        return userData;
      }
      return false;
    } catch (error) {
      switch (error.code) {
        default:
          console.log(error);
          throw error;
        case 'PERMISSION_DENIED':
          console.log('Wrong uid. User may have logged out.');
          throw new Error('Wrong uid. User may have logged out.');
      }
    }
  }

  async getBookingUrl(patientUserData: PatientUserData): Promise<string | undefined> {
    if (patientUserData.customBookingURL !== undefined) {
      return patientUserData.customBookingURL;
    }
    return this.getBookingUrlFromMostRecentAppointment(patientUserData.uid);
  }

  async getBookingUrlFromMostRecentAppointment(patientUserDatUid: string): Promise<string | undefined> {
    const mostRecentAppointment = await this.getMostRecentAppointmentOfPatient(patientUserDatUid);
    if (mostRecentAppointment === undefined) {
      return undefined;
    }

    const companyId = mostRecentAppointment.linkId.split('-')[0];
    const companyData = await this.companyDataService.getCompanyData(companyId);
    if (companyData.website !== undefined) {
      return companyData.website;
    }

    let virtualCareUrl = `${this.BOOK_APPOINTMENT_PATH}${companyData.id}`;
    if (mostRecentAppointment.planId) {
      virtualCareUrl += `;planID=${mostRecentAppointment.planId}`;
    }

    return virtualCareUrl;
  }

  async getMostRecentAppointmentOfPatient(patientUid: string): Promise<PatientAppointment | undefined> {
    const { appts } = await firstValueFrom(this.schedulerService.getAllPatientAppointments(patientUid));

    if (appts.length === 0) {
      return undefined;
    }

    const sortedAppointments = [...appts].sort((appointmentOne, appointmentTwo) => appointmentTwo.bookingDate - appointmentOne.bookingDate);
    return sortedAppointments[0];
  }

  handleSideNavButtonClicked(navigationUrl: string): void {
    this.toggleSidebar();

    if (navigationUrl.startsWith(this.BOOK_APPOINTMENT_PATH) || navigationUrl.startsWith(this.INTERNAL_APP_PATH)) {
      this.router.navigateByUrl(navigationUrl);
    } else {
      window.location.href = navigationUrl;
    }
  }

  toggleSidebar(): void {
    const bodyTagClassList = window.document.getElementsByTagName('body')[0].classList;
    if (bodyTagClassList.contains('sidebar-mobile-open')) {
      bodyTagClassList.remove('sidebar-mobile-open');
    } else {
      bodyTagClassList.add('sidebar-mobile-open');
    }
  }
}
