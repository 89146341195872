// @ts-strict-ignore
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'conditions-question',
  templateUrl: './conditions.component.html',
})
export class ConditionsComponent implements OnInit {
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();

  private conditions = [
    'Anxiety',
    'Arthritis',
    'Asthma',
    'Cancer',
    'Depression',
    'Diabetes',
    'Heart Condition',
    'High Blood Pressure',
    'High Cholesterol',
    'Kidney Disease',
    'Stroke',
    'Thyroid Disease',
  ];

  ngOnInit() {
    if (!this.question.response.selectedCheckboxes) {
      this.question.response.selectedCheckboxes = [];
      this.conditions.forEach((condition) => {
        this.question.response.selectedCheckboxes.push({
          condition,
          selected: false,
        });
      });
    }
    if (!this.question.response.selectedAnswers) {
      this.question.response.selectedAnswers = [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  removeItem(val) {
    const index = this.question.response.selectedAnswers.indexOf(val);
    this.question.response.selectedAnswers.splice(index, 1);
  }

  addItem(val) {
    if (this.question.response.selectedAnswers.indexOf(val) === -1) {
      this.question.response.selectedAnswers.push(val);
    }
    this.question.response.selectedAnswers[-1] = null;
    console.log(this.question.response.selectedAnswers);
  } // end add item
}
