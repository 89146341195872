import { Component, inject } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'delete-folder-dialog',
  templateUrl: './delete-folder-dialog.component.html',
})
export class DeleteFolderDialogComponent {
  public readonly dialogRef = inject<MatDialogRef<DeleteFolderDialogComponent, boolean>>(MatDialogRef<DeleteFolderDialogComponent, boolean>);
}
