// @ts-strict-ignore
import { Component, Input, ViewChild, OnInit, Output, SimpleChanges, OnChanges, EventEmitter, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { MedicationsListService } from '../../../services/medications-list.service';

import { map, startWith, take } from 'rxjs/operators';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'medication-allergies-question',
  templateUrl: './medication-allergies.component.html',
})
export class MedicationAllergiesComponent implements OnInit, OnChanges {
  private readonly medicationsListService = inject(MedicationsListService);
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();

  @ViewChild('itemsSelect') itemsSelect;
  itemsCtrl: UntypedFormControl;
  filteredItems: any;
  items = [];

  private allergyMedications = [
    'Penicillins',
    'Aspirin',
    'NSAIDs ex. Advil, Naproxen etc.',
    'Sulfa Drugs',
    'Codeine',
    'Cephalosporins (Ceclor, Cefzil, Ceftin etc.)',
    'Erythromycin/Biaxin/Zithromax',
    'Tetracyclines (Minocyline, Doxycyline, etc.)',
    'Ciprofloxacin',
    'Other Opioids (Demerol, Morphine, Percocet, etc.)',
  ];

  constructor() {
    this.itemsCtrl = new UntypedFormControl();
    this.filteredItems = this.itemsCtrl.valueChanges.pipe(
      startWith(null),
      map((name) => this.filterItems(name))
    );
  }

  async ngOnInit() {
    const response = this.question.response as {
      selectedCheckboxes: Array<{ medication: string, selected: boolean }>,
      selectedAnswers: string[];
    };
    if (!response.selectedCheckboxes) {
      response.selectedCheckboxes = [];
      this.allergyMedications.forEach((medication) => {
        response.selectedCheckboxes.push({
          medication,
          selected: false,
        });
      });
    } else {
      response.selectedCheckboxes = this.fixNullMedications(response.selectedCheckboxes);
    }
    if (!response.selectedAnswers) {
      response.selectedAnswers = [];
    }
    try {
      this.items = await this.medicationsListService
        .getMedications()
        .pipe(take(1))
        .toPromise();
    } catch (error) {
      console.error(error);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  filterItems(val: string) {
    return val
      ? this.items
          .filter((s) => s.toLowerCase().indexOf(val.toLowerCase()) === 0)
          .slice(0, 50)
      : this.items.slice(0, 50);
  }

  removeItem(val) {
    const index = this.question.response.selectedAnswers.indexOf(val);
    this.question.response.selectedAnswers.splice(index, 1);
  }

  addItem(val) {
    if (this.question.response.selectedAnswers.indexOf(val) === -1) {
      this.question.response.selectedAnswers.push(val);
    }
    this.itemsCtrl.setValue(null);
  } // end add item

  fixNullMedications(selectedCheckboxes: Array<{ medication: string, selected: boolean }>) {
    const isAnyMedicationNull = selectedCheckboxes.some(({ medication }) => medication === null || medication === undefined);
    if (isAnyMedicationNull) {
      selectedCheckboxes = selectedCheckboxes.map(({ medication, selected }, index) => {
        if (medication === null || medication === undefined) {
          medication = this.allergyMedications[index];
        }
        return { medication, selected };
      });
    }
    return selectedCheckboxes;
  }
}
