import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialComponentsModule } from '../material-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../dashboard/shared/shared.module';

import { SubscriptionsComponent } from '../subscriptions/subscriptions.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    CommonModule,
    MaterialComponentsModule,
  ],
  declarations: [
    // Components
    SubscriptionsComponent,
  ],
  exports: [SubscriptionsComponent],
})
export class SubscriptionsModule {}
