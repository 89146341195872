import { Injectable } from '@angular/core';

@Injectable()
export class FlagColorsService {
  private flagColors = [
    { name: 'Red', color: '#CF3759' },
    { name: 'Orange', color: '#E77547' },
    { name: 'Yellow', color: '#ffd600' },
    { name: 'Green', color: '#43a047' },
    { name: 'Blue', color: 'var(--primary-color)' },
    { name: 'Purple', color: '#b388ff' },
  ];

  // ['#000', '#9CB199', '#CF3759', '#485247', '#E77547', '#D38E47', '#0A6A74', '#153974']

  getFlagColors() {
    return this.flagColors;
  }
} // end service
