<div class="text-center">
  <div class="row">
    <div class="col-2">
      <!-- left spacer -->
    </div>
    <div class="col-3">
      <mat-checkbox [(ngModel)]="question.inlineDate"
        >Inline Calendar</mat-checkbox
      >
    </div>
    <div class="col-3">
      <mat-checkbox
        [ngModel]="question.noFuture"
        (ngModelChange)="toggleFutureDates($event)"
        [disabled]="question.noPast"
        >No Future Dates</mat-checkbox
      >
    </div>
    <div class="col-3">
      <mat-checkbox
        [ngModel]="question.noPast"
        (ngModelChange)="togglePastDates($event)"
        [disabled]="question.noFuture"
        >No Past Dates</mat-checkbox
      >
    </div>
    <div class="col-1">
      <!-- right spacer -->
    </div>
  </div>
  <div class="divider"></div>
  <div *ngIf="!question.inlineDate">
    <mat-form-field>
      <mat-label>Choose a date</mat-label>
      <input
        matInput
        [(ngModel)]="selectedDate"
        [matDatepicker]="picker"
        [min]="question.noPast ? today : null"
        [max]="question.noFuture ? today : null"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #picker startView="year" [touchUi]="false"></mat-datepicker>
  </div>
  <div *ngIf="question.inlineDate">
    <dp-day-calendar
      [(ngModel)]="selectedMoment"
      [theme]="'dp-material'"
      [config]="config"
      [minDate]="question.noPast ? thisMoment : null"
      [maxDate]="question.noFuture ? thisMoment : null"
    ></dp-day-calendar>
  </div>

  <div class="row">
    <div class="col-1">
      <!-- left spacer -->
    </div>
    <div class="col-10">
      <mat-checkbox [(ngModel)]="question.flagDays"></mat-checkbox>
      Flag old dates
      <span *ngIf="question.flagDays">
        more than &nbsp;
        <mat-form-field>
          <input matInput type="number" [(ngModel)]="question.flagNumDays" />
        </mat-form-field>
        &nbsp; days ago
        <br />
        Flag reason &nbsp;
        <mat-form-field>
          <input matInput type="text" [(ngModel)]="question.flagDaysReason" />
        </mat-form-field>
      </span>
    </div>
    <div class="col-1">
      <!-- right spacer -->
    </div>
  </div>
</div>
