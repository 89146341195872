import { Component, Input } from '@angular/core';

@Component({
  selector: 'insig-referral-clinic',
  templateUrl: './insig-referral-clinic.component.html',
  styleUrls: ['./insig-referral-clinic.component.scss'],
})
export class InsigReferralClinicComponent {
  @Input() referralClinic: any;
  @Input() referralBorder = true;
  @Input() showAddress = false;
  @Input() doctorMode = false;
}
