<mat-card class="p-3">
  <h1 matDialogTitle>
    Overlapping Time Segments
  </h1>
  <div mat-dialog-content>
    <span>
      There are overlapping time segments in the current schedule and/or exception schedule.
      Please have no overlapping time segments before saving.
    </span>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-end">
    <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
  </div>
</mat-card>
