import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
// Page Layouts

const AppRoutes: Routes = [
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  { path: 'app', component: LayoutComponent },
  {
    path: 'scheduler',
    loadChildren: () =>
      import('./scheduler/scheduler.module').then((m) => m.SchedulerModule),
  },
  {
    path: 'surveys',
    loadChildren: () =>
      import('./surveys/surveys.module').then((m) => m.SurveysModule),
  },
  {
    path: 'network',
    loadChildren: () =>
      import('./network/network.module').then(
        (m) => m.NetworkModule,
      ),
  },
  {
    path: 'virtual',
    loadChildren: () =>
      import('./virtual-care/virtual-care.module').then(
        (m) => m.VirtualCareModule,
      ),
  },
  {
    path: 'notes',
    loadChildren: () =>
      import('./notes/notes.module').then((m) => m.NotesModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'employee-sign-up',
    loadChildren: () =>
      import('./employee-sign-up/employee-sign-up.module').then(
        (m) => m.EmployeeSignUpModule,
      ),
  },
  {
    path: 'quick-start',
    loadChildren: () =>
      import('./quick-start/quick-start.module').then(
        (m) => m.QuickStartModule,
      ),
  },
  {
    path: 'employee-manager',
    loadChildren: () =>
      import('./employee-manager/employee-manager.module').then(
        (m) => m.EmployeeManagerModule,
      ),
  },
  { path: '**', redirectTo: '/auth/login', pathMatch: 'full' },
];

export const AppRoutingModule = RouterModule.forRoot(AppRoutes, {
  useHash: false,
});
