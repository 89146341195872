// @ts-strict-ignore
import {
  Component,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'radio-question',
  templateUrl: './radio.component.html',
  styleUrls: ['../question-types.style.css'],
})
export class RadioComponent implements OnDestroy {
  @Input() question: Question;
  @Input() pageElement = null;
  @Input() elementID = null;
  @Input() triggersDictionary = null;
  @Output() checkTriggersChild = new EventEmitter<string>();
  @Output() questionChange = new EventEmitter<Question>();

  sendCheckTriggers() {
    this.checkTriggersChild.emit('true');
  }

  ngOnDestroy() {
    if (this.pageElement.triggerHidden === true) {
      this.removeInactiveTriggers();
    }
  }

  removeAllTriggers() {
    this.removeInactiveTriggers();
    this.sendCheckTriggers();
  }

  // update triggers for each answer, index is answer selected
  modifyTriggers(index) {
    this.removeInactiveTriggers();
    // activate new trigger
    if (
      this.question.offeredAnswers[index].pageFlow &&
      this.question.offeredAnswers[index].pageFlow.trigger
    ) {
      const triggersDictionary = this.triggersDictionary;
      const parentID = this.elementID;
      // change each childs trigger property for this parent
      this.question.offeredAnswers[index].pageFlow.trigger.forEach((child) => {
        if (triggersDictionary[child]) {
          triggersDictionary[child][parentID] = true;
        } else {
          console.log(
            'Incorrect JSON for this question. Subquestion array error'
          );
        }
      });
    } // end if
    this.sendCheckTriggers();
  } // end modify trigs

  removeInactiveTriggers() {
    // go over each answers triggers
    for (const offeredAnswer in this.question.offeredAnswers) {
      if (
        this.question.offeredAnswers[offeredAnswer].pageFlow &&
        this.question.offeredAnswers[offeredAnswer].pageFlow.trigger
      ) {
        const triggersDictionary = this.triggersDictionary;
        const parentID = this.elementID;
        // change each childs trigger property for this parent
        this.question.offeredAnswers[offeredAnswer].pageFlow.trigger.forEach(
          (child) => {
            if (triggersDictionary[child]) {
              triggersDictionary[child][parentID] = false;
            } else {
              console.log(
                'Incorrect JSON for this question. Subquestion array error'
              );
            }
          }
        );
      } // end if
    }
  }

  handleOfferedAnswerChanged(change: MatRadioChange): void {
    const newValue = change.value;
    if (newValue) {
      this.questionChange.emit({
        ...this.question,
        response: {
          selectedAnswer: newValue,
        },
      });
    }
  }

  handleOtherResponseChange(otherText: string): void {
    this.questionChange.emit({
      ...this.question,
      response: {
        selectedAnswer: 'other',
        other: otherText,
      }
    });
  }
}
