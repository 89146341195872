import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FamilyDoctorSearchComponent } from './components/family-doctor-search.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { UiComponentsModule } from '@insig-health/components';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    UiComponentsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    FamilyDoctorSearchComponent,
  ],
  exports: [
    FamilyDoctorSearchComponent,
  ],
})
export class FamilyDoctorSearchModule {}
