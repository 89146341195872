import { Directive, ElementRef, AfterViewInit, inject } from '@angular/core';

@Directive({ selector: '[myAppendSubmenuIcon]' })
export class AppendSubmenuIconDirective implements AfterViewInit {
  el: ElementRef;
  constructor() {
        const el = inject(ElementRef);

    this.el = el;
  }

  ngAfterViewInit() {
    const $el = $(this.el.nativeElement);

    $el
      .find('.prepend-icon')
      .prepend('<i class="material-icons">keyboard_arrow_right</i>');
  }
}
