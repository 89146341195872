import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { MILLISECONDS_PER_DAY, MILLISECONDS_PER_HOUR, MILLISECONDS_PER_MINUTE, MILLISECONDS_PER_SECOND } from './date-and-time.constants';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(LocalizedFormat);

export enum DaysOfTheWeek {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

@Injectable({
  providedIn: 'root',
})
export class DateAndTimeService {
  getLocalTimeZone(): string {
    return dayjs.tz.guess();
  }

  getLocalTime(timezone: string): Date {
    return dayjs().tz(timezone).toDate();
  }

  getDateAsIsoString(date: Date, timezone: string): string {
    return dayjs.tz(date, timezone).toISOString();
  }

  getHour(date: Date, timezone: string): string {
    return dayjs.tz(date, timezone).format('h');
  }

  setHour(date: Date, timeZone: string, hour: number): Date {
    return dayjs.tz(date, timeZone).set('hour', hour).toDate();
  }

  getHourAndPeriod(date: Date, timezone?: string): string {
    if (timezone === undefined) {
      return dayjs(date).format('h A');
    } else {
      return dayjs.tz(date, timezone).format('h A');
    }
  }

  getHourAndMinute(date: Date, timezone?: string): string {
    if (timezone === undefined) {
      return dayjs(date).format('h:mm');
    } else {
      return dayjs.tz(date, timezone).format('h:mm');
    }
  }

  getHourMinuteAndPeriod(date: Date, timezone: string): string {
    return dayjs.tz(date, timezone).format('h:mm A');
  }

  getShortFormDayOfTheWeek(date: Date, timezone?: string): string {
    if (timezone === undefined) {
      return dayjs(date).format('ddd');
    } else {
      return dayjs.tz(date, timezone).format('ddd');
    }
  }

  getDayOfTheWeek(date: Date, timezone: string): string {
    return dayjs.tz(date, timezone).format('dddd');
  }

  getDayOfTheMonth(date: Date, timezone?: string): string {
    if (timezone === undefined) {
      return dayjs(date).format('D');
    } else {
      return dayjs.tz(date, timezone).format('D');
    }
  }

  getDayAndMonth(date: Date, timezone?: string): string {
    if (timezone === undefined) {
      return dayjs(date).format('D MMM');
    } else {
      return dayjs.tz(date, timezone).format('D MMM');
    }
  }

  getMonthAndDay(date: Date, timezone?: string): string {
    if (timezone === undefined) {
      return dayjs(date).format('MMM D');
    } else {
      return dayjs.tz(date, timezone).format('MMM D');
    }
  }

  getMonthName(date: Date, timezone: string): string {
    return dayjs.tz(date, timezone).format('MMMM');
  }

  getMonthShortformName(date: Date, timezone?: string): string {
    if (timezone === undefined) {
      return dayjs(date).format('MMM');
    } else {
      return dayjs.tz(date, timezone).format('MMM');
    }
  }

  getDate(date: Date, timezone?: string): string {
    if (timezone === undefined) {
      return dayjs(date).format('DD');
    } else {
      return dayjs.tz(date, timezone).format('DD');
    }
  }

  getDateWithOrdinal(date: Date, timezone: string): string {
    return dayjs.tz(date, timezone).format('Do');
  }

  getYear(date: Date, timezone: string): string {
    return dayjs.tz(date, timezone).format('YYYY');
  }

  getReadableCalendarDate(date: Date, timezone: string): string {
    return dayjs.tz(date, timezone).format('Do MMM YYYY');
  }

  getDateAsYearMonthDay(date: Date, timezone?: string): string {
    if (timezone === undefined) {
      return dayjs(date).format('YYYY-MM-DD');
    } else {
      return dayjs.tz(date, timezone).format('YYYY-MM-DD');
    }
  }

  getDateAndTimeInLocalizedFormat(date: Date, timezone?: string): string {
    if (timezone === undefined) {
      return dayjs(date).format('LLL');
    } else {
      return dayjs.tz(date, timezone).format('LLL');
    }
  }

  getDateAsDayMonthYear(date: Date, timezone: string): string {
    return dayjs.tz(date, timezone).format('DD-MM-YYYY');
  }

  getLocalizedDate(date: Date, timezone: string): string {
    return dayjs.tz(date, timezone).format('L');
  }

  getTimeZoneAbbreviation(date: Date, timeZone: string): string {
    return dayjs.tz(date, timeZone).format('z');
  }

  getTimeZoneNames(): string[] {
    // @ts-ignore https://github.com/microsoft/TypeScript/issues/49231
    return Intl.supportedValuesOf('timeZone');
  }

  getDaysDifferenceBetweenTwoDates(dateOne: Date, dateTwo: Date): number {
    const dayOne = dayjs(dateOne).set('milliseconds', 0);
    const dayTwo = dayjs(dateTwo).set('milliseconds', 0);
    return Math.abs(dayOne.diff(dayTwo, 'days'));
  }

  getLocalCalendarDaysDifferenceBetweenTwoDates(minuendDate: Date, subtrahendDate: Date): number {
    const minuendEpochDay = this.getEpochDayFromDate(minuendDate);
    const subtrahendEpochDay = this.getEpochDayFromDate(subtrahendDate);
    return minuendEpochDay - subtrahendEpochDay;
  }

  isSameCalendarDate(dateOne: Date, dateTwo: Date): boolean {
    return this.getLocalCalendarDaysDifferenceBetweenTwoDates(dateOne, dateTwo) === 0;
  }

  getEpochDayFromDate(date: Date): number {
    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return Math.floor(utcDate.getTime() / MILLISECONDS_PER_DAY);
  }

  areTwoDatesTheSame(dateOne: Date, dateTwo: Date): boolean {
    return dayjs(dateOne).isSame(dateTwo);
  }

  areTwoDatesTheSameDay(dateOne: Date, dateTwo: Date, timezone: string): boolean {
    return dayjs.tz(dateOne, timezone).format('YYYY-MM-DD') === dayjs.tz(dateTwo, timezone).format('YYYY-MM-DD');
  }

  areTwoDatesInTheSameWeek(dateOne: Date, dateTwo: Date, timezone: string): boolean {
    return dayjs.tz(dateOne, timezone).isSame(dayjs.tz(dateTwo, timezone), 'week');
  }

  areTwoTimesInTheSameHour(timeOne: Date, timeTwo: Date): boolean {
    return dayjs.tz(timeOne).format('YYYY-MM-DD HH') === dayjs.tz(timeTwo).format('YYYY-MM-DD HH');
  }

  isDateOneAfterDateTwo(dateOne: Date, dateTwo: Date): boolean {
    return dayjs(dateOne).isAfter(dateTwo);
  }

  addMinutesToDate(date: Date, minutes: number): Date {
    return dayjs(date).add(minutes, 'minutes').toDate();
  }

  addHourToDate(date: Date): Date {
    return dayjs(date).add(1, 'hour').toDate();
  }

  addDaysToDate(date: Date, days: number): Date {
    return dayjs(date).add(days, 'days').toDate();
  }

  addWeekToDate(date: Date): Date {
    return dayjs(date).add(1, 'week').toDate();
  }

  addDayToDateObject(date: Date): Date {
    return dayjs(date).add(1, 'day').toDate();
  }

  minusWeekToDate(time: Date): Date {
    return dayjs(time).add(-1, 'week').toDate();
  }

  isDateValid(date: string, dateFormat: string): boolean {
    try {
      return dayjs(date, dateFormat, true).isValid();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  getYearAsNumberFromIsoString(isoString: string): number {
    const yyyymmddString = isoString.split('T')[0];
    const yearString = yyyymmddString.split('-')[0];
    return parseInt(yearString, 10);
  }

  getMonthAsNumberFromIsoString(isoString: string): number {
    const yyyymmddString = isoString.split('T')[0];
    const monthString = yyyymmddString.split('-')[1];
    return parseInt(monthString, 10);
  }

  getDayAsNumberFromIsoString(isoString: string): number {
    const yyyymmddString = isoString.split('T')[0];
    const dayString = yyyymmddString.split('-')[2];
    return parseInt(dayString, 10);
  }

  getEpochTimeFromOperatingTime(operatingTime: number, operatingTimeZone: string, epochDay: number): number {
    const epochDate = dayjs(epochDay * MILLISECONDS_PER_DAY)
      .tz('UTC')
      .startOf('day')
      .toDate();

    const operatingHour = Math.floor(operatingTime / MILLISECONDS_PER_HOUR);
    const remainingMinutes = operatingTime - operatingHour * MILLISECONDS_PER_HOUR;

    const operatingMinute = Math.floor(remainingMinutes / MILLISECONDS_PER_MINUTE);
    const remainingSeconds = remainingMinutes - operatingMinute * MILLISECONDS_PER_MINUTE;

    const operatingSecond = Math.floor(remainingSeconds / MILLISECONDS_PER_SECOND);
    const operatingMillisecond = remainingSeconds - operatingSecond * MILLISECONDS_PER_SECOND;

    const operatingDayJs = dayjs(new Date())
      .set('year', epochDate.getUTCFullYear())
      .set('month', epochDate.getUTCMonth())
      .set('date', epochDate.getUTCDate())
      .set('hour', operatingHour)
      .set('minute', operatingMinute)
      .set('second', operatingSecond)
      .set('millisecond', operatingMillisecond)
      .tz(operatingTimeZone, true);

    return operatingDayJs.toDate().getTime();
  }

  getUtcOffsetMinutes(date: Date, timeZone: string): number {
    // The following code is a workaround for the fact that Dayjs has a bug with the utfoffset() method when the date is around a DST change.
    const dateComponentsStrings = date.toLocaleString('sv', { timeZone }).split(/[-\s:]/);
    const dateComponents = dateComponentsStrings.map((dateComponentString) => parseInt(dateComponentString));
    // Months are zero-based in JavaScript
    dateComponents[1] = dateComponents[1] - 1;

    const finalizedDate = {
      year: dateComponents[0],
      monthIndex: dateComponents[1],
      date: dateComponents[2],
      hours: dateComponents[3],
      minutes: dateComponents[4],
      seconds: dateComponents[5],
    };
    const utcDate = Date.UTC(
      finalizedDate.year,
      finalizedDate.monthIndex,
      finalizedDate.date,
      finalizedDate.hours,
      finalizedDate.minutes,
      finalizedDate.seconds,
    );
    return -Math.round((date.getTime() - utcDate) / 60 / 1000);
  }

  // TODO: Add some unit tests for this method
  getStartOfWeek(date: Date, timezone: string): Date {
    return dayjs.tz(date, timezone).startOf('week').toDate();
  }

  getEndOfWeek(date: Date, timeZone: string): Date {
    return dayjs.tz(date, timeZone).endOf('week').toDate();
  }

  getDatesOfWeek(date: Date, timeZone: string): Date[] {
    const startOfWeek = this.getStartOfWeek(date, timeZone);
    const datesOfTheWeek: Date[] = [];
    for (let dayCount = 0; dayCount < 7; dayCount++) {
      datesOfTheWeek.push(this.addDaysToDate(startOfWeek, dayCount));
    }
    return datesOfTheWeek;
  }

  getStartOfDay(date: Date, timeZone: string): Date {
    return dayjs.tz(date, timeZone).startOf('day').toDate();
  }

  getEndOfDay(date: Date, timeZone: string): Date {
    return dayjs.tz(date, timeZone).endOf('day').toDate();
  }
}
