// @ts-strict-ignore
import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector:
    '[validateYoutube][formControlName],[validateYoutube][formControl],[validateYoutube][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => YoutubeValidator),
      multi: true,
    },
  ],
})
export class YoutubeValidator implements Validator {
  validate(input: AbstractControl): { [key: string]: any } {
    if (!(input.value === null || input.value === false)) {
      if (!(input.value.indexOf('https://www.youtube.com/') === 0)) {
        return { validateYoutube: false };
      }
    }
    return null;
  }
}
