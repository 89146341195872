// @ts-strict-ignore
import { Component, Input } from '@angular/core';

@Component({
  selector: 'youtube-embed-container',
  templateUrl: './youtube-embed-container.component.html',
  styleUrls: ['./youtube-embed-container.component.css'],
})
export class YoutubeEmbedContainerComponent {
  @Input() src: string;
}
