<div
  class="row m-0 p-0 full-height flex insig-sidenav-container"
  (window:resize)="onResize($event)"
>
  <div
    class="col-lg-3 overflow-scroll p-0 full-height no-scrollbar"
    *ngIf="!hideSecondMenuSelect"
  >
    <div class="row m-1">
      <div
        [ngClass]="{
          'col-6': menuItems?.showAdd,
          'col-12': !menuItems?.showAdd
        }"
      >
        <h3 class="tab-title">{{ menuItems?.title }}</h3>
      </div>

      <div class="col-6" *ngIf="menuItems?.showAdd">
        <button
          mat-mini-fab
          class="pull-right basic-button m-3"
          matTooltip="Add New Service Category"
          color="primary"
          [matTooltipPosition]="'right'"
          (click)="addNewCategory.emit()"
        >
          <mat-icon class="notranslate">add</mat-icon>
        </button>
      </div>
    </div>
    <div>
      <div class="full-height second-menu-list full-width">
        <div
          *ngFor="let group of menuItems?.groups; let i = index"
          id="accordion-{{ i }}"
        >
          <div class="border-0 card m-0">
            <button
              class="border-0 card-header primary-color-full"
              id="heading-{{ i }}"
              data-toggle="collapse"
              [attr.data-target]="'#collapse-' + i"
              [attr.aria-controls]="'#collapse-' + i"
            >
              <div class="w-100 d-flex flex-row align-items-center">
                <span>
                  {{ group.title }}
                </span>
                <!-- <span class="ml-auto">
                  &nbsp;{{event?.start | date:'shortTime'}}
                </span> -->
                <mat-icon class="ml-2 mr-2 notranslate">{{
                  group.icon ? group.icon : 'list'
                }}</mat-icon>
              </div>
            </button>

            <div
              id="collapse-{{ i }}"
              class="collapse show m-2"
              [attr.aria-labelledby]="'heading-' + i"
              data-parent="#accordion"
            >
              <div class="card-body">
                <div *ngFor="let item of group?.items | sortBy:'asc':'title'">
                  <a
                    [ngClass]="{
                      'active-nav': selectedCategoryItem == item.iden
                    }"
                    (click)="menuItemSelected.emit(item)"
                  >
                    <img
                      *ngIf="item.image"
                      src="{{ item.image }}"
                      alt="Tia Health"
                      height="35"
                      width="35"
                    />
                    <mat-icon *ngIf="!item.image" class="notranslate">{{
                      item.icon ? item.icon : 'description'
                    }}</mat-icon>
                    &nbsp;&nbsp;
                    <span>{{ item.title }}</span>
                    <mat-icon
                      class="pull-right notranslate hidden-chevron"
                      [ngClass]="{
                        'show-chevron': selectedCategoryItem == item.iden
                      }"
                      >chevron_right</mat-icon
                    >
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="col-lg-9 background-white no-margin-no-padding overflow-scroll p-0 full-height"
    *ngIf="showDataScreenMinWidth"
  >
    <mat-toolbar class="primary-color-full" *ngIf="showToolBar">
      <button mat-icon-button (click)="backToMenu()">
        <mat-icon class="notranslate">keyboard_backspace</mat-icon>
      </button>

      <!-- <span class="flex text-center">Third Line</span> -->
      <!-- <mat-icon class="material-icons float-right">delete</mat-icon> -->
    </mat-toolbar>

    <div
      class="row center-vertical full-height m-0"
      [ngClass]="{ 'container-fluid no-breadcrumbs': !!containerSpacing }"
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>
