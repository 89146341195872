// @ts-strict-ignore
import { Component } from '@angular/core';

@Component({
  selector: 'blood-pressure-edit',
  templateUrl: './blood-pressure-edit.component.html',
})
export class BloodPressureEditComponent {
  public rows = [{}];

  removeRow(i) {
    this.rows.splice(i, 1);
  }

  addNewRow() {
    this.rows.push({});
  }
} // end component
