<div class="row">
  <div class="col-12 mx-auto">
    <mat-progress-spinner
      class="mx-auto"
      *ngIf="!question.alreadyLoaded && !quickPreview"
      mode="indeterminate"
    ></mat-progress-spinner>
    <div *ngIf="quickPreview" class="text-center">
      <h4>
        {{ question.triggeredLibrary ? ' Library' : 'User' }} Questionnaire:
        {{ question.survName }}
      </h4>
      <div class="divider"></div>
      <hr />
    </div>
  </div>
</div>
