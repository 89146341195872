<div class="page-err">
  <div class="err-container text-center">
    <div class="err">
      <h2>Page Under Construction</h2>
    </div>

    <div class="divider"></div>
    <div class="divider"></div>
    <div class="divider"></div>

    <div class="err">
      <h3>Patient dashboard coming soon!</h3>
    </div>

    <div class="divider"></div>
    <div class="divider"></div>

    <div class="err">
      <h3>Sorry for the inconvenience.</h3>
    </div>

    <div class="divider"></div>
    <div class="divider"></div>

    <div class="err-body">
      <a mat-raised-button href="https://app.tiahealth.com" class="btn-lg"
        >www.app.tiahealth.com</a
      >
    </div>
  </div>
</div>
