/**
 * Insig Note Management APIs
 * The specification contains Insig Note APIs
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@insighealth.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Document type:  * `ADDED_DOCS` - Documents added via \"Create Document\" templates such as prescriptions and requisitions.  * `MORE_PDFS` - Documents added via the \"Upload PDF\" feature. 
 */
export type NoteDocumentUploadType = 'ADDED_DOCS' | 'MORE_PDFS';

export const NoteDocumentUploadType = {
    ADDED_DOCS: 'ADDED_DOCS' as NoteDocumentUploadType,
    MORE_PDFS: 'MORE_PDFS' as NoteDocumentUploadType
};

