// @ts-strict-ignore
import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AWSCLOUDFUNCTIONSENDPOINT } from '@insig-health/config/config';

interface PatientDemographics {
  patientFirstName: string;
  patientLastName: string;
  /** Patient's gender at birth: 0 for male, 1 for female */
  patientGender: number;
  /** Patient's birthdate formatted as: yyyy/mm/dd */
  patientBirthDate: string;
  patientHealthCard: string;
  /** Patient's phone number formatted as: xxx-xxx-xxxx */
  patientPhone: string;
}

@Injectable()
export class CHSService {
  private readonly http = inject(HttpClient);
  private getAppointmentsURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'chs/getCHSAppointments';
  private surveyCompletedURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'chs/surveyCompleted';
  private cancelAppointmentsURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'chs/cancelCHSAppointment';
  private bookAppointmentsURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'chs/bookCHSAppointment';
  private decryptCHSMessageEndpoint =
    AWSCLOUDFUNCTIONSENDPOINT + 'chs/decryptCHSMessageEndpoint';
  private validateHealthCardCHSURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'chs/validateHealthCardCHS';

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  isPatientRostered(
    doctorId: string,
    emrPatientIdOrDemographics: string | PatientDemographics
  ): Promise<{ isRostered: boolean; isFound: boolean }> {
    const postBody =
      typeof emrPatientIdOrDemographics === 'string'
        ? {
            doctorId,
            emrPatientId: emrPatientIdOrDemographics,
          }
        : {
            doctorId,
            patientDemographics: emrPatientIdOrDemographics,
          };

    return this.http
      .post<{ isRostered: boolean; isFound: boolean }>(
        AWSCLOUDFUNCTIONSENDPOINT + 'chs/isPatientRostered',
        postBody,
        { headers: this.headers }
      )
      .toPromise();
  }

  isValidOntarioHealthCard(
    doctorId: string,
    healthCardNumber: string,
    healthCardVersion?: string
  ): Promise<{ isValid: boolean }> {
    return this.http
      .post<{ isValid: boolean }>(
        AWSCLOUDFUNCTIONSENDPOINT + 'chs/isValidOntarioHealthCard',
        {
          doctorId,
          healthCardNumber,
          healthCardVersion,
        },
        { headers: this.headers }
      )
      .toPromise();
  }

  validateHealthCardCHS(doctorID, healthCardNumber, healthCardVersion): any {
    return this.http
      .post(
        this.validateHealthCardCHSURL,
        {
          doctorID,
          healthCardNumber,
          healthCardVersion,
        },
        { headers: this.headers }
      )
      .toPromise()
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  } // end func

  // TO DO function coming soon
  surveyCompleted(emrAppointmentId): any {
    return this.http
      .post(
        this.surveyCompletedURL,
        {
          emrAppointmentId,
        },
        { headers: this.headers }
      )
      .toPromise()
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  } // end func

  getCHSAppointments(userID, appointmentsDate): any {
    return this.http
      .post(
        this.getAppointmentsURL,
        {
          uid: userID,
          appointmentsDate,
        },
        { headers: this.headers }
      )
      .toPromise()
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  } // end func

  cancelCHSAppointments(userID, appointmentsDate): any {
    return this.http
      .post(
        this.cancelAppointmentsURL,
        {
          uid: userID,
          appointmentsDate,
        },
        { headers: this.headers }
      )
      .toPromise()
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  } // end sendEmail func

  bookCHSAppointments(
    userID,
    appointmentsDate,
    appointmentDuration,
    patientData,
    appointmentUrl
  ): any {
    return this.http
      .post(
        this.bookAppointmentsURL,
        {
          uid: userID,
          appointmentDateTime: appointmentsDate,
          appointmentDuration,
          patientFirstName: patientData.first,
          patientLastName: patientData.last,
          patientEmail: patientData.email,
          patientPhone: patientData.phone,
          patientHealthCard: patientData.healthCardNumber, //
          patientBirthDate: [
            patientData.year,
            patientData.month,
            patientData.day,
          ].join('/'), //
          patientGender: patientData.gender === 'Male' ? 0 : 1,
          appointmentUrl,
        },
        { headers: this.headers }
      )
      .toPromise()
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  } // end sendEmail func

  decodeCHSMessage(enc, iv): Promise<any> {
    return this.http
      .post(
        this.decryptCHSMessageEndpoint,
        {
          enc,
          iv,
        },
        { headers: this.headers }
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
} // end service1
