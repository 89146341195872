<mat-toolbar
  class="primary-color-full company-plan-toolbar"
  style="position:fixed;"
>
  <mat-toolbar-row>
    <span>{{ selectedPlan.title }} Settings</span>
    <span class="specific-spacer"></span>
    <mat-icon
      class="notranslate speicfic-icon"
      (click)="companyPlanSettingsToggle.emit(true)"
      >close</mat-icon
    >
  </mat-toolbar-row>
</mat-toolbar>
<div class="container-fluid no-breadcrumbs overflow-auto">
  <div class="row ">
    <div class="col-6 image-overlay">
      <h5>Plan Image</h5>
      <div class="container">
        <img
          class="image"
          [src]="
            selectedPlan.image
              ? selectedPlan.image
              : '/assets/images/logo/logo_short.svg'
          "
        />
        <div class="overlay bg-color-primary">
          <div class="text modeEdit" (click)="selectPlanImage()">
            <i class="material-icons">mode_edit</i>Company Plan Image
          </div>
        </div>
      </div>
      <div class="row m-4">
        <mat-form-field>
          <mat-label>Plan Title</mat-label>
          <input
            matInput
            [(ngModel)]="selectedPlan.title"
          />
        </mat-form-field>
      </div>

      <div class="row m-4">
        <h5 class="full-width">Subscribed Practitioner Groups</h5>
        <mat-chip-listbox class="full-width">
          <mat-chip-option
            *ngFor="let group of subscribedUserGroups"
            [selectable]="true"
            [removable]="true"
            [hidden]="group.groupType != 'practitioner'"
            (remove)="removeSubscribedCompany.emit(group)"
          >
            {{ group.groupName }}
            <mat-icon matChipRemove *ngIf="true" class="notranslate"
              >cancel</mat-icon
            >
          </mat-chip-option>
        </mat-chip-listbox>
      </div>

      <div class="row m-4">
        <mat-button-toggle
          class="button-animated primary-color-full col-12"
          (click)="addSubscribedUserGroup.emit('practitioner')"
        >
          Add Group
        </mat-button-toggle>
      </div>

      <div class="row m-4">
        <h5>Allow all patients to book</h5>
        <br />
        <p>
          Do you want to allow all patients to make appointments with this plan?
        </p>
        <mat-slide-toggle [(ngModel)]="selectedPlan.allPatientsAllowed">
          {{ selectedPlan.allPatientsAllowed ? 'Enabled' : 'Disabled' }}
        </mat-slide-toggle>
      </div>

      <div class="row m-4" *ngIf="!selectedPlan.allPatientsAllowed">
        <h5 class="full-width">Subscribed Patient Groups</h5>
        <mat-chip-listbox class="full-width">
          <mat-chip-option
            *ngFor="let group of subscribedUserGroups"
            [selectable]="true"
            [removable]="true"
            [hidden]="group.groupType != 'patient'"
            (remove)="removeSubscribedCompany.emit(group)"
          >
            {{ group.title }}
            <mat-icon matChipRemove *ngIf="true" class="notranslate"
              >cancel</mat-icon
            >
          </mat-chip-option>
        </mat-chip-listbox>
      </div>

      <div class="row m-4" *ngIf="!selectedPlan.allPatientsAllowed">
        <mat-button-toggle
          class="button-animated primary-color-full col-12"
          (click)="addSubscribedUserGroup.emit('patient')"
        >
          Add Group
        </mat-button-toggle>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <h5 class="col-12">Disable Fields</h5>
        <p class="col-12">
          Disallow doctors from changing specific appointment attributes
        </p>
        <table
          class="table table-hover"
          style="table-layout: fixed;word-wrap: break-word;"
        >
          <thead>
            <tr>
              <th scope="col" style="width:20%">Disable</th>
              <th scope="col" style="width:80%">Field</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let opt of disabledOptions; let i = index">
              <td>
                <mat-checkbox
                  [(ngModel)]="selectedPlan.restrictions[opt.iden]"
                ></mat-checkbox>
              </td>
              <td>
                <h4 class="service-header full-width">{{ opt.label }}</h4>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row m-4">
        <h5>Force Entire Plan Service</h5>
        <br />
        <p>
          Do you want to make doctors who subscribe to this plan service all
          appointment types within the plan?
        </p>
        <mat-slide-toggle [(ngModel)]="selectedPlan.optInAll">
          {{ selectedPlan.optInAll ? 'Enabled' : 'Disabled' }}
        </mat-slide-toggle>
      </div>

      <div class="row m-4">
        <h5>Validate Patient is Rostered</h5>
        <br />
        <p>
          Do you want to enforce that patients are rostered patients of the
          physician they are booking with (applicable to specific EMR/EHR
          systems)?
        </p>
        <mat-slide-toggle [(ngModel)]="selectedPlan.validateRosteredPatients">
          {{ selectedPlan.validateRosteredPatients ? 'Enabled' : 'Disabled' }}
        </mat-slide-toggle>
      </div>

      <div class="row m-4" *ngIf="userData?.type?.insigSuperAdmin">
        <h5>Disable Payment for Appointments</h5>
        <br />
        <p>Allow patients to make appointments without paying?</p>
        <mat-slide-toggle
          [(ngModel)]="selectedPlan.publicBilling"
          [disabled]="!userData?.type?.insigSuperAdmin"
        >
          {{ selectedPlan.publicBilling ? 'Enabled' : 'Disabled' }}
        </mat-slide-toggle>
      </div>

      <div
        class="row m-4"
        *ngIf="
          !selectedPlan.subscribedPublicPlan && userData?.type?.insigSuperAdmin
        "
      >
        <div class="w-100">
          <h6>
            {{
              userData?.type?.admin
                ? 'Please select what type of billing from the dropdown below '
                : 'Your company
            admin can add a specific public billing code'
            }}
          </h6>
        </div>
        <div class="row w-100">
          <mat-form-field>
            <mat-label>Available Public Plans</mat-label>
            <mat-select
              [(ngModel)]="subscribedPublicPlan"
              (selectionChange)="planChange($event.value)"
              [disabled]="!userData?.type?.insigSuperAdmin"
            >
              <mat-option *ngFor="let plan of publicPlans" [value]="plan">
                {{ plan.title }}- ${{ plan.price }} ({{ plan.code }})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div
        class="row m-4"
        *ngIf="
          selectedPlan.subscribedPublicPlan && userData?.type?.insigSuperAdmin
        "
      >
        <div class="w-100">
          <mat-card class="example-card">
            <mat-card-header>
              <div mat-card-avatar class="example-header-image"></div>
              <mat-card-title>{{
                selectedPlan.subscribedPublicPlan.title
              }}</mat-card-title>
              <mat-card-subtitle
                >${{
                  selectedPlan.subscribedPublicPlan.price
                }}</mat-card-subtitle
              >
            </mat-card-header>
            <mat-card-actions class="text-right">
              <button
                mat-button
                class="primary-color-full"
                (click)="removePublicPlan()"
                *ngIf="userData?.type?.insigSuperAdmin"
              >
                Remove
              </button>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
    </div>
  </div>

  <div class="row p-5">
    <div class="col-6">
      <button
        mat-raised-button
        class="float-left"
        (click)="companyPlanSettingsToggle.emit(false)"
      >
        Cancel
      </button>
    </div>

    <div class="col-6">
      <button
        mat-raised-button
        class="primary-color-full float-right"
        (click)="companyPlanSettingsToggle.emit(true)"
      >
        Save
      </button>
    </div>
  </div>
</div>
