<div *ngIf="companyManagedBankAccount">
  <div class="col-10 offset-1">
    <h4 class="text-center lineHeight2">
      Virtual Care payments and bank account information is managed by your
      clinic administrator.
    </h4>
    <h4 class="text-center">Please contact them if you have any questions.</h4>
    <div class="divider"></div>
    <hr />
    <h4 class="text-center">
      Administrators can manage the group's banking details via the 'Profile'
      tab
    </h4>
  </div>
</div>

<div *ngIf="!companyManagedBankAccount">
  <div *ngIf="loading">
    <mat-progress-spinner
      class="mx-auto mb-4 mt-4"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>

  <div class="row container" *ngIf="!loading">
    <div class="col-12">
      <div *ngIf="!connectActive">
        <h4 *ngIf="companyBank">Receiving Payments - Insig Group</h4>
        <h4 *ngIf="!companyBank">Receiving Payments - Individual</h4>
        <h6>
          Insig uses Stripe to process and send you payments from patients on
          Virtual Care.
        </h6>
        <h6 *ngIf="companyBank">
          Once setup, all Virtual Care payments for this ENTIRE Insig group will
          be deposited in the bank account you setup on Stripe.
        </h6>
        <h6 *ngIf="!companyBank">
          Once setup, all your Virtual Care payments will be deposited in the
          bank account you setup on Stripe.
        </h6>
        <div class="text-center mt-5">
          <a
            *ngIf="companyBank"
            [href]="stripeConnectUrl + '&redirect_uri=' + currentHref"
            class="stripe-connect"
            ><span>Connect with Stripe</span></a
          >
          <a
            *ngIf="!companyBank"
            [href]="
              stripeConnectUrl + '&redirect_uri=' +
              originURL +
              currentURL
            "
            class="stripe-connect"
            ><span>Connect with Stripe</span></a
          >
        </div>
      </div>
      <div *ngIf="connectActive">
        <h4>Stripe account connected!</h4>
        <h6 *ngIf="companyBank">
          Virtual Care payments for this organization are being deposited in the
          bank account you setup on Stripe.
        </h6>
        <h6 *ngIf="!companyBank">
          Your Virtual Care payments are being deposited in the bank account you
          setup on Stripe.
        </h6>
        <div class="text-center mt-5">
          <a
            href="https://dashboard.stripe.com"
            class="stripe-connect dark mx-auto mt-5"
            ><span>Open Stripe Dashboard</span></a
          >
        </div>
      </div>
    </div>
  </div>
</div>
