<div class="h-100 d-flex theme-color-primary overflow-x-hidden">
  <my-app-sidenav class="flex-shrink-0"></my-app-sidenav>

  <div class="w-100vw w-md-100 d-flex flex-column min-width-0 app-page-container overflow-hidden">
    <my-app-header class="w-100vw w-md-100"></my-app-header>
    <div class="app-content-wrapper overflow-y-auto min-height-0 flex-grow-1 w-100vw w-md-100">
      <router-outlet class="layout-router"></router-outlet>
    </div>
  </div>

  <my-app-customizer
    *ngIf="AppConfig.userType && AppConfig.userType.admin"
  ></my-app-customizer>
</div>
