// @ts-strict-ignore
import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';
import { UntypedFormGroup, Validators, FormBuilder } from '@angular/forms';

import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FamilyMemberProfile as LegacyFamilyMemberProfile } from 'insig-types/user-data';
import { FormValidatorsService } from '@insig-health/services/form-validators/form-validators.service';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';
import { FirestoreService } from '@insig-health/services/firestore/firestore.service';
import { FamilyMemberProfile, FamilyMemberService } from '@insig-health/services/family-member/family-member.service';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/config';
import { FamilyMemberRequest } from '@insig-health/api/patient-management-api';
import { CpsEsDoctor } from '@insig-health/services/cps-es/cps-es.service';

@Component({
  selector: 'members',
  templateUrl: './members.component.html',
})
export class MembersComponent implements OnInit, OnDestroy {
  private readonly patientUserDataService = inject(PatientUserDataService);
  private readonly firebaseAuthService = inject(FirebaseAuthService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly snackbar = inject(MatSnackBar);
  private readonly formValidatorsService = inject(FormValidatorsService);
  private readonly firestoreService = inject(FirestoreService);
  private readonly familyMemberService = inject(FamilyMemberService);
  public familyMembersList = [];
  public isFamilyMemberFormVisible = false;
  public familyMemberSelected = false;
  public createFamilyMemberForm: UntypedFormGroup;
  public selectedFamilyMember = {
    first: 'First Name',
    last: 'Last Name',
    phone: 'phone',
    extension: 'x',
    address: 'address',
    province: 'ON',
    postalCode: 'postal code',
    city: 'city',
  };
  public familyMembersSubscription = null;
  public editingFamilyMember: any = false;
  private authSubscription: Subscription;
  private today = new Date();
  private userID = null;
  public provinces = [];
  public thisYear = new Date().getFullYear();

  public static readonly ADD_FAMILY_MEMBER_ERROR_MESSAGE = 'Error adding patient.';
  public static readonly EDIT_FAMILY_MEMBER_ERROR_MESSAGE = 'Error updating patient.';

  ngOnInit(): void {
    this.provinces = this.patientUserDataService.getProvinces();
    this.buildCreateFamilyMemberForm();

    this.authSubscription = this.firebaseAuthService
      .onIdTokenChanged()
      .subscribe(async (user) => {
        if (user) {
          this.userID = user.uid;
          await this.loadFamilyMembers(user.uid);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.familyMembersSubscription) {
      this.familyMembersSubscription.unsubscribe();
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  handleFamilyDoctorClicked(familyDoctor: CpsEsDoctor): void {
    this.createFamilyMemberForm.get('familyDoctorFaxNumber')?.setValue(familyDoctor.faxNumber);
  }

  buildCreateFamilyMemberForm(): void {
    this.createFamilyMemberForm = this.formBuilder.group(
      {
        first: this.formBuilder.control(null, [Validators.required]),
        last: this.formBuilder.control(null, [Validators.required]),
        healthCardNumber: this.formBuilder.control(null),
        year: this.formBuilder.control(null, [
          Validators.required,
          Validators.min(1900),
          Validators.max(this.today.getFullYear()),
          Validators.minLength(4),
          Validators.maxLength(4),
        ]),
        month: this.formBuilder.control(null, [
          Validators.required,
          Validators.min(1),
          Validators.max(12),
          Validators.minLength(1),
          Validators.maxLength(2),
        ]),
        day: this.formBuilder.control(null, [
          Validators.required,
          Validators.min(1),
          Validators.max(31),
          Validators.minLength(1),
          Validators.maxLength(2),
        ]),
        gender: this.formBuilder.control(null, [Validators.required]),
        phone: this.formBuilder.control(null, [Validators.required, this.formValidatorsService.isPhoneNumberValidValidator(false)]),
        extension: this.formBuilder.control(null),
        address: this.formBuilder.control(null, [Validators.required]),
        province: this.formBuilder.control(null, [Validators.required]),
        city: this.formBuilder.control(null, [
          Validators.required,
          Validators.minLength(2),
        ]),
        postalCode: this.formBuilder.control(null, [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(7),
        ]),
        familyDoctorFullName: this.formBuilder.control(''),
        familyDoctorFaxNumber: this.formBuilder.control('', [
          this.formValidatorsService.isPhoneNumberValidValidator(true),
        ]),
      },
      {
        validators: [
          this.formValidatorsService.isMultiFieldBirthDateValid,
          this.formValidatorsService.isMultiFieldBirthDateInTheFuture,
        ],
      },
    );
  }

  checkFamilyMemberFormValid(form): boolean {
    if (form.controls['year'].value) {
      form.controls['year'].setValue(
        form.controls['year'].value.replace(/[^\d]/g, ''),
      );
    }
    if (form.controls['month'].value) {
      form.controls['month'].setValue(
        form.controls['month'].value.replace(/[^\d]/g, ''),
      );
    }
    if (form.controls['day'].value) {
      form.controls['day'].setValue(
        form.controls['day'].value.replace(/[^\d]/g, ''),
      );
    }
    let message = '';
    if (!form.controls['first'].valid) {
      message = 'Please enter a first name!';
    } else if (!form.controls['last'].valid) {
      message = 'Please enter a last name!';
    } else if (
      !form.controls['year'].valid ||
      !form.controls['month'].valid ||
      !form.controls['day'].valid ||
      this.formValidatorsService.isMultiFieldBirthDateValid(form) !== null ||
      this.formValidatorsService.isMultiFieldBirthDateInTheFuture(form) !== null
    ) {
      message = 'Please enter a valid birthdate!';
    } else if (!form.controls['city'].valid) {
      message = 'Please enter a city!';
    } else if (!form.controls['address'].valid) {
      message = 'Please enter a valid address!';
    } else if (!form.controls['province'].valid) {
      message = 'Please enter a province!';
    } else if (!form.controls['postalCode'].valid) {
      message = 'Please enter a valid postal code!';
    } else if (!form.controls['gender'].valid) {
      message = 'Please enter a gender!';
    } else if (!form.controls['phone'].valid) {
      message = 'Please enter a valid phone number!';
    } else {
      // form is valid so return true
      return true;
    }
    this.snackbar.open(message, null, { duration: 2000 });
    return false;
  }

  addFamilyMember(): void {
    this.isFamilyMemberFormVisible = true;
    this.editingFamilyMember = false;
    for (const field of Object.keys(
      this.createFamilyMemberForm.controls || {},
    )) {
      this.createFamilyMemberForm.controls[field].setValue(null);
    }
  }

  async createFamilyMember(createFamilyMemberForm: UntypedFormGroup): Promise<void> {
    if (!this.checkFamilyMemberFormValid(createFamilyMemberForm)) {
      this.snackbar.open('Please fix the errors in the form.', null, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      return;
    }

    this.snackbar.open('Please wait while we add this patient...', null, {
      duration: 4000,
    });

    try {
      await this.familyMemberService.createNewFamilyMember(this.getFamilyMemberRequestFromForm(createFamilyMemberForm));
      await this.loadFamilyMembers(this.userID);
      this.snackbar.open('Patient added!', null, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      this.isFamilyMemberFormVisible = false;
    } catch (error) {
      console.error(error);
      this.snackbar.open(MembersComponent.ADD_FAMILY_MEMBER_ERROR_MESSAGE, null, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
    }
  }

  async editFamilyMember(uid: string, familyMemberId: string, familyMemberForm: UntypedFormGroup): Promise<void> {

    if (!this.checkFamilyMemberFormValid(familyMemberForm)) {
      this.snackbar.open('Please fix the errors in the form.', null, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      return;
    }

    this.snackbar.open('Please wait while we update this patient...', null, {
      duration: 4000,
    });

    try {
      const familyMemberRequest = this.getFamilyMemberRequestFromForm(familyMemberForm);
      const familyMemberProfile = {
        ...await this.familyMemberService.getFamilyMemberProfile(uid, familyMemberId),
        ...familyMemberRequest,
        firstName: familyMemberRequest.first,
        lastName: familyMemberRequest.last,
        gender: familyMemberRequest.gender === 'Male' ? 'Male' : 'Female' as 'Male' | 'Female',
      };
      await this.familyMemberService.setFamilyMember(uid, familyMemberId, familyMemberProfile);
      await this.loadFamilyMembers(this.userID);
      this.snackbar.open('Patient saved!', null, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      this.isFamilyMemberFormVisible = false;
    } catch (error) {
      console.error(error);
      this.snackbar.open(MembersComponent.EDIT_FAMILY_MEMBER_ERROR_MESSAGE, null, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
    }
  }

  private getFamilyMemberRequestFromForm(familyMemberForm: UntypedFormGroup): FamilyMemberRequest {
    let familyMemberProfile = {} as LegacyFamilyMemberProfile & {
      id: string | null;
      extension: string | null;
      familyDoctorFaxNumber: string | null;
      familyDoctorFullName: string | null;
      day: string | null;
      month: string | null;
      year: string | null;
    };
    for (const field of Object.keys(familyMemberForm.controls || {})) {
      familyMemberProfile[field] = familyMemberForm.controls[field].value;
    }
    familyMemberProfile = this.firestoreService.removeNestedUndefined(familyMemberProfile);
    return {
      address: familyMemberProfile.address,
      city: familyMemberProfile.city,
      day: parseInt(familyMemberProfile.day, 10),
      extension: familyMemberProfile.extension ?? '',
      familyDoctorFaxNumber: familyMemberProfile.familyDoctorFaxNumber ?? '',
      familyDoctorFullName: familyMemberProfile.familyDoctorFullName ?? '',
      first: familyMemberProfile.first,
      gender: familyMemberProfile.gender,
      healthCardNumber: familyMemberProfile.healthCardNumber ?? '',
      last: familyMemberProfile.last,
      month: parseInt(familyMemberProfile.month, 10),
      phone: familyMemberProfile.phone,
      postalCode: familyMemberProfile.postalCode,
      province: familyMemberProfile.province,
      year: parseInt(familyMemberProfile.year, 10),
    };
  }

  openEditFamilyMember(member: FamilyMemberProfile): void {
    this.isFamilyMemberFormVisible = true;
    this.editingFamilyMember = member.familyMemberId;

    for (const field of Object.keys(
      this.createFamilyMemberForm.controls || {},
    )) {
      if (field === 'first') {
        this.createFamilyMemberForm.controls[field].setValue(member.firstName);
      } else if (field === 'last') {
        this.createFamilyMemberForm.controls[field].setValue(member.lastName);
      } else if (field === 'day') {
        this.createFamilyMemberForm.controls[field].setValue(member.day < 10 ? '0' + `${member.day}` : `${member.day}`);
      } else if (field === 'month') {
        this.createFamilyMemberForm.controls[field].setValue(member.month < 10 ? '0' + `${member.month}` : `${member.month}`);
      } else if (field === 'year') {
        this.createFamilyMemberForm.controls[field].setValue(`${member.year}`);
      } else {
        this.createFamilyMemberForm.controls[field].setValue(member[field]);
      }
    }
  }

  async deleteFamilyMember(member: FamilyMemberProfile): Promise<void> {
    await this.patientUserDataService.deleteFamilyMember(member.familyMemberId);
    await this.loadFamilyMembers(this.userID);
  }

  async loadFamilyMembers(patientId): Promise<void> {
    this.familyMembersList = await this.familyMemberService.getFamilyMemberProfiles(patientId);
  }

  scrollTop(): void {
    if (document.getElementById('topOfPage')) {
      document.getElementById('topOfPage').scrollIntoView(true);
    }
  }
}
