<ng-container *ngIf="!surveyError">
  <div
    *ngIf="doctorCompleting"
    class="d-flex justify-content-start align-items-center"
    [ngClass]="{ 'my-4': !compactView }"
  >
    <div class="flex d-none d-xl-block">
      <!-- spacer -->
    </div>

    <div class="flex d-flex justify-content-start justify-content-xl-center">
      <button
        (click)="previousPage()"
        [disabled]="isFirstPage"
        mat-raised-button
        class="btn-w-md"
      >
        <mat-icon class="notranslate">keyboard_arrow_left</mat-icon>
        <span>Back</span>
      </button>
      <span class="space"></span>
      <button
        (click)="nextPage()"
        mat-raised-button
        class="btn-w-md"
        [disabled]="isLastPage"
      >
        <span>Next</span>
        <mat-icon class="notranslate">keyboard_arrow_right</mat-icon>
      </button>
    </div>

    <div class="flex d-flex justify-content-end align-items-center">
      <button
        (click)="childSubmitSurvey('append')"
        mat-raised-button
        class="btn-w-md"
        color="primary"
        matTooltip="Add answers from Practitioner Questions to the end of the note"
        [matTooltipPosition]="'below'"
      >
        <span>Append</span>
      </button>
      <span class="space"></span>
      <button
        (click)="childSubmitSurvey()"
        mat-raised-button
        class="btn-w-md "
        color="primary"
        matTooltip="Recreate the note from patient and practitioner answers"
        [matTooltipPosition]="'below'"
      >
        <span>Overwrite</span>
      </button>
    </div>
  </div>

  <div id="surveyTitle"></div>
  <!-- used as scrollToTop anchor -->

  <div [ngClass]="{ 'my-4': !compactView }">
    <mat-progress-bar
      mode="determinate"
      [value]="progressBarValue"
    ></mat-progress-bar>

    <div class="box box-default">
      <div class="box-body">
        <!-- begin survey questions -->
        <div
          *ngFor="
            let pageElement of survey.pages[currentPage].elements;
            let i = index
          "
          [ngClass]="{ unanswered: pageElement.unanswered }"
        >
          <div
            *ngIf="
              pageElement.question &&
              !pageElement.demographicsOrDoctorHidden &&
              !pageElement.triggerHidden
            "
          >
            <question-types
              *ngIf="
                pageElement.question.type !== 'survey' &&
                pageElement.question.type !== 'sendCommunication'
              "
              id="{{ pageElement.id }}"
              [(question)]="pageElement.question"
              [(uploadedPDFIDs)]="uploadedPDFIDs"
              [(morePDFs)]="survey.morePDFs"
              [pageElement]="pageElement"
              [companyID]="companyID"
              [elementID]="pageElement.id"
              [(doctorCompleting)]="doctorCompleting"
              [(triggersDictionary)]="survey.triggersDictionary"
              (checkTriggersChild)="checkTriggersParent($event)"
              [patientInfo]="patientInfo"
            >
            </question-types>
            <survey-question
              *ngIf="
                pageElement.question.type === 'survey' &&
                !!pageElement.question.survId
              "
              [doctorCompleting]="doctorCompleting"
              [(quickPreview)]="quickPreview"
              [(question)]="pageElement.question"
              [patientInfo]="patientInfo"
              [elementID]="pageElement.id"
              [pageElement]="pageElement"
              [position]="i"
              [(page)]="currentPage"
              [(mainSurvey)]="survey"
              (checkLoadedChild)="checkLoadedParent($event)"
              (checkTriggersChild)="checkTriggersParent($event)"
            >
            </survey-question>
          </div>

          <div
            *ngIf="
              pageElement.paragraph &&
              !pageElement.demographicsOrDoctorHidden &&
              !pageElement.triggerHidden
            "
          >
            <span *ngIf="pageElement.paragraph.patient">
              <ckeditor
                [(config)]="patientCkConfig"
                [(ngModel)]="pageElement.paragraph.html"
                [readonly]="false"
              ></ckeditor>
            </span>
            <div *ngIf="!pageElement.paragraph.patient" [innerHTML]="pageElement.paragraph.html | safeHtml"></div>
            <div class="divider"></div>
            <div class="divider"></div>
            <hr />
          </div>

          <!-- // insig ads -->
          <!-- <div *ngIf="adIndexes.includes(i)">
            <ads></ads>
            <hr />
          </div> -->
        </div>
        <!-- end survey questions -->

        <!-- begin patient footer -->
        <div class="row" *ngIf="!loading && !doctorCompleting">
          <div class="col-6">
            <button
              (click)="previousPage()"
              [disabled]="isFirstPage"
              mat-raised-button
              class="btn-w-md float-right"
              color="primary"
            >
              <mat-icon class="notranslate">keyboard_arrow_left</mat-icon>
              <span>Back</span>
            </button>
          </div>
          <div class="col-6">
            <button
              id="next-page-launch-survey"
              (click)="isLastPage ? childSubmitSurvey() : nextPage()"
              mat-raised-button
              class="btn-w-md float-left"
              color="primary"
              [disabled]="quickPreview && isLastPage"
            >
              <span>{{ isLastPage ? 'Submit' : 'Next' }}</span>
              <mat-icon iconPositionEnd class="notranslate">keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </div>
        <!-- end patient footer -->

        <!-- begin doctor footer -->
        <ng-container *ngIf="!loading && doctorCompleting">
          <div class="w-100 d-flex justify-content-center">
            <button
              (click)="previousPage()"
              [disabled]="isFirstPage"
              mat-raised-button
              class="btn-w-md"
            >
              <mat-icon class="notranslate">keyboard_arrow_left</mat-icon>
              <span>Back</span>
            </button>
            <span class="space"></span>
            <button
              (click)="nextPage()"
              mat-raised-button
              class="btn-w-md"
              [disabled]="isLastPage"
            >
              <span>Next</span>
              <mat-icon class="notranslate">keyboard_arrow_right</mat-icon>
            </button>
          </div>

          <hr />

          <div class="w-100 d-flex justify-content-end align-items-center">
            <button
              (click)="childSubmitSurvey('append')"
              mat-raised-button
              class="btn-w-md"
              color="primary"
              matTooltip="Add answers from Practitioner Questions to the end of the note"
              [matTooltipPosition]="'below'"
            >
              <span>Append</span>
            </button>
            <span class="space"></span>
            <button
              (click)="childSubmitSurvey()"
              mat-raised-button
              class="btn-w-md "
              color="primary"
              matTooltip="Recreate the note from patient and practitioner answers"
              [matTooltipPosition]="'below'"
            >
              <span>Overwrite</span>
            </button>
          </div>
        </ng-container>
        <!-- end doctor footer -->
      </div>
    </div>

    <div *ngIf="!compactView" class="py-5"><!-- spacer --></div>
  </div>
</ng-container>

<ng-container *ngIf="surveyError">
  <h4 class="text-center mt-5 px-4">Error loading questionnaire!</h4>
  <h6 class="text-center px-4">
    The questionnaire could not be found, it may have been deleted.
  </h6>
</ng-container>
