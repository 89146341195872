import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { CalendarSchedulerViewComponent } from './calendar-scheduler-view.component';
import { CalendarSchedulerCellComponent } from './calendar-scheduler-cell.component';
import { CalendarSchedulerHeaderComponent } from './calendar-scheduler-header.component';
import { CalendarSchedulerEventComponent } from './calendar-scheduler-event.component';
import { CalendarSchedulerEventTitleComponent } from './calendar-scheduler-event-title.component';
import { CalendarSchedulerEventContentComponent } from './calendar-scheduler-event-content.component';
import { CalendarSchedulerEventActionsComponent } from './calendar-scheduler-event-actions.component';

export * from './calendar-scheduler-view.component';
export * from './formatters';
export * from './calendar-utils';

import { SchedulerConfig } from './scheduler-config';

export const SCHEDULER_CONFIG = new InjectionToken('SchedulerConfig');

export function provideAuthConfig(config: SchedulerConfig) {
  return new SchedulerConfig();
}

@NgModule({
  imports: [
    CommonModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  declarations: [
    CalendarSchedulerViewComponent,
    CalendarSchedulerCellComponent,
    CalendarSchedulerHeaderComponent,
    CalendarSchedulerEventComponent,
    CalendarSchedulerEventTitleComponent,
    CalendarSchedulerEventContentComponent,
    CalendarSchedulerEventActionsComponent,
  ],
  exports: [
    CalendarSchedulerViewComponent,
    CalendarSchedulerCellComponent,
    CalendarSchedulerHeaderComponent,
    CalendarSchedulerEventComponent,
    CalendarSchedulerEventTitleComponent,
    CalendarSchedulerEventContentComponent,
    CalendarSchedulerEventActionsComponent,
  ],
})
export class SchedulerModule {
  static forRoot(
    config?: SchedulerConfig
  ): ModuleWithProviders<SchedulerModule> {
    return {
      ngModule: SchedulerModule,
      providers: [
        { provide: SCHEDULER_CONFIG, useValue: config },
        {
          provide: SchedulerConfig,
          useFactory: provideAuthConfig,
          deps: [SCHEDULER_CONFIG],
        },
      ],
    };
  }
}
