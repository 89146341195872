// @ts-strict-ignore
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';

@Injectable()
export class DeleteSurveyService {
  async deleteSurveyFromFirestore(userId: string, surveyId: string) {
    // TODO parallelize promises
    // TODO add fallback and recovery options if one of the deletions fail

    // We need to perform a recursive deletion on the survey
    // survey -> pages -> elements

    const baseReference = firebase
      .firestore()
      .collection('userSurveys')
      .doc('surveyData')
      .collection(userId)
      .doc(surveyId);
    // First get the survey
    const surveyDocument = await baseReference.get();
    if (surveyDocument.exists) {
      // Delete all elements
      const elementReferences = await baseReference
        .collection('pages')
        .get()
        .then((pagesSnapshot) =>
          Promise.all(
            pagesSnapshot.docs
              .map((document) => document.id)
              .map((pageNumber) =>
                baseReference
                  .collection('pages')
                  .doc(pageNumber)
                  .collection('elements')
                  .get()
              )
          ).then((elementsSnapshots) =>
            elementsSnapshots
              .map((elementsSnapshot) =>
                elementsSnapshot.docs.map((document) => document.ref)
              )
              .flat(1)
          )
        );
      await Promise.all(
        elementReferences.map((reference) => reference.delete())
      );

      // Delete all pages
      const pageReferences = await baseReference
        .collection('pages')
        .get()
        .then((pagesSnapshot) =>
          pagesSnapshot.docs.map((document) => document.ref)
        );
      await Promise.all(pageReferences.map((reference) => reference.delete()));

      // Delete base data
      await baseReference.delete();
    } else {
      throw new Error(`survey not found: ${userId}/${surveyId}`);
    }
  }

  deleteSurvey(userID, surveyID) {
    firebase
      .database()
      .ref('user/surveys/' + userID + '/' + surveyID)
      .remove();
  }
} // end service
