<div>
  <div *ngIf="allowMultiple">
    <div class="form-group row">
      <div class="col-2"></div>
      <div class="col-8">
        <mat-chip-listbox class="text-center">
          <div
            class="notranslate"
            *ngFor="let item of previewQuestion.response.selectedAnswers"
          >
            <div *ngIf="!(item === undefined)">
              <span class="space space-md"></span>
              <span color="primary">Dr. {{ item }} &nbsp;</span>
              <button
                mat-mini-fab
                color="none"
                (click)="removeItem(item)"
              >
                <mat-icon class="notranslate">clear</mat-icon>
              </button>
            </div>
          </div>
        </mat-chip-listbox>
      </div>
      <div class="col-2"></div>
    </div>

    <div class="form-group row">
      <div class="col-2"></div>
      <div class="col-8">
        <mat-form-field class="full-width">
          <span matPrefix><b>Dr.</b> &nbsp;</span>
          <mat-label>Enter physician name</mat-label>
          <input
            matInput
            (keyup.enter)="
              addItem(previewQuestion.response.selectedAnswers[-1])
            "
            [(ngModel)]="previewQuestion.response.selectedAnswers[-1]"
            [matAutocomplete]="auto"
            #itemsSelect
            [formControl]="itemsCtrl"
          />
        </mat-form-field>
      </div>
      <div class="col-2"></div>
    </div>

    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        class="notranslate"
        *ngFor="let item of filteredItems | async"
        (click)="addItem(item)"
        [value]="item[0] + ' ' + item[1]"
      >
        <mat-icon class="notranslate">add_circle_outline</mat-icon> Dr.
        {{ item[0] }} {{ item[1] }}
      </mat-option>
      <mat-option
        class="notranslate"
        *ngIf="previewQuestion.response.selectedAnswers[-1] != null"
        (click)="addItem(previewQuestion.response.selectedAnswers[-1])"
        [value]="previewQuestion.response.selectedAnswers[-1]"
      >
        <mat-icon class="notranslate">add_circle_outline</mat-icon> Dr.
        {{ previewQuestion.response.selectedAnswers[-1] }}
      </mat-option>
    </mat-autocomplete>
  </div>

  <div *ngIf="!allowMultiple">
    <div class="form-group row">
      <div class="col-12"></div>
    </div>
    <!-- empty spacer -->
    <div class="form-group row">
      <div class="col-2"></div>
      <div class="col-8">
        <mat-form-field class="full-width">
          <span matPrefix><b>Dr.</b> &nbsp;</span>
          <mat-label>Enter physician name</mat-label>
          <input
            matInput
            [(ngModel)]="previewQuestion.response.selectedAnswers[0]"
            [matAutocomplete]="auto"
            #itemsSelect
            [formControl]="itemsCtrl"
          />
        </mat-form-field>
      </div>
      <div class="col-2"></div>
    </div>

    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        class="notranslate"
        *ngFor="let item of filteredItems | async"
        [value]="item[0] + ' ' + item[1]"
      >
        Dr. {{ item[0] }} {{ item[1] }}
      </mat-option>
    </mat-autocomplete>
  </div>

  <div class="text-center">
    <mat-checkbox
      [checked]="allowMultiple"
      (change)="allowMultiple = $event.checked"
      >Allow Multiple Answers</mat-checkbox
    >
  </div>
</div>
