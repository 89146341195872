// @ts-strict-ignore
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'insAppointmentType',
})
@Injectable()
export class AppointmentTypePipe implements PipeTransform {
  transform(type: string): string {
    switch (type) {
      case 'virtual':
        return 'Virtual Care';
      case 'clinic':
        return 'In Clinic';
      case 'both':
        return 'Virtual & Clinic';
      case 'default':
        return 'Default';
    }
  }
}
