// @ts-strict-ignore
import { Injectable } from '@angular/core';

@Injectable()
export class DuplicateSurveyService {
  duplicateSurvey(surveyCopy): any {
    surveyCopy.id = this.generateRandomID(32);

    const surveyMap = {};
    let newTempId: any;
    let newTriggerId: any;

    // Generate new ids
    for (const page of surveyCopy.pages) {
      for (const element of page.elements) {
        surveyMap[element.id] = {
          id: this.generateRandomID(32),
          questions: {},
        };
        if (element.question) {
          surveyMap[element.id].questions[
            element.question.id
          ] = this.generateRandomID(32);
        }
      }
    }

    for (const p in surveyCopy.pages) {
      if (surveyCopy.pages[p]) {
        for (const e in surveyCopy.pages[p].elements) {
          if (surveyCopy.pages[p].elements[e]) {
            if (surveyCopy.pages[p].elements[e].question) {
              surveyCopy.pages[p].elements[e].question.id =
                surveyMap[surveyCopy.pages[p].elements[e].id].questions[
                  surveyCopy.pages[p].elements[e].question.id
                ];
            }
            surveyCopy.pages[p].elements[e].id =
              surveyMap[surveyCopy.pages[p].elements[e].id].id;

            if (
              surveyCopy.pages[p].elements[e].pageFlow &&
              surveyCopy.pages[p].elements[e].pageFlow.parents
            ) {
              for (const rent in surveyCopy.pages[p].elements[e].pageFlow
                .parents) {
                if (
                  surveyMap[
                    surveyCopy.pages[p].elements[e].pageFlow.parents[rent]
                  ]
                ) {
                  newTempId =
                    surveyMap[
                      surveyCopy.pages[p].elements[e].pageFlow.parents[rent]
                    ].id;
                  surveyCopy.pages[p].elements[e].pageFlow.parents[
                    rent
                  ] = newTempId;
                }
              }
            }
            if (
              surveyCopy.pages[p].elements[e].pageFlow &&
              surveyCopy.pages[p].elements[e].pageFlow.children
            ) {
              for (const child in surveyCopy.pages[p].elements[e].pageFlow
                .children) {
                if (
                  surveyMap[
                    surveyCopy.pages[p].elements[e].pageFlow.children[child]
                  ]
                ) {
                  newTempId =
                    surveyMap[
                      surveyCopy.pages[p].elements[e].pageFlow.children[child]
                    ].id;
                  surveyCopy.pages[p].elements[e].pageFlow.children[
                    child
                  ] = newTempId;
                }
              }
            }
            if (
              surveyCopy.pages[p].elements[e].question &&
              surveyCopy.pages[p].elements[e].question.offeredAnswers
            ) {
              for (const o in surveyCopy.pages[p].elements[e].question
                .offeredAnswers) {
                if (
                  surveyCopy.pages[p].elements[e].question.offeredAnswers[o]
                    .pageFlow &&
                  surveyCopy.pages[p].elements[e].question.offeredAnswers[o]
                    .pageFlow.trigger
                ) {
                  for (const t in surveyCopy.pages[p].elements[e].question
                    .offeredAnswers[o].pageFlow.trigger) {
                    if (
                      surveyMap[
                        surveyCopy.pages[p].elements[e].question.offeredAnswers[
                          o
                        ].pageFlow.trigger[t]
                      ]
                    ) {
                      newTriggerId =
                        surveyMap[
                          surveyCopy.pages[p].elements[e].question
                            .offeredAnswers[o].pageFlow.trigger[t]
                        ].id;
                      surveyCopy.pages[p].elements[e].question.offeredAnswers[
                        o
                      ].pageFlow.trigger[t] = newTriggerId;
                    }
                  }
                }
              }
            }
          }
        }
      } // end for surveyCopy pages
    } // end for copy pages
    return surveyCopy;
  } // end duplicate survey function

  generateRandomID(length) {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
} // end service
