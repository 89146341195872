import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GcpIpLoginWidgetComponent } from './gcp-ip-login-widget.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UiComponentsModule } from '@insig-health/components';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    UiComponentsModule,
  ],
  declarations: [
    GcpIpLoginWidgetComponent,
  ],
  exports: [
    GcpIpLoginWidgetComponent,
  ],
})
export class GcpIpModule {}
