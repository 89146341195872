<mat-card>
  <mat-card-title>Download Survey Data</mat-card-title>
  <mat-card-content>
    <div class="row m-0">
      <form [formGroup]="form" class="full-width">
        <mat-radio-group
          aria-label="Select an option"
          class="text-center"
          formControlName="withPatientData"
        >
          <mat-radio-button value="with" class="m-1"
            >With Patient Data</mat-radio-button
          >
          <mat-radio-button value="without" class="m-1"
            >Without Patient Data (Anonymized)</mat-radio-button
          >
        </mat-radio-group>
      </form>
    </div>
  </mat-card-content>
  <div class="divider"></div>
  <div class="divider"></div>
  <mat-card-actions>
    <button mat-raised-button (click)="dialogRef.close(false)">Cancel</button>
    <button
      mat-raised-button
      color="primary"
      class="float-right"
      (click)="downloadSurveyData()"
    >
      Download
    </button>
  </mat-card-actions>
</mat-card>
