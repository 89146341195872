<div class="checkboxGrid">
  <ng-container *ngFor="let answer of question.offeredAnswers; let i = index">
    <mat-checkbox
      class="checkbox"
      [id]="'checkbox-' + i"
      [aria-labelledby]="'checkbox-label' + i"
      [(ngModel)]="question.selectedAnswers[i]"
      (ngModelChange)="setSelectedAnswers($event, answer, i)"
    >
    </mat-checkbox>
    <label
      class="pl-2 d-flex align-items-center justify-content-start"
      [for]="'checkbox-' + i"
      [id]="'checkbox-label' + i"
      (click)="
        question.selectedAnswers[i] = !question.selectedAnswers[i];
        setSelectedAnswers(question.selectedAnswers[i], answer, i)
      "
    >
      <div>{{ answer.value }}</div>
    </label>
  </ng-container>

  <ng-container *ngIf="question.otherAnswer">
    <div class="d-flex justify-content-start align-items-center">
      <mat-checkbox
        class="checkbox mb-4"
        [id]="'checkbox-other'"
        [aria-labelledby]="'checkbox-label-other'"
        [(ngModel)]="question.isOtherAnswer"
      >
      </mat-checkbox>
    </div>
    <div class="d-flex justify-content-start align-items-center">
      <label
        class="pl-2 mb-4 d-flex align-items-center justify-content-start"
        [for]="'checkbox-other'"
        [id]="'checkbox-label-other'"
        (click)="question.isOtherAnswer = !question.isOtherAnswer"
      >
        <div>Other:</div>
      </label>
      <mat-form-field class="w-75 ml-3">
        <textarea
          rows="1"
          matInput
          type="text"
          [(ngModel)]="question.response.other"
          [disabled]="!question.isOtherAnswer"
        ></textarea>
      </mat-form-field>
    </div>
  </ng-container>
</div>
