import { Injectable } from '@angular/core';

export const enum UserAgent {
  SAFARI = 'Safari',
  NOT_SAFARI = 'Not Safari',
}

@Injectable({
  providedIn: 'root',
})
export class UserAgentService {
  getUserAgent(): UserAgent {
    const isSafari = window.safari !== undefined;
    if (isSafari) {
      return UserAgent.SAFARI;
    } else {
      return UserAgent.NOT_SAFARI;
    }
  }
}
