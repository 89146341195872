import { Component, inject } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'confirm-preview-dialog',
  templateUrl: './confirm-preview-dialog.component.html',
})
export class ConfirmPreviewDialogComponent {
  public readonly dialogRef = inject<MatDialogRef<ConfirmPreviewDialogComponent>>(MatDialogRef<ConfirmPreviewDialogComponent>);
  public surveyName = null;
}
