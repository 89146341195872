<div
  class="p-4"
  (mouseenter)="setHoverVariable(true)"
  (mouseleave)="setHoverVariable(false)"
>
  <div class="m-0">
    <button
      mat-icon-button
      class="primary-color-text float-right"
      (click)="deleteBtnClicked.emit()"
      *ngIf="!newService"
    >
      <mat-icon class="notranslate">delete </mat-icon>
    </button>

    <button
      mat-button
      class="primary-color-text"
      (click)="backBtnClicked.emit()"
      *ngIf="!newService"
    >
      <mat-icon class="notranslate">chevron_left</mat-icon>
      Back
    </button>

    <button
      mat-raised-button
      class="primary-color-text m-4"
      (click)="cancelBtnClicked.emit()"
      *ngIf="newService"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      class="primary-color-full ml-4"
      (click)="saveBtnClicked.emit(selectedService)"
    >
      Save
    </button>
  </div>

  <div class="divider divider-grey"></div>

  <div class="row m-0">
    <div class="col-4"></div>

    <div class="pl-0 col-8 staff-details-top-right-col">
      <ul class="stacked-list staff-details-list">
        <li class="details-list-item">
          <input
            id="staff_name"
            type="text"
            class="yellow_hover blue_focus full-width"
            placeholder="Service Name"
            [ngClass]="{ grey_hover: hoverOver }"
            [attr.disabled]="!newService ? newService : null"
            [formControl]="itemsCtrl"
            [matAutocomplete]="servicesAuto"
          />

          <mat-autocomplete #servicesAuto="matAutocomplete">
            <mat-option
              *ngFor="let service of filteredItems | async"
              [value]="service.data.label"
            >
              {{ service.data.label }}
            </mat-option>
          </mat-autocomplete>
        </li>

        <li class="details-list-item">
          <textarea
            style="height: 75px; overflow: hidden; word-wrap: break-word; resize: none;"
            [(ngModel)]="selectedService?.data.desc"
            class="yellow_hover blue_focus full-width"
            placeholder="Description"
            [ngClass]="{ grey_hover: hoverOver }"
          ></textarea>
        </li>
      </ul>
    </div>
  </div>
  <div class="row m-0">
    <ul class="stacked-list staff-details-list-secondary col-12">
      <li
        class="details-list-item"
        *ngIf="!!selectedService.data.overridePublicBilling || !publicBilling"
      >
        <label class="col-4 staff-details-left-col mb-0"
          >Service Cost (minimum $5)</label
        >
        <input
          id="staff_OfficeNumber"
          [disabled]="companyPlanRestrictions?.price"
          (blur)="setMinPrice.emit(selectedService.data)"
          type="number"
          class="staff-details-right-col col-sm-6 yellow_hover blue_focus full-width"
          placeholder="Cost"
          [(ngModel)]="selectedService.data.price"
          [ngClass]="{ grey_hover: hoverOver }"
        />
      </li>
      <!-- [disabled]="companyPlan" -->

      <div class="clear_all" *ngIf="companyPlanAdmin"></div>
      <li class="details-list-item" *ngIf="companyPlanAdmin">
        <label class="col-4 staff-details-left-col mb-0"
          >Cover Amount ($)</label
        >
        <input
          id="staff_LoginId"
          class="staff-details-right-col col-sm-6 yellow_hover blue_focus full-width"
          type="number"
          placeholder="Cover Amount"
          [ngModel]="selectedService.data.coverAmount"
          (ngModelChange)="coverChange($event, 'amount')"
          [ngClass]="{ grey_hover: hoverOver }"
        />
      </li>

      <div class="clear_all" *ngIf="companyPlanAdmin"></div>
      <li class="details-list-item" *ngIf="companyPlanAdmin">
        <label class="col-4 staff-details-left-col mb-0"
          >Cover Percentage (%)</label
        >
        <input
          id="staff_LoginId"
          class="staff-details-right-col col-sm-6 yellow_hover blue_focus full-width"
          type="number"
          placeholder="Cover Percentage"
          [ngModel]="selectedService.data.coverPercent"
          (ngModelChange)="coverChange($event, 'percent')"
          [ngClass]="{ grey_hover: hoverOver }"
        />
      </li>

      <div class="clear_all"></div>
      <li class="details-list-item">
        <label class="col-4 staff-details-left-col mb-0"
          >Service Time (minutes)</label
        >
        <input
          id="staff_LoginId"
          class="staff-details-right-col col-sm-6 yellow_hover blue_focus full-width"
          type="number"
          min="1"
          placeholder="Service Time"
          [(ngModel)]="selectedService.data.duration"
          [ngClass]="{ grey_hover: hoverOver }"
        />
      </li>

      <div class="clear_all"></div>
      <li class="details-list-item">
        <label class="col-4 staff-details-left-col"
          >Buffer Time (minutes)</label
        >

        <input
          id="staff_AltEmail"
          class="staff-details-right-col col-sm-6 yellow_hover blue_focus full-width"
          type="number"
          placeholder="Buffer Time"
          [(ngModel)]="selectedService.data.buffer"
          [ngClass]="{ grey_hover: hoverOver }"
        />
      </li>

      <div class="clear_all"></div>

      <li
        class="details-list-item"
        *ngIf="!companyPlan && !selectedService.planID"
      >
        <label class="col-4 staff-details-left-col">Category</label>

        <mat-select
          #catSelect
          placeholder="Add a category"
          class="staff-details-right-col col-sm-6 yellow_hover blue_focus full-width my-3"
          [ngClass]="{ grey_hover: hoverOver }"
        >
          <mat-option
            *ngFor="let categoryOption of existingCategoriesArray"
            [value]="categoryOption.catID"
            (click)="
              addCategory(selectedService.data.category, categoryOption);
              catSelect.value = null
            "
          >
            {{ categoryOption.data.name }}
          </mat-option>
        </mat-select>
      </li>

      <div class="row" *ngIf="!companyPlan && !selectedService.planID">
        <div class="col-6 offset-4">
          <mat-chip-listbox>
            <mat-chip-option
              *ngFor="let category of selectedService.data.category"
              selected="true"
            >
              {{ category.name }}
              <mat-icon
                matChipRemove
                (click)="
                  removeCategory(selectedService.data.category, category.catID)
                "
                >cancel</mat-icon
              >
            </mat-chip-option>
          </mat-chip-listbox>
          <br />
        </div>
      </div>

      <li
        class="details-list-item mt-2"
        [hidden]="companyPlanRestrictions?.media"
      >
        <label class="col-4 staff-details-left-col">Appointment Medium</label>

        <span class="space"></span>

        <mat-slide-toggle [(ngModel)]="selectedService.data.video">
          Video
        </mat-slide-toggle>

        <span class="space"></span>

        <mat-slide-toggle [(ngModel)]="selectedService.data.phone">
          Phone
        </mat-slide-toggle>
      </li>

      <li
        class="details-list-item mt-2"
        [hidden]="companyPlanRestrictions?.media"
      >
        <label class="col-4 staff-details-left-col">&nbsp;</label>

        <span class="space"></span>

        <mat-slide-toggle [(ngModel)]="selectedService.data.messaging">
          Messaging
        </mat-slide-toggle>

        <span class="space"></span>

        <mat-slide-toggle
          [(ngModel)]="selectedService.data.inPerson"
          *ngIf="!this.companyPlan"
        >
          In-Clinic
        </mat-slide-toggle>
      </li>

      <div class="divider"></div>

      <li class="details-list-item" *ngIf="!this.companyPlan">
        <label class="col-4 staff-details-left-col">&nbsp;</label>
        <mat-checkbox [(ngModel)]="selectedService.data.freeInClinic"
          >Don't Charge for In-Clinic bookings</mat-checkbox
        >
      </li>

      <div class="clear_all" *ngIf="publicBilling"></div>

      <li class="details-list-item" *ngIf="publicBilling">
        <label class="col-4 staff-details-left-col">&nbsp;</label>
        <mat-checkbox
          [ngModel]="selectedService.data.overridePublicBilling"
          (ngModelChange)="overridePublicBillingClicked($event)"
        >
          Charge for this Appointment
        </mat-checkbox>
      </li>

      <div
        class="clear_all"
        *ngIf="!!userData?.type?.insigSuperAdmin && !publicBilling"
      ></div>

      <li
        class="details-list-item"
        *ngIf="!!userData?.type?.insigSuperAdmin && !publicBilling"
      >
        <label class="col-4 staff-details-left-col">&nbsp;</label>
        <mat-checkbox
          [ngModel]="selectedService.data.overridePaidAppointment"
          (ngModelChange)="overridePaidAppointmentClicked($event)"
        >
          Make appointment free
        </mat-checkbox>
      </li>

      <!-- patientSubscriptions -->

      <!-- <div class="clear_all" *ngIf="!this.companyPlan && patientSubscriptions"></div>

      <li  class="details-list-item" *ngIf="!this.companyPlan && patientSubscriptions">
        <label class="col-4 staff-details-left-col">&nbsp;</label>
        <mat-checkbox [ngModel]="selectedService.data.patientSubscriptions"
          (ngModelChange)="patientSubscriptionsClicked($event)">Covered by Patient Subscription</mat-checkbox>

      </li> -->

      <div class="divider" *ngIf="!this.companyPlan"></div>

      <!-- <li  class="details-list-item">
        <label class="col-4 staff-details-left-col">Private Service</label>
        <mat-slide-toggle [checked]="selectedService.data.private"></mat-slide-toggle>
      </li> -->

      <li class="details-list-item" *ngIf="!this.companyPlan">
        <label class="col-4 staff-details-left-col"
          >Default Service/Fee Code</label
        >
        <input
          [disabled]="companyPlanRestrictions?.serviceCode"
          class="staff-details-right-col col-sm-6 yellow_hover blue_focus full-width"
          type="text"
          placeholder="Enter Service/Fee Code"
          [(ngModel)]="selectedService.data.serviceCode"
          [ngClass]="{ grey_hover: hoverOver }"
        />
      </li>

      <li class="details-list-item" *ngIf="!this.companyPlan">
        <label class="col-4 staff-details-left-col"
          >Default Diagnostic Code</label
        >
        <input
          [disabled]="companyPlanRestrictions?.diagnosticCode"
          class="staff-details-right-col col-sm-6 yellow_hover blue_focus full-width"
          type="text"
          placeholder="Enter Diagnostic Code"
          [(ngModel)]="selectedService.data.diagnosticCode"
          [ngClass]="{ grey_hover: hoverOver }"
        />
      </li>

      <div class="divider" *ngIf="!this.companyPlan"></div>

      <li class="details-list-item">
        <label class="col-4 staff-details-left-col">Questionnaire</label>
        <button
          mat-raised-button
          [disabled]="companyPlanRestrictions?.questionnaire"
          class="primary-color-text"
          (click)="selectSurvey.emit()"
        >
          {{
            selectedService.data.survey
              ? selectedService.data.survey.name
              : 'None'
          }}
        </button>
      </li>

      <div class="clear_all"></div>
      <div class="staff-divider-for-details"></div>
      <div class="clear_all"></div>

      <li class="details-list-item" *ngIf="!newService && !companyPlanAdmin">
        <label class="col-4 staff-details-left-col">Booking URL</label>

        <div class="col-5 p-0">
          <input
            readonly
            class="new-gray-btn"
            type="text"
            placeholder="URL"
            #serviceURL
            [(ngModel)]="selectedServiceURL"
          />
        </div>

        <div class="col-3 p-0">
          <button
            mat-raised-button
            color="primary"
            [ngxClipboard]="serviceURL"
            (cbOnSuccess)="copiedURLSuccess.emit()"
          >
            Copy
          </button>
        </div>
      </li>
    </ul>
  </div>
</div>
