// @ts-strict-ignore
import { Component, OnInit, inject } from '@angular/core';

import { Apollo, gql } from 'apollo-angular';

import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';

import { ViewNoteDialogComponent } from '../view-note-dialog/view-note-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'select-note-dialog',
  templateUrl: './select-note-dialog.component.html',
  providers: [],
})
export class SelectNoteDialogComponent implements OnInit {
  public readonly dialogRef = inject<MatDialogRef<SelectNoteDialogComponent>>(MatDialogRef<SelectNoteDialogComponent>);
  private readonly snackbar = inject(MatSnackBar);
  public readonly dialog = inject(MatDialog);
  private readonly apollo = inject(Apollo);
  // graphql queries
  private companyDataQuery = gql`
    query CompanyData($companyID: ID!, $token: ID) {
      getCompanyData(cid: $companyID, token: $token) {
        id
        branding
      }
    }
  `;

  //

  public notes = [];
  public apptID = null;
  public patient = null;
  public caseNotes = [];
  public patientView = true;
  public linkID = null;
  public appointmentType = null;
  private companyData: any = {};
  private cid = null;
  public allDocs = [];
  public cleanPdfUrls = [];

  async ngOnInit() {
    this.sortNotesToRightGroup();

    // load branding image if patient downloading docs
    if (this.linkID) {
      this.cid = this.linkID.split('-')[0];
    }
    if (this.cid) {
      this.companyData = await this.getCompanyData(this.cid);
      if (this.companyData.branding) {
        const companyData = this.companyData;
        this.toDataUrl(companyData.branding, (myBase64) => {
          companyData.branding = myBase64; // myBase64 is the base64 string
        });
      }
      console.log(this.companyData);
    }
  }

  toDataUrl(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  getCompanyData(companyID: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const companyDataQuery: any = await this.apollo
          .query({
            query: this.companyDataQuery,
            variables: {
              companyID,
              token: undefined,
            },
          })
          .toPromise();
        const companyData = companyDataQuery.data.getCompanyData;
        resolve(companyData);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  sortNotesToRightGroup() {
    this.caseNotes = [];
    this.notes.forEach((note) => {
      const noteApptId = note.apptId || note.apptID;
      if (noteApptId && this.apptID && (noteApptId === this.apptID)) {
        this.caseNotes.push(note);
        if (note.addedDocs) {
          this.allDocs = this.allDocs.concat(note.addedDocs);
        }

        if (note.cleanPdfUrls) {
          this.cleanPdfUrls = note.cleanPdfUrls;
        }
      }
    });
  }

  openDocument(doc) {
    console.log(doc);
    if (doc.url) {
      window.open(doc.url);
    } else {
      this.generatePDF(doc);
    }
  }

  generatePDF(doc) {
    this.snackbar.open('Downloading document...', null, { duration: 4000 });
    let tempHTML = doc.html;
    tempHTML = tempHTML.replace(
      new RegExp('<img', 'g'),
      '<img style="max-width:100px;max-height:100%;"'
    );
    tempHTML = tempHTML.replace(
      new RegExp('<span style="font-size:1px">.</span>', 'g'),
      `<img src="${this.companyData.branding}" style="max-width: 100px;max-height: 50px !important;">`
    );

    const opt = {
      margin: 0.5,
      filename: doc.name + '.pdf',
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    html2pdf()
      .from(tempHTML)
      .set(opt)
      .save();
  }

  openNotePatient(note) {
    console.log(note);
    const url = `/notes/patient-note/${note.nid || note.caseID}/${this.linkID}`;
    window.open(url);
  }

  editNote(note) {
    const url = `/notes/edit-note/incomplete/${note.patientSid ||
      note.patientSID ||
      note.patientID}/${note.nid || note.caseID}-${note.companyID ||
      (note.companyData || {}).id ||
      note.doctorData.company}`;
    const height = window.screen.height;
    const width = window.screen.width * 0.7;
    // this opens popup
    window.open(
      url,
      'Insig-Edit-Note',
      'height=' + height + ',width=' + width + '150'
    );
  }

  viewNote(note) {
    this.dialog.open(ViewNoteDialogComponent, { width: '1000px', height: '1000px', minWidth: '1000px', minHeight: '1000px', data: note });
  }
}
