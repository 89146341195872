import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialComponentsModule } from '../../material-components.module';
import { PipesModule } from 'insig-app/pipes/pipes.module';
import { InsigNavigationComponent } from './secondary-nav/insig-navigation.component';
import { InsigSidenavMenuComponent } from './sidenav-menu/insig-sidenav-menu.component';
import { InsigBottomSheetComponent } from './bottom-sheet/insig-bottom-sheet.component';

@NgModule({
  imports: [
    MaterialComponentsModule,
    CommonModule,
    PipesModule,
  ],
  exports: [
    InsigNavigationComponent,
    InsigSidenavMenuComponent,
    InsigBottomSheetComponent,
  ],
  declarations: [
    InsigNavigationComponent,
    InsigSidenavMenuComponent,
    InsigBottomSheetComponent,
  ]
})
export class InsigNavigationModule { }
