import { Injectable, inject } from '@angular/core';
import { AppointmentPatientResponse, PatientInfoNonMedicalRequest, PatientManagementService, SuccessResponse } from '@insig-health/api/patient-management-api';
import { PatientResponse } from '@insig-health/api/patient-management-api/model/patientResponse';
import { firstValueFrom, lastValueFrom, map, mergeWith, Observable, of, shareReplay, Subject, switchMap } from 'rxjs';
import { RegexService } from '../regex/regex.service';
import { GcpIpAuthService } from '../../../gcp-ip/src/gcp-ip-auth.service';

export interface PatientProfileUpdateRequest {
  firstName?: string;
  lastName?: string;
  fullName?: string;
  day?: number;
  month?: number;
  year?: number;
  birthdate?: string;
  gender?: 'Male' | 'Female';
  email?: string;
  phone?: string;
  extension?: string;
  healthCardNumber?: string;
  address?: string;
  city?: string;
  province?: string;
  country?: string;
  postalCode?: string;
  terms?: boolean;
  lastAcceptedTermsOfUseVersion?: string;
  pharmacyName?: string;
  pharmacyFax?: string;
  familyDoctorFirstName?: string;
  familyDoctorLastName?: string;
  familyDoctorFullName?: string;
  familyDoctorFaxNumber?: string;
  prefersSendNoteToFamilyDoctor?: boolean;
}

export interface PatientProfile {
  uid: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  day?: number;
  month?: number;
  year?: number;
  birthdate: string;
  gender: 'Male' | 'Female';
  email: string;
  phone: string;
  extension?: string;
  healthCardNumber: string;
  address: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
  pharmacyName: string;
  pharmacyFax: string;
  familyDoctorFirstName?: string;
  familyDoctorLastName?: string;
  familyDoctorFullName?: string;
  familyDoctorFaxNumber?: string;
  prefersSendNoteToFamilyDoctor?: boolean;
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
}

@Injectable({
  providedIn: 'root',
})
export class PatientProfileService {
  private readonly gcpIpAuthService = inject(GcpIpAuthService);
  private readonly patientManagementService = inject(PatientManagementService);
  private accountHolderProfile$ = new Subject<PatientProfile | undefined>();

  private currentUserUid$ = this.gcpIpAuthService.getAuthStateChanged().pipe(
    map((user) => user?.uid),
  );
  private accountHolderProfileUid$ = this.accountHolderProfile$.pipe(
    map((patientProfile) => patientProfile?.uid),
  );
  private currentUserPatientProfile$ = this.currentUserUid$.pipe(mergeWith(this.accountHolderProfileUid$)).pipe(
    map((uid) => uid ? this.getPatientResponse(uid) : undefined),
    switchMap((patientResponsePromise) => !patientResponsePromise ? of(undefined) : patientResponsePromise),
    map((patientResponse) =>  patientResponse ? this.getPatientProfileFromPatientResponse(patientResponse) : undefined),
    shareReplay(1),
  );

  async setCurrentUserPatientProfile(patientProfile: PatientProfileUpdateRequest): Promise<SuccessResponse> {
    const currentUserPatientProfile = await firstValueFrom(this.getCurrentUserPatientProfile());

    if (!currentUserPatientProfile) {
      throw new Error('No patient profile found');
    }

    const patientInfoNonMedicalRequest = this.getPatientInfoNonMedicalRequestFromPatientProfileUpdateRequest(patientProfile);
    const response = await lastValueFrom(this.patientManagementService.updatePatient(currentUserPatientProfile.uid, patientInfoNonMedicalRequest));

    const patientResponse = await this.getPatientResponse(currentUserPatientProfile.uid);
    const updatedPatientProfile = this.getPatientProfileFromPatientResponse(patientResponse);
    this.accountHolderProfile$.next(updatedPatientProfile);

    return response;
  }

  getPatientResponse(patientUid: string): Promise<PatientResponse> {
    return lastValueFrom(this.patientManagementService.onePatient(patientUid));
  }

  getCurrentUserPatientProfile(): Observable<PatientProfile | undefined> {
    return this.currentUserPatientProfile$;
  }

  async getPartialPatientProfile(patientUid: string): Promise<Partial<PatientProfile>> {
    const patientResponse = await this.getPatientResponse(patientUid);
    return {
      uid: patientResponse.uid,
      firstName: patientResponse.first ?? '',
      lastName: patientResponse.last ?? '',
      fullName: this.getFullName(patientResponse),
      email: patientResponse.email ?? '',
      phone: patientResponse.phone ?? '',
    }
  }

  async getPatientByAppointmentId(companyId: string, appointmentId: string): Promise<PatientProfile> {
    const appointmentPatientResponse = await lastValueFrom(this.patientManagementService.getPatientByAppointmentId(companyId, appointmentId));
    return this.getPatientProfileFromAppointmentPatientResponse(appointmentPatientResponse);
  }

  getBirthdayFromYearMonthDay(year: number, month: number, day: number): string {
    const birthMonth = month < 10 ? `0${month}` : `${month}`;
    const birthDay = day < 10 ? `0${day}` : `${day}`;
    return `${year}-${birthMonth}-${birthDay}`;
  }

  getPatientInfoNonMedicalRequestFromPatientProfileUpdateRequest(patientProfile: PatientProfileUpdateRequest): PatientInfoNonMedicalRequest {
    if (patientProfile.healthCardNumber) {
      patientProfile.healthCardNumber = patientProfile.healthCardNumber.replace(RegexService.WHITESPACE_REGEX, '');
      patientProfile.healthCardNumber = patientProfile.healthCardNumber.replace(RegexService.DASH_REGEX, '');
    }

    return {
      first: patientProfile.firstName,
      last: patientProfile.lastName,
      birthday: patientProfile.birthdate,
      email: patientProfile.email,
      gender: patientProfile.gender,
      address: patientProfile.address,
      city: patientProfile.city,
      province: patientProfile.province,
      country: patientProfile.country,
      postalCode: patientProfile.postalCode,
      healthCardNumber: patientProfile.healthCardNumber,
      healthCardProvince: patientProfile.province,
      phone: patientProfile.phone,
      pharmacyName: patientProfile.pharmacyName,
      pharmacyFax: patientProfile.pharmacyFax,
      extension: patientProfile.extension,
      terms: patientProfile.terms,
      lastAcceptedTermsOfUseVersion: patientProfile.lastAcceptedTermsOfUseVersion,
      familyDoctorFullName: patientProfile.familyDoctorFullName,
      familyDoctorFirstName: patientProfile.familyDoctorFirstName,
      familyDoctorLastName: patientProfile.familyDoctorLastName,
      familyDoctorFaxNumber: patientProfile.familyDoctorFaxNumber,
      requestSendNoteToFamilyDoctor: patientProfile.prefersSendNoteToFamilyDoctor,
    };
  }

  getPatientProfileFromAppointmentPatientResponse(appointmentPatientResponse: AppointmentPatientResponse): PatientProfile {
    if (appointmentPatientResponse.patientPersonalDetailResponse.gender !== 'Male' && appointmentPatientResponse.patientPersonalDetailResponse.gender !== 'Female') {
      throw new Error('Incorrect gender schema from response');
    }

    return {
      uid: appointmentPatientResponse.uid,
      firstName: appointmentPatientResponse.patientPersonalDetailResponse.firstName,
      lastName: appointmentPatientResponse.patientPersonalDetailResponse.lastName,
      fullName: `${appointmentPatientResponse.patientPersonalDetailResponse.firstName} ${appointmentPatientResponse.patientPersonalDetailResponse.lastName}`,
      birthdate: appointmentPatientResponse.patientPersonalDetailResponse.birthday,
      gender: appointmentPatientResponse.patientPersonalDetailResponse.gender,
      email: appointmentPatientResponse.patientContactDetailResponse.email,
      phone: appointmentPatientResponse.patientContactDetailResponse.phoneNumber,
      extension: appointmentPatientResponse.patientContactDetailResponse.extension ?? '',
      healthCardNumber: appointmentPatientResponse.patientMedicalDetailResponse.healthCardNumber ?? '',
      address: appointmentPatientResponse.patientContactDetailResponse.patientAddressDetailResponse?.street ?? '',
      city: appointmentPatientResponse.patientContactDetailResponse.patientAddressDetailResponse?.city ?? '',
      province: appointmentPatientResponse.patientContactDetailResponse.patientAddressDetailResponse?.province ?? '',
      country: appointmentPatientResponse.patientContactDetailResponse.patientAddressDetailResponse?.country ?? '',
      postalCode: appointmentPatientResponse.patientContactDetailResponse.patientAddressDetailResponse?.postalCode ?? '',
      pharmacyName: appointmentPatientResponse.patientMedicalDetailResponse.patientPharmacyDetailResponse.pharmacyName,
      pharmacyFax: appointmentPatientResponse.patientMedicalDetailResponse.patientPharmacyDetailResponse.pharmacyFax,
      familyDoctorFullName: appointmentPatientResponse.patientFamilyDoctorResponse?.familyDoctorFullName ?? '',
      familyDoctorFirstName: appointmentPatientResponse.patientFamilyDoctorResponse?.familyDoctorFirstName ?? '',
      familyDoctorLastName: appointmentPatientResponse.patientFamilyDoctorResponse?.familyDoctorLastName ?? '',
      familyDoctorFaxNumber: appointmentPatientResponse.patientFamilyDoctorResponse?.familyDoctorFaxNumber ?? '',
      prefersSendNoteToFamilyDoctor: appointmentPatientResponse.patientFamilyDoctorResponse?.requestSendNoteToFamilyDoctor ?? undefined,
    };
  }

  getPatientProfileFromPatientResponse(patientResponse: PatientResponse): PatientProfile {
    if (patientResponse.uid === undefined) {
      throw new Error('Patient profile from response does not contain a uid');
    }

    if (patientResponse.gender !== 'Male' && patientResponse.gender !== 'Female') {
      throw new Error('Incorrect gender schema from response');
    }

    if (patientResponse.birthday === undefined) {
      throw new Error(`No birthday defined for ${patientResponse.uid}`);
    }

    const [year, month, day] = patientResponse.birthday.split('-').map((intString) => parseInt(intString, 10));

    return {
      uid: patientResponse.uid,
      firstName: patientResponse.first ?? '',
      lastName: patientResponse.last ?? '',
      fullName: this.getFullName(patientResponse),
      day,
      month,
      year,
      birthdate: patientResponse.birthday ?? '',
      gender: patientResponse.gender,
      email: patientResponse.email ?? '',
      phone: patientResponse.phone ?? '',
      extension: patientResponse.extension ?? '',
      healthCardNumber: patientResponse.healthCardNumber ?? '',
      address: patientResponse.address ?? '',
      city: patientResponse.city ?? '',
      province: patientResponse.province ?? '',
      country: patientResponse.country ?? '',
      postalCode: patientResponse.postalCode ?? '',
      pharmacyName: patientResponse.pharmaName ?? '',
      pharmacyFax: patientResponse.pharmaFax ?? '',
      familyDoctorFullName: patientResponse.familyDoctorFullName ?? '',
      familyDoctorFirstName: patientResponse.familyDoctorFirstName ?? '',
      familyDoctorLastName: patientResponse.familyDoctorLastName ?? '',
      familyDoctorFaxNumber: patientResponse.familyDoctorFaxNumber ?? '',
      prefersSendNoteToFamilyDoctor: patientResponse.requestSendNoteToFamilyDoctor ?? undefined,
    };
  }

  private getFullName(patientResponse: PatientResponse): string {
    const { first, last } = patientResponse;
    if (first === undefined && last === undefined) {
      return '';
    }
    if (first === undefined && last !== undefined) {
      return last;
    }
    if (first !== undefined && last === undefined) {
      return first;
    }
    return `${first} ${last}`;
  }

  isGenderType(gender: string): gender is 'Male' | 'Female' {
    return gender === 'Male' || gender === 'Female';
  }

  async isPatientProfileComplete(patientUid: string): Promise<boolean> {
    const patientResponse = await this.getPatientResponse(patientUid);
    if (patientResponse.birthday === null) {
      return false;
    }
    if (patientResponse.address === null) {
      return false;
    }
    if (patientResponse.gender === null) {
      return false;
    }
    return true;
  }
}
