import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'insig-ui-form-error',
  templateUrl: './insig-form-error.component.html',
})
export class InsigFormErrorComponent {
  @Input() formControlForErrorMessage: AbstractControl | undefined;
  @Input() errorText = '';
  @Input() errorType = '';

  public hasFormControlBeenInteractedWith(formControl: AbstractControl | undefined): boolean {
    if (formControl) {
      return formControl.invalid && (formControl.dirty || formControl.touched);
    } else {
      return false;
    }
  }

  public doesErrorExistOnFormControl(formControl: AbstractControl | undefined, errorType: string): boolean {
    return formControl?.errors?.[errorType] ?? false;
  }
}
