<mat-card>
  <mat-card-title class="h4 text-center my-4">Please read the following Terms & Conditions</mat-card-title>

  <mat-card-content mat-dialog-content>
    <div class="px-2">
        <insig-health-patient-terms-of-use></insig-health-patient-terms-of-use>
    </div>
  </mat-card-content>

  <mat-card-actions class="d-flex justify-content-between">
    <button mat-raised-button (click)="dialogRef.close(false)">Cancel</button>
    <button mat-raised-button color="primary" (click)="dialogRef.close(true)">
      Accept
    </button>
  </mat-card-actions>
</mat-card>
