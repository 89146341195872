<div [hidden]="!!referralSelected">
  <insig-referral-search
    class="full-width"
    [(patientAddress)]="patientAddress"
    (updateAddresses)="updateAddresses()"
    [(mapSettings)]="mapSettings"
    [hidden]="!!patientAddress"
    [doctorMode]="doctorMode"
  ></insig-referral-search>

  <insig-referral-map
    class="full-width"
    [(referralOptions)]="referralOptions"
    [(referralSelected)]="referralSelected"
    [doctorMode]="doctorMode"
    [referralType]="referralType"
    [mapSettings]="mapSettings"
    (clearPatientAddress)="clearPatientAddress()"
    [patientAddress]="patientAddress"
    [hidden]="!patientAddress"
    (blankReferralClicked)="blankReferralClicked()"
  ></insig-referral-map>
</div>

<div
  class="row no-gutters m-0 p-0 kiosk-bg align-content-center"
  *ngIf="!!referralSelected && !referralConfirmed"
>
  <div class="col-12 align-self-center justify-content-center kiosk-title">
    <div class="text-center">
      <h4 class="mb-0 px-4 mx-auto roboto-title">
        {{
          doctorMode
            ? 'Please confirm with the patient, you have selected ...'
            : 'You have selected ...'
        }}
      </h4>
    </div>
  </div>

  <div class="col-12 align-self-center justify-content-center kiosk-title">
    <div
      class="referral-details-container row p-4
        m-0 full-width align-content-start"
    >
      <insig-referral-clinic
        class="mx-auto"
        [referralClinic]="referralSelected"
        [referralBorder]="true"
        [showAddress]="true"
      >
      </insig-referral-clinic>
    </div>
  </div>

  <div class="col-12 action-row">
    <div class="row m-0 full-width">
      <div class="col-12 col-md-6">
        <div
          class=" back-button primary-color-text"
          (click)="referralSelected = false"
        >
          <div class="justify-content-center align-items-center">
            <div class="row mx-0 mb-2 mt-0">
              <span class="title mx-auto">&nbsp;Back to location selection</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <div
          class=" action-button primary-color-full"
          (click)="confirmReferral()"
        >
          <div class="justify-content-center align-items-center">
            <div class="row mx-0 mb-2 mt-0">
              <span class="title mx-auto"
                >&nbsp;
                {{ pharmacySelect ? 'Confirm' : (doctorMode ? 'Send' : 'Send my information to the clinic!') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="row no-gutters m-0 p-0 kiosk-bg full-height"
  *ngIf="!!referralConfirmed"
>
  <div class="col-12 align-self-center justify-content-center kiosk-title" *ngIf="!pharmacySelect">
    <div class="py-5 p-sm-5 text-center">
      <h4 class="mb-0 px-4 mx-auto roboto-title">
        Thank you! Information has been sent to:
      </h4>
    </div>
  </div>

  <div class="col-12 align-self-center justify-content-center">
    <insig-referral-clinic
      class="mx-auto"
      [referralClinic]="referralSelected"
      [referralBorder]="true"
      [showAddress]="true"
    >
    </insig-referral-clinic>
  </div>

  <div class="col-12 align-self-center justify-content-center kiosk-title" *ngIf="!pharmacySelect">
    <div class="py-5 p-sm-5 text-center">
      <h4 class="m-0 px-4 mx-auto roboto-title">
        {{ doctorMode ? 'They will contact the patient shortly.' :
        (!!referralSelected.patientMessage ? referralSelected.patientMessage : 'They will contact you shortly.' )}}
      </h4>
    </div>
  </div>
</div>
