import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'confirm-user-group-delete',
  templateUrl: './confirm-user-group-delete.component.html',
})
export class ConfirmUserGroupDeleteDialogComponent {
  public readonly dialogRef = inject<MatDialogRef<ConfirmUserGroupDeleteDialogComponent>>(MatDialogRef<ConfirmUserGroupDeleteDialogComponent>);
}
