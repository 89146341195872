import { Injectable } from '@angular/core';

@Injectable()
export class CkConfigService {
  private ckConfig = {
    // The full preset from CDN which we used as a base provides more features than we need.
    // Also by default it comes with a 3-line toolbar. Here we put all buttons in a single row.
    toolbar: [
      {
        name: 'document',
        items: ['Print'],
      },
      {
        name: 'styles',
        items: ['Font', 'FontSize'],
      },
      {
        name: 'colors',
        items: ['TextColor'],
      },
      {
        name: 'basicstyles',
        items: ['Bold', 'Underline', 'RemoveFormat'],
      },
      {
        name: 'align',
        items: ['JustifyLeft', 'JustifyCenter'],
      },
      {
        name: 'insert',
        items: ['Image', 'Table'],
      },
    ],

    // Since we define all configuration options here, let's instruct to not load config.js which it does by default.
    // One HTTP request less will result in a faster startup time.
    customConfig: '',
    // add below
    // extraPlugins: 'sourcedialog',

    // Sometimes applications that convert HTML to PDF prefer setting image width through attributes instead of CSS styles.
    // For more information check:
    //  - About Advanced Content Filter: http://docs.ckeditor.com/#!/guide/dev_advanced_content_filter
    //  - About Disallowed Content: http://docs.ckeditor.com/#!/guide/dev_disallowed_content
    //  - About Allowed Content: http://docs.ckeditor.com/#!/guide/dev_allowed_content_rules
    disallowedContent: 'img{width,height,float}',
    extraAllowedContent: 'img[width,height,align]; div(page-break-before)',

    // Enabling extra plugins, available in the full-all preset: http://ckeditor.com/presets-all

    /*********************** File management support ***********************/
    // In order to turn on support for file uploads, CKEditor has to be configured to use some server side
    // solution with file upload/management capabilities, like for example CKFinder.
    // For more information see http://docs.ckeditor.com/#!/guide/dev_ckfinder_integration

    // Uncomment and correct these lines after you setup your local CKFinder instance.
    // filebrowserBrowseUrl: 'http://example.com/ckfinder/ckfinder.html',
    // filebrowserUploadUrl: 'http://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files',
    /*********************** File management support ***********************/

    // Make the editing area bigger than default.
    height: 800,
    // An array of stylesheets to style the WYSIWYG area.
    // Note: it is recommended to keep your own styles in a separate file in order to make future updates painless.
    contentsCss: [
      'https://cdn.ckeditor.com/4.6.1/full-all/contents.css',
      'html {background-color:#e6e6e6;}img{max-width:100%}body.document-editor {line-height: normal;max-width: 90%;width: 23.8cm;min-height: 21cm;padding:  30px 40px 30px 40px;margin: 10px auto 20px auto;border: 1px #D3D3D3 solid;border-radius: 5px;background: white;box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);}body.document-editor, body.document-editor td, body.document-editor th {font-size: 0.9em;}body.document-editor table {margin-top:0.5cm;margin-bottom:0.5cm;}body.document-editor h1 {margin-bottom:1cm;}',
    ],
    // This is optional, but will let us define multiple different styles for multiple editors using the same CSS file.
    bodyClass: 'document-editor',
    // Reduce the list of block elements listed in the Format dropdown to the most commonly used.
    format_tags: 'p;h1;h2;h3;pre',
    // Simplify the Image and Link dialog windows. The "Advanced" tab is not needed in most cases.
    removeDialogTabs: 'image:advanced;link:advanced',
    // Define the list of styles which should be available in the Styles dropdown list.
    // If the "class" attribute is used to style an element, make sure to define the style for the class in "mystyles.css"
    // (and on your website so that it rendered in the same way).
    // Note: by default CKEditor looks for styles.js file. Defining stylesSet inline (as below) stops CKEditor from loading
    // that file, which means one HTTP request less (and a faster startup).
    // For more information see http://docs.ckeditor.com/#!/guide/dev_styles
    stylesSet: [
      /* Inline Styles */
      {
        name: 'Marker',
        element: 'span',
        attributes: {
          class: 'marker',
        },
      },
      {
        name: 'Cited Work',
        element: 'cite',
      },
      {
        name: 'Inline Quotation',
        element: 'q',
      },

      /* Object Styles */
      {
        name: 'Special Container',
        element: 'div',
        styles: {
          padding: '5px 10px',
          background: '#eee',
          border: '1px solid #ccc',
        },
      },
      {
        name: 'Compact table',
        element: 'table',
        attributes: {
          cellpadding: '5',
          cellspacing: '0',
          border: '1',
          bordercolor: '#ccc',
        },
        styles: {
          'border-collapse': 'collapse',
        },
      },
      {
        name: 'Borderless Table',
        element: 'table',
        styles: {
          'border-style': 'hidden',
          'background-color': '#E6E6FA',
        },
      },
      {
        name: 'Square Bulleted List',
        element: 'ul',
        styles: {
          'list-style-type': 'square',
        },
      },
    ],
    // TODO: remove this after upgrade
    versionCheck: false,
  }; // end ck config

  private patientCkConfig = {
    // The full preset from CDN which we used as a base provides more features than we need.
    // Also by default it comes with a 3-line toolbar. Here we put all buttons in a single row.
    toolbar: [],

    // Since we define all configuration options here, let's instruct to not load config.js which it does by default.
    // One HTTP request less will result in a faster startup time.
    customConfig: '',
    // add below
    // extraPlugins: 'sourcedialog',

    // Sometimes applications that convert HTML to PDF prefer setting image width through attributes instead of CSS styles.
    // For more information check:
    //  - About Advanced Content Filter: http://docs.ckeditor.com/#!/guide/dev_advanced_content_filter
    //  - About Disallowed Content: http://docs.ckeditor.com/#!/guide/dev_disallowed_content
    //  - About Allowed Content: http://docs.ckeditor.com/#!/guide/dev_allowed_content_rules
    disallowedContent: 'img{width,height,float}',
    extraAllowedContent: 'img[width,height,align]',

    // Enabling extra plugins, available in the full-all preset: http://ckeditor.com/presets-all

    /*********************** File management support ***********************/
    // In order to turn on support for file uploads, CKEditor has to be configured to use some server side
    // solution with file upload/management capabilities, like for example CKFinder.
    // For more information see http://docs.ckeditor.com/#!/guide/dev_ckfinder_integration

    // Uncomment and correct these lines after you setup your local CKFinder instance.
    // filebrowserBrowseUrl: 'http://example.com/ckfinder/ckfinder.html',
    // filebrowserUploadUrl: 'http://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files',
    /*********************** File management support ***********************/

    // Make the editing area bigger than default.
    height: 250,
    // An array of stylesheets to style the WYSIWYG area.
    // Note: it is recommended to keep your own styles in a separate file in order to make future updates painless.
    contentsCss: [
      'https://cdn.ckeditor.com/4.6.1/full-all/contents.css',
      'html {background-color:#e6e6e6;}img{max-width:100%}body.document-editor {line-height: normal;max-width: 90%;width: 23.8cm;min-height: 4cm;padding:  30px 40px 30px 40px;margin: 10px auto 20px auto;border: 1px #D3D3D3 solid;border-radius: 5px;background: white;box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);}body.document-editor, body.document-editor td, body.document-editor th {font-size: 0.9em;}body.document-editor table {margin-top:0.5cm;margin-bottom:0.5cm;}body.document-editor h1 {margin-bottom:1cm;}',
    ],
    // This is optional, but will let us define multiple different styles for multiple editors using the same CSS file.
    bodyClass: 'document-editor',
    // Reduce the list of block elements listed in the Format dropdown to the most commonly used.
    format_tags: 'p;h1;h2;h3;pre',
    // Simplify the Image and Link dialog windows. The "Advanced" tab is not needed in most cases.
    removeDialogTabs: 'image:advanced;link:advanced',
    // Define the list of styles which should be available in the Styles dropdown list.
    // If the "class" attribute is used to style an element, make sure to define the style for the class in "mystyles.css"
    // (and on your website so that it rendered in the same way).
    // Note: by default CKEditor looks for styles.js file. Defining stylesSet inline (as below) stops CKEditor from loading
    // that file, which means one HTTP request less (and a faster startup).
    // For more information see http://docs.ckeditor.com/#!/guide/dev_styles
    stylesSet: [
      /* Inline Styles */
      {
        name: 'Marker',
        element: 'span',
        attributes: {
          class: 'marker',
        },
      },
      {
        name: 'Cited Work',
        element: 'cite',
      },
      {
        name: 'Inline Quotation',
        element: 'q',
      },

      /* Object Styles */
      {
        name: 'Special Container',
        element: 'div',
        styles: {
          padding: '5px 10px',
          background: '#eee',
          border: '1px solid #ccc',
        },
      },
      {
        name: 'Compact table',
        element: 'table',
        attributes: {
          cellpadding: '5',
          cellspacing: '0',
          border: '1',
          bordercolor: '#ccc',
        },
        styles: {
          'border-collapse': 'collapse',
        },
      },
      {
        name: 'Borderless Table',
        element: 'table',
        styles: {
          'border-style': 'hidden',
          'background-color': '#E6E6FA',
        },
      },
      {
        name: 'Square Bulleted List',
        element: 'ul',
        styles: {
          'list-style-type': 'square',
        },
      },
    ],
    // TODO: remove this after upgrade
    versionCheck: false,
  }; // end ck config

  getPatientConfig() {
    return this.patientCkConfig;
  }

  getConfig() {
    return this.ckConfig;
  }
} // end service
