// @ts-strict-ignore
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, SimpleChanges, OnChanges, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { CanadaDoctorsListService } from '../../../services/canada-doctors-list.service';

import { map, startWith, take } from 'rxjs/operators';

import { Question } from 'insig-types/surveys';
import { Observable } from 'rxjs';

@Component({
  selector: 'doctors-question',
  templateUrl: './doctors.component.html',
  providers: [CanadaDoctorsListService],
})
export class DoctorsComponent implements OnInit, OnChanges {
  private readonly canadaDoctorsListService = inject(CanadaDoctorsListService);
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();

  @ViewChild('itemsSelect') itemsSelect;
  itemsCtrl: UntypedFormControl;
  public readonly filteredItems: Observable<Array<[string, string]>>;
  private items: Array<[string, string]> = [];
  sub = null;

  constructor() {
    this.itemsCtrl = new UntypedFormControl();
    this.filteredItems = this.itemsCtrl.valueChanges.pipe(
      startWith(null),
      map((name) => this.filterItems(name))
    );
  }

  async ngOnInit() {
    if (!this.question.response.selectedAnswers) {
      this.question.response.selectedAnswers = [];
    }
    try {
      this.items = await this.canadaDoctorsListService
        .getDoctors()
        .pipe(take(1))
        .toPromise();
    } catch (error) {
      console.error(error);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  filterItems(val: string) {
    if (!Array.isArray(this.items)) {
      this.items = [];
    }

    return val
      ? this.items
          .filter(
            (s) =>
              s[0].toLowerCase().indexOf(val.toLowerCase()) === 0 ||
              s[1].toLowerCase().indexOf(val.toLowerCase()) === 0
          )
          .slice(0, 50)
      : this.items.slice(0, 50);
  }

  removeItem(val) {
    const index = this.question.response.selectedAnswers.indexOf(val);
    this.question.response.selectedAnswers.splice(index, 1);
  }

  addItem(val) {
    if (this.question.allowMultiple) {
      // if its from the list its an array of first and last names else its a string
      if (val.constructor === Array) {
        val = val[0] + ' ' + val[1];
      }
      if (!this.question.response.selectedAnswers.includes(val)) {
        this.question.response.selectedAnswers.push(val);
      }
      this.itemsCtrl.setValue(null);
    }
    // this.itemsSelect.nativeElement.blur();
  } // end add item
} // end component
