<div class=WordSection1>

  <p class=MsoListParagraph style='margin-left:18.0pt;text-align:
  center'><b><span lang=EN-US style='font-size:18.0pt;line-height:107%'>WE HAVE
  UPDATED OUR TERMS AND CONDITIONS</span></b></p>
  
  <h1 style='text-align:center'><span lang=EN-US style='font-family:
  "Calibri",sans-serif'>Terms &amp; Conditions</span></h1>
  
  <p class=MsoNormal style='text-align:center;line-height:18.0pt'><b><span
  lang=EN-US>INSIG CORPORATION LICENSE AND SERVICES AGREEMENT</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>This License and Services Agreement (the “<b>Agreement</b>”) is
  between <b>INSIG CORPORATION INC.</b> (the “<b>Licensor</b>” or “<b>Insig</b>”)
  and together with our affiliates and distributors (collectively, also referred
  to as “<b>we</b> ”, “<b>us</b>”, “<b>our</b>”), which own and/or operates: (1)
  the website located at https://insighealth.com, https://tiahealth.com, and
  https://virtualclinics.ca </span><span lang=EN-US>(collectively, the &quot;<b>Website</b>&quot;);
  (2) our mobile phone application, used in secure patient/doctor online
  communication (the “<b>App</b>”)]  (3) the associated standalone Insig virtual care
  platform (“<b>IVCP</b>”)(4) the TIA Health and virtualclinic+ virtual clinics
  (“<b>Virtual Clinics</b>”); and (5) all other services and software
  applications provided by us for use by individual health practitioners (i.e.
  doctors, nurses, chiropractors, physiotherapists, dentists, etc.) as well as by
  enterprise level organizations (corporations or professional corporations that
  employ one or more regulated health practitioners), through the App, IVCP,
  Virtual Clinics or as described on the Website (all of the foregoing
  collectively, the &quot;<b>Platform</b>&quot;) and you, a physician or other
  regulated health practitioner providing health care services (collectively the
  “<b>Health Services</b>”) to patients as an independent practitioner, licensed
  to practice medicine or another health care discipline in the jurisdictions in
  which you work. </span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>Licensor is not a health care provider. You alone are responsible
  for the Services you provide through the Platform.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>The Licensor owns and operates the Platform and enjoys all
  intellectual property rights in respect of the Platform. The Licensor owns all
  intellectual property rights in respect to any technical support, renewal,
  payment and updated applications associated with the Platform (the “<b>Licensor
  Services</b>”). The Licensor hereby reserves all rights not expressly granted
  to you. For the purposes of this agreement “<b>intellectual property</b>” means
  all statutory, common law and other intellectual property, whether registered
  or unregistered, including but not limited to copyright, patent, trademark,
  industrial design, trade secret, know-how, inventions, designs, innovations and
  all other intellectual property, and all improvements, amendments and other
  modifications thereto, and all goodwill and moral rights in the foregoing.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><b><span
  lang=EN-US>PLEASE READ THE TERMS AND CONDITIONS OF THIS LICENCE AGREEMENT
  CAREFULLY BEFORE DOWNLOADING AND USING THE PLATFORM.</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>Your use of the Platform is expressly conditioned on your acceptance
  without modification of the terms and conditions of this Agreement. If you
  accept these terms and conditions, click on the “I Accept” button below. That
  action is the equivalent of your signature and indicates your acceptance of the
  terms and conditions of this Agreement and that you intend to be legally bound
  by them. If you do not agree with them, please click on the “Back” button of
  your browser or click on the “Cancel” button below to exit this Website. </span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><b><span
  lang=EN-US>IF YOU DO NOT AGREE WITH (OR CANNOT COMPLY WITH) THE TERMS OF THIS
  AGREEMENT, THEN YOU MAY NOT USE THE PLATFORM.</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>Please read the Agreement carefully. It covers important information
  about Platform and Licensor Services provided to you and any charges, taxes,
  and fees we bill you.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>1.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>Conditions of Use</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>In order to use the Licensor Services and access and use the
  Platform, you must (1) be 18 years of age or older; (2) have the power to enter
  a binding contract with us and not be barred from doing so under any applicable
  laws; (3) be resident in a country where the Platform and Licensor Services are
  available; and (4) be or, in the case of a corporation, employ a regulated
  health professional registered to practice medicine or another regulated health
  profession in the jurisdiction in which you work or operate. You hereby
  represent and warrant that any registration information that you submit to
  Licensor is true, accurate, and complete, and you will promptly notify us of
  any changes.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>We may, in our sole discretion, make changes to the Agreement from
  time to time. When we make material changes to the Agreement, we will provide
  you with prominent notice as appropriate to the circumstances, e.g., by
  displaying a prominent notice within the Licensor Services or by sending you an
  email. In some cases, we will notify you in advance of the changes and your
  continued use of the Platform and Licensor Services will constitute your
  acceptance of the changes. Please ensure that you read any such notice
  carefully. If you do not wish to continue using the Platform and Licensor
  Services under the new version of the Agreement, you may terminate the
  Agreement by contacting us through the Customer Service contact form.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>The collection, use and disclosure of personal information is
  governed by our privacy policy, located in the footer of our home page. Our privacy
  policy is incorporated by reference into, and forms part of this Agreement. By
  accessing and using the Platforms, you agree to be bound by its terms. </span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>2.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span
  lang=EN-US> <a><b>Using Platform and Licensor Services</b></a></span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>The following sets out the ways in which you can use the Platform
  and Licensor Services.</span></p>
  
  <span lang=EN-US style='font-size:11.0pt;line-height:107%;font-family:"Calibri",sans-serif'><br
  style='page-break-before:always'>
  </span>
  
  <p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>
  
  <p class=MsoListParagraph style='margin-left:108.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><span lang=EN-US>a.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
  lang=EN-US>Classes of Users</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>The Platform is developed and designed for use by individual health practitioners
  (doctors, nurses, chiropractors, physiotherapists, dentists, etc.) as well as
  by enterprise level organizations (corporations or professional corporations
  that employ one or more regulated health practitioners).</span></p>
  
  <p class=MsoListParagraph style='margin-left:108.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><span lang=EN-US>b.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-US> <b>Available Subscriptions and Platform Use Fee (the “License Fee”)</b></span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>You shall pay to the Licensor the following License Fee: (i) in the
  case of monthly subscription, in monthly installments payable each month; or
  (ii) in the case of annual subscription, in a lump sum equal to twelve (12)
  monthly payments in the amount specified below. The License Fee shall be
  payable on the day of the month on which this Agreement has been signed by you
  and the License to use the Platform and Licensor Services is activated by us
  (the “ <b>Activation Date</b>”) (for clarity: if your Activation Date is March
  5th, your first monthly payment, in case of monthly subscription, will be on
  March 5th, second monthly payment on April 5th and so forth). Please refer to
  our current License Fees as set out on our Website.</span></p>
  
  <p class=MsoListParagraph style='margin-left:108.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>c.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>No Subscription - Advertising</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>Users without subscriptions may still use the Platform. Our Insig
  ScribeAssist product (questionnaires) is available without subscription as it
  is which may be supported by advertising. With a subscription, as described
  above, the advertising can be removed, provided that all users within that
  User’s Insig group (sharing a company ID, patient notes, etc.) purchase and
  maintain an active Insig subscription as well. Our Virtual Care product is also
  available without a subscription, in the amount mutually agreed between you and
  the Licensor.</span></p>
  
  <p class=MsoListParagraph style='margin-left:108.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>d.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>Corporate Subscriptions</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>Contact us for information on Corporate Subscriptions at </span><span
  lang=EN-US><a href="mailto:info@insighealth.com">info@insighealth.com</a></span><span
  lang=EN-US>. </span></p>
  
  <p class=MsoListParagraph style='margin-left:108.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>e.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>User Equivalents</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>The number of user equivalents is calculated as one user equivalent
  for each person interacting with the software in any way, plus an unlimited
  number of additional user equivalents per 'user equivalent usage'. In
  calculating user equivalents, values are rounded up to the nearest whole number
  and calculations are done on a per person basis, meaning two people using 1.5
  times the 'user equivalent usage' would each be billed two user equivalents for
  a total of 4 user equivalents. The amount of usage representing one 'user
  equivalent usage' is subject to change anytime without notice and is based on
  our user experience.</span></p>
  
  <p class=MsoListParagraph style='margin-left:108.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>f.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>License Fee Terms</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>You shall pay the License Fee upon acceptance of this Agreement. We
  reserve the right to charge you an amount in accordance with the calculation
  above.</span></p>
  
  <p class=MsoListParagraph style='margin-left:108.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>g.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>License Term</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>You acknowledge that the minimum term of this  Agreement and
  subscription period is one month from the Activation Date. Even if you
  terminate this  Agreement after any shorter period, your subscription to the
  Platform and Licensor Services will remain active for one month from the
  Activation Date and you shall be liable for one month License Fee.</span></p>
  
  <p class=MsoListParagraph style='margin-left:108.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>h.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>Changes to License Fee</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>Notwithstanding anything to the contrary in this Agreement, the
  Licensor shall have the right to change the License Fee without any prior
  notice to you. However, your license to use the Platform shall continue on the
  same terms in full force and effect for the entire subscription period you paid
  for before any change in License Fee has taken effect.</span></p>
  
  <p class=MsoListParagraph style='margin-left:108.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>i.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>Patient Fee</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>If you do not have an active IVCP or Virtual Clinics subscription,
  in addition to any other fees provided for under this Agreement, you shall pay
  to the Licensor the amount as mutually agreed between you and the Licensor  for
  the fees received by you from any patient through the use of the Platform and
  any In-Clinic visits.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>In case a patient does not attend a scheduled appointment the “No
  Show” fee shall constitute a dollar value that is configurable through the Platform.
  You agree to pay the Licensor  the agreed fees from all “No Show” fees charged,
  including from In-Clinic visits, in such amounts as mutually agreed between you
  and the Licensor.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>The fees above do not include additional payment processing fees
  charged by Stripe for accepting payments online through the Platform, which are
  your responsibility. You can learn more about Stripe at </span><span
  lang=EN-US><a href="https://stripe.com">https://stripe.com</a></span><span
  lang=EN-US> for more information on their fees for accepting payments online.
  Stripe will charge its fees on every transaction made through the Platform,
  which is in addition to the Licensor’s Fees as described above.</span></p>
  
  <p class=MsoListParagraph style='margin-left:108.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><span lang=EN-US>j.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
  lang=EN-US>Use of</span></b><span lang=EN-US> </span><b><span lang=EN-US>Platform</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>If you are only using a particular Platform, then these terms will
  only apply to the extent it is applicable to such Platform, including the
  corresponding License Fees and as further indicated herein.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>3.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>Free &amp; Paid Services</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>Licensor provides online services offering technical support of the
  Platform. While the license to use the Platform is granted upon payment of the License
  Fee as set out in section </span><span
  lang=EN-US>2</span><span lang=EN-US> of this Agreement, certain Licensor
  Services are provided to you free-of-charge. Other Licensor Services require
  payment before you can access them. You can learn more about our services by
  visiting the Website.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>4.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>Warranty and Disclaimer</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE PLATFORM AND
  LICENSOR SERVICES IS AT YOUR SOLE RISK AND THAT THE ENTIRE RISK AS TO
  SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH YOU. TO THE
  MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE PLATFORM, LICENSOR SERVICES AND
  ANY WORKS PERFORMED OR PROVIDED BY THE PLATFORM (&quot;<b>WORKS</b>&quot;) ARE
  PROVIDED &quot;AS IS&quot; AND “AS AVAILABLE”, WITH ALL FAULTS AND WITHOUT
  WARRANTY OF ANY KIND, AND THE LICENSOR HEREBY DISCLAIMS ALL WARRANTIES AND
  CONDITIONS WITH RESPECT TO THE PLATFORM, LICENSOR SERVICES AND ANY WORKS,
  EITHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY
  QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT,
  AND NON-INFRINGEMENT OF THIRD PARTY RIGHTS. THE LICENSOR DOES NOT WARRANT
  AGAINST INTERFERENCE WITH YOUR ENJOYMENT OF THE PLATFORM, THAT THE FUNCTIONS
  CONTAINED IN, OR WORKS PERFORMED OR PROVIDED BY, THE PLATFORM WILL MEET YOUR
  REQUIREMENTS, THAT THE OPERATION OF THE PLATFORM OR LICENSOR SERVICES OR WORKS
  WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS IN THE PLATFORM OR
  LICENSOR SERVICES OR WORKS WILL BE CORRECTED. LICENSOR DOES NOT WARRANT THAT
  THE PLATFORM OR LICENSOR SERVICES IS FREE OF MALWARE OR OTHER HARMFUL
  COMPONENTS. </span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>IN ADDITION, LICENSOR MAKES NO REPRESENTATION NOR DOES IT WARRANT,
  ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY THIRD-PARTY APPLICATIONS
  (OR THE PRODUCTS THEREOF), OR ANY OTHER PRODUCT OR SERVICE ADVERTISED OR
  OFFERED BY A THIRD PARTY ON OR THROUGH THE LICENSOR WEBSITE OR ANY HYPERLINKED
  WEBSITE, OR FEATURED IN ANY BANNER OR OTHER ADVERTISING. YOU UNDERSTAND AND
  AGREE THAT LICENSOR IS NOT RESPONSIBLE OR LIABLE FOR ANY TRANSACTION BETWEEN
  YOU AND THIRD-PARTY PROVIDERS OF THIRD-PARTY APPLICATIONS OR PRODUCTS OR
  SERVICES ADVERTISED ON OR THROUGH THE LICENSOR WEBSITE. AS WITH ANY PURCHASE OF
  A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE
  YOUR JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE. NO ADVICE OR INFORMATION
  WHETHER ORAL OR IN WRITING OBTAINED BY YOU FROM LICENSOR SHALL CREATE ANY
  WARRANTY ON BEHALF OF LICENSOR IN THIS REGARD NO ORAL OR WRITTEN INFORMATION OR
  ADVICE GIVEN BY THE LICENSOR, OR THEIR AUTHORIZED REPRESENTATIVES SHALL CREATE
  A WARRANTY. SHOULD THE PLATFORM OR LICENSOR SERVICES OR WORKS PROVE DEFECTIVE,
  YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION.
  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR
  LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE
  EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>5.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>Consent to Advertisement; No Endorsements</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>By using the Platform, you consent to the Licensor posting
  advertisement materials on the Platform. Unless expressly stated, Licensor does
  not recommend or endorse any brand of products, services, procedures, or other
  information that appears or is advertised from time to time on the Platform.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>6.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>Transmission of Information</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>You acknowledge that there is no guarantee that information
  transmitted via the Platform or Licensor Services, or through the Internet, or
  submissions to the Platform or Licensor Services through the Internet,
  including via any video conferencing or other communication means, will be
  maintained as confidential and secure. The use of the Platform and Licensor
  Services and the content thereof is at your own risk and Licensor assumes no
  liability or responsibility pertaining to content, or to use of the Platform or
  Licensor Services, or to the receipt, storage, transmission, processing,
  function or other use of information by or in relation to the Platform or
  Licensor Services.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>7.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>Hardware and Security</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>You are responsible for all hardware and software, including
  security and data protection systems, on all systems and computers which the
  Platform and Licensor Services are accessed and utilized and all costs
  associated therewith. The Licensor waives all responsibility and liability for all
  the foregoing.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>You hereby grants to Licensor the right: (a) to allow the Platform
  and Licensor Services to use the processor, bandwidth, and storage hardware on
  any of your, or an authorized user’s, device utilized to access and utilize the
  Platform or Licensor Services, as required in order to facilitate the operation
  of the Platform and Licensor Services; (b) to provide advertising and other
  information to you; and (c) to allow Licensor’s business partners to do the
  same.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>8.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>Rights Insig Grants You</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>The Licensor Services and the Platform are the property of Licensor.
  We grant you a limited, non-exclusive, non-sublicensable, non-transferable,
  revocable license to make use of the Platform and Licensor Services during the
  term of this Agreement (the “<b>Licensed Rights</b>”). Depending on the type of
  subscription, the Licensed Rights can be personal or corporate. The personal
  Licensed Rights allow the use of the Platform and Licensor Services by you as
  an individual subscriber. The personal Licensed Rights allow you to download
  and use one (1) copy of the App and to access the Platform via the Website. The
  corporate Licensed Rights allow you to download and use 1 copy of the App on
  devices of employees and/or contractors of the corporation and to have the same
  number of employees and/or contractors access the Platform via the Website. The
  Licensor Services are available through our Website on a free and a “pay-per-use”
  basis. The Licensed Rights shall remain in effect for the corresponding
  subscription term unless terminated by you or Licensor in accordance with this
  Agreement. You shall use the Platform for your own personal or corporate
  medical or health care practice use only and shall not redistribute or transfer
  the Licensed Rights to any third parties.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>&nbsp;</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>9.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>Ownership</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>The Licensor Services, and the Platform, including the App and other
  software applications, are licensed, not sold, to you, and Licensor retains
  ownership of all copies of the Platform, including the App and other software
  applications, and all other information and software used in the Platform and
  Licensor Services even after installation on your personal computers, mobile
  handsets, tablets, and/or other relevant devices.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>All trademarks, service marks, trade names, logos, domain names used
  in association with the Platform and otherwise used by the Licensor, and any
  other features of the Licensor’s brand (the foregoing collectively the “<b>Licensor’s
  Brand Features</b>”) are the sole property of the Licensor. The Agreement does
  not grant you any rights to use any Licensor’s Brand Features whether for
  commercial or non-commercial use. All copies of the Platform or elements
  thereof, or other information or software applications, that you are permitted
  to make under the terms of this Agreement must bear and otherwise incorporate
  all intellectual property markings and notices existing in the original
  versions thereof.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>You agree to abide by this Agreement, including the user guidelines
  and not to use the Licensor Services, the Platform, the Licensor’s Brand
  Features, any intellectual property relating to any of the foregoing, or any
  part of any of the foregoing in any manner not expressly permitted by this
  Agreement. Except for the rights expressly granted to you in these Agreements,
  Licensor grants no right, title, or interest to you in the Platform, Licensor
  Services, Licensor Brand Features, and information or software applications
  provided by Licensor, or any intellectual property in any of the foregoing.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>10.<span
  style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Infringement</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>If you become aware that any person, firm, company or entity is
  infringing, or threatening to infringe, an of the Platform or Licensor Services
  in violation of either applicable laws or of any contractual obligations of
  such person, firm, company or entity, you shall immediately notify Licensor, in
  writing, to that effect. Upon receipt of such notice, Licensor shall act in its
  sole discretion as it deems reasonably necessary to prevent such third-party
  infringement. You will not take any action against any such third-party
  infringer without the prior written approval of Licensor, and you will
  cooperate and assist Licensor in any such action. Such co-operation and
  assistance may include the execution of reasonable documents at Licensor’s
  expense, and in accordance with applicable laws.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>11.<span
  style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Updates</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>The Platform may be amended or otherwise modified from time to time
  by Licensor to fix bugs or errors therein at Licensor’s sole discretion. For
  clarity, the definitions of “Platform” and “Licensor Services” herein do not
  include any upgrades or new versions of the Platform or Licensor Services. Such
  upgrades or new versions of the Platform or Licensor Services, as well as
  additional features or functionalities, new products and reports (collectively
  “<b>New Licensor Technologies</b>”) may be developed and made available to you,
  and additional fees shall be applicable to any of the New Licensor
  Technologies, and additional contracts or contractual terms may be required for
  you to receive any New Licensor Technologies.</span></p>
  
  <p class=MsoListParagraphCxSpFirst style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>12.<span
  style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Patient Relationship and Information</span></b></p>
  
  <p class=MsoListParagraphCxSpLast style='margin-left:108.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>a.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>Insig is not a party</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>The Platform enables you as a health care provider to collect, use
  or disclose personal health information about your patients (“<b>Patient PHI</b>”)
  and to provide Health Services to the patients. Your use of the Platform
  neither establishes nor governs your relationship with the patients. The Health
  Services which you provide to patients form an exclusive, bilateral contractual
  relationship between you and the patient and you are solely responsible for the
  delivery of such Health Services. The Licensor is not a party to any agreement
  with you and  the patient, unless the patient is the Licensor’s patient in its Virtual
  Clinics platform.</span></p>
  
  <p class=MsoListParagraph style='margin-left:108.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>b.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>Patient Consent</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>You hereby acknowledge and agree that it is your sole responsibility
  to obtain the patient’s informed consent to receive Health Services and their
  voluntary and express consent to the collection, use and disclosure of patient information,
  including Patient PHI. You acknowledge that it is your responsibility to
  establish proper safeguards and practices and for your compliance with
  applicable federal or provincial privacy laws and professional regulatory
  requirements (<b>“Privacy Laws”</b>) and other applicable legislation. </span></p>
  
  <p class=MsoListParagraph style='margin-left:108.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><span lang=EN-US>c.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
  lang=EN-US>Transfer of Patient PHI</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>The Platform is secured through encryption technologies and only you
  and  the patients will have access to Patient PHI. In the event that the
  Virtual Clinic platform is used by you, then the Licensor will also have access
  to Patient PHI and you acknowledge that other practitioners in the Virtual Clinics
  platform of the Licensor will have access to the Patient’s PHI for purposes of
  providing Health Services. You acknowledge and agree that the Platform is not
  intended to replace or to be used as your electronic medical record and it may
  not be compliant with professional regulatory requirements. You are responsible
  for ensuring that any Patient PHI collected on the Platform is transferred to
  your own electronic medical record and for managing such Patient PHI in
  accordance with Privacy Laws. If Virtual Clincs platform is used, the Licensor
  will retain Patient PHI. Upon termination of this Agreement, you will no longer
  have access to the Platform or Patient PHI, </span><span lang=EN-US>except for your
  obligations to retain records in accordance with your respective CMPA and
  College requirement.</span><span lang=EN-US> THE PLATFORM IS NOT A MEDICAL
  DEVICE, NOR IS IT APPROVED FOR ANY OTHER HEALTH APPLICATION BY ANY REGULATOR,
  INCLUDING HEALTH CANADA OR THE THERAPEUTIC PRODUCTS DIRECTORATE UNDER THE FOOD
  AND DRUG REGULATIONS, MEDICAL DEVICES REGULATIONS OR ANY OTHER FOOD AND DRUGS
  ACT.</span></p>
  
  <p class=MsoListParagraph style='margin-left:108.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><span lang=EN-US>d.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
  lang=EN-US>Disclosing Patient PHI</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>We will not disclose Patient PHI stored on the Platform except with
  your agreement, as required by applicable law, or in response to a regulatory
  or legal proceeding.</span></p>
  
  <p class=MsoListParagraph style='margin-left:108.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><span lang=EN-US>e.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
  lang=EN-US>Retaining and Disposing of Patient PHI</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>Unless we otherwise give you notice, and in accordance with our
  privacy policy, we will retain the Patient PHI on the Platform on your behalf
  until such time as you or we terminate this Agreement. Licensor is not the
  custodian or trustee of Patient PHI stored on the Platform; rather it holds Patient
  PHI on your behalf for the benefit of the patients. On termination of this
  Agreement, you will no longer have access to the Platform or the Licensor
  Services. If you are a Virtual Clinics user and at any time you request access
  to Patient PHI and require the Licensor’s support in doing so, Licensor will follow
  all healthcare regulatory rules and standards including required patient
  consent, and certain administrative fees will apply and be payable by you. UNLESS
  YOU ARE SUBSCRIBED WITH VIRTUAL CLINICS PLATFORM, YOU ARE SOLELY RESPONSIBLE
  FOR ENSURING THAT YOU HAVE COPIED AND/OR TRANSFERRED ANY PATIENT PHI RELATING
  TO THE PROVISION OF HEALTH SERVICES PRIOR TO TERMINATION OR AS OTHERWISE AGREED
  BY US.</span></p>
  
  <p class=MsoListParagraph style='margin-left:108.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>f.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>Sending</span></b><b><span lang=EN-US> Reports</span></b></p>
  
  <p class=MsoNormal style='text-align:justify'><span lang=EN-US>Upon the
  patient’s request, you acknowledge that you will send reports regarding the
  care provided to your patient to your patient’s family physician or other
  primary care health professional by facsimile. Your patient will be responsible
  for providing the contact information of its family physician or other primary
  care health professional. We will provide the notification to your patient that
  the reports have been sent to its family physician or other primary care health
  professional upon your completion.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>13.<span
  style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Third-Party Applications</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>The Licensor Services are integrated with third party applications,
  websites, and services (“<b>Third-Party Applications</b>”) in order to make the
  Platform and Licensor Services available to you. You understand and consent to
  the Licensor sharing your personal information with these Third-Party
  Applications. These Third-Party Applications may have their own terms and
  conditions of use and privacy policies and your use of these Third-Party
  Applications will be governed by and subject to such terms and conditions and
  privacy policies. You understand and agree that Licensor does not endorse and
  is not responsible or liable for the behavior, features, or the platform of any
  Third-Party Application or for any transaction you may enter with the provider
  of any such Third-Party Applications.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>YOU AGREE THAT IF ANYONE BRINGS A CLAIM AGAINST LICENSOR RELATED TO
  THIRD-PARTY APPLICATIONS THAT YOU USE, THEN, TO THE EXTENT PERMISSIBLE, YOU
  SHALL RELEASE, INDEMNIFY AND HOLD LICENSOR HARMLESS  FROM AND AGAINST ALL
  DAMAGES, LOSSES, AND EXPENSES OF ANY KIND (INCLUDING REASONABLE ATTORNEY FEES
  AND COSTS) ARISING OUT OF SUCH CLAIM.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>14.<span
  style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Submissions</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:150%'><span
  lang=EN-US>If you provide feedback, ideas or suggestions to Licensor in
  connection with the Licensor Services or Platform (“<b>Submissions</b>”), you
  acknowledge that your feedback is not confidential, and you authorize Licensor
  to use that feedback without restriction and without payment to you. You agree
  not to assert any intellectual property right or moral right of any kind with
  respect to any such Submissions. You further assign all rights in such
  Submissions, including all intellectual property rights therein, to us and
  waive all moral rights therein or if you are a corporation, you will cause the
  persons who provided the Submissions to assign their intellectual property
  rights therein to us and to waive their moral rights therein. You agree to your
  likeness, photograph, name, biographical information and any and all other
  information appearing therewith to be provided through the Platform.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>15.<span
  style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Platform Rules</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>Licensor respects intellectual property rights and expects you to do
  the same. We have established a few ground rules for you to follow when using
  the Platform and Licensor Services. You shall follow these rules and encourage
  other users to do the same.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>The following is not permitted for any reason whatsoever:</span></p>
  
  <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt;
  line-height:18.0pt'><span lang=EN-US>·<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-US>copying, redistributing, reproducing, “ripping”, recording,
  transferring, performing or displaying to the public, or making available to
  the public any part of the Licensor Services or the Platform, or otherwise
  making any use of the Licensor Service or the Platform which is not expressly
  permitted under this Agreement or applicable law or which otherwise infringes,
  dilutes, misappropriates, violates or misuses the intellectual property rights
  in the Licensor Service or the Platform or any part of it, or that breaches the
  rights of any person, contract or legal duty;</span></p>
  
  <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
  line-height:18.0pt'><span lang=EN-US>·<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-US>transferring copies of cached Platform from an authorized device to
  any other device via any means;</span></p>
  
  <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
  line-height:18.0pt'><span lang=EN-US>·<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-US>altering, merging, adapting, translating, reverse-engineering,
  decompiling, disassembling, modifying, or creating derivative works based on
  the Platform or Licensor Services, or any part thereof;</span></p>
  
  <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
  line-height:18.0pt'><span lang=EN-US>·<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-US>circumventing any technology used by Licensor, its licensors, or any
  third party to protect the Platform or the Licensor Services;</span></p>
  
  <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
  line-height:18.0pt'><span lang=EN-US>·<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-US>selling, renting, sublicensing or leasing of any part of the
  Licensor Services or the Platform;</span></p>
  
  <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
  line-height:18.0pt'><span lang=EN-US>·<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-US>circumventing any territorial restrictions applied by Licensor or its
  licensors;</span></p>
  
  <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
  line-height:18.0pt'><span lang=EN-US>·<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-US>removing or altering any copyright, trademark, or other intellectual
  property notices or marking contained on or provided through the Licensor
  Services (including for the purpose of disguising or changing any indications
  of the ownership or source of the Platform);</span></p>
  
  <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
  line-height:18.0pt'><span lang=EN-US>·<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-US>providing your password to any other person or using any other
  person’s username and password;</span></p>
  
  <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
  line-height:18.0pt'><span lang=EN-US>·<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-US>“crawling” the Licensor Services or otherwise using any automated
  means (including bots, harvesters, scrapers, and spiders) to collect
  information from Licensor, including any information about a person;</span></p>
  
  <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
  line-height:18.0pt'><span lang=EN-US>·<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-US>selling a user account, or otherwise accepting any compensation,
  financial or otherwise, to influence the name of an account;</span></p>
  
  <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
  line-height:18.0pt'><span lang=EN-US>·<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-US>access or use the Platform or Licensor Services in order to build a
  competitive product or service, or for any purpose other than its intended
  purposes;</span></p>
  
  <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
  line-height:18.0pt'><span lang=EN-US>·<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-US>harass or interfere with any person’s use and enjoyment of the
  Platform or Licensor Services;</span></p>
  
  <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
  line-height:18.0pt'><span lang=EN-US>·<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-US>interfere in any manner with the operation of the Platform or
  Licensor Services, or the hardware or network used to operate the Platform or
  Licensor Services, including but not limited to introducing or otherwise
  infecting the Platform or Licensor Services with any worm, malware or other
  harmful software code; or</span></p>
  
  <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-18.0pt;
  line-height:18.0pt'><span lang=EN-US>·<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-US>challenge the validity, enforceability, registrability or ownership
  of any intellectual property rights in or relating to the Platform or Licensor
  Services.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>You bear all responsibility to ensure that you and if you are a
  corporation all authorized user of your company has a user account and complies
  with all of the terms of this Agreement, and to promptly provide a request to
  the Licensor to delete user accounts or otherwise remove access to you and
  other authorized users who should no longer be actively using the Platform or
  Licensor Services in accordance with the terms of this Agreement.</span></p>
  
  <b><span lang=EN-US style='font-size:11.0pt;line-height:107%;font-family:"Calibri",sans-serif'><br style='page-break-before:always'>
  </span></b>
  
  <p class=MsoNormal><b><span lang=EN-US>&nbsp;</span></b></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>16.<span
  style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Service Limitations and Modifications</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>Licensor will make reasonable efforts to keep the Platform and
  Licensor Services operational. However, certain technical difficulties or
  maintenance may, from time to time, result in temporary interruptions. To the
  extent permissible under applicable law, Licensor reserves the right,
  periodically and at any time, to modify or discontinue, temporarily or
  permanently, functions and features of the Platform and Licensor Services, with
  or without notice, all without liability, except where prohibited by law, for
  any interruption, modification, or discontinuation of the Platform and Licensor
  Services or any function or feature thereof. Notwithstanding the foregoing, if
  you have prepaid fees for Licensor Service that Licensor permanently
  discontinues prior to the end of the pre-paid period, Licensor will refund you
  the prepaid fees for the pre-paid period after such discontinuation. You
  understand, agree, and accept that Licensor has no obligation to maintain,
  support, upgrade, or update the Platform or Licensor Services, or to provide
  all or any specific products through the Licensor Services. This section will
  be enforced to the extent permissible by applicable law. Licensor may, from
  time to time, remove any such Licensor Service or Platform without notice to the
  extent permitted by applicable law.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>17.<span
  style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Customer Support</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>For customer support with account-related and payment-related
  questions (“<b>Customer Support Queries</b>”), please contact our customer
  service department at </span><span lang=EN-US><a
  href="mailto:support@insighealth.com">support@insighealth.com</a></span><span
  lang=EN-US>. We will use reasonable efforts to respond to all Customer Support
  Queries within a reasonable time frame but we make no guarantees or warranties
  of any kind that any Customer Support Queries will be responded to within any
  particular time frame and/or that we will be able to satisfactorily answer any
  such queries.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>18.<span
  style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Termination</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>This Agreement will continue to apply to you for the entire time you
  use the Platform, or until this Agreement is terminated by either you or
  Licensor. Upon termination you will immediately stop using the Platform and
  Licensor Services and will remove and destroy all copies of the Platform that
  you have on your devices, hardware and systems. Upon request from the Licensor,
  you will provide certification and proof of such removal and destruction.</span></p>
  
  <p class=MsoListParagraph style='margin-left:108.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>a.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>Termination by Licensor</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>Licensor may terminate this Agreement or suspend your access to the
  Platform and Licensor Services at any time, including in the event of your
  actual or suspected unauthorized use of the Platform and/or Licensor Services
  or non-compliance with this Agreement. Licensor may terminate the Agreement or
  suspend your access to the Platform and Licensor Services if we receive
  feedback and concerns about the quality of the health services that you
  provide, which cannot be resolved to our satisfaction. </span></p>
  
  <p class=MsoListParagraph style='margin-left:108.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>b.<span
  style='font:7.0pt'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  lang=EN-US>Termination by You</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>You may terminate this Agreement at any time, close your account and
  have all information removed from our servers by e-mailing </span><span
  lang=EN-US><a href="mailto:info@insighealth.com">info@insighealth.com</a></span><span
  lang=EN-US>. If you are using the Virtual Clinics platform, you may terminate
  this Agreement pursuant to the provisions set out in your physician services
  agreement. </span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>19.<span
  style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Refunds</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>If you or Licensor terminate this Agreement, or if Licensor suspends
  your access to the Platform and/or Licensor Services, you agree that Licensor
  shall have no liability or responsibility to you and Licensor will not refund
  any amounts that you have already paid, to the fullest extent permitted under
  applicable law.  </span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>20.<span
  style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Survival</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>All sections of this Agreement that, either explicitly or by their
  nature, must remain in effect even after termination of this Agreement, shall
  survive termination.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-indent:-18.0pt'><b><span
  lang=EN-US>21.<span style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Remedy</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>YOU AGREE THAT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR SOLE
  AND EXCLUSIVE REMEDY FOR ANY PROBLEMS OR DISSATISFACTION WITH THE PLATFORM AND
  LICENSOR SERVICES IS TO UNINSTALL THE PLATFORM AND/OR ANY LICENSOR SOFTWARE AND
  TO STOP USING THE LICENSOR SERVICES. WHILE LICENSOR ACCEPTS NO RESPONSIBILITY
  FOR THIRD PARTY APPLICATIONS OR PRODUCTS THEREOF, AND WHILE YOUR RELATIONSHIP
  WITH SUCH THIRD-PARTY APPLICATIONS MAY BE GOVERNED BY SEPARATE AGREEMENTS WITH
  SUCH THIRD PARTIES, TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR SOLE AND
  EXCLUSIVE REMEDY, AS WITH RESPECT TO LICENSOR, FOR ANY PROBLEMS OR
  DISSATISFACTION WITH THIRD PARTY APPLICATIONS OR PRODUCTS THEREOF, IS TO
  UNINSTALL AND/OR STOP USING ANY SUCH THIRD PARTY APPLICATIONS.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>22.<span
  style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Limitation of Liability</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL LICENSOR,
  ITS OFFICERS, SHAREHOLDERS, EMPLOYEES, AGENTS, DIRECTORS, SUBSIDIARIES,
  AFFILIATES, SUCCESSORS, ASSIGNS, SUPPLIERS OR LICENSORS BE LIABLE FOR (1) ANY
  INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES;
  (2) ANY LOSS OF USE, DATA, BUSINESS, OR PROFITS (WHETHER DIRECT OR INDIRECT),
  IN ALL CASES ARISING OUT OF THE USE OR INABILITY TO USE THE LICENSOR SERVICE,
  THIRD PARTY APPLICATIONS, OR THIRD PARTY APPLICATION PLATFORM, REGARDLESS OF
  LEGAL THEORY, WITHOUT REGARD TO WHETHER LICENSOR HAS BEEN WARNED OF THE
  POSSIBILITY OF THOSE DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL
  PURPOSE; OR (3) AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE LICENSOR
  SERVICE, THIRD PARTY APPLICATIONS, OR THIRD PARTY APPLICATION PLATFORM MORE
  THAN THE AMOUNTS PAID BY YOU TO LICENSOR DURING THE PRIOR TWELVE MONTHS IN
  QUESTION, TO THE EXTENT PERMISSIBLE BY APPLICABLE LAW.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>Nothing in this Agreement removes or limits Licensor’s liability for
  fraud, fraudulent misrepresentation, or if required by applicable law, gross
  negligence. Some aspects of this section may not apply in some jurisdictions if
  prohibited by applicable law.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>You shall carry at your sole cost and expense, membership in the
  Canadian Medical Protective Association, or carry equivalent professional
  liability insurance or protection, and shall provide us with evidence of such
  professional liability protection from time to time upon request.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>23.<span
  style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Indemnification</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>To the fullest extent permitted by applicable law, you agree to
  indemnify and hold Licensor harmless from and against all damages, losses, and
  expenses of any kind (including reasonable attorney fees and costs) arising out
  of: (1) your breach of this Agreement, including any representation and
  warranty made by you; (2) any activity in which you engage on or through the
  Platform and/or the Licensor Services, including but not limited to claims for
  damages relating to bodily injury, death, personal injury or professional
  negligence; (3) any error, omission or malfeasance committed by you or those
  for whom you are responsible under applicable laws; (4) a breach of privacy,
  security or confidentiality; and (5) your violation of any law or the rights of
  a third party.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>24.<span
  style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Entire Agreement</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>Other than as stated in this section or as explicitly agreed upon in
  writing between you and Licensor, this Agreement constitute all the terms and
  conditions agreed upon between you and Licensor and supersedes any prior
  agreements in relation to the subject matter of these Agreements, whether
  written or oral.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>Please note, however, that other aspects of your use of the Platform
  and the Licensor Services may be governed by additional written agreements.
  When you are presented with an offer for such other aspects of your use, you
  will be presented with any related additional agreement, and you may have an
  opportunity to agree to additional terms. Some of those additional terms are
  listed on Licensor’s website. To the extent that there is any irreconcilable
  conflict between any additional terms and these Agreement, the additional terms
  shall prevail.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>25.<span
  style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Severability and Waiver</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>Unless as otherwise stated in this Agreement, should any provision
  of this Agreement be held invalid or unenforceable for any reason or to any
  extent, such invalidity or enforceability shall not in any manner affect or
  render invalid or unenforceable the remaining provisions of this Agreement, and
  the application of that provision shall be enforced to the extent permitted by
  law.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>Any failure by Licensor or any third-party beneficiary to enforce
  this Agreement or any provision thereof shall not waive Licensor’s or the
  applicable third-party beneficiary’s right to do so.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>26.<span
  style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Assignment</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>Licensor may assign this Agreement or any part of them, and Licensor
  may delegate any of its obligations under this Agreement. You may not assign
  this Agreement or any part of them, nor transfer or sub-license your rights
  under this Agreement, to any third party.</span><span lang=EN-US
  style='font-size:12.0pt'> </span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><a><b><span
  lang=EN-US>27.<span style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Confidential Information</span></b></a></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><b><span
  lang=EN-US>“Confidential Information</span></b><span lang=EN-US>” means all of
  our proprietary information, in any format or media whatsoever, including but
  not limited to, the Platform and other software (in any format), and our
  systems, intellectual property, trade secrets, business policies, strategies,
  operations, finances, plans or opportunities, employee information, client
  information, supplier information, data, including personal health information,
  and all other information relating to our business, not including information
  that you can establish: (a) was lawfully in your possession before receipt from
  us; or (b) is or becomes a matter of public knowledge through no fault of
  yours; or (c) was independently developed or discovered by you.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>All Confidential Information is our exclusive and confidential
  property and will at all times be regarded, treated and protected as such.
  Failure to mark any written material as confidential will not affect the
  confidential nature of such written material or the information contained
  therein. You may not disclose Confidential Information except solely to the
  extent: (i) you are required to do so by law, regulation, governmental
  authority or court, provided that you give us sufficient notice to enable us to
  seek an order limiting or precluding such disclosure; or (ii) we give you prior
  written authorization to do so which is signed by one or our officers.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>You agree to use at least the same degree of care to protect the
  Confidential Information from disclosure to third parties as you use to protect
  your own information of similar importance, but in any event, no less than
  reasonable care. Confidential Information shall be used and reproduced by you
  only in connection with fulfilling your obligations under this Agreement.
  Disclosures of the Confidential Information shall be restricted to your
  employees who are directly participating in providing fulfilling your
  obligations under this Agreement and who have agreed in writing to at least
  commensurate confidentiality obligations to those in this Agreement.</span></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>Upon our request or immediately upon the expiration or earlier
  termination of this Agreement, you will return to us all Confidential
  Information in your possession, power or control, or shall destroy all copies
  of Confidential Information in your possession, power or control in a manner
  that assures the Confidential Information is rendered unrecoverable. Should
  this section </span><span lang=EN-US>27</span><span lang=EN-US> be breached we
  would suffer incalculable damages, and therefore we reserve all rights of
  action, claims and damages, including the right to seek injunctive rights. This
  section </span><span lang=EN-US>27</span><span lang=EN-US> shall survive the
  expiration or termination of this Agreement.</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>28.<span
  style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Governing law / Jurisdiction</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>This Agreement shall be governed by and construed in accordance with
  the laws of the Province of Ontario and federal laws of Canada applicable
  therein and shall be treated, in all respects, as an Ontario  contract. The
  Parties submit to the exclusive jurisdiction of the courts of Ontario</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>29.<span
  style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Headings</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>All headings herein are for convenience of reference and shall not
  be used in interpreting this Agreement.</span></p>
  
  <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
  normal'><span lang=EN-US>&nbsp;</span></p>
  
  <p class=MsoListParagraph style='margin-left:72.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:18.0pt'><b><span lang=EN-US>30.<span
  style='font:7.0pt'>&nbsp; </span></span></b><b><span
  lang=EN-US>Contact us</span></b></p>
  
  <p class=MsoNormal style='text-align:justify;line-height:18.0pt'><span
  lang=EN-US>If you have any questions concerning the Licensor Service or this
  Agreement, please contact Licensor customer service at </span><span lang=EN-US><a
  href="mailto:info@insighealth.com">info@insighealth.com</a></span><span
  lang=EN-US>.</span></p>
  
  <p class=MsoNormal style='text-align:justify'><span lang=EN-US
  style='font-size:12.0pt;line-height:107%'>&nbsp;</span></p>
  
  </div>
