// @ts-strict-ignore
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'yesno-question',
  templateUrl: './yesno.component.html',
})
export class YesNoComponent implements OnChanges, OnDestroy {
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();
  @Input() pageElement = null;
  @Input() elementID = null;
  @Input() triggersDictionary = null;
  @Output() checkTriggersChild: EventEmitter<string> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  ngOnDestroy() {
    if (this.pageElement.triggerHidden === true) {
      this.removeAllTriggers();
    }
  }

  removeAllTriggers() {
    const triggersDictionary = this.triggersDictionary;
    const parentID = this.elementID;
    // triggers for this answer
    if (
      this.question.offeredAnswers[0].pageFlow &&
      this.question.offeredAnswers[0].pageFlow.trigger
    ) {
      this.question.offeredAnswers[0].pageFlow.trigger.forEach((child) => {
        if (triggersDictionary[child]) {
          triggersDictionary[child][parentID] = false;
        } else {
          console.log(
            'Incorrect JSON for this question. Subquestion array error'
          );
        }
      });
    }
    // remove triggers for other answer
    if (
      this.question.offeredAnswers[1].pageFlow &&
      this.question.offeredAnswers[1].pageFlow.trigger
    ) {
      this.question.offeredAnswers[1].pageFlow.trigger.forEach((child) => {
        if (triggersDictionary[child]) {
          triggersDictionary[child][parentID] = false;
        } else {
          console.log(
            'Incorrect JSON for this question. Subquestion array error'
          );
        }
      });
    }
    this.sendCheckTriggers();
  }

  sendCheckTriggers() {
    this.checkTriggersChild.emit('true');
  }

  changeAnswer(answer) {
    if (answer) {
      this.question.response.answer = 'Yes';
      this.modifyTriggers(1);
    } else {
      this.modifyTriggers(0);
      this.question.response.answer = 'No';
    }
  }

  modifyTriggers(answer) {
    const notAnswer = answer === 1 ? 0 : 1;

    const triggersDictionary = this.triggersDictionary;
    const parentID = this.elementID;
    // triggers for this answer
    if (
      this.question.offeredAnswers[answer].pageFlow &&
      this.question.offeredAnswers[answer].pageFlow.trigger
    ) {
      this.question.offeredAnswers[answer].pageFlow.trigger.forEach((child) => {
        if (triggersDictionary[child]) {
          triggersDictionary[child][parentID] = true;
        } else {
          console.error(
            'Incorrect JSON for this question. Subquestion array error'
          );
        }
      });
    }
    // remove triggers for other answer
    if (
      this.question.offeredAnswers[notAnswer].pageFlow &&
      this.question.offeredAnswers[notAnswer].pageFlow.trigger
    ) {
      this.question.offeredAnswers[notAnswer].pageFlow.trigger.forEach(
        (child) => {
          if (triggersDictionary[child]) {
            triggersDictionary[child][parentID] = false;
          } else {
            console.error(
              'Incorrect JSON for this question. Subquestion array error'
            );
          }
        }
      );
    }
    this.sendCheckTriggers();
  } // end modify trigs
} // end component
