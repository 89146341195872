<div class="page-err full-height-overflow">
  <div class="err-container text-center">
    <div class="err">
      <h2>Account Not Validated!</h2>
    </div>

    <div class="divider"></div>
    <div class="divider"></div>
    <div class="divider"></div>

    <div class="err">
      <h3>Please contact the clinic administrator to validate your account.</h3>
    </div>

    <div class="divider"></div>
    <div class="divider"></div>

    <div class="err">
      <h3>This login is for medical professionals.</h3>
    </div>

    <div class="divider"></div>
    <div class="divider"></div>

    <div class="err-body">
      <a mat-raised-button href="{{ APPURL }}" class="btn-lg">{{ APPURL }}</a>
    </div>
  </div>
</div>
