import { Component, inject } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'signature-terms-dialog',
  templateUrl: './signature-terms-dialog.component.html',
})
export class SignatureTermsDialogComponent {
  public readonly dialogRef = inject<MatDialogRef<SignatureTermsDialogComponent>>(MatDialogRef<SignatureTermsDialogComponent>);
  termsContent = null;
}
