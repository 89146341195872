<insig-sidenav-menu
  [menuItems]="menuItems"
  (addNewCategory)="addNewCategory()"
  (menuItemSelected)="menuItemSelected($event)"
  [selectedGroup]="selectedGroup"
  [selectedCategoryItem]="selectedCategoryItem"
>
  <div class="container-fluid no-breadcrumbs">
    <company-plan-list-header
      *ngIf="selectedCategory && !selectedService"
      (addNewService)="addNewService()"
      [selectedCategoryName]="selectedCategoryName"
      [(selectedPlan)]="selectedPlan"
      (companyPlanSettings)="companyPlanSettingsToggle(false)"
      (deleteCompanyPlan)="deleteCompanyPlan()"
      (copyPlanURL)="copyPlanURL()"
    >
      <company-plan-services-list
        [companyPlanVirtualServices]="companyPlanVirtualServices"
        (serviceClicked)="editService($event)"
        [adminView]="true"
        (addServicesBtnClicked)="addNewService()"
      >
      </company-plan-services-list>
    </company-plan-list-header>

    <shared-virtual-service
      *ngIf="selectedCategory && selectedService"
      (backBtnClicked)="back()"
      (deleteBtnClicked)="delete()"
      (saveBtnClicked)="save()"
      (setMinPrice)="setMinPrice($event)"
      (selectSurvey)="selectSurvey()"
      (copiedURLSuccess)="copiedURLSuccess()"
      [(selectedService)]="selectedService"
      [existingServicesArray]="companyPlanVirtualServices"
      [selectedServiceURL]="selectedServiceURL"
      [newService]="newService"
      [companyPlanAdmin]="true"
      (cancelBtnClicked)="cancel()"
      [publicBilling]="!!selectedPlan.publicBilling"
      [userData]="userData"
    >
    </shared-virtual-service>

    <main *ngIf="!selectedCategory" class="ml-auto mr-auto full-height">
      <div>
        <div>
          <h5>You have no company plan selected</h5>
          <h5>Please select one to edit</h5>
        </div>
        <svg viewBox="0 0 200 600">
          <polygon
            points="118.302698 8 59.5369448 66.7657528 186.487016 193.715824 14 366.202839 153.491505 505.694344 68.1413353 591.044514 200 591.044514 200 8"
          ></polygon>
        </svg>
      </div>
      <svg class="crack" viewBox="0 0 200 600">
        <polyline
          points="118.302698 8 59.5369448 66.7657528 186.487016 193.715824 14 366.202839 153.491505 505.694344 68.1413353 591.044514"
        ></polyline>
      </svg>
      <div>
        <svg viewBox="0 0 200 600">
          <polygon
            points="118.302698 8 59.5369448 66.7657528 186.487016 193.715824 14 366.202839 153.491505 505.694344 68.1413353 591.044514 0 591.044514 0 8"
          ></polygon>
        </svg>
        <div>
          <h5>Or</h5>
          <h5>
            <a (click)="addNewCategory()">
              <b>Create One?</b>
            </a>
          </h5>
        </div>
      </div>
    </main>

    <insig-bottom-sheet *ngIf="selectedPlan" [show]="showBottomSheet">
      <company-plan-settings
        [(selectedPlan)]="selectedPlan"
        (companyPlanSettingsToggle)="companyPlanSettingsToggle($event)"
        [subscribedCompanies]="subscribedCompanies"
        [subscribedUserGroups]="subscribedUserGroups"
        (addSubscribedCompany)="addSubscribedCompany()"
        (addSubscribedUserGroup)="addSubscribedUserGroup($event)"
        (removeSubscribedCompany)="removeSubscribedElement($event)"
        [userData]="userData"
      >
      </company-plan-settings>
    </insig-bottom-sheet>
  </div>
</insig-sidenav-menu>
