<div class="row">
  <div class="col-8 offset-2">
    <div class="form-group form-field">
      <mat-form-field class="full-width">
        <span matPrefix>
          <mat-icon class="notranslate material-icons">location_on</mat-icon
          >&nbsp;
        </span>
        <mat-label>Enter address / location</mat-label>
        <input
          matInput
          #searchAddress
          [(ngModel)]="question.response.answer"
        />
      </mat-form-field>
    </div>
  </div>
</div>
