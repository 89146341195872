<h6 class="text-left" (click)="question.hideGeneral = !question.hideGeneral">
  <i *ngIf="question.hideGeneral" class="material-icons">add_circle_outline</i>
  <i *ngIf="!question.hideGeneral" class="material-icons"
    >indeterminate_check_box</i
  >
  General
</h6>
<div class="text-center offset-3" [hidden]="question.hideGeneral">
  <div class="row">
    <div class="col-sm-6 text-left">
      <ng-container *ngFor="let answer of checkboxes; let i = index">
        <ng-container *ngIf="i < checkboxes.length / 2">
          <mat-checkbox [(ngModel)]="question.response.checkboxes[answer]">{{
            answer
          }}</mat-checkbox>
          <div class="divider"></div>
        </ng-container>
      </ng-container>
    </div>
    <div class="col-sm-6 text-left">
      <ng-container *ngFor="let answer of checkboxes; let i = index">
        <ng-container *ngIf="i >= checkboxes.length / 2">
          <mat-checkbox [(ngModel)]="question.response.checkboxes[answer]">{{
            answer
          }}</mat-checkbox>
          <div class="divider"></div>
        </ng-container>
      </ng-container>
    </div>
    <div class="col-12 text-left">
      <span>Other: </span>
      &nbsp;
      <mat-form-field class="w-75">
        <textarea
          rows="1"
          matInput
          type="text"
          autocomplete="off"
          [(ngModel)]="question.response.otherCheckbox"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</div>

<hr />

<h6 class="text-left" (click)="question.showBP = !question.showBP">
  <i *ngIf="!question.showBP" class="material-icons">add_circle_outline</i>
  <i *ngIf="question.showBP" class="material-icons">indeterminate_check_box</i>
  Blood Pressure
</h6>
<blood-pressure
  [hidden]="!question.showBP"
  [(question)]="question"
></blood-pressure>

<br />

<div class="table-responsive">
  <table *ngFor="let phys of defaultStructure" class="table table-bordered">
    <thead>
      <tr class="text-left">
        <td
          colspan="7"
          (click)="question.physical[phys.id] = !question.physical[phys.id]"
        >
          <i *ngIf="!question.physical[phys.id]" class="material-icons"
            >add_circle_outline</i
          >
          <i *ngIf="question.physical[phys.id]" class="material-icons"
            >indeterminate_check_box</i
          >
          <label>&nbsp;{{ phys.id }}</label>
        </td>
      </tr>
    </thead>
    <tbody *ngIf="question.physical[phys.id]">
      <tr>
        <td></td>
        <td>
          <label>Normal</label>
        </td>
        <td>
          <label>Abnormal</label>
        </td>
        <td colspan="3">
          <label>Description</label>
        </td>
      </tr>
      <ng-container *ngFor="let part of phys.sections">
        <tr
          *ngIf="
            part.parent == true ||
            question.response.physicalGrid[phys.id][part.parent].value ==
              'abnormal'
          "
        >
          <td class="text-left">
            <button
              class="float-right mt-3"
              matTooltip="Clear row"
              [matTooltipPosition]="'above'"
              mat-icon-button
              (click)="
                question.response.physicalGrid[phys.id][part.id].value = null;
                question.response.physicalGrid[phys.id][part.id].desc = null
              "
            >
              <i class="material-icons">clear</i>
            </button>

            <p class="mt-4 bold" *ngIf="part.parent == true">{{ part.id }}</p>

            <p class="mt-4" *ngIf="part.parent != true">
              <span class="space space-md"></span>
              {{ part.id }}
            </p>
          </td>
          <td>
            <br />
            <mat-radio-group
              [(ngModel)]="
                question.response.physicalGrid[phys.id][part.id].value
              "
            >
              <mat-radio-button [value]="'normal'"></mat-radio-button>
            </mat-radio-group>
            <ng-container
              *ngIf="
                part.sentence ||
                (question.standard[phys.id] &&
                  question.standard[phys.id][part.id])
              "
            >
              <button
                class="ml-3"
                matTooltip="Autofill normal description"
                [matTooltipPosition]="'above'"
                mat-icon-button
                (click)="autofillDescription(phys.id, part.id, part.sentence)"
              >
                <i class="material-icons">chat</i>
              </button>
            </ng-container>
          </td>
          <td>
            <br />
            <mat-radio-group
              [(ngModel)]="
                question.response.physicalGrid[phys.id][part.id].value
              "
            >
              <mat-radio-button [value]="'abnormal'"></mat-radio-button>
            </mat-radio-group>
          </td>
          <td colspan="4">
            <span
              class="mx-3 text-left"
              *ngFor="let box of part.options; let i = index"
            >
              <mat-checkbox
                [(ngModel)]="
                  question.response.physicalGrid[phys.id][part.id].options[i]
                    .selected
                "
                >{{ box.title }}</mat-checkbox
              >
            </span>
            <mat-form-field class="full-width">
              <textarea
                matInput
                autocomplete="off"
                [(ngModel)]="
                  question.response.physicalGrid[phys.id][part.id].desc
                "
              ></textarea>
            </mat-form-field>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
