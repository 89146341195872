<mat-card>
  <canvas #canvas class="signatureStyle"></canvas>

  <div class="divider"></div>

  <button mat-raised-button (click)="clear()">Clear</button>

  <button
    class="float-right"
    mat-raised-button
    color="primary"
    (click)="doneDrawing()"
  >
    Done
  </button>
</mat-card>
