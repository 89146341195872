import { Component, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';
import { LoadSurveyService } from 'insig-app/services/loadSurvey.service';
import { Survey } from 'insig-types/surveys/survey';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'survey-selection-dialog',
  templateUrl: 'survey-selection-dialog.component.html',
  providers: [LoadSurveyService],
})
export class SurveySelectionDialogComponent {
  public readonly data = inject<any>(MAT_DIALOG_DATA);
  public readonly dialogRef = inject<MatDialogRef<SurveySelectionDialogComponent, any>>(MatDialogRef<SurveySelectionDialogComponent, any>);
  private readonly firebaseAuthService = inject(FirebaseAuthService);
  private readonly loadSurveyService = inject(LoadSurveyService);
  public userSurveysObservable: Observable<Survey[]> =
    this.firebaseAuthService.onIdTokenChanged().pipe(
      switchMap((user) => {
        return this.loadSurveyService.getUserSurveysFromFirestore(user.uid);
      })
    );

  public librarySurveys: Promise<Survey[]> =
    this.loadSurveyService.getLibrarySurveysFromFirestore();
} // end class
