<div class="page-dashboard d-flex flex-row" id="navigation-parent-dashboard">
  <insig-navigation
    [menuItems]="menuItems"
    [(selectedTab)]="selectedTab"
  ></insig-navigation>

  <div class="d-flex flex-column flex main-navigation-container ">
    <!-- patient admin -->

    <patient-admin-history
      *ngIf="selectedTab == 'history' && !!userData"
      [patientAppointments]="patientAppointments"
      [patientAppointmentsSearched]="patientAppointmentsSearched"
      [userData]="userData"
      (getMorePatientAppointments)="getMorePatientAppointments()"
      (getSearchedPatientAppointments)="getSearchedPatientAppointments($event)"
      (getPatientAppointments)="getPatientAppointments()"
      [patientAdminLoading]="patientAdminLoading"
    >
    </patient-admin-history>
    <!-- end of patient admin components -->

    <main-dashboard
      *ngIf="selectedTab === 'dashboard' && !!userData"
      class="container-fluid no-breadcrumbs"
      [userId]="userData.uid"
      [companyId]="userData.company"
      [isPatientAdmin]="isPatientAdmin"
    >
    </main-dashboard>

    <survey-data-dashboard
      *ngIf="selectedTab == 'surveyData' && !!userData"
      class="full-height"
      [surveyDataInstances]="surveyDataInstances"
      [surveyDataTableItems]="surveyDataTableItems"
      [companyID]="userData.company"
      (getSurveyDataInstances)="getSurveyDataInstances($event)"
    >
    </survey-data-dashboard>

    <company-plans
      *ngIf="companyPlan && selectedTab == 'companyPlans' && !!userData"
      class="full-height"
      [userData]="userData"
      [userListFinal]="userListFinal"
      [librarySurveys]="librarySurveys"
    >
    </company-plans>

    <user-groups
      *ngIf="companyPlan && selectedTab == 'userGroups' && !!userData"
      class="full-height"
      [userData]="userData"
    >
    </user-groups>

    <admin-scheduler
      *ngIf="selectedTab == 'scheduler' && !!userData"
      class="full-height"
      [userData]="userData"
      [companyData]="companyData"
      [companyServices]="companyServices"
      [companyCategories]="companyCategories"
      [librarySurveys]="librarySurveys"
    >
    </admin-scheduler>

    <theme-customizer
      *ngIf="selectedTab == 'theme'"
      class="full-height"
      style="background-color:white;"
    >
    </theme-customizer>
  </div>
</div>
