// @ts-strict-ignore
import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

// Firebase
import firebase from 'firebase/compat/app';
import 'firebase/auth';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private readonly snackbar = inject(MatSnackBar);

  /**
   * Function that signs in the user based on the passed email and password
   * @param param0 contains the passed email and password
   * @returns a firebase user if sign in is a success or null otherwise
   */
  async signIn(
    {
      email,
      password,
    }: { email: string; password: string; },
  ): Promise<firebase.User> | null {
    const snackbar = this.snackbar;

    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        console.log(userCredential);
        return userCredential.user;
      })
      .catch((error: firebase.FirebaseError) => {
        console.error(error);
        // wrong password
        switch (error.code) {
          case 'auth/wrong-password':
            snackbar.open('Incorrect password!', null, { duration: 4000 });
            return null;
          case 'auth/user-not-found':
            snackbar.open('No user with this email exists!', null, {
              duration: 4000,
            });
            return null;
          case 'auth/network-request-failed':
            snackbar.open(
              'Failed to connect! Please ensure that you are connected to the internet.',
              null,
              { duration: 4000 },
            );
            return null;
          default:
            snackbar.open(error.message, null, { duration: 4000 });
            return null;
        }
      });
  } // end sign in func
}
