import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthComponent } from './auth.component';
import { AuthRoutingModule } from './auth-routing.module';

import { GlobalPatientModule } from '../global/patient/global-patient.module';
import { PageLoginComponent } from './page-login/login.component';
import { PageSignUpComponent } from './sign-up/sign-up.component';

import { Page404Component } from './404/404.component';
import { NotValidatedComponent } from './not-validated/not-validated.component';
import { Page500Component } from './500/500.component';
import { PageConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { PageMaintenanceComponent } from './maintenance/maintenance.component';
import { PatientDashboardComingComponent } from './patient-dashboard-coming/patient-dashboard-coming.component';
import { RedirectComponent } from './redirect/redirect.component';

import { MaterialComponentsModule } from '../material-components.module';

import { InputMasksModule } from '@insig-health/input-masks/input-masks.module';
import { InsigAppLoaderModule } from '../global/loader/loader.module';
import { PatientTermsOfUseModule } from '@insig-health/patient-terms-of-use/patient-terms-of-use.module';

import { InsigMaterialModule } from 'insig-app/insig-material/insig-material.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

// Libs
import { UiModule } from '@insig-health/ui';
import { DeactivateGuardComponent } from './deactivate-guard/deactivate-guard.component';
import { Page403Component } from './403/403.component';
import { PatientSignUpPageComponent } from './patient-sign-up-page/patient-sign-up-page.component';
import { LoginComponent } from './login/login.component';
import { GcpIpModule } from '@insig-health/gcp-ip/gcp-ip.module';
import { UiComponentsModule } from '@insig-health/components';
import { LoginContainerComponent } from './login-container/login-container.component';
import { LanguageDropdownComponent } from './language-dropdown/language-dropdown.component';
import { UpdateDoctorTermsDialogComponent } from './update-doctor-terms-dialog/update-doctor-terms-dialog.component';
import { UpdatePatientTermsDialogComponent } from './update-patient-terms-dialog/update-patient-terms-dialog.component';
import { DoctorTermsOfUseModule } from 'insig-app/doctor-terms-of-use/doctor-terms-of-use.module';
import { TaliAiWidgetModule } from 'insig-app/tali-ai-widget/tali-ai-widget.module';
import { TaliLoginComponent } from './tali-login/tali-login.component';

@NgModule({
  imports: [
    AuthRoutingModule,
    MaterialComponentsModule,
    FormsModule,
    InputMasksModule,
    ReactiveFormsModule,
    CommonModule,
    InsigAppLoaderModule,
    GlobalPatientModule,
    InsigMaterialModule,
    UiComponentsModule,
    UiModule,
    GcpIpModule,
    PatientTermsOfUseModule,
    DoctorTermsOfUseModule,
    TaliAiWidgetModule,
  ],
  declarations: [
    AuthComponent,
    PageLoginComponent,
    PageSignUpComponent,
    Page403Component,
    Page404Component,
    NotValidatedComponent,
    Page500Component,
    PageConfirmEmailComponent,
    PageMaintenanceComponent,
    PatientDashboardComingComponent,
    RedirectComponent,
    ResetPasswordComponent,
    DeactivateGuardComponent,
    PatientSignUpPageComponent,
    LanguageDropdownComponent,
    LoginComponent,
    LoginContainerComponent,
    UpdateDoctorTermsDialogComponent,
    UpdatePatientTermsDialogComponent,
    TaliLoginComponent,
  ],
  exports: [
    PageLoginComponent,
    LoginComponent,
    LoginContainerComponent,
  ],
})
export class AuthModule {}
