// @ts-strict-ignore
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadSurveyService } from 'insig-app/services/loadSurvey.service';
import { DemographicsAndDoctorFilterService } from 'insig-app/services/demographicsAndDoctorFilter.service';

import { take } from 'rxjs/operators';

@Component({
  selector: 'survey-question',
  templateUrl: './survey-question.component.html',
  providers: [LoadSurveyService, DemographicsAndDoctorFilterService],
})
export class SurveyQuestionComponent implements OnInit, OnDestroy {
  private readonly loadSurveyService = inject(LoadSurveyService);
  private readonly demographicsAndDoctorFilterService = inject(DemographicsAndDoctorFilterService);
  private readonly route = inject(ActivatedRoute);
  @Input() doctorCompleting: boolean;
  @Input() question: any;
  @Input() pageElement: any;
  @Input() patientInfo: any;
  @Input() elementID: string;
  @Input() page: number;
  @Input() position: number;
  @Input() mainSurvey: any;
  @Input() quickPreview = false;
  @Output() checkTriggersChild = new EventEmitter<string>();
  @Output() checkLoadedChild = new EventEmitter<boolean>();
  private triggeredSurvey: any;
  private userID: string;

  async ngOnInit() {
    if (this.quickPreview) {
      return;
    }

    if (!this.question.parentRemovalID) {
      this.question.parentRemovalID = Math.random() * 10;
    }

    try {
      this.userID = (await this.route.params.pipe(take(1)).toPromise()).userID;
      this.loadSurvey(this.question.survId, this.question.survUID);
      // set variable for child to watch if untriggered
      this.question.surveyQuestionTriggered = true;
    } catch (error) {
      console.error(error);
    }
  } // end ng init

  ngOnDestroy() {
    // watch if question is untriggered
    if (this.pageElement.triggerHidden) {
      this.surveyUntriggered();
    }
  }

  sendCheckTriggers() {
    this.checkTriggersChild.emit('true');
  }

  sendLoading(val: boolean): void {
    this.checkLoadedChild.emit(val);
  }

  // if survey un-triggered, hide its children, since children at
  // top level are linked to this variable
  surveyUntriggered() {
    console.log('Remove survey through survey-question component');
    this.removeSurvey();
    this.question.surveyQuestionTriggered = false;
    this.question.alreadyLoaded = false;
    this.sendCheckTriggers();
    delete this.question.liveName;
  }

  // iterate from back so splice works properly on indices
  removeSurvey(): void {
    const triggersDictionary = this.mainSurvey.triggersDictionary;
    const mainSurveyPages = this.mainSurvey.pages;
    for (
      let pageIndex = mainSurveyPages.length - 1;
      pageIndex >= 0;
      --pageIndex
    ) {
      const pageElements = mainSurveyPages[pageIndex].elements;
      for (
        let elementIndex = pageElements.length - 1;
        elementIndex >= 0;
        --elementIndex
      ) {
        if (
          pageElements[elementIndex].removalID === this.question.parentRemovalID
        ) {
          delete triggersDictionary[pageElements[elementIndex].id];
          pageElements.splice(elementIndex, 1);
        } // end if
      } // end loop elements
      if (pageElements.length === 0) {
        mainSurveyPages.splice(pageIndex, 1);
      }
    } // end loop pages
  } // end remove surveys

  // make every question in triggered survey a child of what triggered the survey
  // and add surv id so can remove if untriggered
  setupSurveyTriggers(): void {
    const triggersDictionary = this.mainSurvey.triggersDictionary;
    const surveyQuestionTriggered = this.question.surveyQuestionTriggered;
    const triggeredSurveyName = (this.question.liveName = this.triggeredSurvey.name);
    this.triggeredSurvey.pages.forEach((page) => {
      if (page.elements) {
        page.elements.forEach((element) => {
          triggersDictionary[element.id] = {};
          // if question is top level
          if (!element.pageFlow) {
            element.pageFlow = { child: true, parents: [this.elementID] };
            triggersDictionary[element.id][
              this.elementID
            ] = surveyQuestionTriggered;
          } else if (!element.pageFlow.parents) {
            element.pageFlow.child = true;
            element.pageFlow.parents = [this.elementID];
            triggersDictionary[element.id][
              this.elementID
            ] = surveyQuestionTriggered;
            // if question is a child in the triggered survey
          } else {
            element.pageFlow.parents.forEach((parent) => {
              triggersDictionary[element.id][parent] = false;
            });
          } // end if else
          // add survID to each quesiton so can remove if untriggered
          element.removalID = this.question.parentRemovalID;
          /////////////////////////////////////// Multiple scoring tags
          if (element.question && element.question.type === 'scoring') {
            element.originalSurvey = triggeredSurveyName;
          }
          ///////////////////////////////////////
        }); // end element iteration
      }
    }); // end page iteration
  } // end func survey trigs

  mergeSurveys(): void {
    if (!this.triggeredSurvey || !this.triggeredSurvey.pages) {
      console.log(this.triggeredSurvey);
      console.log('Error with triggeredSurvey');
      return;
    }
    this.setupSurveyTriggers();
    this.triggeredSurvey = this.demographicsAndDoctorFilterService.setAllFilters(
      this.triggeredSurvey,
      this.patientInfo,
      this.doctorCompleting
    );
    this.triggeredSurvey.pages.forEach((page, i) => {
      // Make sure page has at least 1 element
      if (page.elements.length > 0) {
        if (i === 0) {
          this.mainSurvey.pages[this.page].elements.splice(
            this.position + 1,
            0,
            ...page.elements
          );
        } else {
          this.mainSurvey.pages.splice(this.page + i, 0, page);
        }
      }
    });
  } // end merge surveys

  // dave

  async loadSurvey(surveyID, otherUserID?): Promise<void> {
    if (this.question.alreadyLoaded) {
      return;
    }
    this.sendLoading(true);

    try {
      let survey = null;
      // if using patient feedback try to load custom questionnaire
      if (this.mainSurvey.feedbackEnabled) {
        // library, another user, or your own survey
        if (this.question.triggeredLibrary) {
          try {
            survey = await this.loadSurveyService.getUserSurveyFromFirestore(
              this.userID,
              surveyID
            );
            if (!survey) {
              survey = await this.loadSurveyService.getLibrarySurveyFromFirestore(
                surveyID
              );
            }
          } catch (error) {
            survey = await this.loadSurveyService.getLibrarySurveyFromFirestore(
              surveyID
            );
          }
        } else if (otherUserID) {
          survey = await this.loadSurveyService.getUserSurveyFromFirestore(
            this.userID,
            surveyID
          );
          if (survey) {
            // switch survUID to userID because using their own version of survey
            this.question.survUID = this.userID;
          } else {
            survey = await this.loadSurveyService.getUserSurveyFromFirestore(
              otherUserID,
              surveyID
            );
          }
        } else {
          await this.loadSurveyService.getUserSurveyFromFirestore(
            this.userID,
            surveyID
          );
        }
      } else {
        survey = this.question.triggeredLibrary // Check if triggered survey is from the library or not
          ? await this.loadSurveyService.getLibrarySurveyFromFirestore(surveyID)
          : await this.loadSurveyService.getUserSurveyFromFirestore(
              otherUserID || this.userID,
              surveyID
            );
      }

      if (survey) {
        if (survey.pdfID && !this.mainSurvey.pdfID) {
          this.mainSurvey.pdfID = survey.pdfID;
        }
        this.triggeredSurvey = survey;
        this.question.triage =
          survey.triage || survey.folder === 'triage' ? true : false;
        this.mergeSurveys();
        this.sendCheckTriggers();
      } else {
        console.error('error loading survey');
      }
      this.question.alreadyLoaded = true;
    } catch (error) {
      console.error(error);
    } finally {
      this.sendLoading(false);
    }
  } // end load survey
} // end component
