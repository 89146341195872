// @ts-strict-ignore
import { Component, inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirm-delete-survey-dialog',
  templateUrl: './confirm-delete-survey-dialog.component.html',
})
export class ConfirmDeleteSurveyDialogComponent {
  public readonly dialogRef = inject<MatDialogRef<Component>>(MatDialogRef<Component>);
  public readonly data = inject(MAT_DIALOG_DATA);
} // end class
