import { Component, Input } from '@angular/core';

@Component({
  selector: 'insig-ui-profile-picture',
  templateUrl: './insig-profile-picture.component.html',
})
export class InsigProfilePictureComponent {
  public InsigProfilePictureComponent = InsigProfilePictureComponent;
  public static readonly DEFAULT_PROFILE_PICTURE_URL = './assets/img/default-profile.png';

  @Input() imageUrl: string | undefined;
  @Input() alt = '';
  @Input() width: string | undefined;
  @Input() height: string | undefined;
}
