<mat-card [ngStyle]="{ 'min-width': '65vw' }">
  <h1 matDialogTitle>
    {{
      currentScreen === 'locationSelection'
        ? 'Select A Location'
        : 'Select A Questionnaire'
    }}
  </h1>
  <mat-dialog-content>
    <div class="container" *ngIf="currentScreen === 'locationSelection'">
      <div class="row">
        <div class="col-sm-6" *ngFor="let location of locations">
          <div class="card bg-color-white">
            <button mat-button (click)="setLocation(location)">
              <div class="card-content">
                <span>{{ location.name }}</span>
              </div>
            </button>
            <div class="card-action">
              <button
                (click)="setLocation(location)"
                mat-button
                matTooltip="Select Location"
                class="float-right"
                [matTooltipPosition]="'above'"
              >
                <mat-icon>play_arrow</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-tab-group *ngIf="currentScreen !== 'locationSelection'">
      <!-- begin practitioner tab -->
      <mat-tab label="Practitioner Questionnaires">
        <br />
        <mat-form-field>
          <mat-label>Select a Practitioner</mat-label>
          <mat-select
            class="my-3"
            [(ngModel)]="doctorID"
            name="user"
          >
            <mat-option
              *ngFor="let user of userList"
              (click)="getSurveysForDoctor(user.uid)"
              [value]="user.uid"
            >
              {{ user.fullName ? user.fullName : user.first + ' ' + user.last }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <br />

        <ng-container *ngIf="!!doctorSurveys">
          <ng-container
            *ngIf="doctorSurveys.length !== 0; else noDoctorSurveys"
          >
            <hr />
            <mat-form-field class="full-width mat-icon-left">
              <mat-icon matPrefix class="material-icons">search</mat-icon>
              <mat-label>Search</mat-label>
              <input matInput #searchSurveys />
            </mat-form-field>

            <div class="container">
              <div class="row">
                <div
                  class="col-sm-6"
                  *ngFor="
                    let survey of doctorSurveys
                      | searchpipe: 'name':searchSurveys.value
                  "
                >
                  <div class="card bg-color-white">
                    <button mat-button (click)="setSurvey(survey, 'user')">
                      <div class="card-content">
                        <span>{{ survey.name }}</span>
                      </div>
                    </button>
                    <div class="card-action">
                      <button
                        (click)="setSurvey(survey, 'user')"
                        mat-button
                        matTooltip="Select Questionnaire"
                        class="float-right"
                        [matTooltipPosition]="'above'"
                      >
                        <mat-icon>play_arrow</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-template #noDoctorSurveys>
          <div class="text-center">This practitioner has no surveys.</div>
        </ng-template>
      </mat-tab>
      <!-- end practitioner tab -->

      <!-- begin library tab -->
      <mat-tab label="Library">
        <mat-form-field class="my-4 full-width mat-icon-left">
          <mat-icon matPrefix class="material-icons">search</mat-icon>
          <mat-label>Search</mat-label>
          <input matInput #searchLibrary />
        </mat-form-field>

        <div class="container">
          <div class="row">
            <div
              class="col-sm-6"
              *ngFor="
                let survey of librarySurveys
                  | searchpipe: 'name':searchLibrary.value
              "
            >
              <div class="card bg-color-white">
                <button mat-button (click)="setSurvey(survey, 'library')">
                  <div class="card-content">
                    <span>{{ survey.name }}</span>
                  </div>
                </button>
                <div class="card-action">
                  <button
                    (click)="setSurvey(survey, 'library')"
                    mat-button
                    matTooltip="Select Questionnaire"
                    class="float-right"
                    [matTooltipPosition]="'above'"
                  >
                    <mat-icon>play_arrow</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <!-- end library tab -->
    </mat-tab-group>
  </mat-dialog-content>

  <mat-progress-spinner
    *ngIf="loading"
    class="mx-auto"
    mode="indeterminate"
  ></mat-progress-spinner>

  <hr />

  <mat-dialog-actions class="d-flex justify-content-between">
    <button mat-button (click)="dialogRef.close()">Cancel</button>
    <button
      class="float-right"
      *ngIf="appointment"
      color="primary"
      mat-raised-button
      (click)="dialogRef.close('default')"
    >
      Default Questionnaire
    </button>
    <span class="space float-right"></span>
    <button
      class="float-right"
      mat-raised-button
      (click)="dialogRef.close('none')"
    >
      None
    </button>
  </mat-dialog-actions>
</mat-card>
