// @ts-strict-ignore
import { Injectable } from '@angular/core';

@Injectable()
export class GoogleTagManagerService {

  gtag(_one, _two?, _three?) {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push(arguments);
  }


  pushTag(tag:object){

    let dataLayer = window['insigDataLayer'];
    if(!dataLayer){
      dataLayer = [];
    }

    try{
      dataLayer.push(tag)
    }catch(err){
      console.log("erroro pushing to insigDataLayer")
    }
  }

} // end service
