<div class="auth-page full-width bg-white">
  <div *ngIf="loading">
    <div class="divider"></div>
    <div class="divider"></div>
    <insig-app-loader class="mx-auto"></insig-app-loader>
  </div>

  <div class="p-5 bg-white" [hidden]="loading">
    <patient-sign-up
      [patientLoginSignUp]="true"
      [hidePharmacy]="companyData?.hidePharmacy"
      [tiaHealth]="tiaHealth"
      [partialPatient]="partialPatient"
      (backToLogin)="backToLogin()"
      (onSignUp)="onSignUp($event)"
      (onProfileComplete)="onProfileComplete()"
    >
    </patient-sign-up>
  </div>
</div>
