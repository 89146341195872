// @ts-strict-ignore
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'collect-payment',
  templateUrl: './collect-payment.component.html',
  styleUrls: ['./collect-payment.component.scss'],
})
export class CollectPaymentComponent implements OnChanges {
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();
  @Input() companyID:any;
  @Input() patientInfo: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }
}
