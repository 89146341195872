import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MILLISECONDS_PER_HOUR, MILLISECONDS_PER_MINUTE } from '@insig-health/services/date-and-time/date-and-time.constants';

@Component({
  selector: 'time-segment-duration-warning-dialog',
  templateUrl: './time-segment-duration-warning-dialog.component.html',
})
export class TimeSegmentDurationWarningDialogComponent {
  private readonly data = inject<{ operatingStartTime: number, operatingEndTime: number }>(MAT_DIALOG_DATA);
  public operatingStartTime: number;
  public operatingEndTime: number;

  constructor(
) {
    this.operatingStartTime = this.data.operatingStartTime;
    this.operatingEndTime = this.data.operatingEndTime;
  }

  getOperatingTimeString(operatingTime: number): string {
    const hours = Math.floor(operatingTime / MILLISECONDS_PER_HOUR);
    const minutes = Math.floor((operatingTime - hours * MILLISECONDS_PER_HOUR) / MILLISECONDS_PER_MINUTE);

    const amOrPm = hours < 12 ? 'AM' : 'PM';
    const amOrPmHour = hours % 12 === 0 ? 12 : hours % 12;
    const minutesPadding = minutes < 10 ? '0' : '';
    
    return `${amOrPmHour}:${minutesPadding}${minutes} ${amOrPm}`;
  }

  getOperatingTimeDurationString(operatingTimeDuration: number): string {
    const hours = Math.floor(operatingTimeDuration / MILLISECONDS_PER_HOUR);
    const minutes = Math.floor((operatingTimeDuration - hours * MILLISECONDS_PER_HOUR) / MILLISECONDS_PER_MINUTE);

    const hoursString = hours === 0 ? '' : `${hours} hour(s)`;
    const minutesString = minutes === 0 ? '' : `${minutes} minute(s)`;

    if (hoursString && minutesString) {
      return `${hoursString} and ${minutesString}`;
    } else {
      return `${hoursString}${minutesString}`;
    }
  }
}
