<mat-card>
  <mat-toolbar class="d-flex justify-content-between">
    <span>{{ data.type === 'user' ? 'Your Questionnaires' : 'Library' }}</span>
    <button mat-icon-button (click)="dialogRef.close()">
      <mat-icon class="notranslate">clear</mat-icon>
    </button>
  </mat-toolbar>

  <ng-container
    *ngIf="
      (data.type === 'user' ? userSurveysObservable : librarySurveys)
        | async as surveys;
      else loading
    "
  >
    <mat-form-field class="full-width">
      <mat-label>Search</mat-label>
      <input matInput autofocus #searchSurveys />
    </mat-form-field>
    <mat-dialog-content>
      <mat-list>
        <mat-list-item
          *ngFor="
            let survey of surveys | searchpipe: 'name':searchSurveys.value
          "
          (click)="dialogRef.close(survey)"
        >
          {{ survey.name }}
        </mat-list-item>
      </mat-list>
    </mat-dialog-content>
  </ng-container>
</mat-card>

<ng-template #loading>
  <mat-progress-spinner
    class="mx-auto"
    mode="indeterminate"
  ></mat-progress-spinner>
</ng-template>
