// @ts-strict-ignore
import { Injectable, inject } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { take } from 'rxjs/operators';
import { DoctorCreationRequest, DoctorManagementService, DoctorSettingsService, SuccessResponse } from '@insig-health/api/doctor-api';
import { FirebaseAuthService } from './firebase-auth/firebase-auth.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserManagerService {
  private readonly apollo = inject(Apollo);
  private readonly doctorManagementService = inject(DoctorManagementService);
  private readonly doctorSettingsService = inject(DoctorSettingsService);
  private readonly firebaseAuthService = inject(FirebaseAuthService);
  // graphql queries
  private userDataMutation = gql`
    mutation UserDataMutation($userID: ID!, $token: ID!, $data: UserInput!) {
      setUserData(uid: $userID, token: $token, data: $data) {
        uid
      }
    }
  `;
  //

  async addUser(companyId: string, newUserData: DoctorCreationRequest): Promise<void> {
    await this.doctorManagementService.createDoctor(companyId, newUserData).pipe(take(1)).toPromise();
  }

  /**
   * Convenience method for editing user data. You should write your own
   * mutation if you intend to receive post-mutation data as well.
   * @param  {String} userId   uid of the user to mutate
   * @param  {any}    userData the data values to mutate to
   */
  async editUser(userId: string, userData: any) {
    try {
      const token = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();
      await this.apollo
        .mutate({
          mutation: this.userDataMutation,
          variables: {
            userID: userId,
            token,
            data: userData,
          },
        })
        .toPromise();
    } catch (error) {
      console.log(error);
    }
  }

  async deleteUser(companyId: string, doctorId: string): Promise<void> {
    await this.doctorManagementService.deleteDoctor(companyId, doctorId).pipe(take(1)).toPromise();
  }

  async getDoctorNudgeEmailSetting(companyId: string, doctorId: string): Promise<boolean> {
    const response = await firstValueFrom(this.doctorSettingsService.getDoctorNudgeEmailSetting(companyId, doctorId));
    return response.nudgeEmailEnabled;
  }

  async updateDoctorNudgeEmailSetting(companyId: string, doctorId: string, isNudgeEmailEnabled: boolean): Promise<SuccessResponse> {
    const response = await firstValueFrom(this.doctorSettingsService.updateDoctorNudgeEmailSetting(companyId, doctorId, {
      nudgeEmailEnabled: isNudgeEmailEnabled,
    }));
    return response;
  }
}
