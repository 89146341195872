import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'book-appointment-edit',
  templateUrl: './book-appointment-edit.component.html',
  styleUrls: ['./book-appointment-edit.component.scss'],
})
export class BookAppointmentEditComponent {
  @Input() public question: any;
  @Output() public questionChange = new EventEmitter<any>();
}
