// @ts-strict-ignore
import {
  Component,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter, inject } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { InitNoteService } from 'insig-app/services/initNote.service';
import { MatDialog } from '@angular/material/dialog';

import { FlagColorsService } from '../../services/flagColors.service';
import { TriggerDialogComponent } from '../trigger-dialog.component';
import { QuestionTypesDialogComponent } from '../question-types-dialog.component';

import { Survey } from 'insig-types/surveys/survey';
import { Page } from 'insig-types/surveys/page';
import {
  Element,
  QuestionElement,
  ParagraphElement,
} from 'insig-types/surveys/element';
import { QuestionType } from 'insig-types/surveys/question';

@Component({
  selector: 'edit-element',
  templateUrl: './edit-element.component.html',
  providers: [InitNoteService, FlagColorsService],
})
export class EditElementComponent implements OnChanges {
  private readonly initNoteService = inject(InitNoteService);
  private readonly dialog = inject(MatDialog);
  public readonly flagColorsService = inject(FlagColorsService);
  // Required inputs for base functionality
  /** The element object this component represents */
  @Input() element: Element; // TODO make this immutable so that we can use OnPush change detection for performance improvements
  /** Emits element if recursive changes to it are made */
  @Output() elementChange = new EventEmitter<Element>();
  /** Whether or not to show the active view. Defaults to true. */
  private _isActive = true;
  @Input() set isActive(value: boolean) {
    if (this._isActive !== value) {
      this._isActive = value;
      if (value === false) {
        // Deactivate filters and notes views when this card becomes inactive
        this.filtersToggle = false;
        this.notesToggle = false;
      }
    }
  }
  get isActive(): boolean {
    return this._isActive;
  }

  /** The surveyID to save external files under. Optional but recommended for organization purposes. */
  @Input() surveyID?: string = this.generateRandomID(32);
  /** The userID to save external files under. Required for read/write permissions. */
  @Input() userID: string;

  // Required inputs for full survey edit functionality
  /** The survey this element is part of */
  @Input() survey?: Survey; // TODO add strict typing
  @Output() surveyChange = new EventEmitter<Survey>();
  /** The page in the survey this element is part of */
  @Input() page?: Page; // TODO add strict typing
  @Output() pageChange = new EventEmitter<Page>();
  /** A dictionary of available elements in the survey. Used for performance optimizations. */
  @Input() questionDict?: {
    [elementId: string]: { element: Element; pageNumber: number };
  };
  @Input() getParentQuestionsTooltip?: (child: Element) => string;
  @Input() getParentQuestionsList?: (child: Element) => Element[];
  @Input() setActiveElement?: (elementID: string) => void;
  @Input() togglePractitionerQuestion?: (element: Element) => void;
  @Input() moveElementUp?: (page: Page, elementIndex: number) => void; // TODO add strict typing
  @Input() moveElementDown?: (page: Page, elementIndex: number) => void; // TODO add strict typing
  @Input() deleteElement?: (page: Page, elementIndex: number) => void; // TODO add strict typing
  @Input() copyElement?: (
    page: Page,
    elementIndex: number,
    element: Element
  ) => void; // TODO add strict typing

  /** Whether or not to show the filters view */
  public filtersToggle = false;
  /** Whether or not to show the notes view */
  public notesToggle = false;
  /** A set of available note locations */
  public noteLocations: Set<string>;

  constructor(
) {
    this.noteLocations = this.getAllNoteLocations();
  }

  ngOnChanges(_changes: SimpleChanges) {
    if (this.survey) {
      // If there is a survey, check for custom note locations in other elements
      this.noteLocations = this.getAllNoteLocations();
    }
  }

  /**
   * Change the question of the provided element to the specified type
   * @param  {Element} oldElement The element of the question to change
   * @param  {string}  type       The question type to change to
   * @return {Element} The new element, containing a question or paragraph of the specified type
   */
  changeQuestionType(oldElement: Element, type: 'paragraph'): ParagraphElement;
  changeQuestionType(oldElement: Element, type: QuestionType): QuestionElement;
  changeQuestionType(
    oldElement: Element,
    type: 'paragraph' | QuestionType
  ): Element;
  changeQuestionType(
    oldElement: Element,
    type: 'paragraph' | QuestionType
  ): Element {
    // #region change to paragraph
    if (type === 'paragraph') {
      if (oldElement.type === 'paragraph' && !!oldElement.paragraph) {
        // Element is already a paragraph element
        return oldElement; // Do nothing
      } else {
        // Element is currently a question, or is missing the paragraph property
        return Object.assign(oldElement, {
          // Add the paragraph
          type: 'paragraph',
          paragraph: {
            html: '',
            id: this.generateRandomID(32),
          },
          // Remove the question
          question: null,
        });
      }
    }
    // #endregion change to paragraph

    // #region change to question
    if (oldElement.type === 'question' && oldElement.question.type === type) {
      return oldElement;
    }

    const oldQuestion =
      oldElement.type === 'question'
        ? oldElement.question
        : {
            id: this.generateRandomID(32),
            type: null,
            required: false,
            text: '',
          };

    /*
     *  When switching from a triggering question type to a non-triggering
     *  question type we have to remove all the triggers this question causes
     */
    if (
      (oldQuestion.type === 'yesno' ||
        oldQuestion.type === 'radio' ||
        oldQuestion.type === 'checkbox' ||
        oldQuestion.type === 'multiyesno' ||
        oldQuestion.type === 'select') &&
      oldElement.pageFlow
    ) {
      if (oldElement.pageFlow.parent) {
        // Remove all triggers this question causes
        const children = oldElement.pageFlow.children.slice();
        if (!!children && !!this.survey) {
          children.forEach((child) => {
            // Search every element of every page for the child
            for (const iPage of this.survey.pages) {
              for (const iElement of iPage.elements) {
                if (iElement.id === child) {
                  // Child is found, remove all parent references
                  if (iElement.pageFlow.parents !== undefined) {
                    const parentIndex = iElement.pageFlow.parents.indexOf(
                      oldElement.id
                    );
                    if (parentIndex !== -1) {
                      iElement.pageFlow.parents.splice(parentIndex, 1);
                      if (iElement.pageFlow.parents.length === 0) {
                        iElement.pageFlow.child = false;
                      }
                    }
                  }
                  return; // Finished with this child, go to next one
                }
              }
            }
            console.warn('Child id not found: ' + child);
          });
        }
      }
    }

    const newQuestion = {
      id: oldQuestion.id,
      required: oldQuestion.required,
      text: oldQuestion.text,
      type,
      allowMultiple: null,
    };

    // add allowMultiple field to autofill type questions
    if (type === 'doctors' || type === 'autocomplete') {
      newQuestion.allowMultiple = false;
    }

    // auto put a note together for some questions
    if (type === 'medicationAllergies') {
      oldElement.note = { location: 'Medication Allergies' };
      newQuestion.text = 'Medication Allergies';
    } else if (type === 'medications') {
      newQuestion.allowMultiple = true;
      oldElement.note = {
        location: 'Medications',
        first: '%%-his-her-c medications include ',
      };
      newQuestion.text = 'Please enter your medications';
    } else if (type === 'conditions') {
      oldElement.note = { location: 'Past Medical History' };
      newQuestion.text = 'Medical Conditions';
    } else if (type === 'physicalGrid') {
      oldElement.note = { location: 'Physical Exam' };
      oldElement.doctor = true;
    } else if (type === 'bloodPressure') {
      oldElement.note = { location: 'HPI' };
      newQuestion.text = 'Blood Pressure';
    } else if (type === 'surgery') {
      oldElement.note = { location: 'Past Surgical History' };
      newQuestion.text = 'Surgical History';
    } else if (type === 'heightWeight') {
      oldElement.note = { location: 'Patient Profile' };
      newQuestion.text = 'Please enter your height and weight';
    } else if (type === 'address') {
      oldElement.note = {
        location: 'Patient Profile',
        first: '%%-his-her-c address is',
      };
      newQuestion.text = 'Please enter your home address';
    } else if (type === 'shareNote') {
      newQuestion.text =
        'Do you consent to sending your contact info and questionnaire results to ...';
      oldElement.note = { location: 'Sharing Questionnaire Consent' };
    }

    return { ...oldElement, question: newQuestion } as QuestionElement; // Hard cast as QuestionElement since typescript is unsure if it can be a ParagraphElement
    // #endregion change to question
  }

  /**
   * Generates a random alphanumeric string to use as an identifier
   * // TODO adstract this function and similar functions into a global service
   * @param length The length of the id to generate
   */
  generateRandomID(length: number): string {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  /**
   * Changes the gender filter of an element
   * @param element The element whose filter to change
   * @param event The associated DOM event
   * @return The element
   */
  changeGenderFilter(element: Element, event: MatTabChangeEvent): Element {
    const gender = event.index.toString();
    if (gender === '0' || gender === '1' || gender === '2') {
      if (!element.filter) {
        element.filter = {};
      }
      element.filter.gender = gender;
    }
    return element;
  }

  /**
   * Changes the age filter of an element
   * @param element The element whose filter to change
   * @param event The associated DOM event
   * @return The element
   */
  changeAgeFilter(element: Element, event: MatTabChangeEvent): Element {
    const ageFilterType = event.index.toString();
    if (
      ageFilterType === '0' ||
      ageFilterType === '1' ||
      ageFilterType === '2' ||
      ageFilterType === '3'
    ) {
      if (!element.filter) {
        element.filter = {};
      }
      element.filter.ageType = ageFilterType;
      switch (element.filter.ageType) {
        case '1': // younger, set ageMax only
          element.filter.ageMin = null;
          if (element.filter.ageMax === undefined) {
            element.filter.ageMax = null;
          }
          break;
        case '3': // older, set ageMin only
          if (element.filter.ageMin === undefined) {
            element.filter.ageMin = null;
          }
          element.filter.ageMax = null;
          break;
        case '2': // between, set both ageMin and ageMax
          if (element.filter.ageMin === undefined) {
            element.filter.ageMin = null;
          }
          if (element.filter.ageMax === undefined) {
            element.filter.ageMax = null;
          }
          break;
        case '0': // none, set neither ageMin nor ageMax
          element.filter.ageMin = null;
          element.filter.ageMax = null;
          break;
      }
    }
    return element;
  }

  /**
   * Enforce that the ageMin of an element's filter is not greater than ageMax
   * @param element The element to check
   * @return The element
   */
  validateAgeMin(element: Element): Element {
    const filter = element.filter;
    if (!!filter.ageMax && filter.ageMin > filter.ageMax) {
      filter.ageMin = filter.ageMax;
    }
    return element;
  }

  /**
   * Enforce that the ageMax of an element's filter is not lower than ageMin
   * @param element The element to check
   * @return The element
   */
  validateAgeMax(element: Element): Element {
    const filter = element.filter;
    if (!!filter.ageMin && filter.ageMax < filter.ageMin) {
      filter.ageMax = filter.ageMin;
    }
    return element;
  }

  /**
   * Change the note location of an element
   * @param element The element to change the note location of
   * @param location The note location to change to
   * @return The element
   */
  changeNoteLocation(element: Element, location: string): Element {
    if (!element.note) {
      element.note = {};
    }
    element.note.location = location;
    this.noteLocations.add(location);
    return element;
  }

  /**
   * Returns a set of standard note locations and custom note locations in the survey
   * @return Set of note locations
   */
  getAllNoteLocations(): Set<string> {
    // Initialize with standard note locations
    const allNoteLocations = new Set(this.initNoteService.getNoteLocations());
    // Add survey's custom note locations
    if (this.survey) {
      for (const page of this.survey.pages) {
        if (
          !!page.elements &&
          !!page.elements.length &&
          page.elements.length >= 1
        ) {
          // Only iterate over pages with elements
          for (const element of page.elements) {
            if (!!element.note && !!element.note.location) {
              allNoteLocations.add(element.note.location);
            }
          }
        }
      }
    }
    return allNoteLocations;
  }

  /**
   * Converts a paragraph element into a question element
   * @param element The paragraph element to convert
   * @param type    The question type to convert to, defaults to 'text'
   * @return The new element
   */
  changeToQuestion(
    element: ParagraphElement,
    type: QuestionType = 'text'
  ): QuestionElement {
    delete element.paragraph;
    return {
      ...element,
      type: 'question',
      question: {
        id: this.generateRandomID(32),
        required: false,
        text: '',
        type,
      },
    };
  }

  /**
   * Changes the type of an element to whatever is selected via a dialog
   * @param oldElement The element to change the type of
   */
  openQuestionTypesDialog(oldElement: Element): void {
    this.dialog
      .open<QuestionTypesDialogComponent, QuestionType | 'paragraph'>(
        QuestionTypesDialogComponent,
        { width: '950px' }
      )
      .afterClosed()
      .subscribe((selection) => {
        if (selection) {
          oldElement = this.changeQuestionType(oldElement, selection);
          this.elementChange.emit(oldElement);
        }
      });
  }

  /**
   * Open the trigger dialog and apply requested changes
   * @function
   * @param initialId The id of the option to initially set triggers for
   */
  openTriggerDialog: (initialId: string) => void = (initialId) => {
    if (!this.survey) {
      // The full survey must be provided for triggers to be added/removed
      console.error('No survey provided for triggering');
    } else if (
      !!this.element &&
      this.element.type === 'question' &&
      this.element.question.type !== 'multiyesno'
    ) {
      const dialogRef = this.dialog.open(TriggerDialogComponent, {
        width: '80vw',
        height: '80vh',
        data: {
          initialId,
          element: this.element,
          survey: this.survey,
        },
      });
      dialogRef.afterClosed().subscribe(() => {
        if (this.element.type === 'question') {
          // Type safe check
          this.element.question = JSON.parse(
            JSON.stringify(this.element.question)
          ); // Trigger onPush change detection
          this.elementChange.emit(this.element);
          if (this.page) {
            this.pageChange.emit(this.page);
          }
          if (this.survey) {
            this.surveyChange.emit(this.survey);
          }
        }
      });
    } else {
      console.error('This element is incompatible for triggering');
    }
  };

  /**
   * Open the dialog for adding/removing triggers to a multi yes no type question
   * @param initialId The id of the option to initially set triggers for
   * @param yesNoOption Whether to set triggers for the yes option or the no option
   */
  openMultiYesNoTriggerDialog: (
    initialId: string,
    yesNoOption: 'Yes' | 'No'
  ) => void = (initialId, yesNoOption) => {
    if (!this.survey) {
      // The full survey must be provided for triggers to be added/removed
      console.error('No survey provided for triggering');
    } else if (
      !!this.element &&
      this.element.type === 'question' &&
      this.element.question.type === 'multiyesno'
    ) {
      const dialogRef = this.dialog.open(TriggerDialogComponent, {
        width: '80vw',
        height: '80vh',
        data: {
          initialId,
          yesNoOption,
          element: this.element,
          survey: this.survey,
        },
      });
      dialogRef.afterClosed().subscribe(() => {
        if (this.element.type === 'question') {
          // Type safe check
          this.element.question = JSON.parse(
            JSON.stringify(this.element.question)
          ); // Trigger onPush change detection
          this.elementChange.emit(this.element);
          if (this.page) {
            this.pageChange.emit(this.page);
          }
          if (this.survey) {
            this.surveyChange.emit(this.survey);
          }
        }
      });
    } else {
      console.error('This element is incompatible for multiyesno triggering');
    }
  };

  /** An array mapping QuestionType | 'paragraph' to a user friendly string */
  public questionTypes: Array<{
    type: QuestionType | 'paragraph';
    name: string;
  }> = [
    { type: 'text', name: 'Single Line Text' },
    { type: 'textarea', name: 'Multi Line Text' },
    { type: 'radio', name: 'Radio' },
    { type: 'scoring', name: 'Scoring' },
    { type: 'sendCommunication', name: 'Send Communication' },
    { type: 'bookAppointment', name: 'Referral Network' },
    { type: 'checkbox', name: 'Checkbox' },
    { type: 'select', name: 'Select' },
    { type: 'grid', name: 'Grid' },
    { type: 'physicalGrid', name: 'Physical Grid' },
    { type: 'yesno', name: 'Yes / No' },
    { type: 'multiyesno', name: 'Multi Yes / No' },
    { type: 'priority', name: 'Priority' },
    { type: 'number', name: 'Number' },
    { type: 'date', name: 'Date' },
    { type: 'time', name: 'Time' },
    { type: 'yearMonthDay', name: 'Year Month Day' },
    { type: 'dateRange', name: 'Date Range' },
    { type: 'email', name: 'Email' },
    { type: 'range', name: 'Range' },
    { type: 'link', name: 'Link' },
    { type: 'fiveStar', name: 'Five Star' },
    { type: 'drawing', name: 'Drawing' },
    { type: 'diagram', name: 'Diagram' },
    { type: 'signature', name: 'Signature' },
    { type: 'address', name: 'Address / Location' },
    { type: 'phone', name: 'Phone' },
    { type: 'photograph', name: 'Photograph/Image' },
    { type: 'video', name: 'Video' },
    { type: 'doctors', name: 'Doctors' },
    { type: 'bloodPressure', name: 'Blood Pressure' },
    { type: 'conditions', name: 'Medical Conditions' },
    { type: 'heightWeight', name: 'Height & Weight' },
    { type: 'medicationAllergies', name: 'Medication Allergies' },
    { type: 'surgery', name: 'Surgical History' },
    { type: 'medications', name: 'Medications' },
    { type: 'autocomplete', name: 'Autocomplete' },
    { type: 'pdf', name: 'PDF' },
    { type: 'survey', name: 'Questionnaire' },
    { type: 'paragraph', name: 'Paragraph' },
    { type: 'collectPayment', name: 'Collect Payment' },
  ];
}
