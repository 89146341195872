<ul class="nav" myAccordionNav myAppendSubmenuIcon *ngIf="!loading">
  <li
    class="p-0"
    *ngIf="patient && bookingUrl !== undefined"
  >
    <a
      id="global-booker-sidenav"
      mat-button
      class="p-2"
      (click)="handleSideNavButtonClicked(bookingUrl)"
      [ngClass]="{
        'primary-color-full':
          location.path().split('/')[2] === 'virtual' &&
          location.path().split('/')[3] === 'book-appointment'
      }"
    >
      <div
        class="h-100 w-100 d-flex flex-row flex-lg-column justify-content-sidenav align-items-center"
      >
        <mat-icon class="notranslate">event</mat-icon>
        <span class="nav-text ml-4 ml-lg-0 text-left text-lg-center">New Appointment</span>
      </div>
    </a>
  </li>

  <li class="p-0" [hidden]="!admin || (!patient && !admin)">
    <a
      id="profile-sidenav"
      mat-button
      class="p-2"
      (click)="handleSideNavButtonClicked('/app/admin')"
      [ngClass]="{
        'primary-color-full': location.path().split('/')[2] === 'admin'
      }"
    >
      <div
        class="h-100 w-100 d-flex flex-row flex-lg-column justify-content-sidenav align-items-center"
      >
        <mat-icon class="notranslate">account_balance</mat-icon>
        <span class="nav-text ml-4 ml-lg-0 text-left text-lg-center">Admin</span>
      </div>
    </a>
  </li>

  <li tourAnchor="profile.tour" class="p-0">
    <a
      id="profile-sidenav"
      mat-button
      class="p-2"
      (click)="handleSideNavButtonClicked('/app/profile')"
      [ngClass]="{
        'primary-color-full': location.path().split('/')[2] === 'profile'
      }"
    >
      <div
        class="h-100 w-100 d-flex flex-row flex-lg-column justify-content-sidenav align-items-center"
      >
        <mat-icon class="notranslate">account_circle</mat-icon>
        <span class="nav-text ml-4 ml-lg-0 text-left text-lg-center">Profile</span>
      </div>
    </a>
  </li>

  <li tourAnchor="virtual-care.tour" class="p-0" [hidden]="userData?.bookingOnly">
    <a
      id="virtual-sidenav"
      mat-button
      class="p-2"
      (click)="handleSideNavButtonClicked('/app/virtual/dashboard')"
      [ngClass]="{
        'primary-color-full':
          location.path().split('/')[2] === 'virtual' &&
          location.path().split('/')[3] === 'dashboard'
      }"
    >
      <div
        class="h-100 w-100 d-flex flex-row flex-lg-column justify-content-sidenav align-items-center"
      >
        <mat-icon class="notranslate">videocam</mat-icon>
        <span class="nav-text ml-4 ml-lg-0 text-left text-lg-center">{{
          patient ? 'Appointments' : 'Virtual Care'
        }}</span>
      </div>
    </a>
  </li>

  <li class="p-0" [hidden]="!patient || userData?.bookingOnly">
    <a
      id="surveys-sidenav"
      mat-button
      class="p-2"
      (click)="handleSideNavButtonClicked('/app/cpp')"
      [ngClass]="{
        'primary-color-full': location.path().split('/')[2] === 'cpp'
      }"
    >
      <div
        class="h-100 w-100 d-flex flex-row flex-lg-column justify-content-sidenav align-items-center"
      >
        <mat-icon class="notranslate">library_books</mat-icon>
        <span class="nav-text ml-4 ml-lg-0 text-left text-lg-center">Health History</span>
      </div>
    </a>
  </li>

  <li class="p-0" [hidden]="!patient || userData?.bookingOnly">
    <a
      id="surveys-sidenav"
      mat-button
      class="p-2"
      (click)="handleSideNavButtonClicked('/app/members')"
      [ngClass]="{
        'primary-color-full': location.path().split('/')[2] === 'members'
      }"
    >
      <div
        class="h-100 w-100 d-flex flex-row flex-lg-column justify-content-sidenav align-items-center"
      >
        <mat-icon class="notranslate">people</mat-icon>
        <span class="nav-text ml-4 ml-lg-0 text-left text-lg-center">Members</span>
      </div>
    </a>
  </li>

  <li tourAnchor="surveys.tour" class="p-0" [hidden]="patient">
    <a
      id="surveys-sidenav"
      mat-button
      class="p-2"
      (click)="handleSideNavButtonClicked('/app/surveys/show-surveys')"
      [ngClass]="{
        'primary-color-full': location.path().split('/')[2] === 'surveys'
      }"
    >
      <div
        class="h-100 w-100 d-flex flex-row flex-lg-column justify-content-sidenav align-items-center"
      >
        <mat-icon class="notranslate">view_array</mat-icon>
        <span class="nav-text ml-4 ml-lg-0 text-left text-lg-center">Questionnaires</span>
      </div>
    </a>
  </li>

  <li tourAnchor="incompleteNotes.tour" class="p-0" [hidden]="patient">
    <a
      id="incomplete-notes-sidenav"
      mat-button
      class="p-2"
      (click)="handleSideNavButtonClicked('/app/notes/notes-list/incomplete')"
      [ngClass]="{
        'primary-color-full': location.path().split('/')[2] === 'notes'
      }"
    >
      <div
        class="h-100 w-100 d-flex flex-row flex-lg-column justify-content-sidenav align-items-center"
      >
        <mat-icon class="notranslate">note</mat-icon>
        <span class="nav-text ml-4 ml-lg-0 text-left text-lg-center">Notes</span>
      </div>
    </a>
  </li>

  <li class="p-0" [hidden]="patient">
    <a
      id="patients-list-sidenav"
      mat-button
      class="p-2"
      (click)="handleSideNavButtonClicked('/app/patients-list')"
      [ngClass]="{
        'primary-color-full': location.path().split('/')[2] === 'patients-list'
      }"
    >
      <div
        class="h-100 w-100 d-flex flex-row flex-lg-column justify-content-sidenav align-items-center"
      >
        <mat-icon class="notranslate">people_outline</mat-icon>
        <span class="nav-text ml-4 ml-lg-0 text-left text-lg-center">Patients</span>
      </div>
    </a>
  </li>

  <li [hidden]="!admin || !!patient" class="p-0">
    <a
      id="user-manager-sidenav"
      mat-button
      (click)="handleSideNavButtonClicked('/app/user-manager')"
      class="p-2"
      [ngClass]="{
        'primary-color-full': location.path().split('/')[2] === 'user-manager'
      }"
    >
      <div
        class="h-100 w-100 d-flex flex-row flex-lg-column justify-content-sidenav align-items-center"
      >
        <mat-icon class="notranslate">people</mat-icon>
        <span class="nav-text ml-4 ml-lg-0 text-left text-lg-center">Users</span>
      </div>
    </a>
  </li>

  <li class="p-0">
    <a
      id="help-sidenav"
      mat-button
      class="p-2"
      (click)="handleSideNavButtonClicked('/app/help')"
      [ngClass]="{
        'primary-color-full': location.path().split('/')[2] === 'help'
      }"
    >
      <div
        class="h-100 w-100 d-flex flex-row flex-lg-column justify-content-sidenav align-items-center"
      >
        <mat-icon class="notranslate">help</mat-icon>
        <span class="nav-text ml-4 ml-lg-0 text-left text-lg-center">{{ patient ? 'Help' : 'Help & Getting Started' }}</span>
      </div>
    </a>
  </li>
</ul>
