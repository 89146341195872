import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'add-user-groups-to-plan-dialog',
  templateUrl: './add-user-groups-to-plan.component.html',
})
export class AddUserGroupsToPlanDialogComponent {
  public readonly dialogRef = inject<MatDialogRef<AddUserGroupsToPlanDialogComponent>>(MatDialogRef<AddUserGroupsToPlanDialogComponent>);
  public userGroupList: any;
  public type: any;
  public selectedGroups = {};

  addGroups() {
    const selectedGroups = [];
    for (const item in this.userGroupList) {
      if (this.userGroupList[item].selected) {
        selectedGroups.push(this.userGroupList[item]);
      }
    }
    console.log('selected groups', selectedGroups);
    this.dialogRef.close(selectedGroups);
  }
}
