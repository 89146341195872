<div class="text-center">
  <div #container [hidden]="!question.images">
    <img src="{{ question.images }}" [ngStyle]="{ 'max-width.px': maxWidth }" />
    <div class="divider"></div>
    <div class="row">
      <div class="col-10 offset-1 centeringMargin">
        <button
          color="primary"
          mat-raised-button
          (click)="question.images = false"
        >
          Change Image
        </button>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!question.images">
    <div class="col-10 offset-1">
      <p>Upload image below</p>
      <ngx-file-drop
        (onFileDrop)="onAdded($event)"
        multiple="false"
        accept=".png,.jpg,.jpeg"
        dropZoneClassName="ngxFileDropZone"
        contentClassName="ngxFileDropContent"
      >
        <ng-template
          ngx-file-drop-content-tmp
          let-openFileSelector="openFileSelector"
        >
          <span>Drop file here</span>
          <span class="px-3"><!-- divider --></span>
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="openFileSelector()"
          >
            Browse
          </button>
        </ng-template>
      </ngx-file-drop>
    </div>
  </div>
  <div class="divider"></div>
</div>
