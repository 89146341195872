<div *ngFor="let row of rows">
  <div class="row">
    <div class="col-1"></div>
    <div class="col-2">
      <mat-form-field class="full-width">
        <mat-label>Systolic</mat-label>
        <input matInput type="text" />
      </mat-form-field>
    </div>
    <div class="col-2">
      <mat-form-field class="full-width">
        <mat-label>Diastolic</mat-label>
        <input matInput type="text" />
      </mat-form-field>
    </div>
    <div class="col-2">
      <mat-form-field class="full-width">
        <mat-label>BPM</mat-label>
        <input matInput type="text" />
      </mat-form-field>
    </div>
    <div class="col-2">
      <mat-form-field class="full-width">
        <mat-label>SO2 (%)</mat-label>
        <input matInput type="text" />
      </mat-form-field>
    </div>
    <div class="col-2">
      <mat-form-field class="full-width">
        <mat-label>Time</mat-label>
        <input matInput type="time" />
      </mat-form-field>
    </div>
    <div class="col-1">
      <button mat-mini-fab color="none" (click)="removeRow(i)">
        <mat-icon class="notranslate">clear</mat-icon>
      </button>
    </div>
  </div>
</div>

<div class="text-center">
  <button mat-raised-button color="primary" (click)="addNewRow()">
    Add Row
  </button>
</div>
