// @ts-strict-ignore
import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  SimpleChanges,
  EventEmitter,
  ViewChild, inject } from '@angular/core';
import {
  SurveyDataTable,
  SurveyDataTableItem,
  AllCompanySurveyData,
} from 'insig-types/survey-data/table';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DownloadSurveyDataDialogComponent } from '../../dialogs/download-survey-data/download-survey-data-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ReportingService } from 'insig-app/services/virtual/reporting.service';
import { SurveyDataService } from 'insig-app/services/survey-data/survey-data.service';

import { take } from 'rxjs/operators';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';


@Component({
  selector: 'survey-data-dashboard',
  styleUrls: ['./survey-data-dashboard.component.scss'],
  templateUrl: './survey-data-dashboard.component.html',
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', display: 'none' })
      ),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  providers: [ReportingService, SurveyDataService],
})
export class SurveyDataDashboardComponent implements OnInit, OnChanges {
  private readonly insigDialog = inject(MatDialog);
  private readonly reportingService = inject(ReportingService);
  private readonly surveyDataService = inject(SurveyDataService);
  private readonly firebaseAuthService = inject(FirebaseAuthService);
  @Input() companyID: string;
  public surveyData: AllCompanySurveyData | null;
  @Input() surveyDataInstances: SurveyDataTable | null;
  @Input() surveyDataTableItems: SurveyDataTableItem[];
  @Output() getSurveyDataInstances = new EventEmitter();
  // @Output() getMoreSurveyDataInstances = new EventEmitter();
  public dataSource: MatTableDataSource<
    SurveyDataTableItem
  >;

  // @ViewChild(MatPaginator, {static: false }) paginator: MatPaginator;
  private paginator: MatPaginator;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
  }

  public selectedCategoryItem: any;
  public selectedCategory: any;
  public selectedCategoryName: any;
  public selectedGroup: any;
  public parsedInstances: any;
  public innerWidthTableContainer: any;
  public math = Math;
  public leftScrollTable = 0;
  public loading = false;

  public menuItems = {
    title: 'Survey Data',
    showAdd: true,
    groups: [
      {
        title: 'Surveys',
        items: [],
        emptyArrStr: 'You currently have no survey data.',
      },
    ],
  };

  async ngOnInit(): Promise<void> {
    this.dataSource = new MatTableDataSource<SurveyDataTableItem>(this.surveyDataTableItems);
    await this.getSurveyData();
    this.resetMenu();
    if (this.surveyDataInstances) {
      this.parseInstanceData();
    }
  }

  ngOnChanges(_changes: SimpleChanges) {
    this.resetMenu();
    if (_changes.surveyDataTableItems) {
      console.log(_changes.surveyDataTableItems);
      if (this.dataSource) {
        this.dataSource.data = this.surveyDataTableItems;
      }
    }
    if (_changes.surveyDataInstances) {
      this.parseInstanceData();
    }
  }

  async getSurveyData() {
    console.log('getting survey data');
    this.surveyData = await this.surveyDataService.getAllSurveysForCompany(
      this.companyID
    );
  }

  parseInstanceData() {
    this.loading = false;
    console.log(this.surveyDataInstances);
  }

  paginatorChange(ev) {
    console.log(ev);
    if (ev.length <= (ev.pageIndex + 2) * ev.pageSize) {
      console.log('load more data');
      this.getSurveyDataInstances.emit({
        id: this.selectedCategory.iden,
        new: false,
      });
    }
  }

  resetMenu() {
    console.log(this.surveyData);
    if (!!this.surveyData && this.surveyData.library) {
      this.menuItems = {
        title: 'Survey Data',
        showAdd: true,
        groups: [
          {
            title: 'Library Surveys',
            items: [],
            emptyArrStr: 'You currently have no survey data.',
          },
        ],
      };

      for (const survey of this.surveyData.library) {
        // console.log(survey, this.surveyData.librarySurveyResponses[survey.id])
        if (this.surveyData.librarySurveyResponses[survey.id]) {
          this.menuItems.groups[0].items.push({
            iden: survey.id,
            type: 'library',
            title: survey.name,
          });
        }
      }
    }

    if (!!this.surveyData && this.surveyData.doctorSurveys) {
      for (const doctorID in this.surveyData.doctorSurveys) {
        if (this.surveyData.doctorSurveys[doctorID].length > 0) {
          this.menuItems.groups.push({
            title: `${this.surveyData.doctorData[doctorID].first} ${this.surveyData.doctorData[doctorID].last}`,
            items: [],
            emptyArrStr: 'You currently have no survey data.',
          });
          const groupIndex = this.menuItems.groups.length - 1;

          for (const survey of this.surveyData.doctorSurveys[doctorID]) {
            if (
              this.surveyData.doctorSurveyResponses[doctorID] &&
              !!this.surveyData.doctorSurveyResponses[doctorID][survey.id]
            ) {
              this.menuItems.groups[groupIndex].items.push({
                iden: survey.id,
                type: 'user',
                title: survey.name,
              });
            }
          }
        }
      }
    }
  }

  menuItemSelected(item: any) {
    console.log(item);
    this.loading = true;
    let newItem = false;

    delete this.selectedCategory;

    if (
      !this.selectedCategory ||
      this.selectedCategory.title !== item.title ||
      this.selectedCategory.type !== item.type
    ) {
      newItem = true;
    }

    this.selectedCategoryName = item.title ? item.title : 'Tia Health';
    this.selectedCategoryItem = item.iden;
    this.selectedCategory = item;

    this.getSurveyDataInstances.emit({ id: item.iden, new: newItem });
  }

  onScrollTable(ev) {
    this.leftScrollTable = ev.target.scrollLeft;
  }

  exportSurveyData() {
    const dialogRef = this.insigDialog.open(DownloadSurveyDataDialogComponent);

    dialogRef
      .afterClosed()
      .toPromise()
      .then(async (selection) => {
        if (selection) {
          console.log(selection);
          let IDToken;
          const includePatient = selection === 'with' ? true : false;
          try {
            IDToken = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();
          } catch (err) {
            console.log('error getting ID token: ', err);
            return;
          }

          this.loading = true;
          const surveyData = await this.surveyDataService
            .getSurveyDataInstances(
              this.companyID,
              this.selectedCategory.iden,
              undefined,
              true
            )
            .pipe(take(1))
            .toPromise()
            .then((data) => data.surveyData);

          this.loading = false;

          console.log('conversion: ', surveyData);
          this.reportingService
            .getSurveyDataToCSV(includePatient, IDToken, surveyData)
            .then((csv) => {
              const filename = `surveys-${this.selectedCategory.iden}.csv`;

              const data = URL.createObjectURL(
                new Blob([csv], { type: 'text/csv' })
              );

              const link = document.createElement('a');
              link.setAttribute('href', data);
              link.setAttribute('download', filename);
              link.click();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
  }
}
