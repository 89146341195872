// @ts-strict-ignore
import { Component, OnInit, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'move-to-folder-dialog',
  templateUrl: './move-to-folder-dialog.component.html',
  styles: [
    `
      button.selected {
        background-color: var(--primary-color) !important;
        color: white;
      }
      .border-x-1 {
        border-left: 1px solid LightGray;
        border-right: 1px solid LightGray;
      }
      .text-material {
        color: rgba(0, 0, 0, 0.54);
      }
    `,
  ],
})
export class MoveToFolderDialogComponent implements OnInit {
  public readonly data = inject<any>(MAT_DIALOG_DATA);
  public readonly dialogRef = inject<MatDialogRef<MoveToFolderDialogComponent, string>>(MatDialogRef<MoveToFolderDialogComponent, string>);
  private folders: any;

  public leftMenuFolders: any = null;
  public centerMenuFolders: any = null;
  public rightMenuFolders: any = null;

  public selectedFolderId: string = null;

  ngOnInit() {
    this.folders = this.data.folders;
    console.log(this.folders);
  }

  getChildFoldersOfFolder(fid: string) {
    const childFolders = [];
    if (fid === 'root') {
      fid = null;
    } // Children of the root folder have no parent
    this.folders.forEach((folder) => {
      if (folder.parent === fid) {
        childFolders.push(folder);
      }
    });
    childFolders.sort((a, b) => a.name.localeCompare(b.name));
    return childFolders;
  }

  getSiblingFoldersOfFolder(fid: string) {
    if (fid === null || fid === undefined || fid === 'root') {
      return null;
    }
    const siblingFolders = [];
    this.folders.forEach((folder) => {
      if (folder.parent === this.getFolderById(fid).parent) {
        siblingFolders.push(folder);
      }
    });
    siblingFolders.sort((a, b) => a.name.localeCompare(b.name));
    return siblingFolders;
  }

  getFolderById(fid: string) {
    if (fid === 'root') {
      return null;
    }
    for (const folder of this.folders) {
      if (folder.id === fid) {
        return folder;
      }
    }
    return null;
  }

  openFolder(fid: string, column: string) {
    this.selectedFolderId = fid;
    const children = this.getChildFoldersOfFolder(fid);
    switch (column) {
      case 'left':
        if (fid === 'root') {
          this.centerMenuFolders = children;
        } else {
          // Shift all columns to the right
          this.rightMenuFolders = children;
          this.centerMenuFolders = this.getSiblingFoldersOfFolder(fid);
          this.leftMenuFolders = this.getSiblingFoldersOfFolder(
            this.getFolderById(fid).parent
          );
        }
        break;
      case 'center':
        this.rightMenuFolders = children;
        break;
      case 'right': // Shift all columns to the left
        this.leftMenuFolders = this.centerMenuFolders;
        this.centerMenuFolders = this.rightMenuFolders;
        this.rightMenuFolders = children;
    }
  }

  isSelected(fid: string): string {
    return fid === this.selectedFolderId ? 'selected' : '';
  }
}
