<div class="auth-page full-height-overflow full-width">
  <div class="top-nav-left pos-abs">
    <img src="{{
          !!specialLogo ? specialLogo : 'assets/images/global/logofullnormal.svg'
        }}" style="height:50px;margin:20px;" />
  </div>

  <div class="top-nav-right" id="googleTranslateHeader">
    <div id="google_translate_element_main"></div>
  </div>

  <div class="forgot-password-container full-height full-width">
    <div class="container p-5 forgot-password-box my-auto">
      <h1 class="h2 text-center">Password Reset</h1>

      <div class="form-wrapper clearfix-md">
        <form [formGroup]="form" name="material_login_form" class="md-form-auth form-validation">
          <fieldset>
            <p class="text-center text-small no-margin">
              Please Reset Your Password Below!
            </p>
            <div class="form-group form-field mt-4">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Old Password</mat-label>
                    <input
                      matInput
                      required
                      type="password"
                      formControlName="oldPassword"
                    />
                    <mat-error>Required</mat-error>
                    <mat-icon class="notranslate" matPrefix>lock_outline</mat-icon>
                </mat-form-field>
            </div>
            <div class="form-group form-field mt-4">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>New Password</mat-label>
                    <input
                      matInput
                      required
                      formControlName="newPassword"
                      validateEqual="newPasswordConfirm"
                      reverse="true"
                      type="password"
                    />
                    <mat-error>Required</mat-error>
                    <mat-icon class="notranslate" matPrefix>lock</mat-icon>
                </mat-form-field>
            </div>

            <div class="form-group form-field mt-4">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>New Password Confirm</mat-label>
                    <input
                      matInput
                      required
                      type="password"
                      formControlName="newPasswordConfirm"
                      validateEqual="newPassword"
                    />
                    <mat-error
                        *ngIf="form.controls.newPasswordConfirm?.value?.length === 0">
                        Required
                    </mat-error>
                    <mat-error
                        *ngIf="form.controls.newPasswordConfirm?.value?.length !== 0">
                        Passwords must match
                    </mat-error>
                    <mat-icon class="notranslate" matPrefix>lock</mat-icon>
                </mat-form-field>
            </div>

            <div class="row mt-5">


              <div class="filled-btn col-5 float-right d-flex flex-column p-0 mx-auto" 
                (click)="updatePassword()">
                <div class="mt-auto mb-auto">Reset</div>
              </div>
            </div>
          </fieldset>
        </form>
        <p *ngIf="errorMessage">{{ errorMessage }}</p>

      </div>
    </div>
  </div>
</div>
