// @ts-strict-ignore
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'blood-pressure',
  templateUrl: './blood-pressure.component.html',
})
export class BloodPressureComponent implements OnChanges, OnInit {
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();

  ngOnInit() {
    if (!this.question.response.rows) {
      this.question.response.rows = [
        {
          time: new Date().toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
          }),
        },
      ];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  addNewRow() {
    this.question.response.rows.push({
      time: new Date().toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      }),
    });
  }

  removeRow(i) {
    this.question.response.rows.splice(i, 1);
  }
} // end component
