import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialComponentsModule } from 'insig-app/material-components.module';
import { InsigAppLoaderModule } from 'insig-app/global/loader/loader.module';
import { CollectCreditcardComponent } from './collect-creditcard/collect-creditcard.component';
import { PriceCardComponent } from './price-card/price-card.component';
import { FeatureComparisonComponent } from './feature-comparison/feature-comparison.component';
import { InsigUIModule } from 'insig-app/global/insig-ui/insig-ui.module';


@NgModule({
  declarations: [
    CollectCreditcardComponent,
    PriceCardComponent,
    FeatureComparisonComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialComponentsModule,
    InsigAppLoaderModule,
    InsigUIModule,
  ],
  exports: [
    CollectCreditcardComponent,
    PriceCardComponent,
    FeatureComparisonComponent,
  ]
})
export class PaymentsModule { }
