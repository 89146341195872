import { Component, OnInit, OnDestroy, HostListener, inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Apollo, gql } from 'apollo-angular';
import { APPCONFIG, firebaseINIT, DEFAULT_THEME, PRODUCTION } from '@insig-health/config/config';
import { VirtualService } from 'insig-app/services/virtual/virtual.service';
import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';
import { ThemeService } from 'insig-app/services/theme/theme.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SchedulerService } from 'insig-app/services/scheduler.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CookieService } from 'ngx-cookie-service';

import { Title } from '@angular/platform-browser';
import firebase from 'firebase/compat/app';
import { UserData } from 'insig-types/user-data';
import { CompanyData } from 'insig-types/company-data';
import { FirebaseAuthService } from './services/firebase-auth/firebase-auth.service';
import { InactivityService } from './services/inactivity/inactivity.service';
import { ExternalLoginService } from './services/external-login/external-login.service';
import { User } from 'firebase/auth';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [
    CookieService,
    VirtualService,
    SchedulerService,
    PatientUserDataService,
    ThemeService,
    Title,
  ],
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly router = inject(Router);
  private readonly snackbar = inject(MatSnackBar);
  private readonly firebaseAuthService = inject(FirebaseAuthService);
  private readonly deviceService = inject(DeviceDetectorService);
  private readonly cookieService = inject(CookieService);
  private readonly themeService = inject(ThemeService);
  private readonly apollo = inject(Apollo);
  private readonly titleService = inject(Title);
  private readonly inactivityService = inject(InactivityService);
  private readonly externalLoginService = inject(ExternalLoginService);
  private readonly gcpIpAuthService = inject(GcpIpAuthService);
  
  // graphql queries
  private userDataQuery = gql`
    query UserDataQuery($userID: ID!, $token: ID!) {
      getUserData(uid: $userID, token: $token) {
        uid
        first
        last
        title
        email
        company
        type {
          admin
        }
      }
    }
  `;

  private companyDataQuery = gql`
    query CompanyDataQuery($companyID: ID!, $token: ID) {
      getCompanyData(cid: $companyID, token: $token) {
        id
        AppConfig {
          colorOption
          theme
        }
        ThemeConfig {
          primary
          secondary
          text
          textSecondary
          textSelected
        }
        name
        branding
        brandingName
        website
      }
    }
  `;

  private readonly TALI_WIDGET_ALLOWED_PATHS_REGEX = /.*\/(edit-note)|(virtual\/(video|phone|messaging)).*/;
  //
  public AppConfig: any;
  public showTaliWidget = false;
  public isDoctor$ = this.firebaseAuthService.onIdTokenChanged().pipe(
    switchMap((user) => this.firebaseAuthService.checkIfUserIsDoctor(user)),
  );

  public readonly PRODUCTION = PRODUCTION;

  private navigationSubscription: Subscription;

  constructor(
) {
    localStorage.removeItem('firebase:previous_websocket_failure');
    if (firebase.apps.length === 0) {
      firebase.initializeApp(firebaseINIT().firebase);
    }
    this.inactivityService.start();
    this.externalLoginService.start();
    this.navigationSubscription = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        document.body.scrollTop = 0;
        this.showTaliWidget = this.TALI_WIDGET_ALLOWED_PATHS_REGEX.test(evt.url);
      }
    });
  } // end constructor

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    // console.log(window.innerWidth)
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  ngOnInit(): void {
    if (
      window.location.origin.includes('app.well.company') ||
      window.location.origin.includes('app.wellclinics.ca')
    ) {
      this.titleService.setTitle('WELL Health');
    } else if (window.location.origin.includes('app.jacknathanhealth.com')) {
      this.titleService.setTitle('Jack Nathan Health');
    } else if (window.location.origin.includes('app.thevirtualdoctor.org')) {
      this.titleService.setTitle('The Virtual Doctor');
    } else if (window.location.origin.includes('virtual.highmark.tech')) {
      this.titleService.setTitle('EQ Virtual');
    } else if (window.location.origin.includes('app.tiahealth.com')) {
      this.titleService.setTitle('Tia Health');
    }  else if (window.location.origin.includes('app.yourcare.health')) {
      this.titleService.setTitle('YourCare');
    }else {
      this.titleService.setTitle('Insig Health');
    }
    this.checkInternetExplorer();

    this.AppConfig = APPCONFIG;

    // Before the user is logged in, default branding to the latest company if it exists
    const brandingCID = this.cookieService.get('brandingCID');
    if (brandingCID) {
      this.loadCompanyData(brandingCID);
    }

    firstValueFrom(this.gcpIpAuthService.getAuthStateChanged().pipe(
      filter((user): user is User => user !== null),
      switchMap((user) => this.getUserData(user)),
    )).then((userData) => {
        if (!!userData) {
          this.AppConfig.userType = userData.type;
          this.AppConfig.cid = userData.company;
          this.loadCompanyData(this.AppConfig.cid);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  private async getUserData(user: User): Promise<UserData> {
    const queryResult = await firstValueFrom(this.apollo
      .query<{ getUserData: UserData }>({
        query: this.userDataQuery,
        variables: {
          userID: user.uid,
          token: await user.getIdToken(),
        },
      })
    );
    return queryResult.data.getUserData;
  }

  ngOnDestroy(): void {
    console.log('Destroying app component subscriptions');
    if (
      this.navigationSubscription &&
      this.navigationSubscription.unsubscribe
    ) {
      this.navigationSubscription.unsubscribe();
    }
  }

  checkInternetExplorer(): void {
    const deviceInfo = this.deviceService.getDeviceInfo();
    if (
      deviceInfo.browser === 'ie' ||
      deviceInfo.browser === 'internetexplorer' ||
      deviceInfo.browser === 'internet-explorer' ||
      deviceInfo.browser === 'internetExplorer'
    ) {
      setTimeout(() => {
        this.snackbar.open(
          'Internet Explorer is not supported! Please use Google Chrome Web Browser.'
        );
      });
    }
  }

  async loadCompanyData(cid: string): Promise<void> {
    try {
      const companyDataQuery = await firstValueFrom(this.apollo
        .query<{ getCompanyData: CompanyData }>({
          query: this.companyDataQuery,
          variables: {
            companyID: cid,
            token: await this.firebaseAuthService.getIdToken(),
          },
        }));
      const companyData = companyDataQuery.data.getCompanyData;

      if (!companyData) {
        return;
      }
      let theme = DEFAULT_THEME;

      if (!!companyData.ThemeConfig) {
        theme = {
          'primary-color': companyData.ThemeConfig.primary,
          'secondary-color': companyData.ThemeConfig.secondary,
          'text-color': companyData.ThemeConfig.text,
          'text-selected': companyData.ThemeConfig.textSelected,
          'text-secondary': companyData.ThemeConfig.textSecondary,
        };
      }

      this.themeService.setTheme(theme);
      if (companyData.brandingName) {
        this.AppConfig.brand = companyData.brandingName;
      }
      if (companyData.website) {
        this.AppConfig.website = companyData.website;
      }
      this.AppConfig.cid = cid;
      if (companyData.branding) {
        this.AppConfig.branding = companyData.branding;
      }
    } catch (error) {
      console.error(error);
    }
  }

}
