<mat-card>
  <div class="dialogSize">
    <h1 matDialogTitle>New Folder</h1>
    <br />
    <div mat-dialog-content>
      <!-- <h3 class="text-center">{{surveyName}}</h3> -->
      <div class="row container">
        <mat-form-field style="width:90%;margin:auto;">
          <mat-label>Folder Name</mat-label>
          <input
            matInput
            value="New Folder"
            [(ngModel)]="newFolderName"
          />
        </mat-form-field>
      </div>
      <!-- <div class="divider"></div><div class="divider"></div> -->
      <button mat-button (click)="dialogRef.close()">Cancel</button>
      <button
        mat-raised-button
        color="primary"
        class="float-right"
        (click)="dialogRef.close(newFolderName)"
      >
        Confirm
      </button>
    </div>
  </div>
</mat-card>
