// @ts-strict-ignore
import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';

@Component({
  selector: 'insig-navigation',
  templateUrl: './insig-navigation.component.html',
  styleUrls: ['./insig-navigation.component.scss'],
})
export class InsigNavigationComponent {
  private _selectedTab: any;
  public mobile = false;

  @Output() selectedTabChange = new EventEmitter();
  set selectedTab(val) {
    this._selectedTab = val;
    this.selectedTabChange.emit(this._selectedTab);
  }

  @Input() get selectedTab() {
    return this._selectedTab;
  }

  @Input() menuItems: any;

  constructor() {
    if (window.innerWidth < 768) {
      this.mobile = true;
    }
  }

  changeTab(item: string) {
    this.selectedTab = item;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 768) {
      this.mobile = true;
    } else if (event.target.innerWidth >= 768) {
      this.mobile = false;
    }
  }
}
