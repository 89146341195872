<div (click)="startDrawing()">
  <h5>Click/tap to begin drawing</h5>
  <canvas
    [ngStyle]="{ 'max-width.px': maxWidth }"
    *ngIf="!question.response.answer && !question.images"
    class="drawingBorder"
  ></canvas>
  <div #container>
    <img
      [ngStyle]="{ 'max-width.px': maxWidth }"
      *ngIf="!question.response.answer && question.images"
      src="{{ question.images }}"
    />
  </div>
  <img
    *ngIf="question.response.answer"
    src="{{ question.response.answer }}"
    class="{{ drawingBorder }}"
  />
</div>
