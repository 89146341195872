import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsigColorPickerComponent } from './insig-color-picker.component';

@NgModule({
    imports: [CommonModule],
    exports: [InsigColorPickerComponent],
    declarations: [InsigColorPickerComponent]
})
export class InsigColorPickerModule {}
