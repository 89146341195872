<mat-card>
  <h1 matDialogTitle>Send Questionnaire</h1>

  <div mat-dialog-content>
    <mat-tab-group sm-vertical [disablePagination]="true">
      <mat-tab label="Email">
        <div class="box box-default">
          <div class="box-body">
            <form [formGroup]="emailForm" (ngSubmit)="sendSurveyEmail()">
              <div class="form-group">
                <mat-form-field class="full-width">
                  <mat-label>To</mat-label>
                  <input
                    matInput
                    autocomplete="off"
                    required
                    type="email"
                    formControlName="emailTo"
                    pattern="^.+@.+\..+$"
                  />
                  <mat-error
                    >Please enter a valid email (joe@example.com)</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="form-group">
                <mat-form-field class="full-width">
                  <mat-label>From</mat-label>
                  <input
                    matInput
                    required
                    type="email"
                    formControlName="emailFrom"
                    pattern="^.+@.+\..+$"
                  />
                  <mat-error
                    >Please enter a valid email (joe@example.com)</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="form-group">
                <mat-form-field class="full-width">
                  <mat-label>Subject</mat-label>
                  <input
                    matInput
                    formControlName="emailSubject"
                  />
                </mat-form-field>
              </div>
              <div class="form-group">
                <mat-form-field class="full-width">
                  <mat-label>Message</mat-label>
                  <textarea
                    rows="5"
                    required
                    matInput
                    formControlName="emailMessage"
                    >{{ emailMessage }}</textarea
                  >
                </mat-form-field>
              </div>
              <button
                mat-raised-button
                color="primary"
                type="submit"
                class="float-right"
              >
                Send
              </button>
            </form>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="SMS">
        <div class="box box-default">
          <div class="box-body">
            <form [formGroup]="smsForm" (ngSubmit)="sendSurveySMS()">
              <div class="form-group">
                <mat-form-field class="full-width">
                  <mat-label>Cell Phone Number</mat-label>
                  <input
                    required
                    autocomplete="off"
                    matInput
                    formControlName="smsTo"
                    minlength="10"
                    maxlength="14"
                    insig-phone-mask
                    type="tel"
                  />
                </mat-form-field>
              </div>
              <div class="form-group">
                <mat-form-field class="full-width">
                  <mat-label>Message</mat-label>
                  <textarea
                    required
                    rows="8"
                    matInput
                    formControlName="smsMessage"
                    [minLength]="MIN_SMS_LENGTH"
                    [maxlength]="MAX_SMS_LENGTH"
                    >
                  </textarea>
                  <mat-error>Please enter a message between {{ MIN_SMS_LENGTH }} and {{ MAX_SMS_LENGTH }} characters</mat-error>
                </mat-form-field>
              </div>
              <button
                type="submit"
                mat-raised-button
                color="primary"
                class="float-right"
              >
                Send
              </button>
            </form>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Link">
        <div class="box box-default">
          <div class="box-body">
            <div class="form-group">
              <p>{{ surveyLink }}</p>
            </div>
          </div>
        </div>
        <button
          mat-raised-button
          color="primary"
          class="float-right"
          ngxClipboard
          [cbContent]="surveyLink"
          (click)="copyLink()"
        >
          Copy
        </button>
      </mat-tab>
    </mat-tab-group>
  </div>
</mat-card>
