<div matDialogTitle class="pt-0 pb-2">
  <img src='./assets/images/pillway/logo.png' class="w-150px" alt="Pillway Logo" />
  <div class="h4 bold redText">Easy as 1 2 3</div>
</div>

<div mat-dialog-content>
    <ol class="d-flex flex-column p-0 gap-2 listStylePositionInside">
      <li>Select Pillway as your preferred pharmacy, and your prescription will be sent after your appointment.</li>
      <li>Pillway will notify you within 1 business hour and arrange delivery. For same-day delivery, prescriptions must be sent to the pharmacy before 12 PM Mon-Fri. Same-day service is available in the Greater Toronto Area and Greater Vancouver Area only, for the time being. Weekend deliveries will be shipped the next business day.</li>
      <li>Your prescription will be delivered to your door! Download the free Pillway app to connect to the Pillway pharmacy team, check status updates, and more.</li>
    </ol>
    <p>
      By choosing Pillway as your preferred pharmacy, you authorize the sending of your prescription, and contact info to Pillway. You also authorize Pillway to send you email and/or SMS.
    </p>
    <p>Learn more <a href="https://www.pillway.com/" target="_blank" rel="noopener" class="text-decoration-none bold">here</a></p>
</div>

<mat-dialog-actions align="end">
  <insig-ui-button mat-dialog-close>Close</insig-ui-button>
</mat-dialog-actions>
