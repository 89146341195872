// @ts-strict-ignore
const HtmlTags: any = [
  { opening: 'b', openingRtf: '{\\b', closing: '/b', closingRtf: '}' },
  { opening: 'br', openingRtf: '\\line', closing: 'br/', closingRtf: '\\line' },
  {
    opening: 'center',
    openingRtf: '{\\pard\\qr',
    closing: '/center',
    closingRtf: '\\par}',
  },
  {
    opening: 'div',
    openingRtf: '{\\pard',
    closing: '/div',
    closingRtf: '\\sb0\\par}',
  },
  { opening: 'font', openingRtf: '{', closing: '/font', closingRtf: '}' },
  {
    opening: 'h1',
    openingRtf: '{\\pard\\fs22',
    closing: '/h1',
    closingRtf: '\\sb40\\par}',
  },
  {
    opening: 'h2',
    openingRtf: '{\\pard\\fs22',
    closing: '/h2',
    closingRtf: '\\sb40\\par}',
  },
  {
    opening: 'h3',
    openingRtf: '{\\pard\\fs22',
    closing: '/h3',
    closingRtf: '\\sb40\\par}',
  },
  {
    opening: 'h4',
    openingRtf: '{\\pard\\fs22',
    closing: '/h4',
    closingRtf: '\\sb40\\par}',
  },
  {
    opening: 'h5',
    openingRtf: '{\\pard\\fs22',
    closing: '/h5',
    closingRtf: '\\sb40\\par}',
  },
  {
    opening: 'h6',
    openingRtf: '{\\pard\\fs22',
    closing: '/h6',
    closingRtf: '\\sb40\\par}',
  },
  { opening: 'i', openingRtf: '{\\i', closing: '/i', closingRtf: '}' },
  {
    opening: 'li',
    openingRtf: '{\\pntext\\tab}',
    closing: '/li',
    closingRtf: '\\sb0\\par',
  },
  {
    opening: 'p',
    openingRtf: '{\\pard\\fs20',
    closing: '/p',
    closingRtf: '\\sb40\\par}',
  },
  {
    opening: 'ol',
    openingRtf:
      '{{\\*\\pn\\pnlvlbody\\pnf0\\pnindent0\\pnstart1\\pndec{\\pntxta.}}\\fi-360\\li720\\sa200\\sl276\\slmult1',
    closing: '/ol',
    closingRtf: '}',
  },
  { opening: 's', openingRtf: '{\\strike', closing: '/s', closingRtf: '}' },
  {
    opening: 'span',
    openingRtf: '{\\fs20',
    closing: '/span',
    closingRtf: '\\sb20}',
  },
  { opening: 'sub', openingRtf: '{\\sub', closing: '/sub', closingRtf: '}' },
  { opening: 'sup', openingRtf: '{\\super', closing: '/sup', closingRtf: '}' },
  {
    opening: 'strong',
    openingRtf: '{\\b',
    closing: '/strong',
    closingRtf: '}',
  },
  {
    opening: 'table',
    openingRtf:
      '{{\\pard \\brdrb \\brdrs\\brdrw10\\brsp10 {\\fs4\\~}\\par \\pard}',
    closing: '/table',
    closingRtf:
      '{\\pard \\brdrb \\brdrs\\brdrw10\\brsp20 {\\fs4\\~}\\par \\pard}}',
  },
  {
    opening: 'td',
    openingRtf: '{\\pard\\intbl\\qc',
    closing: '/td',
    closingRtf: '\\cell}',
  },
  {
    opening: 'th',
    openingRtf: '{\\pard\\intbl\\qc',
    closing: '/th',
    closingRtf: '\\cell}',
  },
  {
    opening: 'tr',
    openingRtf: '{\\trowd\\trgaph10',
    closing: '/tr',
    closingRtf: '\\row}',
  },
  { opening: 'u', openingRtf: '{\\ul', closing: '/u', closingRtf: '\\sb0}' },
  {
    opening: 'ul',
    openingRtf:
      '{{\\*\\pn\\pnlvlblt\\pnf1\\pnindent0{\\pntxtb\\\'B7}}\\fi-360\\li720\\sa30\\sl276\\slmult1\\lang22\\f0\\fs20\\sb0',
    closing: '/ul',
    closingRtf: '\\sb0}',
  },
  {
    opening: 'img',
    openingRtf: '{\\pict{\\*\\picprop}',
    closing: '/img',
    closingRtf: '}',
  },
];

export default class AllowedHtmlTags {
  static getRtfReferenceTag(tagName) {
    tagName = tagName.toLowerCase();
    for (const tag of HtmlTags) {
      if (tag.opening === tagName) {
        return tag.openingRtf;
      }
      if (tag.closing === tagName) {
        return tag.closingRtf;
      }
    }
    return undefined;
  }
}
