import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsigStepperComponent } from './insig-stepper.component';

@NgModule({
  imports: [CommonModule],
  exports: [InsigStepperComponent],
  declarations: [InsigStepperComponent]
})
export class InsigStepperModule { }
