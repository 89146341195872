<div class="text-center">
  <div class="center" *ngIf="!question.survId">
    <h4>Select a Questionnaire</h4>
    <div class="divider"></div>
    <button mat-raised-button (click)="selectSurvey('user')">
      Your Questionnaires
    </button>
    <span class="space"></span>
    <button mat-raised-button (click)="selectSurvey('library')">Library</button>
  </div>
  <div class="center" *ngIf="question.survId">
    <h4>{{ question.survName }}</h4>
    <div class="divider"></div>
    <button mat-raised-button (click)="removeSurvey()">Change Survey</button>
  </div>
  <div class="divider"></div>
</div>
