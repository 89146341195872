// @ts-strict-ignore
/// <reference types="@types/googlemaps" />

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { GooglePlaceService } from '@insig-health/services/google-place/google-place.service';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'address',
  templateUrl: './address.component.html',
})
export class AddressComponent implements OnChanges, AfterViewInit {
  private readonly googlePlaceService = inject(GooglePlaceService);
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();

  @ViewChild('searchAddress')
  public addressElementRef: ElementRef;

  ngAfterViewInit(): void {
    // load Places Autocomplete
    this.googlePlaceService.getPlaceResultObservable(this.addressElementRef.nativeElement).subscribe((placeResult) => {
      this.question.response.answer = placeResult['formatted_address'];
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }
} // end component
