// @ts-strict-ignore
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Question } from 'insig-types/surveys/question';

const MAX_ITEM_LIMIT = 50; // Only show first 50 items

@Component({
  selector: 'autocomplete-question',
  templateUrl: './autocomplete.component.html',
})
export class AutocompleteComponent implements OnChanges, OnInit, OnDestroy {
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();

  items: string[];

  ngOnInit() {
    if (!this.question.response.selectedAnswers) {
      this.question.response.selectedAnswers = [];
    }
    this.items = this.question.arrayOptions || [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  ngOnDestroy() {
    if (
      this.question.allowMultiple &&
      !!this.question.response.selectedAnswers[-1]
    ) {
      // Ensure the value in the field is captured
      this.question.response.selectedAnswers.push(
        this.question.response.selectedAnswers[-1]
      );
    }
  }

  get filteredItems(): string[] {
    // Only show autocomplete items that begin with val
    return this.question.response.selectedAnswers[-1]
      ? this.items
          .filter(
            (s) =>
              s
                .trim()
                .toLowerCase()
                .indexOf(
                  this.question.response.selectedAnswers[-1].toLowerCase()
                ) === 0
          )
          .slice(0, MAX_ITEM_LIMIT)
      : this.items.slice(0, MAX_ITEM_LIMIT);
  }

  removeItem(val) {
    const index = this.question.response.selectedAnswers.indexOf(val);
    this.question.response.selectedAnswers.splice(index, 1);
  }

  addItem(val: string): void {
    if (this.question.allowMultiple) {
      if (!this.question.response.selectedAnswers.includes(val)) {
        this.question.response.selectedAnswers.push(val);
      }
      this.question.response.selectedAnswers[-1] = '';
    }
  } // end add item
} // end component
