// @ts-strict-ignore
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'show-surveys-navigation',
  templateUrl: './show-surveys-navigation.component.html',
  providers: [],
})
export class ShowSurveysNavigationComponent {
  private _selectedTab: any;

  @Input() mobile: any;
  @Input() companyData: any;

  @Output() selectedTabChange = new EventEmitter();
  set selectedTab(val) {
    this._selectedTab = val;
    this.selectedTabChange.emit(this._selectedTab);
  }

  @Input() get selectedTab() {
    // this.parseVirtualArray()
    return this._selectedTab;
  }

  @Output() resetVariables = new EventEmitter<string>();

  changeTab(item: string) {
    this.selectedTab = item;
    this.resetVariables.next(undefined);
    return;
  }
}
