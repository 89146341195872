import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DoctorTermsOfUseComponent } from './doctor-terms-of-use.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    DoctorTermsOfUseComponent,
  ],
  exports: [
    DoctorTermsOfUseComponent,
  ],
})
export class DoctorTermsOfUseModule {}
