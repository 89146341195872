<h6 class="text-left" (click)="hideGeneral = !hideGeneral">
  <i *ngIf="!hideGeneral" class="material-icons">add_circle_outline</i>
  <i *ngIf="hideGeneral" class="material-icons">indeterminate_check_box</i>
  General
</h6>
<div class="text-center offset-3" [hidden]="!hideGeneral">
  <div class="row">
    <div class="col-sm-6 text-left">
      <ng-container *ngFor="let answer of checkboxes; let i = index">
        <ng-container *ngIf="i < checkboxes.length / 2">
          <mat-checkbox
            [(ngModel)]="questionExample.response.checkboxes[answer]"
            >{{ answer }}</mat-checkbox
          >
          <div class="divider"></div>
        </ng-container>
      </ng-container>
    </div>
    <div class="col-sm-6 text-left">
      <ng-container *ngFor="let answer of checkboxes; let i = index">
        <ng-container *ngIf="i >= checkboxes.length / 2">
          <mat-checkbox
            [(ngModel)]="questionExample.response.checkboxes[answer]"
            >{{ answer }}</mat-checkbox
          >
          <div class="divider"></div>
        </ng-container>
      </ng-container>
    </div>
    <div class="col-12 text-left">
      <span>Other: </span>
      &nbsp;
      <mat-form-field class="w-75">
        <textarea
          matInput
          type="text"
          autocomplete="off"
          [(ngModel)]="questionExample.response.otherCheckbox"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</div>

<hr />

<h6 class="text-left" (click)="showBP = !showBP">
  <i *ngIf="!showBP" class="material-icons">add_circle_outline</i>
  <i *ngIf="showBP" class="material-icons">indeterminate_check_box</i>
  Blood Pressure
</h6>
<blood-pressure-edit [hidden]="!showBP"></blood-pressure-edit>

<br />

<div class="table-responsive">
  <table *ngFor="let phys of defaultStructure" class="table table-bordered">
    <thead>
      <tr class="text-left">
        <td
          colspan="7"
          (click)="
            questionExample.physical[phys.id] = !questionExample.physical[
              phys.id
            ]
          "
        >
          <i *ngIf="!questionExample.physical[phys.id]" class="material-icons"
            >add_circle_outline</i
          >
          <i *ngIf="questionExample.physical[phys.id]" class="material-icons"
            >indeterminate_check_box</i
          >
          <label>&nbsp;{{ phys.id }}</label>
        </td>
      </tr>
    </thead>
    <tbody *ngIf="questionExample.physical[phys.id]">
      <tr>
        <td></td>
        <td>
          <label>Normal</label>
        </td>
        <td>
          <label>Abnormal</label>
        </td>
        <td colspan="3">
          <label>Description</label>
          <p>(Enter a description to be autofilled by the message icon)</p>
        </td>
      </tr>
      <ng-container *ngFor="let part of phys.sections">
        <tr
          *ngIf="
            part.parent == true ||
            questionExample.response.physicalGrid[phys.id][part.parent].value ==
              'abnormal'
          "
        >
          <td class="text-left">
            <button
              class="float-right mt-3"
              mat-icon-button
              (click)="
                questionExample.response.physicalGrid[phys.id][
                  part.id
                ].value = null;
                questionExample.response.physicalGrid[phys.id][
                  part.id
                ].desc = null
              "
            >
              <i class="material-icons">clear</i>
            </button>

            <p class="mt-4 bold" *ngIf="part.parent == true">{{ part.id }}</p>

            <p class="mt-4" *ngIf="part.parent != true">
              <span class="space space-md"></span>
              {{ part.id }}
            </p>
          </td>
          <td>
            <mat-radio-group
              [(ngModel)]="
                questionExample.response.physicalGrid[phys.id][part.id].value
              "
            >
              <mat-radio-button [value]="'normal'"></mat-radio-button>
            </mat-radio-group>
            <br />
            <button
              class="mt-3"
              mat-icon-button
              (click)="explainIcon()"
            >
              <i class="material-icons">chat</i>
            </button>
          </td>
          <td>
            <mat-radio-group
              [(ngModel)]="
                questionExample.response.physicalGrid[phys.id][part.id].value
              "
            >
              <mat-radio-button [value]="'abnormal'"></mat-radio-button>
            </mat-radio-group>
          </td>
          <td colspan="4">
            <span
              class="mx-3 text-left"
              *ngFor="let box of part.options; let i = index"
            >
              <mat-checkbox
                [(ngModel)]="
                  questionExample.response.physicalGrid[phys.id][part.id]
                    .options[i].selected
                "
                >{{ box.title }}</mat-checkbox
              >
            </span>
            <mat-form-field class="full-width">
              <input
                matInput
                [(ngModel)]="question.standard[phys.id][part.id]"
              />
            </mat-form-field>
            <span *ngIf="part.sentence">(Default) {{ part.sentence }}</span>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
