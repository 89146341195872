import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AsyncUtilitiesService {
  /**
   * Prevents a promise from failing by retrying it on failure for a definite number of times.
   * @param promiseGenerator - A function that generates the promise to retry.
   * @param numberOfRetries - The maximum number of times to retry. Default 5.
   * @param exponentialBackoff - The number of ms to backoff. Doubles with each retry. Default 10.
   */
  async retryPromise<T>(
    promiseGenerator: () => Promise<T>,
    numberOfRetries = 5,
    exponentialBackoff = 10
  ): Promise<T> {
    try {
      return await promiseGenerator();
    } catch (error) {
      if (numberOfRetries > 0) {
        return await new Promise((resolve) => {
          setTimeout(() => {
            resolve(
              this.retryPromise(
                promiseGenerator,
                numberOfRetries - 1,
                exponentialBackoff * 2
              )
            );
          }, exponentialBackoff);
        });
      } else {
        // Exceeded maximum number of retries
        throw error; // Throw the error up to be handled by the original caller
      }
    }
  }
}
