<insig-sidenav-menu
  [menuItems]="menuItems"
  (addNewCategory)="addNewCategory()"
  (menuItemSelected)="menuItemSelected($event)"
  [selectedGroup]="selectedGroup"
  [selectedCategoryItem]="selectedCategoryItem"
>
  <div
    [ngClass]="{
      'container-fluid no-breadcrumbs': selectedCategory && !selectedDoctorID,
      'p-0 m-0': selectedDoctorID
    }"
    class="full-width"
  >
    <company-plan-list-header
      *ngIf="selectedCategory && !selectedService"
      (addNewService)="addNewService()"
      [selectedCategoryName]="selectedCategoryName"
      [(selectedPlan)]="selectedPlan"
      (companyPlanSettings)="companyPlanSettingsToggle(false)"
      (deleteCompanyPlan)="deleteCompanyPlan()"
    >
      <company-plan-services-list
        [companyPlanVirtualServices]="companyPlanVirtualServices"
        (serviceClicked)="editService($event)"
        [adminView]="true"
        (addServicesBtnClicked)="addNewService()"
      >
      </company-plan-services-list>
    </company-plan-list-header>

    <shared-virtual-service
      *ngIf="selectedCategory && selectedService"
      (backBtnClicked)="back()"
      (deleteBtnClicked)="delete()"
      (saveBtnClicked)="save()"
      (setMinPrice)="setMinPrice($event)"
      (selectSurvey)="selectSurvey()"
      (copiedURLSuccess)="copiedURLSuccess()"
      [(selectedService)]="selectedService"
      [existingServicesArray]="companyPlanVirtualServices"
      [selectedServiceURL]="selectedServiceURL"
      [newService]="newService"
      [companyPlanAdmin]="true"
      (cancelBtnClicked)="cancel()"
      [userData]="userData"
    >
    </shared-virtual-service>

    <div
      *ngIf="!selectedCategory && !selectedDoctorID"
      class="ml-auto mr-auto full-height cal-week-view"
    >
      <div class="calendar-container">
        <div class="row mt-4">
          <div class="col-md-8">
            <h3 class="mt-3">Week of {{ viewDate | date: 'longDate' }}</h3>
          </div>
          <div class="col-md-4 text-right">
            <div class="btn-group" role="group">
              <button
                type="button"
                class="btn btn-primary"
                style="color:gray;"
                mwlCalendarPreviousView
                [view]="view"
                [(viewDate)]="viewDate"
                [disabled]="prevBtnDisabled"
                (viewDateChange)="dateOrViewChanged()"
              >
                <span class="valign-center">
                  <mat-icon title="Precedente" class="notranslate md-18"
                    >keyboard_arrow_left</mat-icon
                  >
                </span>
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                mwlCalendarToday
                [(viewDate)]="viewDate"
                (viewDateChange)="dateOrViewChanged()"
              >
                <span class="valign-center">
                  <mat-icon title="Oggi" class="notranslate md-18"
                    >today</mat-icon
                  >
                </span>
              </button>
              <button
                type="button"
                class="btn btn-primary"
                style="color:gray;"
                mwlCalendarNextView
                [view]="view"
                [(viewDate)]="viewDate"
                [disabled]="nextBtnDisabled"
                (viewDateChange)="dateOrViewChanged()"
              >
                <span class="valign-center">
                  <mat-icon title="Successivo" class="notranslate md-18"
                    >keyboard_arrow_right</mat-icon
                  >
                </span>
              </button>
            </div>
          </div>
        </div>
        <br />
        <div [ngSwitch]="view">
          <calendar-scheduler-view
            *ngSwitchCase="'week'"
            [viewDate]="viewDate"
            [events]="combinedSchedule"
            [locale]="locale"
            [weekStartsOn]="weekStartsOn"
            [tooltipPlacement]="'top'"
            [refresh]="refreshSubject"
            [excludeDays]="excludeDays"
            [startsWithToday]="startsWithToday"
            [hourSegments]="1"
            [dayStartHour]="dayStartHour"
            [dayEndHour]="dayEndHour"
            [dayModifier]="dayModifier"
            [hourModifier]="hourModifier"
            [segmentModifier]="segmentModifier"
            [showActions]="true"
            (dayClicked)="dayClicked($event.day)"
            (segmentClicked)="segmentClicked('Clicked', $event.segment)"
            (eventClicked)="eventClicked('Clicked', $event.event)"
          >
          </calendar-scheduler-view>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!!selectedDoctorID && !!selectedDoctorCompanyID">
      <h3 class="mt-0">{{ selectedDoctorName }}</h3>

      <div class="row mx-0 mb-4 full-width border-bottom border-top">
        <div class="col-12 text-center">
          <h6>
            Virtual Care
            {{ selectedDoctorUserData.acceptVirtual ? 'Enabled' : 'Disabled' }}
          </h6>
          <button mat-raised-button (click)="setDoctorAcceptVirtual(false)">
            <mat-icon
              *ngIf="!selectedDoctorUserData.acceptVirtual"
              class="notranslate"
              >check_circle</mat-icon
            >
            OFF
          </button>

          <button mat-raised-button (click)="setDoctorAcceptVirtual(true)">
            <mat-icon
              *ngIf="!!selectedDoctorUserData.acceptVirtual"
              class="notranslate"
              >check_circle</mat-icon
            >
            ON
          </button>
        </div>
      </div>

      <mat-tab-group disablePagination="true">
        <mat-tab label="Schedule">
          <edit-availability
            [uid]="selectedDoctorID"
            [company]="selectedDoctorCompanyID"
          >
          </edit-availability>
        </mat-tab>
        <mat-tab label="Services">
          <div class="row m-0" *ngIf="showView != 'edit'">
            <div class="col-6">
              <h4>
                {{ selectedDoctorUserData.title }}
                {{ selectedDoctorUserData.first }}
                {{ selectedDoctorUserData.last }} Services
              </h4>
            </div>
            <div class="col-6">
              <button
                mat-mini-fab
                class="float-right basic-button m-3"
                matTooltip="Add New Service"
                color="primary"
                (click)="addNewService()"
              >
                <mat-icon class="notranslate">add</mat-icon>
              </button>
            </div>
          </div>
          <div class="divider divider-grey"></div>

          <shared-virtual-service
            class="full-height full-width"
            *ngIf="showView == 'edit'"
            (backBtnClicked)="back()"
            (deleteBtnClicked)="delete()"
            (cancelBtnClicked)="cancel()"
            (saveBtnClicked)="save()"
            (setMinPrice)="setMinPrice($event)"
            (selectSurvey)="selectSurvey()"
            (copiedURLSuccess)="copiedURLSuccess()"
            [(selectedService)]="selectedService"
            [existingServicesArray]="companyServices"
            [existingCategoriesArray]="companyCategories"
            [companyPlan]="!!companyPlan ? companyPlan : selectedPlan"
            [newService]="newService"
            [selectedServiceURL]="selectedServiceURL"
            [publicBilling]="
              !!selectedService?.data?.publicBilling
                ? selectedService?.data?.publicBilling
                : companyData.publicBilling
            "
            [patientSubscriptions]="companyData.patientSubscriptions"
            [userData]="userData"
          >
          </shared-virtual-service>

          <div class="full-width p-4" *ngIf="showView == 'list'">
            <div
              *ngFor="let service of userServices; let i = index"
              class="full-width p-0 m-0 location-item"
              (click)="editService(service)"
            >
              <div class="row">
                <div class="service-color-holder float-left"></div>
                <div class="service-drag-image"></div>
                <div class="col-5 pt-3">
                  <h4 class="service-header full-width">
                    {{ service.data.label }}
                  </h4>
                </div>
                <div class="col-2 hidden-md-down pt-3">
                  <span class="service-sample-detail"
                    >{{ service.data.duration }} min.</span
                  >
                </div>
                <div class="col-2 hidden-md-down pt-3">
                  <span class="service-sample-detail"
                    >${{ service.data.price }}</span
                  >
                </div>
                <div class="col-2 hidden-md-down pt-3">
                  <span class="service-sample-detail">
                    <mat-icon *ngIf="service.data.video" class="notranslate"
                      >video_call</mat-icon
                    >
                    <mat-icon *ngIf="service.data.phone" class="notranslate"
                      >phone</mat-icon
                    >
                    <mat-icon *ngIf="service.data.inPerson" class="notranslate"
                      >store</mat-icon
                    >
                  </span>
                </div>
                <div class="col-5 hidden-lg-up" style="padding-top:15px;"></div>
                <mat-icon
                  class=" goto-item float-right notranslate pull-right material-icons"
                  style="font-size:36px;margin-top:auto;margin-bottom:auto;"
                  >chevron_right</mat-icon
                >
              </div>
              <div class="row">
                <div class="col-5 pt-0 pl-4">
                  <span class="pl-3 service-sample-description ">
                    <img
                      *ngIf="service.companyID == 'tiaHealth' && !hideTiaLogo"
                      src="{{ 'assets/images/logo-1.png' }}"
                      style="height: 20px;margin-right:0.3em;"
                    />
                    <span
                      *ngIf="
                        service.planID && companyPlansDictionary[service.planID]
                      "
                      ><b>{{
                        companyPlansDictionary[service.planID].title
                      }}</b></span
                    >
                    <!-- <span *ngFor="let category of service.data.category"> {{category.name}}, </span> -->
                    {{ service.data.desc ? '- ' + service.data.desc : '' }}
                  </span>
                </div>
                <div class="col-7 pt-0 pl-4 text-right">
                  <span class="service-sample-detail">{{
                    service.data.survey ? service.data.survey.name : ''
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </ng-container>

    <insig-bottom-sheet [show]="showBottomSheet">
      <admin-scheduler-daily-settings
        [daySelected]="daySelected"
        (toggleBottomSheet)="toggleBottomSheet()"
        [fullUserMap]="fullUserMap"
        [scheduleSelected]="scheduleSelected"
        (selectDoctor)="selectDoctor($event)"
      >
      </admin-scheduler-daily-settings>
    </insig-bottom-sheet>
  </div>
</insig-sidenav-menu>
