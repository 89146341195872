<div class="background-content z-index-1 background-color-light-gray">
  <div class="icons">
      <div class="background-icon-top-right">
          <img alt="" src="assets/images/background/bandaid-1.svg">
      </div>
      <div class="background-icon-bottom-left">
          <img alt="" src="assets/images/background/bandaid-2.svg">
      </div>
      <div class="background-icon-middle-right">
          <img alt="" src="assets/images/background/pulse.svg">
      </div>
      <div class="background-icon-middle-left">
          <img alt="" src="assets/images/background/pills-1.svg">
      </div>
  </div>
</div>

<div class="position-relative h-100 z-index-2">
  <div class="d-flex flex-column flex-md-row h-100 overflow-scroll">
    <div class="d-flex d-md-none flex-row justify-content-between">
      <ng-container *ngTemplateOutlet="companyLogo"></ng-container>
      <ng-container *ngTemplateOutlet="languageDropdown"></ng-container>
    </div>

    <div class="d-none d-md-block flex-even">
      <ng-container *ngTemplateOutlet="companyLogo"></ng-container>
    </div>

    <div class="flex-fill py-md-4">
        <ng-content></ng-content>
    </div>

    <div class="d-none d-md-flex flex-even justify-content-end align-items-start">
      <ng-container *ngTemplateOutlet="languageDropdown"></ng-container>
    </div>
  </div>
</div>

<ng-template #companyLogo>
  <img
    *ngIf="AppConfig?.branding"
    src="{{ AppConfig?.branding }}"
    class="login-header-logo-img"
    alt="company logo"
  />
  <img
    *ngIf="!AppConfig?.branding"
    src="assets/images/global/Insig-RGB-Colour.svg"
    class="login-header-logo-img"
    alt="company logo"
  />
</ng-template>

<ng-template #languageDropdown>
  <insig-health-language-dropdown
    *ngIf="!hideLanguageDropdown"
    class="d-flex align-items-center m-20px"
  ></insig-health-language-dropdown>
</ng-template>
