<nav class="app-sidebar sidebar-width d-flex flex-column">
  <section class="sidebar-header d-flex flex-row bg-color-primary" style="height: 50px;">
    <div *ngIf="!!specialLogo" class="text-center w-100">
      <img src="{{ specialLogo }}" width="45px" />
    </div>

    <svg
      class="m-auto"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="35px"
      height="35px"
      viewBox="0 0 246.001 251.461"
      enable-background="new 0 0 246.001 251.461"
      xml:space="preserve"
      *ngIf="!specialLogo"
    >
      <g>
        <path
          *ngIf="AppConfig.colorOption == '21'"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          stroke="#000"
          stroke-width="2"
          d="M74.567,15.925
      		c6.059,0.183,12.214,3.017,16.939,8.778c0.545,0.665,0.909,0.755,1.646,0.323c6.274-3.676,12.563-7.327,18.873-10.944
      		c0.712-0.408,0.901-0.796,0.762-1.603c-0.746-4.304,0.633-7.864,4.155-10.411c3.447-2.492,7.244-2.735,10.983-0.722
      		c3.829,2.062,5.839,5.458,5.412,9.779c-0.205,2.078,0.581,2.799,2.109,3.658c5.887,3.308,11.716,6.716,17.547,10.123
      		c0.681,0.398,1.02,0.367,1.559-0.257c6.481-7.501,14.657-10.394,24.231-7.878c9.532,2.503,15.234,9.019,17.188,18.684
      		c0.834,4.127,0.365,8.212-1.128,12.146c-0.345,0.91-0.284,1.361,0.628,1.879c6.288,3.572,12.543,7.203,18.793,10.841
      		c0.699,0.407,1.135,0.458,1.847-0.124c3.492-2.852,7.4-3.395,11.426-1.361c4.023,2.033,6.001,5.51,5.694,10.002
      		c-0.303,4.438-2.723,7.513-6.896,9.093c-0.794,0.301-0.987,0.664-0.984,1.455c0.03,7.24,0.034,14.479-0.003,21.719
      		c-0.005,0.895,0.245,1.175,1.139,1.344c11.811,2.233,19.778,12.037,19.515,23.939c-0.239,10.752-8.303,20.243-18.93,22.279
      		c-1.702,0.326-1.702,0.326-1.702,2.044c0,7.12,0.013,14.239-0.019,21.358c-0.004,0.799,0.203,1.151,0.991,1.45
      		c4.173,1.584,6.533,4.662,6.905,9.098c0.359,4.272-1.977,8.281-5.815,10.069c-4.088,1.905-7.95,1.364-11.422-1.511
      		c-0.638-0.528-1.045-0.585-1.752-0.171c-6.277,3.672-12.571,7.314-18.881,10.93c-0.722,0.413-0.752,0.795-0.496,1.521
      		c4.926,14.013-3.437,28.63-18.054,31.201c-8.663,1.523-16.016-1.299-21.869-7.864c-0.678-0.76-1.082-0.829-1.934-0.33
      		c-6.305,3.703-12.633,7.366-18.978,11c-0.756,0.434-0.911,0.877-0.784,1.712c0.675,4.423-0.841,8.011-4.561,10.453
      		c-3.726,2.447-7.677,2.443-11.4-0.008c-3.716-2.447-5.235-6.04-4.54-10.458c0.149-0.948-0.051-1.399-0.892-1.878
      		c-6.319-3.598-12.61-7.246-18.892-10.909c-0.722-0.421-1.115-0.493-1.765,0.236c-6.519,7.324-14.654,10.112-24.121,7.553
      		c-9.466-2.561-15.121-9.06-17.053-18.674c-0.821-4.086-0.36-8.131,1.1-12.033c0.297-0.794,0.202-1.156-0.561-1.592
      		c-6.282-3.582-12.542-7.205-18.788-10.85c-0.688-0.4-1.107-0.428-1.753,0.137c-3.274,2.864-7.741,3.416-11.532,1.516
      		c-3.794-1.901-6.045-5.865-5.756-10.132c0.282-4.164,3.098-7.774,7.226-9.123c0.775-0.254,0.932-0.61,0.929-1.351
      		c-0.026-7.319-0.027-14.639,0-21.959c0.003-0.805-0.13-1.139-1.047-1.287c-12.088-1.961-20.517-12.52-19.816-24.743
      		c0.621-10.825,8.849-19.888,19.761-21.669c0.977-0.159,1.105-0.525,1.102-1.362c-0.027-7.2-0.027-14.399,0.001-21.599
      		c0.003-0.784-0.158-1.158-0.989-1.428c-4.093-1.327-6.92-5.008-7.169-9.165c-0.257-4.28,2.015-8.224,5.815-10.098
      		c3.843-1.894,8.388-1.276,11.613,1.666c0.599,0.546,0.975,0.521,1.621,0.144c6.278-3.67,12.565-7.325,18.881-10.928
      		c0.849-0.485,0.93-0.887,0.6-1.758C45.402,32.373,56.708,15.924,74.567,15.925z M158.766,192.363
      		c-0.131-0.241-0.258-0.49-0.397-0.732c-3.783-6.534-7.585-13.058-11.332-19.612c-0.504-0.882-0.91-0.883-1.762-0.484
      		c-14.93,6.986-29.878,6.985-44.802-0.018c-0.889-0.417-1.268-0.31-1.749,0.531c-3.708,6.484-7.451,12.95-11.224,19.397
      		c-0.43,0.733-0.411,1.095,0.284,1.65c8.403,6.725,11.183,17.653,7.038,27.66c-0.318,0.767-0.188,1.077,0.509,1.476
      		c6.38,3.65,12.746,7.325,19.091,11.035c0.729,0.426,1.134,0.331,1.749-0.199c4.093-3.534,9.856-3.459,13.883,0.187
      		c0.567,0.515,0.92,0.487,1.52,0.138c6.35-3.702,12.709-7.389,19.086-11.044c0.729-0.418,0.907-0.744,0.549-1.575
      		c-1.853-4.294-2.396-8.768-1.546-13.379c1.064-5.776,3.899-10.514,8.444-14.233C158.37,192.944,158.746,192.804,158.766,192.363z
      		 M186.476,123.205c3.879,0,7.757-0.007,11.635,0.008c0.615,0.002,0.985-0.02,1.108-0.829c1.637-10.806,9.542-18.583,20.39-20.09
      		c0.735-0.102,0.981-0.35,0.979-1.119c-0.031-7.316-0.029-14.633-0.002-21.95c0.003-0.687-0.215-0.943-0.856-1.159
      		c-5.221-1.76-8.076-6.552-7.097-11.927c0.153-0.842-0.021-1.274-0.775-1.706c-6.312-3.605-12.604-7.245-18.883-10.907
      		c-0.67-0.391-1.019-0.414-1.546,0.253c-6.692,8.462-17.165,11.33-27.338,7.487c-0.771-0.292-1.093-0.139-1.483,0.542
      		c-3.8,6.615-7.619,13.219-11.479,19.799c-0.478,0.813-0.359,1.165,0.399,1.678c7.797,5.277,13.687,12.198,17.689,20.721
      		c2.716,5.782,4.244,11.875,4.68,18.239c0.054,0.776,0.305,0.985,1.064,0.977C178.799,123.185,182.638,123.205,186.476,123.205z
      		 M186.547,128.005c-3.601,0-7.2,0-10.8,0c-1.777,0-1.764,0.001-1.93,1.73c-1.542,16.146-8.995,28.856-22.255,38.162
      		c-0.799,0.561-0.891,0.931-0.397,1.771c3.783,6.443,7.53,12.908,11.237,19.396c0.482,0.845,0.89,0.951,1.769,0.611
      		c10.033-3.872,20.88-0.826,27.39,7.689c0.521,0.681,0.854,0.8,1.624,0.348c6.201-3.642,12.426-7.245,18.666-10.821
      		c0.699-0.4,0.924-0.775,0.779-1.595c-0.948-5.357,1.895-10.188,7.005-11.879c0.759-0.251,0.953-0.577,0.951-1.334
      		c-0.029-7.319-0.037-14.64,0.007-21.959c0.006-0.915-0.327-1.124-1.136-1.223c-10.37-1.262-18.798-9.54-20.218-19.88
      		c-0.112-0.815-0.367-1.044-1.174-1.035C194.227,128.028,190.387,128.005,186.547,128.005z M99.587,80.077
      		c0.277-0.128,0.567-0.262,0.855-0.396c14.976-6.993,29.952-6.995,44.91,0.034c0.825,0.388,1.196,0.322,1.664-0.495
      		c3.789-6.621,7.617-13.219,11.467-19.805c0.396-0.679,0.388-1.023-0.262-1.547c-8.305-6.697-11.124-17.433-7.163-27.326
      		c0.326-0.814,0.221-1.126-0.527-1.553c-6.312-3.604-12.603-7.248-18.881-10.91c-0.643-0.375-1.019-0.411-1.624,0.14
      		c-3.998,3.632-9.781,3.691-13.877,0.142c-0.646-0.56-1.063-0.542-1.752-0.14c-6.276,3.667-12.568,7.306-18.873,10.924
      		c-0.689,0.396-0.84,0.747-0.522,1.504c1.062,2.527,1.657,5.181,1.707,7.924c0.144,7.958-2.887,14.458-9.099,19.442
      		c-0.572,0.459-0.747,0.776-0.3,1.427c0.563,0.821,1.013,1.719,1.514,2.583c3.302,5.703,6.6,11.408,9.916,17.104
      		C98.944,79.482,99.029,79.985,99.587,80.077z M59.358,128.005c-3.8,0-7.6,0.022-11.399-0.018c-0.796-0.009-1.067,0.202-1.181,1.027
      		c-1.414,10.298-9.756,18.541-20.092,19.876c-0.718,0.093-1.021,0.266-1.018,1.077c0.034,7.439,0.029,14.879,0.004,22.319
      		c-0.002,0.68,0.224,0.944,0.858,1.175c5.088,1.848,7.773,6.499,6.854,11.871c-0.129,0.757,0.06,1.129,0.724,1.509
      		c6.277,3.592,12.54,7.209,18.785,10.857c0.697,0.406,1.027,0.36,1.53-0.293c6.594-8.568,17.236-11.582,27.373-7.748
      		c0.828,0.313,1.154,0.136,1.57-0.59c3.716-6.482,7.459-12.949,11.241-19.394c0.489-0.832,0.435-1.22-0.387-1.772
      		c-6.476-4.36-11.663-9.94-15.575-16.7c-3.974-6.867-6.206-14.269-6.758-22.175c-0.057-0.81-0.302-1.054-1.129-1.043
      		C66.958,128.032,63.158,128.005,59.358,128.005z M59.295,123.205c3.839,0,7.678-0.019,11.516,0.017
      		c0.753,0.007,1.03-0.186,1.072-0.972c0.078-1.435,0.218-2.873,0.455-4.29c2.449-14.673,9.755-26.286,22.01-34.745
      		c0.673-0.465,0.776-0.789,0.358-1.504c-3.872-6.62-7.715-13.257-11.537-19.906c-0.376-0.655-0.7-0.739-1.398-0.48
      		c-10.067,3.734-20.274,0.946-27.068-7.395c-0.479-0.587-0.82-0.667-1.47-0.287c-6.38,3.729-12.771,7.438-19.178,11.121
      		c-0.633,0.364-0.771,0.74-0.655,1.435c0.883,5.306-1.814,9.97-6.831,11.771c-0.699,0.251-0.897,0.563-0.895,1.271
      		c0.024,7.317,0.025,14.635,0,21.953c-0.002,0.742,0.187,1.022,0.964,1.125c10.473,1.39,18.697,9.523,20.149,19.937
      		c0.108,0.779,0.368,0.972,1.113,0.965C51.697,123.187,55.497,123.205,59.295,123.205z"
        />
        <path
          *ngIf="AppConfig.colorOption != '21'"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          stroke="#FFFFFF"
          stroke-width="2"
          d="M74.567,15.925
        		c6.059,0.183,12.214,3.017,16.939,8.778c0.545,0.665,0.909,0.755,1.646,0.323c6.274-3.676,12.563-7.327,18.873-10.944
        		c0.712-0.408,0.901-0.796,0.762-1.603c-0.746-4.304,0.633-7.864,4.155-10.411c3.447-2.492,7.244-2.735,10.983-0.722
        		c3.829,2.062,5.839,5.458,5.412,9.779c-0.205,2.078,0.581,2.799,2.109,3.658c5.887,3.308,11.716,6.716,17.547,10.123
        		c0.681,0.398,1.02,0.367,1.559-0.257c6.481-7.501,14.657-10.394,24.231-7.878c9.532,2.503,15.234,9.019,17.188,18.684
        		c0.834,4.127,0.365,8.212-1.128,12.146c-0.345,0.91-0.284,1.361,0.628,1.879c6.288,3.572,12.543,7.203,18.793,10.841
        		c0.699,0.407,1.135,0.458,1.847-0.124c3.492-2.852,7.4-3.395,11.426-1.361c4.023,2.033,6.001,5.51,5.694,10.002
        		c-0.303,4.438-2.723,7.513-6.896,9.093c-0.794,0.301-0.987,0.664-0.984,1.455c0.03,7.24,0.034,14.479-0.003,21.719
        		c-0.005,0.895,0.245,1.175,1.139,1.344c11.811,2.233,19.778,12.037,19.515,23.939c-0.239,10.752-8.303,20.243-18.93,22.279
        		c-1.702,0.326-1.702,0.326-1.702,2.044c0,7.12,0.013,14.239-0.019,21.358c-0.004,0.799,0.203,1.151,0.991,1.45
        		c4.173,1.584,6.533,4.662,6.905,9.098c0.359,4.272-1.977,8.281-5.815,10.069c-4.088,1.905-7.95,1.364-11.422-1.511
        		c-0.638-0.528-1.045-0.585-1.752-0.171c-6.277,3.672-12.571,7.314-18.881,10.93c-0.722,0.413-0.752,0.795-0.496,1.521
        		c4.926,14.013-3.437,28.63-18.054,31.201c-8.663,1.523-16.016-1.299-21.869-7.864c-0.678-0.76-1.082-0.829-1.934-0.33
        		c-6.305,3.703-12.633,7.366-18.978,11c-0.756,0.434-0.911,0.877-0.784,1.712c0.675,4.423-0.841,8.011-4.561,10.453
        		c-3.726,2.447-7.677,2.443-11.4-0.008c-3.716-2.447-5.235-6.04-4.54-10.458c0.149-0.948-0.051-1.399-0.892-1.878
        		c-6.319-3.598-12.61-7.246-18.892-10.909c-0.722-0.421-1.115-0.493-1.765,0.236c-6.519,7.324-14.654,10.112-24.121,7.553
        		c-9.466-2.561-15.121-9.06-17.053-18.674c-0.821-4.086-0.36-8.131,1.1-12.033c0.297-0.794,0.202-1.156-0.561-1.592
        		c-6.282-3.582-12.542-7.205-18.788-10.85c-0.688-0.4-1.107-0.428-1.753,0.137c-3.274,2.864-7.741,3.416-11.532,1.516
        		c-3.794-1.901-6.045-5.865-5.756-10.132c0.282-4.164,3.098-7.774,7.226-9.123c0.775-0.254,0.932-0.61,0.929-1.351
        		c-0.026-7.319-0.027-14.639,0-21.959c0.003-0.805-0.13-1.139-1.047-1.287c-12.088-1.961-20.517-12.52-19.816-24.743
        		c0.621-10.825,8.849-19.888,19.761-21.669c0.977-0.159,1.105-0.525,1.102-1.362c-0.027-7.2-0.027-14.399,0.001-21.599
        		c0.003-0.784-0.158-1.158-0.989-1.428c-4.093-1.327-6.92-5.008-7.169-9.165c-0.257-4.28,2.015-8.224,5.815-10.098
        		c3.843-1.894,8.388-1.276,11.613,1.666c0.599,0.546,0.975,0.521,1.621,0.144c6.278-3.67,12.565-7.325,18.881-10.928
        		c0.849-0.485,0.93-0.887,0.6-1.758C45.402,32.373,56.708,15.924,74.567,15.925z M158.766,192.363
        		c-0.131-0.241-0.258-0.49-0.397-0.732c-3.783-6.534-7.585-13.058-11.332-19.612c-0.504-0.882-0.91-0.883-1.762-0.484
        		c-14.93,6.986-29.878,6.985-44.802-0.018c-0.889-0.417-1.268-0.31-1.749,0.531c-3.708,6.484-7.451,12.95-11.224,19.397
        		c-0.43,0.733-0.411,1.095,0.284,1.65c8.403,6.725,11.183,17.653,7.038,27.66c-0.318,0.767-0.188,1.077,0.509,1.476
        		c6.38,3.65,12.746,7.325,19.091,11.035c0.729,0.426,1.134,0.331,1.749-0.199c4.093-3.534,9.856-3.459,13.883,0.187
        		c0.567,0.515,0.92,0.487,1.52,0.138c6.35-3.702,12.709-7.389,19.086-11.044c0.729-0.418,0.907-0.744,0.549-1.575
        		c-1.853-4.294-2.396-8.768-1.546-13.379c1.064-5.776,3.899-10.514,8.444-14.233C158.37,192.944,158.746,192.804,158.766,192.363z
        		 M186.476,123.205c3.879,0,7.757-0.007,11.635,0.008c0.615,0.002,0.985-0.02,1.108-0.829c1.637-10.806,9.542-18.583,20.39-20.09
        		c0.735-0.102,0.981-0.35,0.979-1.119c-0.031-7.316-0.029-14.633-0.002-21.95c0.003-0.687-0.215-0.943-0.856-1.159
        		c-5.221-1.76-8.076-6.552-7.097-11.927c0.153-0.842-0.021-1.274-0.775-1.706c-6.312-3.605-12.604-7.245-18.883-10.907
        		c-0.67-0.391-1.019-0.414-1.546,0.253c-6.692,8.462-17.165,11.33-27.338,7.487c-0.771-0.292-1.093-0.139-1.483,0.542
        		c-3.8,6.615-7.619,13.219-11.479,19.799c-0.478,0.813-0.359,1.165,0.399,1.678c7.797,5.277,13.687,12.198,17.689,20.721
        		c2.716,5.782,4.244,11.875,4.68,18.239c0.054,0.776,0.305,0.985,1.064,0.977C178.799,123.185,182.638,123.205,186.476,123.205z
        		 M186.547,128.005c-3.601,0-7.2,0-10.8,0c-1.777,0-1.764,0.001-1.93,1.73c-1.542,16.146-8.995,28.856-22.255,38.162
        		c-0.799,0.561-0.891,0.931-0.397,1.771c3.783,6.443,7.53,12.908,11.237,19.396c0.482,0.845,0.89,0.951,1.769,0.611
        		c10.033-3.872,20.88-0.826,27.39,7.689c0.521,0.681,0.854,0.8,1.624,0.348c6.201-3.642,12.426-7.245,18.666-10.821
        		c0.699-0.4,0.924-0.775,0.779-1.595c-0.948-5.357,1.895-10.188,7.005-11.879c0.759-0.251,0.953-0.577,0.951-1.334
        		c-0.029-7.319-0.037-14.64,0.007-21.959c0.006-0.915-0.327-1.124-1.136-1.223c-10.37-1.262-18.798-9.54-20.218-19.88
        		c-0.112-0.815-0.367-1.044-1.174-1.035C194.227,128.028,190.387,128.005,186.547,128.005z M99.587,80.077
        		c0.277-0.128,0.567-0.262,0.855-0.396c14.976-6.993,29.952-6.995,44.91,0.034c0.825,0.388,1.196,0.322,1.664-0.495
        		c3.789-6.621,7.617-13.219,11.467-19.805c0.396-0.679,0.388-1.023-0.262-1.547c-8.305-6.697-11.124-17.433-7.163-27.326
        		c0.326-0.814,0.221-1.126-0.527-1.553c-6.312-3.604-12.603-7.248-18.881-10.91c-0.643-0.375-1.019-0.411-1.624,0.14
        		c-3.998,3.632-9.781,3.691-13.877,0.142c-0.646-0.56-1.063-0.542-1.752-0.14c-6.276,3.667-12.568,7.306-18.873,10.924
        		c-0.689,0.396-0.84,0.747-0.522,1.504c1.062,2.527,1.657,5.181,1.707,7.924c0.144,7.958-2.887,14.458-9.099,19.442
        		c-0.572,0.459-0.747,0.776-0.3,1.427c0.563,0.821,1.013,1.719,1.514,2.583c3.302,5.703,6.6,11.408,9.916,17.104
        		C98.944,79.482,99.029,79.985,99.587,80.077z M59.358,128.005c-3.8,0-7.6,0.022-11.399-0.018c-0.796-0.009-1.067,0.202-1.181,1.027
        		c-1.414,10.298-9.756,18.541-20.092,19.876c-0.718,0.093-1.021,0.266-1.018,1.077c0.034,7.439,0.029,14.879,0.004,22.319
        		c-0.002,0.68,0.224,0.944,0.858,1.175c5.088,1.848,7.773,6.499,6.854,11.871c-0.129,0.757,0.06,1.129,0.724,1.509
        		c6.277,3.592,12.54,7.209,18.785,10.857c0.697,0.406,1.027,0.36,1.53-0.293c6.594-8.568,17.236-11.582,27.373-7.748
        		c0.828,0.313,1.154,0.136,1.57-0.59c3.716-6.482,7.459-12.949,11.241-19.394c0.489-0.832,0.435-1.22-0.387-1.772
        		c-6.476-4.36-11.663-9.94-15.575-16.7c-3.974-6.867-6.206-14.269-6.758-22.175c-0.057-0.81-0.302-1.054-1.129-1.043
        		C66.958,128.032,63.158,128.005,59.358,128.005z M59.295,123.205c3.839,0,7.678-0.019,11.516,0.017
        		c0.753,0.007,1.03-0.186,1.072-0.972c0.078-1.435,0.218-2.873,0.455-4.29c2.449-14.673,9.755-26.286,22.01-34.745
        		c0.673-0.465,0.776-0.789,0.358-1.504c-3.872-6.62-7.715-13.257-11.537-19.906c-0.376-0.655-0.7-0.739-1.398-0.48
        		c-10.067,3.734-20.274,0.946-27.068-7.395c-0.479-0.587-0.82-0.667-1.47-0.287c-6.38,3.729-12.771,7.438-19.178,11.121
        		c-0.633,0.364-0.771,0.74-0.655,1.435c0.883,5.306-1.814,9.97-6.831,11.771c-0.699,0.251-0.897,0.563-0.895,1.271
        		c0.024,7.317,0.025,14.635,0,21.953c-0.002,0.742,0.187,1.022,0.964,1.125c10.473,1.39,18.697,9.523,20.149,19.937
        		c0.108,0.779,0.368,0.972,1.113,0.965C51.697,123.187,55.497,123.205,59.295,123.205z"
        />
      </g>
    </svg>
  </section>

  <div class="d-flex flex-column flex h-100 justify-content-end">
    <section
      class="sidebar-content flex"
      [ngClass]="{ 'second-navbar-padding': AppConfig.secondToolbar }"
    >
      <insig-health-sidenav-menu></insig-health-sidenav-menu>
    </section>

    <section class="sidebar-footer">
      <ul class="nav">
        <li>
          <a
            mat-button
            aria-label="menu"
            (click)="logout()"
            matTooltip="LOGOUT"
            [matTooltipPosition]="'right'"
          >
            <div
              class="h-100 w-100 d-flex flex-row flex-md-column justify-content-sidenav align-items-center"
            >
              <mat-icon class="notranslate">exit_to_app</mat-icon>
              <span class="nav-text ml-4 d-inline d-md-none">Logout</span>
            </div>
          </a>
        </li>
      </ul>
    </section>
  </div>
</nav>
