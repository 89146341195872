<mat-card>
  <div [style.min-width]="'500px'">
    <h1 mat-dialog-title>Integrations - {{ startingText }}</h1>

    <div mat-dialog-content class="text-center">
      <div *ngIf="multiYesNo">
        <button
          (click)="
            multiYesNoOption = false; answer = multiYesNoAnswers.integrations[0]
          "
          *ngIf="multiYesNoOption"
          mat-raised-button
        >
          No
        </button>
        <button *ngIf="!multiYesNoOption" mat-raised-button color="primary">
          No
        </button>
        <span class="space"></span>
        <button
          (click)="
            multiYesNoOption = true; answer = multiYesNoAnswers.integrations[1]
          "
          *ngIf="!multiYesNoOption"
          mat-raised-button
        >
          Yes
        </button>
        <button *ngIf="multiYesNoOption" mat-raised-button color="primary">
          Yes
        </button>
        <div class="divider"></div>
        <div class="divider"></div>
      </div>

      <mat-form-field>
        <mat-label>Integration ID</mat-label>
        <input
          matInput
          required
          [(ngModel)]="answer.integrationID"
        />
      </mat-form-field>
      <div class="divider"></div>

      <hr />
      <div class="divider"></div>

      <p *ngIf="!answer.integrationID">
        Enter an ID above to change options below
      </p>
      <mat-radio-group
        [disabled]="!answer.integrationID"
        [ngModel]="answer.integrationType"
        (ngModelChange)="changeIntegrationType($event)"
        class="vertical-group"
      >
        <mat-radio-button [value]="'checkmark'">
          Checkmark
        </mat-radio-button>
        <span class="space"></span>
        <mat-radio-button [value]="'text'">
          Text
        </mat-radio-button>
        <span class="space"></span>
        <mat-radio-button [value]="'image'">
          Image
        </mat-radio-button>
      </mat-radio-group>
      <div class="divider"></div>
      <div class="row" *ngIf="answer.integrationID">
        <div class="col-10 offset-1">
          <p
            *ngIf="
              !answer.integrationType || answer.integrationType == 'checkmark'
            "
          >
            &#10004;
          </p>

          <mat-form-field
            *ngIf="answer.integrationType == 'text'"
            class="full-width"
          >
            <mat-label>Text</mat-label>
            <input
              matInput
              [(ngModel)]="answer.integrationText"
            />
          </mat-form-field>

          <div *ngIf="answer.integrationType == 'image'">
            <div #container [hidden]="!answer.integrationImage">
              <img
                src="{{ answer.integrationImage }}"
                [ngStyle]="{ 'max-width.px': maxWidth }"
              />
              <div class="divider"></div>
              <div class="row">
                <div class="col-10 offset-1 centeringMargin">
                  <button
                    color="primary"
                    mat-raised-button
                    (click)="removeImage(answer)"
                  >
                    Change Image
                  </button>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="!answer.integrationImage">
              <div class="col-10 offset-1" [hidden]="loading">
                <p>Upload image below</p>
                <ngx-file-drop
                  (onFileDrop)="onAdded($event)"
                  multiple="false"
                  accept=".png,.jpg,.jpeg"
                  dropZoneClassName="ngxFileDropZone"
                  contentClassName="ngxFileDropContent"
                >
                  <ng-template
                    ngx-file-drop-content-tmp
                    let-openFileSelector="openFileSelector"
                  >
                    <span>Drop file here</span>
                    <span class="px-3"><!-- divider --></span>
                    <button
                      mat-raised-button
                      color="primary"
                      type="button"
                      (click)="openFileSelector()"
                    >
                      Browse
                    </button>
                  </ng-template>
                </ngx-file-drop>
              </div>
              <mat-progress-spinner
                *ngIf="loading"
                class="mx-auto"
                mode="indeterminate"
              ></mat-progress-spinner>
            </div>
            <div class="divider"></div>
          </div>
        </div>
      </div>
    </div>

    <mat-dialog-actions align="end">
      <button mat-raised-button (click)="dialogRef.close()">Close</button>
    </mat-dialog-actions>
  </div>
</mat-card>
