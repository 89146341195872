<div
  class="h-100 d-flex justify-content-start align-items-center ins-rounded ins-bg-white"
>
  <div class="px-2 d-flex justify-content-center align-items-center">
    <mat-icon class="primary-color-text notranslate">search</mat-icon>
  </div>
  <input
    class="border-0 ins-bg-none flex"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [ngModel]="searchValue"
    (ngModelChange)="onChange($event)"
    (blur)="onTouched()"
  />
</div>
