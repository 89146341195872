<mat-card>
  <h1 matDialogTitle class="text-center">Are you sure you want to delete</h1>

  <div mat-dialog-content class="text-center">
    <h4 class="text-center">{{ data.surveyName }}</h4>
    <div class="divider"></div>
    <div class="divider"></div>
  </div>

  <mat-dialog-actions align="end">
    <button mat-button (click)="dialogRef.close(false)">Cancel</button>
    <button
      id="delete-survey-confirm"
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(true)"
    >
      Confirm
    </button>
  </mat-dialog-actions>
</mat-card>
