// @ts-strict-ignore
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'priority-question',
  templateUrl: './priority.component.html',
  styleUrls: ['./priority.component.css'],
})
export class PriorityComponent implements OnInit, OnChanges {
  public readonly AVAILABLE_CONTAINER = 'available';
  public readonly SORTED_CONTAINER = 'sorted';

  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();

  ngOnInit() {
    if (!this.question.response.priorityList) {
      this.question.response.priorityList = [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  availableDropped(event: CdkDragDrop<string, string>) {
    if (event.previousContainer.data === this.SORTED_CONTAINER) {
      // move from sorted to available
      this.question.response.priorityList.splice(event.previousIndex, 1);
      this.question.priorityList.splice(event.currentIndex, 0, event.item.data);
    }
  }

  sortedDropped(event: CdkDragDrop<string, string>) {
    if (event.previousContainer.data === this.AVAILABLE_CONTAINER) {
      // move from available to sorted
      this.question.priorityList.splice(event.previousIndex, 1);
      this.question.response.priorityList.splice(
        event.currentIndex,
        0,
        event.item.data
      );
    } else if (event.previousContainer.data === this.SORTED_CONTAINER) {
      // reorder sorted
      moveItemInArray(
        this.question.response.priorityList,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
} // end component
