import { Component, inject } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'confirm-company-plan-delete',
  templateUrl: './confirm-company-plan-delete.component.html',
})
export class ConfirmCompanyPlanDeleteDialogComponent {
  public readonly dialogRef = inject<MatDialogRef<ConfirmCompanyPlanDeleteDialogComponent>>(MatDialogRef<ConfirmCompanyPlanDeleteDialogComponent>);
}
