<div>
  <form [formGroup]="yearMonthDayForm">
    <div class="form-group row">
      <div class="col-2"></div>
      <div class="col-8">
        <div class="form-group row">
          <div class="col-4">
            <mat-form-field class="full-width">
              <mat-label>Year</mat-label>            
              <input
                type="number"
                matInput
                autocomplete="off"
                [attr.maxlength]="4"
                formControlName="year"
              />
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="full-width">
              <mat-label>Month</mat-label>
              <input
                type="number"
                matInput
                autocomplete="off"
                [attr.maxlength]="2"
                formControlName="month"
              />
              <mat-error>Please enter a valid month</mat-error>
            </mat-form-field>
          </div>

          <div class="col-4">
            <mat-form-field class="full-width">
              <mat-label>Day</mat-label>
              <input
                type="number"
                matInput
                autocomplete="off"
                [attr.maxlength]="2"
                formControlName="day"
              />
              <mat-error>Please enter a valid day</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-2"></div>
    </div>
  </form>
</div>
