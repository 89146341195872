import { Component, Input } from '@angular/core';

@Component({
  selector: 'insig-health-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss'],
})
export class LoginContainerComponent {
  @Input() hideLanguageDropdown = false;
}
