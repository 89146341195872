<div>
  <div class="row m-0">
    <div class="col-6">
      <h4>{{ selectedPlan?.title }}</h4>
    </div>
    <div class="col-6">
      <button
        mat-mini-fab
        class="pull-right basic-button m-3"
        matTooltip="Delete Plan"
        color="primary"
        [matTooltipPosition]="'right'"
        (click)="deleteCompanyPlan.emit()"
      >
        <i class="material-icons">delete</i>
      </button>

      <button
        mat-mini-fab
        class="pull-right basic-button m-3"
        matTooltip="Add New Service"
        color="primary"
        [matTooltipPosition]="'right'"
        (click)="addNewService.emit()"
      >
        <i class="material-icons">add</i>
      </button>

      <button
        mat-mini-fab
        class="pull-right basic-button m-3"
        matTooltip="Copy Plan URL"
        color="primary"
        [matTooltipPosition]="'right'"
        (click)="copyPlanURL.emit()"
      >
        <i class="material-icons">language</i>
      </button>

      <button
        mat-mini-fab
        class="pull-right basic-button m-3"
        matTooltip="Settings"
        color="primary"
        [matTooltipPosition]="'right'"
        (click)="companyPlanSettings.emit()"
      >
        <i class="material-icons">settings</i>
      </button>
    </div>
  </div>
  <div class="divider divider-grey"></div>

  <ng-content></ng-content>
</div>
