// @ts-strict-ignore
import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { QuestionType } from 'insig-types/surveys/question/index';

@Component({
  selector: 'question-types-dialog',
  templateUrl: 'question-types-dialog.component.html',
  styleUrls: ['./question-types-dialog.component.css'],
})
export class QuestionTypesDialogComponent {
  public readonly dialogRef = inject<MatDialogRef<
          QuestionTypesDialogComponent,
          QuestionType | 'paragraph'
        >>(MatDialogRef<
          QuestionTypesDialogComponent,
          QuestionType | 'paragraph'
        >);
  chosenAnswerType: QuestionType | 'paragraph';

  confirmSelection(chosenAnswerType: QuestionType | 'paragraph'): void {
    this.dialogRef.close(chosenAnswerType);
  }
}
