// @ts-strict-ignore
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  DoCheck,
} from '@angular/core';

@Component({
  selector: 'grid-edit',
  templateUrl: './grid-edit.component.html',
  styleUrls: ['./grid-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridEditComponent implements OnInit, DoCheck {
  private _question: any;
  @Input() set question(value: any) {
    this._question = value;
  }
  get question(): any {
    return this._question;
  }
  @Output() questionChange: EventEmitter<any> = new EventEmitter<any>();
  public dynamicTableWidth: string;

  ngOnInit() {
    if (!this.question.grid) {
      this.question.grid = {
        cols: [],
        rows: [],
      };
      this.addColumn('Column');
      this.addColumn('Column');
      this.addRow('Row');
      this.addRow('Row');
    }
    this.dynamicTableWidth = (this.question.grid.cols.length + 2) * 100 + 'px';
  }

  ngDoCheck() {
    this.dynamicTableWidth = (this.question.grid.cols.length + 2) * 100 + 'px';
  }

  addColumn(label?: string) {
    const newCol = {
      id: this.generateRandomID(32),
      label: label || '',
      orderNo: this.question.grid.cols.length + 1,
    };
    this.question.grid.cols.push(newCol);
  }

  moveColumnRight(index: number) {
    const gettingMoved = this.question.grid.cols[index];
    this.question.grid.cols[index] = this.question.grid.cols[index + 1];
    this.question.grid.cols[index + 1] = gettingMoved;
  }
  moveColumnLeft(index: number) {
    const gettingMoved = this.question.grid.cols[index];
    this.question.grid.cols[index] = this.question.grid.cols[index - 1];
    this.question.grid.cols[index - 1] = gettingMoved;
  }

  removeColumn(index: number) {
    this.question.grid.cols.splice(index, 1);
    for (let i = index; i < this.question.grid.cols.length; i++) {
      this.question.grid.cols[i].orderNo--;
    }
  }

  addRow(label?: string) {
    const newRow = {
      id: this.generateRandomID(32),
      label: label ? label : '',
      orderNo: this.question.grid.rows.length + 1,
    };
    this.question.grid.rows.push(newRow);
  }

  removeRow(index: number) {
    this.question.grid.rows.splice(index, 1);
    for (let i = index; i < this.question.grid.rows.length; i++) {
      this.question.grid.rows[i].orderNo--;
    }
  }

  generateRandomID(length: number) {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  changeGridType(type: string) {
    // Some hard-coded templates for grid
    switch (type) {
      case 'Manual':
        break; // Do nothing
      case 'Yes / No':
        this.question.grid.cellInputType = 'radio';
        if (this.question.grid.cols.length === 0) {
          this.addColumn('Yes');
          this.addColumn('No');
        } else if (this.question.grid.cols.length === 1) {
          this.question.grid.cols[0].label = 'Yes';
          this.addColumn('No');
        } else {
          // this.question.grid.cols.length >= 2
          this.question.grid.cols.splice(2, this.question.grid.cols.length - 2);
          this.question.grid.cols[0].label = 'Yes';
          this.question.grid.cols[1].label = 'No';
        }
        break;
      case 'Normal / Abnormal':
        this.question.grid.cellInputType = 'radio';
        if (this.question.grid.cols.length === 0) {
          this.addColumn('Normal');
          this.addColumn('Abnormal');
        } else if (this.question.grid.cols.length === 1) {
          this.question.grid.cols[0].label = 'Normal';
          this.addColumn('Abnormal');
        } else {
          // this.question.grid.cols.length >= 2
          this.question.grid.cols.splice(2, this.question.grid.cols.length - 2);
          this.question.grid.cols[0].label = 'Normal';
          this.question.grid.cols[1].label = 'Abnormal';
        }
        break;
      case 'Vaccination':
        this.question.grid.cellInputType = 'checkbox';
        this.question.grid.cols = [];
        this.addColumn('Tetanus');
        this.addColumn('Diphtheria');
        this.addColumn('Pertussis');
        this.addColumn('Influenza');
        this.addColumn('Pneumococcal');
        this.addColumn('Hepatitis B');
        this.addColumn('Hepatitis A');
        this.addColumn('Meningococcal');
        this.addColumn('Measles');
        this.addColumn('Mumps');
        this.addColumn('Rubella');
        this.addColumn('Varicella');
        this.addColumn('HPV');
        this.addColumn('Herpes zoster');
        this.question.grid.rows = [];
        this.addRow('2 mo');
        this.addRow('4 mo');
        this.addRow('6 mo');
        this.addRow('12 mo');
        this.addRow('15 mo');
        this.addRow('18 mo');
        this.addRow('4-6 yr');
        this.addRow('12 yr');
        this.addRow('14-16 yr');
        this.addRow('Every 10 yrs. thereafter');
    }
  }
} // end component
