<div>
  <mat-grid-list cols="2" rowHeight="60px" class="full-width">
    <mat-grid-tile [colspan]="1">
      <mat-form-field class="full-width">
        <mat-label>Min</mat-label>
        <input
          required
          matInput
          [ngModel]="question.min"
          type="number"
          [max]="question.max"
          (blur)="checkMin()"
          (ngModelChange)="updateMin($event)"
        />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <mat-form-field class="full-width">
        <mat-label>Max</mat-label>
        <input
          required
          matInput
          [ngModel]="question.max"
          type="number"
          [min]="question.min"
          (blur)="checkMax()"
          (ngModelChange)="updateMax($event)"
        />
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>
</div>
