// @ts-strict-ignore
import type { ExceptionSchedule } from "./doctorSettings.service";

export const MILLISECONDS_PER_SECOND = 1000;
export const MILLISECONDS_PER_MINUTE = 60000;
export const MINUTES_PER_HOUR = 60;
export const MILLISECONDS_PER_HOUR = MILLISECONDS_PER_MINUTE * MINUTES_PER_HOUR;
export const MILLISECONDS_PER_DAY = 24 * MILLISECONDS_PER_HOUR;

export function roundEpochMillisToNearestDay(epochMillis: number): number {
  const approximateEpochDay = epochMillis / MILLISECONDS_PER_DAY;
  const roundedEpochDay = Math.round(approximateEpochDay);
  return roundedEpochDay * MILLISECONDS_PER_DAY;
}

export function getBestEpochDayAlignedException(exceptionSchedules: ExceptionSchedule[]): ExceptionSchedule {
  if (exceptionSchedules.length === 0) {
    return undefined;
  } else if (exceptionSchedules.length === 1) {
    return exceptionSchedules[0];
  } else {
    const alignedExceptionSchedule = exceptionSchedules.find((exceptionSchedule) => {
      return exceptionSchedule.day === exceptionSchedule.epochDay * MILLISECONDS_PER_DAY;
    });
    return alignedExceptionSchedule ?? exceptionSchedules[0];
  }
}

export function getEpochDayFromDate(date: Date) {
  const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  return Math.floor(utcDate.getTime() / MILLISECONDS_PER_DAY);
}

export function convertMsPastMidnightToHoursAndMinutes(msPastMidnight: number): { hours: number, minutes: number } {
  const minutesPastMidnight = Math.floor(msPastMidnight / MILLISECONDS_PER_MINUTE);

  const hours = Math.floor(minutesPastMidnight / 60);
  const minutes = Math.floor(minutesPastMidnight % 60);

  return { hours, minutes };
}

export function convertHoursAndMinutesToTimeString(hoursAndMinutes: { hours: number, minutes: number }): string {
  const { hours, minutes } = hoursAndMinutes;
  
  const amOrPmString = hours >= 12 ? 'PM' : 'AM';
  const hourString = hours > 12 ? `${hours - 12}` : `${hours}`;
  const minuteString = minutes >= 10 ? `${minutes}` : `0${minutes}`;

  return `${hourString}:${minuteString} ${amOrPmString}`;
}
