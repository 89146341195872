<section class="h-100 px-4 overflow-x-auto">
  <div class="d-flex flex-row justify-content-start">
    <div class="article-title primary-color-text">Patients</div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-form-field class="full-width">
        <mat-icon matPrefix class="notranslate">search</mat-icon>
        <mat-label>Name</mat-label>
        <input
          matInput
          #patientSearch
          (keyup.enter)="searchPatients(patientSearch.value)"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="text-center mb-4">
    <button
      *ngIf="searchMode"
      mat-raised-button
      (click)="
        searchMode = false;
        loadAllPatients();
        patientSearch.value = null
      "
    >
      Clear Search
    </button>
    <span *ngIf="searchMode" class="space"></span>
    <button
      mat-raised-button
      color="primary"
      (click)="searchPatients(patientSearch.value)"
    >
      Search
    </button>
  </div>

  <div *ngIf="loading" class="text-center">
    <mat-progress-spinner
      class="mx-auto"
      mode="indeterminate"
    ></mat-progress-spinner>
    <h3>Loading Patients...</h3>
  </div>

  <div class="mat-elevation-z8" [hidden]="loading">
    <table mat-table [dataSource]="dataSource" matSort class="w-100 table-layout-fixed" aria-label="List of Admitted Patients">
      <ng-container matColumnDef="last">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="w-auto overflow-hidden text-overflow-ellipsis white-space-nowrap">Last Name</th>
        <td mat-cell *matCellDef="let patient" class="w-auto overflow-hidden text-overflow-ellipsis white-space-nowrap">{{patient.last}}</td>
      </ng-container>

      <ng-container matColumnDef="first">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="w-auto overflow-hidden text-overflow-ellipsis white-space-nowrap">First Name</th>
        <td mat-cell *matCellDef="let patient" class="w-auto overflow-hidden text-overflow-ellipsis white-space-nowrap">{{patient.first}}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="w-auto overflow-hidden text-overflow-ellipsis white-space-nowrap">Email</th>
        <td mat-cell *matCellDef="let patient" class="w-auto overflow-hidden text-overflow-ellipsis white-space-nowrap">{{patient.email}}</td>
      </ng-container>

      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="w-auto overflow-hidden text-overflow-ellipsis white-space-nowrap">Phone</th>
        <td mat-cell *matCellDef="let patient" class="w-auto overflow-hidden text-overflow-ellipsis white-space-nowrap">{{patient.phoneNumber | phone}}</td>
      </ng-container>

      <ng-container matColumnDef="gender">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="w-auto overflow-hidden text-overflow-ellipsis white-space-nowrap">Gender</th>
        <td mat-cell *matCellDef="let patient" class="w-auto overflow-hidden text-overflow-ellipsis white-space-nowrap">{{patient.gender}}</td>
      </ng-container>

      <ng-container matColumnDef="dateOfBirth">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="w-auto overflow-hidden text-overflow-ellipsis white-space-nowrap">Date of Birth</th>
        <td mat-cell *matCellDef="let patient" class="w-auto overflow-hidden text-overflow-ellipsis white-space-nowrap">{{patient.dateOfBirth | date}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr 
        mat-row
        (click)="visitPatient(row)"
        *matRowDef="let row; columns: columnsToDisplay"
      ></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[100, 150, 200, 500]" showFirstLastButtons></mat-paginator>
  </div>
</section>
