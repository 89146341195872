import { RouterModule, Routes } from '@angular/router';
import { SubscriptionsComponent } from '../subscriptions/subscriptions.component';
import { LayoutComponent } from './layout.component';
import { PatientsListComponent } from '../patients-list/patients-list.component';
import { CPPComponent } from '../cpp/cpp.component';
import { MembersComponent } from '../members/members.component';
import { PatientTermsOfUseComponent } from '@insig-health/patient-terms-of-use/patient-terms-of-use.component';
import { IsProfileSavedGuard } from 'insig-app/guards/is-profile-saved/is-profile-saved.guard';
import { inject } from '@angular/core';
import { IsPatientProfileCompleteGuard } from 'insig-app/guards/is-patient-profile-complete/is-patient-profile-complete.guard';


const routes: Routes = [
  {
    path: 'app',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
      {
        path: 'admin',
        loadChildren: () =>
          import('../dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'notes',
        loadChildren: () =>
          import('../notes/notes.module').then((m) => m.NotesModule),
      },
      {
        path: 'network',
        loadChildren: () =>
          import('../network/network.module').then((m) => m.NetworkModule),
      },
      {
        path: 'profile',
        canActivate: [IsPatientProfileCompleteGuard],
        canDeactivate: [(): Promise<boolean> => inject(IsProfileSavedGuard).canDeactivate()],
        loadChildren: () =>
          import('../profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'scheduler',
        loadChildren: () =>
          import('../scheduler/scheduler.module').then(
            (m) => m.SchedulerModule
          ),
      },
      {
        path: 'help',
        loadChildren: () =>
          import('../help/help.module').then((m) => m.HelpModule),
      },
      {
        path: 'patient-terms-of-use',
        component: PatientTermsOfUseComponent,
      },
      {
        path: 'surveys',
        loadChildren: () =>
          import('../surveys/surveys.module').then((m) => m.SurveysModule),
      },
      { path: 'patients-list', component: PatientsListComponent },
      {
        path: 'user-manager',
        loadChildren: () =>
          import('../user-manager/user-manager.module').then(
            (m) => m.UserManagerModule
          ),
      },
      {
        path: 'virtual',
        loadChildren: () =>
          import('../virtual-care/virtual-care.module').then(
            (m) => m.VirtualCareModule
          ),
      },
      { path: 'cpp', component: CPPComponent },
      { path: 'members', component: MembersComponent },
      { path: 'subscriptions', component: SubscriptionsComponent },
      {
        path: 'employee-manager',
        loadChildren: () =>
          import('../employee-manager/employee-manager.module').then(
            (m) => m.EmployeeManagerModule
          ),
      },
      {
        path: 'quick-start',
        loadChildren: () =>
          import('../quick-start/quick-start.module').then(
            (m) => m.QuickStartModule
          ),
      },
    ],
  },
];

export const LayoutRoutingModule = RouterModule.forChild(routes);
