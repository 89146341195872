import { NgModule } from '@angular/core';

import { EChartsDirective } from './echarts.directive';
import { SlimScrollDirective } from './slim-scroll.directive';

@NgModule({
  imports: [],
  declarations: [EChartsDirective, SlimScrollDirective],
  exports: [EChartsDirective, SlimScrollDirective],
})
export class SharedModule {}
