// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { SpringNote } from 'insig-types/spring-api/notes';

export interface InProgressSurveyIdentifiers {
  /* The uid of the doctor that the survey will be submitted to */
  doctorId: string;
  /* The id of the survey being completed */
  surveyId: string;
}

@Injectable({
  providedIn: 'root',
})
export class InProgressSurveyService {

  public static readonly SURVEY_LOCAL_STORAGE_KEY = 'inProgressSurvey';
  public static readonly CURRENT_PAGE_LOCAL_STORAGE_KEY = 'inProgressSurveyCurrentPage';
  public static readonly DOCTOR_ID_LOCAL_STORAGE_KEY = 'inProgressSurveyDoctorId';
  public static readonly SURVEY_ID_LOCAL_STORAGE_KEY = 'inProgressSurveySurveyId';

  saveInProgressSurvey(identifiers: InProgressSurveyIdentifiers, note: SpringNote, currentPage: number): void {
    const { doctorId, surveyId } = identifiers;
    const setItem = global.localStorage.setItem.bind(global.localStorage);
    setItem(InProgressSurveyService.DOCTOR_ID_LOCAL_STORAGE_KEY, doctorId);
    setItem(InProgressSurveyService.SURVEY_ID_LOCAL_STORAGE_KEY, surveyId);
    setItem(InProgressSurveyService.SURVEY_LOCAL_STORAGE_KEY, JSON.stringify(note));
    setItem(InProgressSurveyService.CURRENT_PAGE_LOCAL_STORAGE_KEY, (currentPage ?? 0).toString(10));
  }

  loadInProgressSurvey(identifiers: InProgressSurveyIdentifiers): [SpringNote, number] {
    const getItem = global.localStorage.getItem.bind(global.localStorage);
    const doctorId = getItem(InProgressSurveyService.DOCTOR_ID_LOCAL_STORAGE_KEY);
    const surveyId = getItem(InProgressSurveyService.SURVEY_ID_LOCAL_STORAGE_KEY);

    if (doctorId === identifiers.doctorId && surveyId === identifiers.surveyId) {
      const inProgressSurveyString = getItem(InProgressSurveyService.SURVEY_LOCAL_STORAGE_KEY);
      const currentPageString = getItem(InProgressSurveyService.CURRENT_PAGE_LOCAL_STORAGE_KEY);

      if (inProgressSurveyString && currentPageString) {
        const inProgressSurvey = JSON.parse(inProgressSurveyString);
        const currentPage = parseInt(currentPageString, 10);
        return [inProgressSurvey, currentPage];
      } else {
        return [null, null];
      }
    } else {
      return [null, null];
    }
  }

  removeInProgressSurvey(): void {
    const removeItem = global.localStorage.removeItem.bind(global.localStorage);
    removeItem(InProgressSurveyService.DOCTOR_ID_LOCAL_STORAGE_KEY);
    removeItem(InProgressSurveyService.SURVEY_ID_LOCAL_STORAGE_KEY);
    removeItem(InProgressSurveyService.SURVEY_LOCAL_STORAGE_KEY);
    removeItem(InProgressSurveyService.CURRENT_PAGE_LOCAL_STORAGE_KEY);
  }

}
