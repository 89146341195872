// @ts-strict-ignore
/// <reference types="@types/googlemaps" />

import { Component, ViewChild, ElementRef, AfterViewInit, inject } from '@angular/core';
import { GooglePlaceService } from '@insig-health/services/google-place/google-place.service';

@Component({
  selector: 'address-edit',
  templateUrl: './address-edit.component.html',
})
export class AddressEditComponent implements AfterViewInit {
  private readonly googlePlaceService = inject(GooglePlaceService);
  @ViewChild('searchAddress')
  public addressElementRef: ElementRef;

  ngAfterViewInit() {
    // load Places Autocomplete
    this.googlePlaceService.getPlaceResultObservable(this.addressElementRef.nativeElement).subscribe((placeResult) => {
      this.addressElementRef.nativeElement.value = placeResult['formatted_address'];
    });
  }
}
