import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'insig-ui-dialog',
  templateUrl: './insig-dialog.component.html',
})
export class InsigDialogComponent {
  private readonly dialogRef = inject<MatDialogRef<InsigDialogComponent, void>>(MatDialogRef<InsigDialogComponent, void>);

  handleCloseClicked(): void {
    this.dialogRef.close();
  }
}
