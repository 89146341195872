<!-- begin active view -->
<ng-container *ngIf="isActive">
  <!-- begin offeredQuestions -->
  <div class="overflow-x-auto">
    <ng-container
      *ngFor="let answer of question.offeredQuestions; let i = index"
    >
      <!-- NOTE reactive template activates at lg-down -->

      <div
        class="d-flex flex-row flex-wrap flex-xl-no-wrap justify-content-center align-items-center"
      >
        <!-- begin reactive first row left -->
        <span
          class="flex d-flex flex-row justify-content-center align-items-center"
        >
          <button
            mat-mini-fab
            color="none"
            insTooltip="Move Up"
            insTooltipPosition="left"
            [disabled]="i === 0"
            (click)="moveUp(i)"
          >
            <mat-icon class="notranslate">arrow_drop_up</mat-icon>
          </button>

          <button
            mat-mini-fab
            color="none"
            insTooltip="Move Down"
            insTooltipPosition="right"
            [disabled]="i === question.offeredQuestions.length - 1"
            (click)="moveDown(i)"
          >
            <mat-icon class="notranslate">arrow_drop_down</mat-icon>
          </button>

          <div class="space"></div>

          <mat-form-field class="full-width">
            <mat-label>Question</mat-label>
            <input
              #answerText
              required
              matInput
              [ngModel]="answer.text"
              (ngModelChange)="onAnswerChange(i, $event)"
            />
          </mat-form-field>
        </span>
        <!-- end reactive first row left -->

        <!-- begin reactive first row right -->
        <span
          class="order-xl-3 d-flex flex-row justify-content-center align-items-center"
        >
          <div class="space"></div>

          <button
            mat-mini-fab
            color="none"
            insTooltip="Remove"
            insTooltipPosition="right"
            (click)="question.offeredQuestions.splice(i, 1)"
          >
            <mat-icon class="notranslate">clear</mat-icon>
          </button>
        </span>
        <!-- end reactive first row right -->

        <div class="space hidden-lg-down"></div>

        <div class="w-100 hidden-xl-up">
          <!-- line break -->
        </div>

        <!-- begin reactive second row -->
        <span class="d-flex flex-row justify-content-center align-items-center">
          <!-- begin left option -->

          <button
            mat-mini-fab
            color="none"
            insTooltip="Flag Answer"
            insTooltipPosition="above"
            [ngStyle]="{
              background: question.offeredQuestions[i].switched
                ? answer.yesFlag || 'White'
                : answer.noFlag || 'White'
            }"
            [insMenuTriggerFor]="
              question.offeredQuestions[i].switched ? colorMenuYes : colorMenuNo
            "
          >
            <mat-icon class="notranslate">flag</mat-icon>
          </button>

          <button
            mat-mini-fab
            (click)="
              openIntegrationsDialog(
                question.offeredQuestions[i],
                question.offeredQuestions[i].switched
              )
            "
            [color]="
              !!question.offeredQuestions[i].integrations &&
              question.offeredQuestions[i].integrations[
                question.offeredQuestions[i].switched ? 1 : 0
              ].integrationID
                ? 'accent'
                : 'none'
            "
          >
            <mat-icon class="notranslate">cloud_queue</mat-icon>
          </button>

          <button
            *ngIf="!!openTriggerDialog"
            mat-mini-fab
            insTooltip="Edit Triggers"
            insTooltipPosition="above"
            (click)="
              openTriggerDialog(
                question.offeredQuestions[i].id,
                question.offeredQuestions[i].switched ? 'Yes' : 'No'
              )
            "
            [color]="
              question.offeredQuestions[i].pageFlow &&
              question.offeredQuestions[i].pageFlow.trigger[
                question.offeredQuestions[i].switched ? 'Yes' : 'No'
              ] &&
              question.offeredQuestions[i].pageFlow.trigger[
                question.offeredQuestions[i].switched ? 'Yes' : 'No'
              ].length > 0
                ? 'accent'
                : 'none'
            "
          >
            <mat-icon class="notranslate">call_split</mat-icon>
          </button>

          <!-- end left option -->

          <div class="space"></div>

          <button
            mat-raised-button
            insTooltip="Swap Yes/No Positions"
            insTooltipPosition="above"
            (click)="
              question.offeredQuestions[i].switched = !question
                .offeredQuestions[i].switched
            "
          >
            {{ question.offeredQuestions[i].switched ? 'Yes/No' : 'No/Yes' }}
          </button>

          <div class="space"></div>

          <!-- begin right option -->

          <button
            *ngIf="!!openTriggerDialog"
            mat-mini-fab
            insTooltip="Edit Triggers"
            insTooltipPosition="above"
            (click)="
              openTriggerDialog(
                question.offeredQuestions[i].id,
                question.offeredQuestions[i].switched ? 'No' : 'Yes'
              )
            "
            [color]="
              question.offeredQuestions[i].pageFlow &&
              question.offeredQuestions[i].pageFlow.trigger[
                question.offeredQuestions[i].switched ? 'No' : 'Yes'
              ] &&
              question.offeredQuestions[i].pageFlow.trigger[
                question.offeredQuestions[i].switched ? 'No' : 'Yes'
              ].length > 0
                ? 'accent'
                : 'none'
            "
          >
            <mat-icon class="notranslate">call_split</mat-icon>
          </button>

          <button
            mat-mini-fab
            (click)="
              openIntegrationsDialog(
                question.offeredQuestions[i],
                !question.offeredQuestions[i].switched
              )
            "
            [color]="
              !!question.offeredQuestions[i].integrations &&
              question.offeredQuestions[i].integrations[
                question.offeredQuestions[i].switched ? 0 : 1
              ].integrationID
                ? 'accent'
                : 'none'
            "
          >
            <mat-icon class="notranslate">cloud_queue</mat-icon>
          </button>

          <button
            mat-mini-fab
            color="none"
            insTooltip="Flag Answer"
            insTooltipPosition="above"
            [ngStyle]="{
              background: question.offeredQuestions[i].switched
                ? answer.noFlag || 'White'
                : answer.yesFlag || 'White'
            }"
            [insMenuTriggerFor]="
              question.offeredQuestions[i].switched ? colorMenuNo : colorMenuYes
            "
          >
            <mat-icon class="notranslate">flag</mat-icon>
          </button>

          <!-- end right option -->
        </span>
        <!-- end reactive second row -->
      </div>

      <hr class="hidden-xl-up" />

      <!-- begin menus -->
      <!-- This must be inside the *ngFor because it refers to 'answer' -->
      <ins-menu #colorMenuYes="insMenu">
        <div mat-menu-item (click)="answer.yesFlag = false">
          <button mat-raised-button>
            <mat-icon class="notranslate">clear</mat-icon>
          </button>
        </div>
        <div
          *ngFor="let color of flagColors"
          mat-menu-item
          (click)="answer.yesFlag = color.color"
        >
          <button [ngStyle]="{ background: color.color }" mat-raised-button>
            <mat-icon class="notranslate">flag</mat-icon>
          </button>
        </div>
      </ins-menu>
      <ins-menu #colorMenuNo="insMenu">
        <div mat-menu-item (click)="answer.noFlag = false">
          <button mat-raised-button>
            <mat-icon class="notranslate">clear</mat-icon>
          </button>
        </div>
        <div
          *ngFor="let color of flagColors"
          mat-menu-item
          (click)="answer.noFlag = color.color"
        >
          <button [ngStyle]="{ background: color.color }" mat-raised-button>
            <mat-icon class="notranslate">flag</mat-icon>
          </button>
        </div>
      </ins-menu>
      <!-- end menus -->
    </ng-container>
  </div>
  <!-- end offeredQuestions -->

  <div class="row">
    <div class="col-4"></div>
    <div class="col-4">
      <!-- begin add option -->
      <div class="w-100 d-flex flex-row justify-content-center">
        <button
          mat-raised-button
          (click)="addOption(question.offeredQuestions.length)"
        >
          Add Question
        </button>
      </div>
      <!-- end add option -->
    </div>
    <div class="col-4">
      <!-- begin grid switch -->
      <div class="w-100 d-flex flex-row justify-content-center">
        <div class="d-flex justify-content-center align-items-center">
          <span>Grid &nbsp;</span>
          <mat-slide-toggle [(ngModel)]="question.sentence"></mat-slide-toggle>
          <span>&nbsp; Sentence</span>
        </div>
      </div>
      <!-- end grid switch -->
    </div>
  </div>
</ng-container>
<!-- end active view -->

<!-- begin inactive view -->
<div *ngIf="!isActive">
  <mat-list>
    <!-- begin offeredQuestions -->
    <div
      *ngFor="let offeredQuestion of question.offeredQuestions; let i = index"
      class="row"
    >
      <div class="col-12">
        <h5>{{ offeredQuestion.text }}</h5>
        <div class="row" *ngIf="!offeredQuestion.switched">
          <div class="col-6">
            <button mat-raised-button class="btn-w-md float-right">No</button>
          </div>
          <div class="col-6">
            <button mat-raised-button class="btn-w-md float-left">Yes</button>
          </div>
        </div>
        <div class="row" *ngIf="offeredQuestion.switched">
          <div class="col-6">
            <button mat-raised-button class="btn-w-md float-right">Yes</button>
          </div>
          <div class="col-6">
            <button mat-raised-button class="btn-w-md float-left">No</button>
          </div>
        </div>
        <hr class="hidden-sm-up" />
        <div class="row hidden-xs-down">
          <div class="col-6 offset-3">
            <hr />
          </div>
        </div>
      </div>
    </div>
    <!-- end offeredQuestions -->
  </mat-list>
</div>
<!-- end inactive view -->
