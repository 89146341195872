import { Component, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SaveSurveyService } from 'insig-app/services/saveSurvey.service';

interface ConfirmLeaveEditDialogData {
  userId: string;
  surveyId: string;
  survey: any;
}

@Component({
  selector: 'confirm-leave-edit-dialog',
  templateUrl: './confirm-leave-edit-dialog.component.html',
  providers: [SaveSurveyService],
})
export class ConfirmLeaveEditDialogComponent {
  public readonly data = inject<ConfirmLeaveEditDialogData>(MAT_DIALOG_DATA);
  public readonly dialogRef = inject<MatDialogRef<ConfirmLeaveEditDialogComponent, boolean>>(MatDialogRef<ConfirmLeaveEditDialogComponent, boolean>);
  private readonly saveSurveyService = inject(SaveSurveyService);

  async saveAndLeave() {
    await this.saveSurveyService.saveUserSurveyToFirestore(
      this.data.survey,
      this.data.userId
    );
    this.dialogRef.close(true);
  }
}
