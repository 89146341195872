// @ts-strict-ignore
import { Component, OnInit, inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'yearmonthday-edit',
  templateUrl: './yearmonthday-edit.component.html',
})
export class YearMonthDayEditComponent implements OnInit {
  private readonly formBuilder = inject(UntypedFormBuilder);
  question = {
    response: {
      answer: {
        day: '',
        month: '',
        year: '',
      },
    },
  };
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  yearMonthDayForm: UntypedFormGroup;

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.yearMonthDayForm = this.formBuilder.group({
      year: this.formBuilder.control(null),
      month: this.formBuilder.control(null, [
        Validators.min(1),
        Validators.max(12),
      ]),
      day: this.formBuilder.control(null, [
        Validators.min(1),
        Validators.max(31),
      ]),
    });

    this.yearMonthDayForm.valueChanges.subscribe((data) => {
      this.question.response.answer.year = data.year;
      this.question.response.answer.month = data.month;
      this.question.response.answer.day = data.day;
    });
  }
} // end component
