<section class="h-100 overflow-y-auto container-fluid user-manager">
  <!-- <mat-card class="m-4"> -->

  <div class="row mt-5">
    <div class="col-12">
      <mat-form-field class="full-width mat-icon-left">
        <mat-icon matPrefix class="notranslate">search</mat-icon>
        <mat-label>Patient Search</mat-label>
        <input matInput #patientSearch />
      </mat-form-field>
    </div>
  </div>

  <div class="text-center mb-4">
    <button
      *ngIf="searchMode"
      mat-raised-button
      (click)="loadPatients(); patientSearch.value = null"
    >
      Clear Search
    </button>
    <span *ngIf="searchMode" class="space"></span>
    <button
      mat-raised-button
      color="primary"
      (click)="searchPatients(patientSearch.value)"
    >
      Search
    </button>
  </div>

  <div class="m-4 mat-elevation-z8">
    <div *ngIf="!!patientAdminLoading" class="full-width full-height">
      <insig-app-loader class="mx-auto my-auto"></insig-app-loader>
    </div>

    <table
      mat-table
      [dataSource]="dataSource"
      multiTemplateDataRows
      [hidden]="!!patientAdminLoading || !!searchMode"
    >
      <ng-container
        matColumnDef="{{ column }}"
        *ngFor="let column of columnsToDisplay"
      >
        <th mat-header-cell *matHeaderCellDef>
          {{ columnTitles[column].title }}
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngStyle]="{
            'font-weight': !!columnTitles[column].bold ? 'bold' : 'initial'
          }"
        >
          {{ element[column] }}
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <div matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="columnsToDisplay.length"
        >
          <div
            class="example-element-detail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <div class="row flex">
              <div class="col-3">
                <mat-icon class="notranslate">info</mat-icon>
                <span>
                  &nbsp;{{ element.label || element.appointmentType }}
                </span>
              </div>
              <div class="col-3">
                <mat-icon class="notranslate">phone</mat-icon>
                &nbsp;<a class="d-inline initialColor p-0">
                  {{ element.phone | phone }}
                </a>
              </div>
              <div class="col-2">
                <mat-icon class="notranslate">cake</mat-icon>
                <span>
                  &nbsp;{{ element.day }}/{{ element.month }}/{{ element.year }}
                </span>
              </div>
              <div class="col-4">
                <mat-icon class="notranslate">home</mat-icon>
                <span>
                  &nbsp;{{ element.address
                  }}{{ element?.city ? ', ' + element?.city : '' }}
                  <span *ngIf="userData?.province"
                    >, {{ element?.province }}</span
                  >
                </span>
              </div>

              <div class="col-4 mb-4 text-center h-100 ">
                <div class="my-auto">
                  <button
                    mat-raised-button
                    *ngIf="!element.approved"
                    (click)="approvalStatusChange(element)"
                    class="mt-3"
                  >
                    Approve
                    <mat-icon class="notranslate">check_circle</mat-icon>
                  </button>
                  <button
                    mat-raised-button
                    *ngIf="!!element.approved"
                    (click)="approvalStatusChange(element)"
                    class="mt-3"
                  >
                    Disapprove
                    <mat-icon class="notranslate">cancel</mat-icon>
                  </button>
                </div>
              </div>
              <div class="col-4 mb-4 text-center h-100 ">
                <div class="my-auto">
                  <button
                    mat-raised-button
                    (click)="openNotesPatient(element)"
                    class="mt-3"
                  >
                    View Notes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </td>
      </div>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: columnsToDisplay"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"
      ></tr>
    </table>

    <table
      mat-table
      [dataSource]="dataSourceSearched"
      multiTemplateDataRows
      [hidden]="!!patientAdminLoading || !searchMode"
    >
      <ng-container
        matColumnDef="{{ column }}"
        *ngFor="let column of columnsToDisplay"
      >
        <th mat-header-cell *matHeaderCellDef>
          {{ columnTitles[column].title }}
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngStyle]="{
            'font-weight': !!columnTitles[column].bold ? 'bold' : 'initial'
          }"
        >
          {{ element[column] }}
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <div matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="columnsToDisplay.length"
        >
          <div
            class="example-element-detail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <div class="row flex">
              <div class="col-3">
                <mat-icon class="notranslate">info</mat-icon>
                <span>
                  &nbsp;{{ element.label || element.appointmentType }}
                </span>
              </div>
              <div class="col-3">
                <mat-icon class="notranslate">phone</mat-icon>
                &nbsp;<a class="d-inline initialColor p-0">
                  {{ element.phone | phone }}
                </a>
              </div>
              <div class="col-2">
                <mat-icon class="notranslate">cake</mat-icon>
                <span>
                  &nbsp;{{ element.day }}/{{ element.month }}/{{ element.year }}
                </span>
              </div>
              <div class="col-4">
                <mat-icon class="notranslate">home</mat-icon>
                <span>
                  &nbsp;{{ element.address
                  }}{{ element?.city ? ', ' + element?.city : '' }}
                  <span *ngIf="userData?.province"
                    >, {{ element?.province }}</span
                  >
                </span>
              </div>

              <div class="col-4 mb-4 text-center h-100 ">
                <div class="my-auto">
                  <button
                    mat-raised-button
                    *ngIf="!element.approved"
                    (click)="approvalStatusChange(element)"
                    class="mt-3"
                  >
                    Approve
                    <mat-icon class="notranslate">check_circle</mat-icon>
                  </button>
                  <button
                    mat-raised-button
                    *ngIf="!!element.approved"
                    (click)="approvalStatusChange(element)"
                    class="mt-3"
                  >
                    Disapprove
                    <mat-icon class="notranslate">cancel</mat-icon>
                  </button>
                </div>
              </div>
              <div class="col-4 mb-4 text-center h-100 ">
                <div class="my-auto">
                  <button
                    mat-raised-button
                    (click)="openNotesPatient(element)"
                    class="mt-3"
                  >
                    View Notes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </td>
      </div>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: columnsToDisplay"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"
      ></tr>
    </table>

    <mat-paginator
      [pageSize]="15"
      [pageSizeOptions]="[15, 30]"
      showFirstLastButtons
      (page)="paginatorChange($event)"
    ></mat-paginator>
  </div>

  <!-- </mat-card> -->
</section>

<!-- <section class="h-100 overflow-y-auto container-fluid user-manager">
  <mat-card class="m-4">
    <mat-list>
      <mat-list-item>
        <mat-grid-list cols="11" rowHeight="60px">

          <mat-grid-tile [colspan]="2">
            <div class="tile-contents-centered">
              <p>Patient Name</p>
            </div>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="2">
            <div class="tile-contents-centered">
              <p>Phone Number</p>
            </div>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="2">
            <div class="tile-contents-centered">
              <p>Practitioner</p>
            </div>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="1">
            <div class="tile-contents-centered">
              <p>Documents Sent</p>
            </div>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="2">
            <div class="tile-contents-centered">
              <p>Notes</p>
            </div>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="2">
            <div class="tile-contents-centered">
              <p>Report Issue</p>
            </div>
          </mat-grid-tile>

        </mat-grid-list>
      </mat-list-item>

      <mat-divider></mat-divider>
      <div *ngFor="let appt of patientAppointments; let i = index" >
        <mat-list-item [attr.id]="'User'+i"  >

          <mat-grid-list cols="11" rowHeight="60px">

            <mat-grid-tile [colspan]="2">
              <div class="tile-contents-centered">
                <p class="text-ellipsis"><b>
                  {{!!appt.familyID ? appt.familyData.data.first : userData.first}}&nbsp;{{!!appt.familyID ? appt.familyData.data.last : userData.last}}
                </b></p>
              </div>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
                <div class="tile-contents-centered">
                  <p class="text-ellipsis">
                    {{!!appt.familyID ? appt.familyData.data.phone : userData.phone}}
                  </p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
                <div class="tile-contents-centered">
                  <p class="text-ellipsis">
                    {{!!appt.doctor && !!appt.doctor.title ? appt.doctor.title : '' }}
                    {{!!appt.doctor ? appt.doctor.first : '' }} {{!!appt.doctor ? appt.doctor.last : '' }}
                  </p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1">
                <div class="tile-contents-centered">

                  <button *ngIf="!appt.documentSent" mat-icon-button >
                    <mat-icon matTooltip="Document Completed But Not Sent" matTooltipPosition="above"
                      class="notranslate">timelapse</mat-icon>
                  </button>

                  <button *ngIf="!!appt.documentSent" mat-icon-button >
                    <mat-icon matTooltip="Document Sent" matTooltipPosition="above"
                      class="notranslate">check_circle</mat-icon>
                  </button>

                </div>
            </mat-grid-tile>


            <mat-grid-tile [colspan]="2">
              <div class="tile-contents-centered">
                <span >
                  <button mat-button (click)="openNotesPatient(appt)">View Notes</button>
                </span>
              </div>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
              <div class="tile-contents-centered">
                <span >
                  <button mat-button (click)="openContactBottomSheet(appt)"> Report Issue</button>
                </span>
              </div>
            </mat-grid-tile>


          </mat-grid-list>
        </mat-list-item>
      </div>
    </mat-list>
  </mat-card>
</section> -->
