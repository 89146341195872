<h1 matDialogTitle>We've updated our terms and conditions</h1>

<div mat-dialog-content>
  <div class="px-2">
    <insig-health-patient-terms-of-use></insig-health-patient-terms-of-use>
  </div>
</div>

<hr>

<div class="d-flex justify-content-center my-2">
  <insig-ui-button [mat-dialog-close]="true" stylePreset="primary">
    I acknowledge
  </insig-ui-button>
</div>
