import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageUploadService {
  private readonly httpClient = inject(HttpClient);

  public async getUploadedImageAsBase64(imageUrl: string): Promise<string> {
    const imageBlob = await lastValueFrom(this.httpClient.get(imageUrl, { responseType: 'blob' }));
    const imageBytes = new Uint8Array(await imageBlob.arrayBuffer());
    return btoa(imageBytes.reduce((base64String, byte) => base64String + String.fromCharCode(byte), ''));
  }
}
