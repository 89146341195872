import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'confirm-user-group-subscribed-delete',
  templateUrl: './confirm-user-group-subscribed-delete.component.html',
})
export class ConfirmUserGroupSubscribedDeleteDialogComponent {
  public readonly dialogRef = inject<MatDialogRef<
          ConfirmUserGroupSubscribedDeleteDialogComponent
        >>(MatDialogRef<
          ConfirmUserGroupSubscribedDeleteDialogComponent
        >);
}
