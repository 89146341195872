<div [hidden]="question.response.answer">
  <button
    *ngIf="scan"
    class="mt-3"
    mat-raised-button
    color="primary"
    (click)="takePhoto()"
  >
    Scan Now
  </button>

  <div #container class="d-flex justify-content-center align-items-center">
    <mat-spinner *ngIf="isVideoLoading"></mat-spinner>
    <video
      #video
      autoplay
      muted
      playsinline
      width="0"
    ></video>
  </div>
  <div class="divider"></div>

  <div class="d-flex flex-column flex-sm-row gap-4 justify-content-center align-items-center" [hidden]="scan">
    <ngx-file-drop
      (onFileDrop)="onAdded($event)"
      multiple="false"
      accept=".png,.jpg,.jpeg"
      dropZoneClassName="ngxFileDropZone"
      contentClassName="ngxFileDropContent"
    >
      <ng-template
        ngx-file-drop-content-tmp
        let-openFileSelector="openFileSelector"
      >
        <span>Upload Image</span>
        <span class="px-3"><!-- divider --></span>
        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="openFileSelector()"
        >
          Browse
        </button>
      </ng-template>
    </ngx-file-drop>

    <button mat-raised-button color="primary" (click)="takePhoto()">
      Take Photo
    </button>
  </div>
</div>

<div *ngIf="question.response.answer">
  <img [src]="question.response.answer" style="max-width:100%;" />
  <div class="divider"></div>
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-2 col-xs-6 col-sm-6 col-6">
      <ngx-file-drop
        (onFileDrop)="onAdded($event)"
        multiple="false"
        accept=".png,.jpg,.jpeg"
        dropZoneClassName="ngxFileDropZone"
        contentClassName="ngxFileDropContent"
      >
        <ng-template
          ngx-file-drop-content-tmp
          let-openFileSelector="openFileSelector"
        >
          <span>Upload Image</span>
          <span class="px-3"><!-- divider --></span>
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="openFileSelector()"
          >
            Browse
          </button>
        </ng-template>
      </ngx-file-drop>
    </div>
    <div class="col-1"></div>
    <div class="col-md-3 col-xs-5 col-sm-5 col-5 pt-2">
      <button mat-raised-button color="primary" (click)="retakePhoto()">
        Retake Photo
      </button>
    </div>
  </div>
</div>

<canvas
  [hidden]="true"
  #canvas
></canvas>

<p *ngIf="permissionDenied">
  <br /><br />You have blocked Insig from accessing your camera
</p>
