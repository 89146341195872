import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsigButtonComponent } from './insig-button/insig-button.component';
import { InsigCardComponent } from './insig-card/insig-card.component';
import { InsigContainerComponent } from './insig-container/insig-container.component';



@NgModule({
  declarations: [
    InsigButtonComponent,
    InsigCardComponent,
    InsigContainerComponent,
  ],
  exports: [
    InsigButtonComponent,
    InsigCardComponent,
    InsigContainerComponent,
  ],
  imports: [
    CommonModule
  ]
})
export class InsigUIModule { }
