import { Routes, RouterModule } from '@angular/router';

import { AuthComponent } from './auth.component';

import { PageLoginComponent } from './page-login/login.component';
import { PageSignUpComponent } from './sign-up/sign-up.component';

import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { Page404Component } from './404/404.component';
import { NotValidatedComponent } from './not-validated/not-validated.component';
import { Page500Component } from './500/500.component';
import { PageConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { PageMaintenanceComponent } from './maintenance/maintenance.component';
import { PatientDashboardComingComponent } from './patient-dashboard-coming/patient-dashboard-coming.component';
import { RedirectComponent } from './redirect/redirect.component';
import { DeactivateGuardComponent } from './deactivate-guard/deactivate-guard.component';
import { Page403Component } from './403/403.component';
import { PatientSignUpPageComponent } from './patient-sign-up-page/patient-sign-up-page.component';
import { TaliLoginComponent } from './tali-login/tali-login.component';

export const AuthRoutes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
      { path: 'login', component: PageLoginComponent },
      { path: 'login/:cid', component: PageLoginComponent },
      { path: 'sign-up', component: PageSignUpComponent },
      { path: 'sign-up/:networkID', component: PageSignUpComponent },

      { path: 'patient-login-sign-up/:cid', component: PatientSignUpPageComponent },
      { path: 'patient-login-sign-up', component: PatientSignUpPageComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: '403', component: Page403Component },
      { path: '404', component: Page404Component },
      { path: 'not-validated', component: NotValidatedComponent },
      { path: '500', component: Page500Component },
      { path: 'confirm-email', component: PageConfirmEmailComponent },
      { path: 'maintenance', component: PageMaintenanceComponent },
      {
        path: 'patient-dashboard-coming',
        component: PatientDashboardComingComponent,
      },
      { path: 'redirect', component: RedirectComponent },
      { path: 'deactivate-guard', component: DeactivateGuardComponent },
      { path: 'tali-login', component: TaliLoginComponent },
    ],
  },
];

export const AuthRoutingModule = RouterModule.forChild(AuthRoutes);
