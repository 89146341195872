// @ts-strict-ignore
import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AWSCLOUDFUNCTIONSENDPOINT } from '@insig-health/config/config';

@Injectable({
  providedIn: 'root',
})
export class SendEmailService {
  private readonly http = inject(HttpClient);
  private sendEmailURL = AWSCLOUDFUNCTIONSENDPOINT + 'communications/sendEmail';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  async sendTemplateEmail(
    emailTo,
    emailFrom,
    subject,
    message,
    templateID,
    substitutions
  ): Promise<any> {
    // TODO add strict typing. Remove integration LAMBDA
    return this.http
      .post(
        this.sendEmailURL,
        {
          email: emailTo,
          from: emailFrom,
          subject,
          body: message,
          templateID,
          substitutions,
        },
        { headers: this.headers }
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  sendEmail(emailTo, emailFrom, subject, message): any {
    return this.http
      .post(
        this.sendEmailURL,
        {
          email: emailTo,
          from: emailFrom,
          subject,
          body: message,
        },
        { headers: this.headers }
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
