// @ts-strict-ignore
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { MedicationsListService } from '../../../services/medications-list.service';

import { map, startWith, take } from 'rxjs/operators';

@Component({
  selector: 'medication-allergies-edit',
  templateUrl: './medication-allergies-edit.component.html',
  providers: [MedicationsListService],
})
export class MedicationAllergiesEditComponent implements OnInit {
  private readonly medicationsListService = inject(MedicationsListService);
  @ViewChild('itemsSelect') itemsSelect;
  itemsCtrl: UntypedFormControl;
  filteredItems: any;
  items = [];
  question = {
    response: {
      selectedAnswers: [],
      selectedCheckboxes: [],
    },
  };

  private allergyMedications = [
    'Penicillins',
    'Aspirin',
    'NSAIDs ex. Advil, Naproxen etc.',
    'Sulfa Drugs',
    'Codeine',
    'Cephalosporins (Ceclor, Cefzil, Ceftin etc.)',
    'Erythromycin/Biaxin/Zithromax',
    'Tetracyclines (Minocyline, Doxycyline, etc.)',
    'Ciprofloxacin',
    'Other Opioids (Demerol, Morphine, Percocet, etc.)',
  ];

  constructor() {
    this.itemsCtrl = new UntypedFormControl();
    this.filteredItems = this.itemsCtrl.valueChanges.pipe(
      startWith(null),
      map((name) => this.filterItems(name))
    );
    this.allergyMedications.forEach((medication) => {
      this.question.response.selectedCheckboxes.push({
        medication,
        selected: false,
      });
    });
  }

  async ngOnInit() {
    try {
      this.items = await this.medicationsListService
        .getMedications()
        .pipe(take(1))
        .toPromise();
    } catch (error) {
      console.error(error);
    }
  }

  filterItems(val: string) {
    return val
      ? this.items
          .filter((s) => s.toLowerCase().indexOf(val.toLowerCase()) === 0)
          .slice(0, 50)
      : this.items.slice(0, 50);
  }

  removeItem(val) {
    const index = this.question.response.selectedAnswers.indexOf(val);
    this.question.response.selectedAnswers.splice(index, 1);
  }

  addItem(val) {
    if (this.question.response.selectedAnswers.indexOf(val) === -1) {
      this.question.response.selectedAnswers.push(val);
    }
    this.itemsCtrl.setValue(null);

    console.log(this.question.response.selectedAnswers);
  } // end add item
}
