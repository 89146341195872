// @ts-strict-ignore
import { Component, OnInit, Input, ViewChild, ElementRef, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import firebase from 'firebase/compat/app';

import { DrawingEditDialogComponent } from './drawing-edit-dialog.component';
import { FileUploadService } from '../../../../services/fileUpload.service';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';

@Component({
  selector: 'drawing-edit',
  templateUrl: './drawing-edit.component.html',
  styleUrls: [
    './drawing-edit.style.css',
    '../../../../../styles/global/ng2-file-input.scss',
  ],
  providers: [FileUploadService],
})
export class DrawingEditComponent implements OnInit {
  public readonly dialog = inject(MatDialog);
  public readonly snackBar = inject(MatSnackBar);
  private readonly fileUploadService = inject(FileUploadService);
  @Input() question: any;
  /** The surveyID for saving the image path under. Optional but recommended for organizational purposes. */
  @Input() surveyID?: string;
  @Input() userID: string;
  @ViewChild('container') public container: ElementRef;
  private maxWidth = 500;
  public loading = false;
  public test = false;

  ngOnInit(): void {
    if (this.maxWidth > window.screen.width * 0.9) {
      this.maxWidth = window.screen.width * 0.75;
    }
  }

  startDrawing(): void {
    const dialogRef = this.dialog.open(DrawingEditDialogComponent);
    dialogRef.componentInstance.question = this.question;
    dialogRef.afterClosed().subscribe((data) => {
      this.maxWidth = this.container.nativeElement.offsetWidth;
      this.test = data;
    });
  } // end func

  async onAdded(fileDropEntries: NgxFileDropEntry[]): Promise<void> {
    this.loading = true;
    const fileDrop = fileDropEntries[0];
    const fileName = fileDrop.fileEntry.name;
    const fileData = await new Promise<File>((resolve) => {
      const fileEntry = fileDrop.fileEntry as FileSystemFileEntry;
      fileEntry.file((file) => {
        resolve(file);
      });
    });

    const extension = fileName.split('.').pop();

    const path = `user/surveys/${this.userID}/${this.surveyID ||
      this.generateRandomID(32)}/${this.question.id}${extension}`;
    const uploadTask = this.fileUploadService.uploadFile(path, fileData);
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        console.log(snapshot);
      },
      (error) => {
        console.log(error);
      },
      async () => {
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
        this.maxWidth = this.container.nativeElement.offsetWidth;
        this.question.images = downloadURL;
        this.test = false;
        this.loading = false;
      }
    );
  }

  /**
   * Generates a random alphanumeric string to use as an identifier
   * // TODO adstract this function and similar functions into a global service
   * @param length The length of the id to generate
   */
  generateRandomID(length: number): string {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
} // end component
