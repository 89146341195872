<div class="text-center">
  <h5>Have you had any surgeries?</h5>
  <div class="divider"></div>
  <div class="row">
    <div class="col-6">
      <button
        (click)="question.response.answer = 'No'"
        *ngIf="question.response.answer != 'No'"
        mat-raised-button
        class="btn-w-md float-right"
      >
        No
      </button>
      <button
        *ngIf="question.response.answer == 'No'"
        mat-raised-button
        class="btn-w-md float-right"
        color="primary"
      >
        No
      </button>
    </div>
    <div class="col-6">
      <button
        (click)="question.response.answer = 'Yes'"
        *ngIf="question.response.answer != 'Yes'"
        mat-raised-button
        class="btn-w-md float-left"
      >
        Yes
      </button>
      <button
        *ngIf="question.response.answer == 'Yes'"
        mat-raised-button
        class="btn-w-md float-left"
        color="primary"
      >
        Yes
      </button>
    </div>
  </div>
</div>

<div class="divider"></div>

<div [hidden]="!question.response.answer || question.response.answer == 'No'">
  <!-- <div class="row">
    <div class="col-sm-6 text-left">
      <ng-container *ngFor="let checkbox of question.response.selectedCheckboxes; let i = index;">
        <ng-container *ngIf="i < question.response.selectedCheckboxes.length / 2">
          <mat-checkbox [(ngModel)]="checkbox.selected">{{checkbox.surgery}}</mat-checkbox>
          <div class="divider"></div>
        </ng-container>
      </ng-container>
    </div>
    <div class="col-sm-6 text-left">
      <ng-container *ngFor="let checkbox of question.response.selectedCheckboxes; let i = index;">
        <ng-container *ngIf="i >= question.response.selectedCheckboxes.length / 2">
          <mat-checkbox [(ngModel)]="checkbox.selected">{{checkbox.surgery}}</mat-checkbox>
          <div class="divider"></div>
        </ng-container>
      </ng-container>
    </div>
  </div> -->

  <div class="divider"></div>

  <div class="form-group row">
    <div class="col-2"></div>
    <div class="col-8">
      <mat-chip-listbox class="text-center">
        <div
          *ngFor="let item of question.response.selectedAnswers; let i = index"
        >
          <div *ngIf="!(item === undefined)">
            <span class="space space-md"></span>
            <span color="primary">{{ item }} &nbsp;</span>
            <button
              mat-mini-fab
              color="none"
              (click)="removeItem(item)"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </div>
      </mat-chip-listbox>
    </div>
    <div class="col-2"></div>
  </div>

  <!-- <h6 class="text-center">Add surgeries below</h6> -->

  <div class="form-group row">
    <div class="col-2"></div>
    <div class="col-8">
      <mat-form-field class="full-width">
        <mat-label>Enter surgeries</mat-label>
        <input
          matInput
          (keyup.enter)="addItem(question.response.selectedAnswers[-1])"
          [(ngModel)]="question.response.selectedAnswers[-1]"
          [matAutocomplete]="auto"
        />
      </mat-form-field>
    </div>
    <div class="col-2"></div>
  </div>

  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      *ngIf="question.response.selectedAnswers[-1] != null"
      (click)="addItem(question.response.selectedAnswers[-1])"
      [value]="question.response.selectedAnswers[-1]"
    >
      <mat-icon>add_circle_outline</mat-icon
      >{{ question.response.selectedAnswers[-1] }}
    </mat-option>
  </mat-autocomplete>
</div>
