// @ts-strict-ignore
import { Injectable, inject } from '@angular/core';

import { Apollo, gql } from 'apollo-angular';

import { map, switchMap } from 'rxjs/operators';
import { APPURL } from '@insig-health/config/config';
import {
  SurveyDataInstances,
  SurveyDataInstancesResponses,
} from 'insig-types/survey-data/instances';
import { SurveyDataMetaData } from 'insig-types/survey-data/survey-metadata';
import {
  SurveyDataTable,
  SurveyDataTableItem,
  AllCompanySurveyData,
} from 'insig-types/survey-data/table';

import { Observable } from 'rxjs';

import firebase from 'firebase/compat/app';
import 'firebase/storage';
import { PatientProfile } from 'insig-types/user-data';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';

@Injectable({
  providedIn: 'root',
})
export class SurveyDataService {
  private readonly apollo = inject(Apollo);
  private readonly gcpIpAuthService = inject(GcpIpAuthService);
  // graphql queries
  private doctorListQuery = gql`
    query DoctorListQuery($companyID: ID!, $idToken: ID!) {
      getCompanyUserList(cid: $companyID, token: $idToken) {
        first
        last
        uid
        valid
        company
        acceptVirtual
      }
    }
  `;
  //

  initSurveyDataSave(survey): SurveyDataInstancesResponses[] {
    // console.log(survey)
    const responses = this.generateResponses(survey);

    const dataResponses = this.generateDataResponses(responses);

    return dataResponses;
  }

  // get responses for every question
  generateResponses(survey, append?) {
    const responses = [];

    survey.pages.forEach((page) => {
      if (!page.elements || !page.elements.length || page.elements.length < 1) {
        return false;
      }
      page.elements.forEach((element) => {
        let response;

        if (append && !element.doctor) {
          return false;
        }

        if (element.question) {
          response = this.getResponse(element.question, survey);
          // check if patient answered it
          if (
            response != null &&
            response !== false &&
            response !== undefined &&
            Array.isArray(response)
          ) {
            responses.concat(
              response.map((res) => ({
                question: {
                  ...element.question,
                  text: res.question,
                },
                note: element.note,
                answer: res.response,
              }))
            );
          } else if (
            response != null &&
            response !== false &&
            response !== undefined
          ) {
            responses.push({
              question: element.question,
              note: element.note,
              answer: response,
            });
          }

          if (element.question.liveName) {
            survey.surveyNames.push({
              name: element.question.liveName,
              id: element.question.survId,
              survUID: element.question.survUID,
              type: element.question.triggeredLibrary ? 'library' : 'user',
              triage: element.question.triage,
            });
          }
        } else if (element.paragraph) {
          if (
            element.note &&
            element.note.location &&
            element.paragraph.html &&
            !element.triggerHidden &&
            !element.demographicsHidden
          ) {
            response = element.paragraph.html;
            responses.push({
              paragraph: true,
              note: element.note,
              answer: response,
            });
          }
        }
        // add to pdfResponses
      });
    });
    return responses;
  } // end func

  getResponse(question, survey) {
    if (!question.response) {
      return false;
    }
    const questionType = question.type;
    if (
      questionType === 'text' ||
      questionType === 'textarea' ||
      questionType === 'phone' ||
      questionType === 'number' ||
      questionType === 'email' ||
      questionType === 'fiveStar' ||
      questionType === 'range' ||
      questionType === 'address'
    ) {
      return question.response.answer;
    } else if (questionType === 'yesno') {
      return this.getYesNoResponse(question, survey);
    } else if (questionType === 'multiyesno') {
      return this.getmultiYesNoResponse(question, survey);
    } else if (
      questionType === 'radio' ||
      questionType === 'scoring' ||
      questionType === 'select'
    ) {
      return this.getRadioResponse(question, survey);
    } else if (questionType === 'checkbox') {
      return this.getCheckboxResponse(question, survey);
    } else if (questionType === 'yearMonthDay') {
      return this.getYearMonthDayResponse(question.response.answer);
    } else if (questionType === 'medications') {
      return this.getMedicationsResponse(question);
    } else if (questionType === 'doctors') {
      return this.getDoctorResponse(question);
    } else if (questionType === 'autocomplete') {
      return this.getAutocompleteResponse(question);
    } else if (questionType === 'pdf') {
      return this.getPDFResponse(question);
    } else if (questionType === 'medicationAllergies') {
      return this.getMedicationAllergiesResponse(question);
    } else if (questionType === 'conditions') {
      return this.getConditionsResponse(question);
    } else if (questionType === 'bloodPressure') {
      return this.getBloodPressureResponse(question);
    } else if (questionType === 'surgery') {
      return this.getSurgeriesResponse(question);
    } else if (questionType === 'heightWeight') {
      return this.getheightWeightResponse(question);
    } else if (questionType === 'grid') {
      return this.getGridResponse(question);
    } else if (questionType === 'physicalGrid') {
      return this.getPhysicalGridResponse(question);
    } else if (questionType === 'drawing' || questionType === 'photograph') {
      return this.getImageResponse(question);
    } else if (questionType === 'signature') {
      return this.getSignatureResponse(question);
    } else if (questionType === 'shareNote') {
      return this.getShareNoteResponse(question);
    } else if (questionType === 'dateRange') {
      return this.getDateRangeResponse(question);
    } else if (questionType === 'date') {
      return this.getDateResponse(question);
    } else if (questionType === 'priority') {
      return this.getPriorityResponse(question);
    } else if (questionType === 'diagram') {
      return this.getDiagramResponse(question);
    } else if (questionType === 'time') {
      return this.getTimeResponse(question);
    } else {
      return false; // question.type + " question not setup yet";
    }
  } // end func

  getTimeResponse(question) {
    if (!question.response.answer) {
      return false;
    }
    if (question.timeType === 'dateTime') {
      return (
        new Date(question.response.answer).toLocaleDateString() +
        ' ' +
        new Date(question.response.answer).toLocaleTimeString()
      );
    }
    return question.response.answer;
  }

  getDiagramResponse(question) {
    if (question.response) {
      return 'Diagram Submitted';
    } else {
      return false;
    }
  }

  getBloodPressureResponse(question) {
    if (!question.response.rows || question.response.rows.length === 0) {
      return false;
    }
    let filledInRows = 0;
    let chart = '';
    for (const index of Object.keys(question.response.rows)) {
      if (
        question.response.rows[index].diastolic ||
        question.response.rows[index].systolic ||
        question.response.rows[index].bpm ||
        question.response.rows[index].so2
      ) {
        filledInRows += 1;
      } else {
        continue;
      }
      chart += `Systolic: ${
        question.response.rows[index].systolic
          ? question.response.rows[index].systolic
          : ''
      }\n
        Diastolic: ${
          question.response.rows[index].diastolic
            ? question.response.rows[index].diastolic
            : ''
        }\n
        BPM: ${
          question.response.rows[index].bpm
            ? question.response.rows[index].bpm
            : ''
        }\n
        SO2: ${
          question.response.rows[index].so2
            ? question.response.rows[index].so2
            : ''
        }\n
        Time: ${
          question.response.rows[index].time
            ? question.response.rows[index].time
            : ''
        }\n\n`;
    }
    if (filledInRows < 1) {
      return false;
    }
    return chart;
  }

  getAutocompleteResponse(question) {
    if (!question.response) {
      return false;
    }
    // check at least one entered med
    let atLeastOne = false;
    for (const item in question.response.selectedAnswers) {
      if (question.response.selectedAnswers[item]) {
        atLeastOne = true;
      }
    }
    if (!atLeastOne) {
      return false;
    }
    if (question.response.selectedAnswers[-1]) {
      question.response.selectedAnswers.push(
        question.response.selectedAnswers[-1]
      );
      question.response.selectedAnswers[-1] = null;
    }
    return question.response.selectedAnswers.join(', ');
  }

  getDoctorResponse(question) {
    if (!question.response) {
      return false;
    }
    // check at least one entered med
    let atLeastOne = false;
    for (const item in question.response.selectedAnswers) {
      if (question.response.selectedAnswers[item]) {
        atLeastOne = true;
      }
    }
    if (!atLeastOne) {
      return false;
    }
    if (question.response.selectedAnswers[-1]) {
      question.response.selectedAnswers.push(
        question.response.selectedAnswers[-1]
      );
      question.response.selectedAnswers[-1] = null;
    }
    return 'Dr. ' + question.response.selectedAnswers.join(', Dr. ');
  }

  getheightWeightResponse(question) {
    if (!question.response) {
      return false;
    }
    const response = question.response;
    let responseString = '';
    if (
      (response.feet && response.height) ||
      (response.height && response.metricHeight)
    ) {
      responseString += `Height: ${this.heightConversion(
        response.height,
        response.feet,
        response.metricHeight
      )}\n`;
      if (response.weight) {
        responseString += `Weight: ${this.weightConversion(
          response.weight,
          response.metricWeight
        )}`;
      }
      return responseString;
    } else if (response.weight) {
      return (responseString += `Weight: ${this.weightConversion(
        response.weight,
        response.metricWeight
      )}`);
    }
    return false;
  }

  weightConversion(weight, metricWeight) {
    if (metricWeight) {
      return Math.round(weight * 2.2) + ' lbs (' + weight + ' ' + 'kg)';
    }
    return weight + ' lbs (' + Math.round(weight / 2.2) + ' ' + 'kg)';
  }
  heightConversion(height, feet, metricHeight) {
    if (metricHeight) {
      const cmToInches = Math.round(height / 2.54);
      return (
        Math.floor(cmToInches / 12) +
        '\' ' +
        (cmToInches % 12) +
        '" ' +
        '(' +
        height +
        ' cm)'
      );
    }
    return (
      feet +
      '\' ' +
      height +
      '" ' +
      '(' +
      Math.round((height + feet * 12) * 2.54) +
      ' cm)'
    );
  }

  getMedicationsResponse(question) {
    if (!question.response) {
      return false;
    }
    // check at least one entered med
    let atLeastOne = false;
    for (const item in question.response.selectedAnswers) {
      if (question.response.selectedAnswers[item]) {
        atLeastOne = true;
      }
    }
    if (!atLeastOne && !question.response.noMeds) {
      return false;
    }
    if (question.response.noMeds && question.allowMultiple) {
      return 'no medications';
    } else if (question.response.noMeds) {
      return 'no medication';
    } else {
      if (
        !!question.response.selectedAnswers &&
        !!question.response.selectedAnswers[-1]
      ) {
        question.response.selectedAnswers.push(
          question.response.selectedAnswers[-1]
        );
        question.response.selectedAnswers[-1] = null;
      }
      return question.response.selectedAnswers.join(', ');
    }
  }

  getDateResponse(question) {
    if (
      question.response.answer &&
      question.response.answer !== 'Invalid Date'
    ) {
      const answer = new Date(question.response.answer).toDateString();
      if (answer === 'Invalid Date') {
        return false;
      }
      question.response.answer = answer;
      return answer;
    } else {
      return false;
    }
  }

  getConditionsResponse(question) {
    let response = '';
    if (!question.response || !question.response.answer) {
      return false;
    }
    if (question.response.answer === 'No') {
      return 'Patient reports having no known medical conditions';
    }
    const combinedArray = [];
    if (question.response.selectedCheckboxes) {
      question.response.selectedCheckboxes.forEach((item) => {
        if (item.selected === true) {
          combinedArray.push(item.condition);
        }
      });
    }

    if (question.response.selectedAnswers) {
      if (question.response.selectedAnswers[-1]) {
        question.response.selectedAnswers.push(
          question.response.selectedAnswers[-1]
        );
        question.response.selectedAnswers[-1] = null;
      }
      for (const item in question.response.selectedAnswers) {
        if (question.response.selectedAnswers[item]) {
          combinedArray.push(question.response.selectedAnswers[item]);
        }
      }
    }
    // check if empty, sometimes leaves [undefined]
    let empty = true;
    combinedArray.forEach((item) => {
      if (item) {
        empty = false;
      }
    });
    if (!empty) {
      response += ' including: ';
      if (combinedArray.length > 1 && combinedArray[combinedArray.length - 1]) {
        combinedArray[combinedArray.length - 1] =
          'and ' + combinedArray[combinedArray.length - 1];
      }
      response += combinedArray.join(', ');
    }
    return response;
  }

  getSurgeriesResponse(question) {
    let response = '';
    if (!question.response || !question.response.answer) {
      return false;
    }
    if (question.response.answer === 'No') {
      return 'Patient reports having no past surgeries';
    }

    const combinedArray = [];
    if (question.response.selectedCheckboxes) {
      question.response.selectedCheckboxes.forEach((item) => {
        if (item.selected === true) {
          combinedArray.push(item.surgery);
        }
      });
    }
    if (question.response.selectedAnswers) {
      if (question.response.selectedAnswers[-1]) {
        question.response.selectedAnswers.push(
          question.response.selectedAnswers[-1]
        );
        question.response.selectedAnswers[-1] = null;
      }
      for (const item in question.response.selectedAnswers) {
        if (question.response.selectedAnswers[item]) {
          combinedArray.push(question.response.selectedAnswers[item]);
        }
      }
    }
    // check if empty, sometimes leaves [undefined]
    let empty = true;
    combinedArray.forEach((item) => {
      if (item) {
        empty = false;
      }
    });
    if (!empty) {
      // response += ' including: ';
      if (combinedArray.length > 1 && combinedArray[combinedArray.length - 1]) {
        combinedArray[combinedArray.length - 1] =
          'and ' + combinedArray[combinedArray.length - 1];
      }
      response += combinedArray.join(', ');
    }
    // return response + ' &nbsp;</span>';
    return response;
  }

  getMedicationAllergiesResponse(question) {
    let response = '';
    if (!question.response || !question.response.answer) {
      return false;
    }
    if (question.response.answer === 'No') {
      return 'Patient reports having no known drug allergies';
    }

    const combinedArray = [];
    if (question.response.selectedCheckboxes) {
      question.response.selectedCheckboxes.forEach((item) => {
        if (item.selected === true) {
          combinedArray.push(item.medication);
        }
      });
    }
    if (question.response.selectedAnswers) {
      if (question.response.selectedAnswers[-1]) {
        question.response.selectedAnswers.push(
          question.response.selectedAnswers[-1]
        );
        question.response.selectedAnswers[-1] = null;
      }
      for (const item in question.response.selectedAnswers) {
        if (question.response.selectedAnswers[item]) {
          combinedArray.push(question.response.selectedAnswers[item]);
        }
      }
    }
    // check if empty, sometimes leaves [undefined]
    let empty = true;
    combinedArray.forEach((item) => {
      if (item) {
        empty = false;
      }
    });

    if (!empty) {
      if (combinedArray.length > 1 && combinedArray[combinedArray.length - 1]) {
        combinedArray[combinedArray.length - 1] =
          'and ' + combinedArray[combinedArray.length - 1];
      }
      response = combinedArray.join(', ');
    }
    return response;
  }

  getDateRangeResponse(question) {
    if (!question.response.answer.from && !question.response.answer.to) {
      return false;
    }
    const response =
      new Date(question.response.answer.from).toDateString() +
      ' to ' +
      new Date(question.response.answer.to).toDateString();
    return response.replace('Invalid Date', '_____');
  }

  getYesNoResponse(question, survey) {
    const answer = question.response.answer;
    if (answer === 'Yes') {
      this.addIntegrationResponse(question.offeredAnswers[1], survey);
    } else if (answer === 'No') {
      this.addIntegrationResponse(question.offeredAnswers[0], survey);
    }
    return answer;
  }

  getYearMonthDayResponse(answer) {
    if (!answer.day && !answer.year && !answer.month) {
      return false;
    }
    let response = '';
    // year and month
    if (!answer.day && answer.year && answer.month) {
      response += answer.month + ' / ' + answer.year;
      // only year
    } else if (!answer.day && answer.year && !answer.month) {
      response += answer.year;
      // only month
    } else if (!answer.day && !answer.year && answer.month) {
      response += answer.month;
      // month and day
    } else if (answer.day && !answer.year && answer.month) {
      response += answer.day + ' / ' + answer.month;
    } else {
      response += answer.day + ' / ' + answer.month + ' / ' + answer.year;
    }
    return response.replace(new RegExp('null|undefined', 'g'), '___');
  }

  getShareNoteResponse(question) {
    if (question.response.responseTerms || question.response.answer) {
      let consent = '';
      if (question.response.responseTerms) {
        consent = 'Terms & Conditions Accepted\n';
      } else if (!question.response.responseTerms) {
        consent = 'Terms & Conditions Not Accepted\n';
      }
      if (question.response.answer) {
        consent += 'Signature Submitted';
      }
      return consent;
    } else {
      return false;
    }
  }

  getSignatureResponse(question) {
    if (question.response.responseTerms || question.response.answer) {
      let consent = '';
      if (question.response.responseTerms) {
        consent = 'Terms & Conditions Accepted\n';
        // if (!question.hideTerms) {
        //   consent += '<br/>' + question.termsContent;
        // }
      } else if (!question.response.responseTerms && question.terms) {
        consent = 'Terms & Conditions Not Accepted\n';
      }
      if (question.response.answer) {
        consent += 'Signature Submitted';
      }
      return consent;
    } else {
      return false;
    }
  }

  getImageResponse(question) {
    if (question.response && question.response.answer) {
      // return "<p><img class='maxWidth100%' src='" + question.response.answer + "'></p>";
      return 'Image Submitted';
    } else {
      return false;
    }
  }

  getPDFResponse(question) {
    if (!question.response || !question.response[0]) {
      return false;
    }
    const images = 'PDF Submitted';
    // question.response.forEach((image) => {
    //   console.log(image);
    //   images += "<p><img class='maxWidth100%' src='" + (image) + "'></p>";
    // });
    return images;
  }

  // dave
  getPhysicalGridResponse(question) {
    //   chart.push({ question: question.offeredQuestions[index].text ? question.offeredQuestions[index].text : '',
    //   response: "Yes",
    // })
    let atLeastOneAnswer = false;

    const checkboxesArray = [];
    const final = [];
    const checkboxesString = '';
    for (const item in question.response.checkboxes) {
      if (question.response.checkboxes[item]) {
        checkboxesArray.push(item);
      }
    }
    if (question.response.otherCheckbox) {
      checkboxesArray.push(question.response.otherCheckbox);
    }
    if (checkboxesArray.length !== 0) {
      // checkboxesString = 'General findings:' + checkboxesArray.join(', ');
      final.concat([
        {
          question: 'Physical Exam General Findings',
          response: checkboxesArray.join(', '),
        },
      ]);
    }

    let bloodPressureString: any =
      this.getBloodPressureResponse(question) || '';
    if (bloodPressureString) {
      // bloodPressureString = '<p><b>Blood Pressure</b></p>' + bloodPressureString;
      bloodPressureString = [
        {
          question: 'Physical Exam Blood Pressure',
          response: bloodPressureString,
        },
      ];
    }

    const finalPhys = [];
    // finalPhys += '<thead><tr><td><b>Physical Grid</b></td><td style="text-align:center;"><label><b>Normal</b></label></td><td style="text-align:center;"><label><b>Abnormal</b></label></td><td style="text-align:center;" colspan="3"><label><b>Description</b></label></td></tr></thead><tbody>';
    for (const key of Object.keys(question.response.physicalGrid)) {
      const temp = {
        question: '',
        response: '',
      };
      // let temp = '<tr><td style="text-align:center;"><label><b>' + key + '</b></label></td><td></td><td></td><td></td></tr>';
      let atLeastOneRow = false;
      for (const part of Object.keys(question.response.physicalGrid[key])) {
        if (question.response.physicalGrid[key][part].options) {
          question.response.physicalGrid[key][part].selectedOptions = [];
          question.response.physicalGrid[key][part].options.forEach((item) => {
            if (item.selected) {
              question.response.physicalGrid[key][part].selectedOptions.push(
                item.title
              );
            }
          });
        }
        if (
          !question.response.physicalGrid[key][part].value &&
          !question.response.physicalGrid[key][part].desc &&
          (!question.response.physicalGrid[key][part].selectedOptions ||
            !question.response.physicalGrid[key][part].selectedOptions.length ||
            question.response.physicalGrid[key][part].selectedOptions.length <
              1)
        ) {
          continue;
        }
        temp.question = `${key} - ${part}`;
        // temp += '<tr><td style="padding-left:1em;"><label>' + part + '</label></td>';
        if (question.response.physicalGrid[key][part].value === 'normal') {
          temp.response = 'Normal';
          atLeastOneRow = true;
        } else if (
          question.response.physicalGrid[key][part].value === 'abnormal'
        ) {
          temp.response = 'Abnormal';
          atLeastOneRow = true;
        }

        // if (question.response.physicalGrid[key][part].selectedOptions
        //   && question.response.physicalGrid[key][part].selectedOptions.length
        //   && question.response.physicalGrid[key][part].selectedOptions.length > 0) {

        //   temp += '<span>' + question.response.physicalGrid[key][part].selectedOptions.join(', ') + '</span><br>';
        // }

        if (question.response.physicalGrid[key][part].desc) {
          temp.response += `\nDescription: ${question.response.physicalGrid[key][part].desc}`;
          atLeastOneRow = true;
        }
      }
      if (atLeastOneRow) {
        finalPhys.push(temp);
        atLeastOneAnswer = true;
      }
    } // end for
    if (atLeastOneAnswer) {
      // finalPhys += '</tbody></table>';
      return final.concat(checkboxesString, bloodPressureString, finalPhys);
    } else if (checkboxesString || bloodPressureString) {
      return checkboxesString + bloodPressureString;
    } else {
      return false;
    }
  } // end func

  getGridResponse(question) {
    const finalChart = [];
    const inputType = question.grid.cellInputType;
    let colInputType: any;
    let atLeastOneAnswerRow: any;
    let colInputIndep = false;
    let atLeastOneAnswer = false;

    for (const row in question.grid.rows) {
      if (question.response[row] && question.response[row].col) {
        atLeastOneAnswer = true;
        break;
      }
    }
    if (!atLeastOneAnswer) {
      return false;
    }
    atLeastOneAnswer = false;
    // header
    const colLabels = {};
    const rowLabels = {};

    for (const col1 of Object.keys(question.grid.cols)) {
      const tempCol1 = question.grid.cols[col1].label;
      colLabels[question.grid.cols[col1].id] = tempCol1;
    }
    for (const c in question.grid.cols) {
      if (question.grid.cols[c].colInputType) {
        colInputIndep = true;
        break;
      }
    }
    for (const row in question.grid.rows) {
      if (!question.response[row].col) {
        continue;
      }
      atLeastOneAnswerRow = 0;
      const tempRow = question.grid.rows[row].label;
      const rowId = question.grid.rows[row].id;
      if (!question.allowMultiple) {
        rowLabels[row] = tempRow;
      }
      for (const col of Object.keys(question.grid.cols)) {
        const colId = question.grid.cols[col].id;
        if (question.grid.cols[col].colInputType) {
          colInputType = question.grid.cols[col].colInputType;
        } else {
          colInputType = false;
        }
        let response: string;
        if (colInputIndep) {
          for (const r in question.response) {
            if (
              question.response[r].col.id === colId &&
              question.response[r].row.id === rowId &&
              question.response[r].value
            ) {
              atLeastOneAnswerRow = true;
              if (colInputType === 'checkbox' || colInputType === 'radio') {
                response = '✓';
              } else if (colInputType === 'date') {
                response = new Date(question.response[r].value).toDateString();
              } else {
                response = question.response[r].value;
              }
            }
          }
        } else if (inputType === 'radio' || inputType === 'checkbox') {
          for (const r in question.response) {
            if (
              question.response[r].value &&
              question.response[r].col &&
              question.response[r].row &&
              question.response[r].col.id === colId &&
              question.response[r].row.id === rowId
            ) {
              atLeastOneAnswerRow = true;
              response = '✓';
            }
          }
        } else {
          for (const r in question.response) {
            if (
              question.response[r].col.id === colId &&
              question.response[r].row.id === rowId &&
              question.response[r].value
            ) {
              atLeastOneAnswerRow = true;
              if (inputType === 'date') {
                response = new Date(question.response[r].value).toDateString();
              } else {
                response = question.response[r].value;
              }
            }
          }
        }

        if (question.allowMultiple) {
          finalChart.push({
            question: colLabels[question.grid.cols[col].id],
            response,
          });
        } else {
          finalChart.push({
            question: tempRow,
            response,
          });
        }
      }
      if (atLeastOneAnswerRow) {
        atLeastOneAnswer = true;
      }
    } // end row loop
    if (!atLeastOneAnswer) {
      return false;
    }

    return finalChart;
  }

  getPriorityResponse(question) {
    if (!question.response.priorityList) {
      return false;
    }
    //   chart.push({ question: question.offeredQuestions[index].text ? question.offeredQuestions[index].text : '',
    //   response: "Yes",
    // })
    let chart = '';
    for (const index of Object.keys(question.response.priorityList)) {
      chart += ` ${question.response.priorityList[index].value} : ${parseInt(
        index,
        10
      ) + 1}\n`;
    }

    return chart;
  }

  getRadioResponse(question, survey) {
    if (!question.response.selectedAnswer) {
      return false;
    }
    if (question.response.selectedAnswer === 'other') {
      return question.response.other;
    } else {
      for (const a in question.offeredAnswers) {
        if (
          question.offeredAnswers[a].id === question.response.selectedAnswer
        ) {
          this.addIntegrationResponse(question.offeredAnswers[a], survey);
          return question.offeredAnswers[a].value;
        }
      }
    }
    return false;
  } // end func

  getCheckboxResponse(question, survey) {
    if (
      (!question.response.selectedAnswers ||
        question.response.selectedAnswers.length === 0) &&
      !question.isOtherAnswer
    ) {
      return false;
    }
    const answer = [];

    for (const a in question.response.selectedAnswers) {
      if (question.response.selectedAnswers) {
        answer.push(question.response.selectedAnswers[a].value);
        // add integration stuff
        this.addIntegrationResponse(
          question.response.selectedAnswers[a],
          survey
        );
      }
    }
    // checking if otherAnswer
    if (question.isOtherAnswer && question.response.other) {
      answer.push(question.response.other);
    }

    // if multiLine, change output format
    if (question.multiLine && answer.length > 1) {
      for (const item in answer) {
        if (answer[item]) {
          answer[item] = '<p>' + answer[item] + '</p>';
        }
      }
      return answer.join('');
    }

    // adding and to end of lists
    if (answer.length > 1 && answer[answer.length - 1]) {
      if (question.Or) {
        answer[answer.length - 1] = 'or ' + answer[answer.length - 1];
      } else {
        answer[answer.length - 1] = 'and ' + answer[answer.length - 1];
      }
    }
    // no comma if only one answer
    if (answer.length < 2) {
      if (answer[0]) {
        return answer[0];
      }
      if (answer[1]) {
        return answer[1];
      }
    }
    return answer.join(', ');
  } // end func

  getmultiYesNoResponse(question, survey) {
    if (
      !question.response.selectedAnswers ||
      question.response.selectedAnswers.length === 0
    ) {
      return false;
    }

    const chart = [];
    for (const index in question.response.selectedAnswers) {
      if (question.response.selectedAnswers[index] === 'Yes') {
        if (
          question.offeredQuestions[index].integrations &&
          question.offeredQuestions[index].integrations[1]
        ) {
          this.addIntegrationResponse(
            question.offeredQuestions[index].integrations[1],
            survey
          );
        }
        chart.push({
          question: question.offeredQuestions[index].text
            ? question.offeredQuestions[index].text
            : '',
          response: 'Yes',
        });
      } else if (question.response.selectedAnswers[index] === 'No') {
        if (
          question.offeredQuestions[index].integrations &&
          question.offeredQuestions[index].integrations[0]
        ) {
          this.addIntegrationResponse(
            question.offeredQuestions[index].integrations[0],
            survey
          );
        }
        chart.push({
          question: question.offeredQuestions[index].text
            ? question.offeredQuestions[index].text
            : '',
          response: 'No',
        });
      }
    }
    return chart;
  } // end func

  addIntegrationResponse(answer, survey) {
    if (!answer.integrationID) {
      return false;
    }
    if (!answer.integrationType || answer.integrationType === 'checkmark') {
      survey.integrationResponses[answer.integrationID] = '✔';
    }
    if (answer.integrationType === 'text' && answer.integrationText) {
      survey.integrationResponses[answer.integrationID] =
        answer.integrationText;
    }
    if (answer.integrationType === 'image' && answer.integrationImage) {
      // split to remove first part --- base64/png etc.
      survey.integrationResponses[
        'image:' + answer.integrationID
      ] = answer.integrationImage.split(',')[1];
    }
  } // end func

  // format responses for summary
  generateDataResponses(responses): [SurveyDataInstancesResponses] {
    const dataResponses: any = [];
    responses.forEach((response) => {
      if (!!response.question && !!response.question.id) {
        dataResponses.push({
          questionID: response.question.id,
          question: response.question.text,
          response: response.answer,
        });
      }
    });

    return dataResponses;
  } // end func

  setSurveyData(survey): SurveyDataInstancesResponses[] {
    survey.integrationResponses = {};
    const formattedResults = this.initSurveyDataSave(survey);

    return formattedResults;
  }

  saveSurveyData(
    companyID: string,
    caseID: string,
    surveyID: string,
    surveyData: SurveyDataMetaData,
    instanceData: SurveyDataInstances
  ): Promise<any> {
    return new Promise<void>((resolve) => {
      // save survey metadata, currently name and id
      firebase
        .firestore()
        .collection('companies')
        .doc(companyID)
        .collection('surveyData')
        .doc(surveyID)
        .set(surveyData, {
          merge: true,
        })
        .then(() => {
          // save instance data in sub collection of that survey
          firebase
            .firestore()
            .collection('companies')
            .doc(companyID)
            .collection('surveyData')
            .doc(surveyID)
            .collection('instances')
            .doc(caseID)
            .set(instanceData)
            .then(() => {
              console.log('successfully saved survey data');
              resolve();
            })
            .catch((err) => {
              console.log('error saving instance data: ', err);
              resolve();
            });
          // end saving instance data
        })
        .catch((err) => {
          console.log('error saving survey data: ', err);
          resolve();
        });
      // end saving survey metadata
    });
  }

  getSurveyDataSurveys(companyID): Observable<SurveyDataMetaData[]> {
    return new Observable<firebase.firestore.QuerySnapshot>((observer) => {
      return firebase
        .firestore()
        .collection('companies')
        .doc(companyID)
        .collection('surveyData')
        .onSnapshot((snapshot) => observer.next(snapshot));
    }).pipe(
      map(
        (snapshot) =>
          snapshot.docs.map((doc) => doc.data() as SurveyDataMetaData) // Convert to field data
      )
    );
  }

  sortByLastName(
    a: {
      last: string;
    },
    b: {
      last: string;
    }
  ): number {
    return a.last.localeCompare(b.last);
  }

  async getDoctorList(companyID): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const userListQuery: any = await this.apollo
          .query({
            query: this.doctorListQuery,
            variables: {
              companyID,
              idToken: await this.gcpIpAuthService.getCurrentUser().getIdToken(),
            },
          })
          .toPromise();
        let userList = userListQuery.data.getCompanyUserList;
        userList = userList.sort(this.sortByLastName);
        const doctorList = [];
        userList.forEach((user) => {
          user['fullName'] = user['first'] + ' ' + user['last'];
          doctorList.push(user);
        });
        console.log(doctorList);
        resolve(doctorList);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  async getAllSurveysForCompany(companyID): Promise<AllCompanySurveyData> {
    // TODO parallelize Promise resolutions

    const returnObj: AllCompanySurveyData = {
      library: [],
      doctorData: {},
      doctorSurveys: {},
      librarySurveyResponses: {},
      doctorSurveyResponses: {},
    };

    // get library surveys
    await firebase
      .firestore()
      .collection('librarySurveys')
      .doc('surveyData')
      .collection('library')
      .get()
      .then((querySnapshot) =>
        querySnapshot.docs
          .map((document) => document.data())
          .map(({ name, id, folder }) => ({ name, id, folder }))
      )
      .catch((error) => {
        console.error('error getting library:', error);
        return [] as Array<{ name; id; folder }>;
      })
      .then(async (librarySurveys) => {
        returnObj.library = librarySurveys;
        return Promise.all(
          librarySurveys.map((survey) => {
            firebase
              .firestore()
              .collection('companies')
              .doc(companyID)
              .collection('notes')
              .where('id', '==', survey.id)
              .orderBy('time', 'desc')
              .limit(1)
              .get()
              .then((querySnapshot) => {
                returnObj.librarySurveyResponses[
                  survey.id
                ] = !querySnapshot.empty;
              });
          })
        );
      });

    // get doctor data in company
    let doctorList: any[];
    try {
      doctorList = await this.getDoctorList(companyID);

      returnObj.doctorData = doctorList.reduce((map, doctor) => {
        map[doctor.uid] = doctor;
        return map;
      }, {});
    } catch (err) {
      console.log('error getting doctor list');
    }

    // get surveys for each doctor in company
    if (doctorList && doctorList.length > 0) {
      await Promise.all(
        doctorList.map((doctor) =>
          firebase
            .firestore()
            .collection('userSurveys')
            .doc('surveyData')
            .collection(doctor.uid)
            .get()
            .then((querySnapshot) =>
              querySnapshot.docs
                .map((document) => document.data())
                .map(({ name, id, folder, feedbackEnabled }) => ({
                  name,
                  id,
                  folder,
                  feedbackEnabled,
                }))
            )
            .catch((error) => {
              console.error('error with doctor: ', doctor, error);
              return [] as Array<{ name; id; folder; feedbackEnabled }>;
            })
            .then(async (doctorSurveys) => {
              returnObj.doctorSurveys[doctor.uid] = doctorSurveys;
              return Promise.all(
                doctorSurveys.map((survey) =>
                  firebase
                    .firestore()
                    .collection('companies')
                    .doc(companyID)
                    .collection('notes')
                    .where('id', '==', survey.id)
                    .orderBy('time', 'desc')
                    .limit(1)
                    .get()
                    .then((querySnapshot) => {
                      if (!returnObj.doctorSurveyResponses[doctor.uid]) {
                        returnObj.doctorSurveyResponses[doctor.uid] = {};
                      }
                      returnObj.doctorSurveyResponses[doctor.uid][
                        survey.id
                      ] = !querySnapshot.empty;
                    })
                )
              );
            })
        )
      );
    }

    return returnObj;
  }

  getSurveyDataInstances(
    companyID,
    surveyID,
    nextStart?: firebase.firestore.QueryDocumentSnapshot,
    getAll?: boolean
  ): Observable<{
    surveyData: SurveyDataTable;
    lastDataRef: firebase.firestore.QueryDocumentSnapshot;
  }> {
    return new Observable<firebase.firestore.QuerySnapshot>((observer) => {
      if (getAll) {
        return firebase
          .firestore()
          .collection('companies')
          .doc(companyID)
          .collection('notes')
          .where('id', '==', surveyID)
          .orderBy('time', 'desc')
          .onSnapshot((snapshot) => observer.next(snapshot));
      } else if (!nextStart) {
        return firebase
          .firestore()
          .collection('companies')
          .doc(companyID)
          .collection('notes')
          .where('id', '==', surveyID)
          .orderBy('time', 'desc')
          .limit(60)
          .onSnapshot((snapshot) => observer.next(snapshot));
      } else {
        // .startAt(nextStart)
        return firebase
          .firestore()
          .collection('companies')
          .doc(companyID)
          .collection('notes')
          .where('id', '==', surveyID)
          .orderBy('time', 'desc')
          .startAt(nextStart)
          .limit(60)
          .onSnapshot((snapshot) => observer.next(snapshot));
      }
    }).pipe(
      map((snapshot) => {
        let nextStart: firebase.firestore.QueryDocumentSnapshot;
        if (!nextStart) {
          nextStart = snapshot.docs[snapshot.docs.length - 1];
        }

        const surveyData: Promise<Array<{
          patientSID?: string;
          patientSid?: string;
          nid: string;
          name?: string;
          id?: string;
          pages: Array<{
            elements: Array<{
              [x: string]: any;
            }>;
          }>;
        }>> = Promise.all(
          snapshot.docs.map(async (doc) => {
            const ref = doc.ref;
            return {
              ...doc.data(),
              nid: doc.id,
              pages: await Promise.all(
                (await ref.collection('pages').get()).docs
                  .sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10))
                  .map(async (pageDoc) => {
                    const pageRef = pageDoc.ref;
                    return {
                      ...pageDoc.data(),
                      elements: await Promise.all(
                        (await pageRef.collection('elements').get()).docs
                          .sort(
                            (a, b) => parseInt(a.id, 10) - parseInt(b.id, 10)
                          )
                          .map(async (elementDoc) => {
                            return {
                              ...elementDoc.data(),
                            };
                          })
                      ),
                    };
                  })
              ),
            };
          })
        );

        return {
          surveyData,
          lastDataRef: nextStart,
        };
        // } catch (error) {
        // console.error(error); // Keep error trace
        // observer.error(error); // Pass error up; should not be handled on service level
        // }
      }),
      switchMap(async (inputObj) => {
        const surveys = inputObj.surveyData;

        const instances = await surveys.then((resolvedData) => {
          return resolvedData;
        });
        return {
          surveyData: instances,
          lastDataRef: inputObj.lastDataRef,
        };
      }),
      switchMap((inputObj) => {
        const instances = inputObj.surveyData;
        return Promise.all(
          instances.map((instance) => {
            const patientID = instance.patientSID || instance.patientSid;
            return firebase
              .firestore()
              .collection('companies')
              .doc(companyID)
              .collection('admittedPatients')
              .doc(patientID)
              .get()
              .then((snapshot) => ({
                surveyData: {
                  ...instance,
                },
                patientData: snapshot.data(),
                lastDataRef: inputObj.lastDataRef,
              }));
          })
        );
      }),
      map((inputObj) => {
        // console.log(inputObj)
        return inputObj.map((survey) => {
          const surveyData = survey.surveyData;
          const instance = this.setSurveyData(surveyData);
          return {
            metadata: {
              ...surveyData,
            },
            surveyData: instance,
            patientData: survey.patientData,
            lastDataRef: survey.lastDataRef,
          };
        });
      }),
      map((instances) => {
        const lastDataRef = instances[0].lastDataRef;
        const questionsObj: any = {};

        for (const instance of instances) {
          for (const response of instance.surveyData) {
            // console.log(response)
            questionsObj[response.questionID] = {
              id: response.questionID,
              name: response.question,
            };
          }
        }

        const allQuestions = Object.keys(questionsObj).map((key) => {
          return questionsObj[key];
        });

        const questionsArray = Object.keys(questionsObj);

        const tableData = instances.map((instance) => {
          const instanceQuestions: SurveyDataTableItem = {
            responses: {},
          };
          for (const response of instance.surveyData) {
            instanceQuestions.responses[response.questionID] =
              response.response;
          }
          for (const response of allQuestions) {
            if (!instanceQuestions.responses[response.id]) {
              instanceQuestions.responses[response.id] = '';
            }
          }
          instanceQuestions.metadata = {
            companyID,
            noteID: instance.metadata.nid,
            patientID: instance.metadata.patientSID,
            surveyID: instance.metadata.id,
            surveyName: instance.metadata.name,
            patientData: instance.patientData as PatientProfile,
          };

          return instanceQuestions;
        });
        const result: {
          surveyData: SurveyDataTable;
          lastDataRef: firebase.firestore.QueryDocumentSnapshot;
        } = {
          surveyData: {
            questionsObj,
            questionsArray,
            data: tableData,
          },
          lastDataRef,
        };
        return result;
      })
    );
  }

  // for sending survey in appt
  setupSurveyURLInAppointment(survey, companyID, appointmentData) {
    if (!survey) return false;
    const surveyType = survey.userID === 'library' ? 'library' : 'user';
    let surveyURL = (
      APPURL + 'surveys/launch/' +
      surveyType +
      '/' +
      companyID +
      '/' +
      survey.userID +
      '/' +
      survey.surveyID +
      (appointmentData.type == 'manual' ? ';manualAppt=true' : ';onlyLogin=true')
    );
    let familyUIDParam = (appointmentData.familyID || appointmentData.patientID) ? ';uid=' + appointmentData.patientID : '';
    surveyURL += familyUIDParam + '?apptID=' + appointmentData.linkID.split('-')[1];
    if (appointmentData.familyID) surveyURL += '&familyID=' + appointmentData.familyID;
    surveyURL += '&sia=1';
    return surveyURL;
  } //end func

} // end service
