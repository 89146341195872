import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'company-plan-services-list',
  templateUrl: './company-plan-services-list.component.html',
})
export class CompanyPlanServicesListComponent {
  @Input() companyPlanVirtualServices: any[] = [];
  @Input() userCompanyPlanMapping: any;
  @Input() adminView = false;

  @Output() serviceClicked = new EventEmitter();
  @Output() toggleService = new EventEmitter();
  @Output() addServicesBtnClicked = new EventEmitter();
}
