<div class="row">
  <div class="col-6">
    <button
      (click)="changeAnswer(false)"
      *ngIf="question.response.answer != 'No'"
      mat-raised-button
      class="btn-w-md float-right"
    >
      No
    </button>
    <button
      *ngIf="question.response.answer == 'No'"
      mat-raised-button
      class="btn-w-md float-right"
      color="primary"
    >
      No
    </button>
  </div>
  <div class="col-6">
    <button
      (click)="changeAnswer(true)"
      *ngIf="question.response.answer != 'Yes'"
      mat-raised-button
      class="btn-w-md float-left"
    >
      Yes
    </button>
    <button
      *ngIf="question.response.answer == 'Yes'"
      mat-raised-button
      class="btn-w-md float-left"
      color="primary"
    >
      Yes
    </button>
  </div>
</div>
