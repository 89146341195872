import { Component, inject } from '@angular/core';
import { TranslateService } from '@insig-health/services/translate/translate.service';

@Component({
  selector: 'insig-health-language-dropdown',
  templateUrl: './language-dropdown.component.html',
})
export class LanguageDropdownComponent {
  private readonly translateService = inject(TranslateService);
  public languageList: Array<string> = [];
  public selectedLanguage: string | undefined;

  constructor() {
    this.translateService.getLanguageList().then((languageList) => {
      this.languageList = languageList;
    });
  }

  switchLanguage(language: string): void {
    this.translateService.changeLanguage(language);
    this.selectedLanguage = language;
  }
}
