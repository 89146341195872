export * from './appointmentBooking.service';
import { AppointmentBookingService } from './appointmentBooking.service';
export * from './doctorAvailabilityES.service';
import { DoctorAvailabilityESService } from './doctorAvailabilityES.service';
export * from './doctorDataSearchES.service';
import { DoctorDataSearchESService } from './doctorDataSearchES.service';
export * from './doctorScheduleAvailabilityReIndex.service';
import { DoctorScheduleAvailabilityReIndexService } from './doctorScheduleAvailabilityReIndex.service';
export * from './doctorScheduleDataES.service';
import { DoctorScheduleDataESService } from './doctorScheduleDataES.service';
export const APIS = [AppointmentBookingService, DoctorAvailabilityESService, DoctorDataSearchESService, DoctorScheduleAvailabilityReIndexService, DoctorScheduleDataESService];
